import React, { useEffect } from 'react';
import { MenuItem, TextField } from '@material-ui/core';

const typeMap = {
  str: 'text',
  int: 'number',
  date: 'date'
};

const Select = ({
  data = {},
  onChangeField,
  maxLength = 255,
  endButton = null
}) => {
  return (
    <TextField
      label={data?.caption || ''}
      type={typeMap[data.type]}
      select={data?.ui_component === 'select'}
      value={data?.value == null ? '' : data.value}
      name={data.fld || ''}
      error={data?.error ?? false}
      helperText={data.errorMessage}
      InputLabelProps={{
        shrink: true
      }}
      onChange={onChangeField}
      variant="outlined"
      fullWidth
      inputProps={{
        maxLength: maxLength
      }}
      InputProps={{
        endAdornment: endButton
      }}
      disabled={data?.disabled ?? false}
      {...(data?.rest || {})}
    >
      {data?.ui_component === 'select' &&
        data?.options.map(data => (
          <MenuItem key={data.value} value={data.value}>
            {data.label}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default Select;
