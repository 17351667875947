import React from 'react';
import SrchAcc from '../SrchAcc';

const Account = ({ data, onChangeField }) => {
  const handleChangeAccount = ({
    ixAcc,
    AccNo,
    sAccTitle,
    ixSubType1,
    ixSubType2,
    ixSubType3,
    ixSubType4,
    ixSubType5,
    sSubType1,
    sSubType2,
    sSubType3,
    sSubType4,
    sSubType5
  }) => {
    onChangeField({
      target: {
        name: data.fld,
        value: {
          ixAcc,
          AccNo,
          sAccTitle,
          ixSubType1,
          ixSubType2,
          ixSubType3,
          ixSubType4,
          ixSubType5,
          sSubType1,
          sSubType2,
          sSubType3,
          sSubType4,
          sSubType5
        }
      }
    });
  };

  return (
    <SrchAcc
      selectedAcc={{
        id: data?.value?.ixAcc ?? 0,
        title: data?.value?.sAccTitle ?? ''
      }}
      onSelectAccount={handleChangeAccount}
      error={data?.error ?? false}
      errorMessage={data?.errorMessage ?? ''}
      allowSelectParent={false}
      {...(data?.rest ?? {})}
    />
  );
};

export default Account;
