import React from 'react';
import { Box, Container, Grid, Card, Typography } from '@material-ui/core';
import useStyles from './useStyles';

const benefits = [
  {
    title: 'Liquidity',
    description: `Manage access to funds by regularly evaluating your cash position and near-term cash needs with UlapBiz’s reports, schedules and dashboards.`,
    icon: '/static/images/solutions/construction/analysis.svg'
  },
  {
    title: 'Project Management',
    description: `Ensure your projects’ efficiency and increase potential successful project completion by keeping track of your budgets and actual performance.`,
    icon: '/static/images/solutions/construction/dashboard-benefits.svg'
  },
  {
    title: 'Compliance',
    description: `With its efficient solutions, UlapBiz provides peace of mind by streamlining the tax compliance process for its users.`,
    icon: '/static/images/solutions/construction/tax.svg'
  }
];

const Benefit = ({ icon, title = '', description = '' }) => {
  const classes = useStyles();
  return (
    <Card className={classes.benefitBg} raised>
      <Box className={classes.benefit}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mb={1}
        >
          <img width={90} src={icon} />

          <Typography
            variant="body1"
            className={classes.benefitTitle}
            align="center"
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.benefitDesc}
          align="center"
        >
          {description}
        </Typography>
      </Box>
    </Card>
  );
};

const Benefits = () => {
  const classes = useStyles();
  return (
    <div className={`${classes.root}`}>
      <Container maxWidth="lg">
        <Box className={classes.sectionTitleContainer}>
          <Box flex={1}>
            <Typography variant="body1" className={classes.smallTitle}>
              <span> BENEFITS </span>
            </Typography>
            <Typography className={classes.sectionTitle}>
              Your Project's{' '}
              <span className={classes.secondaryTitleColor}>
                Instant Financial Insight
              </span>
            </Typography>
          </Box>
          <Box flex={1} />
        </Box>

        <Box style={{ marginTop: '6em' }}>
          <Grid container justifyContent="space-evenly" spacing={5}>
            {benefits.map(item => (
              <Grid key={item.title} item lg={4}>
                <Benefit {...item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Benefits;
