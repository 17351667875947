import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

export const fetchARQ = async ({ base_url, userToken }) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${base_url}/arq/requests`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': userToken
      }
    });

    return {
      success: true,
      data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: error?.response?.statusText
    };
  }
};

export const useUserActiveRequest = () => {
  const [ARQS, setARQS] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ status: false, msg: '' });

  const { base_url, userToken } = useSelector(state => state.auth);

  useEffect(() => {
    let mounted = true;

    const getARQS = async () => {
      setLoading(true);
      setError({ status: false, msg: '' });
      const result = await fetchARQ({ base_url, userToken });

      if (mounted) {
        if (result.success) {
          setLoading(false);
          setARQS([...result.data]);
          return;
        }

        setLoading(false);
        setError({ status: true, msg: result.errorMesssage });
      }
    };

    getARQS();

    return () => (mounted = false);
  }, []);

  const getListOfArqs = () => ARQS;

  const refresh = async () => {
    setLoading(true);
    setError({ status: false, msg: '' });
    const result = await fetchARQ({ base_url, userToken });

    if (result.success) {
      setLoading(false);
      setARQS([...result.data]);
      return;
    }

    setLoading(false);
    setError({ status: true, msg: result.errorMesssage });
  };

  return {
    loading,
    error,
    refresh,
    getListOfArqs
  };
};
