import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { ArrowBack } from '@material-ui/icons';

const ErrorPage = ({
  image = '/static/illustrations/wandering.svg',
  error = 'Something went wrong!',
  showButton = true,
  buttonClickHandler = null,
  imgProps = {},
  titleProps = {},
  buttonProps = {},
  buttonCaption = 'Back',
  showButtonIcon = true,
  buttonIcon = <ArrowBack />
}) => {
  const navigate = useNavigate();

  function onButtonClick() {
    if (buttonClickHandler) buttonClickHandler();
    else navigate(-1);
  }

  return (
    <Box
      display="flex"
      height="calc(100vh - 64px)"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gridRowGap="1em"
    >
      <img src={image} alt="Error Occurred" width={300} {...imgProps} />
      <Typography variant="h1" align="center" color="primary" {...titleProps}>
        {error}
      </Typography>
      {showButton && (
        <Button
          variant="contained"
          color="primary"
          onClick={onButtonClick}
          startIcon={showButtonIcon ? buttonIcon : ''}
          {...buttonProps}
        >
          {buttonCaption}
        </Button>
      )}
    </Box>
  );
};

export default ErrorPage;
