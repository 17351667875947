import React, { useEffect, useRef } from 'react';
import { Box } from '@material-ui/core';
import { getSourceImg } from './ImgWithToken';
import { useSelector } from 'react-redux';
import { useViewImage } from './FileViewer/ImageViewer/ImageViewerProvider';

/* 
  * NOTE : viewComponentType [md_view | editor]
 
  * use `md_view` if the component is MDEditor.Markdown
  * use `edit` if the component is MDEditor
*/

const MarkDownImageViewer = ({
  children,
  content,
  viewComponentType = 'md_view'
}) => {
  const ref = useRef();

  const { show, setUrl: setImgUrl } = useViewImage();
  const { userToken, base_url } = useSelector(state => state.auth);
  useEffect(() => {
    let mounted = true;

    if (content === null) return;

    const reactMarkRef = ref.current;

    let elements = [];

    if (viewComponentType === 'editor') {
      // * NOTE :  preview of the editor
      elements =
        reactMarkRef?.children[0]?.children?.[1]?.children?.[0]?.children ?? [];
    } else {
      elements = reactMarkRef?.children[0]?.children ?? [];
    }

    for (let elem of elements) {
      if (elem.children.length > 0) {
        for (let tg of elem.children) {
          if (tg.tagName === 'IMG') {
            tg.addEventListener('click', Preview, false);
            tg.addEventListener(
              'error',
              async function(e) {
                const thisFunc = this;

                if (!this.hasAttribute('data-count-error')) {
                  this.setAttribute('data-count-error', 1);

                  const source = this.src;

                  this.src = '/static/loading.gif';

                  setTimeout(async () => {
                    const res = await getSourceImg({
                      url: source,
                      userToken,
                      base_url
                    });
                    thisFunc.src = res;
                  }, 2000);

                  return;
                }

                if (Number(this.getAttribute('data-count-error')) >= 3) {
                  this.removeEventListener('click', Preview);
                  this.removeEventListener('error', () => null);
                  return;
                }

                this.setAttribute(
                  'data-count-error',
                  Number(this.getAttribute('data-count-error')) + 1
                );

                const source = this.src;
                this.src = '/static/loading.gif';
                setTimeout(async () => {
                  const res = await getSourceImg({
                    url: source,
                    userToken,
                    base_url
                  });
                  thisFunc.src = res;
                }, 2000);
              },
              false
            );
          }
        }
      }
    }

    return () => {
      mounted = false;

      for (let elem of elements) {
        if (elem.children.length > 0) {
          for (let tg of elem.children) {
            if (tg.tagName === 'IMG') {
              tg.removeEventListener('click', Preview, false);
            }
          }
        }
      }
    };
  }, [content]);

  const Preview = e => {
    if (!e.srcElement.currentSrc) return;

    setImgUrl(e.srcElement.currentSrc);
    show();
  };

  return <Box ref={ref}>{children}</Box>;
};

export default MarkDownImageViewer;
