import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { orange } from '@material-ui/core/colors';
import { AppBar, Toolbar, makeStyles, Box, Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  toolbar: {
    height: 80
  },
  appBar: {
    transition: 'all 500ms ease'
  },
  link: {
    fontWeight: theme.typography.fontWeightBold,
    '& + &': {
      marginLeft: theme.spacing(3)
    },
    '&:hover': {
      color: orange[300]
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  biz: {
    color: orange[500],
    fontWeight: 900
  },
  icon: {
    height: 50
  }
}));

const TopBar = () => {
  const classes = useStyles();

  return (
    <AppBar color="default" elevation={2} className={classes.appBar}>
      <Container maxWidth="lg">
        <Toolbar disableGutters className={classes.toolbar}>
          <Box p={1}>
            <RouterLink to="/">
              <img
                src="/static/radztech_business_solutions.png"
                alt="Radztech Logo"
                className={classes.icon}
              />
            </RouterLink>
          </Box>

          <Box flexGrow={1} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopBar;
