import React from 'react';
import { Page } from 'src/components';
import { Hero, Overview, Features, Pricing, CTA } from 'src/pageComponents';
import Footer from 'src/views/landingPage/Footer';
import { Fab, makeStyles, Tooltip } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { ArrowBack } from '@material-ui/icons';
import { usePages } from 'src/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  fab: {
    background: '#1976D2',
    color: '#fff',
    position: 'fixed',
    zIndex: 2,
    bottom: theme.spacing(3),
    right: theme.spacing(4),
    '&:hover': {
      background: '#1976D2',
      color: '#fff'
    }
  }
}));

/**
 * @type {pageDetails}
 */
const defaultSettings = {
  cta: {
    show: true,
    text: 'Reduce the likelihood of inaccuracies and payroll nightmares'
  },
  features: {
    items: [
      {
        id: 1,
        staticImg: true,
        img: '/static/beyond-accounting/payroll/features/core.png',
        list: [
          {
            style: 'arrow',
            text: 'Auto Computation'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Earnings'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Deductions'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Customizable Formula'
          },
          {
            style: 'arrow',
            text: 'Copy Payroll'
          },
          {
            style: 'arrow',
            text: 'Auto Journal Entry'
          }
        ],
        particle: 'particle1',
        primaryText: 'Core Benefits',
        secondaryText:
          'Say goodbye to the hassle, and say hello to effortless payroll management with UlapBiz!'
      },
      {
        id: 2,
        staticImg: true,
        img: '/static/beyond-accounting/payroll/features/bio.png',
        list: [
          {
            style: 'arrow',
            text: 'Holidays Calendar'
          },
          {
            style: 'arrow',
            text: 'Auto Compute'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Overtime'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Undertime'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Late'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Absences'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Leaves'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Regular Holidays'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Special Holidays'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Official Business'
          }
        ],
        particle: 'particle2',
        primaryText: 'Biometric Time Logs',
        secondaryText:
          "UlapBiz seamlessly read and analyze your employees' time logs, and effortlessly computes deductions and earnings based on their daily attendance."
      },
      {
        id: 3,
        staticImg: true,
        img: '/static/beyond-accounting/payroll/features/reports.png',
        list: [
          {
            style: 'arrow',
            text: 'Payroll Register'
          },
          {
            style: 'arrow',
            text: 'Deductions Summary'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'SSS'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Pag-IBIG'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Withholding Tax'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'SSSOther Customizable Deduction'
          },
          {
            style: 'arrow',
            text: 'Earnings Summary'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Holidays'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Bonuses'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Allowances'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Other Customizable Earning'
          },
          {
            style: 'arrow',
            text: 'Loans Summary'
          },
          {
            style: 'arrow',
            text: 'Payslip'
          }
        ],
        primaryText: 'Payroll Reports',
        secondaryText:
          'Our system provides easy access to payroll reports immediately after each transaction, simplifying the process like never before.'
      },
      {
        id: 4,
        staticImg: true,
        img: '/static/beyond-accounting/payroll/features/hr_access.png',
        list: [
          {
            style: 'arrow',
            text: 'Leave Credits'
          },
          {
            style: 'arrow',
            text: 'Approval'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Leave'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Overtime'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Official Business'
          },
          {
            style: 'arrow',
            text: 'Calendar Setup'
          },
          {
            style: 'arrow',
            text: 'Portal Admin'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'User Management'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'User Activation/Deactivation'
          },
          {
            leftMargin: 2,
            style: 'circle',
            text: 'Portal QR'
          },
          {
            style: 'arrow',
            text: '201 File Update'
          },
          {
            style: 'arrow',
            text: 'Earnings, Deductions and Loans Setup'
          }
        ],
        particle: 'particle1',
        primaryText: 'HR Management',
        secondaryText:
          'Unlock the full potential of your workforce and streamline your HR processes like never before with UlapBiz.'
      }
    ],
    sectionTitle: 'Features',
    show: true
  },
  hero: {
    bg: 'bg1',
    staticImg: true,
    img: '/static/images/landing-page/payroll.png',
    primaryText: 'UlapPayroll',
    secondaryText:
      'Manage your workforce compensation and maintain statutory compliance at the same time.',
    showBg: true,
    show: true
  },
  overview: {
    items: [
      {
        id: 1,
        staticImg: true,
        img: '/static/beyond-accounting/payroll/icons/fingerprint-blk.svg',
        text: 'Biometric Time Logs'
      },
      {
        id: 2,
        staticImg: true,
        img: '/static/beyond-accounting/payroll/icons/reports.svg',
        text: 'Payroll Reports'
      },
      {
        id: 3,
        staticImg: true,
        img: '/static/beyond-accounting/payroll/icons/access-blk.svg',
        text: 'HR Management'
      },
      {
        id: 4,
        staticImg: true,
        img: '/static/beyond-accounting/payroll/icons/debt.svg',
        text: 'Loans'
      },
      {
        id: 5,
        staticImg: true,
        img: '/static/beyond-accounting/payroll/icons/adv-features.svg',
        text: 'Advanced Features'
      },
      {
        id: 6,
        staticImg: true,
        img: '/static/beyond-accounting/payroll/icons/portal.svg',
        text: 'HRIS Portal'
      },
      {
        id: 7,
        staticImg: true,
        img: '/static/beyond-accounting/payroll/icons/adv-reports.svg',
        text: 'Advanced Reports'
      }
    ],
    primaryText: 'Overview',
    secondaryText:
      'Automate compensation calculations, ensure data security, and stay compliant effortlessly. With easy integration, customizable settings, and a self-service portal for employees, streamline your payroll process today!',
    show: true
  },
  pricing: {
    items: [
      {
        color: 'blue',
        planName: 'Basic',
        planPrice: 'PHP 99.00/EMPLOYEE',
        shade: 500
      },
      {
        color: 'black',
        planName: 'PRO',
        planPrice: 'PHP 140.00/EMPLOYEE',
        shade: 900
      },
      {
        color: 'orange',
        planName: 'ENTERPRISE',
        planPrice: 'PHP 210.00/EMPLOYEE',
        shade: 500
      }
    ],
    primaryText: 'Pricing',
    secondaryText:
      "Choose from these three flexible and budget-friendly options tailored to your needs. With prices starting as low as 99 pesos, enjoy the convenience of automated payroll management. Take a moment to assess which pricing plan aligns best with your payroll requirements, and don't hesitate to contact us for further assistance.",
    show: true
  },
  template: 'template1'
};

const Payroll = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const { ixPage, pageDetails } = usePages('payroll', {
    jsonSettings: defaultSettings
  });

  return (
    <Page title="UlapPayroll" style={{ backgroundColor: '#FFF' }}>
      {Boolean(pageDetails?.hero?.show) && (
        <Hero pageDetails={pageDetails.hero} ixPage={ixPage} />
      )}
      {Boolean(pageDetails?.overview?.show) && (
        <Overview pageDetails={pageDetails.overview} ixPage={ixPage} />
      )}
      {Boolean(pageDetails?.features?.show) && (
        <Features pageDetails={pageDetails.features} ixPage={ixPage} />
      )}
      {Boolean(pageDetails?.pricing?.show) && (
        <Pricing pageDetails={pageDetails.pricing} ixPage={ixPage} />
      )}
      {Boolean(pageDetails?.cta?.show) && (
        <CTA pageDetails={pageDetails.cta} ixPage={ixPage} />
      )}
      <Footer />
      <Tooltip title="Go Back">
        <Fab
          aria-label="back"
          color="secondary"
          className={classes.fab}
          onClick={() => navigate('/accounting-and-beyond')}
        >
          <ArrowBack />
        </Fab>
      </Tooltip>
    </Page>
  );
};

export default Payroll;
