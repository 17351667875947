import { useMemo } from 'react';
import { useAuth, useEnv } from 'src/hooks';

import ulapBizGuestRoutes from './ulapBizGuestRoutes';
import ibfrsGuestRoutes from './ibfrsGuestRoutes';
import ibfrsRoutes from './ibfrsRoutes';
import ulapBizRoutes from './ulapBizRoutes';
import appRoutes from './appRoutes';
import registryRoutes from './registryRoutes';
import portalRoutes from './portalRoutes';
import createRouter from './createRouter';
import changePwRoutes from './changePwRoutes';
import portalLoginRoutes from './portalLoginRoutes';
import appLoginRoutes from './appLoginRoutes';
import radztechGuestRoutes from './radztechGuestRoutes';

function useRouteTo() {
  const { EGOV, RADZTECH } = useEnv();
  const { portal, app, registry, require_change_pw } = useAuth();

  const { routeType, routes } = useMemo(() => {
    if (!registry && !portal && !app)
      return {
        routes: EGOV
          ? ibfrsGuestRoutes
          : RADZTECH
          ? radztechGuestRoutes
          : ulapBizGuestRoutes,
        routeType: 'Guest'
      };

    if (registry && !require_change_pw)
      return {
        routes: registryRoutes,
        routeType: 'Registry'
      };
    else if (registry && require_change_pw)
      return {
        routes: changePwRoutes.registry,
        routeType: 'Registry'
      };

    const mergedRoutes = [...(EGOV ? ibfrsRoutes : ulapBizRoutes)];

    if (portal) mergedRoutes.push(...portalRoutes);
    else mergedRoutes.push(...portalLoginRoutes);

    if (app && !require_change_pw) mergedRoutes.push(...appRoutes);
    else if (app && require_change_pw) mergedRoutes.push(...changePwRoutes.app);
    else mergedRoutes.push(...appLoginRoutes);

    return {
      routes: mergedRoutes,
      routeType: 'App'
    };
  }, [portal, app, registry, EGOV, RADZTECH, require_change_pw]);

  const router = createRouter(routes, { portal, app, registry });

  return {
    routeType,
    router
  };
}

export default useRouteTo;
