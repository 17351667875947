import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
  meta: {},
  data: {
    items: [],
    recap: {}
  },
  dateRange: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  ixBrch: 0
};

const slice = createSlice({
  name: 'budgetRAPAL',
  initialState: INITIAL_STATE,
  reducers: {
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setBranch(state, action) {
      state.ixBrch = action.payload;
    },
    clearData(state, action) {
      state.data = {
        items: [],
        recap: {}
      };
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    }
  }
});

const budgetRAPALReducer = slice.reducer;
export const {
  setMeta,
  setData,
  setDateRange,
  setBranch,
  clearData,
  clear,
  restore
} = slice.actions;
export default budgetRAPALReducer;
