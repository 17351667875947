import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  navbar: {
    height: 64,
    width: '100%',
    position: 'fixed',
    zIndex: 10,
    background: theme.palette.background.navbar
  },
  navbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%'
  },
  banner: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  brandName: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF'
  },
  navLinks: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(3)
    }
  },
  navLink: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFFFFF'
  },
  sectionContainer: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5)
    }
  },
  sectionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(5),
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center'
    }
  },
  negateColor: {
    color: '#FFF'
  }
}));

const IBFRSStyleContext = React.createContext({
  navbar: '',
  navbarContainer: '',
  banner: '',
  navLinks: '',
  sectionContainer: '',
  sectionTitle: '',
  navLink: '',
  brandName: '',
  negateColor: ''
});

const IBFRSStyleProvider = ({ children }) => {
  const classes = useStyles();

  return (
    <IBFRSStyleContext.Provider value={classes}>
      {children}
    </IBFRSStyleContext.Provider>
  );
};

export { IBFRSStyleProvider };
export default IBFRSStyleContext;
