import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import ImgBox from 'src/icons/Box.png';

const EmptyBox = ({
  title = '',
  titleProps = {},
  containerProps = {},
  imageProps = {},
  children
}) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gridRowGap={theme.spacing(1)}
      {...containerProps}
    >
      <img width={150} height={150} src={ImgBox} alt="Empty" {...imageProps} />
      <Typography variant="h3" color="primary" {...titleProps}>
        {title || 'Empty!'}
      </Typography>
      {children}
    </Box>
  );
};

export default EmptyBox;
