import React from 'react';
import { useParams } from 'react-router';
import DynamicPageProvider from './DynamicPageProvider';
import LoadingWrapper from 'src/views/registry/admin/User/Loading';
import GeneratePage from './GeneratePage';

function DynamicPage({ code }) {
  const params = useParams();

  return (
    <DynamicPageProvider cdPage={params.cdPage}>
      <LoadingWrapper>
        <GeneratePage />
      </LoadingWrapper>
    </DynamicPageProvider>
  );
}

export default DynamicPage;
