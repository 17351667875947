import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const INITIAL_STATE = {
  jid: 0,
  RefNo: '',
  date1: moment(),
  cashBeg: 0,
  username: '',
  reOpen: false
};

const slice = createSlice({
  name: 'cashierLogs',
  initialState: INITIAL_STATE,
  reducers: {
    setCashierLogs(state, action) {
      state.jid = action.payload.jid;
      state.RefNo = action.payload.RefNo;
      state.date1 = action.payload.date1;
      state.cashBeg = action.payload.cashBeg;
      state.username = action.payload.username;
      state.reOpen = action.payload.reOpen;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    }
  }
});

const cashierLogsReducer = slice.reducer;

export const { setCashierLogs, clear, restore } = slice.actions;
export default cashierLogsReducer;
