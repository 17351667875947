import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Divider,
  DialogContent,
  Box,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { useToggle, useRequest } from 'src/hooks';
import moment from 'moment';
import { Search } from '@material-ui/icons';
import { DatePicker } from 'src/components';
import { useSelector } from 'react-redux';

/**
 * @typedef {Object} Context
 * @property {(params: {JCd: string, onSelectCallback: (row: object) => void}) => void} openSearch
 * @property {VoidFunction} closeTransSearch
 */

/**
 * @type {React.Context<Context>}
 */
const TransSearchContext = React.createContext();

const TransSearchProvider = ({ children }) => {
  const { transactions = [] } = useSelector(state => state.bizMeta);
  const { userToken } = useSelector(state => state.auth);

  const [JCd, setJCd] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [dateRange, setDateRange] = useState({});
  const [searchResult, setSearchResult] = useState({});
  const [isLoading, loadingOn, loadingOff] = useToggle();
  const request = useRequest(loadingOn, loadingOff);
  const [onSelect, setOnSelect] = useState(() => () => {});

  const [isOpen, openTransSearch, closeTransSearch] = useToggle();

  const transList = searchResult?.trans || [];
  const uuid = transactions.find(trans => trans.JCd === JCd)?.jcd_uuid || '';

  function openSearch({ JCd = '', onSelectCallback = () => {} }) {
    if (!JCd) return;

    setJCd(JCd);
    setOnSelect(() => onSelectCallback);
    openTransSearch();
  }

  useEffect(() => {
    if (!uuid || !dateRange?.dt1 || !dateRange?.dt2) return;

    let cancel = () => {};

    (async function() {
      const res = await request.post(
        '/trans/jcd/' + uuid,
        {
          dt1: dateRange.dt1,
          dt2: dateRange.dt2,
          ixBrch: 0,
          srch: searchQuery,
          token: userToken
        },
        {},
        function(c) {
          cancel = c;
        }
      );

      if (res.success) setSearchResult(res.data);
    })();

    return function() {
      cancel();
    };
  }, [dateRange, searchQuery, uuid]);

  return (
    <TransSearchContext.Provider
      value={{
        openSearch,
        closeTransSearch
      }}
    >
      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={closeTransSearch}>
        <DialogTitle disableTypography>
          <Grid container spacing={1}>
            <Grid item md={7} sm={6} xs={12}>
              <TextField
                variant="outlined"
                label="Search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <Search />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
            </Grid>
            <Grid item md={5} sm={6} xs={12}>
              <DatePicker
                type="year"
                values={dateRange}
                onChange={setDateRange}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ padding: 0 }}>
          <Box maxHeight={600}>
            {isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={200}
              >
                <CircularProgress size={30} />
              </Box>
            ) : transList.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={200}
              >
                <Typography variant="h4" color="primary">
                  No results found
                </Typography>
              </Box>
            ) : (
              <List disablePadding>
                {transList.map(
                  ({ jid, Name, jDate, RefNo, ...rest }, index) => (
                    <React.Fragment key={jid}>
                      <ListItem
                        button
                        onClick={() =>
                          onSelect({ jid, Name, jDate, RefNo, ...rest })
                        }
                      >
                        <ListItemText
                          primary={
                            <Box>
                              <Typography variant="h5">{RefNo}</Typography>
                              <Typography variant="body1">{Name}</Typography>
                            </Box>
                          }
                          secondary={moment(jDate).format('MMM DD, YYYY')}
                        />
                      </ListItem>
                      {index !== transList.length - 1 && (
                        <Divider component="li" />
                      )}
                    </React.Fragment>
                  )
                )}
              </List>
            )}
          </Box>
          {/* </ScrollBar> */}
        </DialogContent>
      </Dialog>
      {children}
    </TransSearchContext.Provider>
  );
};

export default TransSearchContext;
export { TransSearchProvider };
