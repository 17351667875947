import React from 'react';
import { Box, Paper, Typography, LinearProgress } from '@material-ui/core';
const Loading = () => {
  return (
    <Box
      display="flex"
      height="calc(100vh - 64px)"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width={{ xs: '100%', sm: '100%', md: '50%', lg: '30%', xl: '30%' }}
        p={3}
      >
        <Typography variant="body2" color="primary" align="center">
          Please Wait
        </Typography>
        <Paper component={Box} evevation={1} p={1} mt={1}>
          <LinearProgress />
        </Paper>
      </Box>
    </Box>
  );
};

export default Loading;
