import React from 'react';
import SingleDatePicker from '../SingleDatePicker';

const DateField = ({ data, onChangeField }) => {
  return (
    <SingleDatePicker
      value={data.value}
      onChange={onChangeField}
      name={data.fld}
      error={data?.error ?? false}
      helperText={data?.errorMessage || ''}
      fullWidth
      disabled={data?.disabled || false}
      label={data?.caption || ''}
      {...(data?.rest || {})}
    />
  );
};

export default DateField;
