import React from 'react';
import { TextField } from '@material-ui/core';
import DebounceTextField from 'src/components/DebounceTextField';
import moment from 'moment';

const typeMap = {
  str: 'text',
  int: 'number',
  date: 'date'
};

const Input = ({ debounce, delay, ...rest }) => {
  return debounce ? (
    <DebounceTextField delay={delay} {...rest} />
  ) : (
    <TextField {...rest} />
  );
};

const Default = ({
  data,
  debounce = true,
  debounceDelay = 500,
  onChangeField,
  maxLength = 255,
  endButton = null
}) => {
  return (
    <Input
      label={data.caption}
      type={typeMap[data.type]}
      value={
        data.type === 'date'
          ? moment(data.value).format('YYYY-MM-DD')
          : data.value == null
          ? ''
          : data.value
      }
      name={data.fld}
      multiline={data?.isLongText ?? false}
      minRows={3}
      error={data?.error ?? false}
      helperText={data.errorMessage}
      InputLabelProps={{
        shrink: true
      }}
      onChange={onChangeField}
      variant="outlined"
      fullWidth
      inputProps={{
        maxLength: maxLength
      }}
      InputProps={{
        endAdornment: endButton
      }}
      disabled={data?.disabled ?? false}
      delay={debounceDelay}
      debounce={debounce}
      {...data.rest}
    />
  );
};

export default Default;
