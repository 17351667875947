import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

const INITIAL_STATE = {
  dateRange: {
    dt1: '',
    dt2: '',
    textValue: ''
  },
  showSN: false,
  showSN2: false,
  showCS: false,
  showSC: false,
  showOrderDetails: false,
  activeJCd: [],
  activeWh: [],
  activeProdCat: {},
  activeProdSubCat: {},
  activeOrderStatus: {},
  activeVariant: {},
  activeBrch: 0,
  showProdDetails: false,
  ixSubType: 0,
  ixSub1: 0,
  sSub1: '',
  ixSub2: 0,
  sSub2: '',
  ixSub3: 0,
  sSub3: '',
  ixSub4: 0,
  sSub4: '',
  ixSub5: 0,
  sSub5: '',
  result: []
};

const isCSEnabled = (data, prop) => {
  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    if ((item?.[prop] ?? 0) > 0) return true;
  }

  return false;
};

const slice = createSlice({
  name: 'purchaseSummary',
  initialState: INITIAL_STATE,
  reducers: {
    storeDateRange(state, action) {
      state.dateRange = action.payload;
    },
    storeActiveJCd(state, action) {
      state.activeJCd = action.payload;
    },
    storeActiveWH(state, action) {
      state.activeWh = action.payload;
    },
    storeActiveProdCat(state, action) {
      state.activeProdCat = action.payload;
    },
    storeActiveProdSubCat(state, action) {
      state.activeProdSubCat = action.payload;
    },
    storeActiveOrderStatus(state, action) {
      state.activeOrderStatus = action.payload;
    },
    storeActiveBranch(state, action) {
      state.activeBrch = action.payload;
    },
    storeActiveVariant(state, action) {
      state.activeVariant = action.payload;
    },
    storeShowProdDetails(state, action) {
      state.showProdDetails = action.payload;
    },
    storeResult(state, action) {
      state.result = action.payload.map(prev => ({ ...prev, uuid: uuid() }));
      state.showCS = isCSEnabled(action.payload, 'libQtyCS');
      state.showSC = isCSEnabled(action.payload, 'libQtySC');
      state.showSN = state.showSN = action.payload.some(
        item => (item?.SN ?? '') !== ''
      );
      state.showSN2 = action.payload.some(item => (item?.SN2 ?? '') !== '');
      state.showOrderDetails = action.payload.some(
        item => (item?.ixOrder ?? 0) > 0
      );
    },
    storeIxSubType(state, action) {
      state.ixSubType = action.payload;
      state.ixSub1 = 0;
      state.sSub1 = '';
    },
    storeSubAcc1(state, action) {
      state.ixSub1 = action.payload.ixSubAcc;
      state.sSub1 = action.payload.sSubAcc;
    },
    storeSubAcc2(state, action) {
      state.ixSub2 = action.payload.ixSubAcc;
      state.sSub2 = action.payload.sSubAcc;
    },
    storeSubAcc3(state, action) {
      state.ixSub3 = action.payload.ixSubAcc;
      state.sSub3 = action.payload.sSubAcc;
    },
    storeSubAcc4(state, action) {
      state.ixSub4 = action.payload.ixSubAcc;
      state.sSub4 = action.payload.sSubAcc;
    },
    storeSubAcc5(state, action) {
      state.ixSub5 = action.payload.ixSubAcc;
      state.sSub5 = action.payload.sSubAcc;
    },
    clearStoredSubAccounts(state, action) {
      state.ixSubType = 0;
      state.ixSub1 = 0;
      state.ixSub2 = 0;
      state.ixSub3 = 0;
      state.ixSub4 = 0;
      state.ixSub5 = 0;
      state.sSub1 = '';
      state.sSub2 = '';
      state.sSub3 = '';
      state.sSub4 = '';
      state.sSub5 = '';
    },
    resetState() {
      return INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const purchaseSummaryReducer = slice.reducer;

export const {
  storeDateRange,
  storeActiveJCd,
  storeActiveWH,
  storeActiveProdCat,
  storeActiveProdSubCat,
  storeActiveOrderStatus,
  storeActiveBranch,
  storeShowProdDetails,
  storeIxSubType,
  storeSubAcc1,
  storeSubAcc2,
  storeSubAcc3,
  storeSubAcc4,
  storeSubAcc5,
  resetState,
  restore,
  clear,
  storeResult,
  storeActiveVariant,
  clearStoredSubAccounts
} = slice.actions;
export default purchaseSummaryReducer;
