import { useState, useEffect } from 'react';
import axios from 'axios';

const useIndustries = () => {
  const [industries, setIndustries] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(null);
      setIndustries({});

      try {
        const { data } = await axios({
          method: 'GET',
          url: '/api/globals/biz/industries',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        setIndustries(data);
        setLoading(false);
        setError(null);
      } catch (error) {
        console.error(error);
        setLoading(false);
        setError('Something went wrong.');
      }
    })();
  }, []);

  return { industries, error, loading };
};

export default useIndustries;
