import React from 'react';
import MaskedInput from 'react-text-mask';
import TextField from '@material-ui/core/TextField';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '+',
        '6',
        '3',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar="_"
      keepCharPositions
      showMask
      guide
    />
  );
}

const ContactNumberTextField = ({ ...rest }) => {
  return (
    <TextField
      {...rest}
      InputProps={{
        inputComponent: TextMaskCustom
      }}
      InputLabelProps={{ shrink: true }}
    />
  );
};

export default ContactNumberTextField;
