import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';

const useReveal = ({
  threshold = 0.1,
  toggle = false,
  observeAfterIntersec = false
}) => {
  const ref = useRef();
  const [reveal, setReveal] = useState(false);
  const observer = new IntersectionObserver(
    entries => {
      const entry = entries[0];
      if (entry.isIntersecting) setReveal(true);

      if (entry.isIntersecting && !observeAfterIntersec && !toggle)
        observer.unobserve(entry.target);

      if (toggle && !entry.isIntersecting && reveal) setReveal(false);
    },
    {
      threshold
    }
  );

  useEffect(() => {
    if (ref?.current) observer.observe(ref.current);
  }, [ref?.current]);

  return [ref, reveal, setReveal];
};

export const Animated = ({ children, className, ...rest }) => {
  const style = useSpring({ ...rest });

  return (
    <animated.div className={className} style={style}>
      {children}
    </animated.div>
  );
};

export default useReveal;
