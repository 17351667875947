import React from 'react';
import {
  Typography,
  Box,
  Container,
  Paper,
  makeStyles
} from '@material-ui/core';
import { Page } from 'src/components';
import { useGlobalStyles } from 'src/hooks';

const gap = 3;

const useStyles = makeStyles({
  terms: {
    '& p': {
      color: 'rgba(0,0,0,0.75)',
      fontWeight: 400
    }
  }
});

const Terms = () => {
  const { page } = useGlobalStyles();
  const classNames = useStyles();

  return (
    <Page title="Terms of Service" className={page}>
      <Container maxWidth="lg">
        <Box mt={15}>
          <Paper>
            <Box p={6} className={classNames.terms}>
              <Typography align="center" variant="h1" color="textSecondary">
                Terms of Service
              </Typography>
              <Box mt={gap}>
                <Typography variant="body1" color="textSecondary">
                  Integrated Budget and Financial Reporting System or iBFRS
                  (“the System”) is an online accounting and finance management
                  service designed for small businesses. Below mentioned terms
                  and conditions will explain Radztech Corporation (“Radztech”)
                  obligations as a service provider and user obligations as a
                  customer.
                </Typography>
              </Box>
              <Box mt={gap}>
                <Typography variant="body1" color="textSecondary">
                  Please read these terms and condition thoroughly before
                  subscribing with iBFRS.
                </Typography>
              </Box>
              <Box mt={gap}>
                <Typography variant="body1" color="textSecondary">
                  These terms are applicable on any use of the iBFRS and apply
                  to users from the time that Radztech provides user with access
                  to the system.
                </Typography>
              </Box>
              <Box mt={gap}>
                <Typography variant="body1" color="textSecondary">
                  We at Radztech stays in permanent beta and our services will
                  evolve over time based on the users’ feedback to get hassle
                  out of budget, accounting, and compliance. Radztech reserves
                  the right to change/alter/amend/modify/delete/re-phrase these
                  terms at any time, effective upon the posting of
                  change/alter/amend/modify/delete/re-phrase terms and Radztech
                  will make every effort to communicate these changes to the
                  users the website or via email or notification required. It is
                  likely the terms of use will
                  change/alter/amend/modify/delete/re-phrase over time. It is
                  users’ obligation to ensure that they have read, understood,
                  and agree to the most recent terms available on the website.
                </Typography>
              </Box>
              <Box mt={gap}>
                <Typography variant="body1" color="textSecondary">
                  By subscribing the services, the users acknowledge and accept
                  that they have read and understood these terms and have the
                  authority to act on behalf of any person for whom they are
                  using the service. Users are deemed to have agreed to these
                  terms on behalf of any entity for whom you use the service.
                </Typography>
              </Box>
              <Box mt={gap}>
                <Typography variant="h5" color="textSecondary">
                  <strong>1. Definitions</strong>
                </Typography>
              </Box>
              <Box ml={5}>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>"Agreement”</strong> means these terms and condition
                    mentioned herein including the recent
                    change/alter/amend/modify/delete/re-phrase time to time for
                    use of iBFRS.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>"Access Charge or Fee”</strong> refers to the
                    monthly/annually/ and or any other periodic charge fee, and
                    or specific to any specific service or product, payable by
                    the users for use of the services and products or any
                    ancillaries in accordance with the Pricing Schedule but
                    exclusive of any taxes and duties as may be applicable time
                    to time.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>"Confidential Information”</strong> includes all the
                    information shared or exchanged between the Radztech and
                    users, whether orally, in writing or electronically
                    including the service but does not include the information
                    which is, or becomes, publicly available other than through
                    un-authorized disclosure by the any other party.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>"Data"</strong> means any data inputted by users or
                    with users’ authority into the Radztech website or platform.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>“Price Schedule"</strong> means the information
                    relating to subscriptions and pricing written on the
                    subscriptions and billings. It shall also include any
                    additional pricing schedule agreed upon mutually or
                    predetermined for any service or product specific to any
                    specific customer requirement.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>"Intellectual Property Right"</strong> means any
                    trademark, patent, service mark, copyright, design, know-how
                    and or any other intellectual property rights, advertorials,
                    videos, communications, SOP’s, certifications,
                    contributions, whether registered or not anywhere in the
                    world.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>"Service"</strong> means the accounting and
                    financial management services made available through
                    Radztech website or any service or product specific to any
                    specific customer requirement.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>"Website"</strong> means the Hosted Internet website
                    at the domain{' '}
                    <a href="http://radztech.com">www.radztech.com</a> or any
                    other site operated by Radztech from time to time.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>“Radztech”</strong> and all current and future
                    holding or subsidiaries of Radztech, including without
                    limitation.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>"Invited User"</strong> means any person and or
                    entity, other than the subscriber, that uses iBFRS with the
                    authorization of the subscriber from time to time.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>"Subscriber"</strong> means the person who is or
                    intend to register for use of iBFRS, and includes any entity
                    on whose behalf that person registers to use the iBFRS.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>“Users”</strong> means the Subscriber, and where the
                    context permits, an Invited User.
                  </Typography>
                </Box>
              </Box>
              <Box mt={gap}>
                <Typography variant="h5" color="textSecondary">
                  <strong>2. Use of Software</strong>
                </Typography>
              </Box>
              <Box mt={gap}>
                <Typography variant="body1" color="textSecondary">
                  Radztech allows user the right to access and use the services
                  through the website with the user roles available to users
                  according to their subscription type. This right is
                  non-transferable, non-exclusive, and limited by and subject to
                  this Agreement. User acknowledge and agree that, subject to
                  any applicable written agreement between the subscriber and
                  the invited users, or any other applicable laws:
                </Typography>
              </Box>
              <Box ml={5}>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>I.</strong> the subscriber determines who is an
                    invited user and what level of user role access to the
                    relevant organization and service that invited user has;
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>II.</strong> the subscriber is responsible for all
                    invited users’ use of the service;
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>III.</strong> the subscriber controls each invited
                    user’s level of access to the relevant organization and
                    service at all times and can revoke or change an invited
                    user’s access, or level of access, at any time and for any
                    reason, in which case that person or entity will cease to be
                    an invited user or shall have that different level of
                    access, as the case may be;
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>IV.</strong> if there is any dispute between a
                    subscriber and an invited user regarding access to any
                    organization or service, the subscriber shall decide what
                    access or level of access to the relevant data or service
                    that invited user shall have, if any.
                  </Typography>
                </Box>
              </Box>
              <Box mt={gap}>
                <Typography variant="h5" color="textSecondary">
                  <strong>3. Users Obligations</strong>
                </Typography>
              </Box>
              <Box ml={5}>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>I. Payment:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    An invoice for the Access Charge or Fee will be issued each
                    month/quarter/half yearly/yearly in accordance with the
                    details set out in the Price Schedule. Radztech will
                    continue invoicing Users in accordance with the Price
                    Schedule until this Agreement is terminated in accordance
                    with termination clause.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    All Radztech invoices will be sent to Users, or to a billing
                    contact whose details are provided by users, by email.
                    Payment of all amounts specified in an invoice must be paid
                    in accordance with the Price Schedule. Users are responsible
                    for payment of all taxes and duties in addition to the
                    Access Charge.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>II. Special pricing or discounts:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Radztech to its Users may offer special pricing or discounts
                    for the Access Charge or Fee as a result of the number of
                    organizations that Users have added to the service or that
                    have been added with Users authority or as a result of Users
                    use of the service ('Organizations'). User shall be eligible
                    for such special pricing or discounts is conditional upon
                    users’ acceptance of responsibility for payment of any
                    Access Charge or Fee in relation to all of Users
                    Organizations. Without prejudice to any other rights that
                    Radztech may have under these Terms or at law, Radztech
                    reserves the right to render invoices for the full
                    (non-discounted) Access Charge due or suspend or terminate
                    Users use of the Service in respect of any or all of Users
                    Organizations in the event that any invoices for those
                    Access Charge are not paid in full in accordance with the
                    requirements set out in the Price Schedule.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>III. General obligations:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    It is responsibility of the Users that the Service and
                    Website for users’ own lawful/legitimate internal business
                    purposes, in accordance with these Terms and any notice sent
                    by Radztech or condition posted on the Website. Users may
                    use the Service and Website on behalf of others or in order
                    to provide services to others but if Users do so they must
                    ensure that they are authorized to do so and that all
                    persons for whom or to whom services are provided comply
                    with and accept all terms of this Agreement that apply to
                    them.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>
                      IV. Automated Bank transaction data delivered into Users
                      iBFRS account:
                    </strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Where available and the if the User has opted for, automated
                    bank account transaction data feeds are generally provided
                    to users free of charge. However, Radztech reserves the
                    right to pass or bill for any charges related to the
                    provision of bank feed data on a case-by-case basis at
                    Radztech sole discretion. Radztech would first inform users
                    via email or any other mode of communication including oral
                    communication to indicate what those charges are likely to
                    be (as such charges may vary depending on user’s bank and
                    users’ volume of bank feeds). All the time Users have the
                    option to decide to discontinue use of automated bank feeds
                    at any time. To exercise this option, Users must give
                    Radztech sufficient prior written notice of which automated
                    bank account transaction data feeds users want to
                    discontinue. Upon receiving such notice Radztech will
                    arrange for such feeds to be terminated in accordance with
                    each bank’s usual practices.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>V. Access conditions:</strong>
                  </Typography>
                </Box>
                <Box ml={5}>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>i.</strong> Users’ responsibility is to that all
                      usernames and passwords required to access the Service are
                      kept secure and confidential. User must immediately notify
                      Radztech of any unauthorized use of their passwords or any
                      other breach of security and Radztech will reset user
                      password and User must take all other actions that
                      Radztech reasonably deems necessary to maintain or enhance
                      the security of Radztech computing systems and networks
                      and user access to the Services.
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>ii.</strong> As a condition of these Terms, when
                      accessing and using the Services, user must:
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Box mt={gap}>
                      <Typography variant="body1" color="textSecondary">
                        <strong>a.</strong> not attempt to undermine the
                        security or integrity of iBFRS or networks or, where the
                        Services are hosted by a third party, that third party's
                        computing systems and networks;
                      </Typography>
                    </Box>
                    <Box mt={gap}>
                      <Typography variant="body1" color="textSecondary">
                        <strong>b.</strong> not use, or misuse or wrong use, the
                        Services in any way which may impact the functionality
                        of the applications, Services or Website, or any other
                        systems used to deliver the Services or Website or
                        impact the ability of any other user to use the Services
                        or Website;
                      </Typography>
                    </Box>
                    <Box mt={gap}>
                      <Typography variant="body1" color="textSecondary">
                        <strong>c.</strong> should not try to attempt or miss
                        attempt to gain or try to gain unauthorized access to
                        any materials other than those to which user have been
                        given express rights to use or access or to the computer
                        system/server on which the Services are hosted;
                      </Typography>
                    </Box>
                    <Box mt={gap}>
                      <Typography variant="body1" color="textSecondary">
                        <strong>d.</strong> should not try to transmit, or input
                        into the Services or Website, any: files or information
                        or inputs or applications or software that may damage
                        any other person's computing devices or software,
                        content that may be offensive, or material or Data in
                        violation of any law (including Data or other material
                        protected by copyright or trade secrets which user do
                        not have the right to use); and
                      </Typography>
                    </Box>
                    <Box mt={gap}>
                      <Typography variant="body1" color="textSecondary">
                        <strong>e.</strong> should not try to attempt to modify,
                        copy, adapt, reproduce, assemble, disassemble,
                        collaborate, decompile, or engineer or reverse engineer
                        any computer programs used to deliver the Services or to
                        operate the Website except as is strictly/mandatorily
                        required to use/consume either of them for normal
                        operation.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>VI. Usage Limitations:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Use of the Service may be subject to limitations, including
                    but not limited to monthly transaction volumes and the
                    number of calls users are permitted to make against iBFRS
                    application programming interface. Any such limitations will
                    be advised.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>VII. Communication Conditions:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    As a condition of these Terms, if user use any communication
                    tools available through the Website (such as any forum, chat
                    room or message center, blogs, e-mailer, advertisement),
                    they agree, accept, and confirm that only to use such
                    communication tools for lawful and legitimate purposes. User
                    must not use any such communication tool/software/
                    applications for posting or disseminating or displaying any
                    material unrelated or not legitimate to the use of the
                    Services, including (but not limited to):
                    offers/advertisement of goods or services for sale,
                    unsolicited commercial e-mail, any other communications,
                    files, applications, terms, tools that may damage any other
                    person's computing devices or software, content that may be
                    offensive, damaging, destructive to any other users of the
                    Services or the Website, or material in violation of any law
                    (including material that is protected by copyright or trade
                    secrets which user do not have the right to use).
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    When user make any communication on the Website or any other
                    media related or owned by Radztech, user represent that they
                    are permitted to make such communication. Radztech is under
                    no circumstance /obligation to ensure that the
                    communications on the Website are legitimate or that they
                    are related only to the use of the Services. As with any
                    other web-based forum, user must exercise caution when using
                    the communication tools available on the Website. However,
                    Radztech does reserve the right to remove any communication
                    at any time in its sole discretion.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>VIII. Indemnity:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    It is construed by accepting the conditions of this Terms of
                    Use, User indemnify Radztech against: all current or future
                    claims, costs, damage, and loss arising from user breach of
                    any of these Terms or any obligation User may have to
                    Radztech, including (but not limited to) any costs or
                    amounts relating to the recovery of any Access Charge or Fee
                    that are due/accrue but have not been paid by user.
                  </Typography>
                </Box>
              </Box>
              <Box mt={gap}>
                <Typography variant="h5" color="textSecondary">
                  <strong>4. Confidentiality and Privacy</strong>
                </Typography>
              </Box>
              <Box ml={5}>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>I. Confidentiality:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Unless otherwise the relevant party has the prior written
                    consent of the other or unless required to do so by law or
                    the governing law:
                  </Typography>
                </Box>
                <Box ml={5}>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>i.</strong> Each party to this agreement shall
                      ensure the confidentiality of all Confidential Information
                      classified or not of the other obtained in connection with
                      these Terms. Neither party shall, without the prior
                      written consent of the other party, disclose or make
                      public or make any Confidential Information available to
                      any person, or use the same for its own benefit, purpose,
                      processing, or any manner other than as contemplated by
                      these Terms. All the information’s collected by each party
                      shall use the information so collected under this Terms of
                      the Service for only and only for legitimate and law full
                      manner for own purpose only and that to the purpose for
                      which it is being collected.
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>ii.</strong> Each party's obligations under this
                      clause will survive during and post termination of these
                      Terms.
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>iii.</strong> The provisions of clauses 4.1.i and
                      4.1.ii shall not apply to any information which:
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Box mt={gap}>
                      <Typography variant="body1" color="textSecondary">
                        <strong>a.</strong> is or becomes public knowledge other
                        than by a breach of this clause either by any party or
                        both party or by virtue of its nature;
                      </Typography>
                    </Box>
                    <Box mt={gap}>
                      <Typography variant="body1" color="textSecondary">
                        <strong>b.</strong> is received or transferred from a
                        third party who lawfully or legitimately acquired it and
                        who is under no obligation restricting its disclosure;
                      </Typography>
                    </Box>
                    <Box mt={gap}>
                      <Typography variant="body1" color="textSecondary">
                        <strong>c.</strong> is in the possession of the
                        receiving party without restriction or discrimination or
                        disclaimer in relation to disclosure before the date of
                        receipt from the disclosing party; or
                      </Typography>
                    </Box>
                    <Box mt={gap}>
                      <Typography variant="body1" color="textSecondary">
                        <strong>d.</strong> is independently developed
                        /innovated/created without access to the Confidential
                        Information.
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="h5" color="textSecondary">
                      <strong>2. Privacy:</strong>
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      Radztech maintains a data privacy policy that sets out the
                      parties’ obligations in respect of personal or any
                      material, financial information. User should read such
                      policy and user will be taken to have agreed, accepted,
                      and confirmed that policy when they accept these Terms.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box mt={gap}>
                <Typography variant="h5" color="textSecondary">
                  <strong>5. Intellectual Property</strong>
                </Typography>
              </Box>
              <Box ml={5}>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>1. General:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Brand Name, Logo, Tagline, Title to, and all Intellectual
                    Property Rights in the Services, the Website and any
                    documentation, communication, advertisement, relating to the
                    Services remain the property of Radztech (or its licensors
                    as the case may be).
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>2. Ownership of Data:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Data, Title to and all Intellectual Property Rights in, the
                    Data remain user property. User access to the Data is
                    however, contingent on full payment of the fee when due or
                    accrue. User by subscribing to the services and accepting to
                    the terms, grant a license to use, arrangement, process,
                    copy, transmit, store, and back-up their information and
                    Data for the purposes of enabling user to access and use the
                    Services and for any other purpose related to provision of
                    services to User.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>3. Backup of Data:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    User at its own end must maintain copies, repository of all
                    Data inputted, entered, migrated into the Service. Radztech
                    ensures adherence to its best practice policies and
                    procedures to prevent data loss, including a system data
                    back-up regime, assure but does not make any guarantees that
                    there will be no loss of Data. Radztech expressly excludes
                    liability for any loss of Data no matter how caused.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>4. Third-party applications and user Data.</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    If user enable third-party applications by accepting or
                    agreeing to their services, for use in conjunction with the
                    Services, user acknowledge that Radztech may allow the
                    providers of those third-party applications to access user
                    Data as required for the interoperation of such third-party
                    applications with the Services. Radztech shall not be
                    responsible for any disclosure, modification or deletion of
                    User Data resulting from any such access by third-party
                    application providers.
                  </Typography>
                </Box>
              </Box>
              <Box mt={gap}>
                <Typography variant="h5" color="textSecondary">
                  <strong>6. Warranties and Acknowledgements</strong>
                </Typography>
              </Box>
              <Box ml={5}>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>1. Authority:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    User warrant and accept that where they have registered to
                    use the Service on behalf of another person, User have all
                    the authority to agree to these Terms on behalf of that
                    person for whom he has registered and agree that by
                    registering to use the Service user bind to the person on
                    whose behalf they act to the performance of all obligations
                    that they become subject to by virtue of these Terms,
                    without limiting user own personal obligations under these
                    Terms.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>2. Acknowledgement:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    User acknowledge and confirm that that:
                  </Typography>
                </Box>
                <Box ml={5}>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>i.</strong> by accepting the conditions of the
                      Terms, User are authorized to use the Services and the
                      Website and to access the information and Data that they
                      input into the Website, including any information or Data
                      input into the Website by any person they have authorized
                      to use the Service. User are also authorized to access the
                      processed information and Data that is made available to
                      them through user use of the Website and the Services
                      (whether that information and Data is user’s own or that
                      of anyone else).
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>ii.</strong> Radztech has no responsibility to any
                      person other than User and nothing in this Agreement
                      confers, or purports to confer, a benefit on any person
                      other than user. If User use the Services or access the
                      Website on behalf of or for the benefit of anyone other
                      than them (whether a body corporate or otherwise) user
                      agree that:
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Box mt={gap}>
                      <Typography variant="body1" color="textSecondary">
                        <strong>a.</strong> User are responsible for ensuring
                        that they have the right to do so;
                      </Typography>
                    </Box>
                    <Box mt={gap}>
                      <Typography variant="body1" color="textSecondary">
                        <strong>b.</strong> User are responsible for authorizing
                        any person who is given access to information or Data,
                        and they agree that Radztech has no obligation to
                        provide any person access to such information or Data
                        without their authorization and may refer any requests
                        for information to user to address; and
                      </Typography>
                    </Box>
                    <Box mt={gap}>
                      <Typography variant="body1" color="textSecondary">
                        <strong>c.</strong> User will indemnify Radztech against
                        any claims or loss relating to:
                      </Typography>
                    </Box>
                    <Box ml={5}>
                      <Box mt={gap}>
                        <Typography variant="body1" color="textSecondary">
                          <strong>•</strong> Radztech refusal to provide any
                          person access to their information or Data in
                          accordance with these Terms,
                        </Typography>
                      </Box>
                      <Box mt={gap}>
                        <Typography variant="body1" color="textSecondary">
                          <strong>•</strong> Radztech making available
                          information or Data to any person with their
                          authorization.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>iii.</strong> The provision of access to, and use
                      of, the Services is on an "as is " basis and at user’s own
                      risk.
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>iv.</strong> Radztech does not warrant or confirm
                      that the use of the Service will be uninterrupted or error
                      free. Amongst other things, the operation and availability
                      of the systems and other resources used for accessing the
                      Service, including public telephone services, computer
                      networks and the Internet, cloud systems can be
                      unpredictable and may from time to time discrete with or
                      interfere with or prevent access to the Services. Radztech
                      gives it disclaimer that is not in any way responsible for
                      any such interference or prevention of user access or use
                      of the Services.
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>v.</strong> Radztech is not user’s accountant and
                      use of the Services does not constitute the receipt of
                      accounting advice. Any accounting questions must be
                      address by the accountant.
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>vi.</strong> It is User sole and exclusive
                      responsibility to determine that the Services meet the
                      needs of their business or the purpose for which he is
                      using the service and are suitable for the purposes for
                      which they are used.
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>vii.</strong> User remain solely responsible for
                      complying with all applicable accounting, compliance, tax,
                      and other laws. It is user sole responsibility to check
                      that storage of and access to their Data through the
                      Software and the Website will comply with laws/compliances
                      applicable to users (including any laws of the land
                      requiring you to retain records).
                    </Typography>
                  </Box>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>3. No warranties:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Radztech assures you to provide best services, however does
                    not give any warranty about the Services. The Services are
                    designed keeping in view of the purpose of common use and
                    not for any specific services until unless designed
                    specifically. Without limiting the above, Radztech does not
                    warrant or confirm that the Services will meet user whole
                    requirements/ requirements/ or partial requirement or that
                    it will be suitable for any particular purpose. To avoid
                    doubt, all implied conditions or warranties are excluded in
                    so far as is permitted by law, including (without
                    limitation) warranties of merchantability, fitness for
                    purpose, title, and non-infringement.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>4. Consumer guarantees:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Users agree and confirms and represent that they are
                    acquiring the right to access and use the Services for the
                    purposes of a business and that, to the maximum extent
                    permitted by law, any statutory consumer guarantees or
                    legislation intended to protect non-business consumers in
                    any jurisdiction does not apply to the supply of the
                    Services, the Website, or these Terms.
                  </Typography>
                </Box>
              </Box>
              <Box mt={gap}>
                <Typography variant="h5" color="textSecondary">
                  <strong>7. Limitation of Liability</strong>
                </Typography>
              </Box>
              <Box ml={5}>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>I.</strong> To the maximum extent permitted by law,
                    Radztech excludes all liability and responsibility to User
                    (or any other person) in contract, tort (including
                    negligence), or otherwise, for any loss (including loss of
                    information, Data, profits, and savings) or damage
                    resulting, directly or indirectly, from any use of, or
                    reliance on, the Service or Website.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>II.</strong> If user suffer loss or damage as a
                    result of Radztech negligence or failure to comply with
                    these Terms, any claim by user against Radztech arising from
                    Radztech’s negligence or failure will be limited in respect
                    of any one incident, or series of connected incidents, to
                    the Access Charge paid by User in the previous 12 months.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>III.</strong> If User are not satisfied with the
                    Service, User sole and exclusive remedy is to terminate
                    these Terms in accordance with the Termination Clause under
                    this agreement.
                  </Typography>
                </Box>
              </Box>
              <Box mt={gap}>
                <Typography variant="h5" color="textSecondary">
                  <strong>8. Termination</strong>
                </Typography>
              </Box>
              <Box ml={5}>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>1. Trial Policy</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Upon User sign up first time for access to the Services user
                    can evaluate the Services under the defined trial usage
                    conditions, with no obligation to continue to use the
                    Services. The trial period is defined by Radztech on its
                    sole discretion and Radztech has all the right to
                    change/abolish/amend/alter the same without any notice. If
                    user chooses to continue using the Services after the trial
                    period, user will be billed by adding their billing details
                    into the Services, as set out in detail in the Price
                    Schedule. If User choose not to continue using the Services,
                    user may delete their organization from the Dashboard of the
                    Services. In case of a User who has not chosen for the
                    continuance of service by being billed-: Radztech shall not
                    be in responsible for storing or maintain its
                    data/file/organization details.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>2. Prepaid Subscriptions</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Radztech will not provide any refund for any remaining
                    prepaid period for a prepaid Access Charge or Fee
                    subscription.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>3. No-fault termination:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Under this agreement, these Terms will continue for the
                    period covered by the Access Charge or Fee paid or payable
                    under payment clause. Upon renewal of Services opted by the
                    user at end of each billing period, these Terms will
                    automatically continue for another period of the same
                    duration as that period for which the renewal is opted for,
                    unless either party terminates these Terms by giving at
                    least one month’s advance written notice. If User elect to
                    terminate these Terms by providing one month's’ advance
                    written notice, User shall be liable to pay all relevant
                    Access Charge up to and including the day of termination of
                    these Terms.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>4. Breach:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    If User:
                  </Typography>
                </Box>
                <Box ml={5}>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>i.</strong> breach any of these Terms and within
                      the cure period of 14 days do not do remedy the breach
                      days after receiving notice of the breach if the breach is
                      capable of being remedied;
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>ii.</strong> upon breach any of these Terms and
                      the breach is not capable of being cured (which includes
                      (without limitation) any breach of clauses or any payment
                      of Access Charge that are not paid in full in accordance
                      with the requirements set out in the Price Schedule); or
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>iii.</strong> upon User or User’s business become
                      insolvent or User business goes into liquidation or has a
                      receiver or manager appointed of any of its assets or if
                      User become insolvent, or make any arrangement with User
                      creditors, or become subject to any similar insolvency
                      event in any jurisdiction,
                    </Typography>
                  </Box>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Radztech may take any or all the following actions, at its
                    sole discretion without any prejudice:
                  </Typography>
                </Box>
                <Box ml={5}>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>iv.</strong> Terminate this Agreement and use of
                      the Services and the Website to the user;
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>v.</strong> Suspend for any definite or indefinite
                      period, use of the Services and the Website to the user;
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>vi.</strong> Suspend or terminate access to all or
                      any Data.
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>vii.</strong> Take either of the actions in
                      sub-clauses (vi), (v) and (vi) of this clause 8(IV) in
                      respect of any or all other persons whom user have
                      authorized to have access to their information or Data.
                    </Typography>
                  </Box>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    For the avoidance of doubt, if payment of any invoice for
                    Access Charge due in relation to any of user’s Billing
                    Contacts, Billing Plans or any of their organizations is not
                    made in accordance with the requirements set out in the
                    Price Schedule, Radztech may: suspend or terminate user use
                    of the Service, the authority for all or any of their
                    organizations to use the Service, or user rights of access
                    to all or any Data.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>5. Accrued Rights:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Termination of these Terms is without prejudice to any
                    rights and obligations of the parties accrued up to and
                    including the date of termination. On termination of this
                    Agreement user will:
                  </Typography>
                </Box>
                <Box ml={5}>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>i.</strong> remains liable for any accrued charges
                      and amounts which become due for payment before or after
                      termination; and
                    </Typography>
                  </Box>
                  <Box mt={gap}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>ii.</strong> immediately cease to use the Services
                      and the Website.
                    </Typography>
                  </Box>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>6. Expiry or termination:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Clauses 3(I), 3(VII), 4, 5, 6, 7, 8 and 10 survive the
                    expiry or termination of these Terms.
                  </Typography>
                </Box>
              </Box>
              <Box mt={gap}>
                <Typography variant="h5" color="textSecondary">
                  <strong>9. Help Desk</strong>
                </Typography>
              </Box>
              <Box ml={5}>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>1. Technical and Functional Problems:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    In the case of technical or any functional problems user
                    must make all reasonable efforts to investigate and diagnose
                    problems before contacting Radztech. If user still need
                    technical or functional help, please check the support
                    provided online by Radztech on the Website or failing that
                    email or call us on the given communication details on
                    www.radztech.com.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>2. Service availability:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    For its esteemed Users Radztech intends that the Services
                    should be available 24 hours a day, six days a week (Monday
                    to Saturday). However, it is possible that on some occasions
                    the Services or Website may be unavailable by virtue of any
                    scheduled/ unscheduled maintenance or other development
                    deployment activity taking place.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    In case any activity that will interrupt the services for a
                    longer period than it often happens during normal course of
                    action, Radztech shall take generally acceptable efforts to
                    communicate by publishing the same on the website of any
                    other means of communication in advance.
                  </Typography>
                </Box>
              </Box>
              <Box mt={gap}>
                <Typography variant="h5" color="textSecondary">
                  <strong>10. Refund Policy</strong>
                </Typography>
              </Box>
              <Box mt={gap}>
                <Typography variant="body1" color="textSecondary">
                  The Refund Policy maintained in the Radztech website
                  www.radztech.com form integral part of this agreement. Any
                  course of refund, for the user shall be governed by the
                  above-mentioned Refund Policy.
                </Typography>
              </Box>
              <Box mt={gap}>
                <Typography variant="h5" color="textSecondary">
                  <strong>11. Privacy Policy</strong>
                </Typography>
              </Box>
              <Box mt={gap}>
                <Typography variant="body1" color="textSecondary">
                  The Privacy Policy maintained in the Radztech website
                  www.radztech.com form integral part of this agreement. Any
                  matter or concern on account of or related to the privacy
                  policy course of the user shall be governed by the
                  above-mentioned Privacy Policy.
                </Typography>
              </Box>
              <Box mt={gap}>
                <Typography variant="h5" color="textSecondary">
                  <strong>12. General</strong>
                </Typography>
              </Box>
              <Box ml={5}>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>1. Entire agreement:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    These Terms, together with the Radztech Privacy Policy,
                    Refund Policy and the terms of any other notices or
                    instructions given to user under these Terms of Use,
                    supersede and extinguish all prior agreements,
                    representations (whether oral or written), and
                    understandings and constitute the entire agreement between
                    user and Radztech relating to the Services and the other
                    matters dealt with in these Terms.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>2. Waiver:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    If either party waives any breach of these Terms, this will
                    not constitute a waiver of any other breach. No waiver will
                    be effective unless made in writing.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>3. Delays:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Neither party will be liable for any delay or failure in
                    performance of its obligations under these Terms if the
                    delay or failure is due to any cause outside its reasonable
                    control. This clause does not apply to any obligation to pay
                    money.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>4. No Assignment:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    You may not assign or transfer any rights to any other
                    person without Radztech prior written consent.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>5. Severability:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    If any part or provision of these Terms is invalid,
                    unenforceable or in conflict with the law, that part or
                    provision is replaced with a provision which, as far as
                    possible, accomplishes the original purpose of that part or
                    provision. The remainder of this Agreement will be binding
                    on the parties.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>6. Notices:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    Any notice given under these Terms by either party to the
                    other must be in writing by email and will be deemed to have
                    been given on transmission. Notices to Radztech must be sent
                    to support@radztech.com or to any other email address
                    notified by email to user by Radztech. Notices to user will
                    be sent to the email address which user provided when
                    setting up access to the Service.
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>7. Rights of Third Parties:</strong>
                  </Typography>
                </Box>
                <Box mt={gap}>
                  <Typography variant="body1" color="textSecondary">
                    A person who is not a party to these Terms has no right to
                    benefit under or to enforce any term of these Terms.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

export default Terms;
