import React, { createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CashierLogModal from '../CashierLogModal';
import { useNotif, useRequest, useToggle } from 'src/hooks';
import { trans } from 'src/lib/endpoints';
import { useEffect } from 'react';
import moment from 'moment';
import { setCashierLogs, clear } from 'src/redux/slices/cashierLogs';
import { cloneDeep } from 'lodash';
import { CASH_COUNT } from 'src/constants';
import LogOffCashierLogs from '../LogOffCashierLog';
import { abs } from 'mathjs';

const CashierLogContext = createContext({});
const KEY = 'cashierLog';
const defaultProps = {
  RefNo: '',
  Particulars: 'Cashier Logs',
  Name: 'Cashier Logs',
  jDate: moment().format(),
  date1: moment().format(),
  date2: '',
  cashBeg: 0,
  cashEnd: 0
};

const postProps = {
  method: 'POST',
  url: '/reports/custom/'
};

function CashierLogProvider({ children }) {
  const dispatch = useDispatch();
  const req = useRequest();
  const notif = useNotif();
  const { saveTransaction, transactionMeta } = trans;
  const [cashierLogDetails, setCashierLogDetails] = useState(
    cloneDeep(defaultProps)
  );
  const [cashierLogShown, showCashierLog, hideCashierLog] = useToggle();
  const [logOffShown, showLogOff, hideLogOff] = useToggle();
  const [cashEnd, setCashEnd] = useState(0);
  const [cashCount, setCashCount] = useState([]);
  const [isUpdating, updateLoadOn, updateLoadOff] = useToggle();
  const [loading, loadOn, loadOff] = useToggle();
  const [isInitializing, initializeOn, initializeOff] = useToggle();
  const cashierLog = useSelector(({ cashierLogs }) => cashierLogs);
  const { frontEnd } = useSelector(({ bizMeta }) => bizMeta);
  const { current_user } = useSelector(({ auth }) => auth);

  const bizSettings = frontEnd?.cashierLog ?? {};

  async function init() {
    if (!cashierLogShown) return;

    initializeOn();
    const { data, success } = await req.get(
      transactionMeta({ uuid: bizSettings?.uuid })
    );
    initializeOff();

    if (!success) {
      hideCashierLog();
      notif.error('Failed to initialize. Please try again.');
      return;
    }

    const { RefNo } = data;

    setCashierLogDetails(prev => ({
      ...prev,
      RefNo
    }));
  }

  async function log() {
    const body = {
      RefNo: cashierLogDetails.RefNo,
      Particulars: cashierLogDetails.Particulars,
      Name: current_user?.username ?? 'Cashier Logs',
      jDate: cashierLogDetails.jDate,
      kvs: {
        cashierLog: {
          cashBeg: cashierLogDetails.cashBeg,
          cashEnd: cashierLogDetails.cashEnd,
          cashCount: CASH_COUNT,
          short: 0,
          over: 0,
          addTotal: 0,
          subtractTotal: 0,
          date1: cashierLogDetails.date1,
          date2: cashierLogDetails.date2
        }
      }
    };

    loadOn();
    const { data, success } = await req.post(
      saveTransaction({ uuid: bizSettings?.uuid ?? '' }),
      body
    );
    loadOff();

    if (!success) {
      notif.error('Failed to save. Please try again');
      return;
    }

    dispatch(
      setCashierLogs({
        jid: data.jid,
        RefNo: cashierLogDetails.RefNo,
        date1: cashierLogDetails.date1,
        date2: cashierLogDetails.date2,
        cashBeg: cashierLogDetails.cashBeg,
        username: current_user?.username,
        reOpen: false
      })
    );

    hideCashierLog();
    setCashierLogDetails(cloneDeep(defaultProps));
  }

  function initCashCount(data) {
    const transCashierLog = data.kvs.find(item => item.key === KEY);
    setCashCount(transCashierLog.value.cashCount);
    setCashEnd(transCashierLog.value.cashEnd);
  }

  function mapFilterValues(filters, meta) {
    let filter_obj = { ...filters };

    Object.entries(filter_obj).forEach(([key, value]) => {
      filter_obj[key] = meta?.[value] ?? value;
    });

    return filter_obj;
  }

  async function initLogOff() {
    showLogOff();
    initializeOn();

    const { success, data } = await getCashierLogDetails({
      jid: cashierLog.jid
    });

    if (!success) {
      initializeOff();
      return;
    }

    initCashCount(data.jv);
    initializeOff();
  }

  function onChangeCashCountQty({ id, value }) {
    setCashCount(prev => {
      const copyPrev = [...prev];

      copyPrev.forEach((item, index) => {
        if (item.id === id) {
          copyPrev[index].qty = +value;
          copyPrev[index].total = +value * +item.denomination;
        }
      });

      setCashEnd(() => copyPrev.reduce((acc, prev) => acc + +prev.total, 0));

      return copyPrev;
    });
  }

  async function updateCashierLog({ jid = 0, ...props }) {
    return await req.put(`/trans/jcd/${bizSettings?.uuid}/save`, {
      jid,
      kvs: { cashierLog: props }
    });
  }

  async function updateStatus({ newStatus = 1, remarks = 'Closed.', jid = 0 }) {
    return await req.put(`/trans/jv/${jid}/status/update`, {
      newStatus,
      remarks
    });
  }

  async function logOff({ isForceClosed = false, jid = 0 }) {
    updateLoadOn();

    const final_jid = !isForceClosed ? cashierLog.jid : jid;

    const {
      success: cashierLogSuccess,
      data: meta
    } = await getCashierLogDetails({
      jid: final_jid
    });

    if (!cashierLogSuccess) {
      notif.error("Can't Log off. Please try again.");
      updateLoadOff();
      return;
    }

    const {
      success: customReportSuccess,
      reports
    } = await getLinkedCustomReports(meta);

    if (!customReportSuccess) {
      notif.error("Can't Log off. Please try again.");
      updateLoadOff();
      return;
    }

    const { addTotal, subtractTotal } = reports.reduce(
      (acc, prev) => {
        if (prev.add) acc.addTotal += prev.value;

        if (prev.subtract) acc.subtractTotal += prev.value;

        return acc;
      },
      {
        addTotal: 0,
        subtractTotal: 0
      }
    );

    const kvsCashierLog = meta.jv.kvs.find(item => item.key === KEY).value;
    const net = kvsCashierLog.cashBeg + addTotal - subtractTotal;
    let sortOver = cashEnd - net;

    if (cashEnd <= 0) sortOver = 0;

    const jvSave = await updateCashierLog({
      ...kvsCashierLog,
      jid: final_jid,
      addTotal: addTotal,
      subtractTotal: subtractTotal,
      cashEnd,
      cashCount: !isForceClosed ? cashCount : kvsCashierLog.cashCount,
      short: !isForceClosed ? (sortOver < 0 ? abs(sortOver) : 0) : 0,
      over: !isForceClosed ? (sortOver > 0 ? abs(sortOver) : 0) : 0,
      date2: moment().format()
    });

    if (!jvSave.success) {
      notif.error('Something went wrong. Please try again.');
      updateLoadOff();
      return;
    }

    const { success: statusChangeSuccess } = await updateStatus({
      jid: final_jid,
      newStatus: 1
    });

    if (!statusChangeSuccess)
      notif.error('Failed to update transaction status.');

    notif.success('Log off successfully');

    updateLoadOff();

    if (!isForceClosed) {
      dispatch(clear());
      setCashCount([]);
      hideLogOff();
    }
  }

  async function getLinkedCustomReports(meta) {
    const linkedCustomReports =
      meta?.jv?._rm?.cashierLogs?.linkedCustomReports ?? [];
    const reports = [];
    const customReports = Object.entries(linkedCustomReports).map(
      ([_, value]) => ({
        ...postProps,
        data: mapFilterValues(value.filters, meta.jv)
      })
    );

    const result = await req.multiFetch(customReports);

    for (let i = 0; i < result.length; i++) {
      const res = result[i];

      if (!res.success) {
        return {
          success: false,
          reports: []
        };
      }

      const reportSettings = linkedCustomReports[i];

      reports.push({
        ...reportSettings,
        value: res?.data?.data?.[0]?.[reportSettings?.fld] ?? 0
      });
    }

    return {
      success: true,
      reports
    };
  }

  async function getCashierLogDetails({ jid = 0 }) {
    const jvDetails = await req.get(`/trans/jv/${jid}`);

    return jvDetails;
  }

  useEffect(() => {
    init();
  }, [cashierLogShown]);

  return (
    <CashierLogContext.Provider
      value={{
        open: showCashierLog,
        logOffShown,
        initLogOff,
        logOff,
        cashCount,
        cashEnd,
        hideLogOff,
        cashierLog,
        clear,
        log,
        isUpdating,
        loading,
        cashierLogShown,
        isInitializing,
        cashierLogDetails,
        hideCashierLog,
        onChangeCashCountQty,
        setCashierLogDetails,
        bizSettings,
        showForceClose: current_user?.username !== cashierLog?.username
      }}
    >
      {children}
      <LogOffCashierLogs />
      <CashierLogModal />
    </CashierLogContext.Provider>
  );
}

export const useCashierLog = () => useContext(CashierLogContext);

export default CashierLogProvider;
