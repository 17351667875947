import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'advanceDashboard',
  initialState: {},
  reducers: {
    setDate(state, { payload: { ixDashboard, ixTarget, data } }) {
      return {
        ...state,
        ['DB' + ixDashboard]: {
          ...(state?.['DB' + ixDashboard] || {}),
          ['TARGET' + ixTarget]: {
            ...(state?.['DB' + ixDashboard]?.['TARGET' + ixTarget] || {}),
            date: data
          }
        }
      };
    },
    setVariation(state, { payload: { ixDashboard, ixTarget, data } }) {
      return {
        ...state,
        ['DB' + ixDashboard]: {
          ...(state?.['DB' + ixDashboard] || {}),
          ['TARGET' + ixTarget]: {
            ...(state?.['DB' + ixDashboard]?.['TARGET' + ixTarget] || {}),
            variation: data
          }
        }
      };
    },
    setFilter(state, { payload: { ixDashboard, ixTarget, data } }) {
      return {
        ...state,
        ['DB' + ixDashboard]: {
          ...(state?.['DB' + ixDashboard] || {}),
          ['TARGET' + ixTarget]: {
            ...(state?.['DB' + ixDashboard]?.['TARGET' + ixTarget] || {}),
            filter: data
          }
        }
      };
    },
    clear(state, action) {
      return {};
    },
    override(_, action) {
      return action.payload || {};
    }
  }
});

const advanceDashboardReducer = slice.reducer;

export const {
  setDate,
  setVariation,
  setFilter,
  restore,
  clear
} = slice.actions;
export default advanceDashboardReducer;
