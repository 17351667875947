/**
 *
 * @param {object[]} data
 * @param {column[]} columns
 * @param {{showNo:boolean,includeTotals:boolean,totals:object}} options
 */
function generateExportableData(
  data = [],
  columns = [],
  options = { showNo: false, includeTotals: false, totals: {} }
) {
  const res = [];

  // generate header
  const header = [];

  if (options.showNo) {
    header.push('No.');
  }

  for (const col of columns) {
    if (col.headerName) {
      header.push(col.headerName);
    }
  }
  res.push(header);

  // add the data
  for (const [index, dataItem] of data.entries()) {
    const row = [];

    if (options.showNo) {
      row.push(index + 1);
    }

    for (const col of columns) {
      if (col.headerName) row.push(dataItem[col.field]);
    }

    res.push(row);
  }

  // adding totals
  if (options.includeTotals) {
    const totalRow = [];

    for (const col of columns) {
      totalRow.push(
        options.totals.hasOwnProperty(col.field)
          ? options.totals[col.field]
          : ''
      );
    }

    res.push(totalRow);
  }

  return res;
}

export default generateExportableData;
