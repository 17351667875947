import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useNavigate } from 'react-router';
import { useGlobalStyles } from 'src/hooks';

const BackButton = ({
  variant = 'contained',
  color = 'primary',
  startIcon = <ArrowBack />,
  children,
  rounded = true,
  size = 'medium',
  ...props
}) => {
  const styles = useGlobalStyles();

  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  return (
    <Button
      variant={variant}
      color={color}
      size={size}
      startIcon={startIcon}
      className={rounded ? styles.roundedBtn : ''}
      onClick={goBack}
      {...props}
    >
      {children}
    </Button>
  );
};

export default BackButton;
