import React from 'react';
import SrchSub from '../SrchSub';

const SubAccount = ({ data, onChangeField }) => {
  const ixSubType = data.ixSubType;
  const sSubType = data.sSubType;

  const handleChangeSrchSub = ({ ixSub, sSub, ...rest }) => {
    onChangeField({
      target: {
        name: data.fld,
        value: {
          ixSub,
          sSub
        }
      }
    });
  };

  return (
    <SrchSub
      ixSubType={ixSubType}
      sSubType={sSubType}
      fld={data.fld}
      error={data?.error ?? false}
      errorMessage={data?.errorMessage ?? ''}
      size={data?.rest?.size ?? 'medium'}
      selectedSub={data?.value?.sSub ?? ''}
      handleSubSelectRoot={handleChangeSrchSub}
      {...(data?.rest ?? {})}
    />
  );
};

export default SubAccount;
