import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useJCdByUser = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { base_url, userToken } = useSelector(({ auth }) => auth);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${base_url}/trans/jcd/by/user`,
          headers: {
            'Content-Type': 'application/json',
            'x-access-tokens': userToken
          }
        });

        setLoading(false);
        setData(data);
      } catch (error) {
        setLoading(false);
        setError('Failed to jcd by user');
      }
    })();
  }, []);

  return {
    data,
    loading,
    error
  };
};

export default useJCdByUser;
