import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import axios from 'axios';
import getRandomNumbers from 'src/utils/getRandomNumbers';
import { isArray } from 'lodash';

const INITIAL_STATE = {
  loading: false,
  error: false,
  errorMsg: '',
  data: null,
  reportLayout: 'SOS',
  dt1: null,
  dt2: null,
  unreportedDt1: null,
  unreportedDt2: null,
  textValue: '',
  page: 0,
  limit: 10,
  transactionDate: true,
  referenceDate: true,
  reportedDate: false,
  selectedReport: 0,
  selectedRows: [],
  reportDate: moment().format(),
  openUpdateReportDate: false,
  openConfirmUpdate: false,
  confirmUpdateSelectedId: 0,
  isUpdating: false,
  updateResult: null,
  updateFailed: false,
  updateFailedMessage: '',
  transactionToShow: 'unreported',
  validationErrors: [],
  transactionsForUpdateConfirmation: {},
  brch: {
    required: false,
    ixBrch: 0,
    label: '',
    lstBrch: [],
    ixParent: 0
  },
  searchQuery: ''
};

const slice = createSlice({
  name: 'sos',
  initialState: INITIAL_STATE,
  reducers: {
    onFetchSOSReport(state) {
      state.validationErrors = [];
      state.updateResult = null;
      state.updateFailed = false;
      state.loading = true;
      state.data = [];
      state.error = false;
      state.errorMsg = '';
      state.selectedRows = [];
    },
    changeSOSLayout(state, { payload }) {
      state.page = 0;
      state.limit = 10;
      state.reportLayout = payload;
    },
    changeSOSDate(state, { payload }) {
      state.dt1 = payload.dt1;
      state.dt2 = payload.dt2;
      state.textValue = payload.textValue;
    },
    loadSOSReport(state, { payload }) {
      state.page = 0;
      state.limit = 10;
      state.loading = false;
      state.data = payload;
      state.selectedRows = [];
      state.reportDate = moment().format();
      state.openUpdateReportDate = false;
      state.isUpdating = false;
      state.updateResult = null;
      state.updateFailed = false;
      state.validationErrors = [];
    },
    loadSOSFetchError(state, { payload }) {
      state.loading = false;
      state.error = true;
      state.errorMsg = payload;
    },
    clearSOSReport(state) {
      state.page = 0;
      state.limit = 10;
      state.data = null;
      state.transactionDate = true;
      state.referenceDate = true;
      state.reportedDate = false;
      state.reportLayout = 'SOS';
      state.transactionToShow = 'unreported';
      state.searchQuery = '';
      state.selectedRows = [];
      state.selectedReport = 0;
      state.updateResult = null;
      state.updateFailed = false;
      state.updateFailedMessage = '';
      state.validationErrors = [];
      state.openConfirmUpdate = false;
      state.confirmUpdateSelectedId = 0;
      state.transactionsForUpdateConfirmation = {};
    },
    cancelSOSRequest(state) {
      state.loading = false;
    },
    changeReportSOSPage(state, { payload }) {
      state.page = payload;
    },
    changeReportSOSLimit(state, { payload }) {
      state.page = 0;
      state.limit = payload;
    },
    onChangeDateFilterCheckbox(state, { payload }) {
      state[payload.name] = payload.value;
    },
    onSelectRows(state, { payload }) {
      let trans = state.transactionsForUpdateConfirmation;

      const transKeys = Object.keys(trans);

      transKeys.forEach(item => {
        if (!payload.includes(+item)) {
          delete trans[+item];
        }
      });

      state.selectedRows = payload;
      state.transactionsForUpdateConfirmation = trans;
    },
    updateReportDate(state, { payload }) {
      state.data = payload.data;
      state.updateResult = payload.result;
      state.isUpdating = false;
      state.openUpdateReportDate = false;
      state.transactionsForUpdateConfirmation = {};
      state.openConfirmUpdate = false;
      state.confirmUpdateSelectedId = 0;
    },
    openUpdateReportDate(state) {
      state.updateFailedMessage = '';
      state.updateFailed = false;
      state.openUpdateReportDate = true;
    },
    closeUpdateReportDate(state) {
      state.updateFailedMessage = '';
      state.updateFailed = false;
      state.openUpdateReportDate = false;
    },
    onUpdateReportDate(state) {
      state.isUpdating = true;
    },
    changeReportDate(state, { payload }) {
      state.reportDate = payload;
    },
    clearUpdateReportModalError(state) {
      state.updateFailed = false;
    },
    failedUpdateReportDate(state, { payload }) {
      state.updateFailedMessage = payload;
      state.updateFailed = true;
      state.isUpdating = false;
    },
    clearUpdateResult(state) {
      state.updateResult = null;
    },
    loadValidationErrors(state, { payload }) {
      state.openUpdateReportDate = false;
      state.validationErrors = payload;
    },
    clearValidationErrors(state) {
      state.openUpdateReportDate = false;
      state.validationErrors = [];
    },
    setSOSBranch(state, { payload }) {
      state.brch = {
        ...state.brch,
        required: payload.required,
        label: payload.label_singular
      };
    },
    changeSOSBranch(state, { payload }) {
      if (isArray(payload)) {
        state.brch.ixBrch = 0;
        state.brch.lstBrch = payload;
        return;
      }

      if (typeof payload?.ixParent !== 'undefined') {
        state.brch.ixBrch = 0;
        state.brch.ixParent = payload.ixParent;
        state.brch.lstBrch = payload.lstBrch;
        return;
      }

      state.brch.ixBrch = payload;
      state.brch.ixParent = 0;
      state.brch.lstBrch = [];
    },
    changeSOSTransactionToShow(state, { payload }) {
      state.transactionToShow = payload;
    },
    addSOSTransactionsForUpdateConfirmation(state, { payload }) {
      state.transactionsForUpdateConfirmation = payload;
    },
    showSOSConfirmUpdateModal(state, action) {
      state.openConfirmUpdate = true;
      state.confirmUpdateSelectedId = action.payload;
    },
    closeSOSConfirmUpdateModal(state) {
      state.openConfirmUpdate = false;
      state.confirmUpdateSelectedId = 0;
    },
    confirmSOSTransactionUpdate(state, { payload }) {
      state.openConfirmUpdate = false;
      state.confirmUpdateSelectedId = 0;
      state.transactionsForUpdateConfirmation = {
        ...state.transactionsForUpdateConfirmation,
        [payload]: {
          ...state.transactionsForUpdateConfirmation[payload],
          isConfirmed: true
        }
      };
    },
    clearSOStransactionsForUpdateConfirmation(state) {
      state.openConfirmUpdate = false;
      state.confirmUpdateSelectedId = 0;
      state.transactionsForUpdateConfirmation = {};
    },
    changeSelectedReport(state, { payload }) {
      state.searchQuery = '';
      state.selectedReport = payload;
    },
    changeUnreportedDate(state, { payload }) {
      state.unreportedDt1 = payload.dt1;
      state.unreportedDt2 = payload.dt2;
    },
    changeSearchQuery(state, { payload }) {
      state.searchQuery = payload;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

export const addSOSTransactionsForUpdateConfirmation = payload => async (
  dispatch,
  getState
) => {
  const { data } = getState().sos;

  let trans = {};

  data.forEach(item => {
    if (payload.includes(item.id)) {
      trans = {
        ...trans,
        [item.id]: {
          passcode: getRandomNumbers(),
          isConfirmed: false
        }
      };
    }
  });

  dispatch(slice.actions.addSOSTransactionsForUpdateConfirmation(trans));
};

export const batchUpdate = () => async (dispatch, getState) => {
  const { selectedRows, data, reportDate } = getState().sos;
  const { base_url, userToken } = getState().auth;

  dispatch(slice.actions.onUpdateReportDate());

  axios({
    method: 'POST',
    url: `${base_url}/reports/tax/sales/update`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    },
    data: {
      report_date: reportDate,
      items: selectedRows
    }
  })
    .then(result => {
      const { data: resultData } = result;

      const newData = data.map(item => {
        if (resultData?.success?.includes(item.id)) {
          return {
            ...item,
            tax_ReportDate: reportDate
          };
        }

        return item;
      });

      dispatch(
        slice.actions.updateReportDate({ data: newData, result: resultData })
      );
    })
    .catch(error => {
      console.error('Failed :', error?.response?.data);
      dispatch(slice.actions.failedUpdateReportDate(error?.response?.data));
    });
};

export let cancelRequest = null;

export const getSOSReport = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const {
    dt1,
    dt2,
    unreportedDt1,
    unreportedDt2,
    transactionDate,
    referenceDate,
    reportedDate,
    brch,
    selectedReport
  } = getState().sos;

  dispatch(slice.actions.onFetchSOSReport());

  let payload = {
    dt1: selectedReport === 1 ? unreportedDt1 : dt1,
    dt2: selectedReport === 1 ? unreportedDt2 : dt2,
    transaction_date: transactionDate,
    reference_date: referenceDate,
    report_date: reportedDate
  };

  if (brch.required) {
    if ((brch?.lstBrch ?? []).length > 0) {
      payload = {
        ...payload,
        ixBrch: brch.ixBrch,
        lstBrch: brch.lstBrch
      };
    } else {
      payload = {
        ...payload,
        ixBrch: brch.ixBrch
      };
    }
  }

  axios({
    method: 'POST',
    url: `${base_url}/reports/tax/sales`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    },
    data: payload,
    cancelToken: new axios.CancelToken(function executor(c) {
      cancelRequest = c;
    })
  })
    .then(({ data }) => {
      dispatch(slice.actions.loadSOSReport(data.rep));
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return;
      }
      dispatch(
        slice.actions.loadSOSFetchError(
          e?.response?.data ?? 'Something went wrong.'
        )
      );
    });
};

const sosReducer = slice.reducer;

export const {
  onFetchSOSReport,
  changeSOSLayout,
  changeSOSDate,
  loadSOSReport,
  loadSOSFetchError,
  clearSOSReport,
  cancelSOSRequest,
  changeReportSOSPage,
  changeReportSOSLimit,
  onChangeDateFilterCheckbox,
  onSelectRows,
  updateReportDate,
  openUpdateReportDate,
  closeUpdateReportDate,
  onUpdateReportDate,
  changeReportDate,
  clearUpdateReportModalError,
  failedUpdateReportDate,
  clearUpdateResult,
  loadValidationErrors,
  clearValidationErrors,
  setSOSBranch,
  changeSOSBranch,
  changeSOSTransactionToShow,
  showSOSConfirmUpdateModal,
  closeSOSConfirmUpdateModal,
  confirmSOSTransactionUpdate,
  clearSOStransactionsForUpdateConfirmation,
  changeSelectedReport,
  changeUnreportedDate,
  changeSearchQuery,
  clear,
  restore
} = slice.actions;
export default sosReducer;
