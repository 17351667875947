import React from 'react';
import { Chip, useTheme } from '@material-ui/core';
import { JSTATUS, JSTATUS_COLOR } from 'src/constants';

const StatusChip = ({ status, size = 'medium', style = {} }) => {
  return (
    <Chip
      style={{
        backgroundColor: JSTATUS_COLOR[status],
        color: '#fff',
        fontWeight: 700,
        ...style
      }}
      label={JSTATUS[status]}
      size={size}
    />
  );
};

export default StatusChip;
