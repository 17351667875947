import { colors } from '@material-ui/core';
import { THEMES } from 'src/constants';
import { strongShadows, softShadows } from '../shadows';

export default {
  name: THEMES.ONE_DARK,
  light: {
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        navbar: '#8a85ff'
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#8a85ff'
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(0, 0, 0, 0.38)'
      }
    },
    overrides: {
      MuiAppBar: {},
      MuiButton: {},
      MuiListItem: {},
      MuiLinearProgress: {}
    },
    shadows: softShadows
  },
  dark: {
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        navbar: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#8a85ff'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    overrides: {
      MuiAppBar: {},
      MuiButton: {},
      MuiListItem: {},
      MuiLinearProgress: {}
    },
    shadows: strongShadows
  }
};
