import React from 'react';
import { Button, FormControl, Menu, MenuItem } from '@material-ui/core';
import useMenu from 'src/hooks/useMenuV2';
import { ArrowDropDown } from '@material-ui/icons';

/**
 *
 * @param {object} param
 * @param {boolean} param.disabled
 * @param {string | number} param.value
 * @param {(value: string | number) => void} param.onChange
 * @param {Array<{value: string | number, label: string}>} param.choices
 * @param {React.ReactNode} param.Icon
 * @param {{value: string, label: string}} param.keys
 * @param {string} param.color
 * @param {'contained'|'text'|'outlined'} param.variant
 * @param {'medium' | 'small'} param.size
 * @param {string} param.caption
 * @param {boolean} param.rounded
 * @param {React.CSSProperties} param.style
 * @returns
 */
const ButtonSelect = ({
  disabled = false,
  value = '',
  onChange = value => {},
  choices = [],
  Icon = null,
  keys = {
    value: 'value',
    label: 'label'
  },
  color = 'primary',
  variant = 'contained',
  size = 'medium',
  caption = '',
  rounded = false,
  style = {}
}) => {
  const [anchorEl, openMenu, closeMenu] = useMenu();

  return (
    <FormControl component="fieldset" disabled={disabled}>
      <Button
        onClick={openMenu}
        startIcon={Icon}
        endIcon={<ArrowDropDown />}
        color={color}
        variant={variant}
        size={size}
        style={{ borderRadius: rounded ? 50 : 4, ...style }}
      >
        {caption ||
          choices.find(choice => choice[keys.value] === value)?.[keys.label] ||
          ''}
      </Button>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        MenuListProps={{
          disablePadding: true
        }}
      >
        {choices.map(choice => (
          <MenuItem
            key={choice[keys.value]}
            onClick={() => {
              onChange(choice[keys.value]);
              closeMenu();
            }}
            disabled={choice?.disabled ?? false}
          >
            {choice[keys.label]}
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );
};

export default ButtonSelect;
