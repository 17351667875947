import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Carousel from 'react-material-ui-carousel';
import {
  Container,
  Typography,
  makeStyles,
  Grid,
  Box,
  useTheme
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `linear-gradient(89deg, rgb(21, 74, 189, 0.8) 0.1%, rgb(26, 138, 211, 0.8) 51.5%, rgb(72, 177, 234, 0.8) 100.2%), url(/static/images/radztech_group_picture.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    paddingTop: 60,
    paddingBottom: 60,
    [theme.breakpoints.down('md')]: {
      paddingTop: 40,
      paddingBottom: 40
    }
  },

  description: {
    color: '#fff',
    textAlign: 'justify',
    lineHeight: '1.8em'
  },
  image: {
    width: '100%',
    borderRadius: '20px 20px'
  },
  headerTitle: {
    fontSize: '2em',
    color: '#fff',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3)
  },
  carouselImage: {
    width: '100%',
    height: 'auto',
    borderRadius: 10
  }
}));

const RADZTECH_PICTURES = [
  '/static/images/about-us-pictures/9.png',
  '/static/images/about-us-pictures/1.jpg',
  '/static/images/about-us-pictures/8.jpg',
  '/static/images/about-us-pictures/2.jpg',
  '/static/images/about-us-pictures/3.jpg',
  '/static/images/about-us-pictures/4.jpg',
  '/static/images/about-us-pictures/5.jpg',
  '/static/images/about-us-pictures/6.jpg',
  '/static/images/about-us-pictures/7.jpg'
];

const About = ({ className, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography variant="h1" className={classes.headerTitle}>
          About Us
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={5} lg={6}>
            <Box
              display="flex"
              flexDirection="column"
              gridRowGap={theme.spacing(3)}
            >
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.description}
              >
                Radztech was conceived when software providers were still scarce
                and development of computer software by developers from Metro
                Manila were costly.
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.description}
              >
                It started when its founder, Rado D. Racimo, was still in
                college - creating a system for his thesis entitled Computerized
                Enrollment System of Our Lady of the Pillar College – Cauayan,
                his alma mater. From the thesis proposal in Year 2000 which
                later turned out to be the first large scale project delivered
                successfully, it is now one of the main products provided by
                Radztech.
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.description}
              >
                Just after his graduation in 2001, he then started marketing his
                software and services as a computer programmer. In year 2004, he
                passed the 2004 ICT Proficiency Examination in Programming
                administered by the National Computer Center and the Civil
                Service Commission. He was one of the only six passers among 80
                examinees nationwide, ranked 2nd and the lone passer in Region
                II.
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.description}
              >
                Operating for at least a decade now, Radztech has already helped
                more than 20 companies under different industries in Region I,
                II and IV.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6}>
            <Carousel
              autoPlay
              animation="fade"
              duration={1000}
              stopAutoPlayOnHover={false}
              indicators={false}
              swipe
            >
              {RADZTECH_PICTURES.map(img => (
                <img
                  key={img}
                  alt="gallery"
                  src={img}
                  className={classes.carouselImage}
                />
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

About.propTypes = {
  className: PropTypes.string
};

export default About;
