import React from 'react';
import { red, orange } from '@material-ui/core/colors';

import {
  Box,
  Typography,
  makeStyles,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  featureDesc: {
    fontWeight: 500
  },
  featureHeaderTitle: {
    color: orange[900],
    fontWeight: 900
  },
  featureValue: {
    fontWeight: 900
  },
  caption: {
    marginTop: '1em',
    color: red[500]
  }
}));

const FeatureItem = ({ description, value, indent = 0, parent }) => {
  const classes = useStyles();

  return (
    <ListItem disableGutters dense>
      <ListItemText
        classes={{
          root: classes.listItemText
        }}
        primary={<Box ml={`${indent}em`}> {description} </Box>}
        primaryTypographyProps={{
          color: 'textPrimary',
          variant: 'caption',
          className: parent ? classes.featureHeaderTitle : classes.featureDesc
        }}
      />
      <ListItemSecondaryAction>
        <Box
          component={Typography}
          variant="caption"
          color="textSecondary"
          className={classes.featureValue}
          aling="right"
        >
          {value}
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default FeatureItem;
