import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

export const addToWatchList = async ({ jid, base_url, userToken }) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${base_url}/trans/jv/${jid}/watch`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': userToken
      }
    });

    return {
      success: true,
      data,
      msg: 'Transaction successfully added to your watched transactions.'
    };
  } catch (error) {
    if (error?.response?.data?.msg) {
      return {
        success: false,
        msg: error?.response?.data?.msg
      };
    }

    if (error?.response?.data) {
      return {
        success: false,
        msg: error?.response?.data
      };
    }

    return {
      success: false,
      msg: 'Failed to add into your watch list. Please try again.'
    };
  }
};

export const removeToWatchList = async ({ jid, base_url, userToken }) => {
  try {
    const { data } = await axios({
      method: 'DELETE',
      url: `${base_url}/trans/jv/${jid}/watch`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': userToken
      }
    });

    return {
      success: true,
      data,
      msg: 'Transaction successfully removed to your watched transactions.'
    };
  } catch (error) {
    if (error?.response?.data?.msg) {
      return {
        success: false,
        msg: error?.response?.data?.msg
      };
    }

    if (error?.response?.data) {
      return {
        success: false,
        msg: error?.response?.data
      };
    }

    return {
      success: false,
      msg: 'Failed to add into your watch list. Please try again.'
    };
  }
};

const useWatch = () => {
  const [watchList, setWatchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const { base_url, userToken } = useSelector(({ auth }) => auth);

  const getWatchList = ({ initialLoadingIndicator = true }) => {
    setLoading(initialLoadingIndicator);
    setError('');
    axios({
      method: 'GET',
      url: `${base_url}/trans/watch-list`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': userToken
      }
    })
      .then(({ data }) => {
        setWatchList(data?.items);
        setLoading(false);
      })
      .catch(({ error }) => {
        setLoading(false);
        setError('Failed to load watch list.');
      });
  };

  const refresh = () => {
    getWatchList({ initialLoadingIndicator: true });
  };

  const filter = () => {
    if (query.trim() === '') return watchList;

    return watchList.filter(data => {
      let matches = true;

      const properties = ['JCd', 'Name', 'RefNo', 'jid'];
      let containsQuery = false;

      properties.forEach(property => {
        if (
          data[property]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }

      return matches;
    });
  };

  const onLimitChange = newLimit => {
    setLimit(newLimit);
    setPage(0);
  };

  const onPageChange = newPage => {
    setPage(newPage);
  };

  useEffect(() => {
    getWatchList({ initialLoadingIndicator: true });
  }, []);

  return {
    allWatchList: watchList,
    watchList: filter(),
    loading,
    error,
    limit,
    page,
    query,
    refresh,
    setQuery,
    filter,
    onLimitChange,
    onPageChange
  };
};

export default useWatch;
