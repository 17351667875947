import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {};

const slice = createSlice({
  name: 'bizState',
  initialState: INITIAL_STATE,
  reducers: {
    saveBizState(state, { payload: { biz_uuid, data } }) {
      return { ...state, [biz_uuid]: data };
    }
  }
});

const bizStateReducer = slice.reducer;

export const { saveBizState } = slice.actions;
export default bizStateReducer;
