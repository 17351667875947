import React from 'react';
import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core';
import { CheckCircleOutline, CheckCircle } from '@material-ui/icons';
import { green, grey } from '@material-ui/core/colors';

const GreenCheckbox = withStyles({
  root: {
    color: grey[500],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox {...props} />);

/**
 *
 * @param {CheckboxProps} props
 * @returns
 */
const CustomCheckbox = props => {
  return (
    <GreenCheckbox
      icon={<CheckCircleOutline />}
      checkedIcon={<CheckCircle />}
      {...props}
    />
  );
};

export default CustomCheckbox;
