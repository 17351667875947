import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Drawer,
  makeStyles,
  List,
  MenuItem,
  ListItemIcon,
  Typography
} from '@material-ui/core';
import { logoutPortal } from 'src/redux/slices/auth';
import { setPortalMeta } from 'src/redux/slices/portal';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SIDEBAR_WIDTH } from 'src/constants';

import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: SIDEBAR_WIDTH
  }
}));

function SideBar({ isCollapsed = false, toggleSideBar = () => null }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cdBiz, cdPortal } = useSelector(state => state.auth);

  const handleShowHome = () => {
    navigate('/portal/welcome');
    toggleSideBar();
  };

  const handleShowProfile = () => {
    navigate('/portal/profile');
    toggleSideBar();
  };

  const handleLogout = async () => {
    dispatch(logoutPortal());
    dispatch(setPortalMeta({}));
    navigate(`/portal/${cdBiz}/${cdPortal}/login`);
  };

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={isCollapsed}
      classes={{ paper: classes.mobileDrawer }}
      onClose={toggleSideBar}
    >
      <PerfectScrollbar>
        <List>
          <MenuItem onClick={handleShowHome}>
            <ListItemIcon>
              <HomeIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">Home</Typography>
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.link
            }}
            onClick={handleShowProfile}
          >
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">Profile</Typography>
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.link
            }}
            onClick={handleLogout}
          >
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">Logout</Typography>
          </MenuItem>
        </List>
      </PerfectScrollbar>
    </Drawer>
  );
}

export default SideBar;
