import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { orange } from '@material-ui/core/colors';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Card,
  Divider,
  CardContent,
  CardActions,
  Button,
  Link
} from '@material-ui/core';
import { createBrowserHistory } from 'history';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 100,
    paddingBottom: 100
  },

  mainTitle: {
    fontSize: '2.5rem',
    fontWeight: 800
  },
  smallTitle: {
    color: orange[500]
  },

  priceFont: {
    fontSize: '2.5rem',
    fontWeight: 900,
    marginRight: '.2em'
  },
  priceColor: {
    color: orange[500]
  },
  notes: {
    marginTop: '.5em'
  },
  cardActions: {
    flexDirection: 'column'
  },
  card: {
    height: '100%'
  },
  cardBody: {
    height: 'calc(100% - 64px)'
  },
  question: {
    fontSize: '1.5rem'
  }
}));

const PriceItem = ({ description, price, ...rest }) => {
  return (
    <Box display="flex" justifyContent="space-between" {...rest}>
      <Typography variant="h5" color="textSecondary">
        {description}
      </Typography>
      <Typography variant="h5">{price}</Typography>
    </Box>
  );
};

const Pricing = ({ className, onChangeFocus, ...rest }) => {
  const classes = useStyles();

  const history = createBrowserHistory();

  const handleGoto = link => {
    history.push(link);
    history.go();
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="sm">
        <Box mt={8}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h1" color="textSecondary">
                Pricing
              </Typography>

              <PriceItem
                description="Transaction type per user"
                price=""
                mt={4}
              />

              <PriceItem
                description="Sales Collection and Invoicing"
                price="Php 1,000.00"
                my={2}
              />
              <PriceItem description="Paid Purchases" price="1,000.00" my={2} />
              <PriceItem
                description="Cash Disbursements"
                price="1,000.00"
                my={2}
              />
              <Divider />

              <Box display="flex" justifyContent="space-between" mt={2}>
                <Typography variant="h5" color="primary">
                  Minimum Subscription Fee
                </Typography>

                <Typography variant="h5" color="primary">
                  Php 3,000.00
                </Typography>
              </Box>
              <Typography
                variant="h6"
                color="textSecondary"
                display="block"
                className={classes.notes}
              >
                Additional fees apply on other features per client request
              </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                fullWidth
                color="primary"
                size="large"
                variant="contained"
                onClick={() => {
                  handleGoto('/contact-us');
                }}
              >
                Inquire
              </Button>
            </CardActions>
          </Card>
          <Box mt={3} display="flex" justifyContent="center">
            <Typography
              color="textSecondary"
              display="block"
              className={classes.question}
            >
              Need more than just tax?{' '}
              <Link
                component="button"
                onClick={() => {
                  handleGoto('/app');
                }}
                variant="body1"
                className={classes.question}
              >
                Click here!
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

Pricing.propTypes = {
  className: PropTypes.string
};

export default Pricing;
