import React from 'react';
import Chip from '@material-ui/core/Chip';
import getColorFromMUI from 'src/utils/getColorFromMUI';

const CustomChip = ({ label = '', color = 'default', style = {}, ...rest }) => {
  return (
    <Chip
      label={label}
      size="small"
      color="primary"
      style={{ ...style, backgroundColor: getColorFromMUI({ color }) }}
      {...rest}
    />
  );
};

export default CustomChip;
