import { useEffect, useState } from 'react';
import axios from 'axios';
import Base64 from 'Base64';

const useInviteUserRegistration = ({ inviteCode }) => {
  const [inviteDetails, setInviteDetails] = useState(null);
  const [
    isGetInvitationDetailFailed,
    setIsGettingInviteDetailsFailed
  ] = useState(false);
  const [isGettingInviteDetails, setIsGettingInviteDetails] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(null);
  const [saveFailed, setSaveFailed] = useState(null);
  const [password, setPassword] = useState('');

  const [timer, setTimer] = useState(0);

  const [isResending, setIsResending] = useState(false);
  const [resendFailed, setResendFailed] = useState(null);

  const [isActivating, setIsActivating] = useState(false);
  const [activationSuccess, setActivationSuccess] = useState(null);
  const [activationFailed, setActivationFailed] = useState(null);

  useEffect(() => {
    setIsGettingInviteDetails(true);
    setIsGettingInviteDetailsFailed(false);
    setInviteDetails(null);

    axios({
      method: 'GET',
      url: `/api/registry/user/invite/${inviteCode}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(({ data }) => {
        setInviteDetails(data);
        setIsGettingInviteDetails(false);
      })
      .catch(error => {
        setIsGettingInviteDetailsFailed(true);
        setIsGettingInviteDetails(false);
      });
  }, []);

  const register = ({ userInfo }) => {
    setIsSaving(true);

    axios({
      method: 'POST',
      url: '/api/registry/user/invite/submit',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${Base64.btoa(
          `${userInfo?.username}:${userInfo?.password}`
        )}`
      },
      data: {
        'invite-code': inviteCode,
        user: {
          ...userInfo
        }
      }
    })
      .then(({ data }) => {
        setIsSaving(false);
        setSaveSuccess(data);
        setSaveFailed(null);
        setPassword(userInfo?.password);
        setTimer(30);
      })
      .catch(error => {
        setIsSaving(false);
        setSaveFailed(error?.response?.data);
      });
  };

  const resendOTP = async ({ username }) => {
    setIsResending(true);
    setResendFailed(null);
    setTimer(0);
    try {
      await axios({
        method: 'POST',
        url: '/api/registry/biz/user/otp-resend',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          username
        }
      });

      setIsResending(false);
      setTimer(30);
    } catch (error) {
      setIsResending(false);
      setResendFailed('Failed to resend code. Please try again.');
    }
  };

  const activateCode = async code => {
    setIsActivating(true);

    await axios({
      method: 'POST',
      url: '/api/registry/biz/user/otp-verify',
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Basic ${Base64.btoa(
          `${saveSuccess?.user?.username}:${password}`
        )}`
      },
      data: {
        username: saveSuccess?.user?.username,
        code
      }
    })
      .then(({ data }) => {
        setActivationSuccess(data);
        setIsActivating(false);
      })
      .catch(e => {
        setIsActivating(false);
        setActivationFailed('Activation failed. Please try again.');
      });
  };

  const onChangeTimer = time => {
    setTimer(time);
  };

  return {
    inviteDetails,
    isSaving,
    saveSuccess,
    saveFailed,
    isGettingInviteDetails,
    isGetInvitationDetailFailed,
    password,
    timer,
    isResending,
    resendFailed,
    isActivating,
    activationSuccess,
    activationFailed,
    register,
    resendOTP,
    onChangeTimer,
    activateCode
  };
};

export default useInviteUserRegistration;
