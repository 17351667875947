import axios from 'axios';

class Fetch {
  #base_url;
  #headers;

  addBaseUrl(url) {
    return this.#base_url + url;
  }

  successFormatter(response) {
    return {
      success: true,
      data: response.data
    };
  }

  errorFormatter(error) {
    return {
      success: false,
      error
    };
  }

  constructor(base_url, headers) {
    this.#base_url = base_url;
    this.#headers = headers;
  }

  async axiosGet(url, config) {
    const result = await axios
      .get(url, config)
      .then(response => {
        return this.successFormatter(response);
      })
      .catch(error => {
        return this.errorFormatter(error);
      });

    return result;
  }

  async axiosPost(url, data, config) {
    const result = await axios
      .post(url, data, config)
      .then(response => {
        return this.successFormatter(response);
      })
      .catch(error => {
        return this.errorFormatter(error);
      });

    return result;
  }

  async axiosPut(url, data, config) {
    const result = await axios
      .put(url, data, config)
      .then(response => {
        return this.successFormatter(response);
      })
      .catch(error => {
        return this.errorFormatter(error);
      });

    return result;
  }

  async axiosDelete(url, data, config) {
    const result = await axios
      .delete(url, { ...config, data })
      .then(response => {
        return this.successFormatter(response);
      })
      .catch(error => {
        return this.errorFormatter(error);
      });

    return result;
  }

  get(url, config = {}) {
    const urlWithBase = this.addBaseUrl(url);
    return this.axiosGet(urlWithBase, {
      headers: this.#headers,
      ...config
    });
  }

  post(url, data = {}, config = {}) {
    const urlWithBase = this.addBaseUrl(url);
    return this.axiosPost(urlWithBase, data, {
      headers: this.#headers,
      ...config
    });
  }

  put(url, data = {}, config = {}) {
    const urlWithBase = this.addBaseUrl(url);
    return this.axiosPut(urlWithBase, data, {
      headers: this.#headers,
      ...config
    });
  }

  delete(url, data = {}, config = {}) {
    const urlWithBase = this.addBaseUrl(url);
    return this.axiosDelete(urlWithBase, data, {
      headers: this.#headers,
      ...config
    });
  }
}

export default Fetch;
