import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import ViewLogs from 'src/components/ViewLogs';
import { ViewChanges } from 'src/components/ViewChanges';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import Avatar from '@material-ui/core/Avatar';
import ClearIcon from '@material-ui/icons/Clear';
import { red, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core';
import Label from 'src/components/Label';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { useConfirmDialog } from './ConfirmDialog';
import { useNotif, useRequest } from 'src/hooks';
import { setAsyncTimeout } from 'src/helpers';
import { FormViewError } from './FormViewError';

const useStyles = makeStyles(theme => ({
  actionButtons: {
    display: 'block'
  },
  textArea: {
    minHeight: '200px'
  },
  cancelButton: {
    color: red[500]
  },
  executeButton: {
    color: green[500]
  },
  avatarAssignedTo: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
}));

const mapStatus = {
  '0': { label: 'Open', color: 'primary' },
  '1': { label: 'Executed', color: 'success' },
  '2': { label: 'Cancelled', color: 'warning' },
  '3': { label: 'Failed', color: 'error' }
};

const AssignedTo = ({ user = '' }) => {
  const classes = useStyles();
  return (
    <Box mt={2} mb={2} display="flex">
      <Typography
        display="block"
        variant="caption"
        style={{ fontWeight: '500', opacity: 0.6 }}
      >
        Assigned to :
      </Typography>
      <Tooltip title={user}>
        <Avatar
          className={classes.avatarAssignedTo}
          alt={user}
          src={`/api/images/user/${user}/avatar.jpg`}
        >
          {(user[0] ?? '').toLocaleUpperCase()}
        </Avatar>
      </Tooltip>
    </Box>
  );
};

const Content = ({ ...data }) => {
  const classes = useStyles();

  const showTransaction = () => {
    window.open('/app/trans/' + data.ARQDetails.key_id, '_blank');
  };

  if (data.loading)
    return (
      <>
        <DialogTitle id="max-width-dialog-title" disableTypography>
          <Typography variant="h4">Please wait</Typography>
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '2em'
          }}
        >
          <CircularProgress />
        </DialogContent>
      </>
    );

  if (data.error.status) {
    return (
      <>
        <DialogTitle id="max-width-dialog-title" disableTypography>
          <Typography variant="h4">Error</Typography>
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="h4" color="error">
            {data.error.msg}
          </Typography>
        </DialogContent>
        <DialogActions></DialogActions>
      </>
    );
  }

  return (
    <>
      <DialogTitle id="max-width-dialog-title" disableTypography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex">
            <Avatar
              src={`/api/images/user/${data?.ARQDetails?.request_by}/avatar.jpg`}
            >
              {(data?.ARQDetails?.request_by[0] ?? '').toLocaleUpperCase()}
            </Avatar>

            <Box ml={2}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                {data.ARQDetails?.request_by}
              </Typography>
              <Typography variant="caption" style={{ opacity: 0.6 }}>
                {moment(new Date(data.ARQDetails?.request_date)).fromNow()}{' '}
                &#183;{' '}
                <Label
                  color={mapStatus[data?.ARQDetails?.status ?? 0].color ?? ''}
                >
                  {mapStatus[data?.ARQDetails?.status ?? 0].label ?? ''}
                </Label>
              </Typography>
            </Box>
          </Box>

          <IconButton
            size="small"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={data.handleClose}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box mb={2} mt={1}>
          <Typography variant="body1">
            {data.ARQDetails?.sARQ} ({data.ARQDetails?.ixARQ})
          </Typography>
        </Box>

        <Box mt={1} mb={3}>
          <Typography variant="body2" display="block">
            {data.ARQDetails?.request_explanation}
          </Typography>
        </Box>

        {data?.showAssignedTo ? (
          <AssignedTo user={data?.ARQDetails?.assigned_to ?? ''} />
        ) : null}

        {data?.showRemarks && data.ARQDetails?.status === 0 ? (
          <Box mb={1}>
            <TextField
              variant="outlined"
              label="Remarks"
              placeholder="Write something..."
              multiline
              fullWidth
              rows={3}
              value={data.remarks}
              onChange={e => data.setRemarks(e.target.value)}
              inputProps={{
                style: {
                  minWidth: '200px'
                }
              }}
            />
          </Box>
        ) : null}

        {data.errorSave.status && (
          <Box mt={2}>
            <Alert
              severity="error"
              style={{
                whiteSpace: 'pre-wrap'
              }}
            >
              {data.errorSave.msg}
            </Alert>
          </Box>
        )}

        {data.successSave.status && (
          <Box mt={2}>
            <Alert severity="success">{data.successSave.msg}</Alert>
          </Box>
        )}
      </DialogContent>

      <DialogActions className={classes.actionButtons} disableSpacing>
        {!data.successSave.status ? (
          <Box display="flex" justifyContent="space-between" mb={1}>
            {data?.showCancel ? (
              <Button
                fullWidth
                disabled={data.executing}
                onClick={() => data.executeCommand({ type: 'cancel' })}
                endIcon={<HighlightOffOutlinedIcon />}
                color="secondary"
                className={classes.cancelButton}
              >
                Cancel Request
              </Button>
            ) : null}

            {data?.showExecute ? (
              <Button
                disabled={data.executing}
                fullWidth
                onClick={() => data.executeCommand({ type: 'execute' })}
                endIcon={<CheckCircleOutlineOutlinedIcon />}
                className={classes.executeButton}
              >
                Execute
              </Button>
            ) : null}
          </Box>
        ) : null}
        <Divider />
        <Box display="flex" justifyContent="space-evenly" mt={1}>
          {data?.showDetails ? (
            <Button
              color="primary"
              onClick={showTransaction}
              endIcon={<OpenInNewOutlinedIcon />}
            >
              Details
            </Button>
          ) : null}
          {data?.showChanges ? (
            <Button
              color="primary"
              onClick={data.openChanges}
              endIcon={<SyncOutlinedIcon />}
            >
              Changes
            </Button>
          ) : null}
          {data?.showLogs ? (
            <Button
              color="primary"
              onClick={data.openLogs}
              endIcon={<UpdateOutlinedIcon />}
            >
              Logs
            </Button>
          ) : null}
        </Box>
      </DialogActions>
    </>
  );
};

const ExecuteRequest = ({
  open,
  handleClose,
  ixARQ,
  callBack = e => null,
  showExecute = true,
  showCancel = true,
  showRemarks = false,
  showAssignedTo = false,
  showDetails = true,
  showLogs = true,
  showChanges = true
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ status: false, msg: '' });
  const [ARQDetails, setARQDetails] = useState(null);
  const [executing, setExecuting] = useState(false);
  const [errorSave, setErrorSave] = useState({ status: false, msg: '' });
  const [successSave, setSuccessSave] = useState({ status: false, msg: '' });
  const [remarks, setRemarks] = useState('');
  const [viewLogs, setViewlogs] = useState(false);
  const [viewChanges, setViewChanges] = useState(false);

  const request = useRequest();
  const { prompt } = useConfirmDialog();
  const notif = useNotif();

  const getARQDetails = async cancel => {
    setLoading(true);
    setError({
      status: false,
      msg: ''
    });

    const result = await request.get(`/arq/${ixARQ}`, {}, cancel);

    if (result.success) {
      setLoading(false);
      setARQDetails(result.data);
      return;
    }

    setLoading(false);
    setError({ status: true, msg: result.errorMessage });
    setARQDetails(null);
  };

  const deleteTransaction = async ({ loadOn, loadOff, closeModal }) => {
    loadOn();
    const { success, error } = await request.delete(
      `/trans/jv/${ARQDetails.key_id}`,
      {
        confirm_delete: {
          status_approved: true
        }
      }
    );
    loadOff();
    closeModal();

    return await setAsyncTimeout(() => {
      if (success) {
        notif.success(' Transaction deleted successfully');
        return true;
      }

      if (!success && error.status === 404) {
        return true;
      }

      prompt({
        title: '',
        body: (
          <>
            <Typography variant="h1" align="center" color="error">
              Failed
            </Typography>
            {error?.data?.jv?.errors && (
              <FormViewError errorList={error?.data?.jv?.errors} useDialog />
            )}
          </>
        ),
        hideCancel: true,
        okText: 'OK',
        okProps: {
          color: 'primary',
          variant: 'contained'
        }
      });
      return false;
    }, 300);
  };

  const executeCommand = async ({ type }) => {
    let execute_command = true;

    if (type === 'execute' && ARQDetails.key_type === 'jv_delete') {
      await prompt({
        title: <Typography variant="h4">Delete Transaction</Typography>,
        body: (
          <Typography variant="body1">
            Are you sure you want to delete this transaction?
          </Typography>
        ),
        onOk: async ({ loadOn, loadOff, closeConfimationModal }) => {
          execute_command = await deleteTransaction({
            loadOn,
            loadOff,
            closeModal: closeConfimationModal
          });
        },
        onCancel: ({ closeConfimationModal }) => {
          execute_command = false;
          closeConfimationModal();
        },
        okText: 'Delete',
        okProps: {
          color: 'primary',
          variant: 'contained'
        },
        autoCloseOnOk: false
      });
    }

    if (!execute_command) {
      return;
    }

    setExecuting(true);
    setSuccessSave({ status: false, msg: '' });
    setErrorSave({ status: false, msg: '' });

    const result = await request.put(`/arq/${ixARQ}/${type}`, {
      Remarks: remarks
    });

    if (result.success) {
      setExecuting(false);
      setSuccessSave({
        status: true,
        msg: `${type === 'execute' ? 'Executed' : 'Cancelled'}`
      });
      callBack(result.data);
      return;
    }

    let msg = `${result.errorMessage}\n`;
    if ((result?.error?.data?.msg_detail ?? []).length > 0) {
      (result?.error?.data?.msg_detail ?? []).forEach(item => {
        msg += `${item}\n`;
      });
    }

    if (ARQDetails.key_type === 'jv_delete') {
      await request.post(`/arq/${ixARQ}/add-log`, {
        log_msg: msg
      });
    }

    setExecuting(false);
    setErrorSave({ status: true, msg });
  };

  const close = () => {
    setLoading(false);
    setError({ status: false, msg: '' });
    setARQDetails(null);
    setExecuting(false);
    setErrorSave({ status: false, msg: '' });
    setRemarks('');
    setSuccessSave({ status: false, msg: '' });
    handleClose();
  };

  const data = {
    loading,
    error,
    ARQDetails,
    executing,
    errorSave,
    successSave,
    remarks,
    showExecute,
    showCancel,
    showAssignedTo,
    showRemarks,
    showDetails,
    showChanges,
    showLogs,
    handleClose: close,
    openLogs: () => setViewlogs(true),
    openChanges: () => setViewChanges(true),
    setRemarks,
    executeCommand
  };

  useEffect(() => {
    let cancel = () => null;

    if (open) {
      getARQDetails(function(c) {
        cancel = c;
      });
    }

    return () => {
      cancel();
    };
  }, [open]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={open}
      onClose={close}
      aria-labelledby="max-width-dialog-title"
    >
      <Content {...data} />
      {ARQDetails ? (
        <>
          <ViewLogs
            url={`/arq/${ARQDetails.ixARQ}/logs`}
            title="Logs"
            openTransactionDialog={viewLogs}
            setOpenTransactionDialog={() => setViewlogs(false)}
          />
          <ViewChanges
            url={`/api/arq/${ARQDetails.ixARQ}/view-changes`}
            open={viewChanges}
            handleClose={() => setViewChanges(false)}
          />
        </>
      ) : null}
    </Dialog>
  );
};

export default ExecuteRequest;
