import NotFoundView from 'src/views/errors/NotFoundView';
import { Navigate } from 'react-router-dom';
import RadztechLayout from 'src/layouts/RadztechLayout';
import Radztech from 'src/views/landingPage/Radztech';
import Contact from 'src/views/landingPage/iBFRS/Contact';
import Terms from 'src/views/landingPage/iBFRS/Terms';
import BenefitsPage from 'src/views/landingPage/iBFRS/BenefitsPage';
import MainPage from 'src/views/landingPage/IBFRS3/MainPage';
import IBFRS3Layout from 'src/layouts/IBFRS3Layout';
import appLoginRoutes from './appLoginRoutes';
import portalLoginRoutes from './portalLoginRoutes';

const ibfrsGuestRoutes = [
  {
    path: '/login',
    element: Navigate,
    elementProps: { to: '/app/login' }
  },
  {
    path: '/register',
    element: Navigate,
    elementProps: { to: '/app/register' }
  },
  {
    path: '',
    element: IBFRS3Layout,
    children: [
      { path: '/', element: MainPage },
      { path: 'contact-us', element: Contact },
      {
        path: 'register',
        element: Navigate,
        elementProps: { to: '/app/register' }
      },
      { path: 'benefits', element: MainPage },
      { path: 'benefits/more', element: BenefitsPage },
      { path: 'anchors', element: MainPage },
      { path: 'testimonials', element: MainPage },
      { path: 'terms', element: Terms },
      { path: '404', element: NotFoundView },
      ...appLoginRoutes,
      ...portalLoginRoutes,
      { path: '*', element: Navigate, elementProps: { to: '/404' } }
    ]
  },
  {
    path: '/',
    element: RadztechLayout,
    children: [{ path: '/radztech', element: Radztech }]
  }
];

export default ibfrsGuestRoutes;
