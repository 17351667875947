import React from 'react';
import { Box, MenuItem, Avatar, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SelectWithSearch from './SelectWithSearch';

const CustomMenuItem = ({ value, description, username, ...props }) => {
  return (
    <MenuItem {...props}>
      <Box display="flex" gridGap="1em" alignItems="center">
        <Avatar
          src={`/api/images/user/${username}/avatar.jpg`}
          variant="circle"
          style={{
            height: '2em',
            width: '2em'
          }}
        />
        <Box>
          <Typography variant="body1">{description}</Typography>
          <Typography variant="caption" color="textSecondary">
            {username}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  );
};

const SearchUser = ({ value = '', onChange, disabled = false }) => {
  /**
   * @type {{users: user[]}}
   */
  const { users = [] } = useSelector(state => state.bizMeta);
  const formattedUsers = users.map(user => ({
    ...user,
    value: user.ixUser,
    description: user.FName + ' ' + user.LName
  }));

  return (
    <SelectWithSearch
      label="User"
      value={value}
      options={formattedUsers}
      disabled={disabled}
      searchKeys={['UserID', 'FName', 'LName']}
      onChange={onChange}
      CustomMenuItem={CustomMenuItem}
    />
  );
};

export default SearchUser;
