import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQrcode,
  faBox,
  faStore,
  faLink
} from '@fortawesome/free-solid-svg-icons';
import { isArray } from 'lodash';
import { blue, green, orange, red } from '@material-ui/core/colors';

export const Icons = {
  faStore: <FontAwesomeIcon icon={faStore} size="lg" />,
  faBox: <FontAwesomeIcon icon={faBox} size="lg" color="gray" />,
  faQrcode: <FontAwesomeIcon icon={faQrcode} size="lg" />,
  faLink: <FontAwesomeIcon icon={faLink} size="lg" />
};

export const jStatuses = [
  'For Approval',
  'Approved',
  'Disapproved',
  'Cancelled'
];

export const statusColors = [blue[400], green[400], red[400], orange[400]];

export const getsJStatus = index => jStatuses[index];

export const getStatusColor = index => statusColors[index];

export const getWFStatus = (jsonWF, ixStatus) => {
  if (isArray(jsonWF)) {
    const index = jsonWF.findIndex(item => +item.ixStatus === +ixStatus);

    return jsonWF?.[index]?.sStatus ?? 'n/a';
  }

  return jsonWF?.[ixStatus]?.sStatus ?? 'n/a';
};

export const getWFColor = (jsonWF, ixStatus) => {
  if (isArray(jsonWF)) {
    const index = jsonWF.findIndex(item => +item.ixStatus === +ixStatus);

    return jsonWF?.[index]?.color ?? 'n/a';
  }

  return jsonWF?.[ixStatus]?.color ?? 'n/a';
};

export const formatData = (numberFields, dateFields, value, fld) => {
  if (numberFields.some(numFld => numFld === fld))
    return typeof value === 'undefined' || value === null || value === ''
      ? ''
      : numeral(value).format('0,0.00');

  if (dateFields.some(numFld => numFld === fld))
    return typeof value === 'undefined' || value === null || value === ''
      ? ''
      : moment(value).format('MM/DD/YYYY');

  return value;
};

export const dataPosition = (numberFields, fld) => {
  if (numberFields.some(numFld => numFld === fld)) return 'right';
  return 'left';
};

export const formatDate = (dateFields, value, fld) => {
  if (dateFields.some(numFld => numFld === fld))
    return typeof value === 'undefined' || value === null || value === ''
      ? value
      : moment(value).format('MM/DD/YYYY');

  return value;
};

export const isObjectEmpty = obj => {
  for (var i in obj) return false;
  return true;
};

export const mapColor = color => {
  const colors = {
    blue: { background: '#2196f3', color: '#fff' },
    orange: { background: '#ff9800', color: '#fff' },
    red: { background: '#f44336', color: '#fff' },
    yellow: { background: '#ffeb3b', color: '#000' }
  };

  return colors[color]
    ? colors[color]
    : { background: '#E0E0E0', color: '#000' };
};

export const showNewlines = text => {
  const toRenderHtml = text.replace(/(\r\n|\n|\r)/gm, ' <br> ');
  return {
    __html: toRenderHtml
  };
};

export const getDateFormat = dayString => {
  const date =
    typeof dayString === 'undefined' || dayString === ''
      ? new Date()
      : new Date(dayString);

  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day} ${new Date().toTimeString()}`;
};

export const setFieldValue = (type, defaultValue, value) => {
  if (typeof value === 'undefined' || value === '' || value === null) {
    return type === 'date' ? getDateFormat() : defaultValue;
  }

  return value;
};

export const getIcon = ({ obj, key, defaultIcon }) => {
  if (obj?.jv?._rm?.sections[key]?.icon) {
    const ToRenderComponent = Icons[obj?.jv?._rm?.sections[key]?.icon];
    return ToRenderComponent;
  }
  return defaultIcon;
};

export const getLabel = ({ obj, key, defaultLabel }) => {
  return obj?.jv?._rm?.sections[key]?.label
    ? obj?.jv?._rm?.sections[key]?.label
    : defaultLabel;
};
