import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  repList: [],
  selectedRep: null,
  data: null,
  dateRange: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  page: 0,
  limit: 50,
  brch: {
    required: false,
    ixBrch: 0,
    label: ''
  },
  ixBrch: 0,
  prodCatList: [],
  prodCatSubList: [],
  WHList: null,
  repState: {
    ixProdCat: 0,
    ixProdCatSub: 0,
    ixWH: 0
  },
  error: false,
  errorMsg: ''
};

const slice = createSlice({
  name: 'customReport',
  initialState: INITIAL_STATE,
  reducers: {
    setAdditionalFilterFieldValue(state, action) {
      state.selectedRep = {
        ...state.selectedRep,
        jsonSettings: {
          ...state.selectedRep.jsonSettings,
          filterV2: action.payload
        }
      };
    },
    setReportList(state, action) {
      return { ...state, ...action.payload };
    },
    resetState(state) {
      return {
        ...INITIAL_STATE,
        repList: state.repList,
        prodCatList: state.prodCatList,
        prodCatSubList: state.prodCatSubList,
        WHList: state.WHList,
        brch: {
          ...state.brch,
          ixBrch: 0
        }
      };
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setReport(state, action) {
      let ixWH = 0;
      let ixProdCat = 0;

      if (
        state.selectedRep?.jsonSettings?.showWH == 1 &&
        state.WHList.length > 0
      )
        ixWH = state.ixWH[0]['ixWH'];

      if (
        state.selectedRep?.jsonSettings?.showProdCat == 1 &&
        state.prodCatList.length > 0
      ) {
        ixProdCat = state.prodCatList[0]['ixProdCat'];
      }

      state.selectedRep = action.payload;
      state.error = false;
      state.loading = false;
      state.data = null;
      state.repState = {
        ixWH,
        ixProdCat,
        ixProdCatSub: 0
      };
      state.dateRange = {
        dt1: null,
        dt2: null,
        textValue: ''
      };
    },
    setReportState(state, action) {
      state.repState = {
        ...state.repState,
        ...action.payload
      };
    },
    setReportData(state, action) {
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: false
      };
    },
    setError(state, action) {
      return {
        ...state,
        ...action.payload
      };
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
      state.page = 0;
    },
    setBrch(state, action) {
      state.ixBrch = action.payload;
    },
    cancelRequest(state) {
      state.loading = false;
    },
    clear(state, action) {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const customReportReducer = slice.reducer;

export const {
  setAdditionalFilterFieldValue,
  setReportList,
  resetState,
  setDateRange,
  setReport,
  setReportState,
  setReportData,
  setError,
  setLoading,
  setPage,
  setLimit,
  setBrch,
  cancelRequest,
  restore,
  clear
} = slice.actions;
export default customReportReducer;
