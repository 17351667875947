import React, {
  useRef,
  useState,
  useEffect,
  createContext,
  useCallback
} from 'react';

const duration = 3 * 1000;
let animationEnd;

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

function getAnimationSettings(originXA, originXB) {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 0,
    particleCount: 150,
    origin: {
      x: randomInRange(originXA, originXB),
      y: Math.random() - 0.2
    }
  };
}

const ConfettiContext = createContext({
  start: () => {},
  getInstance: () => {}
});

const ConfettiProvider = ({ children }) => {
  const refAnimationInstance = useRef(null);
  const [intervalId, setIntervalId] = useState();

  const getInstance = useCallback(instance => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
      refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
    }

    var timeLeft = animationEnd - Date.now();
    if (timeLeft <= 0) stop();
  }, []);

  const start = useCallback(() => {
    if (!intervalId) {
      animationEnd = Date.now() + duration;
      setIntervalId(setInterval(nextTickAnimation, 400));
    }
  }, [intervalId, nextTickAnimation]);

  const stop = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
  }, [intervalId]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  return (
    <ConfettiContext.Provider value={{ start, getInstance }}>
      {children}
    </ConfettiContext.Provider>
  );
};

export { ConfettiProvider };
export default ConfettiContext;
