import React from 'react';

import {
  Box,
  Typography,
  makeStyles,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { green } from '@material-ui/core/colors';
import useReveal, { Animated } from 'src/hooks/useReveal';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  description: {
    lineHeight: '1.3rem',
    marginTop: theme.spacing(2)
  },
  headerTitle: {
    fontWeight: 900,
    fontSize: '2.8em',
    color: '#FF7704',
    [theme.breakpoints.down('md')]: {
      fontSize: '2em'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '2em'
    }
  },
  image: {
    width: '100%',
    height: 300,
    flex: 1
  },
  mainTitle: {
    fontSize: '1.8rem',
    fontWeight: 500,
    textTransform: 'uppercase'
  },

  flex: {
    display: 'flex',
    gridGap: '2em',
    '&:nth-child(even)': {
      flexDirection: 'row-reverse'
    }
  },

  titleStyles: {
    color: '#fff'
  },
  smallCard: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5em',
    border: '1px solid #eee',
    padding: theme.spacing(2),
    borderRadius: '1em',
    height: '100%',
    background: '#fff',
    transition: 'all 100ms ease-in-out',
    '&:hover': {
      border: '2px solid #FF7704'
    }
  }
}));

const Card = ({ title, icon, reveal, delay }) => {
  const classes = useStyles();

  return (
    <Animated
      to={{
        opacity: reveal ? 1 : 0
      }}
      config={{
        duration: 300
      }}
      delay={delay}
      className={classes.smallCard}
    >
      <Box p={2}>
        <FontAwesomeIcon icon={icons[icon]} size="xl" color="#FF7704" />
      </Box>
      <Box flex={2}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ fontWeight: 900 }}
        >
          {title}
        </Typography>
      </Box>
    </Animated>
  );
};

const Transactions = ({ transactionItems }) => {
  const [ref, reveal] = useReveal({ threshold: 0.1 });
  const classes = useStyles();
  return (
    <div ref={ref}>
      <Animated
        to={{
          opacity: reveal ? 1 : 0,
          y: reveal ? 0 : 50
        }}
        config={{
          duration: 500
        }}
      >
        <Typography variant="h1" className={classes.headerTitle}>
          Transactions
        </Typography>
      </Animated>
      <Box my="2em">
        <List>
          {transactionItems.map(({ title, icon }, i) => (
            <Animated
              to={{
                opacity: reveal ? 1 : 0
              }}
              config={{
                duration: 300
              }}
              delay={i * 200}
            >
              <ListItem key={title}>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={icons['faCheck']}
                    color={green[300]}
                    size="xl"
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'h3',
                    color: 'textSecondary'
                  }}
                >
                  {title}
                </ListItemText>
              </ListItem>
            </Animated>
          ))}
        </List>
      </Box>
    </div>
  );
};

const Reports = ({ reports }) => {
  const [ref, reveal] = useReveal({ threshold: 0.1 });
  const classes = useStyles();
  return (
    <div ref={ref}>
      <Animated
        to={{
          opacity: reveal ? 1 : 0,
          y: reveal ? 0 : 50
        }}
        config={{
          duration: 500
        }}
      >
        <Typography variant="h1" className={classes.headerTitle}>
          Reports
        </Typography>
      </Animated>
      <Box my="2em">
        <List>
          {reports.map(({ title, icon }, i) => (
            <Animated
              to={{
                opacity: reveal ? 1 : 0,
                y: reveal ? 0 : 50
              }}
              config={{
                duration: 300
              }}
              delay={i * 200}
            >
              <ListItem key={title}>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={icons['faCheck']}
                    color={green[300]}
                    size="xl"
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'h3',
                    color: 'textSecondary'
                  }}
                >
                  {title}
                </ListItemText>
              </ListItem>
            </Animated>
          ))}
        </List>
      </Box>
    </div>
  );
};

const Features = ({ features }) => {
  const [ref, reveal] = useReveal({ threshold: 0.1 });
  const classes = useStyles();
  return (
    <Box ref={ref}>
      <Animated
        to={{
          opacity: reveal ? 1 : 0,
          y: reveal ? 0 : 50
        }}
        config={{
          duration: 500
        }}
      >
        <Typography variant="h1" className={classes.headerTitle}>
          System Features
        </Typography>
      </Animated>
      <Box my="4em">
        <Grid container spacing={4}>
          {features.map(({ title, icon }, i) => (
            <Grid item xs={12} sm={12} md={4} lg={3} key={title}>
              <Card title={title} icon={icon} reveal={reveal} delay={200 * i} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SystemFeatures = ({ features, transactions, reports }) => {
  const classes = useStyles();
  const featureItems = features?.items || [];
  const transactionItems = transactions?.items || [];
  const reportItems = reports?.items || [];

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Features features={featureItems} />
        <Grid container spacing={4}>
          {transactionItems?.length !== 0 ? (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Transactions transactionItems={transactionItems} />
            </Grid>
          ) : null}
          {reportItems?.length !== 0 ? (
            <Grid xs={12} sm={12} md={6} lg={6}>
              <Reports reports={reportItems} />
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </div>
  );
};

export default SystemFeatures;
