import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

export const fetchRecent = async ({ base_url, userToken, page }) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${base_url}/arq/recent`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': userToken
      },
      params: {
        page
      }
    });

    return {
      success: true,
      data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: error?.response?.statusText
    };
  }
};

export const useRecentRequest = () => {
  const [ARQS, setARQS] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ status: false, msg: '' });
  const [page, setPage] = useState(1);

  const { base_url, userToken } = useSelector(state => state.auth);

  useEffect(() => {
    let mounted = true;

    const getARQS = async () => {
      setLoading(true);
      setError({ status: false, msg: '' });
      const result = await fetchRecent({ base_url, userToken, page });

      if (mounted) {
        if (result.success) {
          setLoading(false);

          setARQS([...ARQS, ...result.data]);

          return;
        }

        setLoading(false);
        setError({ status: true, msg: result.errorMesssage });
      }
    };

    getARQS();

    return () => (mounted = false);
  }, [page]);

  const getListOfRecentArqs = () => ARQS;

  const nextPage = async () => {
    setLoading(true);
    setError({ status: false, msg: '' });
    const result = await fetchRecent({ base_url, userToken, page: page + 1 });

    if (result.success) {
      setLoading(false);

      if (result.data.length === 0) return;

      setPage(prev => prev + 1);
      setARQS([...ARQS, ...result.data]);

      return;
    }

    setLoading(false);
    setError({ status: true, msg: result.errorMesssage });
  };

  const refresh = async () => {
    setLoading(true);
    setPage(1);
    setError({ status: false, msg: '' });
    const result = await fetchRecent({ base_url, userToken, page: 1 });

    if (result.success) {
      setLoading(false);

      if (result.data.length === 0) return;

      setARQS([...result.data]);

      return;
    }

    setLoading(false);
    setError({ status: true, msg: result.errorMesssage });
  };

  return {
    loading,
    error,
    refresh,
    nextPage,
    getListOfRecentArqs
  };
};
