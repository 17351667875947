import { v4 as generateUUID } from 'uuid';

function insertInEndOfPage(
  arrayToBeInserted = [],
  pageSize = 0,
  itemToInsert,
  generateId = false,
  idKey = 'id'
) {
  const res = [];

  let add = 2;

  arrayToBeInserted.forEach((item, index) => {
    res.push(item);

    if ((index + add) % pageSize === 0) {
      res.push(
        generateId ? { ...itemToInsert, [idKey]: generateUUID() } : itemToInsert
      );
      add++;
    }
  });

  if (res.length % pageSize !== 0)
    res.push(
      generateId ? { ...itemToInsert, [idKey]: generateUUID() } : itemToInsert
    );

  return res;
}

export default insertInEndOfPage;
