import { useState } from 'react';
import Base64 from 'Base64';
import { useSelector } from 'react-redux';
import { APIRequestV2 } from 'src/helpers/APIRequest';
import { useDispatch } from 'react-redux';
import { loadCredentials } from 'src/redux/slices/auth';
import { fetchBizSettings, loadBizSettings } from 'src/redux/slices/biz';
import useBizState from 'src/hooks/useBizState';
import { setAdminBizList, setBizList } from 'src/redux/slices/registry';

function useAuth() {
  const dispatch = useDispatch();

  const { base_url } = useSelector(({ auth }) => auth);
  const [showPassword, setShowPassword] = useState(false);
  const [authError, setAuthError] = useState('');
  const { restore } = useBizState();

  async function login(username, password) {
    setAuthError('');

    const { success, data, error } = await APIRequestV2({
      url: base_url + '/login',
      headers: {
        Authorization: 'Basic ' + Base64.btoa(username + ':' + password),
        'Content-Type': 'application/json'
      }
    });

    if (!success) {
      setAuthError(error?.data);
      return;
    }

    dispatch(setBizList([]));
    dispatch(setAdminBizList([]));

    // if user no longer needed to select a biz
    if (!data.must_select_biz && !data.require_change_pw) {
      const bizSettings = await fetchBizSettings(base_url, data.token);

      const uuid = bizSettings?.biz?.data?.info?.biz_uuid || '';

      restore(uuid);
      dispatch(loadBizSettings(bizSettings));
    }

    dispatch(loadCredentials(data));
  }

  function clearError() {
    setAuthError('');
  }

  return {
    login,
    showPassword,
    setShowPassword,
    authError,
    clearError
  };
}

export default useAuth;
