import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './useStyles';

const CTA = () => {
  const classes = useStyles();
  return (
    <div className={classes.ctaContainer}>
      <Box className={classes.ctContent}>
        <Typography variant="h1" align="center" className={classes.ctaText}>
          Transform the way you monitor and manage projects using UlapBiz
        </Typography>
        <Button
          component={Link}
          to="/contact-us"
          color="primary"
          size="large"
          variant="outlined"
          className={classes.ctaButton}
        >
          Book a Demo
        </Button>
      </Box>
    </div>
  );
};

export default CTA;
