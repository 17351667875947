import { createSlice } from '@reduxjs/toolkit';

const pageHelper = (listReport, index, value) => {
  const copyListReport = [...listReport];
  const list = copyListReport[index];
  list.page = value;
  copyListReport[index] = list;

  return copyListReport;
};

const limitHelper = (listReport, index, value) => {
  const copyListReport = [...listReport];
  const list = copyListReport[index];
  list.limit = value;
  copyListReport[index] = list;

  return copyListReport;
};

const INITIAL_STATE = {
  loading: false,
  ptsReportList: [],
  jePtsList: null,
  jePtsServerList: null,
  sJEPts: null,
  accounTitle: '',
  filterFormData: {
    ixAcc: null,
    ixSub: null,
    ixJEPts: null,
    dt1: null,
    dt2: null,
    textValue: ''
  },
  brch: {
    required: false,
    ixBrch: 0,
    label: ''
  },
  errorStatus: false,
  errorMsg: '',
  page: 0,
  limit: 25
};

const slice = createSlice({
  name: 'jepts',
  initialState: INITIAL_STATE,
  reducers: {
    onFetchReport(state, action) {
      state.loading = true;
    },
    loadReportList(state, action) {
      state.loading = false;
      state.status = 'success';
      state.ptsReportList = action.payload;
    },
    loadError(state, action) {
      state.loading = false;
      state.errorMsg = action.payload.message;
      state.errorStatus = true;
    },
    setJePts(state, action) {
      state.filterFormData.ixJEPts = action.payload;
    },
    selectJePtsReport(state, action) {
      state.sJEPts = action.payload;
    },
    setAccount(state, action) {
      state.filterFormData.ixAcc = action.payload;
    },
    setSub(state, action) {
      state.filterFormData.ixSub = action.payload;
    },
    setTitle(state, action) {
      state.accounTitle = action.payload;
    },
    setDateRange(state, action) {
      state.filterFormData.dt1 = action.payload.dt1;
      state.filterFormData.dt2 = action.payload.dt2;
      state.filterFormData.textValue = action.payload.textValue;
    },
    loadData(state, action) {
      state.loading = false;
      state.jePtsList = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
    setServerPage(state, action) {
      state.jePtsServerList = pageHelper(
        state.jePtsServerList,
        action.payload.serverIndex,
        action.payload.page
      );
    },
    setServerLimit(state, action) {
      state.jePtsServerList = limitHelper(
        state.jePtsServerList,
        action.payload.serverIndex,
        action.payload.limit
      );
    },
    setReport(state, action) {
      state.sJEPts = action.payload;
    },
    setServerReport(state, action) {
      state.jePtsServerList = action.payload;
    },
    clearServerReports(state, action) {
      state.jePtsServerList = null;
    },
    resetReport(state, action) {
      return {
        ...INITIAL_STATE,
        ptsReportList: state.ptsReportList,
        sJEPts: state.ptsReportList > 1 ? null : state.sJEPts,
        filterFormData: {
          ixAcc: state.ptsReportList > 1 ? null : state.filterFormData.ixAcc,
          ixSub: null,
          ixJEPts:
            state.ptsReportList > 1 ? null : state.filterFormData.ixJEPts,
          dt1: null,
          dt2: null,
          textValue: ''
        },
        brch: {
          ...state.brch,
          ixBrch: 0
        }
      };
    },
    setBrch(state, action) {
      state.brch.label = action.payload.label;
      state.brch.required = action.payload.required;
    },
    changeBrch(state, action) {
      state.brch.ixBrch = action.payload;
    },
    resetError(state, action) {
      state.loading = false;
      state.errorStatus = false;
      state.errorMsg = '';
    },
    cancelRequest(state, action) {
      state.loading = false;
    },
    clear(state, action) {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

export const fetchReportList = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const {
    loadReportList,
    selectJePtsReport,
    setJePts,
    setAccount,
    loadError
  } = slice.actions;

  const response = await fetch(`${base_url}/reports/je/pts/list`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-access-tokens': userToken
    }
  });

  try {
    const responseJson = await response.json();

    dispatch(loadReportList(responseJson));
    if (responseJson.length === 1) {
      dispatch(selectJePtsReport(responseJson[0]));
      dispatch(setJePts(responseJson[0].ixJEPts));
      dispatch(setAccount(responseJson[0].ixAcc));
    }
  } catch (err) {
    dispatch(
      loadError({
        message: `Error - Something went wrong.`
      })
    );
  }
};

const jePtsReducer = slice.reducer;
export const {
  onFetchReport,
  loadReportList,
  loadError,
  setJePts,
  setAccount,
  setSub,
  setTitle,
  setDateRange,
  loadData,
  setPage,
  setLimit,
  setServerPage,
  setServerLimit,
  setReport,
  setServerReport,
  clearServerReports,
  resetReport,
  setBrch,
  changeBrch,
  resetError,
  cancelRequest,
  restore,
  clear,
  selectJePtsReport
} = slice.actions;
export default jePtsReducer;
