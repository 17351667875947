import React from 'react';
import { Page } from 'src/components';
import Hero from './Hero';
import Overview from './Overview';
import Features from './Features';
import Pricing from './Pricing';
import CallToAction from './CallToAction';
import Footer from 'src/views/landingPage/Footer';
import { useDynamicPages } from '../../DynamicPageProvider';

const Template1 = () => {
  const { pageDetails } = useDynamicPages();

  const { features, overview, pricing, cta } = pageDetails?.jsonSettings || {};

  const showFeatures = features?.show ?? false;
  const showOverview = overview?.show ?? false;
  const showPricing = pricing?.show ?? false;
  const showCTA = cta?.show ?? false;

  return (
    <Page title={pageDetails?.sPage ?? ''} style={{ backgroundColor: '#FFF' }}>
      <Hero />
      {showFeatures && <Features />}
      {showOverview && <Overview />}
      {showPricing && <Pricing />}
      {showCTA && <CallToAction />}
      <Footer />
    </Page>
  );
};

export default Template1;
