import React from 'react';
import {
  Container,
  Box,
  Paper,
  makeStyles,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import VerticalTimeline from './VerticalTimeline';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontSize: '2rem',
    fontWeight: theme.typography.fontWeightBold
  },
  line: {
    height: 16,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    padding: '0 80px',
    boxShadow:
      'inset 0 0 1px 0 rgba(0,0,0,0.31), inset 0 4px 6px -2px rgba(0,0,0,0.25)',
    zIndex: 10,
    transform: 'rotate(-5deg) translateY(-60px)',
    top: '100%'
  },
  m_line: {
    height: 1200,
    width: 16,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  dot: {
    position: 'relative',
    borderRadius: '50%',
    top: '50%',
    transform: 'translateY(-50%) rotate(5deg)',
    width: 30,
    height: 30,
    backgroundColor: '#F4F6F8',
    boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 20
  },
  details: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  detailsContainer: {
    minHeight: 160,
    width: 220,
    padding: theme.spacing(2),
    borderRadius: 20
  }
}));

const TIMELINE = [
  {
    key: 1,
    title: '2000 - 2005',
    statements: [
      'Accounting Information System v1.',
      'Computerized Enrollment System.',
      'Radztech Computer Systems.'
    ]
  },
  {
    key: 2,
    title: '2006',
    statements: [
      'Radztech Component Systems: Payroll and Inventory Management System.'
    ]
  },
  {
    key: 3,
    title: '2011 - 2021',
    statements: [
      'Radztech AIS Specialization',
      'Became the most used accounting and inventory system for distributors in its area of operation.'
    ]
  },
  {
    key: 4,
    title: '2022',
    statements: [
      'UlapBiz - Online AIS and ERP Systems for private entities.',
      'Radztech Business Solution.'
    ]
  },
  {
    key: 5,
    title: '2023',
    statements: ['Integrated Budget & Financial Reporting System.']
  }
];

const Timeline = () => {
  const theme = useTheme();
  const classes = useStyles();

  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container maxWidth="lg">
      <Box py={10}>
        <Typography variant="h1" className={classes.sectionTitle}>
          Brief History
        </Typography>
        {downMd ? (
          <VerticalTimeline timeline={[...TIMELINE].reverse()} />
        ) : (
          <Box position="relative" height={400}>
            <Box className={classes.line}>
              {TIMELINE.map(({ key, title, statements }) => (
                <Box key={key} className={classes.dot}>
                  <Box
                    width={15}
                    height={15}
                    bgcolor="#FF7704"
                    borderRadius="50%"
                  />
                  <Box className={classes.details}>
                    <Paper className={classes.detailsContainer} elevation={15}>
                      <Typography
                        variant="h4"
                        color="primary"
                        style={{ fontWeight: 700 }}
                      >
                        {title}
                      </Typography>
                      <List
                        disablePadding
                        style={{ marginTop: theme.spacing(1) }}
                      >
                        {statements.map(statement => (
                          <ListItem disableGutters key={statement}>
                            <ListItemText
                              primary={statement}
                              primaryTypographyProps={{ variant: 'body2' }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                    <Box pt={2} pb={4}>
                      <Box
                        height={60}
                        width={8}
                        borderRadius={10}
                        bgcolor="#888"
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Timeline;
