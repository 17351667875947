import React from 'react';

import {
  Box,
  Typography,
  makeStyles,
  Container,
  Grid,
  IconButton,
  Collapse,
  Divider,
  useTheme
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useToggle } from 'src/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  description: {
    lineHeight: '1.3rem',
    marginTop: theme.spacing(2)
  },
  headerTitle: {
    textTransform: 'uppercase',
    display: 'block',
    fontWeight: 900,
    fontSize: '2em',
    color: '#FF7704'
  },
  image: {
    width: '100%',
    height: 300,
    flex: 1
  },
  mainTitle: {
    fontSize: '1.8rem',
    fontWeight: 500,
    textTransform: 'uppercase'
  },

  flex: {
    display: 'flex',
    gridGap: '2em',
    '&:nth-child(even)': {
      flexDirection: 'row-reverse'
    }
  },
  subTitle: {
    fontWeight: 900
  },
  contentContainer: {
    display: 'flex'
  },
  image: {
    width: 450
  },
  featureCard: {
    background: '#FFF',
    boxShadow: theme.shadows[1],
    borderRadius: 10
  }
}));

const FeatureItem = ({ title = '', description = '' }) => {
  const classNames = useStyles();

  const [isOpen, , , toggle] = useToggle();
  const [collapsed, , , toggleCollapse] = useToggle();

  function handleToggleClick() {
    toggle();
    if (!isOpen) toggleCollapse();
  }

  return (
    <Box className={classNames.featureCard}>
      <Box display="flex" alignItems="center" px={2} height={60}>
        <Typography variant="h5" style={{ flex: 1 }}>
          {title}
        </Typography>
        <Box>
          <IconButton size="small" onClick={handleToggleClick}>
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>
      {collapsed && <Divider />}
      <Collapse in={isOpen} onExited={toggleCollapse}>
        <Box px={2} py={1}>
          <Typography variant="subtitle1">{description}</Typography>
        </Box>
      </Collapse>
    </Box>
  );
};

/**
 *
 * @param {object} param
 * @param {pageFeatureList} param.pageDetails
 * @param {string} param.ixPage
 * @returns
 */
const FeatureListTl1 = ({ pageDetails, ixPage }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          {pageDetails.features.map(item => (
            <Grid
              item
              {...item.grid}
              style={{ marginBottom: theme.spacing(5) }}
            >
              <Typography variant="h1" className={classes.headerTitle}>
                {item.title}
              </Typography>
              {Boolean(item.hasSubtitle) ? (
                <Grid container spacing={5}>
                  {item.children.map(subtitle => (
                    <Grid
                      item
                      {...subtitle.grid}
                      style={{ marginTop: theme.spacing(4) }}
                    >
                      <Typography variant="h4" className={classes.subTitle}>
                        {subtitle.subtitle}
                      </Typography>
                      <Box
                        mt={3}
                        display="flex"
                        flexDirection="column"
                        gridGap={theme.spacing(2)}
                      >
                        {subtitle.children.map(child => (
                          <FeatureItem
                            title={child.title}
                            description={child.description}
                          />
                        ))}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box
                  mt={3}
                  display="flex"
                  flexDirection="column"
                  gridGap={theme.spacing(2)}
                >
                  {item.children.map(child => (
                    <FeatureItem
                      title={child.title}
                      description={child.description}
                    />
                  ))}
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default FeatureListTl1;
