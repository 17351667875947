import React, { useRef, useState } from 'react';
import { useTheme, Box, alpha, useMediaQuery } from '@material-ui/core';
import { Bar, Line } from 'react-chartjs-2';
import { useEffect } from 'react';
import moment from 'moment-timezone';

const Chart = ({ chartData, getPostingData, chartType }) => {
  const theme = useTheme();
  const onSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [data, setData] = useState(chartData.data);
  const [labels, setLabels] = useState(chartData.labels);
  const chartRef = useRef();

  const onClick = (_, event) => {
    const chart = chartRef.current.chartInstance.chart;
    const element = chart.getElementAtEvent(event)[0];

    if (element) {
      const { _index, _datasetIndex } = element;

      const ixJCd = chartData.data[_datasetIndex].ixJCd;
      const date = moment(chartData.labels[_index], 'MMM DD').format(
        'YYYY-MM-DD'
      );
      const sJCd = chartData.data[_datasetIndex].label;
      const count = chartData.data[_datasetIndex].data[_index];

      let color;
      if (chartType === 'line') {
        color = chartData.data[_datasetIndex].borderColor;
      } else {
        color = chartData.data[_datasetIndex].backgroundColor;
      }

      getPostingData(ixJCd, date, sJCd, count, color);
    }
  };

  useEffect(() => {
    setData(chartData.data);
    setLabels(chartData.labels);
  }, [chartData]);

  const options = {
    cornerRadius: 0,
    layout: { padding: 0 },
    legend: {
      display: true,
      position: 'top'
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 10,
      xPadding: 10,
      borderWidth: 1
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: chartType === 'stackedBar',
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            maxTicksLimit: 4,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true
          },
          stacked: chartType === 'stackedBar'
        }
      ]
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }
  };

  return (
    <Box height={{ xs: 200, sm: 200, md: 250 }} position="relative">
      {chartType === 'bar' && (
        <Bar
          ref={chartRef}
          onElementsClick={onClick}
          data={{
            datasets: data,
            labels
          }}
          options={options}
        />
      )}
      {chartType === 'stackedBar' && (
        <Bar
          ref={chartRef}
          onElementsClick={onClick}
          data={{
            datasets: data,
            labels
          }}
          options={options}
        />
      )}
      {chartType === 'line' && (
        <Line
          ref={chartRef}
          onElementsClick={onClick}
          data={canvas => {
            const ctx = canvas.getContext('2d');
            const datasets = data.map(dataset => {
              const gradient = ctx.createLinearGradient(0, 0, 0, 400);

              gradient.addColorStop(0, alpha(dataset.backgroundColor, 0.2));
              gradient.addColorStop(0.9, 'rgba(255,255,255,0)');
              gradient.addColorStop(1, 'rgba(255,255,255,0)');

              return {
                ...dataset,
                backgroundColor: gradient,
                pointBackgroundColor: dataset.backgroundColor,
                pointRadius: onSmallScreen ? 0 : 3,
                borderWidth: onSmallScreen ? 2 : 3
              };
            });

            return {
              datasets,
              labels
            };
          }}
          options={options}
        />
      )}
    </Box>
  );
};

export default Chart;
