import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeDateRange: {
    dt1: '',
    dt2: '',
    textValue: ''
  },
  activeVariant: '',
  activeBranch: {
    required: false,
    ixBrch: 0,
    label: ''
  },
  activeTransactions: [],
  activeUser: ''
};

const slice = createSlice({
  name: 'postingSummary',
  initialState: initialState,
  reducers: {
    setActiveDateRange(state, action) {
      state.activeDateRange = action.payload;
    },
    setActiveVariant(state, action) {
      state.activeVariant = action.payload;
    },
    setActiveTransactions(state, action) {
      state.activeTransactions = action.payload;
    },
    setActiveBranch(state, action) {
      state.activeBranch = {
        ...state.brch,
        ixBrch: action.payload
      };
    },
    setActiveUser(state, action) {
      state.activeUser = action.payload;
    },
    setBranchRequired(state, action) {
      state.activeBranch = {
        ...state.brch,
        label: action.payload.label,
        required: action.payload.required
      };
    },
    clearState(state, actions) {},
    restore(_, action) {
      return action?.payload || initialState;
    }
  }
});

const postingSummaryReducer = slice.reducer;

export const {
  setActiveDateRange,
  setActiveVariant,
  setActiveTransactions,
  setActiveBranch,
  setBranchRequired,
  setActiveUser,
  clearState,
  restore
} = slice.actions;
export default postingSummaryReducer;
