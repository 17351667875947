import React from 'react';
import FeatureListTl1 from './FeatureListTl1';

/**
 *
 * @param {object} param
 * @param {string} param.ixPage
 * @param {pageFeatureList} param.pageDetails
 * @returns
 */
const FeatureList = ({ pageDetails, ixPage }) => {
  return <FeatureListTl1 pageDetails={pageDetails} ixPage={ixPage} />;
};

export default React.memo(FeatureList);
