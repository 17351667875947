import React from 'react';
import { Container, makeStyles, Grid, Paper, Box } from '@material-ui/core';
import clsx from 'clsx';
import MDEditor from '@uiw/react-md-editor';
import acceptRemote from './images/accept-remote.jpg';
import addressPage from './images/address.JPG';
import clickDownload from './images/click-download.png';
import MarkDownImageViewer from 'src/components/MarkDownImageViewer';
import { useEnv } from 'src/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#EEE',
    paddingTop: 50,
    paddingBottom: 50
  },
  img: {
    ['& img']: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    }
  }
}));

const SupportContent = [
  '## Contacts ☎',
  '  - ### For Technical Support Related :',
  '    - Contact no.: (+63) 926 610 7859',
  '    - Email: [support@radztech.com](mailto:support@radztech.com)',
  '  - ### For Accounting or Sales Related :',
  '    - Contact no.: (+63) 926 670 8266 or (+63) 956 896 3767',
  '    - Email: [sales@radztech.com](mailto:sales@radztech.com)',
  '## Technical and Accounting Support Instructions',
  '1. Message us using the format below:',
  '    1. Company',
  '    2. Name',
  '    3. Position',
  '    4. Queries or problems encountered',
  '2. Wait for the acknowledgment message and `ticket number` with further instructions.',
  '3. To follow up on your queries, just message us and indicate the `ticket number` given by our support personnel.',
  '> Note : Please be polite when you talk to our support personnel.',
  '## Anydesk Remote Instructions',
  '1. Make sure that you have **fast internet connection**.',
  "2. Download Anydesk application [here](https://download.anydesk.com/AnyDesk.exe  'Anydesk') or visit [Anydesk's website](https://anydesk.com/en/downloads/thank-you?dv=win_exe  'Anydesk').",
  "3. Click the Anydesk application that you've just downloaded.  ",
  `![](${clickDownload})`,
  '4. Copy your **9 digits Anydesk Address** that can be seen on top of your anydesk application.  ',
  `![](${addressPage})`,
  '5. Send the copied Anydesk address to any of the contact information indicated above.',
  '6. Stay put and wait for the acceptance form that will be prompted on your screen.',
  '7. Once the form prompted, click the `Accept` button.  ',
  `![](${acceptRemote})`,
  '8. Finally, monitor your screen and do not move your keyboard and mouse unless instructed by our Support Personnel.'
];

const Support = ({ className, ...rest }) => {
  const classNames = useStyles();

  const { EGOV } = useEnv();

  const content = [
    EGOV
      ? '# <span style="color: #4b8ef1">iBFRS</span> Support 💻'
      : '# Ulap<span style="color: #FF7704">Biz</span> Support 💻',
    ...SupportContent
  ].join('\n');

  return (
    <div className={clsx(classNames.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container justifyContent="center">
          <Grid item lg={12}>
            <Paper
              elevation={2}
              component={Box}
              mt={EGOV ? 10 : 0}
              p={5}
              className={classNames.img}
            >
              <div data-color-mode="light">
                <div className="wmde-markdown-var" />{' '}
                <MarkDownImageViewer content={content}>
                  <MDEditor.Markdown
                    source={content}
                    className={classNames.img}
                  />
                </MarkDownImageViewer>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Support;
