import React from 'react';
import {
  Container,
  Typography,
  Box,
  makeStyles,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  particle2: {
    position: 'relative',
    zIndex: 10,
    '&:before': {
      content: '""',
      zIndex: -10,
      position: 'absolute',
      backgroundImage: 'url(/static/ibfrs3/dots1.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      aspectRatio: 1,
      height: 400,
      top: 0,
      left: '5%',
      transformOrigin: 'center',
      transform: 'rotate(-90deg)',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  },
  accountingParticle: {
    position: 'relative',
    zIndex: 10,
    '&:before': {
      content: '""',
      zIndex: -10,
      position: 'absolute',
      backgroundImage: 'url(/static/ibfrs3/particles/circle-dots.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      aspectRatio: 1,
      height: 200,
      top: 0,
      right: '-15%',
      transformOrigin: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  },
  sectionTitle: {
    fontSize: '3rem',
    fontWeight: 900,
    textAlign: 'center',
    '& .highlight': {
      color: theme.palette.primary.main,
      borderBottom: '4px solid ' + theme.palette.primary.main
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem'
    }
  },
  anchorTitle: {
    fontSize: '2.6rem',
    fontWeight: 900,
    marginBottom: '0.6em',
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.8rem'
    }
  },
  anchorDescription: {
    fontSize: '1.8rem',
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.2rem'
    }
  }
}));

const AnchorsSection = () => {
  const classNames = useStyles();

  return (
    <Box
      minHeight={600}
      py={10}
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classNames.particle2}
    >
      <Container maxWidth="lg">
        <Typography className={classNames.sectionTitle}>
          Major <span className="highlight">Anchors</span>
        </Typography>
        <Box mt={8}>
          <Container maxWidth="lg">
            <Grid container spacing={10} justifyContent="space-between">
              <Grid item lg={6} md={6} sm={10} xs={12}>
                <Box>
                  <Typography className={classNames.anchorTitle}>
                    Budget System
                  </Typography>
                  <Typography className={classNames.anchorDescription}>
                    Prepares, reviews, and approves government budget,
                    collecting, analyzing, and allocating funds based on
                    priorities, facilitating budgeting process.
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={10} xs={12}>
                <img
                  src="/static/ibfrs3/anchors/budget-system.png"
                  width={'100%'}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box mt={8} className={classNames.accountingParticle}>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={10}
              direction="row-reverse"
              justifyContent="center"
            >
              <Grid item lg={6} md={6} sm={10} xs={12}>
                <Box>
                  <Typography className={classNames.anchorTitle}>
                    Accounting System
                  </Typography>
                  <Typography className={classNames.anchorDescription}>
                    Manages financial transactions, tracks revenues,
                    expenditures, and fund balances. Adheres to accounting
                    standards. Includes government-specific financial processes.
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={10} xs={12}>
                <img
                  src="/static/ibfrs3/anchors/accounting-system.png"
                  width={'100%'}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box mt={8}>
          <Container maxWidth="lg">
            <Grid container spacing={10} justifyContent="center">
              <Grid item lg={6} md={6} sm={10} xs={12}>
                <Box>
                  <Typography className={classNames.anchorTitle}>
                    Workflow Process
                  </Typography>
                  <Typography className={classNames.anchorDescription}>
                    Automates budgeting and accounting processes, streamlining
                    task sequence, approvals, and information routing. Boosts
                    efficiency and accountability.
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={10} xs={12}>
                <img
                  src="/static/ibfrs3/anchors/wf-process.png"
                  width={'100%'}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box mt={8}>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={10}
              direction="row-reverse"
              justifyContent="center"
            >
              <Grid item lg={6} md={6} sm={10} xs={12}>
                <Box>
                  <Typography className={classNames.anchorTitle}>
                    Reports
                  </Typography>
                  <Typography className={classNames.anchorDescription}>
                    Provides detailed customizable financial reports for
                    government, regulatory, and internal reporting, covering
                    budget execution, expenditures, revenue analysis, and
                    performance metrics.
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={10} xs={12}>
                <img src="/static/ibfrs3/anchors/reports.png" width={'100%'} />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box mt={8}>
          <Container maxWidth="lg">
            <Grid container spacing={10} justifyContent="center">
              <Grid item lg={6} md={6} sm={10} xs={12}>
                <Box>
                  <Typography className={classNames.anchorTitle}>
                    Financial Statements
                  </Typography>
                  <Typography className={classNames.anchorDescription}>
                    Formal financial reports display government's position,
                    performance, and cash flows. Supports informed decisions and
                    transparency.
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={10} xs={12}>
                <img src="/static/ibfrs3/anchors/fs_new.png" width={'100%'} />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box mt={8}>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={10}
              direction="row-reverse"
              justifyContent="center"
            >
              <Grid item lg={6} md={6} sm={10} xs={12}>
                <Box>
                  <Typography className={classNames.anchorTitle}>
                    Dashboards
                  </Typography>
                  <Typography className={classNames.anchorDescription}>
                    Real-time visual displays of financial and budgetary data.
                    Enables quick insights, monitoring, and informed
                    decision-making. Customizable for user preferences.
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={10} xs={12}>
                <img
                  src="/static/ibfrs3/anchors/dashboards.png"
                  width={'100%'}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>
    </Box>
  );
};

export default AnchorsSection;
