import React from 'react';
import Loading from 'src/components/Loading';
import { useUser } from './UserProvider';

function LoadingWrapper({ children }) {
  const { isLoading } = useUser();

  if (isLoading) return <Loading />;

  return children;
}

export default LoadingWrapper;
