// xxx-xxx-xxx-xxxxx
const isValidTIN = (TIN = '') => {
  let copy_tin = TIN.replaceAll('_', '')
    .replaceAll(' ', '')
    .replaceAll('-', '');

  return copy_tin.length === 14;
};

export const isTINEmpty = (TIN = '') => {
  if (!TIN) return true;

  let copy_tin = TIN.replaceAll('_', '')
    .replaceAll(' ', '')
    .replaceAll('-', '');

  return copy_tin.length === 0;
};

export default isValidTIN;
