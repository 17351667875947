import React from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { useEnv } from 'src/hooks';
import { createTheme } from 'src/theme';

const PortalLoginLayout = () => {
  const { EGOV } = useEnv();

  const theme = createTheme({
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: 'DEFAULT',
    mode: 'light',
    EGOV
  });

  return (
    <ThemeProvider theme={theme}>
      <Outlet />
    </ThemeProvider>
  );
};

export default PortalLoginLayout;
