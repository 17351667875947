import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { getColorFromMUI } from 'src/utils';
import React from 'react';
import { useDynamicPages } from '../../DynamicPageProvider';

const useStyles = makeStyles(theme => ({
  card: {
    height: 120,
    boxShadow: '0 0 1rem 0 rgba(0, 0, 0, .2)',
    backgroundColor: 'rgba(255, 255, 255, .10)',
    backdropFilter: 'blur(2px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2)
  }
}));

const Pricing = () => {
  const theme = useTheme();
  const classNames = useStyles();
  const { pageDetails } = useDynamicPages();
  const pricing = pageDetails?.jsonSettings?.pricing ?? {
    primaryText: 'Pricing',
    secondaryText:
      'Choose from these three flexible and budget-friendly options tailored to your needs.',
    items: []
  };

  return (
    <Box position="relative" zIndex={10}>
      <Container maxWidth="lg">
        <Box minHeight={600} py={10}>
          <Grid container spacing={10}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography
                style={{
                  fontSize: '3.8rem',
                  fontWeight: 900,
                  marginBottom: '0.4em'
                }}
              >
                {pricing?.primaryText ?? ''}
              </Typography>
              <Typography style={{ fontSize: '1.4rem' }}>
                {pricing?.secondaryText ?? ''}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                gridGap={theme.spacing(2)}
              >
                {pricing.items.map(item => (
                  <Box item={item.id} className={classNames.card}>
                    <Box
                      flex={1}
                      bgcolor={getColorFromMUI({
                        color: item?.color ?? 'orange',
                        shade: item?.shade ?? 300
                      })}
                      height="100%"
                      justifyContent="center"
                      alignItems="center"
                      display="flex"
                    >
                      <Typography
                        style={{
                          fontSize: '2rem',
                          color: '#fff',
                          fontWeight: 900,
                          textAlign: 'center'
                        }}
                      >
                        {item.planName}
                      </Typography>
                    </Box>
                    <Box
                      flex={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        style={{
                          letterSpacing: '2px',
                          fontWeight: 700,
                          textAlign: 'center'
                        }}
                      >
                        {item.planPrice}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Pricing;
