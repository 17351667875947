import React, { useState, useEffect } from 'react';
import { Box, DialogActions, Divider, Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { getDateValues } from './utils';
import { Database } from 'react-feather';

const Day = ({
  dateValues,
  prefixText,
  defaultDatePicker,
  defaultDateRangePicker,
  handleCloseDialog,
  handleChangeDatePickerValue
}) => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    setDate(moment(dateValues.dt1).format('YYYY-MM-DD'));
  }, []);

  const updateDate = val => {
    setDate(moment(val).format('YYYY-MM-DD'));
  };

  const handleOk = () => {
    handleChangeDatePickerValue(
      getDateValues.day({
        values: { dt1: date, dt2: date },
        prefixText: prefixText || 'day'
      })
    );
    handleCloseDialog();
  };

  return (
    <>
      <Box width="100%">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            orientation="landscape"
            variant="static"
            label="Date"
            inputVariant="outlined"
            value={date}
            placeholder="mm/dd/yyy"
            onChange={updateDate}
            format="MM/dd/yyyy"
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Divider />
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button color="primary" onClick={handleCloseDialog}>
          Cancel
        </Button>

        <Button color="primary" variant="contained" onClick={handleOk}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
};

export default Day;
