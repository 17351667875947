import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRequest } from 'src/hooks';
import { lib as libEndpoints, trans } from 'src/lib/endpoints';
import { setAccounts, setLibAccounts } from 'src/redux/slices/storedValues';
import searchByField from 'src/utils/searchByField';
import { useJsonToCsv } from 'react-json-csv';

export default function useAccSearch({ lib = false }) {
  const dispatch = useDispatch();
  const request = useRequest();
  const { saveAsCsv } = useJsonToCsv();

  const { accounts = [], lib_accounts = [] } = useSelector(
    state => state.storedValues
  );
  const [accountTypes, setAccountTypes] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [expenseClass, setExpenseClass] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [query, setQuery] = useState('');

  const accListToUse = lib ? lib_accounts : accounts;

  async function getAccounts() {
    setLoading(true);

    const [libAccounts, transAccounts] = await request.multiFetch([
      { url: libEndpoints.acc, method: 'POST' },
      { url: trans.searchAccounts, method: 'POST' }
    ]);

    if ((lib && !libAccounts.success) || (!lib && !transAccounts.success)) {
      setError(true);
      setLoading(false);
      return;
    }

    dispatch(setLibAccounts(libAccounts.data));
    dispatch(setAccounts(transAccounts.data));

    setLoading(false);
  }

  function filteredAccounts() {
    if (accListToUse.length === 0) return [];

    const filtered = searchByField({
      data: accListToUse.filter(acc => {
        if (expenseClass.length > 0) {
          return (
            expenseClass.includes(acc.ixExpCls) &&
            accountTypes.includes(acc.acc_type)
          );
        } else return accountTypes.includes(acc.acc_type);
      }),
      keys: ['sAccTitle', 'ixAcc', 'AccNo', 'srch_tag'],
      query
    });

    return accListToUse.filter(({ ixAcc, ixParent, iRgt, iLft }) => {
      for (let i = 0; i < filtered.length; i++) {
        const acc = filtered[i];

        if (acc.ixAcc === ixAcc) return true;
        else if (
          iRgt - iLft !== 1 &&
          (ixParent === acc.ixAcc || acc.ixParent === ixAcc)
        )
          return true;
      }

      return false;
    });
  }

  function exportAccounts() {
    const pretty_flds = {
      ixAcc: 'Account ID',
      AccNo: 'Account No',
      sAccTitle: 'Account Title',
      sAccType: 'Account Type',
      ixParent: 'Parent Account ID',
      parent_sAccTitle: 'Parent Account',
      ixSubType1: 'Sub Type 1 ID',
      sSubType1: 'Sub Type 1',
      ixSubType2: 'Sub Type 2 ID',
      sSubType2: 'Sub Type 2',
      ixSubType3: 'Sub Type 3 ID',
      sSubType3: 'Sub Type 3',
      ixSubType4: 'Sub Type 4 ID',
      sSubType4: 'Sub Type 4',
      ixSubType5: 'Sub Type 5 ID',
      sSubType5: 'Sub Type 5',
      ixAccContra: 'Contra Account ID',
      sAccContra: 'Contra Account',
      lft: 'Left Account',
      rgt: 'Right Account'
    };

    const flds = [
      'ixAcc',
      'AccNo',
      'sAccTitle',
      'sAccType',
      'ixParent',
      'parent_sAccTitle',
      'ixSubType1',
      'sSubType1',
      'ixSubType2',
      'sSubType2',
      'ixSubType3',
      'sSubType3',
      'ixSubType4',
      'sSubType4',
      'ixSubType5',
      'sSubType5',
      'ixAccContra',
      'sAccContra',
      'lft',
      'rgt'
    ];

    saveAsCsv({
      data: accListToUse.map(item => {
        const res = {};
        flds.forEach(fld => {
          if (fld === 'sAccTitle') {
            res[fld] = `"${item[fld]}"`;
          } else res[fld] = `"${item[fld]}"`;
        });
        return res;
      }),
      fields: flds.reduce((acc, prev) => {
        acc = {
          ...acc,
          [prev]: pretty_flds[prev]
        };

        return acc;
      }, {}),
      filename: `Chart of Accounts`
    });
  }

  useEffect(() => {
    if ((lib && lib_accounts.length === 0) || (!lib && accounts.length === 0))
      getAccounts();
  }, []);

  return {
    loading,
    error,
    query,
    accountTypes,
    refresh: getAccounts,
    accountList: filteredAccounts(),
    setAccountTypes,
    setQuery,
    exportAccounts,
    setExpenseClass
  };
}
