import React, { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import TopBar from './TopBar';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    overflowX: 'hidden'
  }
}));

/**
 * @typedef {object} RadztechContext
 * @property {boolean} isScrolled
 * @property {VoidFunction} scrolled
 * @property {VoidFunction} atTop
 * @property {boolean} hiddenTopBar
 * @property {React.MutableRefObject<undefined>} contentRef
 * @property {React.MutableRefObject<undefined>} coverRef
 */

/**
 * @type {React.Context<RadztechContext>}
 */
const RadztechContext = React.createContext();

const RadztechLayout = ({ hiddenTopBar = true }) => {
  const classes = useStyles();
  const contentRef = useRef();
  const coverRef = useRef();

  return (
    <RadztechContext.Provider
      value={{
        contentRef,
        coverRef,
        hiddenTopBar
      }}
    >
      <div className={classes.root}>
        <Helmet>
          <link rel="shortcut icon" href="/static/images/radztech_logo.png" />
        </Helmet>
        <TopBar />
        <div style={{ marginTop: 80 }}>
          <Outlet />
        </div>
      </div>
    </RadztechContext.Provider>
  );
};

export const useRadztechLayout = () => React.useContext(RadztechContext);
export default RadztechLayout;
