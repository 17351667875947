import {
  Box,
  Grid,
  Link,
  Typography,
  makeStyles,
  useMediaQuery,
  Container,
  useTheme
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  image: {
    width: '90%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    }
  },
  iconImage: {
    width: '40%',
    height: 'auto',
    filter: 'drop-shadow(5px 3px 5px rgba(0,0,0,0.5))',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  tagline: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: '3rem',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '80%'
    },
    '& span': {
      color: theme.palette.primary.main
    }
  },
  title: {
    fontWeight: 900,
    fontSize: '4rem',
    color: theme.palette.text.primary,
    '& span': {
      color: theme.palette.primary.contrastText
    }
  },
  content: {
    fontSize: '1.2rem',
    width: '68%',
    padding: '0 .5rem',
    color: theme.palette.primary.contrastText
  },
  gradientBackground: {
    width: '100%',
    height: '120vh',
    background: 'linear-gradient(180deg, #FF7704, #FFF)',
    transform: 'skewY(-25deg)',
    zIndex: 10,
    position: 'absolute',
    top: '60%',
    left: '0%',
    padding: '0 0 0 0',
    [theme.breakpoints.down('sm')]: {
      top: '65%'
    }
  },
  scheduleDemo: {
    backgroundColor: '#FAFAFA',
    fontWeight: 700,
    textTransform: 'uppercase',
    borderRadius: '50px',
    fontSize: 12,
    padding: '.5rem 1rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
      padding: '.8rem 1.75rem'
    },
    '&:hover': {
      backgroundColor: '#FF7704',
      color: '#FAFAFA'
    }
  }
}));

/**
 *
 * @param {object} param
 * @param {number} param.ixPage
 * @param {pageBanner} param.pageDetails
 * @returns
 */
const BannerTl1 = ({ ixPage, pageDetails }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  });

  return (
    <Box bgcolor="#fff">
      {isSmallScreen ? (
        <Box position="relative" overflow="hidden" padding={5}>
          <Box position="relative" zIndex={20}>
            <Box display="flex" justifyContent="center" mb={5}>
              <Typography
                align="center"
                variant="h3"
                className={classes.tagline}
              >
                {pageDetails?.tagline?.phrase1}{' '}
                <span>{pageDetails?.tagline?.phrase2}</span>
              </Typography>
            </Box>
            <Box mb={7}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <img
                  src={
                    pageDetails?.heroImg?.static
                      ? pageDetails?.heroImg?.src
                      : `/api/pages/${ixPage}/images/${pageDetails?.heroImg?.src}`
                  }
                  alt="banner-hero"
                  className={classes.image}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '.5rem'
              }}
            >
              {Boolean(pageDetails?.icon?.show) && (
                <img
                  src={
                    pageDetails?.icon?.static
                      ? pageDetails?.icon?.src
                      : `/api/pages/${ixPage}/images/${pageDetails?.icon?.src}`
                  }
                  alt="banner-icon"
                  className={classes.iconImage}
                  style={{ width: '40%' }}
                />
              )}
              <Typography variant="h2" align="center" className={classes.title}>
                {pageDetails?.title?.phrase1}
                <span>{pageDetails?.title?.phrase2}</span>
              </Typography>
              <Typography
                component={'p'}
                align="center"
                className={classes.content}
              >
                {pageDetails?.content}
              </Typography>
              {Boolean(pageDetails?.showContactUs) && (
                <Link
                  component={RouterLink}
                  to="/ulaptax"
                  underline="none"
                  variant="h5"
                  className={classes.scheduleDemo}
                >
                  Contact Us
                </Link>
              )}
            </Box>
          </Box>
          <Box className={classes.gradientBackground} />
        </Box>
      ) : (
        <Container
          disableGutters
          style={{ position: 'relative', overflow: 'hidden' }}
        >
          <Grid container style={{ position: 'relative', zIndex: 20 }}>
            <Grid item sm={12}>
              <Box
                sx={{
                  height: '20vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'end'
                }}
              >
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.tagline}
                >
                  {pageDetails?.tagline?.phrase1}{' '}
                  <span>{pageDetails?.tagline?.phrase2}</span>
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '.5rem',
                  height: 500
                }}
              >
                <Grid item sm={6}>
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={
                        pageDetails?.heroImg?.static
                          ? pageDetails?.heroImg?.src
                          : `/api/pages/${ixPage}/images/${pageDetails?.heroImg?.src}`
                      }
                      width="80%"
                      alt="banner-hero"
                      className={classes.image}
                    />
                  </Box>
                </Grid>
                <Grid item sm={6}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '.5rem'
                    }}
                  >
                    {Boolean(pageDetails?.icon?.show) && (
                      <img
                        src={
                          pageDetails?.icon?.static
                            ? pageDetails?.icon?.src
                            : `/api/pages/${ixPage}/images/${pageDetails?.icon?.src}`
                        }
                        alt="banner-icon"
                        className={classes.iconImage}
                      />
                    )}
                    <Typography
                      variant="h1"
                      align="center"
                      className={classes.title}
                    >
                      {pageDetails?.title?.phrase1}
                      <span>{pageDetails?.title?.phrase2}</span>
                    </Typography>
                    <Typography
                      component={'p'}
                      align="center"
                      className={classes.content}
                    >
                      {pageDetails?.content}
                    </Typography>
                    {Boolean(pageDetails?.showContactUs) && (
                      <Link
                        component={RouterLink}
                        to="/contact-us"
                        underline="none"
                        variant="h5"
                        className={classes.scheduleDemo}
                      >
                        Contact Us
                      </Link>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.gradientBackground} />
        </Container>
      )}
    </Box>
  );
};

export default BannerTl1;
