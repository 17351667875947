import React, { useState } from 'react';
import { Avatar, Box, MenuItem, Typography } from '@material-ui/core';
import SelectWithSearch from '../SelectWithSearch';
import { useRequest, useToggle } from 'src/hooks';
import { useEffect } from 'react';

function useBizList() {
  const [biz, setBiz] = useState([]);
  const [loading, loadOn, loadOff] = useToggle();
  const [err, setErr] = useState('');
  const req = useRequest(loadOn, loadOff);

  async function getBizList(token) {
    const { success, error, data, isCancelled } = await req.get(
      '/billing/biz',
      {},
      token
    );

    if (!success && isCancelled) return;
    else if (!success) {
      setErr(error.errorMessage);
      return;
    } else setBiz(data);
  }

  useEffect(() => {
    let token = null;

    getBizList(c => {
      token = c;
    });

    return () => {
      token();
    };
  }, []);

  return {
    biz: biz.map(item => ({
      Address1: item.Address1,
      Address2: item.Address2,
      ixImage: item.ixImage,
      value: item.ixBiz,
      description: item.sBiz
    })),
    error: err,
    loading
  };
}

function CustomMenuItem({
  value,
  description,
  Address1,
  Address2,
  ixImage,
  ...props
}) {
  return (
    <MenuItem {...props}>
      <Box display="flex" gridGap="1em" alignItems="center">
        <Avatar
          src={`/api/images/biz/${ixImage}/logo.jpg`}
          variant="circular"
          style={{
            height: '2em',
            width: '2em'
          }}
        />
        <Box>
          <Typography variant="body1">{description}</Typography>
          <Typography variant="caption" color="textSecondary">
            {Address1},{Address2}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  );
}

function BizList({ data, onChangeField }) {
  const { value, caption, error, errorMessage } = data;
  const { biz, loading, error: apiError } = useBizList();

  function handleChangeField(e) {
    onChangeField({
      target: {
        name: data?.fld || '',
        value: e.target.value
      }
    });
  }

  return (
    <SelectWithSearch
      label={caption}
      value={value}
      options={biz}
      disabled={loading}
      searchKeys={['description', 'value']}
      onChange={handleChangeField}
      CustomMenuItem={CustomMenuItem}
      error={error || apiError !== ''}
      errorMessage={
        Boolean(errorMessage) && errorMessage !== '' ? errorMessage : apiError
      }
    />
  );
}

export default BizList;
