import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  selectedTransType: ''
};

const slice = createSlice({
  name: 'daloy',
  initialState: INITIAL_STATE,
  reducers: {
    selectTransactionType(state, action) {
      state.selectedTransType = action.payload;
    },
    clear(state, action) {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const daloyReducer = slice.reducer;

export const { selectTransactionType, restore, clear } = slice.actions;
export default daloyReducer;
