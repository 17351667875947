import React, { useEffect } from 'react';
import {
  Card,
  IconButton,
  Button,
  makeStyles,
  InputAdornment,
  Typography,
  FormControlLabel,
  Switch,
  LinearProgress,
  Box,
  Tooltip,
  Link,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {
  ArrowForward,
  OpenInNew,
  VisibilityOffOutlined
} from '@material-ui/icons';
import { DataGrid, GridToolbarContainer } from '@material-ui/data-grid';
import { blue } from '@material-ui/core/colors';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import useWatch, { removeToWatchList } from 'src/hooks/useWatch';
import { useSelector, useDispatch } from 'react-redux';
import DebounceTextField from 'src/components/DebounceTextField';
import useNotif from 'src/hooks/useNotif';
import columns from './columns';
import moment from 'moment';
import ScrollBar from 'react-perfect-scrollbar';
import CustomChip from 'src/components/CustomChip';
import getColorFromMUI from 'src/utils/getColorFromMUI';
import getixJCd from 'src/helpers/getixJCd';
import { getWFColor, getWFStatus } from 'src/helper';
import {
  TransCardContainer,
  TransCard,
  TransActions,
  TransContent
} from 'src/components/TransCard';
import {
  setHasWatchedTransactions,
  setShowWatchedTransactions,
  setWatchedOnCardView,
  setWatchedShowUnseen
} from 'src/redux/slices/dashboard';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
  emptyImg: {
    width: '100px',
    height: 'auto',
    marginBottom: theme.spacing(2)
  },
  cursur: {
    cursor: 'pointer'
  },
  rowNewBorderColor: {
    borderLeft: `5px solid ${blue[500]}`,
    '& .MuiTableCell-root': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  rowBgColor: {
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: theme.palette.background.dark
    }
  }
}));

const LoadingOverlay = () => <LinearProgress style={{ height: 2 }} />;

const WatchList = ({ className, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    watchList,
    allWatchList,
    loading,
    query,
    limit,
    page,
    refresh,
    setQuery,
    onLimitChange,
    onPageChange
  } = useWatch();
  const { success: successNotif, errorNotif } = useNotif();
  const { base_url, userToken } = useSelector(({ auth }) => auth);
  const { wf } = useSelector(state => state.bizMeta);
  const {
    watchedTransactions: { showWatchedTransaction, showUnseen, onCardView }
  } = useSelector(state => state.dashboard);

  const remove = async jid => {
    const { success, msg } = await removeToWatchList({
      base_url,
      userToken,
      jid
    });

    if (success) {
      successNotif(msg);
      refresh();
    } else {
      errorNotif(msg);
    }
  };

  const onSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setTimeout(() => {
      handleToggleView(onSmallScreen);
    }, [100]);
  }, [onSmallScreen]);

  const handleToggleWatchedTransactions = e => {
    e.stopPropagation();
    dispatch(setShowWatchedTransactions(!showWatchedTransaction));
  };

  const handleToggleView = checked => {
    dispatch(setWatchedOnCardView(checked));
  };

  const handleToggleUnseen = checked => {
    dispatch(setWatchedShowUnseen(!checked));
  };

  const onChangeSearch = e => {
    onPageChange(e, 0);
    setQuery(e.target.value);
  };

  const getTransTypeColor = JCd => {
    const transTypes = Object.entries(wf);
    let color = {
      color: 'default',
      shade: 500
    };

    for (let i = 0; i < transTypes.length; i++) {
      if (transTypes[i][1].JCd === JCd) {
        color.color = transTypes[i][1]?.['wf-settings']?.color || 'default';
        color.shade = transTypes[i][1]?.['wf-settings']?.shade || 500;
        break;
      }
    }

    return color;
  };

  useEffect(() => {
    dispatch(setHasWatchedTransactions(allWatchList.length !== 0));
  }, [allWatchList]);

  const filteredWatchList = watchList.filter(rows =>
    showUnseen ? !rows?.lastview : rows?.lastview
  );

  return (
    <>
      <Box
        display="flex"
        gridGap="1em"
        flexWrap="wrap"
        alignItems="center"
        mt={3}
      >
        <DebounceTextField
          value={query}
          placeholder="Search..."
          variant="outlined"
          onChange={onChangeSearch}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            )
          }}
        />

        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={refresh}
          style={{
            marginRight: 'auto'
          }}
        >
          Refresh
        </Button>
        <FormControlLabel
          control={
            <Switch
              checked={onCardView}
              onChange={e => {
                handleToggleView(e.target.checked);
              }}
              size="small"
            />
          }
          labelPlacement="start"
          label={
            <Typography variant="h5" color="textSecondary">
              Card view
            </Typography>
          }
          style={{ marginRight: 0 }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={!showUnseen}
              onChange={e => {
                handleToggleUnseen(e.target.checked);
              }}
              size="small"
            />
          }
          labelPlacement="start"
          label={
            <Typography variant="h5" color="textSecondary">
              Viewed
            </Typography>
          }
          style={{ marginRight: 0 }}
        />
      </Box>

      <Box width="100%" overflow="auto">
        <Box mb={2}>
          <Card className={clsx(classes.root, className)} {...rest}>
            {onCardView ? (
              <Box p={2}>
                <ScrollBar>
                  <TransCardContainer mb={2} p={1} style={{ columnGap: '1em' }}>
                    {filteredWatchList.map(item => {
                      const color = getTransTypeColor(item.JCd);
                      const bgColor = getColorFromMUI(color);

                      return (
                        <TransCard
                          showCaption={item?.isNew}
                          color={bgColor}
                          key={item.jid}
                        >
                          <TransContent>
                            <Typography
                              variant="caption"
                              style={{
                                fontWeight: 700,
                                marginBottom: '4px'
                              }}
                            >
                              {item.Name}
                            </Typography>
                            <Typography variant="caption">
                              Type: {item.JCd}
                            </Typography>
                            <Typography variant="caption">
                              Ref #: {item.RefNo}
                            </Typography>
                            <Typography variant="caption">
                              Date: {moment(item.jDate).format('MMM D, YYYY')}
                            </Typography>
                            <CustomChip
                              color={getWFColor(
                                wf?.[getixJCd(item?.JCd, wf)]?.wf || {},
                                item.wfStatus
                              )}
                              label={getWFStatus(
                                wf?.[getixJCd(item?.JCd, wf)]?.wf || {},
                                item.wfStatus
                              )}
                              style={{
                                fontSize: '11px',
                                width: 'fit-content'
                              }}
                            />
                          </TransContent>
                          <TransActions>
                            <Tooltip title="Open">
                              <IconButton
                                size="small"
                                color="primary"
                                component={Link}
                                href={'/app/trans/' + item.jid}
                              >
                                <ArrowForward fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Open in new tab">
                              <IconButton
                                size="small"
                                color="primary"
                                component={Link}
                                href={'/app/trans/' + item.jid}
                                target="_blank"
                              >
                                <OpenInNew fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Unwatch">
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => remove(item.jid)}
                              >
                                <VisibilityOffOutlined fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TransActions>
                        </TransCard>
                      );
                    })}
                  </TransCardContainer>
                </ScrollBar>
              </Box>
            ) : (
              <DataGrid
                disableColumnMenu
                disableColumnFilter
                style={{ border: 'none' }}
                rows={watchList.filter(rows => {
                  if (!showUnseen) return true;
                  return !rows?.lastview;
                })}
                columns={columns(remove)}
                page={page}
                onPageChange={onPageChange}
                pageSize={limit}
                onPageSizeChange={onLimitChange}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                getRowId={row => row['jid']}
                autoHeight
                rowHeight={80}
                loading={loading}
                components={{
                  LoadingOverlay
                }}
                className={classes.rowBgColor}
                disableSelectionOnClick
                onRowDoubleClick={({ row }) => {
                  window.open(`/app/trans/${row.jid}`, '_blank');
                }}
              />
            )}
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default WatchList;
