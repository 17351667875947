import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  date: {
    textValue: '',
    dt1: '',
    dt2: ''
  },
  brch: {
    ixBrch: 0,
    sBrch: ''
  },
  activeBrch: {
    ixBrch: 0,
    sBrch: ''
  },
  activeVariant: {
    ixVariant: '',
    sVariant: ''
  },
  activeTransType: {
    ixJCd: '',
    sJCd: ''
  },
  variant: {
    ixVariant: '',
    sVariant: ''
  },
  transType: {
    ixJCd: '',
    sJCd: ''
  },
  chartType: 'bar',
  data: [],
  data2Shown: false,
  data2: [],
  data2Title: '',
  data3Title: '',
  user: ''
};

const slice = createSlice({
  name: 'wfStats',
  initialState: initialState,
  reducers: {
    setDate(state, action) {
      state.date = action.payload;
    },
    setVariant(state, action) {
      state.variant = action.payload;
    },
    setTransType(state, action) {
      state.transType = action.payload;
    },
    setActiveVariant(state, action) {
      state.activeVariant = action.payload;
    },
    setActiveTransType(state, action) {
      state.activeTransType = action.payload;
    },
    setChartType(state, action) {
      state.chartType = action.payload;
    },
    setBrch(state, action) {
      state.brch = action.payload;
    },
    setActiveBrch(state, action) {
      state.activeBrch = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setData2(state, action) {
      state.data2 = action.payload;
    },
    setData2Title(state, action) {
      state.data2Title = action.payload;
    },
    setData3Title(state, action) {
      state.data3Title = action.payload;
    },
    setData2Shown(state, action) {
      state.data2Shown = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    clear() {
      return initialState;
    },
    restore(_, action) {
      return action.payload || initialState;
    }
  }
});

const wfStatsReducer = slice.reducer;

export const {
  setDate,
  restore,
  clear,
  setVariant,
  setTransType,
  setChartType,
  setBrch,
  setActiveTransType,
  setActiveVariant,
  setActiveBrch,
  setData,
  setData2,
  setData2Shown,
  setData2Title,
  setData3Title,
  setUser
} = slice.actions;
export default wfStatsReducer;
