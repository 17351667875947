import React from 'react';
import CustomCheckbox from '../CustomCheckbox';

const Checkbox = ({ data, onChangeField }) => {
  return (
    <CustomCheckbox
      checked={data?.value || false}
      onChange={e =>
        onChangeField({
          target: {
            name: data.fld,
            value: e.target.checked
          }
        })
      }
    />
  );
};

export default Checkbox;
