import React from 'react';
import {
  Container,
  Typography,
  Box,
  makeStyles,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  particle2: {
    position: 'relative',
    zIndex: 10,
    '&:before': {
      content: '""',
      zIndex: -10,
      position: 'absolute',
      backgroundImage: 'url(/static/ibfrs3/dots1.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      aspectRatio: 1,
      height: 400,
      top: 0,
      left: '5%',
      transformOrigin: 'center',
      transform: 'rotate(-90deg)',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  },
  accountingParticle: {
    position: 'relative',
    zIndex: 10,
    '&:before': {
      content: '""',
      zIndex: -10,
      position: 'absolute',
      backgroundImage: 'url(/static/ibfrs3/particles/circle-dots.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      aspectRatio: 1,
      height: 200,
      top: 0,
      right: '-15%',
      transformOrigin: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  },
  sectionTitle: {
    fontSize: '3rem',
    fontWeight: 900,
    textAlign: 'center',
    '& .highlight': {
      color: theme.palette.primary.main,
      borderBottom: '4px solid ' + theme.palette.primary.main
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem'
    }
  },
  anchorTitle: {
    fontSize: '2.6rem',
    fontWeight: 900,
    marginBottom: '0.6em',
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.8rem'
    }
  },
  anchorDescription: {
    fontSize: '1.8rem',
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.2rem'
    }
  }
}));

/**
 *
 * @param {object} param
 * @param {pageAnchors} param.pageDetails
 * @param {number} param.ixPage
 * @returns
 */
const AnchorsTl1 = ({ pageDetails, ixPage }) => {
  const classNames = useStyles();

  return (
    <Box
      minHeight={600}
      py={10}
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classNames.particle2}
    >
      <Container maxWidth="lg">
        <Typography className={classNames.sectionTitle}>
          {Boolean(pageDetails?.title1?.text) && (
            <>
              <span
                className={pageDetails?.title1?.highlight ? 'highlight' : ''}
              >
                {pageDetails.title1.text}
              </span>{' '}
            </>
          )}
          {Boolean(pageDetails?.title2?.text) && (
            <>
              <span
                className={pageDetails?.title2?.highlight ? 'highlight' : ''}
              >
                {pageDetails.title2.text}
              </span>{' '}
            </>
          )}
          {Boolean(pageDetails?.title3?.text) && (
            <span className={pageDetails?.title3?.highlight ? 'highlight' : ''}>
              {pageDetails.title3.text}
            </span>
          )}
        </Typography>
        {(pageDetails?.items || []).map((anchor, index) => (
          <Box mt={8} key={anchor.id}>
            <Container maxWidth="lg">
              <Grid
                container
                spacing={10}
                justifyContent="space-between"
                direction={index % 2 === 0 ? 'row' : 'row-reverse'}
              >
                <Grid item lg={6} md={6} sm={10} xs={12}>
                  <Box>
                    <Typography className={classNames.anchorTitle}>
                      {anchor.title}
                    </Typography>
                    <Typography className={classNames.anchorDescription}>
                      {anchor.description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={10} xs={12}>
                  <img
                    src={
                      anchor?.staticImg
                        ? anchor?.img
                        : `/api/pages/${ixPage}/images/${anchor?.img}`
                    }
                    width={'100%'}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        ))}
      </Container>
    </Box>
  );
};

export default AnchorsTl1;
