import { utils, writeFileXLSX, JSON2SheetOpts, WritingOptions } from 'xlsx';

/**
 * @param {object} param
 * @param {Array<{data: any[], options: JSON2SheetOpts}>} param.data
 * @param {string} param.fileName
 * @param {string[]} param.sheetNames
 * @param {WritingOptions} param.options
 */
function jsonToExcel({
  data = [],
  fileName = 'download',
  sheetNames = [],
  options = {}
}) {
  const wb = utils.book_new();

  for (let i = 0; i < data.length; i++) {
    const ws = utils.json_to_sheet(data[i].data, data[i]?.options || {});

    utils.book_append_sheet(wb, ws, sheetNames?.[i] || 'Sheet' + (i + 1));
  }

  writeFileXLSX(wb, fileName + '.xlsx', options);
}

export default jsonToExcel;
