import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Container
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import useReveal, { Animated } from 'src/hooks/useReveal';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    paddingTop: 100,
    paddingBottom: 100,
    background: '#F8FBFB'
  },
  description: {
    lineHeight: '1.9rem',
    marginTop: theme.spacing(2)
  },
  headerTitle: {
    display: 'block',
    fontWeight: 900
  },
  image: {
    width: '100%',
    height: '170px'
  },
  mainTitle: {
    fontSize: '1.8rem',
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  paperComponent: {
    position: 'block',
    height: '100%',
    background: '#fff',
    transition: 'all 250ms ease-in-out',
    boxShadow: `rgba(255, 119, 4, 0.2) 0px 10px 15px -3px, rgba(255, 119, 4, 0.05) 0px 4px 6px -2px`,
    '&:hover': {
      boxShadow: `rgba(255, 119, 4, 0.25) 0px 25px 50px -12px`
    }
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Card = ({ title, icon, description, delay }) => {
  const classes = useStyles();
  const [ref, reveal] = useReveal({ threshold: 0.1 });

  return (
    <Animated
      config={{ duration: 500 }}
      to={{ opacity: reveal ? 1 : 0, x: reveal ? 0 : -100 }}
      delay={delay}
    >
      <Box className={classes.paperComponent} component="div" ref={ref}>
        <Box px={4} pt={4} pb={4}>
          <FontAwesomeIcon icon={icons[icon]} size="2x" color="#FF7704" />
        </Box>
        <Box px={4} pb={4}>
          <Typography
            gutterBottom
            color="textPrimary"
            className={classes.headerTitle}
            variant="body1"
          >
            {title}
          </Typography>
          <Box mt={1}>
            <Typography
              variant="body2"
              className={classes.description}
              color="textSecondary"
            >
              {description}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Animated>
  );
};

const features = [
  {
    title: 'Accessible Anytime, Anywhere',
    icon: 'faHouseLaptop',
    description: `Certain businessmen can view their transactions outside
                of their workplace. Ulap.Biz helps businessmen to
                monitor their operations online.`
  },
  {
    title: 'Income and Expense Tracker',
    icon: 'faHandHoldingDollar',
    description: `Businessmen can now check how much they earned and spent
    through tracking their Sales and Expenses.`
  },
  {
    title: 'Better Business Judgment',
    icon: 'faScaleBalanced',
    description: `A quality information can do away with poor judgments.
    Only those timely, relevant, and accurate financial
    details can contribute to quality of an information that
    leads to better and decisions making.`
  },
  {
    title: 'Organized Process Flows',
    icon: 'faCodeBranch',
    description: `Having no definite function of an employee may signify
    lack of internal control that may increase the
    susceptibility of irregularities. The system identifies
    responsibilities and assignments that separate
    preparation, review, authorization, custodial, and
    verification functions of the company.`
  },
  {
    title: 'Paperless / Less Paper',
    icon: 'faFileCircleXmark',
    description: `Being Paperless signifies the presence of streamlined
    processes, reduce transaction error rates, and eliminate
    document storage. Companies now tend to move in the
    general direction of paperless operations over time by
    implementing paperless improvements to existing systems.`
  },
  {
    title: ' User-Friendly',
    icon: 'faThumbsUp',
    description: `Businessmen may be hesitant to invest in an online system,
    due to the perception that it is complicated to use.
    Ulap.Biz is an accounting system that even non-accountants
    can utilize and understand.`
  }
];

const Features = ({ className, onGotoProductLine, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box py={3}>
        <Container maxWidth="lg">
          <Grid container spacing={6}>
            {features.map((data, i) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.centerContent}
                key={data.title}
              >
                <Card delay={100 * i} {...data} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
