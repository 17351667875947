import React from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Avatar,
  Divider,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useParams } from 'react-router-dom';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import useInviteUserRegistration from './useInviteUserRegistration';
import CreateUserForm from './CreateUserForm';
import OTP from './OTP';
import ActivationSuccess from '../UserRegistration/ActivationSuccess';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,

    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  avatar: {
    width: '6rem',
    height: '6rem',
    margin: '0 auto'
  }
}));

const InviteUserRegistration = () => {
  const classes = useStyles();
  const { inviteCode } = useParams();

  const {
    inviteDetails,
    isGettingInviteDetails,
    isGetInvitationDetailFailed,
    ...rest
  } = useInviteUserRegistration({
    inviteCode
  });

  if (isGettingInviteDetails) {
    return (
      <Page className={classes.root} title="User Invitation">
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={40} />
        </Box>
      </Page>
    );
  }

  if (isGetInvitationDetailFailed) {
    return (
      <Page className={classes.root} title="User Invitation">
        <Container maxWidth="sm">
          <Alert severity="error">
            <AlertTitle>Invite Invalid</AlertTitle>
            This invite may be expired, or the invite code is invalid. Please
            request a new invitation link to proceed.
          </Alert>
        </Container>
      </Page>
    );
  }

  return (
    <Page className={classes.root} title="User Invitation">
      <Container maxWidth="sm">
        <Box
          my={3}
          display="flex"
          flexDirection="column"
          style={{ gap: '1em' }}
        >
          <Box>
            <Avatar
              src={`/api/images/biz/${inviteDetails?.biz?.ixImage}/logo.jpg`}
              className={classes.avatar}
            >
              <BusinessOutlinedIcon fontSize="large" />
            </Avatar>
          </Box>
          <Box>
            <Typography variant="h3" color="textPrimary" align="center">
              {inviteDetails?.biz?.Name}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              {inviteDetails?.biz?.Address1}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              {inviteDetails?.biz?.Address2}
            </Typography>
          </Box>
        </Box>
        <Divider />
        {!rest.saveSuccess && (
          <CreateUserForm inviteDetails={inviteDetails} {...rest} />
        )}

        {rest.saveSuccess && !rest?.activationSuccess && <OTP {...rest} />}

        {rest?.saveSuccess && rest?.activationSuccess && (
          <ActivationSuccess {...rest} />
        )}
      </Container>
    </Page>
  );
};

export default InviteUserRegistration;
