import { createSlice } from '@reduxjs/toolkit';
import { APIRequestV2, cancelAPIRequest } from 'src/helpers/APIRequest';

const INITIAL_STATE = {
  loading: false,
  lstAcc: null,
  agingReportData: null,
  formFilterData: {
    ixAcc: null,
    dt1: null,
    dt2: null,
    textValue: '',
    showDueOnly: true,
    showUnreferencedReturns: true
  },
  brch: {
    required: false,
    ixBrch: 0,
    label: ''
  },
  selectedLstAcc: '',
  showDetails: true,
  errorStatus: false,
  errorMsg: '',
  page: 0,
  limit: 25
};

const slice = createSlice({
  name: 'aging',
  initialState: INITIAL_STATE,
  reducers: {
    onFetchAccounts(state, actions) {
      state.loading = true;
    },
    loadAccounts(state, action) {
      state.lstAcc = action.payload;
      state.loading = false;
      state.errorMsg = '';
      state.errorStatus = false;
    },
    loadError(state, action) {
      state.errorStatus = true;
      state.errorMsg = action.payload.message;
      state.loading = false;
    },
    resetReport(state, action) {
      return { ...INITIAL_STATE, lstAcc: state.lstAcc };
    },
    selectAccount(state, action) {
      state.selectedLstAcc = action.payload;
      state.formFilterData.ixAcc = action.payload;
    },
    setDateRange(state, action) {
      state.formFilterData.dt1 = action.payload.dt1;
      state.formFilterData.dt2 = action.payload.dt1;
      state.formFilterData.textValue = action.payload.textValue;
    },
    loadData(state, action) {
      state.loading = false;
      state.agingReportData = action.payload;
    },
    onFetchReport(state, action) {
      state.loading = true;
    },
    clearReport(state, action) {
      state.agingReportData = null;
    },
    clearError(state, action) {
      state.loading = false;
      state.errorStatus = false;
      state.errorMsg = '';
    },
    showDueOnly(state, action) {
      state.formFilterData.showDueOnly = action.payload;
    },
    showUnreferencedReturns(state, action) {
      state.formFilterData.showUnreferencedReturns = action.payload;
    },
    showDetails(state, action) {
      state.showDetails = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
    setBrch(state, action) {
      state.brch.label = action.payload.label;
      state.brch.required = action.payload.required;
    },
    changeBrch(state, action) {
      state.brch.ixBrch = action.payload;
    },
    cancelRequest(state, action) {
      state.loading = false;
    },
    clear(state, action) {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

export const fetchAccountList = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const { loadError, loadAccounts } = slice.actions;

  const { success, data } = await APIRequestV2({
    url: `${base_url}/reports/aging/init`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-access-tokens': userToken
    }
  });

  if (!success) {
    dispatch(
      loadError({
        message: `Error -  Something went wrong`
      })
    );
  }

  dispatch(loadAccounts(data.lstAcc));
};

export const fetchReport = () => async (dispatch, getState) => {
  const { formFilterData, brch } = getState().aging;
  const { base_url, userToken } = getState().auth;
  const {
    clearReport,
    clearError,
    onFetchReport,
    loadData,
    loadError
  } = slice.actions;

  if (formFilterData.ixAcc === null) {
    dispatch(loadError({ message: 'Please select account.' }));
    return;
  }

  if (brch.required && brch.ixBrch === '') {
    dispatch(loadError({ message: `${brch.label} is required.` }));
    return;
  }

  if (formFilterData.dt1 === null || formFilterData.dt2 === null) {
    dispatch(
      loadError({
        message: 'Start date or End date is empthy.'
      })
    );
    return;
  }

  const dt1 = new Date(formFilterData.dt1).getTime();
  const dt2 = new Date(formFilterData.dt2).getTime();

  if (dt1 > dt2) {
    dispatch(
      loadError({
        message: 'Start date cannot be greater than end date'
      })
    );
    return;
  }

  dispatch(clearReport());
  dispatch(clearError());
  dispatch(onFetchReport());

  const payload = { ...formFilterData };

  if (brch?.required) payload.ixBrch = brch.ixBrch;

  const { success, data, error, isCancelled } = await APIRequestV2({
    method: 'POST',
    url: `${base_url}/reports/aging`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    },
    data: payload
  });

  if (!success && isCancelled) return;
  else if (!success) dispatch(loadError({ message: error.data }));
  else dispatch(loadData(data));
};

export const cancelRequest = () => (dispatch, getState) => {
  const action = slice.actions;
  if (cancelAPIRequest) cancelAPIRequest();
  dispatch(action.cancelRequest());
};

const agingReducer = slice.reducer;
export const {
  onFetchAccounts,
  loadAccounts,
  loadError,
  resetReport,
  selectAccount,
  setDateRange,
  loadData,
  onFetchReport,
  clearReport,
  clearError,
  showDueOnly,
  showUnreferencedReturns,
  showDetails,
  setPage,
  setLimit,
  setBrch,
  changeBrch,
  restore,
  clear
} = slice.actions;
export default agingReducer;
