import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ErrorPage, Loading, Page } from 'src/components';
import { useEnv, useGlobalStyles, useRequest, useToggle } from 'src/hooks';

const useStyles = makeStyles(theme => ({
  welcome: {
    fontSize: '3.4rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: 20,
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.6rem'
    }
  },
  icon: {
    height: 180,
    width: 180,
    [theme.breakpoints.only('xs')]: {
      height: 140,
      width: 140
    }
  },
  page: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(4)
  }
}));

const Welcome = () => {
  const { EGOV } = useEnv();
  const { cdBiz, cdPortal } = useParams();

  const { page } = useGlobalStyles();
  const classNames = useStyles();

  const [portalDetails, setPortalDetails] = useState({
    biz: '',
    sPortal: ''
  });
  const [error, setError] = useState({ message: '', status: 0 });

  const [isLoading, loadingOn, loadingOff] = useToggle(true);
  const request = useRequest(loadingOn, loadingOff);

  const logoFallback = EGOV
    ? '/static/images/iBFRS.png'
    : '/static/images/landingpage/sign-in-logo.png';

  useEffect(() => {
    let cancel = () => {};

    (async function() {
      const res = await request.get(`/portal/${cdBiz}/${cdPortal}/welcome`);

      if (res.success) setPortalDetails(res.data);
      else if (!res.isCancelled)
        setError({ message: res.errorMessage, status: res.error.status });
    })();

    return cancel;
  }, []);

  if (isLoading) return <Loading />;

  if (error.status && error.status === 404)
    return (
      <ErrorPage error={error.message || 'Not Found!'} showButton={false} />
    );

  if (error.status && error.status === 500)
    return (
      <ErrorPage
        error={error.message || 'Internal Server Error'}
        showButton={false}
        image="/static/illustrations/fixing_bugs.svg"
      />
    );

  return (
    <Page className={clsx(page, classNames.page)} title="Welcome">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        maxWidth={500}
      >
        <img
          src={`/api/portal/${cdBiz}/${cdPortal}/images/active_portal_logo.png`}
          alt="LOGO"
          onError={e => {
            e.target.onerror = null;
            e.target.src = logoFallback;
          }}
          className={classNames.icon}
        />
        <Box mt={3}>
          <Typography
            align="center"
            color="primary"
            className={classNames.welcome}
          >
            Welcome
          </Typography>
          <Typography
            align="center"
            variant="h2"
            color="primary"
            style={{ fontWeight: 'bold' }}
          >
            {portalDetails.sPortal}
          </Typography>
        </Box>
        <Button
          fullWidth
          className={classNames.button}
          component={Link}
          variant="outlined"
          color="primary"
          size="large"
          to={`/portal/${cdBiz}/${cdPortal}/login`}
        >
          Login
        </Button>
      </Box>
    </Page>
  );
};

export default Welcome;
