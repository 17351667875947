import React from 'react';
import { Box, Typography, makeStyles, Container } from '@material-ui/core';
import useReveal, { Animated } from 'src/hooks/useReveal';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#268ABC',
    paddingTop: 60,
    paddingBottom: 60
  },
  description: {
    lineHeight: '1.3rem',
    marginTop: theme.spacing(2)
  },
  headerTitle: {
    display: 'block',
    fontWeight: 900,
    fontSize: '2em',
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5em'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '1.5em'
    }
  },
  image: {
    width: '100%',
    height: 'auto'
  },
  mainTitle: {
    fontSize: '1.8rem',
    fontWeight: 500,
    textTransform: 'uppercase'
  }
}));

const MainHighLight = ({ title, image, hideImage = false }) => {
  const classes = useStyles();
  const [ref, reveal] = useReveal({ threshold: 0.1 });

  return (
    <div className={classes.root} ref={ref}>
      <Container maxWidth="lg">
        <Animated
          to={{
            opacity: reveal ? 1 : 0,
            y: reveal ? 0 : -50
          }}
          config={{
            duration: 1000
          }}
        >
          <Typography
            variant="h1"
            className={classes.headerTitle}
            align="center"
          >
            {title}
          </Typography>
        </Animated>
        {!hideImage && (
          <Animated
            to={{
              opacity: reveal ? 1 : 0,
              y: reveal ? 0 : 50
            }}
            config={{
              duration: 1000
            }}
          >
            <Box mt="3em">
              <img src={image} className={classes.image} alt="highlight" />
            </Box>
          </Animated>
        )}
      </Container>
    </div>
  );
};

export default MainHighLight;
