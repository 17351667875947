import React, { useEffect, useRef } from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

const BenefitsPage = () => {
  const navigate = useNavigate();
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [ref]);

  return (
    <div id="benefits" className="services section" ref={ref}>
      <Container maxWidth="lg" style={{ marginBottom: 40 }}>
        <Grid container justifyContent="center">
          <Grid item lg={8}>
            <div
              className="section-heading  wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <Typography variant="h4">
                <em>Benefits</em>
              </Typography>
              <img
                src="/static/ibfrs/assets/images/heading-line-dec.png"
                alt=""
              />
            </div>
          </Grid>
        </Grid>
        <Box mt={5} mb={5} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowBack />}
            size="large"
            onClick={() => navigate('/benefits')}
          >
            Back
          </Button>
        </Box>
      </Container>
      <Container maxWidth="lg" style={{ marginBottom: 40 }}>
        <Grid
          container
          spacing={4}
          alignItems="stretch"
          justifyContent="center"
        >
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="service-item first-service">
              <div className="icon"></div>
              <Typography variant="h4">Save Time</Typography>
              <Typography variant="body1">
                Eliminates manual and repetitive tasks, such as data entry,
                calculations, and report generation. It allows employees to
                focus on more value-added activities like analysis,
                decision-making, and strategic planning.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="service-item second-service">
              <div className="icon"></div>
              <Typography variant="h4">Streamline Processes</Typography>
              <Typography variant="body1">
                Enables integration of various financial functions, such as
                budgeting, expense tracking, revenue management, and reporting.
                This streamlines processes by eliminating the need for data
                duplication, manual reconciliations, and transferring
                information between different systems or departments.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="service-item third-service">
              <div className="icon"></div>
              <Typography variant="h4">
                Improve Reporting and Analysis
              </Typography>
              <Typography variant="body1">
                System generated reports, making it easier to analyze
                budget/financial data and extract meaningful insights. It allows
                for faster and more accurate analyses, enabling informed
                decision-making and strategic planning.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="service-item fourth-service">
              <div className="icon"></div>
              <Typography variant="h4">Realtime Monitoring</Typography>
              <Typography variant="body1">
                Provides realtime access to budget/financial data, allowing
                government officials to have up-to-date information on budgets,
                spending, revenue, and overall financial performance. Realtime
                monitoring enables proactive decision-making, timely corrections
                and resource allocation.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="service-item first-service">
              <div className="icon"></div>
              <Typography variant="h4">Accessible Anytime, Anywhere</Typography>
              <Typography variant="body1">
                Certain businessmen can view their transactions outside of their
                workplace. Ulap.Biz helps businessmen to monitor their
                operations online.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="service-item second-service">
              <div className="icon"></div>
              <Typography variant="h4">Income and Expense Tracker</Typography>
              <Typography variant="body1">
                Businessmen can now check how much they earned and spent through
                tracking their Sales and Expenses.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="service-item third-service">
              <div className="icon"></div>
              <Typography variant="h4">Better Business Judgment</Typography>
              <Typography variant="body1">
                A quality information can do away with poor judgments. Only
                those timely, relevant, and accurate financial details can
                contribute to quality of an information that leads to better and
                decisions making.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="service-item fourth-service">
              <div className="icon"></div>
              <Typography variant="h4">Organized Process Flows</Typography>
              <Typography variant="body1">
                Having no definite function of an employee may signify lack of
                internal control that may increase the susceptibility of
                irregularities. The system identifies responsibilities and
                assignments that separate preparation, review, authorization,
                custodial, and verification functions of the company.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="service-item first-service">
              <div className="icon"></div>
              <Typography variant="h4">Paperless / Less Paper</Typography>
              <Typography variant="body1">
                Being Paperless signifies the presence of streamlined processes,
                reduce transaction error rates, and eliminate document storage.
                Companies now tend to move in the general direction of paperless
                operations over time by implementing paperless improvements to
                existing systems.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="service-item second-service">
              <div className="icon"></div>
              <Typography variant="h4">User-Friendly</Typography>
              <Typography variant="body1">
                Businessmen may be hesitant to invest in an online system, due
                to the perception that it is complicated to use. Ulap.Biz is an
                accounting system that even non-accountants can utilize and
                understand.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default BenefitsPage;
