import { Navigate } from 'react-router';
import LoginView from 'src/views/auth/LoginView';
import ResetPassword from 'src/views/auth/ResetPassword';
import ActivatePhone from 'src/views/auth/ActivatePhone';
import UserRegistration from 'src/views/auth/UserRegistration';
import InviteUserRegistration from 'src/views/auth/InviteUserRegistration';
import VerifyEmail from 'src/views/auth/VerifyEmail';
import LandingPageLayout from 'src/layouts/LandingPageLayout';

const appLoginRoutes = [
  {
    path: 'app',
    children: [
      {
        path: '',
        element: Navigate,
        elementProps: { to: '/app/login' }
      },
      {
        path: 'login',
        element: LoginView
      },
      {
        path: 'reset-password',
        element: ResetPassword
      },
      {
        path: 'activate-phone-number',
        element: ActivatePhone
      },
      {
        path: 'verify-email/:cd/:email',
        element: VerifyEmail
      },

      {
        path: 'register/:inviteCode',
        element: InviteUserRegistration
      },
      {
        path: '*',
        element: LoginView
      }
    ]
  },
  {
    path: 'app',
    element: LandingPageLayout,
    children: [
      {
        path: 'register',
        element: UserRegistration
      }
    ]
  }
];

export default appLoginRoutes;
