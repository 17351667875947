import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  ulapBizImage: {
    height: 150,
    animation: `$myEffect 1000ms ${theme.transitions.easing.easeInOut} infinite alternate`,

    [theme.breakpoints.down('md')]: {
      height: 100
    },
    [theme.breakpoints.down('sm')]: {
      height: 100
    }
  },
  '@keyframes myEffect': {
    '0%': {
      transform: 'translateY(0)'
    },
    '100%': {
      transform: 'translateY(-1.5em)'
    }
  }
}));

const AppLoadingOverlay = () => {
  const classNames = useStyles();

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <img
        alt="logo"
        src="/static/images/landing-page/ulap-biz-logo.png"
        className={classNames.ulapBizImage}
      />
    </Box>
  );
};

export default AppLoadingOverlay;
