import { isEmpty } from 'lodash';

function flatTable(items = [], settings = {}) {
  const tableKey = settings?.table_key ?? '';
  const rows = [];

  if (tableKey === '') return items;

  const data = items.map(item => ({
    ...item,
    [tableKey]: JSON.parse(item[tableKey])
  }));

  data.forEach(props => {
    const rawTable = props[tableKey];

    if (typeof rawTable === 'undefined') {
      rows.push(props);
      return;
    }

    const flattenedResult = rawTable.map(tableProps =>
      Object.entries(tableProps).reduce((acc, [key, value]) => {
        if (typeof value === 'object') {
          Object.entries(value).forEach(([obj_key, obj_value]) => {
            acc[`${tableKey}.${key}.${obj_key}`] = obj_value;
          });
        } else acc[key] = value;
        return acc;
      }, {})
    );

    const propsToInclude = { ...props };
    delete propsToInclude[tableKey];

    flattenedResult.forEach(row => rows.push({ ...row, ...propsToInclude }));
  });

  return rows;
}

function groupByFields(data = [], settings) {
  const { flds, sumFlds } = settings;

  return data.reduce((acc, prev) => {
    const index = acc.findIndex(item =>
      flds.every(grpItem => item[grpItem] === prev[grpItem])
    );

    if (index !== -1) {
      if (sumFlds) {
        sumFlds.forEach(fld => {
          acc[index][fld] = parseFloat(acc[index][fld]) + parseFloat(prev[fld]);
        });
      }
    } else acc.push(prev);

    return acc;
  }, []);
}

function groupByKeyID(data, settings) {
  const res = [];
  const groups = Object.keys(settings);

  groups.forEach(group => {
    const grouped = [];

    const groupIds = data.reduce((accumulator, item) => {
      if (!accumulator.includes(item[group])) accumulator.push(item[group]);
      return accumulator;
    }, []);

    groupIds.forEach(groupId => {
      grouped.push(data.filter(item => item[group] === groupId));
    });

    grouped.forEach(groupItems => {
      // TODO: remove hard coded values below
      const item = { ...groupItems[0], align: 'right', numeral: '0,0.00' };

      groupItems.forEach(groupItem => {
        const {
          convertValToColumns,
          defaultColumnValue,
          customColumnValue = {}
        } = settings[group];

        const groupValue = groupItem[convertValToColumns];

        if (groupValue in customColumnValue) {
          const valSettings = customColumnValue[groupValue];

          const jsonVal = valSettings?.parseJson
            ? JSON.parse(groupItem[customColumnValue[groupValue].value])
            : groupItem[customColumnValue[groupValue].value];

          if (valSettings?.type === 'array') {
            (valSettings?.generateColumns || []).forEach(
              ({ prop, renderValue }) => {
                if (renderValue?.operation === 'sum')
                  item[prop] = jsonVal.reduce(
                    (total, current) => total + current[renderValue?.of],
                    0
                  );
              }
            );
          }

          item[groupValue] = jsonVal;
        } else item[groupValue] = groupItem[defaultColumnValue];
      });

      res.push(item);
    });
  });

  return res;
}

function transposeData(data = [], settings) {
  const { columnFields, columnHeaderFields, amountKey, groupBy } = settings;
  let items = [];

  if (!groupByFields && !amountKey) return { items: data, columns: {} };

  const columns = data.reduce((acc, prev) => {
    const newField = columnFields.map(fld => prev[fld]).join('_');
    if (Object.keys(acc).includes(newField)) return acc;

    acc = {
      ...acc,
      [newField]: columnHeaderFields
        .map(fld => prev[fld])
        .filter(item => item !== '')
        .join(' - ')
    };

    return acc;
  }, {});

  data.forEach(item => {
    let props = {};

    Object.keys(columns).forEach(col => {
      const splittedField = col.split('_');
      if (columnFields.every((fld, index) => item[fld] == splittedField[index]))
        props[col] = item[amountKey];
      else props[col] = 0;
    });

    items.push({
      ...props,
      ...item
    });
  });

  if (groupBy) {
    items = groupByFields(items, {
      flds: groupBy,
      sumFlds: Object.keys(columns)
    });
  }

  return { columns, items };
}

function processAfterLoad({ data = [], after_load = {}, flds = [] }) {
  const { groupBy = {}, flat_kvs_table = {}, transpose = {} } = after_load;
  let res = data;
  let fields = [...flds];
  let addl_totals = {};

  if (isEmpty(after_load)) return { data, flds };

  if (!isEmpty(flat_kvs_table)) res = flatTable(res, flat_kvs_table);

  if (groupBy?.flds) res = groupByFields(res, groupBy);

  if (groupBy?.key_id) res = groupByKeyID(res, groupBy);

  if (!isEmpty(transpose)) {
    const { items, columns } = transposeData(res, transpose);
    res = items;

    Object.entries(columns).forEach(([key, value]) => {
      fields.push({
        align: 'right',
        fld: key,
        total: true,
        heading: value,
        numeral: '0,0.00'
      });

      addl_totals = {
        ...addl_totals,
        [key]: items.reduce((acc, prev) => acc + prev[key], 0)
      };
    });
  }

  if ((after_load?.exclude ?? []).length > 0) {
    fields = fields.filter(
      fld => !(after_load?.exclude || []).includes(fld.fld)
    );
  }

  if ((after_load?.include ?? []).length > 0) {
    fields = [...fields, ...(after_load?.include ?? [])];
    (after_load?.include ?? []).forEach(({ fld, total = false }) => {
      if (total) {
        addl_totals = {
          ...addl_totals,
          [fld]: res.reduce((acc, prev) => acc + prev[fld], 0)
        };
      }
    });
  }

  return {
    data: res,
    flds: fields,
    addl_totals
  };
}

export default processAfterLoad;
