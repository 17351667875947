import { useSelector } from 'react-redux';
import { Request } from 'src/helpers';

const usePortalRequest = (
  loadingOn = () => {},
  loadingOff = () => {},
  extraConfig = {}
) => {
  const { base_url, portal } = useSelector(state => state.auth);

  const portalToken = portal?.token || null;

  const config = {
    headers: {
      'x-access-tokens': portalToken,
      'Content-Type': 'application/json'
    },
    ...extraConfig
  };

  const loadingDisplay = {
    display: loadingOn,
    hide: loadingOff
  };

  const request = new Request(base_url, config, loadingDisplay);

  return request;
};

export default usePortalRequest;
