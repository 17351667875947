import React, { useState, useEffect, useRef } from 'react';
import { TextareaAutosize, Box, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import useTextEditorSpecialFunctions from 'src/hooks/useTextEditorSpecialFunctions';
import useNotif from 'src/hooks/useNotif';

const JsonEditor = ({
  value = {},
  onChange = () => {},
  disabled = false,
  styles = {},
  onError = () => null,
  forceChangeValue = 0,
  save = () => {}
}) => {
  const [json, setJson] = useState('');
  const [error, setError] = useState({ status: false, msg: '' });

  const beautifyJson = () => {
    try {
      setJson(JSON.stringify(JSON.parse(json), null, 2));
      onChange(JSON.parse(json, null, 2));
    } catch (error) {
      setError({
        status: true,
        msg: 'Invalid JSON format ' + error.message
      });
    }
  };

  const { ref, onKeyDown } = useTextEditorSpecialFunctions({
    save,
    error,
    beautifyJson
  });

  useEffect(() => {
    setJson(JSON.stringify(value === '' ? {} : value, null, 2));
  }, []);

  useEffect(() => {
    setJson(JSON.stringify(value === '' ? {} : value, null, 2));
  }, [forceChangeValue]);

  useEffect(() => {
    onError({
      hasErrors: error.status,
      msg: error.msg
    });
  }, [error.status]);

  const handleChangeJson = e => {
    const newJSONValue = e.target.value;

    setJson(newJSONValue);

    try {
      if (newJSONValue === '') {
        return;
      }

      onChange(JSON.parse(newJSONValue));
      setError({
        status: false,
        msg: ''
      });
    } catch (error) {
      setError({
        status: true,
        msg: 'Invalid JSON format ' + error.message
      });
    }
  };

  const getStatus = () => {
    if (error.status) return 'red';
    return 'green';
  };

  return (
    <Box>
      <TextareaAutosize
        value={json}
        onChange={handleChangeJson}
        readOnly={disabled}
        disabled={disabled}
        className={styles}
        onKeyDown={onKeyDown}
        ref={ref}
        style={
          disabled
            ? {}
            : {
                border: `.2em solid ${getStatus()}`,
                outline: 'none',
                tabSize: 2
              }
        }
      />

      {error.status ? (
        <Box mt={1}>
          <Alert variant="outlined" severity="error">
            {error.msg}
          </Alert>
        </Box>
      ) : null}

      {!disabled && !error.status && (
        <Button onClick={beautifyJson} color="primary" fullWidth>
          Beautify JSON
        </Button>
      )}
    </Box>
  );
};

export default JsonEditor;
