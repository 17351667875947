import { createSlice } from '@reduxjs/toolkit';
import Request from 'src/helpers/Request';

const INITIAL_STATE = {
  jv_wf_assigned_loading: false,
  jv_wf_assigned: {},
  jv_wf_assigned_msg: ''
};

const slice = createSlice({
  name: 'wfAssigned',
  initialState: INITIAL_STATE,
  reducers: {
    onFetchWFAssigned(state, action) {
      state.jv_wf_assigned_loading = true;
    },
    loadError(state, action) {
      state.jv_wf_assigned_loading = false;
      state.jv_wf_assigned_msg = action.payload;
    },
    loadData(state, action) {
      state.jv_wf_assigned = action.payload;
      state.jv_wf_assigned_msg = '';
      state.jv_wf_assigned_loading = false;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    },
    clear(state, action) {
      return INITIAL_STATE;
    }
  }
});

export const getWFAssignedTransactions = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const { loadError, loadData, onFetchWFAssigned } = slice.actions;
  dispatch(onFetchWFAssigned());
  const config = {
    headers: {
      'x-access-tokens': userToken,
      'Content-Type': 'application/json'
    }
  };

  const request = new Request(base_url, config);
  const { success, data, error } = await request.get('/trans/wf/assigned');

  if (!success) {
    dispatch(loadError(error?.data?.message || 'Something went wrong.'));
    return;
  }

  dispatch(loadData(data));
};

const wfAssignedReducer = slice.reducer;
export const { clear, restore } = slice.actions;
export default wfAssignedReducer;
