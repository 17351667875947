import { createSlice } from '@reduxjs/toolkit';
import { Request } from 'src/helpers';

const INITIAL_STATE = {
  initialDataFilters: null,
  specialJournalReportData: {
    accFlds: [],
    tableData: [],
    tableFields: [],
    tableTotals: {},
    numFields: [],
    tableDataWithSundry: [],
    feildsInprops: {}
  },
  brch: {
    required: false,
    ixBrch: 0,
    label: ''
  },
  pur_terms: [],
  sales_terms: [],
  selectedReport: '',
  selectedStatus: 1,
  page: 0,
  limit: 25,
  loading: false,
  error: false,
  errorMessage: '',
  columnFilters: {},
  showAllSundryStatus: false,
  formFilterData: {
    ixSPEJ: '',
    ixStatus: 1,
    dt1: null,
    dt2: null,
    textValue: ''
  }
};

function showSundryHelper(states, jid) {
  const copyListReport = [...states];
  const index = copyListReport.findIndex(data => data.ID === jid);
  const list = copyListReport[index];
  list.showSundry = !list.showSundry;
  copyListReport[index] = list;

  return copyListReport;
}

function showAllSundryHelper(states, payload) {
  return states.map(data => {
    return {
      ...data,
      showSundry: !payload
    };
  });
}

const slice = createSlice({
  name: 'specialJournals',
  initialState: INITIAL_STATE,
  reducers: {
    fetchInitialDataFilters(state) {
      state.error = false;
      state.errorMessage = '';
    },
    fetchInitialDataFiltersSuccess(state, action) {
      state.initialDataFilters = action.payload;
      state.loading = false;
      state.error = false;
      state.errorMessage = '';
    },
    fetchInitialDataFiltersFailed(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload.message;
    },
    setSelectedReport(state, action) {
      state.selectedReport = action.payload;
      state.formFilterData = {
        ...state.formFilterData,
        ixSPEJ: action.payload
      };
    },
    setSelectedStatus(state, action) {
      state.selectedStatus = action.payload;
      state.formFilterData = {
        ...state.formFilterData,
        ixStatus: action.payload
      };
    },
    setDateRange(state, action) {
      state.formFilterData = {
        ...state.formFilterData,
        dt1: action.payload.dt1,
        dt2: action.payload.dt2,
        textValue: action.payload.textValue
      };
    },
    clearSpecialJournalReport(state) {
      return {
        ...state,
        ...INITIAL_STATE,
        brch: {
          ...state.brch,
          ixBrch: 0
        }
      };
    },
    setSpecialJournalReportError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload.message;
    },
    setSpecialJournalReportData(state, action) {
      state.page = 0;
      state.limit = 25;
      state.loading = false;
      state.showAllSundryStatus = false;
      state.specialJournalReportData = {
        ...state.specialJournalReportData,
        ...(action.payload?.data || [])
      };
      state.columnFilters = action.payload?.settings?.allowed_columns || {};
    },
    setColumnFilters(state, action) {
      state.columnFilters = action.payload;
    },
    clearSpecialJournalReportData(state) {
      state.page = 0;
      state.limit = 25;
      state.columnFilters = {};
      state.specialJournalReportData = {
        accFlds: [],
        tableData: [],
        tableFields: [],
        tableTotals: {},
        numFields: [],
        tableDataWithSundry: [],
        feildsInprops: {}
      };
    },
    fetchSpecialJournalReportData(state) {
      state.loading = true;
      state.error = false;
      state.errorMessage = '';
    },
    setReportPage(state, action) {
      state.page = action.payload;
    },
    setReportLimit(state, action) {
      state.limit = action.payload;
      state.page = 0;
    },
    changeShowSundryStatus(state, action) {
      state.specialJournalReportData = {
        ...state.specialJournalReportData,
        tableData: showSundryHelper(
          state.specialJournalReportData.tableData,
          action.payload
        )
      };
    },
    showAllSundry(state, action) {
      state.showAllSundryStatus = !state.showAllSundryStatus;
      state.specialJournalReportData = {
        ...state.specialJournalReportData,
        tableData: showAllSundryHelper(
          state.specialJournalReportData.tableData,
          action.payload
        )
      };
    },
    columnChangeStatus(state, action) {
      state.columnFilters = {
        ...state.columnFilters,
        [action.payload.key]: action.payload.value
      };
    },
    setBranchSpecialJournalReport(state, action) {
      state.brch = {
        ...state.brch,
        ixBrch: action.payload
      };
    },
    brchRequiredSpecialJournalReport(state, action) {
      state.brch = {
        ...state.brch,
        label: action.payload.label,
        required: action.payload.required
      };
    },
    setPurTerms(state, action) {
      state.pur_terms = action.payload;
    },
    setSalesTerms(state, action) {
      state.sales_terms = action.payload;
    },
    cancelRequestSpej(state) {
      state.loading = false;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const specialJournalsReducer = slice.reducer;

export const {
  fetchInitialDataFilters,
  fetchInitialDataFiltersSuccess,
  fetchInitialDataFiltersFailed,
  setSelectedReport,
  setSelectedStatus,
  setDateRange,
  clearSpecialJournalReport,
  setSpecialJournalReportError,
  setSpecialJournalReportData,
  setColumnFilters,
  clearSpecialJournalReportData,
  fetchSpecialJournalReportData,
  setReportPage,
  setReportLimit,
  changeShowSundryStatus,
  showAllSundry,
  columnChangeStatus,
  setBranchSpecialJournalReport,
  brchRequiredSpecialJournalReport,
  cancelRequestSpej,
  setPurTerms,
  setSalesTerms,
  clear,
  restore
} = slice.actions;
export default specialJournalsReducer;

export const fetchInitialData = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const request = new Request(base_url, {
    headers: {
      'x-access-tokens': userToken,
      'Content-Type': 'application/json'
    }
  });

  dispatch(fetchInitialDataFilters());

  const [meta, pur, sls] = await request.multiFetch([
    {
      url: '/reports/special/journal/init',
      method: 'get'
    },
    {
      url: '/lib/terms/pur',
      method: 'post'
    },
    {
      url: '/lib/terms/sales',
      method: 'post'
    }
  ]);

  if (!meta?.success) {
    dispatch(
      fetchInitialDataFiltersFailed({
        message: meta?.data ?? 'Something went wrong.'
      })
    );
  } else dispatch(fetchInitialDataFiltersSuccess(meta.data));

  if (pur.success) dispatch(slice.actions.setPurTerms(pur?.data ?? []));

  if (sls.success) dispatch(slice.actions.setSalesTerms(sls?.data ?? []));
};
