import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  REGISTRY: true,
  STUDENT: false,
  VERSION: ''
};

const slice = createSlice({
  name: 'env',
  initialState: INITIAL_STATE,
  reducers: {
    setEnv(_, action) {
      return action.payload;
    }
  }
});

const envReducer = slice.reducer;

export const { setEnv } = slice.actions;
export default envReducer;
