import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { orange } from '@material-ui/core/colors';
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  Typography,
  TextField,
  makeStyles,
  Snackbar,
  Slide,
  withStyles,
  CircularProgress,
  Grid
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Alert from '@material-ui/lab/Alert';
import { Request } from 'src/helpers';
import ReCAPTCHA from 'react-google-recaptcha';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 50,
    paddingBottom: 50
  },
  avatar: {
    backgroundColor: orange[400],
    color: theme.palette.secondary.contrastText
  },
  ulapBizFeaturesSpacing: {
    marginTop: theme.spacing(2)
  },
  image: {
    width: '100%',
    height: '200px'
  },
  mainTitle: {
    fontSize: '2.5rem',
    fontWeight: 900
  },
  smallTitle: {
    color: orange[500]
  },
  textField: {
    marginTop: '1em'
  },
  messageUsButton: {
    background: orange[300]
  },
  captcha: {
    gap: '1em'
  }
}));

const ColorButton = withStyles(theme => ({
  root: {
    color: '#fff',
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[300]
    }
  }
}))(Button);

const ContactUs = ({ className, ...rest }) => {
  const classes = useStyles();
  const req = new Request('/api', {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [loading, setLoading] = useState(false);

  const handleSendMessage = async (data, actions) => {
    const {
      company_name,
      industry,
      full_name,
      email,
      mobile_phone,
      message
    } = data;

    const { success } = await req.post('/send-email-inquiry', {
      company_name,
      industry,
      full_name,
      email,
      contact_no: mobile_phone,
      message
    });
    setLoading(false);

    if (!success) {
      setSnackBar({
        open: true,
        message: 'Something went wrong',
        severity: 'error'
      });
      return;
    }

    setSnackBar({
      open: true,
      message: 'Successfully Sent',
      severity: 'success'
    });
    actions.resetForm();
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  const handleClose = () => {
    setSnackBar({
      open: false,
      message: ''
    });
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="md">
        <Typography
          component="p"
          variant="overline"
          className={classes.smallTitle}
          align="center"
        >
          Contact Us
        </Typography>
        <Typography align="center" className={classes.mainTitle}>
          Get in touch
        </Typography>
        <Box mt={6}>
          <Grid container spacing={3}>
            <Grid item lg={6}>
              <Box my={3}>
                <Typography variant="h3" color="textSecondary">
                  Contacts
                </Typography>
              </Box>

              <Box>
                <Box>
                  <Typography variant="overline">Email : </Typography>
                  <Typography variant="h5" color="textSecondary">
                    sales@radztech.com
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="overline">Mobile Number : </Typography>
                  <Typography variant="h5" color="textSecondary">
                    +63 917 523 5387
                  </Typography>
                  <Typography variant="h5" color="textSecondary">
                    +63 956 896 3767
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="overline">Address : </Typography>
                  <Typography variant="h5" color="textSecondary">
                    Radztech Business Solutions, Canciller Ave., Cauayan City,
                    3305 Isabela
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box>
                <Box my={3}>
                  <Typography variant="h3" color="textSecondary">
                    Message Us
                  </Typography>
                </Box>

                <Card>
                  <CardContent>
                    <Formik
                      initialValues={{
                        company_name: '',
                        industry: '',
                        full_name: '',
                        email: '',
                        mobile_phone: '',
                        message: '',
                        token: ''
                      }}
                      validationSchema={Yup.object().shape({
                        company_name: Yup.string()
                          .max(255)
                          .required('Company Name is required'),
                        industry: Yup.string()
                          .max(255)
                          .required('Industry is required'),
                        full_name: Yup.string()
                          .max(255)
                          .required('Fullname is required'),
                        email: Yup.string()
                          .max(100)
                          .required('Email is required')
                          .email('Invalid Email'),
                        mobile_phone: Yup.string()
                          .max(20)
                          .required('Mobile Phone is required'),
                        message: Yup.string()
                          .max(255)
                          .required('Message is required'),
                        token: Yup.string().required('Captcha is required')
                      })}
                      onSubmit={async (values, action) => {
                        setLoading(true);
                        handleSendMessage(values, action);
                      }}
                    >
                      {({
                        errors,
                        handleChange,
                        handleSubmit,
                        touched,
                        values
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <TextField
                            label="Company Name"
                            name="company_name"
                            value={values.company_name}
                            onChange={handleChange}
                            variant="outlined"
                            placeholder="Please enter your company name"
                            required
                            fullWidth
                            error={Boolean(
                              touched.company_name && errors.company_name
                            )}
                            helperText={
                              touched.company_name && errors.company_name
                            }
                          />
                          <TextField
                            label="Industry"
                            name="industry"
                            value={values.industry}
                            onChange={handleChange}
                            variant="outlined"
                            placeholder="Please enter industry"
                            required
                            fullWidth
                            className={classes.textField}
                            error={Boolean(touched.industry && errors.industry)}
                            helperText={touched.industry && errors.industry}
                          />
                          <TextField
                            label="Full Name"
                            name="full_name"
                            value={values.full_name}
                            onChange={handleChange}
                            variant="outlined"
                            placeholder="Please enter your fullname"
                            required
                            fullWidth
                            className={classes.textField}
                            error={Boolean(
                              touched.full_name && errors.full_name
                            )}
                            helperText={touched.full_name && errors.full_name}
                          />
                          <TextField
                            type="email"
                            label="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            variant="outlined"
                            className={classes.textField}
                            placeholder="Please enter your email"
                            required
                            fullWidth
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                          />
                          <TextField
                            label="Mobile Phone"
                            name="mobile_phone"
                            value={values.mobile_phone}
                            onChange={handleChange}
                            variant="outlined"
                            className={classes.textField}
                            placeholder="Please enter your mobile phone"
                            required
                            fullWidth
                            error={Boolean(
                              touched.mobile_phone && errors.mobile_phone
                            )}
                            helperText={
                              touched.mobile_phone && errors.mobile_phone
                            }
                          />
                          <TextField
                            label="Message"
                            name="message"
                            value={values.message}
                            onChange={handleChange}
                            variant="outlined"
                            className={classes.textField}
                            multiline
                            fullWidth
                            required
                            placeholder="Leave your message..."
                            minRows={8}
                            error={Boolean(touched.message && errors.message)}
                            helperText={touched.message && errors.message}
                          />
                          {process?.env?.REACT_APP_RECAPTCHA_SITE_KEY ? (
                            <Box
                              mt={2}
                              display="flex"
                              flexDirection="column"
                              className={classes.captcha}
                            >
                              <ReCAPTCHA
                                sitekey={
                                  process?.env?.REACT_APP_RECAPTCHA_SITE_KEY ??
                                  ''
                                }
                                onExpired={e => {
                                  handleChange({
                                    target: {
                                      name: 'token',
                                      value: ''
                                    }
                                  });
                                }}
                                onChange={e => {
                                  handleChange({
                                    target: {
                                      name: 'token',
                                      value: e
                                    }
                                  });
                                }}
                              />

                              {Boolean(touched.token && errors.token) ? (
                                <Typography
                                  variant="caption"
                                  color="error"
                                  display="block"
                                >
                                  {touched.token && errors.token}
                                </Typography>
                              ) : null}
                            </Box>
                          ) : null}

                          <Box mt={2}>
                            <ColorButton
                              type="submit"
                              disabled={loading}
                              fullWidth
                              size="large"
                              endIcon={
                                loading ? (
                                  <CircularProgress
                                    size={15}
                                    color="secondary"
                                  />
                                ) : null
                              }
                            >
                              Send
                            </ColorButton>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={snackBar.open}
          onClose={handleClose}
          TransitionComponent={SlideTransition}
          key={SlideTransition.name}
          autoHideDuration={3000}
        >
          <Alert
            onClose={handleClose}
            severity={snackBar.severity}
            variant="filled"
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

ContactUs.propTypes = {
  className: PropTypes.string
};

export default ContactUs;
