import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { orange } from '@material-ui/core/colors';
import {
  Avatar,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 100,
    paddingBottom: 100
  },
  avatar: {
    backgroundColor: orange[400],
    color: theme.palette.secondary.contrastText
  },
  ulapBizFeaturesSpacing: {
    marginTop: theme.spacing(2)
  },
  image: {
    width: '100%',
    height: '200px'
  },
  mainTitle: {
    fontSize: '2.5rem',
    fontWeight: 900
  },
  smallTitle: {
    color: orange[500]
  }
}));

const Features = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography
          align="center"
          color="primary"
          className={classes.mainTitle}
        >
          Features
        </Typography>
        <Box mt={8}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <img
                  alt="Records"
                  className={classes.image}
                  src="/static/images/undraw_data_processing_yrrv.svg"
                />
              </Box>
              <Box display="flex" mt={3}>
                <Avatar className={classes.avatar}>01</Avatar>
                <Box ml={2}>
                  <Typography variant="h3" gutterBottom color="textPrimary">
                    Records
                  </Typography>
                  <Box mt={1}>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.ulapBizFeaturesSpacing}
                    >
                      Sales Collection and Invoicing
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.ulapBizFeaturesSpacing}
                    >
                      Paid Purchases
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.ulapBizFeaturesSpacing}
                    >
                      Cash Disbursements
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <img
                  alt="Produces"
                  className={classes.image}
                  src="/static/images/undraw_done_checking_re_6vyx.svg"
                />
              </Box>

              <Box display="flex" mt={3}>
                <Avatar className={classes.avatar}>02</Avatar>
                <Box ml={2}>
                  <Typography variant="h3" gutterBottom color="textPrimary">
                    Provides Basis For
                  </Typography>
                  <Box mt={1}>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.ulapBizFeaturesSpacing}
                    >
                      VAT Payable
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.ulapBizFeaturesSpacing}
                    >
                      Percentage Tax Payable
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.ulapBizFeaturesSpacing}
                    >
                      Expanded Withholding Taxes
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <img
                  alt="Create Reports"
                  className={classes.image}
                  src="/static/images/undraw_at_work_re_qotl.svg"
                />
              </Box>
              <Box display="flex" mt={3}>
                <Avatar className={classes.avatar}>03</Avatar>
                <Box ml={2}>
                  <Typography variant="h3" gutterBottom color="textPrimary">
                    Generates Reports
                  </Typography>
                  <Box mt={1}>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.ulapBizFeaturesSpacing}
                    >
                      Summary Alphalist of Sales and Purchases (SLSP)
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.ulapBizFeaturesSpacing}
                    >
                      Summary Alphalist of Withholding Taxes (SAWT)
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.ulapBizFeaturesSpacing}
                    >
                      Quarterly Alphalist of Payees (QAP)
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
