class Leave {
  Name;
  Particulars;
  RefNo;
  Remarks;
  ixDate;
  ixEmp;
  ixSIL;
  ixSILType;
  ixTime1;
  ixTime2;
  jDate;
  jStatus;
  qtyOUT;
  t_Remarks;
  jid;

  constructor({
    Name,
    Particulars,
    RefNo,
    Remarks,
    ixDate,
    ixEmp,
    ixSIL,
    ixSILType,
    jDate,
    jStatus,
    qtyOUT,
    t_Remarks,
    jid,
    ixTime1,
    ixTime2
  }) {
    this.Name = Name;
    this.Particulars = Particulars;
    this.RefNo = RefNo;
    this.Remarks = Remarks;
    this.ixDate = ixDate;
    this.ixEmp = ixEmp;
    this.ixSIL = ixSIL;
    this.ixSILType = ixSILType;
    this.jDate = jDate;
    this.jStatus = jStatus;
    this.qtyOUT = qtyOUT;
    this.t_Remarks = t_Remarks;
    this.jid = jid;
    this.ixTime1 = ixTime1;
    this.ixTime2 = ixTime2;
  }
}

export default Leave;
