import React from 'react';
import PropTypes from 'prop-types';
import { Button, ListItem, makeStyles, Box } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';
import { Collapse } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  title: {
    marginRight: 'auto',
    marginLeft: theme.spacing(1)
  }
}));

const NavItemHeader = ({
  icon: Icon,
  title,
  isOpen,
  children,
  generateNav,
  onClick,
  isCollapsed
}) => {
  const classes = useStyles();

  return (
    <>
      <ListItem className={classes.item} disableGutters>
        <Button
          className={classes.button}
          style={{ justifyContent: isCollapsed ? 'flex-start' : 'center' }}
          onClick={onClick}
          endIcon={
            !isCollapsed ? null : isOpen ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowRight />
            )
          }
        >
          {Icon && <Icon />}
          {isCollapsed && <span className={classes.title}>{title}</span>}
        </Button>
      </ListItem>
      {isCollapsed && (
        <Box pl="1.2em">
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {generateNav(children)}
          </Collapse>
        </Box>
      )}
    </>
  );
};

NavItemHeader.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  onOpen: PropTypes.func
};

export default NavItemHeader;
