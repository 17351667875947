const getixJCd = (JCd = '', wf = {}) => {
  const lc_JCd = JCd.toLowerCase();

  for (const [_, transType] of Object.entries(wf))
    if (lc_JCd === (transType?.JCd || '').toLowerCase()) return transType.ixJCd;

  return '';
};

export default getixJCd;
