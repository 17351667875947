import {
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  makeStyles
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  hero: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      backgroundImage: 'url(/static/ibfrs3/background-header.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      top: -80,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 10
    }
  },
  heroTitle: {
    fontSize: '3rem',
    fontWeight: 900,
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem'
    }
  },
  heroSubTitle: {
    fontSize: '1.2rem',
    marginTop: '1em',
    fontWeight: 500,
    [theme.breakpoints.only('xs')]: {
      fontSize: '1rem'
    }
  },
  heroImage: {
    width: '100%',
    [theme.breakpoints.only('sm')]: {
      width: '80%'
    }
  },
  indicator: {
    width: '20px',
    height: '35px',
    border: '2px solid #000',
    borderRadius: '50px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '8px',
      height: '8px',
      background: '#000',
      margin: 'auto',
      left: '0',
      right: '0',
      top: 0,
      borderRadius: '50%',
      animation: '$animate 1s linear infinite'
    }
  },
  '@keyframes animate': {
    '0%': {
      top: 0
    },
    '100%': {
      top: '20px',
      transform: 'scale(0.8)'
    }
  },
  scrollDown: {
    color: '#000',
    marginTop: '.1em'
  },
  scrollDownContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  arrow: {
    position: 'relative',
    zIndex: 10,
    '&:before': {
      content: '""',
      zIndex: -10,
      position: 'absolute',
      backgroundImage: 'url(/static/ibfrs3/particles/arrow-1.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      aspectRatio: 2 / 1,
      width: '100%',
      left: '100%',
      top: '130%',
      pointerEvents: 'none',
      transform: 'scale(1.5)',
      transformOrigin: 'top left',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  }
}));

const HeroSection = () => {
  const theme = useTheme();
  const belowMd = useMediaQuery(theme.breakpoints.down('sm'));
  const onXs = useMediaQuery(theme.breakpoints.only('xs'));
  const classNames = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ height: 'calc(100vh - 80px)' }}
      className={classNames.hero}
    >
      <Container maxWidth="lg" style={{ zIndex: 20 }}>
        <Grid
          container
          direction={belowMd ? 'column-reverse' : 'row'}
          spacing={5}
        >
          <Grid item lg={6} md={6} sm={12} className={classNames.particle1}>
            <Grid container>
              <Grid item lg={12}>
                <Typography className={classNames.heroTitle}>
                  Integrated Budget and Financial Reporting System
                </Typography>
                <Typography className={classNames.heroSubTitle}>
                  An online government reporting platform that will greatly help
                  the nation in achieving its goal of one unified system for our
                  national government agencies and local government units.
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button
                  component={Link}
                  to="/contact-us"
                  color="primary"
                  variant="contained"
                  size="large"
                  style={{ marginTop: '1em' }}
                  className={classNames.arrow}
                >
                  Book a Demo
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <Box display="flex" justifyContent="center">
              <img
                src="/static/ibfrs3/iBFRS_header.png"
                alt="ibfrs-header"
                className={classNames.heroImage}
              />
            </Box>
          </Grid>
        </Grid>
        <Box className={classNames.scrollDownContainer} mt={5}>
          <div className={classNames.indicator} />
          <Typography
            variant="caption"
            display="block"
            className={classNames.scrollDown}
          >
            Scroll Down
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroSection;
