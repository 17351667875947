import {
  Box,
  Container,
  IconButton,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles({
  box: {
    height: 120,
    width: 120,
    backgroundColor: '#FF7704',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    flexGrow: 0,
    flexShrink: 0
  },
  boxNumber: {
    fontSize: '5rem',
    color: '#FFF'
  },
  container: {
    position: 'relative',
    overflow: 'hidden'
  },
  nextBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 200
  },
  prevBtn: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    zIndex: 200
  },
  animate: {
    animation: '$scaleDown 2s ease-in'
  },
  scaleDown: {
    transform: 'scale(0)'
  },
  scaleBottomLeft: {
    transform: 'translate(-100%, 100%)',
    opacity: 0,
    transition: 'all 400ms ease-in'
  },
  scaleTopRight: {
    transform: 'translate(100%, -100%)',
    opacity: 0,
    transition: 'all 400ms ease-in'
  },
  bigText: {
    fontSize: '6rem',
    textShadow: 'black 6px 6px',
    fontStretch: 'expanded',
    lineHeight: '5rem',
    height: '5rem'
  }
});

const NUMBERS = [
  {
    no: 1,
    image: '/static/images/about-us-pictures/8.jpg',
    Content: ({ bigText }) => {
      return (
        <Typography
          align="center"
          style={{ fontSize: '2.2rem', fontWeight: 700, color: '#FFF' }}
        >
          Powered with <span className={bigText}>1</span> core engine that is
          developed to adapt to different industries and business scenarios
        </Typography>
      );
    }
  },
  {
    no: 2,
    image: '/static/images/about-us-pictures/6.jpg',
    Content: ({ bigText }) => {
      return (
        <Typography
          align="center"
          style={{ fontSize: '2.2rem', fontWeight: 700, color: '#FFF' }}
        >
          Developing the technology-integrated accounting solutions and other
          computer systems using our core for <span className={bigText}>2</span>{' '}
          decades now
        </Typography>
      );
    }
  },
  {
    no: 3,
    image: '/static/images/about-us-pictures/5.jpg',
    Content: ({ bigText }) => {
      return (
        <>
          <Typography
            align="center"
            style={{ fontSize: '2.2rem', fontWeight: 700, color: '#FFF' }}
          >
            Serving our clients in accordance to our{' '}
            <span className={bigText}>3</span> core values
          </Typography>
          <Box
            mt={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gridRowGap="1.4em"
          >
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Integrity
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Dedication
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Quality
            </Typography>
          </Box>
        </>
      );
    }
  },
  {
    no: 4,
    image: '/static/images/about-us-pictures/2.jpg',
    Content: ({ bigText }) => {
      return (
        <>
          <Typography
            align="center"
            style={{ fontSize: '2.2rem', fontWeight: 700, color: '#FFF' }}
          >
            Built a cloud-based accounting system with{' '}
            <span className={bigText}>4</span> beyond accounting features
          </Typography>
          <Box mt={3} display="flex" gridGap="1.4em" flexWrap="wrap">
            <Box>
              <img
                src="/static/images/sub-systems/tax.png"
                width={180}
                height="auto"
              />
              <Typography
                align="center"
                style={{ fontSize: '1.8rem', fontWeight: 700 }}
              >
                <span style={{ color: '#FFF' }}>Ulap</span>
                <span style={{ color: '#000' }}>Tax</span>
              </Typography>
            </Box>
            <Box>
              <img
                src="/static/images/sub-systems/inv.png"
                width={180}
                height="auto"
              />
              <Typography
                align="center"
                style={{ fontSize: '1.8rem', fontWeight: 700 }}
              >
                <span style={{ color: '#FFF' }}>Ulap</span>
                <span style={{ color: '#000' }}>Inventory</span>
              </Typography>
            </Box>
            <Box>
              <img
                src="/static/images/sub-systems/targets.png"
                width={180}
                height="auto"
              />
              <Typography
                align="center"
                style={{ fontSize: '1.8rem', fontWeight: 700 }}
              >
                <span style={{ color: '#FFF' }}>Ulap</span>
                <span style={{ color: '#000' }}>Targets</span>
              </Typography>
            </Box>
            <Box>
              <img
                src="/static/images/sub-systems/daloy.png"
                width={180}
                height="auto"
              />
              <Typography
                align="center"
                style={{ fontSize: '1.8rem', fontWeight: 700 }}
              >
                <span style={{ color: '#FFF' }}>Daloy</span>
                <span style={{ color: '#000' }}>Workflow</span>
              </Typography>
            </Box>
          </Box>
        </>
      );
    }
  },
  {
    no: 5,
    image: '/static/images/about-us-pictures/3.jpg',
    Content: ({ bigText }) => {
      return (
        <>
          <Typography
            align="center"
            style={{ fontSize: '2.2rem', fontWeight: 700, color: '#FFF' }}
          >
            Known for providing <span className={bigText}>5</span> systems
          </Typography>
          <Box
            mt={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gridRowGap="1.4em"
          >
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Accounting Information System
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Inventory Management System
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Enrollment System
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Payroll System
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              UlapBiz
            </Typography>
          </Box>
        </>
      );
    }
  },
  {
    no: 6,
    image: '/static/images/about-us-pictures/4.jpg',
    Content: ({ bigText }) => {
      return (
        <>
          <Typography
            align="center"
            style={{ fontSize: '2.2rem', fontWeight: 700, color: '#FFF' }}
          >
            Offering <span className={bigText}>6</span> Management Services
          </Typography>
          <Box
            mt={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gridRowGap="1.4em"
          >
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Process Workflow Documentation
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Internal Audit
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Tax and ISO Accreditation Consultancy
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Feasibility and Forecasting
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              System Audit
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Management Technical Consulting
            </Typography>
          </Box>
        </>
      );
    }
  },
  {
    no: 7,
    image: '/static/images/about-us-pictures/7.jpg',
    Content: ({ bigText }) => {
      return (
        <>
          <Typography
            align="center"
            style={{ fontSize: '2.2rem', fontWeight: 700, color: '#FFF' }}
          >
            Catered the needs of <span className={bigText}>7</span> industries
            thru the years
          </Typography>
          <Box
            mt={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gridRowGap="1.4em"
          >
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Trading
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Distribution
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Services{' '}
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Construction
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Manufacturing
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              E-commerce
            </Typography>
            <Typography variant="h2" style={{ color: '#FFF' }}>
              Educational Institution
            </Typography>
          </Box>
        </>
      );
    }
  }
];

const Item = ({ style, className, onTransitionEnd, image, children }) => {
  return (
    <Box
      minHeight={600}
      px={2}
      py={4}
      width="100%"
      style={{
        ...style,
        backgroundImage: `linear-gradient(89deg, rgb(21, 74, 189, 0.8) 0.1%, rgb(26, 138, 211, 0.8) 51.5%, rgb(72, 177, 234, 0.8) 100.2%), url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
      className={className}
      onTransitionEnd={onTransitionEnd}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        maxWidth={1100}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Box>
    </Box>
  );
};

const Seven = () => {
  const theme = useTheme();
  const classes = useStyles();

  const [current, setCurrent] = useState(0);
  const [currentClassName, setCurrentClassName] = useState('');
  const [btnClicked, setBtnClicked] = useState('');

  function handleNext() {
    setCurrentClassName(classes.scaleTopRight);
    setBtnClicked('next');
  }

  function handlePrev() {
    setCurrentClassName(classes.scaleBottomLeft);
    setBtnClicked('prev');
  }

  function onTransitionEnd() {
    setCurrentClassName('');

    if (btnClicked === 'next')
      setCurrent(state => (state === NUMBERS.length - 1 ? 0 : state + 1));
    else if (btnClicked === 'prev')
      setCurrent(state => (state === 0 ? NUMBERS.length - 1 : state - 1));

    setBtnClicked('');
  }

  const next = useMemo(
    () => (current === NUMBERS.length - 1 ? NUMBERS[0] : NUMBERS[current + 1]),
    [current]
  );

  const prev = useMemo(
    () => (current === 0 ? NUMBERS[NUMBERS.length - 1] : NUMBERS[current - 1]),
    [current]
  );

  useEffect(() => {
    let timeoutID;

    (function loop() {
      timeoutID = setTimeout(() => {
        handleNext();
        loop();
      }, 9000);
    })();

    return () => {
      clearTimeout(timeoutID);
    };
  }, []);

  return (
    <Box className={classes.container}>
      <Item
        image={NUMBERS[current].image}
        className={clsx(classes.current, currentClassName)}
        style={{ position: 'relative', zIndex: 100 }}
        onTransitionEnd={onTransitionEnd}
      >
        {NUMBERS[current].Content({ bigText: classes.bigText })}
      </Item>
      {btnClicked === 'next' && (
        <Item
          image={next.image}
          style={{ position: 'absolute', zIndex: 10, top: 0, left: 0 }}
        >
          {next.Content({ bigText: classes.bigText })}
        </Item>
      )}
      {btnClicked === 'prev' && (
        <Item
          image={prev.image}
          style={{ position: 'absolute', zIndex: 10, top: 0, left: 0 }}
        >
          {prev.Content({ bigText: classes.bigText })}
        </Item>
      )}
      {/* <IconButton className={classes.nextBtn} onClick={handleNext}>
        <ArrowForwardIos style={{ transform: 'rotate(-45deg)' }} />
      </IconButton>
      <IconButton className={classes.prevBtn} onClick={handlePrev}>
        <ArrowBackIos style={{ transform: 'rotate(-45deg)' }} />
      </IconButton> */}
    </Box>
  );
};

export default Seven;
