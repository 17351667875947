import { useEffect } from 'react';
import { useToggle } from 'src/hooks';
import { APIRequestV2 } from 'src/helpers';
import { useParams } from 'react-router';
import { useState } from 'react';

const useVerifyEmail = () => {
  const [isVerifying, verifyOn, verifyOff] = useToggle();
  const [result, setResult] = useState({
    type: 'success',
    message: 'Email Verified'
  });
  const params = useParams();

  async function activate() {
    verifyOn();
    const { success } = await APIRequestV2({
      method: 'post',
      url: `/api/registry/user/verify-email/${params.cd}/${params.email}`,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    verifyOff();

    setResult({
      type: success ? 'success' : 'failed',
      msg: success
        ? 'Your email address has been verified'
        : "Email verification failed. Please check your provided email address and ensure the verification link isn't expired."
    });
  }

  useEffect(() => {
    activate();
  }, []);

  return {
    activate,
    result,
    isVerifying
  };
};

export default useVerifyEmail;
