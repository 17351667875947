import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Hidden,
  Typography,
  withStyles,
  makeStyles
} from '@material-ui/core';
import { Pagination as MuiPagination } from '@material-ui/lab';
import { isString } from 'lodash';
import { usePaginate } from 'src/hooks';
import Carousel from 'react-material-ui-carousel';

const Pagination = withStyles(theme => ({
  root: {
    '& ul': {
      gap: theme.spacing(2),
      '& li button': {
        fontWeight: theme.typography.fontWeightBold
      }
    },
    '& .Mui-selected, .Mui-focusVisible': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    }
  }
}))(props => <MuiPagination {...props} />);

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontSize: '3.2rem',
    fontWeight: 900,
    textAlign: 'center',
    '& .highlight': {
      color: theme.palette.primary.main,
      borderBottom: '4px solid ' + theme.palette.primary.main
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem'
    }
  }
}));

let focusTimeoutID;

/**
 *
 * @param {object} param
 * @param {number} param.ixPage
 * @param {pageTestimonies} param.pageDetails
 * @returns
 */
const PaginatedTestimonies = ({ ixPage, pageDetails }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const {
    page,
    pageItems,
    totalPage,
    goToPage,
    nextPage,
    nextPageAvailable
  } = usePaginate({
    items: pageDetails?.items || [],
    itemsPerPage: 6
  });

  function changeFocus() {
    focusTimeoutID = setTimeout(() => {
      if (activeIndex === pageItems.length - 1) {
        if (!nextPageAvailable) goToPage(1);
        else nextPage();

        setActiveIndex(0);
      } else setActiveIndex(index => index + 1);
    }, 10000);
  }

  useEffect(() => {
    changeFocus();

    return () => {
      clearTimeout(focusTimeoutID);
    };
  }, [activeIndex]);

  return (
    <Grid item lg={12}>
      <div className="naccs">
        <div className="grid">
          <Grid container>
            <Grid item lg={7}>
              <div className="menu">
                {pageItems.map((testimony, index) => (
                  <div
                    key={testimony.id}
                    className={index === activeIndex ? 'active' : ''}
                    onClick={() => {
                      if (index !== activeIndex) {
                        clearTimeout(focusTimeoutID);
                        setActiveIndex(index);
                      }
                    }}
                  >
                    <div className="thumb">
                      <Grid container alignItems="center">
                        <Grid item lg={5} sm={5} xs={12}>
                          <Typography variant="h4">{testimony.name}</Typography>
                        </Grid>
                        <Grid item lg={7} sm={7} xs={12}>
                          {isString(testimony.position) ? (
                            <Typography variant="body1">
                              {testimony.position}
                            </Typography>
                          ) : (
                            <>
                              {testimony.position.map(position => (
                                <Typography variant="body1" key={position}>
                                  {position}
                                </Typography>
                              ))}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                ))}
                <Box display="flex" justifyContent="center">
                  <Pagination
                    hidePrevButton
                    hideNextButton
                    count={totalPage}
                    page={page}
                    onChange={(_, val) => {
                      goToPage(val);

                      if (activeIndex !== 0) {
                        clearTimeout(focusTimeoutID);
                        setActiveIndex(0);
                      }
                    }}
                  />
                </Box>
              </div>
            </Grid>
            <Grid item lg={5}>
              <ul className="nacc">
                <li className="active">
                  <div>
                    <div className="thumb">
                      <Grid container>
                        <Grid item lg={12}>
                          <div className="client-content">
                            <img
                              src="/static/ibfrs/assets/images/quote.png"
                              alt=""
                            />
                            <Typography variant="body1">
                              "{pageItems[activeIndex]?.comment}"
                            </Typography>
                            {pageItems[activeIndex]?.subComment && (
                              <Typography
                                variant="body1"
                                style={{ marginTop: 10 }}
                              >
                                {pageItems[activeIndex]?.subComment}
                              </Typography>
                            )}
                          </div>
                          <div className="down-content">
                            <img
                              src={
                                pageItems?.[activeIndex]?.staticProfile
                                  ? pageItems?.[activeIndex]?.profile
                                  : `/api/pages/${ixPage}/images/${pageItems[activeIndex]?.profile}`
                              }
                              alt={pageItems[activeIndex]?.name}
                            />
                            <div className="right-content">
                              <Typography variant="h4">
                                {pageItems[activeIndex]?.name}
                              </Typography>
                              {pageItems[activeIndex]?.position && (
                                <>
                                  {isString(pageItems[activeIndex].position) ? (
                                    <Typography variant="body1">
                                      {pageItems[activeIndex].position}
                                    </Typography>
                                  ) : (
                                    <>
                                      {pageItems[activeIndex].position.map(
                                        position => (
                                          <Typography
                                            variant="body1"
                                            key={position}
                                          >
                                            {position}
                                          </Typography>
                                        )
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

/**
 *
 * @param {object} param
 * @param {number} param.ixPage
 * @param {pageTestimonies} param.pageDetails
 * @returns
 */
const TestimonialsTl1 = ({ pageDetails, ixPage }) => {
  const classNames = useStyles();

  return (
    <Box id="clients" className="the-clients" py={10}>
      <Container maxWidth="lg">
        <Typography className={classNames.sectionTitle}>
          {pageDetails.title1}{' '}
          <span className="highlight">{pageDetails.title2}</span>
        </Typography>
        <Box mt={10}>
          <Grid container justifyContent="center">
            <Hidden smDown>
              <PaginatedTestimonies
                ixPage={ixPage}
                pageDetails={pageDetails}
                key="paginated-testimonies"
              />
            </Hidden>
            <Hidden mdUp>
              <Carousel
                key="testimonies"
                autoPlay
                animation="slide"
                interval={10000}
                stopAutoPlayOnHover
                indicators
                swipe
                indicatorContainerProps={{
                  style: {
                    marginTop: 30
                  }
                }}
              >
                {(pageDetails?.items || []).map(testimony => (
                  <ul className="nacc" key={testimony.id}>
                    <li className="active">
                      <div>
                        <div className="thumb">
                          <Grid container>
                            <Grid item lg={12}>
                              <div className="client-content">
                                <img
                                  src="/static/ibfrs/assets/images/quote.png"
                                  alt=""
                                />
                                <Typography variant="body1">
                                  "{testimony.comment}"
                                </Typography>
                                {testimony.subComment && (
                                  <Typography
                                    variant="body1"
                                    style={{ marginTop: 10 }}
                                  >
                                    {testimony.subComment}
                                  </Typography>
                                )}
                              </div>
                              <div className="down-content">
                                <img
                                  src={`/api/pages/${ixPage}/images/${testimony?.profile}`}
                                  alt={testimony?.name}
                                />
                                <div className="right-content">
                                  <Typography variant="h4">
                                    {testimony.name}
                                  </Typography>
                                  {testimony.position && (
                                    <>
                                      {isString(testimony.position) ? (
                                        <Typography variant="body1">
                                          {testimony.position}
                                        </Typography>
                                      ) : (
                                        <>
                                          {testimony.position.map(position => (
                                            <Typography
                                              variant="body1"
                                              key={position}
                                            >
                                              {position}
                                            </Typography>
                                          ))}
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </li>
                  </ul>
                ))}
              </Carousel>
            </Hidden>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default TestimonialsTl1;
