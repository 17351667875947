import React, { useEffect, useState } from 'react';
import { useRequest, useToggle } from 'src/hooks';
import { DebounceTextField } from 'src/components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  SvgIcon,
  IconButton,
  InputAdornment,
  Tooltip,
  Box,
  Typography,
  CircularProgress,
  Divider,
  Checkbox,
  DialogActions,
  Button
} from '@material-ui/core';
import { lib } from 'src/lib/endpoints';
import { Search } from '@material-ui/icons';
import { Close, ArrowRight } from '@material-ui/icons';

/**
 * @typedef {Object} openSubSelectParam
 * @property {subType} openSubSelectParam.subType
 * @property {{lstSubParent:number[]}} openSubSelectParam.filters
 * @property {(selectedSub: subAccount[]) => void} openSubSelectParam.onAddCallback
 */

/**
 * @typedef {Object} Context
 * @property {VoidFunction} open
 * @property {VoidFunction} close
 * @property {(props: openSubSelectParam) => void} openSubSelect
 * @property {String} searchQuery
 * @property {(searchQuery: String) => void} setSearchQuery
 * @property {subAccount[]} selectedSub
 * @property {subType} subType
 * @property {(subType: subType) => void} setSubType
 * @property {() => void} clearSelectedSub
 */

/**
 * @type {React.Context<Context>}
 */
const MultiSubSelectContext = React.createContext();

const useStyles = makeStyles({
  dialogContent: {
    height: 500,
    padding: 0
  }
});

const filter_keys = ['lstSubParent'];

const MultiSubSelectProvider = ({ children }) => {
  const classNames = useStyles();

  const [subType, setSubType] = useState({
    ixSubType: 0,
    sSubType: ''
  });

  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSub, setSelectedSub] = useState([]);
  const [filters, setFilters] = useState({});
  const [onAdd, setOnAdd] = useState(() => () => {});

  const [error, hasError, clearError] = useToggle();
  const [isOpen, open, close] = useToggle();
  const [isLoading, loadingOn, loadingOff] = useToggle();

  const request = useRequest(loadingOn, loadingOff);

  const isSelected = ixSub => selectedSub.some(sub => sub.ixSub === ixSub);

  /**
   *
   * @param {(selectedSub: subAccount[])} callback
   */
  function addSelected() {
    onAdd(selectedSub);
  }

  function clearSelectedSub() {
    setSelectedSub([]);
  }

  function updateSelectedSub({ ixSub, sSub }) {
    setSelectedSub(state => {
      if (state.some(item => item.ixSub === ixSub))
        return state.filter(item => item.ixSub !== ixSub);

      return [...state, { ixSub, sSub }];
    });
  }

  function openSubSelect({
    subType: { ixSubType, sSubType },
    filters = {},
    onAddCallback = () => {}
  }) {
    if (!ixSubType) return;

    setSubType({ ixSubType, sSubType });
    setFilters(filters);
    setOnAdd(() => onAddCallback);
    open();
  }

  async function search(cancelCb) {
    const params = {
      ixSubType: subType.ixSubType,
      q: searchQuery.trim()
    };

    for (const key of filter_keys) {
      if (filters?.[key]?.length) params[key] = filters[key];
    }

    const res = await request.get(
      lib.sub,
      {
        params
      },
      cancelCb
    );

    if (res.success) setSearchResult(res.data?.sub_list || []);
    else if (!res.isCancelled) hasError();
  }

  useEffect(() => {
    setSelectedSub([]);
  }, [subType.ixSubType]);

  useEffect(() => {
    if (!subType.ixSubType) return;

    let cancel = () => {};
    search(c => (cancel = c));
    return cancel;
  }, [subType.ixSubType, searchQuery]);

  return (
    <MultiSubSelectContext.Provider
      value={{
        open,
        close,
        openSubSelect,
        searchQuery,
        setSearchQuery,
        selectedSub,
        subType,
        setSubType,
        clearSelectedSub
      }}
    >
      <Dialog fullWidth maxWidth='sm' open={isOpen} onClose={close}>
        <DialogTitle>
          <DebounceTextField
            fullWidth
            delay={200}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            autoComplete='off'
            autoFocus
            InputProps={{
              disableUnderline: true,
              autoFocus: true,
              startAdornment: (
                <InputAdornment position='start'>
                  <SvgIcon color='action'>
                    <Search />
                  </SvgIcon>
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  <InputAdornment position='end'>
                    <Tooltip title='Close' arrow>
                      <span>
                        <IconButton onClick={close} tabIndex={-1} size='small'>
                          <Close />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </InputAdornment>
                </>
              )
            }}
            placeholder={subType.sSubType || 'Search...'}
            variant='standard'
          />
        </DialogTitle>
        <Divider />
        <DialogContent className={classNames.dialogContent}>
          {isLoading ? (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='100%'
            >
              <CircularProgress size={40} />
            </Box>
          ) : searchResult.length === 0 ? (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='100%'
            >
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Search color='primary' fontSize='large' />
                <Typography variant='h5' color='primary'>
                  No Result Found
                </Typography>
              </Box>
            </Box>
          ) : (
            <Result
              subAccounts={searchResult}
              updateSelectedSub={updateSelectedSub}
              isSelected={isSelected}
            />
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          {selectedSub.length > 0 && (
            <Typography variant='body2' color='primary'>
              {selectedSub.length} Selected
            </Typography>
          )}
          <Button variant='contained' color='primary' onClick={addSelected}>
            Add Selected
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </MultiSubSelectContext.Provider>
  );
};

/**
 *
 * @param {Object} param
 * @param {subAccount[]} param.subAccounts
 * @param {(sub: subAccount) => void} param.updateSelectedSub
 * @param {(ixSub: Number) => Boolean} param.isSelected
 * @returns
 */
const Result = ({ subAccounts = [], updateSelectedSub, isSelected }) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {subAccounts.map(({ ixSub, sSub }) => (
            <TableRow
              hover
              onClick={() => {
                updateSelectedSub({ ixSub, sSub });
              }}
              key={ixSub}
            >
              <TableCell>{ixSub}</TableCell>
              <TableCell>{sSub}</TableCell>
              <TableCell style={{ flex: 1 }} />
              <TableCell align='right'>
                <Checkbox
                  size='small'
                  color='primary'
                  checked={isSelected(ixSub)}
                  onClick={e => {
                    e.stopPropagation();
                    updateSelectedSub({ ixSub, sSub });
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MultiSubSelectContext;
export { MultiSubSelectProvider };
