import React from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import numeral from 'numeral';
import Label from 'src/components/Label';

const useStyles = makeStyles(theme => ({
  item: {
    position: 'relative'
  },
  prodCatContainer: {
    position: 'absolute',
    top: 0,
    right: 0
  },

  avatar: {
    marginRight: theme.spacing(2)
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    background: '#fff',
    zIndex: 100
  },
  table: {
    position: 'relative'
  },
  image: {
    height: '5em',
    width: '5em'
  },
  spacing: {
    position: 'relative',
    gap: theme.spacing(2)
  },
  otherDetails: {
    marginRight: theme.spacing(2)
  },
  headerTitle: {
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  price: {
    position: 'absolute',
    display: 'block',
    bottom: 10,
    right: 10
  },
  subLinks: {
    marginTop: '0.5em',
    display: 'flex',
    gridGap: '0.5em'
  }
}));

const Item = ({
  item,
  handleProdSelect,
  divider,
  showPrice = false,
  priceProp = 'cPrice1'
}) => {
  const classes = useStyles();

  const { ixImage } = useSelector(state => state.auth);

  return (
    <ListItem
      className={`${classes.spacing} ${classes.item}`}
      divider={divider}
      button={true}
      onClick={() => {
        handleProdSelect(item);
      }}
    >
      <ListItemAvatar>
        <Avatar
          className={classes.image}
          variant="rounded"
          src={`/api/images/${ixImage}/prod/${item?.ixProd}/active_prod_avatar.jpg`}
        >
          <ImageOutlinedIcon fontSize="large" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={item.sProd}
        primaryTypographyProps={{
          variant: 'body1',
          className: classes.headerTitle
        }}
        secondaryTypographyProps={{
          variant: 'caption'
        }}
        secondary={
          <>
            <span className={classes.otherDetails}>{item.sProdCat}</span>
            <span className={classes.otherDetails}>{item.ProdCd}</span>
            <span className={classes.subLinks}>
              <Label>{item.unit}</Label>
              {(item?.qtySC ?? 0) > 0 && (
                <Label>
                  {item.unitSC} ({item.qtySC})
                </Label>
              )}
              {(item?.qtyCS ?? 0) > 0 && (
                <Label>
                  {item.unitCS} ({item.qtyCS})
                </Label>
              )}

              {[1, 2, 3, 4, 5].map(num =>
                Boolean(item[`ixProdSubLink${num}`]) ? (
                  <Label key={item}>{item[`sProdSubLink${num}`]}</Label>
                ) : null
              )}
            </span>
          </>
        }
      />
      {showPrice && (
        <Typography variant="h4" color="primary" className={classes.price}>
          {numeral(item?.[priceProp] ?? 0).format('0,0.00')}
        </Typography>
      )}
    </ListItem>
  );
};

const SrchProdResults = ({
  className,
  prodList,
  handleProdSelect,
  showPrice = false,
  priceProp = 'cPrice1'
}) => {
  return (
    <List className={className} disablePadding>
      {typeof prodList !== 'undefined' &&
        prodList.map((item, index) => {
          return (
            <Item
              key={item.ixProd}
              item={item}
              handleProdSelect={handleProdSelect}
              divider={prodList.length - 1 !== index}
              showPrice={showPrice}
              priceProp={priceProp}
            />
          );
        })}
    </List>
  );
};

SrchProdResults.propTypes = {
  className: PropTypes.string,
  prodList: PropTypes.array.isRequired,
  handleProdSelect: PropTypes.func,
  showPrice: PropTypes.bool,
  priceProp: PropTypes.string
};

export default SrchProdResults;
