import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  selectedJCd: 0,
  searchValue: '',
  page: 0,
  limit: 25,
  dateRange: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  data: {
    date: 0,
    dt1: '',
    dt2: '',
    lst_pr: [0],
    meta: {
      deductions: [],
      earnings: [],
      months: []
    },
    rep: [],
    totals: {}
  },
  meta: {
    jcd: []
  }
};

const slice = createSlice({
  name: 'trtnMonthPay',
  initialState: INITIAL_STATE,
  reducers: {
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setSearchValue(state, action) {
      state.page = 0;
      state.searchValue = action.payload;
    },
    setSelectedJCd(state, action) {
      state.selectedJCd = action.payload;
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setData(state, action) {
      state.page = 0;
      state.data = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.page = 0;
      state.limit = action.payload;
    },
    clearData(state, action) {
      return {
        ...state,
        searchValue: '',
        page: 0,
        limit: 25,
        data: INITIAL_STATE.data
      };
    },
    clear(state, action) {
      return INITIAL_STATE;
    },

    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const trtnMonthPayReducer = slice.reducer;
export const {
  setMeta,
  setData,
  setDateRange,
  setSelectedJCd,
  clear,
  restore,
  setSearchValue,
  setLimit,
  setPage,
  clearData
} = slice.actions;
export default trtnMonthPayReducer;
