import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid,
  Avatar
} from '@material-ui/core';
import data from '../data';
import useReveal, { Animated } from 'src/hooks/useReveal';
import EqualizerIcon from '@material-ui/icons/Equalizer';
const useStyles = makeStyles(theme => ({
  root: {
    color: '#fff',
    height: 'auto',
    width: '100%',
    paddingTop: 100,
    paddingBottom: 100,
    backgroundColor: '#F8FBFB',
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'center',
      height: '100%',
      backgroundSize: 'auto'
    }
  },
  mainTitle: {
    fontSize: '2.5rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center'
    }
  },
  description: {
    lineHeight: '1.5rem',
    marginTop: theme.spacing(2)
  },
  featureTitle: {
    fontWeight: 500,
    // borderBottom: `.2em solid #ff7704`,
    position: 'relative',
    '&:before': {
      content: "''",
      position: 'absolute',
      bottom: 1,
      left: 1,
      width: 50,
      height: '.2em',
      backgroundColor: '#ff7704'
    }
  },

  rounded: {
    color: '#fff',
    backgroundColor: '#ff7704'
  },
  paperComponent: {
    position: 'block',
    height: '100%',
    background: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    transition: 'all 250ms ease-in-out',
    boxShadow: `rgba(255, 119, 4, 0.2) 0px 10px 15px -3px, rgba(255, 119, 4, 0.05) 0px 4px 6px -2px`,
    '&:hover': {
      boxShadow: `rgba(255, 119, 4, 0.25) 0px 25px 50px -12px`
    }
  }
}));

const Report = ({ title, description, delay }) => {
  const classes = useStyles();
  const [ref, reveal] = useReveal({ threshold: 0.5 });

  return (
    <Animated
      config={{ duration: 500 }}
      to={{ opacity: reveal ? 1 : 0 }}
      delay={delay}
      className={classes.paperComponent}
    >
      <Box ref={ref}>
        <Box display="flex" justifyContent="space-between">
          <Typography color="textPrimary" className={classes.featureTitle}>
            {title}
          </Typography>

          <Avatar variant="rounded" className={classes.rounded}>
            <EqualizerIcon />
          </Avatar>
        </Box>
        <Typography
          color="textSecondary"
          variant="body2"
          className={classes.description}
        >
          {description}
        </Typography>
      </Box>
    </Animated>
  );
};

const ReportsFeature = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography
          className={classes.mainTitle}
          align="center"
          color="textPrimary"
        >
          Schedules and Reports
        </Typography>

        <Box mt={8}>
          <Grid container spacing={5}>
            {data.reports.map((data, i) => (
              <Grid item sm={12} md={4} key={i}>
                <Report
                  key={i}
                  title={data.title}
                  description={data?.description ?? ''}
                  delay={50 * i}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

ReportsFeature.propTypes = {
  className: PropTypes.string
};

export default ReportsFeature;
