import React from 'react';
import { Paper, Box, Typography, makeStyles } from '@material-ui/core';
import { colors } from '@material-ui/core';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 900
  },
  caption: {
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    color: '#546e7a'
  },
  paper: props => {
    let color = props.color === 'default' ? 'indigo' : props.color;
    return {
      transition: 'all 250ms ease-in-out',
      ['& h1']: {
        fontSize: '3rem'
      },
      ['& h1, .MuiTypography-body2']: {
        color: colors[color][props.shade],
        transition: 'all 250ms ease-in-out'
      },

      ['&:hover']: {
        ['& h1']: {
          fontSize: '3rem'
        },
        ['& h1,.MuiTypography-h5']: {
          color: '#fff'
        },
        background: colors[color][props.shade],
        transform: 'translateY(-.5em)',
        cursor: 'pointer'
      }
    };
  },
  active: props => {
    let color = props.color === 'default' ? 'indigo' : props.color;

    return {
      ['& h1']: {
        fontSize: '3rem',
        color: '#fff'
      },
      background: colors[color][props.shade],
      transform: 'translateY(-.5em)',
      ['& .MuiTypography-h5']: {
        color: '#fff'
      }
    };
  },
  icons: {
    fontSize: '4rem',
    position: 'absolute',
    right: '.2em',
    top: '.5em',
    opacity: '35%'
  }
}));

const TransCard = ({
  filterTransactions,
  color,
  shade,
  sJCd,
  icon,
  ...data
}) => {
  const classes = useStyles({ color, shade });

  const { JCd, active, count } = data;

  const hoverable = () => {
    return active ? null : classes.paper;
  };

  const levitate = () => {
    return active ? classes.active : null;
  };

  const Icon = () => {
    if (icon === '') return null;

    if (icon === null) return null;

    if (!icons[icon]) return null;

    return <FontAwesomeIcon icon={icons[icon]} className={classes.icons} />;
  };

  return (
    <Paper
      component={Box}
      p={2}
      elevation={3}
      className={clsx(levitate(), hoverable())}
      onClick={() => filterTransactions(JCd)}
      position="relative"
    >
      <Typography variant="h5" className={classes.caption}>
        {sJCd ?? JCd}
      </Typography>

      <Typography variant="h1">{count}</Typography>

      <Icon />
    </Paper>
  );
};

export default TransCard;
