import React, { useState } from 'react';
import {
  Box,
  Chip,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import SrchSub from '../SrchSub';
import useNotif from 'src/hooks/useNotif';

const MultiSub = ({ data, onChangeField: onChange }) => {
  const notify = useNotif();
  const items = data === '' ? [] : data?.value?.items ?? [];
  const [open, setOpen] = useState(false);
  const [selectedSub, setSelectedSub] = useState({
    ixSub: '',
    sSub: ''
  });

  const handleAddItem = () => {
    if (items.map(data => data.ixSub).includes(selectedSub.ixSub)) {
      notify.error('Already Added.');
      return;
    }

    onChange({
      target: {
        name: data.fld,
        value: {
          items: [
            ...items,
            {
              ixSub: selectedSub.ixSub,
              sSub: selectedSub.sSub
            }
          ]
        }
      }
    });
    setSelectedSub({ ixSub: '', sSub: '' });
  };

  const handleDeleteItem = item => {
    onChange({
      target: {
        name: data.fld,
        value: {
          items: items.filter(data => data.ixSub !== item.ixSub)
        }
      }
    });
  };

  const handleChangeSUb = item => {
    setSelectedSub(item);
  };

  return (
    <Box>
      <Typography variant="body1" color="textSecondary">
        {data.caption}
      </Typography>

      <Box mt={1} display="flex" gridGap=".5em" flexWrap="wrap">
        {items.map(item => (
          <Chip
            key={item.ixSub}
            label={item.sSub}
            color="primary"
            variant="outlined"
            onDelete={() => handleDeleteItem(item)}
          />
        ))}

        <Chip
          icon={<Add fontSize="small" />}
          label={data.sSubType}
          clickable
          color="primary"
          onClick={() => setOpen(true)}
        />
      </Box>

      <Dialog open={open} maxWidth="sm" onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography variant="h4">Select {data.sSubType}</Typography>
        </DialogTitle>
        <DialogContent>
          <SrchSub
            ixSubType={data.ixSubType}
            sSubType={data.sSubType}
            selectedSub={selectedSub.sSub}
            handleSubSelectRoot={handleChangeSUb}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={selectedSub.ixSub === ''}
            onClick={handleAddItem}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MultiSub;
