import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Support from './Support';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark
  }
}));

const SupportPage = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Support">
      <Support />
    </Page>
  );
};

export default SupportPage;
