import axios from 'axios';

export let cancelAPIRequest = null;

const APIRequest = async ({
  method = 'GET',
  url,
  headers = {},
  data = {},
  ...rest
}) => {
  try {
    const res = await axios({
      method,
      url,
      headers,
      data,
      ...rest,
      cancelToken: new axios.CancelToken(function executor(c) {
        cancelAPIRequest = c;
      })
    });

    return { success: true, data: res.data };
  } catch (error) {

    return {
      success: false,
      isCancelled: axios.isCancel(error),
      error:
        error?.response?.data?.msg ||
        error?.response?.data.description ||
        error?.response?.data?.message ||
        error?.response?.data ||
        error?.message ||
        'Something went wrong'
    };
  }
};

export const APIRequestV2 = async ({
  method = 'GET',
  url,
  headers = {},
  data = {},
  ...rest
}) => {
  try {
    const res = await axios({
      method,
      url,
      headers,
      data,
      ...rest,
      cancelToken: new axios.CancelToken(function executor(c) {
        cancelAPIRequest = c;
      })
    });

    return { success: true, data: res.data };
  } catch (error) {
    return {
      success: false,
      isCancelled: axios.isCancel(error),
      error: error.response
    };
  }
};

export default APIRequest;
