import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
  meta: {},
  data: { items: [], totals: {} },
  dateRange: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  page: 0,
  limit: 25,
  ixBrch: 0,
  ixExpCls: 0
};

const slice = createSlice({
  name: 'budgetRAOD',
  initialState: INITIAL_STATE,
  reducers: {
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setBranch(state, action) {
      state.ixBrch = action.payload;
    },
    setExpCls(state, action) {
      state.ixExpCls = action.payload;
    },
    clearData(state, action) {
      state.data = { items: [], totals: {} };
    },
    setLimit(state, action) {
      state.limit = action.payload;
      state.page = 0;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    }
  }
});

const budgetRAODReducer = slice.reducer;
export const {
  setMeta,
  setData,
  setDateRange,
  setBranch,
  clearData,
  setExpCls,
  setPage,
  setLimit,
  clear,
  restore
} = slice.actions;
export default budgetRAODReducer;
