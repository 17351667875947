import React, { createContext, useContext, useState } from 'react';
import useToggle from 'src/hooks/useToggle';
import ConfirmationModal from './ConfirmationModal';
import { random } from 'lodash';

export const ConfirmDialogContext = createContext({
  openConfirmationModal: () => {},
  closeConfimationModal: () => {},
  prompt: ({
    body = null,
    title = 'Confirm',
    onCancel = () => {},
    onOk = ({
      remarks = '',
      loadOn = () => {},
      loadOff = () => {},
      closeConfimationModal = () => {},
      openConfirmationModal = () => {}
    }) => {},
    cancelText = 'Cancel',
    okText = 'Ok',
    okProps = {},
    cancelProps = {},
    hideCancel = false,
    remarksProps = {},
    showRemarks = false,
    requiredRemarks = false,
    autoCloseOnOk = true,
    disableTypography = false,
    showCodeValidation = false
  }) => new Promise()
});

const ConfirmDialog = ({ children }) => {
  const { isOn, off, on } = useToggle();
  const { isOn: loading, off: loadOff, on: loadOn } = useToggle();

  const [confirmationModalProps, setConfirmationModalProps] = useState({
    body: null,
    title: 'Confirm',
    onCancel: off,
    onOk: off,
    cancelText: 'Cancel',
    okText: 'Ok',
    okProps: {},
    cancelProps: {},
    hideCancel: false,
    remarksProps: {},
    showRemarks: false,
    requiredRemarks: false,
    autoCloseOnOk: true,
    disableTypography: false,
    showCodeValidation: false
  });

  const [passCode, setPassCode] = useState('');
  const [userPassCode, setUserPassCode] = useState('');

  const prompt = ({
    body = null,
    title = 'Confirm',
    onCancel = off,
    onOk = off,
    cancelText = 'Cancel',
    okText = 'Ok',
    okProps = {},
    cancelProps = {},
    hideCancel = false,
    remarksProps = {},
    showRemarks = false,
    requiredRemarks = false,
    autoCloseOnOk = true,
    disableTypography = false,
    showCodeValidation = false
  }) =>
    new Promise(resolve => {
      setConfirmationModalProps({
        body,
        title,
        onCancel: () => {
          resolve(
            onCancel({
              loadOff,
              closeConfimationModal
            })
          );
        },
        onOk: ({
          remarks,
          loadOn,
          loadOff,
          closeConfimationModal,
          openConfirmationModal
        }) => {
          resolve(
            onOk({
              remarks,
              loadOn,
              loadOff,
              closeConfimationModal,
              openConfirmationModal
            })
          );
        },
        cancelText,
        okText,
        okProps,
        cancelProps,
        hideCancel,
        remarksProps,
        showRemarks,
        requiredRemarks,
        autoCloseOnOk,
        disableTypography,
        showCodeValidation
      });

      if (showCodeValidation) {
        setPassCode(random(100000, 999999, false).toString());
        setUserPassCode('');
      }

      on();
    });

  const {
    body,
    title,
    onCancel,
    onOk,
    cancelText,
    okText,
    okProps,
    cancelProps,
    hideCancel,
    remarksProps,
    showRemarks,
    requiredRemarks,
    autoCloseOnOk,
    disableTypography,
    showCodeValidation
  } = confirmationModalProps;

  const closeConfimationModal = () => off();

  const openConfirmationModal = () => on();

  const handleOk = remarks => {
    onOk({
      remarks,
      loadOff,
      loadOn,
      closeConfimationModal,
      openConfirmationModal
    });

    if (autoCloseOnOk) off();
  };

  return (
    <ConfirmDialogContext.Provider
      value={{ openConfirmationModal, closeConfimationModal, prompt }}
    >
      {children}
      <ConfirmationModal
        open={isOn}
        handleClose={off}
        body={body}
        title={title}
        onCancel={onCancel}
        onOk={handleOk}
        cancelText={cancelText}
        okText={okText}
        okProps={okProps}
        cancelProps={cancelProps}
        hideCancel={hideCancel}
        remarksProps={remarksProps}
        showRemarks={showRemarks}
        requiredRemarks={requiredRemarks}
        isLoading={loading}
        disableTypography={disableTypography}
        showCodeValidation={showCodeValidation}
        passCode={passCode}
        userPassCode={userPassCode}
        updateUserPassCode={setUserPassCode}
      />
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmDialog = () => useContext(ConfirmDialogContext);
export default ConfirmDialog;
