import { escapeRegExp } from 'lodash';

const filterListByQuery = (query = '', list = [], props = []) => {
  const searchRegEx = new RegExp(escapeRegExp(query), 'i');

  return list.filter(item =>
    props.length === 0
      ? Object.keys(item).some(field =>
          searchRegEx.test((item?.[field] || '').toString())
        )
      : props.some(prop => searchRegEx.test((item?.[prop] || '').toString()))
  );
};

export default filterListByQuery;
