import {
  CHANGE_BIZ_INFO,
  CHANGE_CURRENT_FORM,
  CHANGE_USER_INFO,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_VALIDATION_FAILED,
  CREATE_ACCOUNT_FAILED,
  UPDATE_TIMER,
  RESEND_OTP,
  RESEND_OTP_ERROR,
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAILED,
  CLEAR_REGISTRATION
} from '../actions/registration';

const initialState = {
  biz: {
    Name: '',
    Address1: '',
    Address2: '',
    TIN: '',
    OwnersName: '',
    ixPlan: '',
    ixIndustry: '',
    accept: false
  },
  user: {
    uname: '',
    LName: '',
    FName: '',
    MName: '',
    SName: '',
    email: '',
    phone: '',
    password: ''
  },
  timer: 30,
  isCreatingAccount: false,
  isActivating: false,
  isResendingOTP: false,
  currentForm: 0,
  saveResults: null,
  activateResult: null,
  errors: null,
  generalError: '',
  activateError: null,
  resendOTPError: null
};

const registrationReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case CHANGE_BIZ_INFO: {
      return {
        ...state,
        biz: {
          ...state.biz,
          ...payload
        }
      };
    }
    case CHANGE_USER_INFO: {
      return {
        ...state,
        user: {
          ...state.user,
          ...payload
        }
      };
    }

    case CHANGE_CURRENT_FORM: {
      return {
        ...state,
        currentForm: payload
      };
    }

    case CREATE_ACCOUNT: {
      return {
        ...state,
        isCreatingAccount: true,
        errors: null,
        generalError: ''
      };
    }

    case CREATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        currentForm: 2,
        isCreatingAccount: false,
        saveResults: payload
      };
    }

    case CREATE_ACCOUNT_VALIDATION_FAILED: {
      return {
        ...state,
        isCreatingAccount: false,
        errors: payload
      };
    }

    case CREATE_ACCOUNT_FAILED: {
      return {
        ...state,
        isCreatingAccount: false,
        generalError: payload
      };
    }

    case UPDATE_TIMER: {
      return {
        ...state,
        isResendingOTP: false,
        timer: payload
      };
    }

    case RESEND_OTP: {
      return {
        ...state,
        isResendingOTP: true
      };
    }

    case RESEND_OTP_ERROR: {
      return {
        ...state,
        isResendingOTP: false,
        resendOTPError: payload
      };
    }

    case ACTIVATE_ACCOUNT: {
      return {
        ...state,
        isActivating: true
      };
    }

    case ACTIVATE_ACCOUNT_SUCCESS: {
      return {
        ...initialState,
        currentForm: 3
      };
    }

    case ACTIVATE_ACCOUNT_FAILED: {
      return {
        ...state,
        isActivating: false,
        activateError: payload
      };
    }

    case CLEAR_REGISTRATION: {
      return {
        ...initialState
      };
    }

    default:
      return state;
  }
};

export default registrationReducer;
