import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { GridColDef } from '@material-ui/data-grid';
import { getPropValue } from 'src/utils';
import { v4 as uuid } from 'uuid';
import { DataGrid } from '@material-ui/data-grid';

/**
 *
 * @param {Array<{render: {type: 'numeral'|'moment', format: string}}>} columns
 * @returns {Array<GridColDef>}
 */
function generateColumns(columns) {
  return columns.map(col => {
    if (!col.hasOwnProperty('render')) return col;

    const newCol = { ...col };

    if (col?.render?.type === 'moment')
      col.valueFormatter = ({ value }) =>
        moment(value).format(col?.render?.format || 'YYYY-MM-DD');

    if (col?.render?.type === 'numeral')
      col.valueFormatter = ({ value }) =>
        numeral(value).format(col?.render?.format || '0,0.00');

    return newCol;
  });
}

/**
 *
 * @param {string} rowID
 * @param {Array<{field: string}>} columns
 * @param {Array<object>} data
 * @param {object} currentValue
 */
function generateData(rowID, columns, data, currentValue) {
  const lastValue = {};
  columns.forEach(col => {
    lastValue[col.field] = getPropValue(currentValue, col.field, '');
  });

  const result = data.map(item => {
    const dataItem = {
      [rowID]: getPropValue(item, rowID, uuid())
    };

    columns.forEach(col => {
      const value = getPropValue(item, col.field, '');

      if (value) {
        dataItem[col.field] = value;
        lastValue[col.field] = value;
      } else {
        dataItem[col.field] = lastValue[col.field];
      }
    });

    return dataItem;
  });

  // result.reverse();
  return result;
}

/**
 *
 * @param {subAccountDetails} subAcc
 */
function getCurrentValue(subAcc) {
  const kvs = {};

  (subAcc?.kvs || []).forEach(({ key, value }) => {
    kvs[key] = value;
  });

  return { ...subAcc, kvs };
}

const Logs = ({ data }) => {
  const currentValue = getCurrentValue(data?.currentValue || {});
  const columns = generateColumns(data?.columns || []);
  const tableData = generateData(
    data?.rowID || 'id',
    data?.columns || [],
    data?.value || [],
    { updates: currentValue }
  );

  return (
    <DataGrid
      rows={tableData}
      columns={columns}
      getRowId={row => row?.[data?.rowID || 'id']}
      autoHeight
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      hideFooter
    />
  );
};

export default Logs;
