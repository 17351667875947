import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  ixTL: 0,
  RefNo: '',
  jDate: '',
  ixSubAcc1: 0,
  ixSubAcc2: 0,
  ixSubAcc3: 0,
  ixSubAcc4: 0,
  ixSubAcc5: 0,
  Address: '',
  Particulars: '',
  Remarks: '',
  ixBrch: 0,
  Name: '',
  ixSubType: 0,
  ixLinkSked: 0,
  kvs: {},
  checkDetails: {
    tCheckNo: '',
    tCheckDate: '',
    tCheckAccNo: '',
    ixAcc: 0
  },
  trans_je: [],
  trans_part: []
};

const slice = createSlice({
  name: 'jvTrans',
  initialState: INITIAL_STATE,
  reducers: {
    setJVTrans(state, action) {
      return action.payload;
    },
    clear(state, action) {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const jvTrans = slice.reducer;
export const { setJVTrans, clear } = slice.actions;
export default jvTrans;
