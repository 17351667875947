import axios from 'axios';
import Base64 from 'Base64';
export const CHANGE_BIZ_INFO = 'CHANGE_BIZ_INFO';
export const CHANGE_CURRENT_FORM = 'CHANGE_CURRENT_FORM';
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_VALIDATION_FAILED =
  'CREATE_ACCOUNT_VALIDATION_FAILED';
export const CREATE_ACCOUNT_FAILED = 'CREATE_ACCOUNT_FAILED';
export const UPDATE_TIMER = 'UPDATE_TIMER';
export const RESEND_OTP = 'RESEND_OTP';
export const RESEND_OTP_ERROR = 'RESEND_OTP_ERROR';
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS';
export const ACTIVATE_ACCOUNT_FAILED = 'ACTIVATE_ACCOUNT_FAILED';
export const CLEAR_REGISTRATION = 'CLEAR_REGISTRATION';

export const changeBizInfo = payload => ({
  type: CHANGE_BIZ_INFO,
  payload
});

export const changeCurrentForm = payload => ({
  type: CHANGE_CURRENT_FORM,
  payload
});

export const changeUserInfo = payload => ({
  type: CHANGE_USER_INFO,
  payload
});

export const createAccount = payload => ({
  type: CREATE_ACCOUNT,
  payload
});

export const createAccountSuccess = payload => ({
  type: CREATE_ACCOUNT_SUCCESS,
  payload
});

export const createAccountValidationFailed = payload => ({
  type: CREATE_ACCOUNT_VALIDATION_FAILED,
  payload
});

export const createAccountFailed = payload => ({
  type: CREATE_ACCOUNT_FAILED,
  payload
});

export const resendOTP = () => ({
  type: RESEND_OTP
});

export const resendOTPError = payload => ({
  type: RESEND_OTP_ERROR,
  payload
});

export const activateAccount = () => ({
  type: ACTIVATE_ACCOUNT
});

export const activateAccountSuccess = payload => ({
  type: ACTIVATE_ACCOUNT_SUCCESS,
  payload
});

export const activateAccountFailed = payload => ({
  type: ACTIVATE_ACCOUNT_FAILED,
  payload
});

export const clearRegistration = () => ({ type: CLEAR_REGISTRATION });

export const register = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const {
    biz: { accept, ...bizData },
    user: { password, uname, ...rest }
  } = getState().registrationReducer;

  dispatch(createAccount());

  await axios({
    method: 'POST',
    url: `${base_url}/registry/biz/new`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    },
    data: {
      biz: { ...bizData },
      user: { username: uname, ...rest }
    }
  })
    .then(({ data }) => {
      dispatch(createAccountSuccess(data));
    })
    .catch(error => {
      const errors = error?.response?.data;

      const { user, biz } = errors;

      if (!user?.errors && !biz?.errors) {
        dispatch(
          createAccountFailed('Something went wrong. Please try again.')
        );
        return;
      }

      if (biz?.errors) {
        dispatch(createAccountValidationFailed(errors));
        dispatch(changeCurrentForm(0));
        return;
      }

      if (user?.errors) {
        dispatch(createAccountValidationFailed(errors));
        dispatch(changeCurrentForm(1));
      }
    });
};

export const updateTimer = payload => ({
  type: UPDATE_TIMER,
  payload
});

export const resendCode = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const { saveResults } = getState().registrationReducer;

  dispatch(resendOTPError(null));

  if (!saveResults?.user?.username) return;

  dispatch(resendOTP());

  await axios({
    method: 'POST',
    url: `${base_url}/registry/biz/user/otp-resend`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    },
    data: {
      username: saveResults?.user?.username
    }
  })
    .then(({ data }) => {
      dispatch(resendOTPError(''));
      dispatch(updateTimer(30));
    })
    .catch(e => {
      dispatch(resendOTPError('Failed to resend code. Please try again.'));
    });
};

export const activateCode = code => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const { saveResults, user } = getState().registrationReducer;

  dispatch(activateAccountFailed(null));

  if (code === '' || code === null || typeof code === 'undefined') return;

  dispatch(activateAccount());

  await axios({
    method: 'POST',
    url: `${base_url}/registry/biz/user/otp-verify`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken,
      Authorization: `Basic ${Base64.btoa(
        saveResults?.user?.username + ':' + user.password
      )}`
    },
    data: {
      username: saveResults?.user?.username,
      code
    }
  })
    .then(({ data }) => {
      dispatch(activateAccountSuccess(data));
    })
    .catch(e => {
      console.log(e);
      dispatch(activateAccountFailed('Activation failed. Please try again.'));
    });
};
