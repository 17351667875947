import { read as readXlsx, utils as xlsxUtils } from 'xlsx';

/**
 *
 * @param {any} file
 * @param {(data: any[]) => void} handler
 */
function excelToJson(file, handler) {
  const reader = new FileReader();

  reader.onload = evt => {
    // parse data
    const bstr = evt.target.result;
    const workbook = readXlsx(bstr, { type: 'binary' });

    // get first worksheet
    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];

    // convert sheet to json
    const data = xlsxUtils.sheet_to_json(worksheet, { header: 1 });

    handler(data);
  };

  reader.readAsArrayBuffer(file);
}

export default excelToJson;
