import React from 'react';
import { makeStyles, Paper, Box } from '@material-ui/core';
import Page from 'src/components/Page';
import Content from './Content';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6),
    maxWidth: '1280px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6),
      width: '90%'
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(6),
      width: '90%'
    }
  }
}));

const Terms = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Terms of Service">
      <Paper component={Box} className={classes.root} elevation={6}>
        <Content />
      </Paper>
    </Page>
  );
};

export default Terms;
