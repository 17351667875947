import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  date: {
    dt1: '',
    dt2: '',
    textValue: ''
  },
  transType: {},
  earning: {},
  deduction: {},
  employee: {},
  type: {},
  activeType: {},
  data: {}
};

const slice = createSlice({
  name: 'prAnnualSummary',
  initialState: INITIAL_STATE,
  reducers: {
    setDate(state, action) {
      state.date = action.payload;
    },
    setTransType(state, action) {
      state.transType = action.payload;
    },
    setEarning(state, action) {
      state.earning = action.payload;
    },
    setDeduction(state, action) {
      state.deduction = action.payload;
    },
    setEmployee(state, action) {
      state.employee = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setActiveType(state, action) {
      state.activeType = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const prAnnualSummaryReducer = slice.reducer;

export const {
  setDate,
  setTransType,
  setEarning,
  setDeduction,
  setEmployee,
  setType,
  clear,
  restore,
  setData,
  setActiveType
} = slice.actions;
export default prAnnualSummaryReducer;
