import React, { useState, useEffect, useContext, createContext } from 'react';
import { useRequest, useToggle } from 'src/hooks';
import { useNavigate } from 'react-router';
import Template1 from './Templates/Template1';

const DynamicPageContext = createContext({
  loading: false,
  pageDetails: {}
});

export const TEMPLATES = {
  template1: Template1
};

function DynamicPageProvider({ cdPage = '', children }) {
  const [pageDetails, setDetails] = useState({});
  const [loading, loadOn, loadOff] = useToggle();
  const request = useRequest(loadOn, loadOff);
  const navigate = useNavigate();

  async function getPageDetails(cancelCb) {
    const { success, data, error, isCancelled } = await request.get(
      `/pages/by-code/${cdPage}`,
      {},
      cancelCb
    );

    if (!success && isCancelled) return;
    else if (!success) {
      if (error.status === 404) navigate('/404');
      return;
    } else setDetails(data);
  }

  useEffect(() => {
    let cancel = () => {};

    getPageDetails(c => (cancel = c));

    return () => {
      cancel();
    };
  }, [cdPage]);

  return (
    <DynamicPageContext.Provider
      value={{
        loading,
        pageDetails
      }}
    >
      {children}
    </DynamicPageContext.Provider>
  );
}

export default DynamicPageProvider;

export const useDynamicPages = () => useContext(DynamicPageContext);
