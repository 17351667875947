import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  property: {
    color: '#116329'
  },
  operator: {
    color: '#0550ae'
  },
  value: {
    color: '#0550ae'
  },
  defaultBg: {
    backgroundColor: theme.palette.background.paper
  },
  comment: {
    color: '#6e7781'
  }
}));

const Line = ({ jsonLine = '', hasError = false, message = '' }) => {
  const classNames = useStyles();
  const operatorIndex = jsonLine.indexOf(':');

  if (operatorIndex === -1)
    return <span className="punctuation">{jsonLine.trim()}</span>;

  const property = jsonLine.substring(0, operatorIndex).trim();
  let value = jsonLine.substring(operatorIndex + 1, jsonLine.length).trim();
  let punctuation = '';

  const hasPunctuation = [',', '[', '{'].includes(value[value.length - 1]);
  if (hasPunctuation) {
    punctuation = value[value.length - 1];
    value = value.substring(0, value.length - 1);
  }

  return (
    <>
      <span className={classNames.property}>{property}</span>
      <span className={classNames.operator}>
        {jsonLine[operatorIndex]}
      </span>{' '}
      <span
        style={
          hasError
            ? {
                backgroundColor: '#f44336',
                color: '#FFF',
                padding: '0 2px',
                borderRadius: 4
              }
            : { color: '#0550ae' }
        }
      >
        {value}
      </span>
      {hasPunctuation && <span className="punctuation">{punctuation}</span>}
      {message && (
        <>
          &emsp; &emsp; &emsp;
          <span className={classNames.comment}>// {message}</span>
        </>
      )}
    </>
  );
};

const JsonPreview = ({ json = {}, space = 2, errors = [] }) => {
  const classNames = useStyles();

  const errorList = errors.reduce(
    (accumulator, current) => [...accumulator, current.line],
    []
  );

  function getMessage(index) {
    const message = errors.find(error => error.line === index)?.message || '';
    return message;
  }

  const jsonLines = JSON.stringify(json, null, space).split('\n');

  return (
    <pre className={classNames.defaultBg}>
      <code className={classNames.code}>
        {jsonLines.map((line, index) => (
          <span key={index}>
            {line.substring(0, line.search(/\S/))}
            <Line
              jsonLine={line}
              hasError={errorList.includes(index + 1)}
              message={getMessage(index + 1)}
            />
            {'\n'}
          </span>
        ))}
      </code>
    </pre>
  );
};

export default JsonPreview;
