const parseShortcutNumber = value => {
  const BASE = {
    m: 1000000,
    k: 1000,
    h: 100,
    t: 10
  };

  const lastChar = [...value].slice(-1)[0];

  if (BASE?.[lastChar]) {
    if (value.length === 1) return BASE?.[lastChar];

    const num =
      [...value].splice(0, value.length - 1).join('') * BASE?.[lastChar];

    return isNaN(num) ? 0 : num;
  }

  return 0;
};

export default parseShortcutNumber;
