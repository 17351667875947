import React from "react";
import numeral from "numeral";
import { Box, LinearProgress, Typography } from "@material-ui/core";
import { GridColDef } from "@material-ui/data-grid";
import clsx from "clsx";

const LinearProgressWithLabel = ({ progress }) => {
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{ height: 16, borderRadius: 12 }}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {progress} %
        </Typography>
      </Box>
    </Box>
  );
};

const tableColumns = [
  {
    field: "desc",
    headerName: "Description",
    width: 200,
    sortable: false
  },
  {
    field: "date",
    headerName: "Date",
    width: 150,
    sortable: false
  }
];

const valueFormatter = ({ value }) => numeral(value).format("0,0.00");

export const generatePaySchedTblColumns = ({
  accounts = [],
  showRebateColumn = false,
  classNames = [],
  thickRightBorder = "",
  noSeparator = ""
}) => {
  /**
   * @type {GridColDef[]}
   */
  const columns = [
    {
      field: "desc",
      headerName: "Description",
      width: 200,
      sortable: false
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      sortable: false,
      cellClassName: thickRightBorder,
      headerClassName: clsx(thickRightBorder, noSeparator)
    }
  ];

  accounts.forEach(({ heading }, index) => {
    columns.push({
      field: heading,
      headerName: "Receivables - " + heading,
      headerAlign: "center",
      width: 160,
      sortable: false,
      align: "right",
      editable: true,
      headerClassName: classNames?.[index] || "",
      type: "number",
      valueFormatter
    });

    if (showRebateColumn)
      columns.push({
        field: "rebate" + heading,
        headerName: "Rebate - " + heading,
        headerAlign: "center",
        width: 150,
        sortable: false,
        align: "right",
        editable: true,
        headerClassName: classNames?.[index] || "",
        type: "number",
        valueFormatter
      });

    columns.push(
      {
        field: "percentage" + heading,
        headerName: `Receivables - ${heading} (%)`,
        width: 180,
        sortable: false,
        headerClassName: classNames?.[index] || "",
        align: "right",
        renderCell: ({ value }) => <LinearProgressWithLabel progress={value} />
      },
      {
        field: "paid" + heading,
        headerName: "Paid",
        headerAlign: "center",
        width: 150,
        sortable: false,
        align: "right",
        headerClassName: classNames?.[index] || "",
        valueFormatter
      },
      {
        field: "balance" + heading,
        headerName: "Balance",
        headerAlign: "center",
        width: 150,
        sortable: false,
        align: "right",
        headerClassName: clsx(
          classNames?.[index] || "",
          thickRightBorder,
          noSeparator
        ),
        cellClassName: clsx(thickRightBorder),
        valueFormatter
      }
    );
  });

  if (showRebateColumn)
    columns.push(
      {
        field: "totalRebate",
        headerName: "Total Rebate",
        headerAlign: "center",
        width: 150,
        sortable: false,
        align: "right",
        valueFormatter
      },
      {
        field: "totalPaid",
        headerName: "Total Paid",
        headerAlign: "center",
        width: 150,
        sortable: false,
        align: "right",
        valueFormatter
      },
      {
        field: "totalBal",
        headerName: "Total Balance",
        headerAlign: "center",
        width: 150,
        sortable: false,
        align: "right",
        valueFormatter
      }
    );

  return columns;
};

export default generatePaySchedTblColumns;
