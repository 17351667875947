import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  result: [],
  meta: {},
  dt1: null,
  dt2: null,
  textValue: "",
  ixBrch: 0,
  ixWH: 0,
  items_per_page: 25,
  page: 1,
  showBranch: false,
  loadAll: false,
  ixCat: 0,
  ixProdSubCat: 0,
  by_transaction: false
};

const slice = createSlice({
  name: "stockImage",
  initialState: INITIAL_STATE,
  reducers: {
    setProdCat(state, action) {
      state.ixCat = action.payload;
    },
    setSubCat(state, action) {
      state.ixProdSubCat = action.payload;
    },
    setWarehouse(state, action) {
      state.ixWH = action.payload;
    },
    setBranch(state, action) {
      state.ixBrch = action.payload;
    },
    setDateRange(state, action) {
      state.dt1 = action.payload.dt1;
      state.dt2 = action.payload.dt2;
      state.textValue = action.payload.textValue;
    },
    loadResult(state, action) {
      state.result = action.payload.items;
      state.loadAll = action.payload.loadAll;

      state.showBranch = action.payload.items.some(item => {
        const firstItem = action.payload.items[0];
        return firstItem.ixBrch !== item.ixBrch;
      });
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setSize(state, action) {
      state.items_per_page = action.payload;
    },
    setByTransaction(state, action) {
      state.by_transaction = action.payload;
    },
    setMeta(state, action) {
      state.meta = action.payload;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const stockImageReducer = slice.reducer;

export const {
  setDateRange,
  setProdCat,
  setSubCat,
  setWarehouse,
  setBranch,
  setPage,
  setSize,
  loadResult,
  setByTransaction,
  setByMerge_Product,
  setByMerge_Branch,
  setMeta,
  clear,
  restore
} = slice.actions;
export default stockImageReducer;
