import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { Bar, Doughnut, HorizontalBar } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  useTheme,
  makeStyles,
  Button,
  ButtonGroup
} from '@material-ui/core';
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined';
import InsertChartOutlinedTwoToneIcon from '@material-ui/icons/InsertChartOutlinedTwoTone';
import { useChartOptions } from 'src/hooks';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}));

const WFChart = ({
  className,
  data = [],
  backgroundColor = [],
  labels = [],
  label = '',
  title = '',
  callBack = e => null,
  defaultChartType = 'pie',
  onChangeChartType = () => null,
  ...rest
}) => {
  const [chartType, setChartType] = useState('pie');
  const [barType, setBarType] = useState('vertical');
  const classes = useStyles();
  const chartOption = useChartOptions();

  let options = chartOption[chartType];
  options = {
    ...options,
    legend: {
      ...options.legend,
      display: chartType === 'pie'
    },
    onClick: function(e, items) {
      callBack(items[0]);
    }
  };

  const handleChangeChartType = type => {
    setChartType(type);
    onChangeChartType(type);
  };

  useEffect(() => {
    setChartType(defaultChartType);
  }, [defaultChartType]);

  useEffect(() => {
    if (data.length >= 10) {
      setBarType('horizontal');
    } else {
      setBarType('vertical');
    }
  }, [data.length]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title={title}
        action={
          <ButtonGroup
            color="primary"
            size="small"
            aria-label="outlined primary button group small"
          >
            <Button
              onClick={() => handleChangeChartType('pie')}
              variant={chartType === 'pie' ? 'contained' : ''}
              size="small"
            >
              <PieChartOutlinedIcon size="small" />
            </Button>
            <Button
              onClick={() => handleChangeChartType('bar')}
              variant={chartType === 'bar' ? 'contained' : ''}
              size="small"
            >
              <InsertChartOutlinedTwoToneIcon size="small" />
            </Button>
          </ButtonGroup>
        }
      />
      <CardContent>
        <Box height={300} position="relative">
          {chartType === 'pie' && (
            <Doughnut
              data={{
                datasets: [
                  {
                    label,
                    data,
                    backgroundColor
                  }
                ],

                labels
              }}
              options={options}
            />
          )}

          {chartType === 'bar' && barType === 'vertical' && (
            <Bar
              data={{
                datasets: [
                  {
                    label,
                    data,
                    backgroundColor
                  }
                ],

                labels
              }}
              options={options}
            />
          )}

          {chartType === 'bar' && barType === 'horizontal' && (
            <HorizontalBar
              data={{
                datasets: [
                  {
                    label,
                    data,
                    backgroundColor
                  }
                ],

                labels
              }}
              options={options}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default WFChart;
