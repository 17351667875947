import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import Zoom from '@material-ui/core/Zoom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const Canvas = ({ content }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (!ref) return;

    const ctx = ref.current.getContext('2d');
    ctx.font = '40px Arial';
    ctx.fillText(content ?? '', 80, 60);
  }, [content]);

  return <canvas ref={ref} width="300" height="100" />;
};

const useStyles = makeStyles(theme => ({
  okDelete: {
    background: red[500],
    color: '#fff',
    '&:hover': {
      background: red[300],
      color: '#fff'
    }
  },
  dialogContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3)
  }
}));

const ConfirmationModal = ({
  open = false,
  handleClose,
  body = null,
  title = '',
  onCancel,
  onOk,
  cancelText = 'Cancel',
  okText = 'Ok',
  okProps = {},
  remarksProps = {},
  cancelProps = {},
  hideCancel = false,
  showRemarks = false,
  requiredRemarks = false,
  isLoading = false,
  disableTypography = false,
  showCodeValidation = false,
  passCode = '',
  userPassCode = '',
  updateUserPassCode = () => {}
}) => {
  const classes = useStyles();
  const [remarks, setRemarks] = useState('');

  const handleOk = () => {
    if (showRemarks) {
      onOk(remarks);
    } else {
      onOk();
    }
    setRemarks('');
  };

  const handleChangeRemarks = e => {
    const val = e.target.value;
    setRemarks(val);
  };

  const handleCancel = () => {
    onCancel();
    setRemarks('');
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason);
        }
      }}
      maxWidth="xs"
      TransitionComponent={Transition}
      fullWidth
    >
      <DialogTitle disableTypography={disableTypography}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {body}
        {showRemarks && (
          <Box mt={2}>
            <TextField
              value={remarks}
              onChange={handleChangeRemarks}
              placeholder="Write something..."
              multiline
              fullWidth
              minRows={3}
              autoFocus
              {...remarksProps}
            />
          </Box>
        )}
        {showCodeValidation && (
          <>
            <Box display="flex" justifyContent="center">
              <Canvas content={passCode} />
            </Box>
            <TextField
              fullWidth
              placeholder="Input the code given above to proceed"
              value={userPassCode}
              onChange={e => updateUserPassCode(e.target.value)}
              required
              name="code"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        {hideCancel ? null : (
          <Button
            variant="text"
            disabled={isLoading}
            onClick={handleCancel}
            {...cancelProps}
          >
            {cancelText}
          </Button>
        )}
        <Button
          color="primary"
          onClick={handleOk}
          disabled={
            (requiredRemarks && remarks.length === 0) ||
            (showCodeValidation && passCode !== userPassCode) ||
            isLoading
          }
          {...okProps}
        >
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
