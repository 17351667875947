import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Slide,
  CircularProgress,
  IconButton,
  makeStyles,
  AppBar,
  Toolbar,
  Box,
  Paper,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import getFile, { cancel } from 'src/helpers/getFile';
import Viewer from 'react-viewer';
import { Close } from '@material-ui/icons';

const FILE_MAP = {
  pdf: 'application/pdf',
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  mp4: 'video/mp4'
};

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  }
}));

const getFileType = fileName => {
  const splittedFilename = fileName.split('.');

  return splittedFilename[splittedFilename.length - 1];
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageViewer = ({ url, onClose, open }) => {
  return (
    <Viewer
      visible={open}
      onClose={onClose}
      images={[{ src: url, alt: 'image' }]}
      zIndex={1600}
    />
  );
};

const PDFViewer = ({ url, open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={true}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar component={Box} display="flex" justifyContent="end">
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>

      <iframe
        src={url}
        style={{ width: '100%', height: '100%' }}
        container={Paper}
      />
    </Dialog>
  );
};

const VideoViewer = ({ url, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <video autoPlay controls>
        <source src={url} type="video/mp4" />
        Failed to load Video
      </video>
    </Dialog>
  );
};

const FallBack = ({ fileUrl, fileName, onClose, open }) => {
  useEffect(() => {
    if (open && fileUrl) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      onClose();
    }
  }, [open, fileUrl]);

  return null;
};

const ViewFile = ({ fileUrl, fileType, fileName, open, onClose }) => {
  if (['png', 'jpg', 'jpeg'].includes(fileType))
    return <ImageViewer url={fileUrl} open={open} onClose={onClose} />;
  if (fileType === 'pdf')
    return <PDFViewer url={fileUrl} open={open} onClose={onClose} />;
  if (fileType === 'mp4')
    return <VideoViewer url={fileUrl} open={open} onClose={onClose} />;
  return (
    <FallBack
      fileUrl={fileUrl}
      fileName={fileName}
      open={open}
      onClose={onClose}
    />
  );
};

const Loading = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height={200}
          width={200}
          gridGap="2em"
        >
          <CircularProgress size={50} />

          <Button onClick={onClose} color="primary" size="small" fullWidth>
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const FileViewer = ({
  url = '',
  fileName = '',
  method = 'get',
  fetchConfig = {},
  downloadAndView = false,
  open = false,
  onClose
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const { userToken } = useSelector(state => state.auth);
  const fileType = getFileType(fileName);

  const get = async () => {
    setIsLoading(true);
    const type = FILE_MAP[fileType];
    const file = await getFile({
      url,
      userToken,
      fileType: type,
      method,
      fetchConfig
    });
    setFileUrl(file);
    setIsLoading(false);
  };

  const cancelRequest = () => {
    cancel();
    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    if (!open) return;

    if (fileUrl === null) {
      if (downloadAndView) get();
      else setFileUrl(url);
    }
  }, [open]);

  if (isLoading) return <Loading open={isLoading} onClose={cancelRequest} />;

  return (
    <ViewFile
      fileUrl={fileUrl}
      fileType={fileType}
      fileName={fileName}
      onClose={onClose}
      open={open}
    />
  );
};

export default FileViewer;
