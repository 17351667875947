import { colors } from '@material-ui/core';
import { THEMES } from 'src/constants';
import { strongShadows, softShadows } from '../shadows';

export default {
  name: THEMES.UNICORN,
  light: {
    palette: {
      type: 'light',
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        navbar: '#a67dff'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(0, 0, 0, 0.38)'
      }
    },
    overrides: {
      MuiAppBar: {},
      MuiButton: {},
      MuiListItem: {},
      MuiLinearProgress: {}
    },
    shadows: softShadows
  },
  dark: {
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#a67dff',
        navbar: '#a67dff',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    overrides: {
      MuiAppBar: {},
      MuiButton: {},
      MuiListItem: {},
      MuiLinearProgress: {}
    },
    shadows: strongShadows
  }
};
