import React from 'react';
import CTATl1 from './CTATl1';

/**
 *
 * @param {object} param
 * @param {pageCTA} param.pageDetails
 * @returns
 */
const CTA = ({ pageDetails }) => {
  return <CTATl1 pageDetails={pageDetails} />;
};

export default CTA;
