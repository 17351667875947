import React, { Children } from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';

const FieldContainer = ({
  showContainer = true,
  title = '',
  titleProps = {},
  children
}) => {
  const theme = useTheme();

  const containerContent = Children.map(children, child => child);

  if (!containerContent.length) return null;

  if (!showContainer) return containerContent;

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={3} sm={3} xs={12}>
        <Typography variant="h5" color="textSecondary" {...titleProps}>
          {title}
        </Typography>
      </Grid>
      <Grid item lg={9} md={9} sm={9} xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          gridRowGap={theme.spacing(2)}
        >
          {containerContent}
        </Box>
      </Grid>
    </Grid>
  );
};

export default FieldContainer;
