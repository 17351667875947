import { useSelector } from 'react-redux';

const useWFStatus = () => {
  const { wf } = useSelector(state => state.bizMeta);

  function getStatusProps(ixJCd, wfStatus) {
    const trans = wf[ixJCd] || {};

    return trans?.wf?.[wfStatus] || {};
  }

  return { wf, getStatusProps };
};

export default useWFStatus;
