import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Avatar,
  AppBar,
  Toolbar,
  makeStyles,
  Link,
  Box,
  Container,
  Button,
  Hidden,
  IconButton,
  Typography
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import MobileMenu from './MobileMenu';
import { useAuth } from 'src/hooks';

const useStyles = makeStyles(theme => ({
  toolbar: {
    background: '#fff',
    height: 64
  },
  appBar: {
    [theme.breakpoints.down('md')]: {
      height: '100%'
    }
  },
  link: {
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightBold,
    '& + &': {
      marginLeft: theme.spacing(4)
    },
    '&:hover': {
      color: '#FF7704'
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  biz: {
    color: '#FF7704',
    fontWeight: 900
  },

  iconContainer: {
    display: 'flex',
    gap: '.3em',
    alignItems: 'center',
    filter: 'grayscale(10%)',
    transition: 'all 500ms ease-in-out',
    '&:hover': {
      filter: 'grayscale(0)'
    }
  },
  signInButton: {
    background: '#FF7704',
    fontWeight: 900,
    marginLeft: '.5em',
    color: '#fff',
    '&:hover': {
      background: '#FF7704',
      color: '#fff'
    }
  },
  burgerIcon: {
    color: '#FF7704',
    fontWeight: 900
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { app, require_change_pw, registry, portal } = useAuth();

  return (
    <AppBar
      className={open ? classes.appBar : ''}
      elevation={0}
      color="inherit"
      {...rest}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters className={classes.toolbar}>
          <Box className={classes.iconContainer}>
            <RouterLink to="/">
              <Avatar src="/static/images/icon_no_white_background.png" />
            </RouterLink>
            <Link
              component={RouterLink}
              variant="h3"
              to="/"
              underline="none"
              color="textPrimary"
            >
              Ulap<span className={classes.biz}>Biz</span>
            </Link>
          </Box>

          <Hidden smDown>
            <Box ml={5}>
              <Typography
                className={classes.link}
                component={RouterLink}
                to="/"
                color="textSecondary"
                underline="none"
                variant="body2"
              >
                Home
              </Typography>
              <Typography
                className={classes.link}
                component={RouterLink}
                to="/plans"
                color="textSecondary"
                underline="none"
                variant="body2"
              >
                Plans
              </Typography>
              <Typography
                className={classes.link}
                component={RouterLink}
                to="/accounting-and-beyond"
                color="textSecondary"
                underline="none"
                variant="body2"
              >
                Accounting and Beyond
              </Typography>
              <Typography
                className={classes.link}
                component={RouterLink}
                to="/notable-features"
                color="textSecondary"
                underline="none"
                variant="body2"
              >
                Features
              </Typography>

              <Typography
                className={classes.link}
                component={RouterLink}
                to="/reports-and-schedule"
                color="textSecondary"
                underline="none"
                variant="body2"
              >
                Schedules and Reports
              </Typography>

              <Typography
                className={classes.link}
                component={RouterLink}
                to="/contact-us"
                color="textSecondary"
                underline="none"
                variant="body2"
              >
                Contact Us
              </Typography>
            </Box>
            <Box flex={1} />
            {!registry && !require_change_pw && app ? (
              <>
                {portal && (
                  <Link
                    className={classes.link}
                    component={RouterLink}
                    to="/portal/welcome"
                    color="textSecondary"
                    underline="none"
                    variant="body1"
                  >
                    Portal
                  </Link>
                )}

                {app && (
                  <Link
                    className={classes.link}
                    component={RouterLink}
                    // onClick={() => navigate('/app/dashboard')}
                    to="/app/dashboard"
                    color="textSecondary"
                    underline="none"
                    variant="body1"
                  >
                    Dashboard
                  </Link>
                )}
              </>
            ) : (
              <>
                <Button
                  className={classes.link}
                  component={RouterLink}
                  to="/app/login"
                  underline="none"
                >
                  Sign In
                </Button>
                <Button
                  className={classes.signInButton}
                  component={RouterLink}
                  to="/app/register"
                  underline="none"
                >
                  Sign Up
                </Button>
              </>
            )}
          </Hidden>

          <Hidden mdUp>
            <Box flex={1} />
            <IconButton
              onClick={() => setOpen(prev => !prev)}
              className={classes.burgerIcon}
            >
              {!open ? <MenuIcon /> : <ClearIcon />}
            </IconButton>
          </Hidden>
        </Toolbar>

        <Hidden mdUp>
          <MobileMenu open={open} setOpen={setOpen} />
        </Hidden>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
