export default function removeUnderscore(value) {
  let data = value;
  data = data.replace(/_/g, '');

  while (data.length && data[data.length - 1] === '-') {
    data = data.slice(0, -1);
  }

  return data;
}
