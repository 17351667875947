import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Link } from '@material-ui/core';
import { useToggle } from 'src/hooks';
import { CustomSwitch } from 'src/components';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  homeBtn: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(3),
    transition: 'opacity 0.2s ease-in'
  },
  darkModeBtn: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(3),
    transition: 'opacity 0.2s ease-in'
  },
  bgWhite: {
    backgroundColor: '#fff'
  },
  bgDark: {
    backgroundColor: '#292c35'
  },
  hidden: {
    opacity: 0
  }
}));

let timeOutId = null;

const Header = () => {
  const classNames = useStyles();

  const [isOnDarkMode, setOnDarkMode] = useState();
  const [dmToggleShown, showDmToggle, hideDmToggle] = useToggle();

  function showDarkModeToggle() {
    if (!dmToggleShown) showDmToggle();

    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      hideDmToggle();
    }, 3000);
  }

  useEffect(() => {
    showDarkModeToggle();
  }, []);

  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      className={isOnDarkMode ? classNames.bgDark : classNames.bgWhite}
      onMouseMove={showDarkModeToggle}
      style={{ cursor: dmToggleShown ? 'default' : 'none' }}
    >
      <Link
        component={RouterLink}
        to={'/'}
        size="small"
        color="primary"
        disableRipple
        className={clsx(
          classNames.homeBtn,
          dmToggleShown ? '' : classNames.hidden
        )}
      >
        <FontAwesomeIcon icon={faHouse} size="lg" />
      </Link>
      <CustomSwitch
        className={clsx(
          classNames.darkModeBtn,
          dmToggleShown ? '' : classNames.hidden
        )}
        checked={isOnDarkMode}
        onChange={e => setOnDarkMode(e.target.checked)}
      />
      <img
        alt="Radztech Business Solutions"
        src={
          isOnDarkMode
            ? '/static/rbs-dark.png'
            : '/static/radztech_business_solutions.png'
        }
        width="50%"
      />
    </Box>
  );
};

export default Header;
