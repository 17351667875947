import React from 'react';
import { Page } from 'src/components';
import { Hero, Features, Overview } from 'src/pageComponents';
import Footer from 'src/views/landingPage/Footer';
import { Fab, makeStyles, Tooltip } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { ArrowBack } from '@material-ui/icons';
import { usePages } from 'src/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  fab: {
    background: '#1976D2',
    color: '#fff',
    position: 'fixed',
    zIndex: 100,
    bottom: theme.spacing(3),
    right: theme.spacing(4),
    '&:hover': {
      background: '#1976D2',
      color: '#fff'
    }
  }
}));

/**
 * @type {pageDetails}
 */
const defaultSettings = {
  features: {
    items: [
      {
        id: 1,
        img: '/static/beyond-accounting/portal/portal_support.png',
        staticImg: true,
        list: [
          {
            style: 'arrow',
            text: 'UlapBiz Support'
          },
          {
            style: 'arrow',
            text: 'Payroll Support'
          }
        ],
        particle: 'particle1',
        primaryText: 'Portal Support',
        secondaryText:
          'Get the assistance you need with our Portal Support. Quick, reliable, and dedicated to ensuring your seamless navigation and optimal experience within our digital workspace. Your success is our priority.'
      },
      {
        id: 2,
        img: '/static/beyond-accounting/portal/portal_transactions.png',
        staticImg: true,
        list: [
          {
            style: 'arrow',
            text: 'Leave Application'
          },
          {
            style: 'arrow',
            text: 'Overtime Application'
          },
          {
            style: 'arrow',
            text: 'Official Business'
          }
        ],
        particle: 'particle2',
        primaryText: 'Payroll Transactions',
        secondaryText:
          'Unlock efficiency and convenience with our Employee Portal. Your go-to hub for streamlined communication, essential resources, and personalized tools, empowering you to thrive in the heart of our digital workplace. Your journey, simplified.'
      },
      {
        id: 3,
        img: '/static/beyond-accounting/portal/portal_payslip.png',
        staticImg: true,
        list: [],
        primaryText: 'Payroll Payslip',
        secondaryText:
          'Access Statement: Easily view your payslip anytime, anywhere through our Employee Portal. Swift, secure, and designed for your convenience, accessing your financial information has never been more seamless. Empowering you with instant insight into your earnings.'
      },
      {
        id: 4,
        img: '/static/beyond-accounting/portal/portal_timelogs.png',
        staticImg: true,
        list: [
          {
            style: 'arrow',
            text: 'Late/Undertime'
          },
          {
            style: 'arrow',
            text: 'Absences'
          },
          {
            style: 'arrow',
            text: 'Overtime'
          },
          {
            style: 'arrow',
            text: 'Night Shift Differential'
          },
          {
            style: 'arrow',
            text: 'Holidays'
          }
        ],
        particle: 'particle1',
        primaryText: 'Payroll Time Logs',
        secondaryText:
          'Effortlessly view and update your daily time records through our Employee Portal. Streamline your workday by accessing and managing your time with ease, ensuring accuracy and convenience at your fingertips. Your time, your control.'
      }
    ],
    sectionTitle: 'Features',
    show: true
  },
  hero: {
    bg: 'bg1',
    staticImg: true,
    img: '/static/images/sub-systems/portal.png',
    primaryText: 'UlapPortals',
    secondaryText:
      'An integrated portal for interacting with customers, suppliers or employees.',
    show: true,
    showBg: true
  },
  overview: {
    items: [
      {
        id: 1,
        img: '/static/beyond-accounting/portal/icons/client_support.svg',
        staticImg: true,
        text: 'Client Support'
      },
      {
        id: 2,
        img: '/static/beyond-accounting/portal/icons/transactions.svg',
        staticImg: true,
        text: 'HRIS Transactions'
      },
      {
        id: 3,
        img: '/static/beyond-accounting/portal/icons/payslip.svg',
        staticImg: true,
        text: 'Payslip Viewing'
      },
      {
        id: 4,
        img: '/static/beyond-accounting/portal/icons/time_logs.svg',
        staticImg: true,
        text: 'Payroll Time Logs Viewing'
      },
      {
        id: 5,
        img: '/static/beyond-accounting/portal/icons/leave_credits.svg',
        staticImg: true,
        text: 'Leave Credits Viewing'
      }
    ],
    primaryText: 'Overview',
    secondaryText:
      'Our HRIS and Support Portal is your central hub for streamlined HR processes and comprehensive support. Access HRIS tools effortlessly, and find dedicated support resources, all in one place. Experience a workplace where efficiency meets support for a seamless employee journey.',
    show: true
  }
};

const Portal = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const { ixPage, pageDetails } = usePages('portal', {
    jsonSettings: defaultSettings
  });

  return (
    <Page title="UlapPortals" style={{ backgroundColor: '#FFF' }}>
      {Boolean(pageDetails?.hero?.show) && (
        <Hero pageDetails={pageDetails.hero} ixPage={ixPage} />
      )}
      {Boolean(pageDetails?.overview?.show) && (
        <Overview pageDetails={pageDetails.overview} ixPage={ixPage} />
      )}
      {Boolean(pageDetails?.features?.show) && (
        <Features pageDetails={pageDetails.features} ixPage={ixPage} />
      )}
      <Footer />
      <Tooltip title="Go Back">
        <Fab
          aria-label="back"
          color="secondary"
          className={classes.fab}
          onClick={() => navigate('/accounting-and-beyond')}
        >
          <ArrowBack />
        </Fab>
      </Tooltip>
    </Page>
  );
};

export default Portal;
