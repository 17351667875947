import getFile from 'src/helpers/getFile';
import { useSelector } from 'react-redux';

function usePrint() {
  const { base_url, userToken } = useSelector(({ auth }) => auth);

  async function print({ url, data }) {
    const res = await getFile({
      url: `${base_url}/${url}`,
      userToken,
      fileType: 'application/pdf',
      method: 'post',
      params: {
        data
      }
    });

    // Create iframe
    let iframe = document.createElement('iframe');
    document.body.appendChild(iframe); // append iframe in doc body
    iframe.style.display = 'none'; // dont display the iframe
    iframe.onload = function() {
      // before loading print the iframe
      setTimeout(function() {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
    iframe.src = res;
  }

  return { print };
}

export default usePrint;
