import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isLoading: false,
  currentTab: '',
  meta: [],
  usersWithPriv: [],
  users: [],
  portals: [],
  dashboards: [],
  transactions: [],
  systemTypes: [],
  subAccTypes: [],
  SPEJReports: [],
  customReports: [],
  selectedUser: {},
  selectedPortal: {},
  selectedTransaction: {},
  selectedSubAccType: {},
  selectedSystemType: {},
  selectedDashboard: {},
  selectedSPEJReport: {},
  selectedCustomReport: {},
  hasChanges: false,
  notifier: null,
  userPriv: {},
  needsRefresh: false,
  userHasPriv: false,
  errorCause: ''
};

const slice = createSlice({
  name: 'privileges',
  initialState: INITIAL_STATE,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setNotifier(state, action) {
      state.notifier = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setTransactions(state, action) {
      state.transactions = action.payload;
    },
    setSystemTypes(state, action) {
      state.systemTypes = action.payload;
    },
    setSubAccTypes(state, action) {
      state.subAccTypes = action.payload;
    },
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setSelectedSubAccType(state, action) {
      state.selectedSubAccType = action.payload;
    },
    setSelectedTransaction(state, action) {
      state.selectedTransaction = action.payload;
    },
    setNeedsRefresh(state, action) {
      state.needsRefresh = action.payload;
    },
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setUsersWithPrivilege(state, action) {
      state.usersWithPriv = action.payload;
    },
    setUserHasPrivilege(state, action) {
      state.userHasPriv = action.payload;
    },
    setUserPrivileges(state, action) {
      state.userPriv = action.payload;
    },
    setHasChanges(state, action) {
      state.hasChanges = action.payload;
    },
    setErrorCause(state, action) {
      state.errorCause = action.payload;
    },
    setSpejReports(state, action) {
      state.SPEJReports = action.payload;
    },
    setSelectedSpejReport(state, action) {
      state.selectedSPEJReport = action.payload;
    },
    setCustomReports(state, action) {
      state.customReports = action.payload;
    },
    setSelectedSystemType(state, action) {
      state.selectedSystemType = action.payload;
    },
    setSelectedCustomReport(state, action) {
      state.selectedCustomReport = action.payload;
    },
    setPortals(state, action) {
      state.portals = action.payload;
    },
    setSelectedPortal(state, action) {
      state.selectedPortal = action.payload;
    },
    setDashboards(state, action) {
      state.dashboards = action.payload;
    },
    setSelectedDashboard(state, action) {
      state.selectedDashboard = action.payload;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const privilegesReducer = slice.reducer;

export const {
  setIsLoading,
  setNotifier,
  setUsers,
  setTransactions,
  setSubAccTypes,
  setSelectedUser,
  setCurrentTab,
  setSelectedSubAccType,
  setSelectedTransaction,
  setNeedsRefresh,
  setMeta,
  setUsersWithPrivilege,
  setUserHasPrivilege,
  setUserPrivileges,
  setHasChanges,
  setErrorCause,
  setSpejReports,
  setSelectedSpejReport,
  setCustomReports,
  setSelectedCustomReport,
  setSystemTypes,
  setSelectedSystemType,
  clear,
  restore,
  setPortals,
  setSelectedPortal,
  setDashboards,
  setSelectedDashboard
} = slice.actions;
export default privilegesReducer;
