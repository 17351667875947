import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from '../shadows';

export default {
  name: THEMES.PURPLE,
  light: {
    palette: {
      type: 'light',
      background: {
        default: '#FAFAFA',
        paper: '#FFFFFF',
        navbar: '#68507b',
        dark: '#f4f6f8'
      },
      primary: {
        main: '#68507b',
        contrastText: 'rgba(255,255,255,0.87)'
      },
      secondary: {
        main: '#68507b',
        contrastText: 'rgba(255,255,255,0.87)'
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(0, 0, 0, 0.38)'
      }
    },
    overrides: {
      MuiAppBar: {},
      MuiButton: {},
      MuiListItem: {},
      MuiLinearProgress: {}
    },
    shadows: softShadows
  },
  dark: {
    palette: {
      type: 'dark',
      background: {
        default: '#303030',
        navbar: '#68507b',
        paper: '#424242',
        dark: '#303030'
      },
      primary: {
        main: '#a395b0',
        contrastText: 'rgba(255,255,255,0.87)'
      },
      secondary: {
        main: '#a395b0',
        contrastText: 'rgba(255,255,255,0.87)'
      },
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(255, 255, 255, 0.5)'
      }
    },
    overrides: {
      MuiAppBar: {},
      MuiButton: {},
      MuiListItem: {},
      MuiLinearProgress: {}
    },
    shadows: strongShadows
  }
};
