import WelcomePortal from 'src/views/portal/guest/Welcome';
import PortalLogin from 'src/views/auth/PortalLogin';
import ResetPortalPassword from 'src/views/auth/ResetPortalPassword';
import PortalNavigator from 'src/views/auth/PortalNavigator';
import PortalLoginLayout from 'src/layouts/PortalLoginLayout';

const portalLoginRoutes = [
  {
    path: 'portal',
    element: PortalLoginLayout,
    children: [
      {
        path: '',
        element: PortalNavigator
      },
      {
        path: ':cdBiz/:cdPortal',
        element: WelcomePortal
      },
      {
        path: ':cdBiz/:cdPortal/login',
        element: PortalLogin
      },
      {
        path: ':cdBiz/:cdPortal/reset-password',
        element: ResetPortalPassword
      },
      {
        path: ':cdBiz/:cdPortal/reset-password/:username',
        element: ResetPortalPassword
      },
      {
        path: '*',
        element: PortalNavigator
      }
    ]
  }
];

export default portalLoginRoutes;
