import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Avatar,
  AppBar,
  Toolbar,
  makeStyles,
  Link,
  Box,
  Container,
  Typography,
  Button,
  Hidden,
  IconButton
} from '@material-ui/core';
import MobileMenu from './MobileMenu';
import { logout } from 'src/redux/slices/auth';
import useRequest from 'src/hooks/useRequest';
import useNotif from 'src/hooks/useNotif';
import { useDispatch, useSelector } from 'react-redux';
import { useEnv } from 'src/hooks';
import {
  BusinessOutlined,
  Contacts,
  RecentActors,
  SupervisedUserCircle
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {},
  toolbar: {
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1)
  },
  appTitle: {
    paddingLeft: 8
  },
  link: {
    color: '#fff'
  },
  avatar: props => props?.logo_settings || {}
}));

const TopBar = ({ className, ...rest }) => {
  const { CONFIG } = useEnv();

  const classes = useStyles({ logo_settings: CONFIG.logo_settings });
  const [open, setOpen] = useState(false);
  const { current_user } = useSelector(({ auth }) => auth);
  const notify = useNotif();
  const request = useRequest();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    const req = await request.post('/logout');
    dispatch(logout());
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Container maxWidth="lg">
        <Toolbar disableGutters className={classes.toolbar}>
          <RouterLink to="/">
            <Avatar src={CONFIG.logo_url.small} className={classes.avatar} />
          </RouterLink>
          <Typography className={classes.appTitle} align="center" variant="h3">
            {CONFIG.name.first_word}
            <b>{CONFIG.name.second_word}</b>
          </Typography>

          <Box flexGrow={1} />
          <Hidden smDown>
            {current_user?.ru === 1 && (
              <Button
                component={RouterLink}
                to="/app/registry/admin/biz"
                endIcon={<SupervisedUserCircle />}
                color="inherit"
              >
                Admin Registry
              </Button>
            )}

            {current_user?.ru === 1 && (
              <Button
                component={RouterLink}
                to="/app/agency/admin"
                endIcon={<Contacts />}
                color="inherit"
              >
                Agency
              </Button>
            )}

            <Button
              component={RouterLink}
              to="/"
              endIcon={<BusinessOutlined />}
              color="inherit"
            >
              Select Biz
            </Button>

            <Link
              className={classes.link}
              component={Button}
              onClick={handleLogout}
              variant="body1"
              endIcon={<InputIcon />}
              underline="none"
            >
              Logout
            </Link>
          </Hidden>
          <Hidden smUp>
            <IconButton onClick={() => setOpen(prev => !prev)} color="inherit">
              {!open ? <MenuIcon /> : <ClearIcon />}
            </IconButton>
          </Hidden>
        </Toolbar>
      </Container>
      <Hidden smUp>
        <MobileMenu open={open} setOpen={setOpen} logout={handleLogout} />
      </Hidden>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
