import React, { useEffect, useState } from 'react';
import { DataGrid, GridRowParams, GridColumns } from '@material-ui/data-grid';
import {
  LinearProgress,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  useTheme,
  TextField,
  DialogTitle,
  MenuItem,
  FormControlLabel,
  Switch,
  Chip,
  Typography
} from '@material-ui/core';
import { useNotif, useRequest, useToggle } from 'src/hooks';
import DebounceTextField from '../DebounceTextField';
import SrchAcc from '../SrchAcc';
import { v4 as generateUUID } from 'uuid';
import numeral from 'numeral';
import CustomNumberFormat from '../CustomNumberFormat';
import SingleDatePicker from '../SingleDatePicker';
import moment from 'moment';

const Loading = () => <LinearProgress style={{ height: 2 }} />;

/**
 * @typedef {Object} limit
 * @property {string} id
 * @property {string} description
 * @property {number} ixAcc
 * @property {string} sAcc
 * @property {Array<{ixJCd: number, JCd: string}>} transTypes
 * @property {string} limitType
 * @property {boolean} limitByValue
 * @property {boolean} limitByInvoice
 * @property {boolean} allowTerms
 * @property {number} value
 * @property {number} overrideValue
 * @property {string} validUntil
 */

const limitDefault = {
  id: '',
  description: '',
  ixAcc: 0,
  sAcc: '',
  transTypes: [],
  limitType: '',
  limitByValue: false,
  limitByInvoice: false,
  allowTerms: false,
  value: 0,
  overrideValue: 0,
  validUntil: ''
};

const Limits = ({ data, onChangeField, ...props }) => {
  const theme = useTheme();
  const notify = useNotif();

  /**
   * @type {Array<limit>}
   */
  const limitItems = data?.value?.items || [];

  const [addOpen, openAddDialog, closeAddDialog] = useToggle();
  const [isLoading, loadingOn, loadingOff] = useToggle();

  /**
   * @type {state<limit>}
   */
  const [limitForm, setLimitForm] = useState(limitDefault);
  const [limitFormError, setLimitFormError] = useState({
    description: '',
    acc: '',
    transTypes: '',
    limitType: '',
    value: '',
    overrideValue: '',
    validUntil: ''
  });

  const [transTypes, setTransTypes] = useState([]);

  const JcdMap = transTypes.reduce((accumulator, { ixJCd, JCd }) => {
    accumulator[ixJCd] = JCd;
    return accumulator;
  }, {});

  const request = useRequest(loadingOn, loadingOff);

  /**
   * @type {GridColumns}
   */
  const columns = [
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      width: 170
    },
    {
      field: 'sAcc',
      headerName: 'Account',
      sortable: false,
      width: 200
    },
    {
      field: 'transTypes',
      headerName: 'Transaction Types',
      sortable: false,
      width: 170,
      renderCell: ({ value }) =>
        value.map(({ JCd }) => (
          <Chip key={JCd} size="small" color="primary" label={JCd} />
        ))
    },
    // {
    //   field: 'limitType',
    //   headerName: 'Limit Type',
    //   sortable: false,
    //   width: 170
    // },
    {
      field: 'limitByValue',
      headerName: 'By Value',
      sortable: false,
      width: 100,
      align: 'center',
      renderCell: ({ value }) => <Switch size="small" checked={value} />
    },
    {
      field: 'limitByInvoice',
      headerName: 'By Invoice',
      sortable: false,
      width: 100,
      align: 'center',
      renderCell: ({ value }) => <Switch size="small" checked={value} />
    },
    {
      field: 'allowTerms',
      headerName: 'Allow Terms',
      sortable: false,
      width: 120,
      align: 'center',
      renderCell: ({ value }) => <Switch size="small" checked={value} />
    },
    {
      field: 'value',
      headerName: 'Value',
      sortable: false,
      width: 120,
      align: 'center',
      renderCell: ({ value }) => numeral(value).format('0,0.00')
    },
    {
      field: 'overrideValue',
      headerName: 'Override Value',
      sortable: false,
      width: 120,
      align: 'center',
      renderCell: ({ value }) => numeral(value).format('0,0.00')
    },
    {
      field: 'validUntil',
      headerName: 'Valid Until',
      sortable: false,
      flex: 1,
      minWidth: 100,
      renderCell: ({ value }) =>
        value ? moment(value).format('MM-DD-YYYY') : 'N/A'
    }
  ];

  function onTransTypeChange(e) {
    const value = e.target.value;

    setLimitForm(state => ({
      ...state,
      transTypes: value.map(ixJCd => ({
        ixJCd,
        JCd: JcdMap[ixJCd]
      }))
    }));
  }

  /**
   *
   * @param {GridRowParams} params
   */
  function onRowClick(params) {
    const id = params.id;

    const limit = limitItems.find(item => item.id === id);

    if (limit) {
      setLimitForm(limit);
      openAddDialog();
    }
  }

  function updateLimit(key, value) {
    setLimitForm();
  }

  function validateLimit() {
    let hasError = false;

    const errors = {
      description: '',
      acc: '',
      transTypes: '',
      limitType: '',
      value: '',
      overrideValue: '',
      validUntil: ''
    };

    if (limitForm.value >= limitForm.overrideValue) {
      hasError = true;
      errors.value = 'Value must be lower than override value.';
    }

    if (limitForm.overrideValue <= limitForm.value) {
      hasError = true;
      errors.overrideValue = 'Override value must be greater than value.';
    }

    if (limitForm.transTypes.length === 0) {
      hasError = true;
      errors.transTypes = 'No transaction selected.';
    }

    if (!limitForm.description) {
      hasError = true;
      errors.description = 'Description is required.';
    }

    if (!limitForm.ixAcc) {
      hasError = true;
      errors.acc = 'No account selected';
    }

    setLimitFormError(errors);

    return hasError;
  }

  function saveLimit() {
    const newItems = [...limitItems];
    let index = -1;

    if (limitForm.id)
      index = newItems.findIndex(item => item.id === limitForm.id);

    if (index !== -1) newItems[index] = limitForm;
    else newItems.push({ ...limitForm, id: generateUUID() });

    if (validateLimit()) return notify.error('Validation failed.');

    onChangeField({
      target: {
        name: data?.fld || '',
        value: { ...(data?.value || {}), items: newItems }
      }
    });
    closeAddDialog();
  }

  async function getTransTypes(cancelCb) {
    const res = await request.get('/setup/jcd', {}, cancelCb);

    if (res.success) setTransTypes(res.data);
  }

  useEffect(() => {
    let cancel = () => {};
    getTransTypes(c => (cancel = c));
    return cancel;
  }, []);

  return (
    <Box>
      <Dialog fullWidth maxWidth="sm" open={addOpen} onClose={closeAddDialog}>
        <DialogTitle disableTypography>
          <Typography variant="h4">
            {limitForm.id ? 'Update Limit' : 'Add Limit'}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(2),
            padding: theme.spacing(2)
          }}
        >
          <DebounceTextField
            fullWidth
            variant="outlined"
            label="Description"
            value={limitForm.description}
            onChange={e =>
              setLimitForm(state => ({
                ...state,
                description: e.target.value
              }))
            }
            error={Boolean(limitFormError.description)}
            helperText={limitFormError.description}
          />
          <SrchAcc
            selectedAcc={{
              ixAcc: limitForm.ixAcc,
              title: limitForm.sAcc
            }}
            onSelectAccount={acc => {
              setLimitForm(state => ({
                ...state,
                ixAcc: acc.ixAcc,
                sAcc: acc.sAccTitle
              }));
            }}
            error={Boolean(limitFormError.acc)}
            errorMessage={limitFormError.acc}
          />
          <TextField
            fullWidth
            select
            multiple
            SelectProps={{
              multiple: true
            }}
            variant="outlined"
            label="Transaction Type"
            value={limitForm.transTypes.map(item => item.ixJCd)}
            onChange={onTransTypeChange}
            error={Boolean(limitFormError.transTypes)}
            helperText={limitFormError.transTypes}
          >
            {transTypes.map(({ ixJCd, sJCd }) => (
              <MenuItem key={ixJCd} value={ixJCd}>
                {sJCd}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel
            control={
              <Switch
                checked={limitForm.limitByValue}
                onChange={e =>
                  setLimitForm(state => ({
                    ...state,
                    limitByValue: e.target.checked
                  }))
                }
                size="small"
              />
            }
            label="Limit By Outstanding Value"
          />
          <FormControlLabel
            control={
              <Switch
                checked={limitForm.limitByInvoice}
                onChange={e =>
                  setLimitForm(state => ({
                    ...state,
                    limitByInvoice: e.target.checked
                  }))
                }
                size="small"
              />
            }
            label="Limit By Outstanding Invoice"
          />
          <FormControlLabel
            control={
              <Switch
                checked={limitForm.allowTerms}
                onChange={e =>
                  setLimitForm(state => ({
                    ...state,
                    allowTerms: e.target.checked
                  }))
                }
                size="small"
              />
            }
            label="Allow Terms"
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Value"
            InputProps={{
              inputComponent: CustomNumberFormat,
              inputProps: {
                thousandSeparator: true,
                decimalScale: 2,
                fixedDecimalScale: true,
                style: { textAlign: 'right' }
              }
            }}
            value={limitForm.value}
            onChange={e =>
              setLimitForm(state => ({
                ...state,
                value: parseFloat(e.target.value || '0')
              }))
            }
            error={Boolean(limitFormError.value)}
            helperText={limitFormError.overrideValue}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Override Value"
            InputProps={{
              inputComponent: CustomNumberFormat,
              inputProps: {
                thousandSeparator: true,
                decimalScale: 2,
                fixedDecimalScale: true,
                style: { textAlign: 'right' }
              }
            }}
            value={limitForm.overrideValue}
            onChange={e =>
              setLimitForm(state => ({
                ...state,
                overrideValue: parseFloat(e.target.value || '0')
              }))
            }
            error={Boolean(limitFormError.overrideValue)}
            helperText={limitFormError.overrideValue}
          />
          <SingleDatePicker
            label="Valid Until"
            value={limitForm.validUntil}
            onChange={e =>
              setLimitForm(state => ({
                ...state,
                validUntil: e.target.value
              }))
            }
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button color="primary" onClick={closeAddDialog}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={saveLimit}>
            {limitForm.id ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      <DataGrid
        rows={limitItems}
        columns={columns}
        loading={isLoading}
        autoHeight
        components={{
          LoadingOverlay: Loading
        }}
        onRowClick={onRowClick}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        hideFooter
      />
      <Box mt={1} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setLimitForm(limitDefault);
            openAddDialog();
          }}
        >
          Add Item
        </Button>
      </Box>
    </Box>
  );
};

export default Limits;
