// export data generate row
const generateRow = ({ columns = [], rowData = [] }) => {
  let row = {};

  columns.forEach(({ key }, i) => {
    if (!rowData[i]) {
      row = { ...row, [key]: '' };
      return;
    }

    row = {
      ...row,

      [key]: rowData[i]
    };
  });

  return row;
};

export default generateRow;

export const generateRows = ({ toInsert = [], flds = {} }) => {
  return toInsert.map(items => {
    let obj = {};

    Object.entries(flds).forEach(([key, _]) => {
      obj = {
        ...obj,
        [key]: items?.[key] ?? ''
      };
    });

    return obj;
  });
};

export const generateEmptyRow = flds => {
  let obj = {};

  Object.entries(flds).forEach(([key, _]) => {
    obj = {
      ...obj,
      [key]: ''
    };
  });

  return obj;
};
