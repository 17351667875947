import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dateRange: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  ixAcc: 0,
  ixCheckStatus: 1,
  ixSort: 0,
  ixBrch: 0,
  page: 0,
  limit: 25,
  meta: {
    dateType: 'month',
    lstAcc: [],
    Sort: {},
    CheckStatus: {}
  },
  result: []
};

const slice = createSlice({
  name: 'checkRegister',
  initialState: INITIAL_STATE,
  reducers: {
    setMeta(state, action) {
      state.meta.dateType = action.payload.dateType;
      state.meta.lstAcc = action.payload.lstAcc;
      state.meta.Sort = action.payload.Sort;
      state.meta.CheckStatus = action.payload.CheckStatus;
    },
    setDateRange(state, action) {
      state.dateRange.dt1 = action.payload.dt1;
      state.dateRange.dt2 = action.payload.dt2;
      state.dateRange.textValue = action.payload.textValue;
    },
    setAccount(state, action) {
      state.ixAcc = action.payload;
    },
    setCheckStatus(state, action) {
      state.ixCheckStatus = action.payload;
    },
    setSort(state, action) {
      state.ixSort = action.payload;
    },
    setBranch(state, action) {
      state.ixBrch = action.payload;
    },
    setResult(state, action) {
      state.page = 0;
      state.result = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.page = 0;
      state.limit = action.payload;
    },
    resetState() {
      return INITIAL_STATE;
    },
    clearReportData(state, action) {
      state.result = [];
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const checkRegisterReducer = slice.reducer;

export const {
  setMeta,
  setSort,
  setDateRange,
  setAccount,
  setCheckStatus,
  setBranch,
  setResult,
  resetState,
  setPage,
  setLimit,
  restore,
  clear,
  clearReportData
} = slice.actions;
export default checkRegisterReducer;
