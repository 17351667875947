import React, { useRef } from 'react';
import { Fab, makeStyles, Tooltip } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import NotableFeatures from './NotableFeatures';
import Footer from '../../Footer';
import SystemFeatures from './SystemFeatures';
import ActionButtons from './ActionButtons';
import { useNavigate, useParams } from 'react-router';
import data from '../data';
import MainHighLight from './MainHighLight';
import { __RouterContext } from 'react-router';
import Payroll from './Payroll';
import { ArrowBack } from '@material-ui/icons';
import Portal from './Portal';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  fab: {
    background: '#1976D2',
    color: '#fff',
    position: 'fixed',
    zIndex: 2,
    bottom: theme.spacing(3),
    right: theme.spacing(4),
    '&:hover': {
      background: '#1976D2',
      color: '#fff'
    }
  }
}));

const Main = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { key } = useParams();
  const subSystems = useRef();
  const {
    hero,
    notableFeatures: notable,
    systemFeatures: features,
    transactions,
    reports,
    ...rest
  } = data?.[key] || {};

  if (key === 'payroll') return <Payroll />;
  if (key === 'portal') return <Portal />;

  return (
    <Page className={classes.root} title={`Accounting and Beyond`}>
      <div ref={subSystems}>
        <Hero data={hero} />
      </div>
      {rest?.showMainHighlight && (
        <MainHighLight
          title={rest?.mainHighlight?.title ?? 'Main Highlight'}
          image={rest?.mainHighlight?.image}
          hideImage={rest?.mainHighlight?.hideImage || false}
        />
      )}
      <NotableFeatures data={notable} />
      <SystemFeatures
        features={features}
        transactions={transactions}
        reports={reports}
      />
      <Tooltip title="Go Back">
        <Fab
          aria-label="back"
          color="secondary"
          className={classes.fab}
          onClick={() => navigate('/accounting-and-beyond')}
        >
          <ArrowBack />
        </Fab>
      </Tooltip>
      <ActionButtons />
      <Footer />
    </Page>
  );
};

export default Main;
