import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import useRequest from 'src/hooks/useRequest';
import { setAtc } from 'src/redux/slices/storedValues';

const useATCList = () => {
  const dispatch = useDispatch();
  const request = useRequest();

  const { atc } = useSelector(state => state.storedValues);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  async function getAtc() {
    setLoading(true);
    const res = await request.get('/globals/tax/atc');

    if (res.success) {
      setHasError(false);
      dispatch(setAtc(res.data));
    } else setHasError(true);
    setLoading(false);
  }

  useEffect(() => {
    if (!isEmpty(atc)) return;
    getAtc();
  }, []);

  return { loading, hasError, atc, refresh: getAtc };
};

export default useATCList;
