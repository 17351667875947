import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const AntTabs = withStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.primary.main
  }
}))(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: 0,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    '& .MuiTab-wrapper': {
      display: 'flex',
      flexDirection: 'row',
      '& > *:first-child': {
        marginBottom: 0,
        marginRight: '6px'
      }
    }
  }
}))(props => <Tab disableRipple {...props} />);

/**
 *
 * @param {Object} param0
 * @param {Array<{id: string | number, title: string, icon: React.JSX.Element | null}>} param0.tabs
 * @param {number} param0.activeTab
 * @param {(event: React.ChangeEvent<{}>, value: any) => void} param0.onChange
 * @returns
 */
const StyledTab = ({
  tabs,
  activeTab,
  onChange,
  className,
  children,
  ...props
}) => {
  return (
    <AntTabs
      variant="scrollable"
      scrollButtons="auto"
      value={activeTab}
      onChange={onChange}
      className={className}
      {...props}
    >
      {tabs.map(({ id, title, icon = null }) => (
        <AntTab key={id} label={title} icon={icon} />
      ))}
      {children}
    </AntTabs>
  );
};

export default StyledTab;
