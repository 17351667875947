import React, { CSSProperties } from 'react';
import {
  makeStyles,
  Card,
  CardContent,
  Box,
  Typography
} from '@material-ui/core';
import numeral from 'numeral';
import { isInteger, isString } from 'lodash';
import { Skeleton } from '@material-ui/lab';

const useCardStyles = makeStyles(theme => ({
  card: {
    transition: 'all 200ms ease-in-out',
    height: '100%',
    '&:hover': {
      transform: 'translateY(-.5em)',
      boxShadow: '0 4px 8px rgba(0,0,0,0.3)'
    }
  },
  cardContent: {
    position: 'relative',
    minHeight: 140,
    height: '100%'
  },
  cardValue: {
    fontSize: '3rem',
    marginTop: '.4em',
    fontWeight: 700
  }
}));

/**
 *
 * @param {Object} props
 * @param {Boolean} props.loading
 * @param {String} props.title
 * @param {Number | String} props.value
 * @param {String} props.backgroundColor
 * @param {String} props.unit
 * @param {Boolean} props.disableUnit
 * @param {CSSProperties} props.unitStyles
 * @returns
 */
const ValueCard = ({
  loading = false,
  title,
  value,
  backgroundColor,
  unit = '',
  disableUnit = false,
  unitStyles = { fontWeight: 400, fontSize: '1rem' }
}) => {
  const classes = useCardStyles();

  const formattedValue = value
    ? isString(value)
      ? value
      : numeral(value).format(isInteger(value) ? '0,0' : '0,0.00')
    : '0';

  return (
    <Card
      className={classes.card}
      style={{
        backgroundColor
      }}
    >
      <CardContent className={classes.cardContent}>
        <Box
          minHeight={60}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography variant="h5">{title}</Typography>
          <Box display="flex" justifyContent="flex-end">
            {loading ? (
              <Skeleton variant="rect" height={56} width={200} />
            ) : (
              <Typography
                variant="h1"
                className={classes.cardValue}
                align="right"
              >
                {formattedValue}
                {!disableUnit && (
                  <span style={{ ...unitStyles, marginLeft: 8 }}>{unit}</span>
                )}
              </Typography>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ValueCard;
