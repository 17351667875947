import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useNavigate } from 'react-router';

const ActivationSuccess = () => {
  const navigate = useNavigate();

  const handleOK = () => {
    navigate('/app/login');
  };

  return (
    <Box my={2}>
      <Box mb={2}>
        <Typography align="center">
          <CheckCircleOutlineIcon
            style={{ fontSize: '5em', color: green[500] }}
          />
        </Typography>
      </Box>
      <Typography variant="h1" align="center" style={{ color: green[500] }}>
        Activation Successful
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary">
        You may now sign in to your account
      </Typography>

      <Box mt={4} display="flex" justifyContent="center">
        <Button color="primary" variant="contained" onClick={handleOK}>
          Sign In
        </Button>
      </Box>
    </Box>
  );
};

export default ActivationSuccess;
