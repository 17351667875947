import { useRef } from 'react';
import useNotif from './useNotif';

const isSaveKey = key => key === 's' || key === 'S';

const useTextEditorSpecialFunctions = (props = {}) => {
  const ref = useRef(null);
  const notify = useNotif();

  const isMac = navigator.userAgent.indexOf('Mac OS X') != -1;

  const {
    save = () => {},
    error = { status: false },
    beautifyJson = () => {}
  } = props;

  const getLineIndexes = (val = '', currentIndex = 0) => {
    const regex = /\n/; // new lines regex
    let endIndex = 0,
      startIndex = 0;

    // get last index
    for (let i = currentIndex; i <= val.length; i++) {
      const item = val[i];

      if (regex.test(item)) {
        endIndex = i;
        break;
      }

      endIndex = i;
    }

    //get first index
    for (let i = currentIndex - 1; i >= 0; i--) {
      const item = val[i];

      if (regex.test(item)) {
        startIndex = i;
        break;
      }
      startIndex = i;
    }

    return [startIndex, endIndex];
  };

  const onKeyDown = e => {
    const selectionStart = ref.current.selectionStart;
    const selectionEnd = ref.current.selectionEnd;
    const val = ref.current.value;

    if (e.shiftKey) {
      switch (e.keyCode) {
        case 9: // Remove Indent (Shift + Tab)
          e.preventDefault();

          if (val === '') return;

          if (val[selectionStart - 1] === '	') {
            ref.current.setRangeText(
              '',
              selectionStart - 1,
              selectionEnd,
              'start'
            );
          }
          return;
      }
    }

    if (e.altKey) {
      if (e.keyCode === 66) beautifyJson();
    }

    if (e.ctrlKey) {
      switch (e.keyCode) {
        case 75: {
          // Remove Indent (Shift + Tab)
          e.preventDefault();
          const [start, end] = getLineIndexes(val, selectionStart);
          ref.current.setRangeText('', start, end, 'start');
          return;
        }
        case 76: {
          // Remove Line Crtl + k
          e.preventDefault();
          const [start, end] = getLineIndexes(val, selectionStart);
          ref.current.setSelectionRange(start, end);
          return;
        }
      }
    }

    const macShortcutValid = isMac && e.metaKey && isSaveKey(e.key);
    const otherDevShortcutValid = !isMac && e.ctrlKey && isSaveKey(e.key);

    if (macShortcutValid || otherDevShortcutValid) {
      e.preventDefault();

      if (error.status)
        return notify.info("Can't save. Please fix the errors of your JSON.");

      save();
    }

    // Indent (Tab)
    if (e.keyCode === 9) {
      e.preventDefault();
      ref.current.setRangeText('\t', selectionStart, selectionEnd, 'end');
      return;
    }
  };

  return { ref, onKeyDown };
};

export default useTextEditorSpecialFunctions;
