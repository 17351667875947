export const getInitials = string => {
  if (!string[0]) return '';

  return string?.[0].toUpperCase();
};

export const formatName = (string = '') => {
  if (!string) return '';

  if (string.length === 0) return '';

  string = string.trim();

  if (string.length === 1) return string.charAt(0).toUpperCase();

  return string
    .split(' ')
    .map(item => item.charAt(0).toUpperCase() + item.substring(1).toLowerCase())
    .join(' ');
};

const getTitleFormat = ({
  nameProps = {
    fname: '',
    mname: '',
    lname: '',
    sname: '',
    sSub: ''
  },
  acc_title = 2
}) => {
  const fname = formatName(nameProps.fname);
  const mname = formatName(nameProps.mname);
  const lname = formatName(nameProps.lname);
  const sname = formatName(nameProps.sname);

  if (acc_title === 0) {
    let title = lname + ', ' + fname;

    if (sname) title += ' ' + sname;
    if (mname) title += ' ' + getInitials(mname) + '.';

    return title;
  }

  if (acc_title === 3) {
    if (!mname && !sname) return fname + ' ' + lname;

    let title = mname
      ? fname + ' ' + getInitials(mname) + '. ' + lname
      : fname + ' ' + lname;

    if (sname) title += ' ' + sname;

    return title;
  }

  if (acc_title === 4) {
    let title = getInitials(fname) + ', ' + getInitials(lname);

    if (mname) title += ' ' + getInitials(mname);
    if (sname) title += ' ' + sname;

    return title;
  }

  return nameProps?.sSub;
};

export default getTitleFormat;
