import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, Box } from '@material-ui/core';
import axios from 'axios';

export const ImgWithToken = ({ url, Component, ...rest }) => {
  if (!rest.file) return null;

  return <Component image={rest.file} {...rest} />;
};

export const getSourceImg = async ({ url, userToken, base_url }) => {
  try {
    let jwtHeader = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': userToken
      }
    };
    let r = await axios.get(url, {
      ...jwtHeader,
      responseType: 'arraybuffer'
    });
    let d = Buffer.from(r.data).toString('base64');

    return `data:image/png;base64, ${d}`;
  } catch (error) {
    return null;
  }
};
