import React from 'react';
import HeroTl1 from './HeroTl1';
import HeroTl2 from './HeroTl2';

/**
 *
 * @param {object} param
 * @param {string} param.ixPage
 * @param {pageHero} param.pageDetails
 * @returns
 */
const Hero = ({ pageDetails, ixPage }) => {
  if (pageDetails?.template === 'template2')
    return <HeroTl2 pageDetails={pageDetails} ixPage={ixPage} />;

  return <HeroTl1 pageDetails={pageDetails} ixPage={ixPage} />;
};

export default React.memo(Hero);
