import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ContactNumberTextField from 'src/components/ContactNumberTextField';
import { FormViewError } from 'src/components/FormViewError';

const phonePattern = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const fieldValidation = {
  username: Yup.string()
    .max(125)
    .required('Username is required'),
  email: Yup.string()
    .email('Invalid email')
    .max(125)
    .required('Email is required'),
  phone: Yup.string()
    .matches(phonePattern, 'Phone Number is required')
    .required('Phone Number is required')
};

const Request = ({
  loading,
  sendRequest,
  onChangeValueType,
  value,
  valueType,
  requestFailedResult
}) => {
  if (valueType === '') {
    return (
      <Box display="flex" flexDirection="column" style={{ gap: '1em' }}>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => {
            onChangeValueType('username');
          }}
        >
          Username
        </Button>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => {
            onChangeValueType('phone');
          }}
        >
          Phone number
        </Button>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => {
            onChangeValueType('email');
          }}
        >
          Email
        </Button>
      </Box>
    );
  }

  return (
    <>
      {requestFailedResult && (
        <FormViewError
          title={
            requestFailedResult?.msg ??
            'Something went wrong. Please try again.'
          }
          errorList={requestFailedResult?.errors ?? []}
        />
      )}

      <Formik
        initialValues={{
          [valueType]: value
        }}
        validationSchema={Yup.object().shape({
          [valueType]: fieldValidation[valueType]
        })}
        onSubmit={(values, action) => {
          const val = values[valueType];

          sendRequest({
            type: valueType,
            value: val
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            {valueType === 'username' && (
              <TextField
                variant="outlined"
                value={values[valueType]}
                margin="normal"
                fullWidth
                placeholder="Enter your username"
                required
                name={valueType}
                onChange={handleChange}
                onBlur={handleBlur}
                type="input"
                error={Boolean(touched[valueType] && errors[valueType])}
                helperText={touched[valueType] && errors[valueType]}
                label="Username"
              />
            )}
            {valueType === 'phone' && (
              <ContactNumberTextField
                variant="outlined"
                value={values[valueType]}
                margin="normal"
                fullWidth
                required
                name={valueType}
                onChange={handleChange}
                onBlur={handleBlur}
                type="input"
                error={Boolean(touched[valueType] && errors[valueType])}
                helperText={touched[valueType] && errors[valueType]}
                label="Phone Number"
              />
            )}
            {valueType === 'email' && (
              <TextField
                type="email"
                variant="outlined"
                value={values[valueType]}
                margin="normal"
                fullWidth
                placeholder="Enter your email address"
                required
                name={valueType}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched[valueType] && errors[valueType])}
                helperText={touched[valueType] && errors[valueType]}
                label="Email Address"
              />
            )}

            <Box
              mt={2}
              display="flex"
              flexDirection="column"
              style={{ gap: '1em' }}
            >
              <Button
                type="submit"
                color="primary"
                fullWidth
                variant="contained"
                disabled={loading}
              >
                Reset Password
              </Button>
              <Button
                type="button"
                color="primary"
                fullWidth
                variant="text"
                onClick={() => {
                  onChangeValueType('');
                }}
              >
                Back
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Request;
