import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import getColorFromMUI from 'src/utils/getColorFromMUI';
import { useDispatch } from 'react-redux';
import getixJCd from 'src/helpers/getixJCd';
import useToggle from 'src/hooks/useToggleV2';
import Fetch from 'src/helpers/Fetch';
import { plainColors } from 'src/utils/getColorFromMUI';
import {
  setPostingChartType,
  setPostingDateRange
} from 'src/redux/slices/dashboard';

const usePostingSummary = () => {
  // IMPORTED VARIABLES & FUNCTIONS
  const { base_url, userToken, current_user } = useSelector(
    state => state.auth
  );
  const { wf } = useSelector(({ bizMeta }) => bizMeta);

  const {
    postingSummary: { dateRange, chartType }
  } = useSelector(state => state.dashboard);

  const dispatch = useDispatch();
  const [isLoading, toggleLoadingOn, toggleLoadingOff] = useToggle();
  const [
    postingDataIsLoading,
    togglePostingDataOn,
    togglePostingDataOff
  ] = useToggle();
  const [
    postingDataShown,
    postingDataShownToggleOn,
    postingDataShownToggleOff
  ] = useToggle();

  // VARIABLES
  const headers = {
    'Content-Type': 'application/json',
    'x-access-tokens': userToken
  };

  const fetch = new Fetch(base_url, headers);

  // STATES
  const [postingSummary, setPostingSummary] = useState([]);
  const [postChartData, setPostChartData] = useState({ labels: [], data: [] });
  const [postingData, setPostingData] = useState([]);
  const [summaryTitle, setSummaryTitle] = useState('');
  const [minMaxAvg, setMinMaxAvg] = useState({
    post: {
      min: 0,
      max: 0,
      avg: 0
    }
  });
  const [minMaxBG, setMinMaxBG] = useState('#FFF');
  const [selectedTrans, setSelectedTrans] = useState('');
  const summaryRef = useRef();

  // FUNCTIONS
  const scrollToSummary = () => {
    summaryRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  };

  const updateDateRange = num => {
    dispatch(setPostingDateRange(num));
  };

  const setChartType = val => {
    dispatch(setPostingChartType(val));
  };

  const generateChartDataset = data => {
    let d,
      lls = [],
      consolidated = {},
      max = 0;

    data.forEach(item => {
      if (item.ixJCd in consolidated)
        consolidated[item.ixJCd].value += item.countJCd;
      else
        consolidated[item.ixJCd] = {
          value: item.countJCd,
          label: item.sJCd,
          JCd: item.JCd
        };
    });

    const sorted = Object.entries(consolidated).sort(
      (a, b) => b[1].value - a[1].value
    );

    max = sorted.length > 3 ? 3 : sorted.length;
    d = Array.from({ length: max }, e => Array(0));

    for (let i = dateRange; i >= 0; i--) {
      const date = moment()
        .subtract(i, 'days')
        .format('YYYY-MM-DD');

      const fd = data.filter(item => item.grpPostDate === date);

      for (let i = 0; i < max; i++) {
        const item = fd.find(item => item.ixJCd === parseInt(sorted[i][0]));

        if (item) d[i].push(item.countJCd);
        else d[i].push(0);
      }

      lls.push(moment(date).format('MMM DD'));
    }

    let availableColor = [...plainColors];

    return {
      data: d.map((dataset, index) => {
        const ixJCd = getixJCd(sorted[index][1].JCd, wf);
        const wfSettings = wf?.[ixJCd]?.['wf-settings'] ?? {};

        const color = wfSettings?.color ?? '';
        const shade = wfSettings?.shade ?? 500;
        const colorToUse = color ? color : availableColor[0];

        const muiColor = getColorFromMUI({ color: colorToUse, shade });

        const colorIndex = availableColor.indexOf(colorToUse);
        if (colorIndex !== -1) availableColor.splice(colorIndex, 1);

        let t = {
          data: dataset,
          label: sorted[index][1].label,
          ixJCd: parseInt(sorted[index][0]),
          borderColor: muiColor,
          backgroundColor: muiColor
        };

        return t;
      }),
      labels: lls
    };
  };

  const formatDateTime = (dateTime, subtract, position) =>
    (position === 'start' ? dateTime.startOf('day') : dateTime.endOf('day'))
      .subtract(subtract, 'days')
      .format('YYYY-MM-DDTHH:mm:ss') + '+08:00';

  const getDateRange = () => ({
    dt1: formatDateTime(moment(), dateRange, 'start'),
    dt2: formatDateTime(moment(), 0, 'end')
  });

  const setMinMax = ixJCd => {
    const trans = postingSummary.find(item => item.ixJCd === ixJCd);

    if (trans) {
      setMinMaxAvg({
        post: {
          min: Math.round(trans.minDays * 100) / 100,
          max: Math.round(trans.maxDays * 100) / 100,
          avg: Math.round(trans.avgDays * 100) / 100
        }
      });
    }
  };

  const getPostingData = async (ixJCd, date, sJCd, count, color) => {
    setMinMax(ixJCd);
    setMinMaxBG(color);
    setSummaryTitle(
      `${sJCd} - ${moment(date).format('MMMM DD, YYYY')} (${count})`
    );
    setSelectedTrans(sJCd);
    togglePostingDataOn();
    postingDataShownToggleOn();
    scrollToSummary();

    const requestBody = {
      ixVariant: 0,
      username: current_user.username,
      ixBrch: 0,
      ixJCd: [ixJCd],
      dt1: formatDateTime(moment(date, 'YYYY-MM-DD'), 0, 'start'),
      dt2: formatDateTime(moment(date, 'YYYY-MM-DD'), 0, 'end')
    };

    const res = await fetch.post('/reports/posting-summary', requestBody);

    if (res.success) {
      setPostingData(res.data);
    } else {
      setPostingData([]);
      postingDataShownToggleOff();
    }
    togglePostingDataOff();
  };

  const getPostingSummary = async () =>
    fetch.post('/reports/posting-summary', {
      ixVariant: 10,
      username: current_user.username,
      ixBrch: 0,
      ...getDateRange()
    });

  // USE_EFFECT
  useEffect(() => {
    postingDataShownToggleOff();

    (async () => {
      toggleLoadingOn();
      const postingRes = await getPostingSummary();

      if (postingRes.success) setPostingSummary(postingRes.data);
      else setPostingSummary([]);

      if (dateRange === 0 && chartType === 'line') setChartType('bar');
      toggleLoadingOff();
    })();
  }, [dateRange]);

  useEffect(() => {
    setPostChartData(generateChartDataset(postingSummary));
  }, [postingSummary, chartType]);

  // RETURN
  return {
    dateRange,
    updateDateRange,
    postingSummary,
    postChartData,
    isLoading,
    getPostingData,
    postingData,
    postingDataIsLoading,
    postingDataShown,
    summaryRef,
    postingDataShownToggleOff,
    summaryTitle,
    chartType,
    setChartType,
    minMaxAvg,
    minMaxBG,
    selectedTrans
  };
};

export default usePostingSummary;
