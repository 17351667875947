import { useTheme } from '@material-ui/core';
import Chart from 'chart.js';
import numeral from 'numeral';

function useChartOptions() {
  const theme = useTheme();

  /**
   * @type {Chart.ChartOptions}
   */
  const defaultOptions = {
    cornerRadius: 0,
    legend: {
      display: true,
      position: 'right'
    },
    layout: { padding: 0 },
    maintainAspectRatio: false,
    responsive: true,
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }
  };

  /**
   * @type {Chart.ChartOptions}
   */
  const pie = {
    ...defaultOptions,
    tooltips: {
      enabled: true,
      intersect: false,
      caretSize: 10,
      yPadding: 10,
      xPadding: 10,
      borderWidth: 1,
      callbacks: {
        label: function(tooltipItem, d) {
          const dataset = d.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];
          const label = d.labels[tooltipItem.index];

          const total = dataset.data.reduce(
            (total, current) => total + current,
            0
          );

          return `${label}: ${numeral(value).format('0,0.00')} (${numeral(
            value / total
          ).format('0.00%')})`;
        }
      }
    }
  };

  /**
   * @type {Chart.ChartOptions}
   */
  const line = {
    ...defaultOptions,
    cornerRadius: 0,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 10,
      xPadding: 10,
      borderWidth: 1
    },
    legend: {
      display: true,
      position: 'top',
      fontColor: theme.palette.text.secondary
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            maxTicksLimit: 8,
            beginAtZero: true,
            fontColor: theme.palette.text.secondary,
            callback: function(value) {
              var ranges = [
                { divider: 1e6, suffix: 'M' },
                { divider: 1e3, suffix: 'K' }
              ];
              const formatNumber = n => {
                for (var i = 0; i < ranges.length; i++) {
                  if (Math.abs(n) >= ranges[i].divider) {
                    return (
                      ((n / ranges[i].divider).toString() + ranges[i].suffix)
                    );
                  }
                }
                return n;
              };

              return formatNumber(value);
            }
          }
        }
      ]
    }
  };

  /**
   * @type {Chart.ChartOptions}
   */
  const bar = {
    ...defaultOptions,
    cornerRadius: 0,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 10,
      xPadding: 10,
      borderWidth: 1,
      callbacks: {
        label: function(tooltipItem, d) {
          const dataset = d.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];
          const label = dataset.label ? dataset.label + ' : ' : '';

          return (label + numeral(value).format('0,0.00'));
        }
      }
    },
    legend: {
      display: true,
      position: 'top'
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 8,
            fontColor: theme.palette.text.secondary,
            callback: function(value) {
              var ranges = [
                { divider: 1e6, suffix: 'M' },
                { divider: 1e3, suffix: 'K' }
              ];
              const formatNumber = n => {
                for (var i = 0; i < ranges.length; i++) {
                  if (Math.abs(n) >= ranges[i].divider) {
                    return (
                      ((n / ranges[i].divider).toString() + ranges[i].suffix)
                    );
                  }
                }
                return n;
              };

              return formatNumber(value);
            }
          }
        }
      ]
    }
  };

  const chartOptions = {
    pie,
    bar,
    line
  };

  return chartOptions;
}

export default useChartOptions;
