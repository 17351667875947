import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Divider,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import Login from './Login';
import { useEnv } from 'src/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  container: {
    height: props => (props.EGOV ? 'calc(100vh - 80px)' : '100vh'),
    display: 'flex',
    flexDirection: 'column'
  },
  version: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '4em',
    addingTop: '1em',
    paddingBottom: '1em'
  }
}));

const LoginView = () => {
  const { EGOV } = useEnv();
  const classes = useStyles({ EGOV });

  const logoUrl = EGOV
    ? '/static/images/iBFRS_transparent.png'
    : '/static/images/landingpage/sign-in-logo.png';

  return (
    <Page className={classes.root} title="Login">
      <Container className={classes.container} maxWidth="xs">
        <Box className={classes.cardContainer}>
          <Box>
            <Box display="flex" justifyContent="center">
              <RouterLink to="/">
                <img alt="Logo" src={logoUrl} height={180} />
              </RouterLink>
            </Box>
            <Box flexGrow={1} mt={2}>
              <Login />
            </Box>
            <Box my={3}>
              <Divider />
            </Box>

            <Box display="flex" flexDirection="column">
              <Box display="flex">
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  style={{ marginRight: '.5em' }}
                >
                  Don't have an account yet?
                </Typography>

                <Typography
                  component={RouterLink}
                  variant="subtitle2"
                  color="primary"
                  to="/app/register"
                  tabIndex={1}
                  style={{
                    textDecoration: 'none'
                  }}
                >
                  Sign Up
                </Typography>
              </Box>

              <Typography
                component={RouterLink}
                variant="subtitle2"
                to="/app/reset-password"
                color="primary"
                underline="none"
                tabIndex={2}
                style={{
                  textDecoration: 'none'
                }}
              >
                Forgot password?
              </Typography>
              <Typography
                component={RouterLink}
                variant="subtitle2"
                color="primary"
                to="/app/activate-phone-number"
                underline="none"
                tabIndex={2}
                style={{
                  textDecoration: 'none'
                }}
              >
                Activate Phone Number?
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.version}>
          <Typography variant="body2" color="textSecondary">
            version {process?.env?.REACT_APP_VERSION ?? ''}
          </Typography>
        </Box>
      </Container>
    </Page>
  );
};

export default LoginView;
