import React, { useState, useEffect } from 'react';
import { Box, DialogActions, Divider, Button } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { getDateValues, formatDate } from './utils';

const Range = ({
  handleChangeDatePickerValue,
  handleCloseDialog,
  dateValues,
  type,
  defaultDatePicker,
  prefixText,
  ...data
}) => {
  const [dateStart, setDateStart] = useState(moment().format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    setDateStart(moment(dateValues.dt1).format('YYYY-MM-DD'));
    setDateEnd(moment(dateValues.dt2).format('YYYY-MM-DD'));
  }, []);

  const handleChangeDateStart = val => {
    setDateStart(moment(val).format('YYYY-MM-DD'));
  };

  const handleChangeDateEnd = val => {
    setDateEnd(moment(val).format('YYYY-MM-DD'));
  };

  const handleOk = () => {
    handleChangeDatePickerValue(
      getDateValues.range({
        values: { dt1: dateStart, dt2: dateEnd },
        prefixText: prefixText || 'range'
      })
    );
    handleCloseDialog();
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        style={{ gap: '1em' }}
        justifyContent="center"
        p={2}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            label="From"
            placeholder="MM/dd/yyyy"
            clearable
            fullWidth
            inputVariant="outlined"
            value={dateStart}
            onChange={date => handleChangeDateStart(date)}
            format="MM/dd/yyyy"
          />

          <KeyboardDatePicker
            label="To"
            placeholder="MM/dd/yyyy"
            fullWidth
            inputVariant="outlined"
            minDate={dateStart}
            value={dateEnd}
            onChange={date => handleChangeDateEnd(date)}
            format="MM/dd/yyyy"
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Divider />
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button color="primary" onClick={handleCloseDialog}>
          Cancel
        </Button>

        <Button color="primary" variant="contained" onClick={handleOk}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
};

export default Range;
