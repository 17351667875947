import React from 'react';
import HeroSection from './HeroSection';
import BenefitsSection from './BenefitsSection';
import AnchorsSection from './AnchorsSection';
import Footer from './Footer';
import usePageRefs from 'src/hooks/usePageRefs';
import { usePages } from 'src/hooks';
import { Anchors, Testimonials } from 'src/pageComponents';

/**
 * @type {pageDetails}
 */
const defaultSettings = {
  anchors: {
    items: [
      {
        description:
          'Prepares, reviews, and approves government budget, collecting, analyzing, and allocating funds based on priorities, facilitating budgeting process.',
        id: 1,
        img: '/static/ibfrs3/anchors/budget-system.png',
        staticImg: true,
        title: 'Budget System'
      },
      {
        description:
          'Manages financial transactions, tracks revenues, expenditures, and fund balances. Adheres to accounting standards. Includes government-specific financial processes.',
        id: 2,
        staticImg: true,
        img: '/static/ibfrs3/anchors/accounting-system.png',
        title: 'Accounting System'
      },
      {
        description:
          'Automates budgeting and accounting processes, streamlining task sequence, approvals, and information routing. Boosts efficiency and accountability.',
        id: 3,
        staticImg: true,
        img: '/static/ibfrs3/anchors/wf-process.png',
        title: 'Workflow Process'
      },
      {
        description:
          'Provides detailed customizable financial reports for government, regulatory, and internal reporting, covering budget execution, expenditures, revenue analysis, and performance metrics.',
        id: 4,
        staticImg: true,
        img: '/static/ibfrs3/anchors/reports.png',
        title: 'Reports'
      },
      {
        description:
          "Formal financial reports display government's position, performance, and cash flows. Supports informed decisions and transparency.",
        id: 5,
        staticImg: true,
        img: '/static/ibfrs3/anchors/fs_new.png',
        title: 'Financial Statements'
      },
      {
        description:
          'Real-time visual displays of financial and budgetary data. Enables quick insights, monitoring, and informed decision-making. Customizable for user preferences.',
        id: 6,
        staticImg: true,
        img: '/static/ibfrs3/anchors/dashboards.png',
        title: 'Dashboards'
      }
    ],
    show: true
  }
};

const MainPage = () => {
  const { benefits, anchors, ibfrsHero, testimonials } = usePageRefs();
  const { pageDetails, ixPage } = usePages('ibfrs', {
    jsonSettings: defaultSettings
  });

  return (
    <div>
      <div ref={ibfrsHero}>
        <HeroSection />
      </div>
      <div ref={benefits}>
        <BenefitsSection />
      </div>
      {Boolean(pageDetails?.anchors?.show) && (
        <div ref={anchors}>
          <Anchors ixPage={ixPage} pageDetails={pageDetails.anchors} />
        </div>
      )}
      {Boolean(pageDetails?.testimonies?.show) && (
        <div ref={testimonials}>
          <Testimonials ixPage={ixPage} pageDetails={pageDetails.testimonies} />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default MainPage;
