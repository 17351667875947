import { createSlice } from '@reduxjs/toolkit';
import getRandomNumbers from 'src/utils/getRandomNumbers';
import moment from 'moment';
import axios from 'axios';
import { APIRequestV2, cancelAPIRequest } from 'src/helpers/APIRequest';
import { isArray } from 'lodash';

const INITIAL_STATE = {
  loading: false,
  error: false,
  errorMsg: '',
  data: null,
  reportLayout: 'SOP',
  dt1: null,
  dt2: null,
  unreportedDt1: null,
  unreportedDt2: null,
  textValue: '',
  page: 0,
  limit: 10,
  transactionDate: true,
  referenceDate: true,
  reportedDate: false,
  claimDate: null,
  selectedList: [],
  selectedReport: 0,
  selectedRows: [],
  reportDate: moment().format(),
  openUpdateReportDate: false,
  openConfirmUpdate: false,
  confirmUpdateSelectedId: 0,
  isUpdating: false,
  updateResult: null,
  updateFailed: false,
  updateFailedMessage: '',
  transactionToShow: 'unreported',
  validationErrors: [],
  transactionsForUpdateConfirmation: {},
  brch: {
    required: false,
    ixBrch: 0,
    label: '',
    lstBrch: [],
    ixParent: 0
  },
  searchQuery: ''
};

const slice = createSlice({
  name: 'sop',
  initialState: INITIAL_STATE,
  reducers: {
    onFetchReport(state, action) {
      state.validationErrors = [];
      state.updateResult = null;
      state.updateFailed = false;
      state.loading = true;
      state.data = [];
      state.error = false;
      state.errorMsg = '';
      state.selectedRows = [];
    },
    changeLayout(state, action) {
      state.page = 0;
      state.limit = 10;
      state.reportLayout = action.payload;
    },
    changeDate(state, action) {
      state.dt1 = action.payload.dt1;
      state.dt2 = action.payload.dt2;
      state.textValue = action.payload.textValue;
    },
    loadReport(state, action) {
      state.page = 0;
      state.limit = 10;
      state.loading = false;
      state.data = action.payload;
      state.selectedRows = [];
      state.reportDate = moment().format();
      state.openUpdateReportDate = false;
      state.isUpdating = false;
      state.updateResult = null;
      state.updateFailed = false;
      state.validationErrors = [];
    },
    loadError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMsg = action.payload;
    },
    clearReport(state, action) {
      state.page = 0;
      state.limit = 10;
      state.data = null;
      state.transactionDate = true;
      state.referenceDate = true;
      state.reportedDate = false;
      state.reportLayout = 'SOP';
      state.transactionToShow = 'unreported';
      state.searchQuery = '';
      state.selectedRows = [];
      state.selectedReport = 0;
      state.updateResult = null;
      state.updateFailed = false;
      state.updateFailedMessage = '';
      state.validationErrors = [];
      state.openConfirmUpdate = false;
      state.confirmUpdateSelectedId = 0;
      state.transactionsForUpdateConfirmation = {};
    },
    cancelApiRequest(state, action) {
      state.loading = false;
    },
    changeReportPage(state, action) {
      state.page = action.payload;
    },
    changeReportLimit(state, action) {
      state.limit = action.payload;
    },
    checkItem(state, action) {
      state.selectedList = [...state.selectedList, action.payload];
    },
    unCheckItem(state, action) {
      state.selectedList = state.selectedList.filter(
        ({ key }) => key !== action.payload
      );
    },
    selectRows(state, action) {
      let trans = state.transactionsForUpdateConfirmation;

      const transKeys = Object.keys(trans);

      transKeys.forEach(item => {
        if (!action.payload.includes(+item)) {
          delete trans[+item];
        }
      });

      state.selectedRows = action.payload;
      state.transactionsForUpdateConfirmation = trans;
    },
    updateReportDate(state, action) {
      state.data = action.payload.data;
      state.updateResult = action.payload.result;
      state.isUpdating = false;
      state.openUpdateReportDate = false;
      state.transactionsForUpdateConfirmation = {};
      state.openConfirmUpdate = false;
      state.confirmUpdateSelectedId = 0;
    },
    openUpdateReportDate(state, action) {
      state.updateFailed = false;
      state.updateFailedMessage = '';
      state.openUpdateReportDate = true;
    },
    closeUpdateReportDate(state, action) {
      state.updateFailed = false;
      state.updateFailedMessage = '';
      state.openUpdateReportDate = false;
    },
    changeDateFilterCheckBox(state, action) {
      state[action.payload.name] = action.payload.value;
    },
    changeReportDate(state, action) {
      state.reportDate = action.payload;
    },
    clearUpdateReportModalError(state, action) {
      state.updateFailed = false;
    },
    onUpdateReportDate(state, action) {
      state.isUpdating = true;
    },
    failedUpdateReportDate(state, action) {
      state.updateFailedMessage = action.payload;
      state.updateFailed = true;
      state.isUpdating = false;
    },
    clearUpdateResult(state, action) {
      state.updateResult = null;
    },
    loadValidationErrors(state, action) {
      state.openUpdateReportDate = false;
      state.validationErrors = action.payload;
    },
    clearValidationErrors(state, action) {
      state.openUpdateReportDate = false;
      state.validationErrors = [];
    },
    setBrch(state, action) {
      state.brch.required = action.payload.required;
      state.brch.label = action.payload.label_singular;
    },
    changeBrch(state, { payload }) {
      if (isArray(payload)) {
        state.brch.ixBrch = 0;
        state.brch.lstBrch = payload;
        return;
      }

      if (typeof payload?.ixParent !== 'undefined') {
        state.brch.ixBrch = 0;
        state.brch.ixParent = payload.ixParent;
        state.brch.lstBrch = payload.lstBrch;
        return;
      }

      state.brch.ixBrch = payload;
      state.brch.ixParent = 0;
      state.brch.lstBrch = [];
    },
    changeTransactionToShow(state, action) {
      state.transactionToShow = action.payload;
    },
    addTransactionsForUpdateConfirmation(state, action) {
      let trans = {};

      state.data.forEach(item => {
        if (action.payload.includes(item.id)) {
          trans = {
            ...trans,
            [item.id]: {
              passcode: getRandomNumbers(),
              isConfirmed: false
            }
          };
        }
      });

      state.transactionsForUpdateConfirmation = trans;
    },
    showConfirmUpdateModal(state, action) {
      state.openConfirmUpdate = true;
      state.confirmUpdateSelectedId = action.payload;
    },
    closeConfirmUpdateModal(state, action) {
      state.openConfirmUpdate = false;
      state.confirmUpdateSelectedId = 0;
    },
    confirmTransactionUpdate(state, action) {
      state.openConfirmUpdate = false;
      state.confirmUpdateSelectedId = 0;
      state.transactionsForUpdateConfirmation[
        action.payload
      ].isConfirmed = true;
    },
    clearTransactionsForUpdateConfirmation(state, action) {
      state.openConfirmUpdate = false;
      state.confirmUpdateSelectedId = 0;
      state.transactionsForUpdateConfirmation = {};
    },
    changeSelectedReport(state, action) {
      state.searchQuery = '';
      state.selectedReport = action.payload;
    },
    changeUnreportedDate(state, action) {
      state.unreportedDt1 = action.payload.dt1;
      state.unreportedDt2 = action.payload.dt2;
    },
    changeSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

export const batchUpdate = () => async (dispatch, getState) => {
  const { selectedRows, data, reportDate } = getState().sop;
  const { base_url, userToken } = getState().auth;
  const {
    onUpdateReportDate,
    updateReportDate,
    failedUpdateReportDate
  } = slice.actions;

  dispatch(onUpdateReportDate());

  axios({
    method: 'POST',
    url: `${base_url}/reports/tax/purchases/update`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    },
    data: {
      report_date: reportDate,
      items: selectedRows
    }
  })
    .then(result => {
      const { data: resultData } = result;

      const newData = data.map(item => {
        if (resultData?.success?.includes(item.id)) {
          return {
            ...item,
            tax_ReportDate: reportDate
          };
        }

        return item;
      });

      dispatch(updateReportDate({ data: newData, result: resultData }));
    })
    .catch(error => {
      console.error('Failed :', error?.response?.data);
      dispatch(failedUpdateReportDate(error?.response?.data));
    });
};

export const getReport = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const { loadError, loadReport, onFetchReport } = slice.actions;
  const {
    dt1,
    dt2,
    unreportedDt1,
    unreportedDt2,
    transactionDate,
    referenceDate,
    reportedDate,
    brch,
    selectedReport
  } = getState().sop;

  dispatch(onFetchReport());

  let payload = {
    dt1: selectedReport === 1 ? unreportedDt1 : dt1,
    dt2: selectedReport === 1 ? unreportedDt2 : dt2,
    transaction_date: transactionDate,
    reference_date: referenceDate,
    report_date: reportedDate
  };

  if (brch.required) {
    if ((brch?.lstBrch ?? []).length > 0) {
      payload = {
        ...payload,
        ixBrch: brch.ixBrch,
        lstBrch: brch.lstBrch
      };
    } else {
      payload = {
        ...payload,
        ixBrch: brch.ixBrch
      };
    }
  }

  const { success, data, isCancelled, error } = await APIRequestV2({
    method: 'POST',
    url: `${base_url}/reports/tax/purchases`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    },
    data: payload
  });

  if (!success && isCancelled) return;
  else if (!success) {
    dispatch(loadError(error?.data ?? 'Something went wrong.'));
  } else {
    dispatch(loadReport(data.rep));
  }
};

export const cancelRequest = () => (dispatch, _) => {
  if (cancelAPIRequest) cancelAPIRequest();
  dispatch(slice.actions.cancelApiRequest());
};

const sopReducer = slice.reducer;

export const {
  onFetchReport,
  changeLayout,
  changeDate,
  changeLoadReport,
  loadError,
  clearReport,
  changeReportPage,
  changeReportLimit,
  checkItem,
  unCheckItem,
  selectRows,
  updateReportDate,
  openUpdateReportDate,
  closeUpdateReportDate,
  changeDateFilterCheckBox,
  changeReportDate,
  clearUpdateReportModalError,
  onUpdateReportDate,
  failedUpdateReportDate,
  clearUpdateResult,
  loadValidationErrors,
  clearValidationErrors,
  setBrch,
  changeBrch,
  changeTransactionToShow,
  addTransactionsForUpdateConfirmation,
  showConfirmUpdateModal,
  closeConfirmUpdateModal,
  confirmTransactionUpdate,
  clearTransactionsForUpdateConfirmation,
  changeSelectedReport,
  changeUnreportedDate,
  changeSearchQuery,
  clear,
  restore
} = slice.actions;
export default sopReducer;
