import React, { useEffect, useRef } from 'react';
import Page from 'src/components/Page';
import UlapBizProducts from './UlapBizProducts';

import Footer from '../Footer';

const PlanDetails = () => {
  const planDetails = useRef();

  useEffect(() => {
    planDetails.current.scrollIntoView();
  }, []);

  return (
    <Page title="UlapBiz - Plans">
      <div ref={planDetails}>
        <UlapBizProducts />
      </div>
      <Footer />
    </Page>
  );
};

export default PlanDetails;
