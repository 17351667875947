import React from 'react';
import { Tooltip, Button, Box, Typography } from '@material-ui/core';
import { Add, Link, OpenInNew } from '@material-ui/icons';
import { useNotif, useRequest, useTranSearch } from 'src/hooks';
import { useConfirmDialog } from '../ConfirmDialog';
import { useSelector } from 'react-redux';

const TransLink = ({ data }) => {
  const { transactions = [] } = useSelector(state => state.bizMeta);
  const notify = useNotif();
  const { prompt } = useConfirmDialog();

  const editable = 'editable' in data ? data.editable : true;

  const jid = data?.value;
  const onLink = data?.onLink || function() {};
  const getPayload = data?.getPayload || function() {};
  const onCreate = data?.onCreate || function() {};

  const { openSearch, closeTransSearch } = useTranSearch();
  const request = useRequest();

  function linkTransaction() {
    openSearch({
      JCd: data?.JCd || '',
      onSelectCallback: row => {
        onLink(data.field, row);
        closeTransSearch();
      }
    });
  }

  async function createTransaction() {
    const uuid =
      transactions.find(trans => trans.JCd === data?.JCd)?.jcd_uuid || '';
    if (!uuid)
      return notify.error(
        'Failed to create. You may have no privilege to create on this transaction.'
      );

    const payload = getPayload(data.field);
    const res = await request.post(`/trans/jcd/${uuid}/save`, payload || {});

    if (res.success) {
      notify.success('Transaction successfully created.');
      onCreate(data.field, res.data);
    } else {
      notify.error('Failed to create transaction.');
    }
  }

  function confirmNewTransaction() {
    prompt({
      title: <Typography variant="h4">Confirm New Transaction</Typography>,
      body: (
        <Typography variant="body1" align="justify">
          Are you sure you want to create this transaction?
        </Typography>
      ),
      okText: 'Proceed',
      cancelText: 'Cancel',
      okProps: {
        variant: 'contained',
        color: 'primary'
      },
      disableTypography: true,
      cancelProps: {
        color: 'primary'
      },
      onOk: createTransaction
    });
  }

  return jid ? (
    <Tooltip title="Create in new tab">
      <Button
        color="primary"
        startIcon={<OpenInNew />}
        onClick={() => {
          window.open(`/app/trans/${jid}`, '_blank');
        }}
      >
        Open
      </Button>
    </Tooltip>
  ) : (
    <>
      {editable ? (
        <Box display="flex" gridGap={8}>
          <Tooltip title="Create Transaction">
            <Button
              color="primary"
              startIcon={<Add />}
              onClick={confirmNewTransaction}
            >
              Create
            </Button>
          </Tooltip>
          <Tooltip title="Link Existing Transaction">
            <Button
              color="primary"
              startIcon={<Link />}
              onClick={linkTransaction}
            >
              Link
            </Button>
          </Tooltip>
        </Box>
      ) : null}
    </>
  );
};

export default TransLink;
