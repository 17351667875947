import React, { useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { useRequest } from 'src/hooks';
import {
  setAnnouncement,
  closeAnnouncement
} from 'src/redux/slices/announcement';
import { makeStyles, Typography } from '@material-ui/core';
import { trans } from 'src/lib/endpoints';
import moment from 'moment';
import { useDispatch } from 'src/store';

const useStyles = makeStyles(theme => ({
  announcement: {
    borderRadius: 0,
    padding: `0 ${theme.spacing(2)}px`
  }
}));

const Announcement = () => {
  const { announcement: className } = useStyles();
  const request = useRequest();
  const dispatch = useDispatch();

  const { announcement = {}, isClosed = false } = useSelector(
    state => state.announcement
  );

  const shouldShow =
    announcement.start &&
    announcement.end &&
    moment().isSameOrAfter(moment(announcement.start)) &&
    moment().isSameOrBefore(moment(announcement.end));

  function close() {
    dispatch(closeAnnouncement());
  }

  useEffect(() => {
    let cancel = () => {};

    (async function() {
      const res = await request.get(trans.announcements, {}, function(c) {
        cancel = c;
      });

      if (res.success) {
        dispatch(setAnnouncement(res.data));
      }
    })();

    return () => {
      cancel();
    };
  }, []);

  if (!shouldShow || !announcement?.message || isClosed) return null;

  return (
    <Alert
      severity={announcement.severity}
      variant="filled"
      className={className}
      onClose={close}
    >
      <Typography
        variant="caption"
        dangerouslySetInnerHTML={{ __html: announcement.message }}
      />
    </Alert>
  );
};

export default Announcement;
