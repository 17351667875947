import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 500,
    color: '#FF7704'
  }
}));

const MobileMenu = ({ open = false, setOpen }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <Collapse in={open}>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText
            primary="Home"
            primaryTypographyProps={{
              align: 'center',
              className: classes.root
            }}
          />
        </ListItem>

        <ListItem
          button
          component={Link}
          className={classes.root}
          to={'/plans'}
        >
          <ListItemText
            primary="Plans"
            primaryTypographyProps={{
              align: 'center',
              className: classes.root
            }}
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          className={classes.root}
          to="/accounting-and-beyond"
        >
          <ListItemText
            primary="Accounting and Beyond"
            primaryTypographyProps={{
              align: 'center',
              className: classes.root
            }}
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          className={classes.root}
          to="/reports-and-schedule"
        >
          <ListItemText
            primary="Schedules and Reports"
            primaryTypographyProps={{
              align: 'center',
              className: classes.root
            }}
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          className={classes.root}
          to="/notable-features"
        >
          <ListItemText
            primary="Notable features"
            primaryTypographyProps={{
              align: 'center',
              className: classes.root
            }}
          />
        </ListItem>

        <ListItem
          button
          component={Link}
          className={classes.root}
          to="/app/login"
        >
          <ListItemText
            primary="Sign In"
            primaryTypographyProps={{
              align: 'center',
              className: classes.root
            }}
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          className={classes.root}
          to="/app/register"
        >
          <ListItemText
            primary="Sign Up"
            primaryTypographyProps={{
              align: 'center',
              className: classes.root
            }}
          />
        </ListItem>
      </List>
    </Collapse>
  );
};

export default MobileMenu;
