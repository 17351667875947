import React from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Button,
  Container
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  rightTopCorner: {
    position: 'absolute',
    top: -300,
    right: -300,
    transform: 'scale(0.5)',
    transformOrigin: 'top right'
  },
  bottomLeftCorner: {
    position: 'absolute',
    bottom: 0,
    left: -150,
    transform: 'scale(0.5)',
    transformOrigin: 'bottom left'
  },
  particle1: {
    position: 'absolute',
    top: 140,
    left: 360
  },
  container: {
    position: 'relative',
    overflow: 'hidden'
  },
  header: {
    position: 'relative',
    overflow: 'hidden',
    height: 'calc(100vh - 64px)'
  },
  headerBg1: {
    backgroundImage: 'url(/static/backgrounds/geometric_lines.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  headerBg2: {
    backgroundImage: 'url(/static/ibfrs3/background-header.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  wave1Top: {
    position: 'absolute',
    bottom: '98%',
    left: 0
  },
  wave1Bottom: {
    position: 'absolute',
    top: '98%',
    left: 0
  },
  scrollDownWrapper: {
    position: 'absolute',
    bottom: 80,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  indicator: {
    width: '20px',
    height: '35px',
    border: '2px solid #000',
    borderRadius: '50px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '8px',
      height: '8px',
      background: '#000',
      margin: 'auto',
      left: '0',
      right: '0',
      top: 0,
      borderRadius: '50%',
      animation: '$animate 1s linear infinite'
    }
  },
  '@keyframes animate': {
    '0%': {
      top: 0
    },
    '100%': {
      top: '20px',
      transform: 'scale(0.8)'
    }
  },
  scrollDown: {
    color: '#000',
    marginTop: '.1em'
  },
  scrollDownContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  primaryTypography: {
    fontSize: '3.8rem',
    fontWeight: 900
  },
  secondaryTypography: {
    fontSize: '1.2rem',
    fontWeight: 500,
    marginTop: '1em'
  },
  image: {
    height: 450,
    display: 'flex',
    justifyContent: 'center',
    aligItems: 'center',
    '& > img': {
      height: 450,
      boxShadow: theme.shadows[20],
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        width: '80%'
      }
    }
  },
  bookDemo: {
    background: '#ff7704',
    color: '#fff',
    '&:hover': {
      background: '#ff7704',
      color: '#fff'
    }
  },
  flex: {
    display: 'flex',
    gridGap: '6em',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gridGap: '2.5em'
    }
  },
  buttonContainer: {
    display: 'flex',
    gap: '1em',
    marginTop: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  headerTitle: {
    display: 'block',
    fontWeight: 900,
    fontSize: '3.3rem',
    lineHeight: '4rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.3rem',
      lineHeight: '3rem',
      textAlign: 'center'
    }
  }
}));

/**
 *
 * @param {object} param
 * @param {string} param.ixPage
 * @param {pageHero} param.pageDetails
 * @returns
 */
const HeroTl2 = ({ ixPage, pageDetails }) => {
  const classNames = useStyles();

  const showBg = pageDetails?.showBg || false;
  const bg = pageDetails?.bg || 'bg1';

  const bgClass = { bg1: classNames.headerBg1, bg2: classNames.headerBg2 }[bg];

  return (
    <Box className={`${classNames.header} ${showBg ? bgClass : ''}`}>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100%"
      >
        <Container maxWidth="lg">
          <Box className={classNames.flex}>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box>
                <Typography
                  color="textPrimary"
                  className={classNames.primaryTypography}
                >
                  {pageDetails.primaryText}
                </Typography>
              </Box>
              <Box>
                <Typography
                  color="textSecondary"
                  className={classNames.secondaryTypography}
                >
                  {pageDetails.secondaryText}
                </Typography>
              </Box>
              <Box className={classNames.buttonContainer}>
                <Button
                  component={NavLink}
                  to="/contact-us"
                  variant="contained"
                  size="large"
                  className={classNames.bookDemo}
                >
                  Schedule a Demo
                </Button>
              </Box>
            </Box>
            <Box className={classNames.image} flex={1}>
              <img
                src={
                  pageDetails?.staticImg
                    ? pageDetails?.img
                    : `/api/pages/${ixPage}/images/${pageDetails.img}`
                }
                loading="lazy"
              />
            </Box>
          </Box>

          <Box className={classNames.scrollDownContainer} mt={20}>
            <div className={classNames.indicator} />
            <Typography
              variant="caption"
              display="block"
              className={classNames.scrollDown}
            >
              Scroll Down
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default HeroTl2;
