import { isEmpty } from 'lodash';
import React from 'react';
import Field from '.';

const DEFAULT_TYPES_VALUE = {
  str: '',
  int: 0,
  json: {},
  boolean: false,
  date: ''
};

const MultiField = ({ data, onChangeField, ...props }) => {
  const { props: jsonFlds = {}, fld, value = {}, error = {} } = data;

  if (isEmpty(jsonFlds)) return null;

  function onChange(event) {
    onChangeField({
      target: {
        name: fld,
        value: {
          ...value,
          [event.target.name]: event.target.value
        }
      }
    });
  }

  return Object.entries(jsonFlds).map(([key, fldProps]) => (
    <Field
      key={key}
      {...props}
      data={{
        ...fldProps,
        fld: key,
        value: value?.[key] || DEFAULT_TYPES_VALUE?.[fldProps?.type],
        error: Boolean(error?.key),
        errorMessage: error?.key || ''
      }}
      onChangeField={onChange}
    />
  ));
};

export default MultiField;
