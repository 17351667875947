import React from 'react';
import { Box, Typography, Container, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './useStyles';

const Hero = () => {
  const classes = useStyles();
  return (
    <div className={classes.whiteBgColor}>
      <Container maxWidth="lg" className={classes.heroContainer}>
        <Box flex={1}>
          <Typography className={classes.heroTitle}>
            UlapBiz for{' '}
            <span className={classes.secondaryTitleColor}>
              Construction Companies
            </span>
          </Typography>

          <Typography className={classes.heroSubtitle} color="textSecondary">
            Construction project management made easier by integrating site and
            office information for real-time representation
          </Typography>
          <Box className={classes.btnContainer}>
            <Button
              component={Link}
              to="/contact-us"
              color="primary"
              size="large"
              variant="contained"
              className={classes.btn}
            >
              Book a demo
            </Button>
          </Box>
        </Box>
        <Box className={classes.heroImageContainer} flex={1}>
          <img
            className={classes.heroImage}
            src="/static/images/solutions/construction/construction.svg"
          />
        </Box>
      </Container>
    </div>
  );
};

export default Hero;
