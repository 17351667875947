import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  selectedAcc: {
    id: 0,
    title: '',
    ixSubType1: 0,
    ixSubType2: 0,
    ixSubType3: 0,
    ixSubType4: 0,
    ixSubType5: 0,
    sSubType1: '',
    sSubType2: '',
    sSubType3: '',
    sSubType4: '',
    sSubType5: ''
  },
  otherAcc: [],
  data: null,
  dateRange: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  brch: {
    required: false,
    ixBrch: 0,
    label: ''
  },
  page: 0,
  limit: 25,
  loading: false,
  error: false,
  errorMsg: '',
  graphData: {},
  graphRequestData: {},
  showCompact: false,
  showAccTitle: false,
  showBeg: false,
  showOtherDetails: false,
  showReconDetails: false
};

const slice = createSlice({
  name: 'gl',
  initialState: INITIAL_STATE,
  reducers: {
    setOnLoadPageParams(state, action) {
      state.dateRange.dt1 = action.payload.dt1;
      state.dateRange.dt2 = action.payload.dt2;
      state.dateRange.textValue = action.payload.textValue;
      state.selectedAcc.id = action.payload.ixAcc;
      state.selectedAcc.title = action.payload.sAcc;
      state.brch.ixBrch = action.payload.ixBrch;
    },
    setReportState(state, action) {
      return { ...state, ...action.payload, loading: false };
    },
    resetState(state) {
      return { ...INITIAL_STATE, brch: { ...state.brch, ixBrch: 0 } };
    },
    setDateFilter(state, action) {
      state.dateFilter = action.payload;
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setAccount(state, action) {
      state.selectedAcc = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setError(state, action) {
      return { ...state, ...action.payload };
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
      state.page = 0;
    },
    setBrch(state, action) {
      state.brch = {
        ...state.brch,
        ixBrch: action.payload
      };
    },
    setBrchRequired(state, action) {
      state.brch = {
        ...state.brch,
        label: action.payload.label,
        required: action.payload.required
      };
    },
    cancelRequest(state) {
      state.loading = false;
    },
    setGraphData(state, action) {
      state.graphData = action.payload;
    },
    setRequestData(state, action) {
      state.graphRequestData = action.payload;
    },
    setShowCompact(state, action) {
      state.showCompact = action.payload;
    },
    setShowOtherDetails(state, action) {
      state.showOtherDetails = action.payload;
    },
    setShowReconDetails(state, action) {
      state.showReconDetails = action.payload;
    },
    setOtherAcc(state, action) {
      state.otherAcc = action.payload;
    },
    setShowAccTitle(state, action) {
      state.showAccTitle = action.payload;
    },
    setShowBeg(state, action) {
      state.showBeg = action.payload;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    }
  }
});

const glReducer = slice.reducer;

export const {
  setReportState,
  resetState,
  setDateFilter,
  setDateRange,
  setAccount,
  setError,
  setLoading,
  setPage,
  setLimit,
  setBrch,
  setBrchRequired,
  cancelRequest,
  setGraphData,
  setRequestData,
  setShowCompact,
  setShowAccTitle,
  restore,
  clear,
  setOtherAcc,
  setOnLoadPageParams,
  setShowBeg,
  setShowOtherDetails,
  setShowReconDetails,
  setData
} = slice.actions;
export default glReducer;
