import React from 'react';
import {
  FormControl,
  SvgIcon,
  Button,
  Menu,
  MenuItem
} from '@material-ui/core';
import useMenu from 'src/hooks/useMenuV2';
import { Calendar } from 'react-feather';

const timeRanges = [
  {
    value: 6,
    text: 'Last 7 days'
  },
  {
    value: 29,
    text: 'Last 30 days'
  }
];

const DateFilter = ({ dateRange, updateDateRange, isLoading }) => {
  const [anchorEl, openMenu, closeMenu] = useMenu();

  return (
    <FormControl component="fieldset" disabled={isLoading}>
      <Button
        onClick={openMenu}
        startIcon={
          <SvgIcon fontSize="small">
            <Calendar />
          </SvgIcon>
        }
        color="primary"
      >
        {timeRanges.find(timeRange => timeRange.value === dateRange).text}
      </Button>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {timeRanges.map(timeRange => (
          <MenuItem
            key={timeRange.value}
            onClick={() => updateDateRange(timeRange.value)}
          >
            {timeRange.text}
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );
};

export default DateFilter;
