import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    paddingTop: 150,
    paddingBottom: 150,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10)
    }
  },
  whiteBgColor: {
    backgroundColor: '#fff',
    background: 'url(/static/images/solutions/construction/background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  heroContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    gap: '2em',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  heroTitle: {
    fontSize: '3.3rem',
    lineHeight: '1.3em',
    fontWeight: 900,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '3rem',
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: '2.5rem',
      width: '100%'
    }
  },
  heroSubtitle: {
    marginTop: '1em',
    fontSize: '1.125rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      textAlign: 'center',
      width: '100%'
    }
  },
  heroImageContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  heroImage: {
    objectFit: 'contain',
    height: 500,
    animation: `$bounce 3000ms ${theme.transitions.easing.easeInOut} infinite alternate`,

    [theme.breakpoints.down('sm')]: {
      width: '80%',
      height: 'auto',
      transform: 'translate(1px,1px)',
      animation: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
      transform: 'translate(1px,1px)',
      animation: 'none'
    }
  },
  '@keyframes bounce': {
    '0%': {
      transform: 'translateY(0)'
    },
    '100%': {
      transform: 'translateY(-50px)'
    }
  },
  secondaryTitleColor: {
    color: '#FF7704'
  },

  btnContainer: {
    display: 'flex',
    marginTop: '2em',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column'
    }
  },

  btn: {
    borderRadius: '30px',
    width: 223,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  btnContactUs: {
    background: '#fff',
    border: '1px solid #000'
  },
  benefitBg: {
    background: '#FBFCFF',
    height: '100%'
  },
  sectionTitleContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  sectionTitle: {
    fontSize: '3rem',
    lineHeight: '1.3em',
    fontWeight: 900,
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
      flex: 2,
      fontWeight: 900
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.2rem',
      flex: 2,
      fontWeight: 900
    }
  },

  smallTitle: {
    position: 'relative',
    fontWeight: 900,
    fontSize: '1.125rem',
    '& span': {
      marginLeft: '40px'
    },
    '&:before': {
      content: "''",
      display: 'block',
      width: '35px',
      height: '2px',
      background: '#000',
      left: 0,
      top: '45%',
      position: 'absolute'
    }
  },

  benefit: {
    padding: theme.spacing(5)
  },
  benefitTitle: {
    fontWeight: 900,
    fontSize: '1.125rem',
    marginBottom: '.5em',
    marginTop: theme.spacing(3)
  },
  benefitDesc: {
    lineHeight: '1.5rem'
  },
  feature: {
    fontSize: '3rem',
    textAlign: 'center',
    fontWeight: 900,

    [theme.breakpoints.down('sm')]: {
      fontSize: '2.3rem',
      lineHeight: '1em'
    }
  },
  featurDesc: {
    marginTop: '.5em',
    textAlign: 'center'
  },
  featureContainer: {
    display: 'flex',
    margin: '5em 0',
    gridGap: '4em',
    borderRadius: '20px',
    marginTop: theme.spacing(10),
    '&:nth-child(even)': {
      flexDirection: 'row-reverse'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '&:nth-child(even)': {
        flexDirection: 'column'
      }
    }
  },
  featureTitle: {
    fontSize: '3em',
    fontWeight: 900,
    lineHeight: '1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em'
    }
  },
  featureDesc: {
    marginTop: '1em',
    fontSize: '1.125rem'
  },
  featureDetailsContainer: {},
  featureImage: {
    width: '50%',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  ctaContainer: {
    height: 500,
    backgroundColor: '#fff',
    background: 'url(/static/images/solutions/construction/cta.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ctContent: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '3em',
    width: '40%',
    padding: '1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  ctaText: {
    color: '#fff',
    fontWeight: 900,
    fontSize: '2.5em'
  },
  ctaButton: {
    outline: '2px solid #fff',
    borderRadius: '20px',
    color: '#fff',
    '&.MuiButton-outlined ': {
      border: 'none'
    }
  }
}));

export default useStyles;
