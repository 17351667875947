function getPropValue(json = {}, nestedProp = '', defaultValue = undefined) {
  const props = nestedProp.split('.');
  let val = json;

  for (const prop of props) {
    if (prop in val) val = val[prop];
    else return defaultValue;
  }

  return val;
}

export default getPropValue;
