import React from 'react';
import TestimonialsTl1 from './TestimonialsTl1';

/**
 *
 * @param {object} param
 * @param {number} param.ixPage
 * @param {pageTestimonies} param.pageDetails
 * @returns
 */
const Testimonials = ({ ixPage, pageDetails }) => {
  return <TestimonialsTl1 ixPage={ixPage} pageDetails={pageDetails} />;
};

export default Testimonials;
