import React, { useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Button,
  Dialog,
  DialogTitle
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import pdf from './resources/sample_bir_form.pdf';
const useStyles = makeStyles(theme => ({
  textMargin: {
    marginTop: '1em',
    marginBottom: '1em'
  },
  ul: {
    marginLeft: '1em'
  },

  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(2)
    }
  },
  previewButton: {
    background: '#1976D2',
    color: '#fff',
    '&:hover': {
      background: '#1976D2',
      color: '#fff'
    }
  },
  qoute: {
    color: '#268ABC',
    fontWeight: 900,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3em'
    }
  }
}));

const FilingAttachments = () => {
  const classes = useStyles();

  return (
    <Box>
      <Typography color="textSecondary" className={classes.textMargin}>
        Generate reports for VAT payable, percentage tax payable, and expanded
        withholding taxes.
      </Typography>
      <Typography color="textSecondary" style={{ marginBottom: '1em' }}>
        Generate and Export the following :
      </Typography>

      <ul className={classes.ul}>
        <li>
          <Typography color="textSecondary">
            Summary of Alphalist of Sales and Purchases (SLSP)
          </Typography>
        </li>
        <li>
          <Typography color="textSecondary">
            Summary of Alphalist of Withholding Taxes (SAWT)
          </Typography>
        </li>
        <li>
          <Typography color="textSecondary">
            Quarterly Alphalist of Payees (QAP)
          </Typography>
        </li>
        <li>
          <Typography color="textSecondary">DAT File </Typography>
        </li>
      </ul>
    </Box>
  );
};

const InternalControl = () => {
  const classes = useStyles();

  return (
    <Box>
      <Typography color="textSecondary" className={classes.textMargin}>
        Integrates control in the system thru specific user privileges,
        requirements on transactions, and default transaction status defined
        based on the management's decision.
      </Typography>
      <Typography variant="h2" className={classes.qoute}>
        Integrates with your work and on how you work.
      </Typography>
    </Box>
  );
};

const DaloyBoard = () => {
  const classes = useStyles();

  return (
    <Box>
      <Typography color="textSecondary" className={classes.textMargin}>
        Provides a summary of all the transactions based on their corresponding
        status and enables the user to easily move transactions from one status
        to another.
      </Typography>
      <Typography variant="h2" className={classes.qoute}>
        Visualize who's working on what.
      </Typography>
    </Box>
  );
};

const GenerateAction = () => {
  const classes = useStyles();
  const [preview, setPreview] = useState(false);

  return (
    <Box className={classes.buttonContainer}>
      <Dialog open={preview} onClose={() => setPreview(false)} fullScreen>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={0}
            m={0}
          >
            <Typography display="block">Preview Sample</Typography>
            <Button color="primary" onClick={() => setPreview(false)}>
              Close
            </Button>
          </Box>
        </DialogTitle>

        <iframe title="Preview" src={pdf} height="100%" width="100%" />
      </Dialog>

      <Button
        startIcon={<PrintIcon />}
        onClick={() => setPreview(prev => !prev)}
        className={classes.previewButton}
        size="large"
      >
        Preview Sample
      </Button>
    </Box>
  );
};

export default {
  tax: {
    hero: {
      title: 'UlapTax',
      title1: 'Ulap',
      title2: 'Tax',
      narrative: `One of the legal requirements that must be complied with regularly by every business is the filing and payment of business tax. The preparation and process for such compliance can be exhausting for business owners.
      However, with Ulap.Biz Tax, worry no more, for you will be guaranteed that your firm will be up-to-date and will keep you from incurring penalities, offering you the easiest and efficient way to comply with such tax laws.`,
      s1: `One of the legal requirements that must be complied with regularly by every business is the filing and payment of business tax. The preparation and process for such compliance can be exhausting for business owners.`,
      s2: `However, with Ulap.Biz Tax, worry no more, for you will be guaranteed that your firm will be up-to-date and will keep you from incurring penalities, offering you the easiest and efficient way to comply with such tax laws.`,
      logo: '/static/images/sub-systems/tax.png',
      heroPhoto: '/static/images/landing-page/tax.jpg'
    },
    notableFeatures: {
      items: [
        {
          title: 'Fills-up the forms for you.',
          description:
            'Auto-fill and Print BIR forms such as BIR Form 2307 and 2306.',
          photo: '/static/images/sub-systems/bir_forms.png',
          actions: <GenerateAction />
        },
        {
          title: 'Tax Integrated Transactions',
          description:
            'Tax details integrated in sales invoicing and collection, purchases, and cash disbursement.',
          photo: '/static/images/sub-systems/tax_related.png'
        },
        {
          title: 'Filing Attachments',
          type: 'component',
          description: <FilingAttachments />,
          photo: '/static/images/sub-systems/tax_report.png'
        }
      ]
    },
    systemFeatures: {
      items: [
        {
          title: 'VAT Selection',
          icon: 'faPercent'
        },
        {
          title: 'Income Tax and Percentage Tax Withheld Selection',
          icon: 'faBuildingColumns'
        },
        {
          title: 'Printable BIR Forms',
          icon: 'faPrint'
        },
        {
          title: 'Downloadable DAT and CSV File',
          icon: 'faFileInvoice'
        }
      ]
    }
  },
  inv: {
    hero: {
      title: 'UlapInventory',
      title1: 'Ulap',
      title2: 'Inventory',
      narrative:
        'Inventory management is one of the key processes in a merchandising company where stock updates of products need to be updated repeatedly for better flow of deliveries. To achieve efficient management of inventory, the adoption of the latest technologies has become the best solution. Using new technology is like an extended power that enhances your businesses capabilities.',
      s1: `Inventory management is one of the key processes in a merchandising company where stock updates of products need to be updated repeatedly for better flow of deliveries.`,
      s2: `To achieve efficient management of inventory, the adoption of the latest technologies has become the best solution. Using new technology is like an extended power that enhances your businesses capabilities.`,
      logo: '/static/images/sub-systems/inv.png',
      heroPhoto: '/static/images/landing-page/inv.jpg'
    },
    notableFeatures: {
      items: [
        {
          title: 'FIFO Costing Method',
          description:
            'Provides accurate value of your ending inventory and cost of goods sold by assuming that items first placed in inventory are first sold.',
          photo: '/static/images/sub-systems/undraw_booked_re_vtod.svg'
        },
        {
          title: 'Multi-Branch & Multi-Warehouse',
          description:
            'Creates virtual locations within the system that help you monitor and control inventory-related transactions on all your branches and warehouses.',
          photo: '/static/images/sub-systems/undraw_heavy_box_agqi.svg'
        },
        {
          title: 'Transfers and Adjustments',
          description:
            'Integrates intercompany inventory transfers and adjustments to reflect real-time stock locations and levels. ',
          photo: '/static/images/sub-systems/undraw_order_ride_re_372k.svg'
        }
      ]
    },
    systemFeatures: {
      items: [
        {
          title: 'Product Images',
          icon: 'faImage'
        },

        {
          title: 'Serial Number',
          icon: 'faBarcode'
        },
        {
          title: 'Batch Number',
          icon: 'faQrcode'
        },
        {
          title: 'Price Types',
          icon: 'faTags'
        },
        {
          title: 'Multiple Units of Measurements',
          icon: 'faRuler'
        },
        {
          title: 'Markup and Discounts',
          icon: 'faPlusMinus'
        },
        {
          title: 'Stock Bundle',
          icon: 'faBoxesStacked'
        },
        {
          title: 'Accounting Integration',
          icon: 'faCalculator'
        },
        {
          title: 'VAT Integration',
          icon: 'faPercent'
        },
        {
          title: 'FIFO Method',
          icon: 'faList'
        },
        {
          title: 'Customer and Supplier Masterlist',
          icon: 'faUsers'
        },
        {
          title: 'Inventory Standard Reports',
          icon: 'faChartSimple'
        }
      ]
    },
    transactions: {
      items: [
        {
          title: 'Purchases Transaction',
          icon: 'faReceipt'
        },
        {
          title: 'Sales Transaction',
          icon: 'faReceipt'
        },
        {
          title: 'Warehouse Transfer',
          icon: 'faDolly'
        },
        {
          title: 'Branch Transfer',
          icon: 'faTruckArrowRight'
        },
        {
          title: 'Stock Adjustment',
          icon: 'faPlusMinus'
        },
        {
          title: 'Pick List',
          icon: 'faListCheck'
        },
        {
          title: 'Sales Order',
          icon: 'faStore'
        },
        {
          title: 'Purchase Order',
          icon: 'faStore'
        },
        {
          title: 'Purchase Return',
          icon: 'faStore'
        },
        {
          title: 'Sales Return',
          icon: 'faStore'
        }
      ]
    },
    reports: {
      items: [
        {
          title: 'Purchase Summary',
          icon: 'faChartSimple'
        },
        {
          title: 'Sales Summary',
          icon: 'faChartSimple'
        },
        {
          title: 'Stock Card with Graph',
          icon: 'faChartSimple'
        },
        {
          title: 'Order Summary Report',
          icon: 'faChartSimple'
        },
        {
          title: 'Ending Inventory Report',
          icon: 'faChartSimple'
        },
        {
          title: 'Transfer Summary',
          icon: 'faChartSimple'
        },
        {
          title: 'Returns Summary',
          icon: 'faChartSimple'
        }
      ]
    },
    showMainHighlight: true,
    mainHighlight: {
      title: 'Stock Card comes standard with a Graph',
      image: '/static/images/sub-systems/stock_card.png'
    }
  },
  daloy: {
    hero: {
      title: 'DaloyWorkFlow',
      title1: 'Daloy',
      title2: 'Workflow',
      narrative:
        'Efficient workflow is an integral part leading to the success of every operation. Keeping track of the transactions based on their corresponding status makes it easier to monitor daily progress and address irregularities. To facilitate systematic process flows, there is a necessity to innovate and follow the current trend through automations and technologies.',
      s1: `Efficient workflow is an integral part leading to the success of every operation. Keeping track of the transactions based on their corresponding status makes it easier to monitor daily progress and address irregularities.`,
      s2: `To facilitate systematic process flows, there is a necessity to innovate and follow the current trend through automations and technologies.`,
      logo: '/static/images/sub-systems/daloy.png',
      heroPhoto: '/static/images/landing-page/daloy.jpg'
    },
    showMainHighlight: true,
    mainHighlight: {
      title:
        'Allows you to integrate your work with the system while improving internal control at the same time.',
      hideImage: true
    },
    notableFeatures: {
      items: [
        {
          title: 'Daloy Board',
          type: 'component',
          description: <DaloyBoard />,
          photo: '/static/images/sub-systems/daloy_board.png'
        },
        {
          title: 'Internal Control',
          type: 'component',
          description: <InternalControl />,
          // photo: '/static/images/sub-systems/assigned_transactions.png'
          photo: '/static/images/sub-systems/undraw_subscriptions_re_k7jj.svg'
        },
        {
          title: 'Segregation of Duties',
          description:
            "Assigns workflow based on user's duties, responsibilities, and specific transaction privileges.",
          photo: '/static/images/sub-systems/undraw_co-working_re_w93t.svg'
        }
      ]
    },
    systemFeatures: {
      items: [
        {
          title: 'Interactive',
          icon: 'faUsers'
        },
        {
          title: 'Commenting System',
          icon: 'faComments'
        },
        {
          title: 'Digital Attachments',
          icon: 'faFile'
        },
        {
          title: 'Checklist',
          icon: 'faClipboardList'
        },
        {
          title: 'Customizable Workflows',
          icon: 'faCodeBranch'
        },
        {
          title: 'DaloyWF Logs',
          icon: 'faClockRotateLeft'
        },
        {
          title: 'SMS Notifications',
          icon: 'faCommentSms'
        },
        {
          title: 'DaloyWF Dashboard',
          icon: 'faPieChart'
        },
        {
          title: 'Daloy Board',
          icon: 'faPersonChalkboard'
        }
      ]
    }
  },
  targets: {
    hero: {
      title: 'UlapTargets',
      title1: 'Ulap',
      title2: 'Targets',
      narrative:
        'Businesses establish objectives and targets for company growth and organizational harmony. UlapTargets provides realistic and instantly generated graphical representation with the goal of delivering well-established budgets and actual data. Thus, allowing management to easily track which aspects of the business require necessary improvement.',
      s1: `Businesses establish objectives and targets for company growth and organizational harmony.`,
      s2: `UlapTargets provides realistic and instantly generated graphical representation with the goal of delivering well-established budgets and actual data. Thus, allowing management to easily track which aspects of the business require necessary improvement.`,
      logo: '/static/images/sub-systems/targets.png',
      heroPhoto: '/static/images/landing-page/targets.jpg'
    },
    notableFeatures: {
      items: [
        // {
        //   title: 'Budget Settings',
        //   description:
        //     "Establishes a budget structure that may serve as the foundation for an organization's operations, assuring project feasibility.",
        //   photo: '/static/images/sub-systems/budget_settings.png'
        // },
        {
          title: 'Target Setting and Display',
          description:
            'Establishes targets in key operational areas at a certain point in time so that everyone in the organization understands what to strive for. Provides a graphical representation of the actual and standard data.',
          photo: '/static/images/sub-systems/targets_with_blob.png'
        }
      ]
    },
    showMainHighlight: true,
    mainHighlight: {
      title:
        "Allows you to track your team's progress by comparing targets with actual data",
      hideImage: true
    },
    systemFeatures: {
      items: [
        {
          title: 'Monthly, Quarterly or Yearly Setup',
          icon: 'faCalendar'
        },
        {
          title: 'Graphical Representation',
          icon: 'faLineChart'
        },
        {
          title: 'Real Time',
          icon: 'faClock'
        },
        {
          title: 'Customizable',
          icon: 'faGear'
        }
      ]
    }
  },
  payroll: {
    hero: {
      title: 'UlapPayroll',
      title1: 'Ulap',
      title2: 'Payroll',
      s2:
        'UlapPayroll is a cloud-based solution that manages automated payments to employees. It helps organizations of all sizes maintain compliance with tax laws and other financial regulations. It also saves time and reduces errors compared to the manual computation of compensation.',
      logo: '/static/images/landing-page/payroll.png',
      heroPhoto: '/static/images/landing-page/targets.jpg'
    },
    showMainHighlight: true,
    mainHighlight: {
      title: 'Reduces the likelihood of inaccuracies and payroll nightmares',
      hideImage: true
    },
    systemFeatures: {
      items: [
        {
          title: 'Employee Masterlist',
          icon: 'faAddressBook'
        },
        {
          title: 'Withholding Tax',
          icon: 'faPercent'
        },
        {
          title: 'Mandatory Withholdings',
          icon: 'faPesoSign'
        },
        {
          title: 'Other Earnings and Deductions',
          icon: 'faPiggyBank'
        },
        {
          title: 'Payslip',
          icon: 'faFileInvoice'
        },
        {
          title: '13th Month Payroll',
          icon: 'faCalendar'
        },
        {
          title: 'Payroll Types (Weekly, Semi-Monthly, Monthly)',
          icon: 'faCalendarWeek'
        }
      ]
    }
  }
};
