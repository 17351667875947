import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  Checkbox,
  InputAdornment,
  IconButton,
  Collapse
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useAuth from './useAuth';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

const Login = () => {
  const {
    login,
    showPassword,
    setShowPassword,
    authError,
    clearError
  } = useAuth();

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        accept: false
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .max(255)
          .required('Username is required'),
        password: Yup.string()
          .max(255)
          .required('Password is required'),
        accept: Yup.bool().oneOf([true], 'You must accept the terms of service')
      })}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async values => {
        await login(values.username, values.password);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Collapse in={authError !== ''}>
            <Box mb={2}>
              <Alert severity="error" variant="filled" onClose={clearError}>
                {authError}
              </Alert>
            </Box>
          </Collapse>

          <TextField
            error={Boolean(touched.username && errors.username)}
            fullWidth
            autoFocus
            helperText={touched.username && errors.username}
            label="Username"
            margin="normal"
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.username}
            variant="outlined"
            disabled={isSubmitting}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password}
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            disabled={isSubmitting}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword(prev => !prev);
                    }}
                    size="small"
                    tabIndex={1}
                    disabled={isSubmitting}
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Box display="flex" alignItems="center">
            <Checkbox
              checked={values.accept}
              name="accept"
              type="checkbox"
              onChange={handleChange}
              size="small"
              disabled={isSubmitting}
            />
            <Typography
              variant="subtitle2"
              display="inline"
              color="textSecondary"
            >
              I agree with{' '}
              <Link href="/terms" target="_blank" tabIndex={1} underline="none">
                Terms of Service <OpenInNewIcon fontSize="inherit" />
              </Link>
            </Typography>
          </Box>
          {Boolean(touched.accept && errors.accept) ? (
            <Typography
              variant="caption"
              color="error"
              display="block"
              style={{ marginLeft: '1em' }}
            >
              {touched.accept && errors.accept}
            </Typography>
          ) : null}

          <Box mt={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {isSubmitting ? 'Logging in...' : 'Log In'}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Login;
