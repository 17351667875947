import React from 'react';
import jwt_decode from 'jwt-decode';
import { useSelector } from 'react-redux';

const AuthContext = React.createContext({
  app: false,
  portal: false,
  registry: false,
  require_change_pw: false
});

const isTokenInvalid = token =>
  !token || jwt_decode(token).exp < Date.now() / 1000;

const AuthProvider = ({ children }) => {
  const {
    userToken,
    loginToken,
    mustSelectBiz,
    isBizSelected,
    portal,
    require_change_pw
  } = useSelector(state => state.auth);

  const portalToken = portal?.token || null;

  const isValidLogin =
    !isTokenInvalid(userToken) && !isTokenInvalid(loginToken);

  const app =
    isValidLogin && (!mustSelectBiz || (mustSelectBiz && isBizSelected));
  const isPortal = !isTokenInvalid(portalToken);
  const registry = isValidLogin && mustSelectBiz && !isBizSelected;

  return (
    <AuthContext.Provider
      value={{ app, portal: isPortal, registry, require_change_pw }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
export { AuthProvider };
