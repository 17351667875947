/**
 *
 * @param {any} file
 * @param {(data: any[]) => void} handler
 */
function txtToJson(file, handler) {
  const fileReader = new FileReader();

  fileReader.onload = () => {
    const rows = fileReader.result.trim().split('\n');
    const parsedData = rows.map(row => row.trim().split(/\s+/));

    handler(parsedData);
  };

  fileReader.readAsText(file);
}

/**
 *
 * @param {any} file
 * @param {(data: any[]) => void} handler
 */
export function txtToJson2(file, handler) {
  const fileReader = new FileReader();

  fileReader.onload = () => {
    const rows = fileReader.result.trim().split('\n');
    handler(rows);
  };

  fileReader.readAsText(file);
}

export default txtToJson;
