import React from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import ContactNumberTextField from 'src/components/ContactNumberTextField';
import {
  changeUserInfo,
  register,
  changeCurrentForm
} from 'src/redux/actions/registration';

const phonePattern = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const AccountDetails = () => {
  const dispatch = useDispatch();
  const {
    user: { LName, FName, MName, SName, email, phone, uname, password },
    isCreatingAccount,
    errors: backendErrors
  } = useSelector(({ registrationReducer }) => registrationReducer);

  const hasBackendErrors = fld => {
    if (!backendErrors) return false;

    if (!backendErrors?.user?.validation_errors) return false;

    return backendErrors?.user?.validation_errors?.[fld];
  };

  const getBackendErrors = fld => {
    if (!backendErrors) return '';

    if (!backendErrors?.user?.validation_errors) return '';

    return backendErrors?.user?.validation_errors?.[fld]
      ?.map(data => data)
      .join('');
  };

  return (
    <>
      <Formik
        initialValues={{
          LName,
          FName,
          MName,
          SName,
          email,
          phone,
          uname,
          password,
          confirmPassword: password
        }}
        validationSchema={Yup.object().shape({
          LName: Yup.string()
            .max(50)
            .required('Last Name is required'),
          FName: Yup.string()
            .max(50)
            .required('First Name is required'),
          MName: Yup.string().max(50),
          SName: Yup.string().max(2),
          email: Yup.string()
            .email('Invalid email')
            .max(125)
            .required('Email is required'),
          phone: Yup.string()
            .required('Phone Number is required')
            .matches(phonePattern, 'Phone Number is required'),
          uname: Yup.string()
            .max(50)
            .required('Username is required'),
          password: Yup.string()
            .max(125)
            .min(8, 'Password must be at least 8 characters')
            .required('Password is required'),
          confirmPassword: Yup.string()
            .max(125)
            .min(8, 'Confirm Password must be at least 8 characters')
            .when('password', {
              is: val => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref('password')],
                'Both password need to be the same'
              )
            })
            .required('Confirm Password is required')
        })}
        onSubmit={(values, action) => {
          const { confirmPassword, ...rest } = values;

          dispatch(changeUserInfo(rest));
          dispatch(register());
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              error={Boolean(
                (touched.LName && errors.LName) || hasBackendErrors('LName')
              )}
              fullWidth
              helperText={
                (touched.LName && errors.LName) || getBackendErrors('LName')
              }
              label="Last Name"
              margin="normal"
              name="LName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="input"
              value={values.LName}
              variant="outlined"
            />

            <TextField
              error={Boolean(
                (touched.FName && errors.FName) || hasBackendErrors('FName')
              )}
              fullWidth
              helperText={
                (touched.FName && errors.FName) || getBackendErrors('FName')
              }
              label="First Name"
              margin="normal"
              name="FName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="input"
              value={values.FName}
              variant="outlined"
            />

            <TextField
              error={Boolean(
                (touched.MName && errors.MName) || hasBackendErrors('MName')
              )}
              fullWidth
              helperText={
                (touched.MName && errors.MName) || getBackendErrors('MName')
              }
              label="Middle Name"
              margin="normal"
              name="MName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="input"
              value={values.MName}
              variant="outlined"
            />
            <TextField
              error={Boolean(
                (touched.SName && errors.SName) || hasBackendErrors('SName')
              )}
              fullWidth
              helperText={
                (touched.SName && errors.SName) || getBackendErrors('SName')
              }
              label="Extension Name"
              margin="normal"
              name="SName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="input"
              value={values.SName}
              variant="outlined"
            />
            <TextField
              error={Boolean(
                (touched.email && errors.email) || hasBackendErrors('email')
              )}
              fullWidth
              helperText={
                (touched.email && errors.email) || getBackendErrors('email')
              }
              label="Email"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="input"
              value={values.email}
              variant="outlined"
            />

            <ContactNumberTextField
              error={Boolean(
                (touched.phone && errors.phone) || hasBackendErrors('phone')
              )}
              fullWidth
              helperText={
                (touched.phone && errors.phone) || getBackendErrors('phone')
              }
              label="Phone Number"
              margin="normal"
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              type="input"
              value={values.phone}
              variant="outlined"
            />

            <TextField
              error={Boolean(
                (touched.uname && errors.uname) || hasBackendErrors('username')
              )}
              fullWidth
              helperText={
                (touched.uname && errors.uname) || getBackendErrors('username')
              }
              label="Username"
              margin="normal"
              name="uname"
              onBlur={handleBlur}
              onChange={handleChange}
              type="input"
              value={values.uname}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              label="Confirm Password"
              margin="normal"
              name="confirmPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.confirmPassword}
              variant="outlined"
            />

            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button
                color="primary"
                variant="text"
                type="button"
                onClick={() => {
                  dispatch(changeCurrentForm(0));
                }}
              >
                Previous
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isCreatingAccount}
              >
                Create Account
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AccountDetails;
