import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import OtpInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';

import {
  updateTimer,
  resendCode,
  activateCode,
  activateAccountFailed as setActivateAccountFailed,
  resendOTPError as setResendOTPError
} from 'src/redux/actions/registration';

const inputStyle = {
  fontSize: '1.3rem',
  fontWeight: 600,
  width: '2rem',
  height: '2rem'
};

const containerStyle = {
  display: 'flex',
  gap: '1em',
  justifyContent: 'center',
  marginTop: '1em',
  marginBottom: '1em'
};

const errorStyle = {
  border: '.2rem solid red'
};

const ActivateAccount = () => {
  const dispatch = useDispatch();
  const {
    timer,
    isResendingOTP,
    isActivating,
    resendOTPError,
    activateError
  } = useSelector(({ registrationReducer }) => registrationReducer);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        dispatch(updateTimer(+timer - 1));
      }, 1000);
    }
  }, [timer]);

  const [OTP, setOTP] = useState('');

  const handleChange = otp => {
    setOTP(otp);
  };

  const handleResendCode = () => {
    dispatch(resendCode());
  };

  const handleActivate = () => {
    if (OTP.length !== 6) {
      return;
    }
    dispatch(activateCode(OTP));
  };

  const clear = () => {
    dispatch(setActivateAccountFailed(null));
    dispatch(setResendOTPError(null));
    setOTP('');
  };

  return (
    <Box my={2}>
      <Typography variant="h1" align="center">
        OTP Verification
      </Typography>

      <Typography variant="body2" color="textSecondary" align="center">
        Enter the verification code we just sent you on your mobile phone to
        activate your account.
      </Typography>

      <OtpInput
        value={OTP}
        onChange={handleChange}
        numInputs={6}
        separator=""
        isInputNum
        shouldAutoFocus
        containerStyle={containerStyle}
        inputStyle={inputStyle}
        isDisabled={isActivating}
        hasErrored={Boolean(activateError)}
        errorStyle={errorStyle}
      />

      {activateError && (
        <Box my={1}>
          <Typography
            variant="caption"
            display="block"
            color="error"
            align="center"
          >
            {activateError}
          </Typography>
        </Box>
      )}

      {resendOTPError && (
        <Box my={1}>
          <Typography
            variant="caption"
            display="block"
            color="error"
            align="center"
          >
            {resendOTPError}
          </Typography>
        </Box>
      )}

      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body2" color="textSecondary" align="center">
          Didn't you receive any code?
        </Typography>

        <Button
          color="primary"
          variant="text"
          onClick={handleResendCode}
          disabled={isResendingOTP || isActivating || timer > 0}
          size="small"
        >
          {timer > 0 ? `Resend (${timer})` : 'Resend'}
        </Button>
      </Box>

      <Box display="flex" justifyContent="center" mt={2} style={{ gap: '1em' }}>
        <Button color="primary" variant="text" onClick={clear}>
          Clear
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isActivating || OTP.length !== 6}
          onClick={handleActivate}
        >
          {isActivating ? 'Activating...' : 'Activate'}
        </Button>
      </Box>
    </Box>
  );
};

export default ActivateAccount;
