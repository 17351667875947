import { Drawer, Typography, Box } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import useToggle from 'src/hooks/useToggleV2';

const defaultContextValue = {
  openDrawer: () => {},
  closeDrawer: () => {},
  showInDrawer: () => {}
};

export const RightDrawerContext = React.createContext(defaultContextValue);

const RightDrawerProvider = ({ children }) => {
  const [isDrawerOpen, openDrawer, closeDrawer] = useToggle();
  const [drawerComponent, setDrawerComponent] = useState(null);

  function showInDrawer(component = null) {
    setDrawerComponent(component);
    openDrawer();
  }

  return (
    <RightDrawerContext.Provider
      value={{ openDrawer, closeDrawer, showInDrawer }}
    >
      <Drawer
        variant="temporary"
        anchor="right"
        onClose={closeDrawer}
        open={isDrawerOpen}
      >
        <Box width={400} p={1}>
          {drawerComponent}
        </Box>
      </Drawer>
      {children}
    </RightDrawerContext.Provider>
  );
};

export default RightDrawerProvider;
