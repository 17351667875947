import React, { forwardRef, useEffect, useCallback } from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import track from 'src/utils/analytics';
import { useSelector } from 'react-redux';

const Page = forwardRef(
  (
    { children, title = '', className = '', show_version = false, ...rest },
    ref
  ) => {
    const location = useLocation();
    const { backend_version } = useSelector(state => state.auth);
    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        page_path: location.pathname
      });
    }, []);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    return (
      <div ref={ref} className={className} {...rest}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
        {show_version && (
          <Container maxWidth="lg">
            <Box
              display="flex"
              justifyContent="flex-end"
              gridGap="1em"
              pt={2}
              px={1}
            >
              <Typography variant="caption" color="textSecondary">
                Version {process?.env?.REACT_APP_VERSION ?? ''}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Backend Version {backend_version}
              </Typography>
            </Box>
          </Container>
        )}
      </div>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  show_version: PropTypes.bool
};

export default Page;
