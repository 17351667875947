import { isEmpty, round, split } from "lodash";
import { evaluate } from "mathjs";
import { INVENTORY_TYPES } from "src/constants";

export const getParticularsAmount = particulars => {
  if (particulars.length === 0) return 0;

  return particulars
    .map(({ Amt }) => Amt.value)
    .reduce((prev, curr, indx) => Number(prev) + Number(curr));
};

export const validateForm = ({ data, excludes = [], allowZeroFlds = [] }) => {
  let newData = { ...data };
  let hasError = false;

  Object.entries(newData).forEach(([key, { require, value, ...rest }]) => {
    const isRequired = require || rest?.isRequired || rest?.required;

    if (
      !isRequired ||
      excludes.includes(key) ||
      (allowZeroFlds.includes(key) && value === 0)
    ) {
      return;
    }

    if (rest?.type === "json" && rest?.ui_component === "sub_acc") {
      const id = value?.id ?? "";
      const empty = id === "" || id === 0;

      if (empty) {
        newData[key].error = true;
        newData[key].errorMessage = "This field is required";
        hasError = true;
        return;
      }
    }

    const empty = value === 0 || value === "" || typeof value === "undefined";
    if (empty) {
      newData[key].error = true;
      newData[key].errorMessage = "This field is required";
      hasError = true;
      return;
    }
  });

  return { hasError, newData };
};

export const solveExpression = ({ expression = "", variables = {} }) => {
  const exp = [...expression];

  if (exp[0] !== "=") {
    return "Invalid Formula";
  }

  exp.splice(0, 1); // remove '='

  try {
    return evaluate(exp.join(""), variables);
  } catch (error) {
    return "Invalid formula";
  }
};

export const setSelfValueVariable = ({ value, formula = "" }) => {
  const selfVariables = ["cos"];
  let copyFormula = formula;

  for (let i = 0; i < selfVariables.length; i++) {
    const item = selfVariables[i];

    if (copyFormula.indexOf(item))
      copyFormula = copyFormula.replace(item, value);
  }

  return copyFormula;
};

export const recomputeJournalEntry = ({ journalEntries, variables }) => {
  let copyJournalEntries = [...journalEntries];

  let updatedJERows = [];

  if (copyJournalEntries.length === 0) return [];

  copyJournalEntries.forEach((item, i) => {
    // if no formula just get the bal
    if (item.Cr.formula === "" && item.Dr.formula === "") return;

    const amountType = item.Cr.formula && item.Cr.formula !== "" ? "Cr" : "Dr";

    const expression = setSelfValueVariable({
      value: item[amountType].value,
      formula: item[amountType].formula
    });

    //solve the formula
    const result = solveExpression({
      expression,
      variables
    });

    if (result === "Invalid formula") {
      copyJournalEntries[i][amountType].value = 0; // update the row
      copyJournalEntries[i][amountType].error = true; // update the row
      copyJournalEntries[i][amountType].errorMessage =
        "Invalid Formula : " + item[amountType].formula; // update the row

      return;
    }

    // if expression result is the same with stored amount just update the balance
    if (round(result, 4) === round(item[amountType].value, 4)) {
      copyJournalEntries[i][amountType].value = round(result, 4); // update the row
      return;
    }

    copyJournalEntries[i][amountType].value = round(result, 4); // update the row

    // create a copy of updated row
    let updatedRow = { ...item };
    updatedRow[amountType].value = round(result, 4);

    // push updated row
    updatedJERows.push({
      updatedRow,
      jeIndex: i
    });
  });

  return updatedJERows;
};

export const getKVSTableVariables = (value, kvs_key, settings) => {
  const obj = settings.columns.reduce((acc, { field, total }) => {
    if (total) {
      acc = {
        ...acc,
        [`jv_kvs_${kvs_key}_${field}`]: value.reduce(
          (t, p) => t + (p?.[field] ?? 0),
          0
        )
      };
    }
    return acc;
  }, {});

  const otherVars = value.reduce((acc, prev) => {
    if (Boolean(prev?.createVariable)) {
      acc = {
        ...acc,
        [`jv_kvs_${kvs_key}_var_${prev?.createVariable?.name}`]:
          prev?.[prev?.createVariable?.key] ?? 0
      };
    }
    return acc;
  }, {});

  return { ...obj, ...otherVars };
};

export const getKvsNumberValues = kvs => {
  let kvsNumberValues = {};

  Object.entries(kvs).forEach(([key, { type, value, ...rest }]) => {
    if (rest?.ui_component === "select" && type !== "int") {
      return;
    }

    if (rest?.ui_component === "table") {
      const kvsTableVariables = getKVSTableVariables(value, key, rest);
      kvsNumberValues = {
        ...kvsNumberValues,
        ...kvsTableVariables
      };
    }

    if (type === "int") {
      kvsNumberValues = {
        ...kvsNumberValues,
        [`jv_kvs_${key}`]: isNaN(value) ? 0 : Number(value)
      };
    }
  });

  return kvsNumberValues;
};

const getValueFromSubAccount = (key, subAccountDetails) => {
  const splittedKey = split(key, ".");

  if (splittedKey.length === 1)
    return subAccountDetails?.[splittedKey[0]] ?? "";

  if (splittedKey[0] === "kvs") {
    const kvs = subAccountDetails?.kvs ?? [];
    const keyIndex = kvs.findIndex(({ key }) => key === splittedKey[1]);

    return kvs?.[keyIndex]?.value ?? "";
  }

  return "";
};

export const saveSubAccountDetailsToKVS = ({
  settings = {},
  jvKVS = {},
  subAccountDetails = {}
}) => {
  let toUpdate = {};

  //! No settings
  if (isEmpty(settings)) return toUpdate;

  // * map_values => update kvs value where the source is sub account
  if (settings?.map_values) {
    Object.entries(settings?.map_values).forEach(([key, value]) => {
      //! key not found
      if (!Boolean(jvKVS[key])) return;

      toUpdate = {
        ...toUpdate,
        [key]: getValueFromSubAccount(value, subAccountDetails)
      };
    });
  }

  // * onchange_update_jv_kvs_values
  if (settings?.onchange_update_jv_kvs_values) {
    let updatedKvs = {};

    //* apply new updates
    Object.entries(jvKVS).forEach(([key, { value }]) => {
      updatedKvs = {
        ...updatedKvs,
        [key]: toUpdate?.[key] ?? value
      };
    });

    //* key => kvs key to update
    //* props.key => source key
    //* source_map

    Object.entries(settings?.onchange_update_jv_kvs_values).forEach(
      ([key, props]) => {
        //* if key to update not exist
        if (!updatedKvs.hasOwnProperty(key)) return;

        //* if source key not exist
        if (!props?.key) return;

        //* source map not exist
        if (!props?.source_map) return;

        // * create source
        let source = {};
        Object.entries(props.source_map).map(([key_source, value]) => {
          source = {
            ...source,
            [key_source]: updatedKvs?.[value] ?? ""
          };
        });

        //* get the key value
        const keyValue = updatedKvs?.[props.key] ?? "";

        toUpdate = {
          ...toUpdate,
          [key]: source?.[keyValue] ?? ""
        };
      }
    );
  }

  return toUpdate;
};

export const getInventoryVariables = ({
  inventory = [],
  invType = 0,
  amtType = "OUT"
}) => {
  const {
    invGrossAmt,
    invPriceIN,
    invPriceOUT,
    invTotalMU,
    invTotalDiscOUT,
    invTotalDisc,
    invTotalGrossAmt,
    invTotalGrossOfVAT,

    invTotalNonVAT,
    invTotalVatable,
    invTotalExempt,
    invTotalZeroRated,

    invNewPrice,
    invNewVatIN,
    invNewPriceTotalNonVAT,
    invNewPriceTotalVatable,
    invNewPriceTotalExempt,
    invNewPriceTotalZeroRated,
    invRebate,

    invAmt,
    invVatIN,
    invVatOUT,
    invTotalItems,
    invTotalQtyIN,
    invTotalQtyOUT,
    invTotalAmtIN,
    invTotalAmtOUT
  } = inventory.reduce(
    (acc, prev) => {
      const qty = parseFloat(+prev.qty.value),
        price = parseFloat(+prev.price.value),
        muAmt = parseFloat(+prev.muAmt),
        discOutrightAmt = parseFloat(+prev.discOutrightAmt),
        discAmt = parseFloat(+prev.discAmt),
        vatOUTAmt = parseFloat(+prev.vatOUTAmt),
        vatINAmt = parseFloat(+prev.vatINAmt),
        new_vatINAmt = parseFloat(+prev.new_vatINAmt),
        priceINAmt = parseFloat(+prev.priceIN) * qty,
        priceOUTAmt = parseFloat(+prev.priceOUT) * qty,
        newPriceAmt = parseFloat(+prev.new_price.value) * qty,
        grossAmt = qty * price,
        taxablePrice = parseFloat(+prev.taxablePrice),
        totalTaxableAmt = taxablePrice * qty,
        vatCode = prev.vatCode,
        qtyREC = prev.qtyREC,
        qtyACT = prev?.qtyACT?.value ?? 0,
        rebate = prev?.rebate ?? 0;
      let vatAmt = 0;

      if (prev.isDeleted) return acc;

      acc.invGrossAmt += grossAmt;
      acc.invTotalMU += muAmt * qty;
      acc.invTotalDiscOUT += discOutrightAmt * qty;
      acc.invTotalDisc += discAmt * qty;
      acc.invTotalGrossAmt += grossAmt;
      acc.invAmt +=
        grossAmt + muAmt * qty - discOutrightAmt * qty - discAmt * qty;

      acc.invTotalItems += qty;

      acc.invVatIN += vatINAmt;
      acc.invVatOUT += vatOUTAmt;

      acc.invPriceIN += priceINAmt;
      acc.invPriceOUT += priceOUTAmt;

      if (prev.qtyType === "IN") {
        if (invType === INVENTORY_TYPES.STOCK_ADJUSTMENT_BY_VARIANCE) {
          acc.invTotalQtyIN += qtyACT - qtyREC;
        } else acc.invTotalQtyIN += qty;

        acc.invTotalAmtIN += grossAmt;
      }

      if (prev.qtyType === "OUT") {
        if (invType === INVENTORY_TYPES.STOCK_ADJUSTMENT_BY_VARIANCE) {
          acc.invTotalQtyOUT += qtyREC - qtyACT;
        } else acc.invTotalQtyOUT += qty;

        acc.invTotalAmtOUT += grossAmt;
      }

      acc.invNewPrice += newPriceAmt;
      acc.invNewVatIN += new_vatINAmt;
      acc.invRebate += rebate;

      if (amtType === "OUT") vatAmt = vatOUTAmt;

      if (amtType === "IN") vatAmt = vatINAmt;

      acc.invTotalGrossOfVAT += totalTaxableAmt;
      if (vatCode === 0) acc.invTotalNonVAT += totalTaxableAmt;
      else if (vatCode === 1) acc.invTotalVatable += totalTaxableAmt - vatAmt;
      else if (vatCode === 2) acc.invTotalExempt += totalTaxableAmt;
      else if (vatCode === 3) acc.invTotalZeroRated += totalTaxableAmt;

      if (vatCode === 0) acc.invNewPriceTotalNonVAT += newPriceAmt;
      else if (vatCode === 1)
        acc.invNewPriceTotalVatable += newPriceAmt - new_vatINAmt;
      else if (vatCode === 2) acc.invNewPriceTotalExempt += newPriceAmt;
      else if (vatCode === 3) acc.invNewPriceTotalZeroRated += newPriceAmt;

      return acc;
    },
    {
      invGrossAmt: 0,
      invPriceIN: 0,
      invPriceOUT: 0,
      invTotalMU: 0,
      invTotalDiscOUT: 0,
      invTotalDisc: 0,
      invTotalGrossAmt: 0,
      invTotalGrossOfVAT: 0,

      invTotalNonVAT: 0,
      invTotalVatable: 0,
      invTotalExempt: 0,
      invTotalZeroRated: 0,

      invNewPrice: 0,
      invNewVatIN: 0,
      invNewPriceTotalNonVAT: 0,
      invNewPriceTotalVatable: 0,
      invNewPriceTotalExempt: 0,
      invNewPriceTotalZeroRated: 0,
      invRebate: 0,

      invAmt: 0,
      invVatIN: 0,
      invVatOUT: 0,
      invTotalItems: 0,
      invTotalQtyIN: 0,
      invTotalQtyOUT: 0,
      invTotalAmtIN: 0,
      invTotalAmtOUT: 0
    }
  );

  return {
    invGrossAmt: round(invGrossAmt, 4),
    invPriceIN: round(invPriceIN, 4),
    invPriceOUT: round(invPriceOUT, 4),
    invTotalMU: round(invTotalMU, 4),
    invTotalDiscOUT: round(invTotalDiscOUT, 4),
    invTotalDisc: round(invTotalDisc, 4),
    invTotalGrossAmt: round(invTotalGrossAmt, 4),
    invTotalGrossOfVAT: round(invTotalGrossOfVAT, 4),

    invTotalNonVAT: round(invTotalNonVAT, 4),
    invTotalVatable: round(invTotalVatable, 4),
    invTotalExempt: round(invTotalExempt, 4),
    invTotalZeroRated: round(invTotalZeroRated, 4),

    invNewPrice: round(invNewPrice, 4),
    invNewVatIN: round(invNewVatIN, 4),
    invNewPriceTotalNonVAT: round(invNewPriceTotalNonVAT, 4),
    invNewPriceTotalVatable: round(invNewPriceTotalVatable, 4),
    invNewPriceTotalExempt: round(invNewPriceTotalExempt, 4),
    invNewPriceTotalZeroRated: round(invNewPriceTotalZeroRated, 4),
    invRebate: round(invRebate, 4),

    invTotalAmtIN: round(invTotalAmtIN, 4),
    invTotalAmtOUT: round(invTotalAmtOUT, 4),

    invAmt: round(invAmt, 4),
    invVatIN: round(invVatIN, 4),
    invVatOUT: round(invVatOUT, 4),
    invTotalItems,
    invTotalQtyIN,
    invTotalQtyOUT
  };
};

export const getJournalEntryVariables = ({
  journalEntries = [],
  cos_acc_id = 0,
  mi_account = 0,
  isAbsolute = true
}) => {
  const {
    bal,
    totalDr,
    totalCr,
    jeVatIN,
    jeVatOUT,
    jeWtIN,
    jeWtOUT,
    jeVatWtAmt,
    jeCOS,
    jeMI,
    jeQtyAndRateTotal,
    jeTaxGross
  } = journalEntries.reduce(
    (acc, prev) => {
      const Dr = parseFloat(+prev.Dr.value);
      const Cr = parseFloat(+prev.Cr.value);
      const diff = Dr - Cr;

      const vatIN = parseFloat(+prev?.tax?.vatIN ?? 0);
      const vatOUT = parseFloat(+prev?.tax?.vatOUT ?? 0);
      const wtAmtIN = parseFloat(+prev?.tax?.wtAmtIN ?? 0);
      const wtAmtOUT = parseFloat(+prev?.tax?.wtAmtOUT ?? 0);
      const vatWTamt = parseFloat(+prev?.tax?.vatWTamt ?? 0);

      const reqQtyRate = +prev.reqQtyRate;
      const drQty = +prev.drQty;
      const crQty = +prev.crQty;
      const drRate = parseFloat(+prev.drRate);
      const crRate = parseFloat(+prev.crRate);
      const taxGross = parseFloat(+prev?.tax?.gross ?? 0);

      acc.totalDr += Dr;
      acc.totalCr += Cr;
      acc.bal += diff;

      acc.jeVatIN += vatIN;
      acc.jeVatOUT += vatOUT;
      acc.jeWtIN += wtAmtIN;
      acc.jeWtOUT += wtAmtOUT;
      acc.jeVatWtAmt += vatWTamt;
      acc.jeTaxGross += taxGross;

      if (+prev.ixAcc.value === +cos_acc_id) acc.jeCOS += Dr;

      if (+prev.ixAcc.value === +mi_account) acc.jeMI += Cr;

      if (reqQtyRate === 1) acc.jeQtyAndRateTotal += drRate * drQty;

      if (reqQtyRate === 2) acc.jeQtyAndRateTotal += crRate * crQty;

      return acc;
    },
    {
      bal: 0,
      totalDr: 0,
      totalCr: 0,
      jeVatIN: 0,
      jeVatOUT: 0,
      jeWtIN: 0,
      jeWtOUT: 0,
      jeVatWtAmt: 0,
      jeCOS: 0,
      jeMI: 0,
      jeQtyAndRateTotal: 0,
      jeTaxGross: 0
    }
  );

  return {
    bal: round(isAbsolute ? Math.abs(bal) : bal, 4),
    totalDr: round(totalDr, 4),
    totalCr: round(totalCr, 4),
    jeVatIN: round(jeVatIN, 4),
    jeVatOUT: round(jeVatOUT, 4),
    jeWtIN: round(jeWtIN, 4),
    jeWtOUT: round(jeWtOUT, 4),
    jeVatWtAmt: round(jeVatWtAmt, 4),
    jeCOS: round(jeCOS, 4),
    jeMI: round(jeMI, 4),
    jeQtyAndRateTotal: round(jeQtyAndRateTotal, 4),
    jeTaxGross: round(jeTaxGross, 4)
  };
};

export function fillSubAccounts(ref, subDetails, refKey = "") {
  if (refKey === "") return;

  for (let i = 1; i <= 5; i++) {
    if (!Boolean(ref[`${refKey}${i}`])) continue;

    for (let j = 0; j <= 5; j++) {
      let ixSubLinkType = 0,
        ixSubLink = 0,
        sSubLink = "";

      if (j === 0) {
        ixSubLinkType = subDetails?.["ixSubParentType"] ?? 0;
        ixSubLink = subDetails?.["ixSubParent"] ?? 0;
        sSubLink = subDetails?.["sSubParent"] ?? "";
      } else {
        ixSubLinkType = subDetails?.["ixSubLinkType" + j] ?? 0;
        ixSubLink = subDetails?.["ixSubLink" + j] ?? 0;
        sSubLink = subDetails?.["sSubLink" + j] ?? "";
      }

      if (
        ixSubLinkType !== 0 &&
        ixSubLinkType === ref[`${refKey}${i}`].ixSubType &&
        (ref[`${refKey}${i}`].sub_id === 0 ||
          ref[`${refKey}${i}`].sub_id === "") &&
        ref[`${refKey}${i}`].sub_id !== ixSubLink
      ) {
        ref[`${refKey}${i}`].sub_id = ixSubLink;
        ref[`${refKey}${i}`].value = ixSubLink;
        ref[`${refKey}${i}`].sub_title = sSubLink;
        ref[`${refKey}${i}`].error = false;
        ref[`${refKey}${i}`].errorMessage = "";
      }
    }
  }
}
