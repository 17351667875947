import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  ixAcc: 0,
  sAcc: '',
  ixSubType1: 0,
  sSubType1: '',
  ixBrch: 0,
  dt1: null,
  dt2: null,
  textValue: '',
  page: 0,
  limit: 10,
  searchQuery: '',
  data: {
    tBeg: 0,
    tEnd: 0,
    tDr: 0,
    tCr: 0,
    rep: []
  }
};

const slice = createSlice({
  name: 'slSubBalances',
  initialState: INITIAL_STATE,
  reducers: {
    onChangeAccount(state, action) {
      state.ixSubType1 = action.payload.ixSubType1;
      state.sSubType1 = action.payload.sSubType1;
      state.ixAcc = action.payload.ixAcc;
      state.sAcc = action.payload.sAcc;
    },
    onChangeDateRange(state, action) {
      state.dt1 = action.payload.dt1;
      state.dt2 = action.payload.dt2;
      state.textValue = action.payload.textValue;
    },
    onChangeBrch(state, action) {
      state.ixBrch = action.payload;
    },
    loadReportData(state, action) {
      return {
        ...state,
        data: action.payload
      };
    },
    onChangeLimit(state, action) {
      state.limit = action.payload;
    },
    onChangePage(state, action) {
      state.page = action.payload;
    },
    onChangeSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    },
    clearReport(state, _) {
      return {
        ...state,
        data: INITIAL_STATE.data
      };
    }
  }
});

const slSubBalances = slice.reducer;

export const {
  onChangeAccount,
  onChangeDateRange,
  onChangeBrch,
  loadReportData,
  onChangeLimit,
  onChangePage,
  onChangeSearchQuery,
  clearReport,
  restore,
  clear
} = slice.actions;
export default slSubBalances;
