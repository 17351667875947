import React from 'react';

import {
  Box,
  Button,
  makeStyles,
  Container,
  Typography
} from '@material-ui/core';

import { NavLink } from 'react-router-dom';
import useReveal, { Animated } from 'src/hooks/useReveal';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#1976D2',
    paddingTop: 60,
    paddingBottom: 60
  },
  bookDemo: {
    background: '#FF7704',
    color: '#fff',
    '&:hover': {
      background: '#FF7704',
      color: '#fff'
    }
  },
  back: {
    background: '#FF7704',
    color: '#fff',
    '&:hover': {
      background: '#FF7704',
      color: '#fff'
    }
  },
  buttonContainer: {
    display: 'flex',
    gap: '1em',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  header: {
    color: '#fff',
    marginBottom: '1em',
    fontSize: '2rem',
    fontWeight: 900,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}));

const ActionButtons = () => {
  const classes = useStyles();
  const [ref, reveal] = useReveal({ threshold: 0.1 });
  return (
    <div className={classes.root} ref={ref}>
      <Container maxWidth="lg">
        <Animated
          to={{
            opacity: reveal ? 1 : 0,
            y: reveal ? 0 : -50
          }}
          config={{
            duration: 1000
          }}
        >
          <Typography variant="h1" className={classes.header}>
            Ready to bring your business to the cloud?
          </Typography>
        </Animated>
        <Animated
          to={{
            opacity: reveal ? 1 : 0,
            y: reveal ? 0 : 50
          }}
          config={{
            duration: 1000
          }}
        >
          <Box className={classes.buttonContainer}>
            <Button
              component={NavLink}
              to={'/app/register'}
              variant="contained"
              size="large"
              className={classes.bookDemo}
            >
              Get Started
            </Button>
            <Button
              component={NavLink}
              to="/contact-us"
              variant="contained"
              size="large"
              className={classes.back}
            >
              Contact Us
            </Button>
          </Box>
        </Animated>
      </Container>
    </div>
  );
};

export default ActionButtons;
