import { createSlice } from '@reduxjs/toolkit';
import { Request } from 'src/helpers';
const INITIAL_STATE = {
  meta: {},
  ixAcc: 0,
  sAccTitle: '',
  ixRC: 0,
  sRC: '',
  data: {
    items: [],
    totals: {
      appropTotal: 0,
      allotTotal: 0,
      obligTotal: 0,
      dvTotal: 0
    }
  },
  page: 0,
  limit: 25,
  graphData: {
    datasets: [],
    labels: []
  },
  dateRange: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  ixBrch: 0,
  ixExpCls: 0,
  group: false
};

const slice = createSlice({
  name: 'budgetLedger',
  initialState: INITIAL_STATE,
  reducers: {
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setAccount(state, action) {
      state.ixAcc = action.payload.ixAcc;
      state.sAccTitle = action.payload.sAccTitle;
      state.ixExpCls = action.payload.ixExpCls;
    },
    setRC(state, action) {
      state.ixRC = action.payload.ixSub;
      state.sRC = action.payload.sSub;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setGraphData(state, action) {
      state.graphData = action.payload;
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setBranch(state, action) {
      state.ixBrch = action.payload;
    },
    setExpCls(state, action) {
      state.ixExpCls = action.payload;
    },
    clearData(state, action) {
      state.data = {
        items: [],
        totals: {
          appropTotal: 0,
          allotTotal: 0,
          obligTotal: 0,
          dvTotal: 0
        }
      };
      state.graphData = {
        datasets: [],
        labels: []
      };
    },
    setLimit(state, action) {
      state.limit = action.payload;
      state.page = 0;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setGroup(state, action) {
      state.group = action.payload;
    },
    setParams(state, action) {
      state.dateRange.dt1 = action.payload.dt1;
      state.dateRange.dt2 = action.payload.dt2;
      state.dateRange.textValue = action.payload.sDate;
      state.ixAcc = parseInt(action.payload.ixAcc);
      state.sAccTitle = action.payload.sAcc;
      state.ixBrch = parseInt(action.payload.ixBrch);
      state.ixRC = parseInt(action.payload.ixRC);
      state.sRC = action.payload.sRC;
      state.ixExpCls = parseInt(action.payload.ixExpCls);

      return state;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    }
  }
});

export const setURLparams = urlParams => async (dispatch, getState) => {
  const { accounts } = getState().storedValues;
  const { base_url, userToken } = getState().auth;
  const req = new Request(base_url, {
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    }
  });



  const { setParams } = slice.actions;
  const params = {
    ixAcc: urlParams?.ixAcc ?? 0,
    sAcc: urlParams?.sAcc ?? '',
    ixRC: urlParams?.ixRC ?? 0,
    sRC: urlParams?.sRC ?? '',
    ixBrch: urlParams?.ixBrch ?? 0,
    ixExpCls: urlParams?.ixExpCls ?? 0,
    dt1: getState().budgetLedger.dateRange.dt1,
    dt2: getState().budgetLedger.dateRange.dt2,
    sDate: getState().budgetLedger.dateRange.textValue
  };
  const acc = accounts.find(account => +account.ixAcc === +urlParams.ixAcc);

  if (acc) {
    params.ixAcc = acc.ixAcc;
    params.sAcc = acc.sAccTitle;
    params.ixExpCls = acc.ixExpCls;
  }

  if (!urlParams?.sRC) {
    const { data, success } = await req.get(`/lib/sub/${urlParams?.ixRC ?? 0}`);
    if (success) {
      params.ixRC = data.ixSub;
      params.sRC = data.sSub;
    }
  }

  if (urlParams?.dt1 && urlParams?.dt1 && urlParams?.sDate) {
    params.dt1 = urlParams.dt1;
    params.dt2 = urlParams.dt2;
    params.sDate = urlParams.sDate;
  }

  dispatch(setParams(params));

  return params;
};

const budgetLedgerReducer = slice.reducer;
export const {
  setMeta,
  setData,
  setGraphData,
  setDateRange,
  setBranch,
  clearData,
  setAccount,
  setExpCls,
  setRC,
  setLimit,
  setPage,
  setGroup,
  setParams,
  restore,
  clear
} = slice.actions;
export default budgetLedgerReducer;
