import { useState } from 'react';

/**
 * @function a custom hook where you can utilize for menu
 * @returns {[Element, (event: Event) => void, VoidFunction]}
 */
const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   *
   * @param {Event} event
   */
  function open(event) {
    setAnchorEl(event.currentTarget);
  }

  function close() {
    setAnchorEl(null);
  }

  return [anchorEl, open, close];
};

export default useMenu;
