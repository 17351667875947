import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  InputAdornment,
  IconButton,
  TextField,
  makeStyles,
  Divider,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import moment from 'moment-timezone';
import { red } from '@material-ui/core/colors';
import {
  getDateValues,
  getTypeByRange,
  incrementOrDecrementByType
} from './utils';
import StyledTab from 'src/components/StyledTab';
import { useStylesTextSeach } from './SrcDate.styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faCalendarDays
} from '@fortawesome/free-regular-svg-icons';
import {
  faCalendarDay,
  faCalendarWeek,
  faTableCellsLarge
} from '@fortawesome/free-solid-svg-icons';
import ClearIcon from '@material-ui/icons/Clear';

//Dates
import Day from './Day';
import Month from './Month';
import Year from './Year';
import Range from './Range';
import Quarterly from './Quarterly';
import { NavigateBefore, NavigateNext, Cancel } from '@material-ui/icons';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  tabs: {
    '& .MuiTab-root': {
      marginRight: 0
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  }
});

const tabList = [
  {
    id: 1,
    title: 'Day',
    type: 'day',
    icon: <FontAwesomeIcon icon={faCalendarDay} />,
    picker: Day
  },
  {
    id: 2,
    title: 'Month',
    type: 'month',
    icon: <FontAwesomeIcon icon={faCalendarDays} />,
    picker: Month
  },
  {
    id: 4,
    title: 'Quarter',
    type: 'quarter',
    icon: <FontAwesomeIcon icon={faTableCellsLarge} />,
    picker: Quarterly
  },
  {
    id: 3,
    title: 'Year',
    type: 'year',
    icon: <FontAwesomeIcon icon={faCalendar} />,
    picker: Year
  },
  {
    id: 5,
    title: 'Range',
    type: 'range',
    icon: <FontAwesomeIcon icon={faCalendarWeek} />,
    picker: Range
  }
];

export const getDatePickerComponent = ({ type, ...data }) => {
  return {
    day: <Day {...data} />,
    month: <Month {...data} />,
    year: <Year {...data} />,
    range: <Range {...data} />,
    quarter: <Quarterly {...data} />
  }[type];
};

const DatePicker = ({
  type = 'day', // day, month,year,quarter,range
  values = {
    dt1: null,
    dt2: null,
    textValue: '',
    lastUpdate: ''
  },
  prefixText = '', // for , as of etc
  defaultDateRangePicker = '', // range, month ,day,year
  onChange = () => null,
  disabled = false,
  datePickers = ['day', 'month', 'year', 'quarter', 'range'],
  size = 'medium',
  clearable = false,
  ...props
}) => {
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const { frontEnd } = useSelector(({ bizMeta }) => bizMeta);

  // HOOKS
  const classNames = useStyles();
  const classes = useStylesTextSeach();

  const currentType = getTypeByRange(values.dt1, values.dt2);
  const tabs = tabList.filter(item => datePickers.includes(item.type));

  // STATES
  const [activeTab, setActiveTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  // METHODS
  const updateActiveTab = (e, val) => setActiveTab(val);

  // moment format() automatically adds timezone ->  https://momentjs.com/
  // ISO8601 format YYYY-MM-DDTHH:mm:ssZ -> https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
  const formatDatesWithTZ = values => {
    const tz = frontEnd?.tz ?? '',
      dt1 = moment(values.dt1),
      dt2 = moment(values.dt2);

    if (tz !== '') {
      dt1.tz(tz);
      dt2.tz(tz);
    }

    return {
      dt1: dt1.format(),
      dt2: dt2.format(),
      textValue: values.textValue,
      lastUpdate: moment().format()
    };
  };

  const handleChangeDatePickerValue = values => {
    onChange(formatDatesWithTZ(values));
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getDatePicker = props => {
    const Picker = tabs[activeTab].picker;
    return <Picker {...props} />;
  };

  function next(event) {
    event.stopPropagation();
    onChange(
      formatDatesWithTZ(
        incrementOrDecrementByType(values, currentType, 'increment')
      )
    );
  }

  function prev(event) {
    event.stopPropagation();
    onChange(
      formatDatesWithTZ(
        incrementOrDecrementByType(values, currentType, 'decrement')
      )
    );
  }

  // USE_EFFECTS
  useEffect(() => {
    if (type === 'range' && defaultDateRangePicker !== '')
      type = defaultDateRangePicker;

    const index = tabs.findIndex(item => item.type === type);
    setActiveTab(index !== -1 ? index : 0);

    if (
      ((!values.dt1 || !values.dt2) && !clearable) ||
      (values.lastUpdate && moment().diff(values.lastUpdate, 'days') >= 7)
    ) {
      const defaultValues = getDateValues[type]({
        values: {
          dt1: moment().format('YYYY-MM-DD'),
          dt2: moment().format('YYYY-MM-DD')
        },
        prefixText: prefixText === '' ? type : prefixText
      });

      onChange(formatDatesWithTZ(defaultValues));
    }
  }, [defaultDateRangePicker, values, type, values.lastUpdate]);

  return (
    <Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        fullScreen={onMobile}
      >
        <StyledTab
          tabs={tabs}
          activeTab={activeTab}
          onChange={updateActiveTab}
          className={classNames.tabs}
        />
        <Divider />
        <Box>
          {getDatePicker({
            handleChangeDatePickerValue,
            handleCloseDialog,
            dateValues: values,
            defaultDateRangePicker,
            defaultDatePicker: type,
            prefixText
          })}
        </Box>
      </Dialog>

      <TextField
        className={classes.input}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="search"
                className={classes.iconButton}
                onClick={handleOpenDialog}
                disabled={disabled}
                size={size}
              >
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {clearable && (
                <InputAdornment position="end">
                  <Tooltip title="Clear">
                    <IconButton
                      aria-label="clear"
                      size="small"
                      onClick={e => {
                        e.stopPropagation();
                        onChange({
                          dt1: null,
                          dt2: null,
                          textValue: ''
                        });
                      }}
                      disabled={disabled}
                    >
                      <Cancel color="primary" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )}

              <InputAdornment position="end">
                <Tooltip title={'Prev ' + capitalize(currentType)}>
                  <span>
                    <IconButton
                      onClick={prev}
                      size="small"
                      disabled={disabled || !currentType}
                      color="primary"
                    >
                      <NavigateBefore />
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
              <InputAdornment position="start">
                <Tooltip title={'Next ' + capitalize(currentType)}>
                  <span>
                    <IconButton
                      onClick={next}
                      size="small"
                      disabled={disabled || !currentType}
                      color="primary"
                    >
                      <NavigateNext />
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            </>
          ),
          classes: {
            adornedStart: classes.adornStart,
            adornedEnd: classes.adornEnd
          }
        }}
        onClick={handleOpenDialog}
        value={values?.textValue || ''}
        placeholder="Please set a date"
        variant="outlined"
        disabled={disabled}
        size={size}
        {...props}
      />
    </Box>
  );
};

export { DatePicker };
export default DatePicker;
