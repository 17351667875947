import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Paper,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 60,
    paddingBottom: 60,
    [theme.breakpoints.down('md')]: {
      paddingTop: 40,
      paddingBottom: 40
    }
  },

  title: {
    fontSize: '2.1em',
    marginBottom: '.5em',
    position: 'relative',

    fontWeight: 900,
    '&:before': {
      content: '',
      display: 'block',
      position: 'absolute',
      top: 0,
      width: '50%',
      left: '25%',
      borderBottom: '3em solid red'
    }
  },
  description: {},
  container: {
    height: '100%'
  },
  illustrator: {
    // height: '80%',
    // minHeight: '340px'
    height: 600
  }
}));

const MissionVision = ({ className, onClickGetStarted, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item sm={12} lg={6}>
            <Box display="flex" justifyContent="center" alignContent="center">
              <img
                alt="image"
                src="/static/images/mission-vision.svg"
                className={classes.illustrator}
              />
            </Box>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Paper component={Box} p={4} mb={4}>
              <Box display="flex" justifyContent="space-between">
                <Typography className={classes.title}>Mission</Typography>
              </Box>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.description}
              >
                Radztech Business Solutions provides technology-integrated
                accounting solutions aiming to help our clients improve their
                business value.
              </Typography>
            </Paper>

            <Paper component={Box} p={4}>
              <Box display="flex" justifyContent="space-between">
                <Typography className={classes.title}>Vision</Typography>
              </Box>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.description}
              >
                The country's leading partner in technology-integrated
                accounting innovations.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

MissionVision.propTypes = {
  className: PropTypes.string
};

export default MissionVision;
