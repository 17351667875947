import moment from 'moment';

export function formatTimeToInt(time) {
  return time ? parseInt(moment(time, 'HH:mm').format('HHmm')) : 0;
}

export function formatDateToInt(date) {
  return parseInt(moment(date).format('YYYYMMDD'));
}

export function formatDateToStr(date) {
  const strDate = date.toString();

  return (
    strDate.slice(0, 4) + '-' + strDate.slice(4, 6) + '-' + strDate.slice(6)
  );
}

export function formatTimeToStr(time = 0, format = 'HH:mm') {
  if (!time) return '';

  let strTime = time.toString();

  if (time < 100) strTime = '00' + strTime;
  else if (time < 1000) strTime = '0' + strTime;

  return moment(strTime, 'HHmm').format(format);
}

export function intTimeToMoment(time = 0) {
  let strTime = time.toString();

  if (time < 10) strTime = '000' + strTime;
  else if (time < 100) strTime = '00' + strTime;
  else if (time < 1000) strTime = '0' + strTime;

  return moment(strTime, 'HHmm');
}

export function formatDateToStrWithDay(date) {
  const formattedDate = formatDateToStr(date);
  return moment(formattedDate).format('YYYY-MM-DD (ddd)');
}

/**
 *
 * @param {number} serial
 * @returns
 */
export function excelDateTimeToJSDate(serial) {
  // Excel date serial number begins from January 1, 1900 (or 1904).
  // JavaScript date epoch begins from January 1, 1970.

  // Excel's epoch starts from December 30, 1899, 00:00:00 UTC
  const date = new Date(Date.UTC(1899, 11, 30, 0, 0, 0));

  const days = Math.floor(serial);

  // Convert fraction of a day to milliseconds
  const milliseconds = Math.round((serial - days) * 86400000);

  // Add the number of days to the date
  date.setDate(date.getUTCDate() + days);

  // Add milliseconds for the time part
  date.setUTCMilliseconds(date.getUTCMilliseconds() + milliseconds);

  return date;
}
