import { createSlice } from '@reduxjs/toolkit';
import { APIRequestV2, cancelAPIRequest } from 'src/helpers/APIRequest';

const INITIAL_STATE = {
  loading: false,
  error: false,
  errorMessages: '',
  data: null,
  selectedReport: 0,
  ixBrch: 0
};

const slice = createSlice({
  name: 'imbal',
  initialState: INITIAL_STATE,
  reducers: {
    onFetchReport(state, action) {
      state.loading = true;
    },
    loadData(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    loadError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessages = action.payload;
    },

    cancelRequest(state, action) {
      state.loading = false;
    },
    clear(state, action) {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

export const fetchImbalanceReport = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const { loadData, loadError, onFetchReport } = slice.actions;
  dispatch(onFetchReport());

  const { data, success, isCancelled } = await APIRequestV2({
    method: 'POST',
    url: `${base_url}/reports/fs/imbal`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    }
  });

  if (!success && isCancelled) return;
  else if (!success) dispatch(loadError('Something went wrong.'));
  else dispatch(loadData(data));
};

export const cancelRequest = () => (dispatch, getState) => {
  const action = slice.actions;

  if (cancelAPIRequest) cancelAPIRequest();
  dispatch(action.cancelRequest());
};

const imbalReducer = slice.reducer;
export const {
  restore,
  loadData,
  loadError,
  onFetchReport,
  clear
} = slice.actions;
export default imbalReducer;
