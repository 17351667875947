import { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filterListByQuery from 'src/helpers/filterListByQuery';
import useRequest from 'src/hooks/useRequest';
import {
  setProductCategories,
  setProducts,
  setProductSubCategories
} from 'src/redux/slices/storedValues';

export default function useProdSearch(props) {
  const dispatch = useDispatch();
  const request = useRequest();

  const {
    productCategories = [],
    productSubCategories: allProductSubCategories = {},
    products: allProducts = []
  } = useSelector(state => state.storedValues);

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [query, setQuery] = useState('');
  const [productCategory, setProductCategory] = useState(0);
  const [productSubCategory, setProductSubCategory] = useState(0);
  const prodCategories = props?.filters?.productCategories ?? [];

  const filteredProductCategories = productCategories.filter(item => {
    if (prodCategories.length === 0) return true;
    return prodCategories.includes(item.ixProdCat);
  });
  const productSubCategories = allProductSubCategories?.[productCategory] || [];
  const products = useMemo(() => {
    return filterListByQuery(
      query,
      allProducts
        .filter(({ ixCat }) => {
          if (prodCategories.length === 0) return true;
          return prodCategories.includes(ixCat);
        })
        .filter(product => {
          if (!props.showInactiveProd && product.prodStatus === 0) return false;

          return (
            (productCategory === 0 || product.ixCat === productCategory) &&
            (productSubCategory === 0 ||
              product.ixCatSub === productSubCategory)
          );
        })
    );
  }, [allProducts, productCategory, productSubCategory, query]);

  async function getProductCategories() {
    const res = await request.get('/lib/prod/cat');

    if (res.success) {
      setHasError(false);
      dispatch(setProductCategories(res.data));
    } else setHasError(true);
  }

  async function getProductSubCategories() {
    if (!productCategory) return;

    setLoading(true);
    const res = await request.get(
      '/lib/prod/cat/sub?ixProdCat=' + productCategory
    );
    setLoading(false);

    if (res.success) {
      dispatch(setProductSubCategories({ [productCategory]: res.data }));
      setHasError(false);
    } else setHasError(true);
  }

  async function getProducts() {
    setLoading(true);
    let page = 1;
    let prodTemp = [];

    while (true) {
      const res = await request.post(`/lib/prod`, {
        page,
        limit: 1000
      });

      if (res.success) {
        const items = res.data?.items || [];
        prodTemp = [...prodTemp, ...items];

        if (items.length < 1000) {
          dispatch(setProducts(prodTemp));
          setLoading(false);
          break;
        }
      } else {
        dispatch(setProducts(prodTemp));
        setLoading(false);
        break;
      }

      page++;
    }
  }

  function refresh() {
    getProducts();
    getProductCategories();
  }

  useEffect(() => {
    if (productCategory in allProductSubCategories) return;
    getProductSubCategories();
  }, [productCategory]);

  useEffect(() => {
    if (products.length === 0) getProducts();
    if (productCategories.length === 0) getProductCategories();
  }, []);

  return {
    loading,
    hasError,
    setProductCategory,
    setProductSubCategory,
    query,
    setQuery,
    products,
    productCategory,
    productSubCategory,
    productCategories: filteredProductCategories,
    productSubCategories,
    refresh
  };
}
