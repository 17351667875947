import { colors } from '@material-ui/core';
import { softShadows } from '../shadows';
import { baseOptions } from '..';
import { IBFRS_COLORS } from 'src/constants';

const {
  GREEN_BLUE,
  DARK_BLUE,
  DARK_GREEN,
  BLUE,
  BLUE_DISABLED,
  LIGHT_GREEN,
  WHITE
} = IBFRS_COLORS;

const IBFRS_THEME = {
  ...baseOptions,
  overrides: {
    MuiInputBase: {
      root: {
        '&$disabled': {
          color: BLUE_DISABLED
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: 50,
        textTransform: 'none'
      },
      contained: {
        // background: `linear-gradient(to right, ${DARK_BLUE}, ${BLUE})`,
        background: `linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%)`
      }
    },
    MuiFormLabel: {
      root: {
        color: DARK_BLUE,
        '&$disabled': {
          color: BLUE_DISABLED
        }
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'rgba(0,0,0,0.25)'
      },
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '#FFF',
          WebkitTextFillColor: DARK_BLUE
        }
      }
    },
    MuiCheckbox: {
      root: {
        color: DARK_BLUE
      },
      colorSecondary: {
        color: DARK_BLUE,
        '&.Mui-checked': {
          color: DARK_BLUE
        }
      }
    }
  },
  // typography: {
  //   fontSize: 12,
  //   fontFamily: `'Montserrat', sans-serif`,
  //   fontWeightBold: 900,
  //   fontWeightMedium: 700,
  //   fontWeightRegular: 400
  // },
  palette: {
    type: 'dark',
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(255, 255, 255, 0.04)',
      selected: 'rgba(255, 255, 255, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.10)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      focus: 'rgba(255, 255, 255, 0.12)'
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF',
      navbar: `linear-gradient(to right, ${GREEN_BLUE}, ${BLUE})`,
      greenGradient: `linear-gradient(to right, ${DARK_GREEN}, ${LIGHT_GREEN})`,
      blueGradient: `linear-gradient(to right, ${GREEN_BLUE}, ${BLUE})`
    },
    primary: {
      // main: DARK_BLUE,
      main: '#4b8ef1'
    },
    secondary: {
      main: WHITE
    },
    text: {
      // primary: DARK_BLUE,
      primary: '#4b8ef1',
      secondary: 'rgba(0,0,0,0.75)'
    }
  },
  shadows: softShadows
};

export default IBFRS_THEME;
