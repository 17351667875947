import React, { useState } from 'react';
import {
  TableContainer as Container,
  Table as MuiTable,
  TableHead as Head,
  TableBody as Body,
  TableRow as Row,
  TableCell as Cell,
  useTheme,
  Box,
  Divider,
  InputAdornment,
  Button,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { useStyles } from './hooks';
import { getCellValue, generateTotals, generateExportableData } from './utils';
import DebounceTextField from '../DebounceTextField';
import { CloudDownload, Search } from '@material-ui/icons';
import { filterListByQuery } from 'src/helpers';
import { jsonToExcel } from 'src/utils';
import { TblFilter } from './components';
import { escapeRegExp } from 'lodash';

const applySticky = data => {
  const lastStickyIndex = data.findIndex(item => item.sticky);
  if (lastStickyIndex === -1) return data;

  return data.map((item, index) => ({
    ...item,
    sticky: index <= lastStickyIndex || item.sticky
  }));
};

function filterTableData(data = [], filters = {}, searchFilter = '') {
  const searchRegEx = new RegExp(escapeRegExp(searchFilter), 'i');

  return data.filter(item => {
    for (const key of Object.keys(filters)) {
      if (filters[key] !== 'all' && item[key] !== filters[key]) {
        return false;
      }
    }

    return Object.keys(item).some(field =>
      searchRegEx.test((item?.[field] || '').toString())
    );
  });
}

/**
 *
 * @param {object} param
 * @param {column[]} param.columns
 * @param {string} param.rowIDKey
 * @param {any[]} param.data
 * @param {string[]} param.totals
 * @param {{show:boolean,fields:string[]}} param.search
 * @param {{show:boolean,fields:string[],prepend:string[],showNo:boolean,includeTotals:boolean}} param.dataExport
 * @param {string} param.title
 * @param {{left: React.JSX.Element, right: React.JSX.Element}} param.toolbar
 * @param {boolean} param.isLoading
 * @returns
 */
const Table = ({
  columns = [],
  data = [],
  rowIDKey = 'id',
  totals = [],
  search = { show: true, fields: [] },
  dataExport = {
    show: true,
    fields: [],
    prepend: [],
    showNo: false,
    includeTotals: false
  },
  title = '',
  toolbar = {
    left: null,
    right: null
  },
  isLoading = false,
  maxHeight = '100%'
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const tableFilters = columns.filter(col => col?.filter?.allow);

  /**
   * @type {column[]}
   */
  const shownColumns = applySticky(columns.filter(col => !col?.hide));
  const columnsTotals = generateTotals(totals, data);

  const [searchQuery, setSearchQuery] = useState('');
  const [filterValues, setFilterValues] = useState({});

  function updateFilters(name, value) {
    setFilterValues(state => ({ ...state, [name]: value }));
  }

  const shownData = filterTableData(data, filterValues, searchQuery);

  function exportData() {
    jsonToExcel({
      data: [
        {
          data: generateExportableData(data, columns, {
            showNo: dataExport?.showNo || false,
            includeTotals: dataExport?.includeTotals || false,
            totals: columnsTotals
          }),
          options: { skipHeader: true }
        }
      ],
      fileName: title
    });
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
          {search.show && (
            <DebounceTextField
              variant="outlined"
              size="small"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          )}
          {toolbar.left}
        </Box>
        <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
          {toolbar.right}
          {dataExport.show && (
            <Button
              size="small"
              startIcon={<CloudDownload />}
              variant="contained"
              color="primary"
              onClick={exportData}
            >
              Export
            </Button>
          )}
        </Box>
      </Box>
      {isLoading && <LinearProgress style={{ height: 2 }} />}
      <Divider />
      {Boolean(tableFilters?.length) && (
        <>
          <TblFilter
            filters={tableFilters}
            filterValues={filterValues}
            updateFilters={updateFilters}
            data={data}
          />
          <Divider />
        </>
      )}
      <Container style={{ maxHeight }}>
        <MuiTable style={{ border: 'none' }}>
          <Head>
            <Row
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 20,
                border: 'none',
                borderBottom: '3px solid rgba(224, 224, 224, 1)'
              }}
            >
              {shownColumns.map((col, index) => (
                <Cell
                  key={col.field}
                  className={classes.header}
                  style={{
                    textAlign: col?.headerAlign || 'left',
                    minWidth: col?.width || 200,
                    position: col.sticky ? 'sticky' : 'static',
                    backgroundColor: theme.palette.background.paper,
                    zIndex: col.sticky ? 15 : 10,
                    left:
                      col.sticky && index >= 0
                        ? `${shownColumns
                            .slice(0, index)
                            .reduce(
                              (acc, col) => acc + (col.width || 300),
                              0
                            )}px`
                        : 'auto'
                  }}
                >
                  {col.headerName}
                </Cell>
              ))}
            </Row>
          </Head>

          <Body>
            {shownData.map((item, rowIndex) => (
              <Row
                key={item[rowIDKey]}
                className={rowIndex % 2 ? classes.evenRow : classes.oddRow}
                style={{
                  border: 'none',
                  borderBottom: '1px solid rgba(224, 224, 224, 1)'
                }}
              >
                {shownColumns.map((col, index) => (
                  <Cell
                    key={col.field}
                    className={`
                      ${classes.cellDense} 
                      ${
                        col.sticky
                          ? rowIndex % 2
                            ? classes.stickyCellEvenRow
                            : classes.stickyCellOddRow
                          : ''
                      }
                    `}
                    align={col?.align || 'left'}
                    style={{
                      minWidth: col?.width || 200,
                      position: col.sticky ? 'sticky' : 'static',
                      zIndex: col.sticky ? 15 : 10,
                      borderBottom: 'rgba(224, 224, 224, 1)',
                      left:
                        col.sticky && index >= 0
                          ? `${shownColumns
                              .slice(0, index)
                              .reduce(
                                (acc, col) => acc + (col.width || 300),
                                0
                              )}px`
                          : 'auto',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {getCellValue(col, item, item[rowIDKey], item[col.field])}
                  </Cell>
                ))}
              </Row>
            ))}
            {totals.length > 0 && (
              <Row
                style={{
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 20,
                  border: 'none',
                  borderTop: '3px solid rgba(224, 224, 224, 1)'
                }}
              >
                {shownColumns.map((col, index) => (
                  <Cell
                    key={col.field}
                    className={`
                      ${classes.cellDense} 
                      ${
                        col.sticky
                          ? index % 2 === 0
                            ? classes.stickyCellEvenRow
                            : classes.stickyCellOddRow
                          : ''
                      }
                    `}
                    style={{
                      textAlign: index === 0 ? 'left' : 'right',
                      minWidth: col?.width || 200,
                      position: col.sticky ? 'sticky' : 'static',
                      backgroundColor: theme.palette.background.paper,
                      zIndex: col.sticky ? 15 : 10,
                      left:
                        col.sticky && index >= 0
                          ? `${shownColumns
                              .slice(0, index)
                              .reduce(
                                (acc, col) => acc + (col.width || 300),
                                0
                              )}px`
                          : 'auto'
                    }}
                  >
                    <strong>
                      {index === 0
                        ? 'Total'
                        : totals.includes(col.field)
                        ? getCellValue(col, {}, 0, columnsTotals[col.field])
                        : ''}
                    </strong>
                  </Cell>
                ))}
              </Row>
            )}
          </Body>
        </MuiTable>
      </Container>
      {shownData.length === 0 && (
        <Box
          height={52}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderBottom="3px solid rgba(224, 224, 224, 1)"
        >
          <Typography variant="body2">No Data</Typography>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(Table);
