import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  portalMeta: {}
};

const slice = createSlice({
  name: 'portal',
  initialState: INITIAL_STATE,
  reducers: {
    setPortalMeta(state, action) {
      state.portalMeta = action.payload;
    }
  }
});

const portalReducer = slice.reducer;

export const { setPortalMeta } = slice.actions;

export default portalReducer;
