import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'transactions',
  initialState: {},
  reducers: {
    updateTransactionReport(state, action) {
      const { payload, jcd_uuid } = action.payload;
      state[jcd_uuid] = {
        ...state[jcd_uuid],
        ...payload
      };
    },
    resetState(state, action) {
      return {};
    },
    clear() {
      return {};
    },
    removeTransactions(state, action) {
      return action.payload;
    },
    restore(_, action) {
      return action.payload || {};
    }
  }
});

const transactionsReducer = slice.reducer;
export const {
  updateTransactionReport,
  resetState,
  clear,
  restore,
  removeTransactions
} = slice.actions;
export default transactionsReducer;
