import axios from 'axios';

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export let cancel = () => null;

const getFile = async ({
  url,
  userToken,
  fileType = 'image/png',
  method = 'get',
  fetchConfig = {},
  params = {}
}) => {
  try {
    let r = await axios({
      url,
      method,
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': userToken
      },
      responseType: 'arraybuffer',
      cancelToken: new axios.CancelToken(function executor(c) {
        cancel = c;
      }),
      ...fetchConfig,
      ...params
    });

    let d = Buffer.from(r.data).toString('base64');

    const blob = b64toBlob(d, fileType);
    return URL.createObjectURL(blob);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default getFile;
