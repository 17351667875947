import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPreferences,
  setTheme,
  setMode,
  setDefault
} from 'src/redux/slices/preferences';
import { THEMES } from 'src/constants';

const defaultContextValue = {
  savePreferences: () => {},
  saveTheme: () => {},
  preferences: {
    direction: 'ltr',
    responsiveFontSizes: true,
    mode: 'light',
    theme: THEMES.LIGHT
  }
};

const PreferencesContext = React.createContext(defaultContextValue);

const PreferencesProvider = ({ children }) => {
  const dispatch = useDispatch();

  const preferences = useSelector(state => state.preferences);

  function savePreferences(preferences) {
    dispatch(setPreferences(preferences));
  }

  function saveTheme(theme) {
    dispatch(setTheme(theme));
  }

  function changeMode(mode) {
    dispatch(setMode(mode));
  }

  function setDefaultTheme() {
    dispatch(setDefault());
  }

  return (
    <PreferencesContext.Provider
      value={{
        preferences,
        savePreferences,
        saveTheme,
        changeMode,
        setDefaultTheme
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

export { PreferencesProvider };
export default PreferencesContext;
