import React from 'react';

import Page from 'src/components/Page';
import Main from './Main';
import About from './About';
import MissionVision from './MissionVision';
import Footer from './Footer';
import Timeline from './Timeline';
import Systems from './Systems';
import Seven from './Seven';
import Clients from './Clients';
import Products from './Products';

const Radztech = () => {
  return (
    <Page title="Radztech">
      <Main />
      <About />
      <MissionVision />
      <Seven />
      <Timeline />
      <Systems />
      <Clients />
      <Products />
      <Footer />
    </Page>
  );
};

export default Radztech;
