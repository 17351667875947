import React from "react";
import { NumberInput } from "src/views/trans/JCdTrans/components/NumberInput";

const NumberWithFormula = ({ data, onChangeField }) => {
  function handleChangeField({ value }) {
    onChangeField({
      target: {
        name: data.field,
        value: +value
      }
    });
  }

  return (
    <NumberInput
      value={data.value}
      label={data?.label || ""}
      error={data?.error ?? false}
      errorMessage={data?.errorMessage || ""}
      onChange={handleChangeField}
      disableDecimal={data?.disableDecimal ?? false}
      format={data?.format ?? "0,0.00"}
      textboxProps={{ disabled: data.disabled, ...data.textboxProps }}
    />
  );
};

export default NumberWithFormula;
