import { createSlice } from '@reduxjs/toolkit';
import { Request } from 'src/helpers';

const INITIAL_STATE = {
  accounts: [],
  lib_accounts: [],
  lastAccountSearchQuery: '',
  products: [],
  branches: [],
  warehouse: [],
  productCategories: [],
  productSubCategories: {},
  limits: {},
  transJDate: '',
  atc: {}
};

const slice = createSlice({
  name: 'storedValues',
  initialState: INITIAL_STATE,
  reducers: {
    setAccounts(state, action) {
      state.accounts = action.payload;
    },
    setLibAccounts(state, action) {
      state.lib_accounts = action.payload;
    },
    setWarehouse(state, action) {
      state.warehouse = action.payload;
    },
    setProducts(state, action) {
      state.products = action.payload;
    },
    setBrch(state, action) {
      state.branches = action.payload;
    },
    setProductCategories(state, action) {
      state.productCategories = action.payload;
    },
    setProductSubCategories(state, action) {
      state.productSubCategories = {
        ...state.productSubCategories,
        ...action.payload
      };
    },
    loadProductSubCategories(state, action) {
      state.productSubCategories = action.payload;
    },
    updateLimits(state, action) {
      const { ixSubType, limits, sSubType, ixUser } = action.payload;

      return {
        ...state,
        limits: {
          ...state.limits,
          [ixUser]: {
            ...state.limits[ixUser],
            [ixSubType]: { limits, sSubType }
          }
        }
      };
    },
    setAtc(state, action) {
      state.atc = action.payload;
    },
    setLastSearchAccountQuery(state, action) {
      state.lastAccountSearchQuery = action.payload;
    },
    setTransJDate(state, action) {
      state.transJDate = action.payload;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

export const setLimits = ({ limits, ixSubType, sSubType }) => (
  dispatch,
  getState
) => {
  const { current_user } = getState().auth;
  const { updateLimits } = slice.actions;

  dispatch(
    updateLimits({
      ixSubType,
      sSubType,
      ixUser: current_user.username,
      limits
    })
  );
};

export const reloadAccounts = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const { setAccounts } = slice.actions;

  const req = new Request(base_url, {
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    }
  });

  const { success, data } = await req.post('/trans/search/acc', {
    q: ''
  });

  if (success) {
    dispatch(setAccounts(data));
    return data;
  } else return [];
};

const storedValuesReducer = slice.reducer;
export const {
  setWarehouse,
  setAtc,
  setProducts,
  setProductCategories,
  setProductSubCategories,
  setAccounts,
  loadProductSubCategories,
  clear,
  restore,
  setBrch,
  setLastSearchAccountQuery,
  setTransJDate,
  setLibAccounts
} = slice.actions;
export default storedValuesReducer;
