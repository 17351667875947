import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  transaction: {
    selectedTransaction: ''
  }
};

const slice = createSlice({
  name: 'setup',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedTransaction(state, action) {
      state.transaction.selectedTransaction = action.payload;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const setupReducer = slice.reducer;

export const { setSelectedTransaction, restore, clear } = slice.actions;
export default setupReducer;
