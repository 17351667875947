import { useEffect, useState } from 'react';

/**
 * @function a custom hook where you can utilize to declare a boolean state with method declared
 * @param {Boolean} initialValue
 * @returns {[Boolean, VoidFunction, VoidFunction, VoidFunction]}
 */
const useToggle = (initialValue = false) => {
  const [isOn, setIsOn] = useState(false);

  function switchOn() {
    setIsOn(true);
  }

  function switchOff() {
    setIsOn(false);
  }

  function toggle() {
    setIsOn(state => !state);
  }

  useEffect(() => {
    setIsOn(initialValue);
  }, [initialValue]);

  return [isOn, switchOn, switchOff, toggle];
};

export default useToggle;
