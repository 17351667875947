import { useEffect } from 'react';

const isSaveKey = key => key === 's' || key === 'S';
const isNewKey = key => key === 'n' || key === 'N';
const isOpenKey = key => key === 'o' || key === 'O';

/**
 *
 * @param {object} param
 * @param {VoidFunction|null} param.onOpen
 * @param {VoidFunction|null} param.onSave
 * @param {VoidFunction|null} param.onNew
 * @param {boolean} param.disableShortcuts
 */
const useKeyboardShortcuts = ({
  onOpen = null,
  onSave = null,
  onNew = null,
  disableShortcuts = false
}) => {
  const isMac = navigator.userAgent.indexOf('Mac OS X') != -1;

  useEffect(() => {
    if (!onOpen) return;

    function callback(event) {
      if (disableShortcuts) return;
      const { ctrlKey, key, metaKey } = event;

      const macShortcutValid = isMac && metaKey && isOpenKey(key);
      const otherDevShortcutValid = !isMac && ctrlKey && isOpenKey(key);

      if (macShortcutValid || otherDevShortcutValid) {
        event.preventDefault();
        onOpen();
      }
    }

    document.addEventListener('keydown', callback);

    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [onOpen, disableShortcuts]);

  useEffect(() => {
    if (!onSave) return;

    function callback(event) {
      if (disableShortcuts) return;
      const { ctrlKey, key, metaKey } = event;

      const macShortcutValid = isMac && metaKey && isSaveKey(key);
      const otherDevShortcutValid = !isMac && ctrlKey && isSaveKey(key);

      if (macShortcutValid || otherDevShortcutValid) {
        event.preventDefault();
        onSave();
      }
    }

    document.addEventListener('keydown', callback);

    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [onSave, disableShortcuts]);

  useEffect(() => {
    if (!onNew) return;

    function callback(event) {
      if (disableShortcuts) return;
      const { ctrlKey, key, metaKey } = event;

      const macShortcutValid = isMac && metaKey && isNewKey(key);
      const otherDevShortcutValid = !isMac && ctrlKey && isNewKey(key);

      if (macShortcutValid || otherDevShortcutValid) {
        event.preventDefault();
        onNew();
      }
    }

    document.addEventListener('keydown', callback);

    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [onNew, disableShortcuts]);
};

export default useKeyboardShortcuts;
