import { LinearProgress } from '@material-ui/core';
import React from 'react';

const LineProgress = ({ height = 2, ...props }) => {
  return (
    <LinearProgress style={{ ...(props?.style || {}), height }} {...props} />
  );
};

export default LineProgress;
