import React from 'react';
import {
  Button,
  Menu,
  MenuItem,
  FormControl,
  SvgIcon
} from '@material-ui/core';
import { Category } from '@material-ui/icons';

const SelectCategory = ({
  openCategory,
  closeCategory,
  productCategories,
  productCategory,
  catAnchorEl,
  setProductCategory,
  setProductSubCategory
}) => {
  return (
    <FormControl component="fieldset">
      <Button
        onClick={openCategory}
        startIcon={
          <SvgIcon fontSize="small">
            <Category />
          </SvgIcon>
        }
        color="primary"
      >
        {productCategories.find(pCat => pCat.ixProdCat === productCategory)
          ?.sProdCat || 'All Categories'}
      </Button>
      <Menu
        anchorEl={catAnchorEl}
        onClose={closeCategory}
        open={Boolean(catAnchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem
          onClick={() => {
            setProductCategory(0);
            setProductSubCategory(0);
            closeCategory();
          }}
        >
          All Categories
        </MenuItem>
        {productCategories.map(({ ixProdCat, sProdCat }) => (
          <MenuItem
            button
            key={ixProdCat}
            onClick={() => {
              setProductCategory(ixProdCat);
              setProductSubCategory(0);
              closeCategory();
            }}
          >
            {sProdCat}
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );
};

export default SelectCategory;
