import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { Today } from '@material-ui/icons';

const SingleDatePicker = ({
  value,
  onChange = ({ target: { name, value } }) => {},
  name = '',
  label = '',
  variant = 'inline',
  inputVariant = 'outlined',
  disableToolbar = true,
  fullWidth = false,
  InputProps = {},
  disabled = false,
  error = false,
  helperText = '',
  size = 'medium',
  ...props
}) => {
  const { frontEnd = {} } = useSelector(state => state.bizMeta);
  const dateFormat = frontEnd?.dateFormat || 'MM/dd/yyyy';

  const [dateError, setDateError] = useState('');

  function handleChange(newValue, value) {
    if (!value) {
      setDateError('');
      onChange({
        target: {
          name,
          value: ''
        }
      });
      return;
    }
    if (moment(newValue).isValid()) {
      setDateError('');
      onChange({
        target: {
          name,
          value: moment(newValue).format('YYYY-MM-DD')
        }
      });
    } else {
      setDateError('Invalid date format');
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        variant="inline"
        fullWidth={fullWidth}
        orientation="landscape"
        format={dateFormat}
        label={label}
        inputVariant="outlined"
        value={moment(value).isValid() ? value : null}
        onChange={handleChange}
        error={Boolean(dateError) || error}
        helperText={dateError || helperText}
        InputProps={InputProps}
        disabled={disabled}
        KeyboardButtonProps={{ disabled: Boolean(InputProps?.readOnly), size }}
        keyboardIcon={<Today fontSize={size} />}
        size={size}
        views={['year', 'month', 'date']}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default SingleDatePicker;
