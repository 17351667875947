import React, { useMemo } from 'react';
import { Box, Container, Divider, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import JVWFAssigned from './AssignedTransaction';
import Banner from './Banner';
import WatchList from './WatchedTransactions';
import RecentActivities from './RecentActivities';
import PostingSummary from './PostingSummary';
import OpenedTransaction from './OpenedTransaction';
import { useSelector, useDispatch } from 'react-redux';
import { UserCheck } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import {
  AssignmentTurnedInOutlined,
  RemoveRedEyeOutlined,
  HistoryOutlined
} from '@material-ui/icons';
import StyledTab from 'src/components/StyledTab';
import { setCurrentTab } from 'src/redux/slices/dashboard';
import ActionRequest from './ActionRequest';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentTab } = useSelector(state => state.dashboard);
  const { frontEnd } = useSelector(({ bizMeta }) => bizMeta);

  const tabs = useMemo(() => {
    return [
      {
        id: 1,
        title: 'Posting Summary',
        icon: <FontAwesomeIcon icon={faChartSimple} size="lg" />,
        hide: false
      },
      {
        id: 2,
        title: 'Assigned Transaction',
        icon: <AssignmentTurnedInOutlined />,
        hide: false
      },
      {
        id: 3,
        title: 'Opened Transactions',
        icon: <FontAwesomeIcon icon={faBoxOpen} size="lg" />,
        hide: false
      },
      {
        id: 4,
        title: 'Watched Transactions',
        icon: <RemoveRedEyeOutlined />,
        hide: false
      },
      {
        id: 5,
        title: 'Recent Activities',
        icon: <HistoryOutlined />,
        hide: false
      },
      {
        id: 6,
        title: 'Action Request',
        icon: <UserCheck />,
        hide: !(frontEnd?.arq?.allow ?? false)
      }
    ].filter(({ hide }) => !hide);
  }, [frontEnd]);

  const handleChangeTab = (_, selectedTab) => {
    dispatch(setCurrentTab(selectedTab));
  };

  return (
    <Page className={classes.root} title="Dashboard">
      <Banner />
      <Container maxWidth="lg">
        <Box mt={3}>
          <StyledTab
            tabs={tabs}
            activeTab={currentTab}
            onChange={handleChangeTab}
          />
          <Divider />
          <Box mt={2}>
            {currentTab === 0 && <PostingSummary />}
            {currentTab === 1 && <JVWFAssigned />}
            {currentTab === 2 && <OpenedTransaction />}
            {currentTab === 3 && <WatchList />}
            {currentTab === 4 && <RecentActivities />}
            {currentTab === 5 && <ActionRequest />}
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default Dashboard;
