import React from 'react';
import BannerTl1 from './BannerTl1';

/**
 *
 * @param {object} param
 * @param {number} param.ixPage
 * @param {pageBanner} param.pageDetails
 * @returns
 */
const Banner = ({ ixPage, pageDetails }) => {
  // if (pageDetails?.template === 'template2')
  //   return <Banner2/>

  return <BannerTl1 ixPage={ixPage} pageDetails={pageDetails} />;
};

export default Banner;
