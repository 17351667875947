import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles({
  loading: {
    filter: 'blur(6px)',
    clipPath: 'inset(0)'
  },
  loaded: {
    filter: 'blur(0px)',
    transition: 'filter 0.5s linear'
  }
});

const ProgressiveImg = ({ placeholderSrc, src, ...props }) => {
  const { loading, loaded } = useStyles();
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    setImgSrc(placeholderSrc);
  }, [placeholderSrc]);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  return (
    <img
      {...{ src: imgSrc, ...props }}
      alt={props.alt || ''}
      className={clsx(
        placeholderSrc && imgSrc === placeholderSrc ? loading : loaded,
        props.className
      )}
    />
  );
};
export default ProgressiveImg;
