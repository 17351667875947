import React from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  Button,
  Box,
  Paper,
  useTheme,
  Typography
} from '@material-ui/core';
import {
  CheckCircle,
  VisibilityOffOutlined,
  VisibilityOutlined,
  Cancel
} from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';
import { useToggle, useRequest, useNotif, useAuth } from 'src/hooks';
import { Formik } from 'formik';
import { string, object, ref } from 'yup';
import base64 from 'Base64';
import { useDispatch, useSelector } from 'react-redux';
import { clearRequireChangePw } from 'src/redux/slices/auth';
import { useNavigate } from 'react-router';
import { fetchBizSettings, loadBizSettings } from 'src/redux/slices/biz';

const ChangePassword = () => {
  const theme = useTheme();
  const notify = useNotif();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { base_url, userToken } = useSelector(state => state.auth);
  const { registry } = useAuth();

  const [loading, loadingOn, loadingOff] = useToggle();
  const [passwordVisible, , , togglePasswordVisibility] = useToggle();
  const [newPasswordVisible, , , toggleNewPasswordVisibility] = useToggle();
  const [pwConfirmVisible, , , togglePwConfirmVisibility] = useToggle();

  const request = useRequest(loadingOn, loadingOff);

  async function updatePassword({ password, newPassword }) {
    const res = await request.post(
      '/user/change/pw',
      {
        new_pw: base64.btoa(newPassword)
      },
      {
        headers: {
          Authorization: 'Basic ' + base64.btoa('rado:' + password),
          'new-pw': base64.btoa(newPassword)
        }
      }
    );

    if (res.success && res.data?.success) {
      notify.success('Password updated successfully.');
      dispatch(clearRequireChangePw());

      if (!registry) {
        navigate('/app/dashboard');
        const bizSettings = await fetchBizSettings(base_url, userToken);
        dispatch(loadBizSettings(bizSettings));
      }
    } else {
      notify.error(res.data?.message || 'Something went wrong.');
    }
  }

  return (
    <Box
      display="flex"
      height="calc(100vh - 64px)"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width={{ xs: '100%', sm: '100%', md: '50%', lg: '30%', xl: '30%' }}
        p={3}
      >
        <Paper>
          <Formik
            initialValues={{
              password: '',
              newPassword: '',
              confirmNewPassword: ''
            }}
            validationSchema={object().shape({
              password: string()
                .max(125)
                .required('Password is required'),
              newPassword: string()
                .max(125)
                .required('New password is required')
                .min(8, 'Password must be at least 8 characters')
                .test(
                  'hasUpperCase',
                  'Password must contain at least 1 uppercase letter',
                  value => /(?=.*[A-Z])/.test(value)
                )
                .test(
                  'hasLowerCase',
                  'Password must contain at least 1 lowercase letter',
                  value => /(?=.*[a-z])/.test(value)
                )
                .test(
                  'hasNumber',
                  'Password must contain at east 1 number',
                  value => /[0-9]/.test(value)
                )
                .test(
                  'hasSpecialChar',
                  'Password must contain at least 1 special character.',
                  value =>
                    /(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(value)
                ),
              confirmNewPassword: string()
                .max(125)
                .required('Confirm Password is required')
                .oneOf([ref('newPassword'), null], 'Passwords did not match')
            })}
            validateOnBlur={false}
            onSubmit={updatePassword}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldTouched
            }) => (
              <form onSubmit={handleSubmit}>
                <Box py={2} px={3}>
                  <Typography variant="h4">Change Password Required</Typography>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  flexDirection="column"
                  gridGap={theme.spacing(2)}
                  p={3}
                >
                  <TextField
                    type={passwordVisible ? 'text' : 'password'}
                    variant="outlined"
                    label="Password"
                    fullWidth
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            size="small"
                            tabIndex={1}
                            disabled={loading}
                          >
                            {passwordVisible ? (
                              <VisibilityOutlined />
                            ) : (
                              <VisibilityOffOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Divider />
                  <TextField
                    type={newPasswordVisible ? 'text' : 'password'}
                    variant="outlined"
                    label="New Password"
                    fullWidth
                    name="newPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                    onFocus={() => setFieldTouched('newPassword', true)}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={toggleNewPasswordVisibility}
                            size="small"
                            tabIndex={1}
                            disabled={loading}
                          >
                            {newPasswordVisible ? (
                              <VisibilityOutlined />
                            ) : (
                              <VisibilityOffOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: Boolean(values.newPassword) && (
                        <InputAdornment position="start">
                          {Boolean(errors.newPassword) ? (
                            <Cancel htmlColor={red[400]} fontSize="small" />
                          ) : (
                            <CheckCircle
                              htmlColor={green[400]}
                              fontSize="small"
                            />
                          )}
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    type={pwConfirmVisible ? 'text' : 'password'}
                    variant="outlined"
                    label="Confirm New Password"
                    fullWidth
                    name="confirmNewPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() => setFieldTouched('confirmNewPassword', true)}
                    value={values.confirmNewPassword}
                    error={Boolean(
                      touched.confirmNewPassword && errors.confirmNewPassword
                    )}
                    helperText={
                      touched.confirmNewPassword && errors.confirmNewPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePwConfirmVisibility}
                            size="small"
                            tabIndex={1}
                            disabled={loading}
                          >
                            {pwConfirmVisible ? (
                              <VisibilityOutlined />
                            ) : (
                              <VisibilityOffOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: Boolean(values.confirmNewPassword) && (
                        <InputAdornment position="start">
                          {Boolean(errors.confirmNewPassword) ? (
                            <Cancel htmlColor={red[400]} fontSize="small" />
                          ) : (
                            <CheckCircle
                              htmlColor={green[400]}
                              fontSize="small"
                            />
                          )}
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                <Divider />
                <Box py={2} px={3} display="flex" justifyContent="flex-end">
                  <Button
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Update Password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Paper>
      </Box>
    </Box>
  );
};

export default ChangePassword;
