import React from 'react';
import { TextField } from '@material-ui/core';

const TimeField = ({ data, onChangeField }) => {
  const { fld: name = '', caption = '', value = null } = data;

  return (
    <TextField
      fullWidth
      name={name}
      label={caption}
      value={value || ''}
      type="time"
      onChange={onChangeField}
      variant="outlined"
      InputLabelProps={{
        shrink: true
      }}
    />
  );
};

export default TimeField;
