import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLimits, setBrch } from 'src/redux/slices/storedValues';
import useRequest from 'src/hooks/useRequest';
import { BRANCH_MODES } from 'src/constants';

/**
 * @typedef {object} branch
 * @property {number} ixBrch
 * @property {number} ixParent
 * @property {string} sBrch
 * @property {string} sParent
 */

/**
 *
 * @param {boolean} disregardLimits
 * @param {number[]} excludedBrch
 * @param {boolean} useForBudget
 * @returns {{
 *  data:{allow:boolean,label:string,label_singular:string,items:branch[]},
 *  branches:branch[],
 *  parents:branch[],
 *  loading:boolean,
 *  error:boolean,
 *  limits:{},
 *  refresh:Promise<void>,
 *  hasOnlyOneOption:boolean,
 *  mode:0|1|2
 * }}
 */
const useBranch = (
  disregardLimits = false,
  excludedBrch = [],
  useForBudget = false
) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { brch, budget } = useSelector(state => state.bizMeta);
  const { current_user } = useSelector(state => state.auth);

  const { limits: subLimits, branches } = useSelector(
    state => state.storedValues
  );
  const request = useRequest();
  const brchSubType = brch?.ixBrchSubType || 0;
  const limits =
    subLimits?.[current_user.username]?.[brchSubType]?.limits || [];
  const hasOnlyOneOption = useMemo(() => limits.length === 1, [limits]);

  const getBranches = async () => {
    setLoading(true);
    setError(false);

    const hasLimits = Boolean(
      subLimits?.[current_user.username]?.[brchSubType]
    );

    if (brchSubType && !hasLimits) {
      const limit = await request.get('/trans/sub-limits/' + brchSubType);
      dispatch(
        setLimits({
          ixSubType: brchSubType,
          sSubType: brch.label,
          limits: limit.data
        })
      );
    }

    if (branches.length === 0) {
      const branch = await request.get('/lib/brch');

      // if branch failed to fetch
      if (!branch.success) {
        setError(true);
        return;
      }

      dispatch(setBrch(branch.data.items));
    }

    setLoading(false);
  };

  const refresh = async () => {
    setError(false);
    setLoading(true);
    const branch = await request.get('/lib/brch');
    setLoading(false);

    if (!branch.success) {
      setError(true);
      return;
    }

    dispatch(setBrch(branch.data.items));
  };

  /**
   * @type {branch[]}
   */
  const filteredBranches = useMemo(() => {
    let items = branches;

    if (limits.length && !disregardLimits) {
      items = branches.filter(brch =>
        limits.some(limit => limit.ixSub === brch.ixBrch)
      );
    }

    // Filter Excluded Branches
    if (excludedBrch.length > 0) {
      items = items.filter(item => !excludedBrch.includes(item.ixBrch));
    }

    // Fund cluster with budget only
    const branchesWithBudget = budget?.brch ?? [];
    if (useForBudget) {
      items = items.filter(item =>
        branchesWithBudget.length === 0
          ? true
          : branchesWithBudget.includes(item.ixBrch)
      );
    }

    return items;
  }, [branches, limits, excludedBrch, useForBudget]);

  const parents = useMemo(() => {
    return filteredBranches
      .reduce((acc, prev) => {
        if (!acc.some(item => item.ixParent === prev.ixParent)) {
          acc.push({
            ixParent: prev.ixParent,
            sParent: prev.sParent
          });
        }
        return acc;
      }, [])
      .filter(item => item.ixParent);
  }, [filteredBranches]);

  useEffect(() => {
    if (brch.mode > 0) getBranches();
  }, []);

  return {
    data: {
      allow: brch?.mode > 0,
      label: brch?.label || 'Branches',
      label_singular: brch?.label_singular || 'Branch',
      items: branches
    },
    branches: filteredBranches,
    parents,
    loading,
    error,
    limits,
    refresh,
    hasOnlyOneOption,
    mode: brch.mode
  };
};

export default useBranch;
