import React from 'react';
import Page from 'src/components/Page';
import Header from './Header';

import Plans from './Plans';
import NotableFeatures from './NotableFeatures';
import ReportsFeature from './ReportsFeature';
import Footer from '../Footer';
import AccountingSoftware from './AccountingSoftware';
import BeyondAccounting from './BeyondAccounting';
import Features from './Features';
import ActionButtons from '../SubSystems/components/ActionButtons';
import usePageRefs from 'src/hooks/usePageRefs';
import { usePages } from 'src/hooks';

const UlapBiz = () => {
  const { plans, header, report, notable, anb, accounting } = usePageRefs();
  const { isLoading, pageDetails, cdPage, ixPage } = usePages('ulapbiz');

  const handleGotoProductLine = () => {
    if (!plans.current) return;

    plans.current.scrollIntoView({
      behavior: 'smooth'
    });
  };

  const handleOnClickScrollDown = () => {
    if (!accounting.current) return;

    accounting.current.scrollIntoView({
      behavior: 'smooth'
    });
  };

  return (
    <Page title="UlapBiz">
      <div ref={header}>
        <Header
          onGotoProductLine={handleGotoProductLine}
          onClickScrollDown={handleOnClickScrollDown}
          bannerSettings={pageDetails?.banner}
          ixPage={ixPage}
        />
      </div>
      <div ref={accounting}>
        <AccountingSoftware onGotoProductLine={handleGotoProductLine} />
      </div>
      <Features />
      <div ref={plans}>
        <Plans />
      </div>
      <div ref={anb}>
        <BeyondAccounting />
      </div>
      <div ref={notable}>
        <NotableFeatures />
      </div>
      <div ref={report}>
        <ReportsFeature />
      </div>
      <ActionButtons />
      <Footer />
    </Page>
  );
};

export default UlapBiz;
