import React from 'react';
import PricingTl1 from './PricingTl1';

/**
 *
 * @param {object} param
 * @param {string} param.ixPage
 * @param {pagePricing} param.pageDetails
 * @returns
 */
const Pricing = ({ ixPage, pageDetails }) => {
  return <PricingTl1 ixPage={ixPage} pageDetails={pageDetails} />;
};

export default Pricing;
