import React from 'react';
import { TableCell, TextField, makeStyles, Box } from '@material-ui/core';
import { useToggle } from 'src/hooks';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  textField: {
    padding: theme.spacing(1),
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    width: '100%',
    height: 53,
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    fontSize: 'inherit',
    textAlign: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
    '&:focus': {
      outline: 'none'
    }
  }
}));

const EditableTableCell = ({
  value,
  style = {},
  className = '',
  type = 'text',
  onChange = event => {}
}) => {
  const classNames = useStyles();

  const [editValue, setEditValue] = useState(value);
  const [onEditMode, enterEditMode, leaveEditMode] = useToggle();

  function handleBlur() {
    onChange({ target: { value: editValue } });
    leaveEditMode();
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      onChange({ target: { value: editValue } });
      leaveEditMode();
    }
  }

  return (
    <TableCell
      style={style}
      padding={onEditMode ? 'none' : 'normal'}
      className={className}
      onDoubleClick={enterEditMode}
    >
      {onEditMode ? (
        <input
          autoFocus
          type={type}
          className={classNames.textField}
          value={editValue}
          onBlur={handleBlur}
          onChange={e => setEditValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      ) : (
        value
      )}
    </TableCell>
  );
};

export default EditableTableCell;
