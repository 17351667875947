import React from 'react';
import OverviewTl1 from './OverviewTl1';

/**
 *
 * @param {object} param
 * @param {string} param.ixPage
 * @param {pageOverview} param.pageDetails
 * @returns
 */
const Overview = ({ ixPage, pageDetails }) => {
  return <OverviewTl1 ixPage={ixPage} pageDetails={pageDetails} />;
};

export default Overview;
