import React from 'react';
import {
  Box,
  makeStyles,
  Typography,
  Avatar,
  Container
} from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  headerName: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em'
  },
  avatar: {
    border: `2px solid ${theme.palette.common.white}`,
    height: 120,
    width: 120,
    top: -60,
    left: theme.spacing(3),
    position: 'absolute',
    [theme.breakpoints.only('sm')]: {
      height: 100,
      width: 100,
      top: -50
    },
    [theme.breakpoints.only('xs')]: {
      height: 80,
      width: 80,
      top: -40
    }
  },
  cover: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    aspectRatio: 6 / 1,

    '&:before': {
      position: 'absolute',
      content: '" "',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundImage:
        'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)'
    }
  }
}));

const Banner = () => {
  const { ixImage, biz = {} } = useSelector(state => state.auth);
  const classes = useStyles();

  return (
    <div>
      <div
        className={classes.cover}
        style={{ backgroundImage: `url(/api/images/biz/${ixImage}/wide.jpg)` }}
      />
      <Container maxWidth="lg">
        <Box position="relative" mt={1} display="flex" alignItems="center">
          <Avatar
            alt="Biz Logo"
            className={classes.avatar}
            src={`/api/images/biz/${ixImage}/logo.jpg`}
          />
          <Box marginLeft={{ xs: '110px', sm: '130px', md: '160px' }}>
            <Typography variant="h4" color="textPrimary">
              {biz.name}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {`${biz.ad1}, ${biz.ad2}`}
            </Typography>
          </Box>
          <Box flexGrow={1} />
        </Box>
      </Container>
    </div>
  );
};

export default Banner;
