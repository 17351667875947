import React from 'react';
import clsx from 'clsx';
import { Container, Grid, makeStyles, Box, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 50,
    paddingBottom: 50
  },
  image: {
    display: 'block',
    height: 'auto',
    width: 100,
    margin: '0 auto',
    transition: 'all 300ms ease-out',
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-.5em)'
    }
  },
  slider: {
    margin: 'auto',
    overflow: 'hidden',
    position: 'relative',
    '&:before': {
      background: `linear-gradient(to right,  rgba(255,255,255) 0%,rgba(255,255,255,0) 100%)`,
      content: "''",
      height: '100%',
      position: 'absolute',
      width: 200,
      zIndex: 2,
      left: 0,
      top: 0
    },
    '&:after': {
      background: `linear-gradient(to right,  rgba(255,255,255) 0%,rgba(255,255,255,0) 100%)`,
      content: "''",
      height: '100%',
      position: 'absolute',
      width: 200,
      zIndex: 2,
      right: 0,
      top: 0,
      transform: 'rotateZ(180deg)'
    },
    '& .slideTrack': {
      animation: '$scroll 40s linear infinite',
      display: 'flex',
      width: `calc(250px * ${IMAGES.length})`,
      height: 100,
      '&:hover': {
        animationPlayState: 'paused'
      }
    },
    '& .slide': {
      height: '100%',
      width: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  '@keyframes scroll': {
    '0%': { transform: 'translateX(0)' },
    '100%': { transform: `translateX(calc(-250px * ${IMAGES.length / 2}))` }
  }
}));

const CLIENTS = [
  {
    key: 'ulapbiz',
    name: 'UlapBiz',
    img: '/static/images/clients/ulapbiz.png',
    style: { width: 90 }
  },
  { key: 'snj', name: 'S & J Marketing', img: '/static/images/clients/1.png' },
  {
    key: 'tagchem',
    name: 'Tagchem',
    img: '/static/images/clients/tagchem.png',
    style: { width: 90 }
  },
  {
    key: 'olpcc',
    name: 'Our Lady of the Pillar College',
    img: '/static/images/clients/4.png',
    style: { width: 90 }
  },
  {
    key: 'ncf',
    name: 'NCF Prime Industrial Sales Inc.',
    img: '/static/images/clients/ncf.jpg',
    style: { width: 120 }
  },
  {
    key: 'cdc',
    name: 'Centro De Cultura Inc.',
    img: '/static/images/clients/cdc.jpg',
    style: { width: 90 }
  },
  {
    key: 'emi',
    name: 'Equity Machineries, Inc.',
    img: '/static/images/clients/emi.jpg',
    style: { width: 90 }
  },
  {
    key: 'mti',
    name: 'Motorline Trading, Inc.',
    img: '/static/images/clients/mti.jpg',
    style: { width: 130 }
  },
  {
    key: 'gmi',
    name: 'Grupo Marilen Inc.',
    img: '/static/images/clients/gmi.png',
    style: { width: 90 }
  },
  {
    key: 'uphs',
    name: 'University of Perpetual Help System',
    img: '/static/images/clients/uphs.png',
    style: { width: 90 }
  },
  {
    key: 'acc',
    name: 'Agri Component Corporation',
    img: '/static/images/clients/acc.PNG',
    style: { width: 120 }
  },
  {
    key: 'atsi',
    name: 'Agricom Trucking Services Incorporation',
    img: '/static/images/clients/atsi.png'
  },
  {
    key: 'iltc',
    name: 'Isabela Leaf Tobacco Co., Inc.',
    img: '/static/images/clients/iltc.png',
    style: { width: 130 }
  },
  {
    key: 'nasc',
    name: 'Northern Asia Sales Corporation',
    img: '/static/images/clients/nasc.PNG',
    style: { width: 110 }
  },
  {
    key: 'amcc',
    name: 'Agricomp Machineries and Construction Corp.',
    img: '/static/images/clients/amcc_logo.png'
  },
  {
    key: 'dmaya',
    name: 'DMAYA Corporation',
    img: '/static/images/clients/dmaya.png'
  },
  {
    key: 'lasuerte',
    name: 'Isabela La Suerte Rice Mill Corporation',
    img: '/static/images/clients/la_suerte.jpg',
    style: { width: 90 }
  }
];

const IMAGES = [
  ...CLIENTS,
  ...CLIENTS.map(client => ({ ...client, key: client.key + '-clone' }))
];

const Clients = ({ className }) => {
  const classes = useStyles();

  return (
    <Box bgcolor="#FFF">
      <Container maxWidth="lg">
        <Box className={clsx(classes.slider, classes.root)}>
          <Box className="slideTrack">
            {IMAGES.map(({ img, name, key, style = {} }) => (
              <Box className="slide" key={key}>
                <Tooltip title={name}>
                  <img
                    src={img}
                    alt={name}
                    className={classes.image}
                    style={style}
                  />
                </Tooltip>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Clients;
