import React, { useState, useMemo } from 'react';
import {
  Box,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  InputAdornment,
  Divider,
  FormHelperText
} from '@material-ui/core';
import searchByField from 'src/utils/searchByField';
import SearchIcon from '@material-ui/icons/Search';
import DebounceTextField from './DebounceTextField';

export default function SelectWithSearch({
  value,
  options = [],
  label = '',
  searchKeys = [],
  onChange,
  disabled,
  searchPlaceHolder = 'Type to search...',
  CustomMenuItem = null,
  error = false,
  errorMessage = ''
}) {
  const [searchText, setSearchText] = useState('');
  const displayedOptions = useMemo(
    () =>
      searchByField({
        data: options,
        keys: ['description', ...searchKeys],
        query: searchText
      }),
    [searchText, options]
  );

  function handleChangeSearchText(e) {
    setSearchText(e.target.value);
  }

  function clearSearchText() {
    setSearchText('');
  }

  return (
    <FormControl error={error} variant="outlined" fullWidth>
      <InputLabel id="search-select-label">{label}</InputLabel>
      <Select
        MenuProps={{ autoFocus: false }}
        labelId="search-select-label"
        id="search-select"
        value={searchText !== '' ? '' : value}
        label={label}
        disabled={disabled}
        onChange={onChange}
        onClose={clearSearchText}
        renderValue={val =>
          options.find(item => +item.value === +val)?.description ?? ''
        }
      >
        <ListSubheader disableGutters>
          <Paper
            component={Box}
            elevation={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={2}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <DebounceTextField
              size="small"
              autoFocus
              placeholder={searchPlaceHolder}
              fullWidth
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={handleChangeSearchText}
              onKeyDown={e => {
                if (e.key !== 'Escape') e.stopPropagation();
              }}
            />
          </Paper>
          <Divider />
        </ListSubheader>

        {displayedOptions.map(({ value, description, ...rest }, index) =>
          Boolean(CustomMenuItem) ? (
            <CustomMenuItem
              key={value}
              value={value}
              description={description}
              {...rest}
            />
          ) : (
            <MenuItem key={value} value={value}>
              {description}
            </MenuItem>
          )
        )}
      </Select>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
