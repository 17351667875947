import React from 'react';
import { Box, Paper, Button, TextField, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import ContactNumberTextField from 'src/components/ContactNumberTextField';

const phonePattern = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const CreateUserForm = ({ inviteDetails, isSaving, register, saveFailed }) => {
  const hasBackendErrors = fld => {
    if (!saveFailed) return false;

    if (!saveFailed?.user?.validation_errors) return false;

    return saveFailed?.user?.validation_errors?.[fld];
  };

  const getBackendErrors = fld => {
    if (!saveFailed) return '';

    if (!saveFailed?.user?.validation_errors) return '';

    return saveFailed?.user?.validation_errors?.[fld]
      ?.map(data => data)
      .join('');
  };

  return (
    <Box>
      <Box my={3}>
        <Typography color="primary" variant="h4" align="center">
          {inviteDetails?.msg}
        </Typography>
      </Box>

      {saveFailed?.msg && (
        <Alert severity="error">
          <AlertTitle>Failed</AlertTitle>
          {saveFailed?.msg}
        </Alert>
      )}

      {saveFailed?.user?.msg && (
        <Alert severity="error">
          <AlertTitle>Failed</AlertTitle>
          {saveFailed?.user?.msg}
        </Alert>
      )}

      {saveFailed && !saveFailed?.msg && !saveFailed?.user?.msg && (
        <Alert severity="error">
          <AlertTitle>Failed</AlertTitle>
          Something went wrong. Please try again.
        </Alert>
      )}

      <Paper component={Box} px={2} pb={2} my={3}>
        <Formik
          initialValues={{
            LName: '',
            FName: '',
            MName: '',
            SName: '',
            email: '',
            phone: '',
            username: '',
            password: '',
            confirmPassword: ''
          }}
          validationSchema={Yup.object().shape({
            LName: Yup.string()
              .max(50)
              .required('Last Name is required'),
            FName: Yup.string()
              .max(50)
              .required('First Name is required'),
            MName: Yup.string().max(50),
            SName: Yup.string().max(2),
            email: Yup.string()
              .email('Invalid email')
              .max(125)
              .required('Email is required'),
            phone: Yup.string()
              .required('Phone Number is required')
              .matches(phonePattern, 'Phone Number is required'),
            username: Yup.string()
              .max(50)
              .required('Username is required'),
            password: Yup.string()
              .max(125)
              .min(8, 'Password must be at least 8 characters')
              .required('Password is required'),
            confirmPassword: Yup.string()
              .max(125)
              .min(8, 'Confirm Password must be at least 8 characters')
              .when('password', {
                is: (val: any) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                  [Yup.ref('password')],
                  'Both password need to be the same'
                )
              })
              .required('Confirm Password is required')
          })}
          onSubmit={(values, action) => {
            register({ userInfo: values });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                error={
                  Boolean(touched.LName && errors.LName) ||
                  hasBackendErrors('LName')
                }
                fullWidth
                helperText={
                  (touched.LName && errors.LName) || getBackendErrors('LName')
                }
                label="Last Name"
                margin="normal"
                name="LName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="input"
                value={values.LName}
                variant="outlined"
              />

              <TextField
                error={
                  Boolean(touched.FName && errors.FName) ||
                  hasBackendErrors('FName')
                }
                fullWidth
                helperText={
                  (touched.FName && errors.FName) || getBackendErrors('FName')
                }
                label="First Name"
                margin="normal"
                name="FName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="input"
                value={values.FName}
                variant="outlined"
              />

              <TextField
                error={
                  Boolean(touched.MName && errors.MName) ||
                  getBackendErrors('FName')
                }
                fullWidth
                helperText={
                  (touched.MName && errors.MName) || getBackendErrors('MName')
                }
                label="Middle Name"
                margin="normal"
                name="MName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="input"
                value={values.MName}
                variant="outlined"
              />
              <TextField
                error={
                  Boolean(touched.SName && errors.SName) ||
                  hasBackendErrors('SName')
                }
                fullWidth
                helperText={
                  (touched.SName && errors.SName) || getBackendErrors('SName')
                }
                label="Extension Name"
                margin="normal"
                name="SName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="input"
                value={values.SName}
                variant="outlined"
              />
              <TextField
                error={
                  Boolean(touched.email && errors.email) ||
                  hasBackendErrors('email')
                }
                fullWidth
                helperText={
                  (touched.email && errors.email) || getBackendErrors('email')
                }
                label="Email"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="input"
                value={values.email}
                variant="outlined"
              />

              <ContactNumberTextField
                error={
                  Boolean(touched.phone && errors.phone) ||
                  hasBackendErrors('phone')
                }
                fullWidth
                helperText={
                  (touched.phone && errors.phone) || getBackendErrors('phone')
                }
                label="Phone Number"
                margin="normal"
                name="phone"
                onBlur={handleBlur}
                onChange={handleChange}
                type="input"
                value={values.phone}
                variant="outlined"
              />

              <TextField
                error={
                  Boolean(touched.username && errors.username) ||
                  hasBackendErrors('username')
                }
                fullWidth
                helperText={
                  (touched.username && errors.username) ||
                  getBackendErrors('username')
                }
                label="Username"
                margin="normal"
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                type="input"
                value={values.username}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <TextField
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                label="Confirm Password"
                margin="normal"
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.confirmPassword}
                variant="outlined"
              />

              <Box mt={2}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  size="large"
                  disabled={isSaving}
                >
                  Create Account
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default CreateUserForm;
