import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import APIRequest from 'src/helpers/APIRequest';
import useToggle from 'src/hooks/useToggle';
import { setOpenedLimit, setOpenedQuery } from 'src/redux/slices/dashboard';
import searchByField from 'src/utils/searchByField';

const useOpenedTransactions = () => {
  const dispatch = useDispatch();

  const {
    openedTransactions: { limit, query }
  } = useSelector(state => state.dashboard);
  const { base_url, userToken } = useSelector(({ auth }) => auth);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const { isOn: loading, on: loadingOn, off: loadingOff } = useToggle();

  const getOpenTransactions = async () => {
    loadingOn();
    const { data: openedTransactions, success } = await APIRequest({
      url: `${base_url}/trans/watch-list-auto`,
      headers: {
        'Content-type': 'application/json',
        'x-access-tokens': userToken
      }
    });
    loadingOff();
    if (success) setData(openedTransactions?.items || []);
  };

  function setLimit(val) {
    dispatch(setOpenedLimit(val));
  }

  function setQuery(val) {
    dispatch(setOpenedQuery(val));
  }

  const onLimitChange = newLimit => {
    setLimit(newLimit);
    setPage(0);
  };

  const onPageChange = newPage => {
    setPage(newPage);
  };

  useEffect(() => {
    getOpenTransactions();
  }, []);

  return {
    loading,
    refresh: getOpenTransactions,
    data: searchByField({
      data,
      query,
      keys: ['JCd', 'Name', 'RefNo', 'jid']
    }),
    rawData: data,
    query,
    setQuery,
    limit,
    page,
    onLimitChange,
    onPageChange
  };
};

export default useOpenedTransactions;
