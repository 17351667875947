import { lazy } from 'react';
import { Navigate } from 'react-router';
import PortalLayout from 'src/layouts/PortalLayout';

const Welcome = lazy(() => import('src/views/portal/Welcome'));
const Payroll = lazy(() => import('src/views/portal/Payroll'));
const TransList = lazy(() => import('src/views/portal/TransList'));
const TransView = lazy(() => import('src/views/portal/TransView'));
const PayrollView = lazy(() => import('src/views/portal/PayrollView'));
const Profile = lazy(() => import('src/views/portal/Profile'));
const PortalTrans = lazy(() => import('src/views/portal/PortalTrans'));

const portalRoutes = [
  {
    path: 'portal',
    element: PortalLayout,
    children: [
      {
        path: '*',
        element: Navigate,
        elementProps: {
          to: '/portal/welcome'
        }
      },
      {
        path: '',
        element: Navigate,
        elementProps: { to: '/portal/welcome' }
      },
      {
        path: 'welcome',
        element: Welcome
      },
      {
        path: 'profile',
        element: Profile
      },
      {
        path: 'payroll/:ixJCd',
        element: Payroll
      },
      {
        path: 'trans/:ixJCd',
        element: TransList
      },
      {
        path: 'payroll/:ixJCd/:jid',
        element: PayrollView
      },
      {
        path: 'trans/:ixJCd/:jid/view',
        element: TransView
      },
      {
        path: 'trans/:ixJCd/new',
        element: PortalTrans
      }
    ]
  }
];

export default portalRoutes;
