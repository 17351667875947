import { useEffect } from 'react';
import { useDashboardLayout } from 'src/layouts/DashboardLayout';
export { default } from './StickyBanner';

export const useOpenStickBanner = ({ threshold = 0 }) => {
  const {
    contentRef,
    isStickyBannerOpen,
    setStickyBannerDetails,
    showBanner,
    hideBanner
  } = useDashboardLayout();

  function setDetails(data) {
    setStickyBannerDetails(data);
  }

  function clearDetails() {
    hideBanner();
    setStickyBannerDetails({
      title: '',
      detail1: '',
      detail2: '',
      detail3: '',
      detail4: '',
      detail5: ''
    });
  }

  useEffect(() => {
    let timeOutId = null;

    function handleScroll() {
      if (contentRef.current) {
        const scrollPos = contentRef.current.scrollTop;

        timeOutId = setTimeout(() => {
          if (scrollPos >= threshold) showBanner();
          else hideBanner();
        }, 500);
      }
    }

    if (contentRef.current)
      contentRef.current.addEventListener('scroll', handleScroll);

    return () => {
      if (contentRef.current)
        contentRef.current.removeEventListener('scroll', handleScroll);

      if (!timeOutId) return;
      clearTimeout(timeOutId);
    };
  }, [contentRef?.current?.scrollTop]);

  useEffect(() => {
    window.onbeforeunload = function() {
      clearDetails();
      return;
    };

    return () => {
      window.onbeforeunload = null;
      clearDetails();
    };
  }, []);

  return {
    isStickyBannerOpen,
    setDetails
  };
};
