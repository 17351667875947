import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Main from './Main';
import Features from './Features';
import Pricing from './Pricing';
import Footer from '../Footer';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const UlapTax = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="UlapTax">
      <Main />

      <Features />

      <Pricing />

      <Footer />
    </Page>
  );
};

export default UlapTax;
