import React from 'react';
import { useSelector } from 'react-redux';
import { ULAP_CONFIG } from 'src/constants';

const EnvContext = React.createContext({
  EGOV: false,
  RADZTECH: false,
  REGISTRY: true,
  STUDENT: false,
  VERSION: '',
  CONFIG: {
    name: {
      first_word: '',
      second_word: ''
    },
    logo_url: {
      small: '',
      regular: '',
      medium: '',
      large: ''
    },
    logo_settings: {
      padding: 0,
      background: 'transparent'
    }
  }
});

const EnvProvider = ({ children }) => {
  const { REGISTRY = true, STUDENT = false, VERSION = '' } = useSelector(
    state => state.env || {}
  );

  const __SAVED_EGOV = window.localStorage.getItem('EGOV');
  const __SAVED_RADZTECH = window.localStorage.getItem('RADZTECH');

  const EGOV = __SAVED_EGOV ? __SAVED_EGOV === '1' : false;
  const RADZTECH = __SAVED_RADZTECH ? __SAVED_RADZTECH === '1' : false;

  function getConfig() {
    if (EGOV) return ULAP_CONFIG.egov;
    if (RADZTECH) return ULAP_CONFIG.radztech;

    return ULAP_CONFIG.default;
  }

  return (
    <EnvContext.Provider
      value={{
        EGOV,
        RADZTECH,
        REGISTRY,
        STUDENT,
        VERSION,
        CONFIG: getConfig()
      }}
    >
      {children}
    </EnvContext.Provider>
  );
};

export default EnvContext;
export { EnvProvider };
