import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import { orange } from '@material-ui/core/colors';
import { useRadztechLayout } from 'src/layouts/RadztechLayout';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 60,
    paddingBottom: 60,
    [theme.breakpoints.down('md')]: {
      paddingTop: 40,
      paddingBottom: 40
    }
  },
  image: {
    width: '80%',
    height: '350px',
    display: 'block',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '60%',
      margin: '0 auto'
    }
  },

  title: {
    fontSize: '2em',
    lineHeight: '1.5em',
    fontWeight: theme.typography.fontWeightBold,
    display: 'block',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.3em',
      textAlign: 'center'
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3em',
      textAlign: 'center'
    }
  },
  radztechTitle: {
    fontSize: '3em',
    lineHeight: '1.5em',
    fontWeight: theme.typography.fontWeightBold,
    display: 'block',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.3em',
      textAlign: 'center'
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3em',
      textAlign: 'center'
    }
  },
  smallTitle: {
    color: orange[500],
    fontWeight: theme.typography.fontWeightBold
  },

  description: {
    marginTop: '1em',
    fontSize: '1.5em',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
      textAlign: 'center'
    }
  },
  topTitle: {
    fontSize: '2rem',
    fontWeight: theme.typography.fontWeightBold,
    color: orange[500]
  },
  gridItem: {
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  highlight: {
    display: 'inline',
    padding: '.1em .3em',
    background: orange[500],
    color: '#fff',
    fontSize: '.9em'
  }
}));

const Item = ({ children, direction = 'row', image }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={6}
      alignItems="center"
      direction={direction}
      className={classes.gridItem}
    >
      <Grid item lg={6}>
        {children}
      </Grid>
      <Grid item lg={6}>
        <img alt="image" src={image} className={classes.image} />
      </Grid>
    </Grid>
  );
};

const Main = ({ className, ...rest }) => {
  const classes = useStyles();
  const { contentRef } = useRadztechLayout();

  return (
    <div ref={contentRef} className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Carousel
          autoPlay
          animation="slide"
          duration={1000}
          stopAutoPlayOnHover={false}
          navButtonsAlwaysInvisible
          swipe
        >
          <Item image="/static/images/undraw_business_deal_re_up4u.svg">
            <Box>
              <Typography className={classes.radztechTitle}>
                Radztech Corporation
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.description}
              >
                We are leading a movement to a future where all{' '}
                <strong> business processes </strong> are{' '}
                <strong>automated</strong>.
              </Typography>
            </Box>
          </Item>
          <Item direction="row-reverse" image="/static/images/great-minds.svg">
            <Typography className={classes.title}>
              We are powered by people with great minds and our collective
              mission is automation in the hands of every businessman
            </Typography>
          </Item>
          <Item image="/static/images/analyze.svg">
            <Typography className={classes.title}>
              We <span className={classes.highlight}>analyze</span> your pain
              points and create solutions.
            </Typography>
          </Item>
          <Item direction="row-reverse" image="/static/images/design.svg">
            <Typography className={classes.title}>
              We <span className={classes.highlight}>design</span> based on your
              requirements.
            </Typography>
          </Item>
          <Item image="/static/images/develop.svg">
            <Typography className={classes.title}>
              We <span className={classes.highlight}>develop</span> to help you
              solve your problems.
            </Typography>
          </Item>
        </Carousel>
      </Container>
    </div>
  );
};

Main.propTypes = {
  className: PropTypes.string
};

export default Main;
