import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Zoom,
  Box,
  LinearProgress
} from '@material-ui/core';
import { useToggle } from 'src/hooks';
import { isArray, isString } from 'lodash';
import { Alert, AlertTitle } from '@material-ui/lab';

/**
 * @typedef {Object} config
 * @property {'info'|'warning'|'error'|'success'} severity
 * @property {string} title
 * @property {string} titleVariant
 * @property {boolean} autoClose
 * @property {number} autoCloseDuration
 * @property {boolean} hideActions
 */

/**
 * @typedef {Object} Context
 * @property {(message: any, config: config) => void} popup
 */

/**
 * @type {React.Context<Context>}
 */
const PopupContext = React.createContext();

/**
 *
 * @param {any} message
 * @param {'info'|'warning'|'error'|'success'} severity
 * @returns
 */
function parseMessage(message, severity = 'info') {
  return <Alert severity={severity}>{message}</Alert>;
}

function parseError(error) {
  if (isString(error)) return <Alert severity="error">{error}</Alert>;
  if (isArray(error))
    return error.map(item => {
      if (isString(item)) return <Alert severity="error">{item}</Alert>;
      if (isString(item?.msg))
        return <Alert severity="error">{item?.msg}</Alert>;

      return null;
    });

  return (
    <Alert severity="error">
      <AlertTitle>{error?.msg || 'Something went wrong!'}</AlertTitle>
      {error.hasOwnProperty('errors') && (
        <Box display="flex" flexDirection="column">
          {error.errors.map(errorItem => {
            if (isString(errorItem)) return <span>{errorItem}</span>;
            if (isString(errorItem?.msg)) return <span>{errorItem.msg}</span>;

            return null;
          })}
        </Box>
      )}
    </Alert>
  );
}

const defaultConfig = {
  title: 'Error',
  severity: 'error',
  titleVariant: 'h4',
  autoClose: false,
  autoCloseDuration: 3000,
  hideActions: false
};

let intervalId = null;
const intervalSpeed = 50;

const PopupProvider = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');

  /**
   * @type {state<config>}
   */
  const [config, setConfig] = useState(defaultConfig);

  const [isOpen, open, close] = useToggle();

  function stopCounter() {
    clearInterval(intervalId);
    setProgress(0);
  }

  function startCounter(duration = 3000) {
    clearInterval(intervalId);

    const loopCount = duration / intervalSpeed;
    const increasePerLoop = 100 / loopCount;

    intervalId = setInterval(() => {
      setProgress(state => {
        if (state >= 100) {
          close();
          stopCounter();
        }

        return state + increasePerLoop;
      });
    }, intervalSpeed);
  }

  /**
   *
   * @param {any} message
   * @param {config} config
   */
  function popup(message, config = {}) {
    setMessage(message || 'Something went wrong!');

    const newConfig = { ...defaultConfig, ...config };

    setConfig(newConfig);
    open();

    if (newConfig.autoClose) startCounter(newConfig.autoCloseDuration);
  }

  return (
    <PopupContext.Provider value={{ popup }}>
      <Dialog fullWidth maxWidth="xs" open={isOpen} TransitionComponent={Zoom}>
        {config.autoClose && (
          <LinearProgress variant="determinate" value={progress} />
        )}
        <DialogTitle disableTypography>
          <Typography variant={config.titleVariant}>{config.title}</Typography>
        </DialogTitle>
        <DialogContent>
          {config.severity === 'error'
            ? parseError(message)
            : parseMessage(message)}
        </DialogContent>
        {!config.hideActions && (
          <DialogActions>
            <Button variant="contained" color="primary" onClick={close}>
              Close
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {children}
    </PopupContext.Provider>
  );
};

export default PopupContext;
export { PopupProvider };
