import React from 'react';
import {
  Box,
  Popover,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup
} from '@material-ui/core';

function Filters({
  anchorElement = null,
  accountTypes,
  onChangeAccountTypes,
  onCloseFilter
}) {
  return (
    <Popover
      id={Boolean(anchorElement) ? 'filter-account' : undefined}
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      onClose={onCloseFilter}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Box p={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Account Types</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountTypes.includes(1)}
                  onChange={onChangeAccountTypes}
                  name="1"
                />
              }
              label="Assets"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountTypes.includes(2)}
                  onChange={onChangeAccountTypes}
                  name="2"
                />
              }
              label="Liabilities"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountTypes.includes(3)}
                  onChange={onChangeAccountTypes}
                  name="3"
                />
              }
              label="Equity"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountTypes.includes(4)}
                  onChange={onChangeAccountTypes}
                  name="4"
                />
              }
              label="Revenue"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountTypes.includes(5)}
                  onChange={onChangeAccountTypes}
                  name="5"
                />
              }
              label="Expenses"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountTypes.includes(6)}
                  onChange={onChangeAccountTypes}
                  name="6"
                />
              }
              label="Other Income"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountTypes.includes(7)}
                  onChange={onChangeAccountTypes}
                  name="7"
                />
              }
              label="Other Expenses"
            />
          </FormGroup>
        </FormControl>
      </Box>
    </Popover>
  );
}

export default Filters;
