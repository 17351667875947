import React from 'react';
import Page from 'src/components/Page';
import Hero from './Hero';
import Benefits from './Benefits';
import Features from './Features';
import Footer from 'src/views/landingPage/Footer';
import CTA from './CTA';

const Main = () => {
  return (
    <Page title="Construction">
      <Hero />
      <Benefits />
      <Features />
      <CTA />
      <Footer />
    </Page>
  );
};

export default Main;
