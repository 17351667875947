import IBFRS3Layout from 'src/layouts/IBFRS3Layout';
import IBFRSMainPage from 'src/views/landingPage/IBFRS3/MainPage';
import Contact from 'src/views/landingPage/iBFRS/Contact';
import BenefitsPage from 'src/views/landingPage/iBFRS/BenefitsPage';
import SupportPage from 'src/views/landingPage/Support';
import NotFoundView from 'src/views/errors/NotFoundView';

const ibfrsRoutes = [
  {
    path: '',
    element: IBFRS3Layout,
    children: [
      { path: '/', element: IBFRSMainPage },
      { path: 'contact-us', element: Contact },
      { path: 'benefits', element: IBFRSMainPage },
      { path: 'benefits/more', element: BenefitsPage },
      { path: 'anchors', element: IBFRSMainPage },
      { path: 'testimonials', element: IBFRSMainPage },
      { path: 'support', element: SupportPage }
    ]
  },
  {
    path: '*',
    element: NotFoundView
  }
];

export default ibfrsRoutes;
