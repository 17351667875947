import { Navigate } from 'react-router-dom';
import LandingPageLayout from 'src/layouts/LandingPageLayout';
import RadztechLayout from 'src/layouts/RadztechLayout';
import UlapBiz from 'src/views/landingPage/ulapBiz';
import SubSystems from 'src/views/landingPage/SubSystems';
import Terms from 'src/views/landingPage/Terms';
import PlanDetails from 'src/views/landingPage/PlanDetails';
import ResetPassword from 'src/views/auth/ResetPassword';
import ContactUs from 'src/views/landingPage/ContactUs/ContactUs';
import SupportPage from 'src/views/landingPage/Support';
import NotFoundView from 'src/views/errors/NotFoundView';
import MotorcycleDealers from 'src/views/landingPage/Solutions/MotorcycleDealers';
import Construction from 'src/views/landingPage/Solutions/Construction';
import UlapTax from 'src/views/landingPage/ulapTax';
import Radztech from 'src/views/landingPage/Radztech';
import Contact from 'src/views/landingPage/iBFRS/Contact';
import IBFRSTerms from 'src/views/landingPage/iBFRS/Terms';
import IBFRSMainPage from 'src/views/landingPage/IBFRS3/MainPage';
import IBFRS3Layout from 'src/layouts/IBFRS3Layout';
import DynamicPage from 'src/views/landingPage/DynamicPage';

import portalLoginRoutes from './portalLoginRoutes';
import appLoginRoutes from './appLoginRoutes';
import Header from 'src/views/landingPage/Radztech/Header';

const ulapBizGuestRoutes = [
  {
    path: '/login',
    element: Navigate,
    elementProps: { to: '/app/login' }
  },
  {
    path: '/register',
    element: Navigate,
    elementProps: { to: '/app/register' }
  },
  {
    path: '/register/:inviteCode',
    element: Navigate,
    elementProps: { to: '/app/register/:inviteCode' }
  },
  ...portalLoginRoutes,
  ...appLoginRoutes,
  {
    path: '/',
    element: LandingPageLayout,
    children: [
      { path: '/', element: UlapBiz },
      { path: '/beyond-accounting/:key', element: SubSystems },
      { path: '/terms', element: Terms },
      { path: '/plans', element: UlapBiz },
      { path: '/plans-details', element: PlanDetails },
      { path: '/reports-and-schedule', element: UlapBiz },
      { path: '/notable-features', element: UlapBiz },
      { path: '/accounting-and-beyond', element: UlapBiz },
      {
        path: 'solutions',
        children: [
          { path: 'motorcycle-dealers', element: MotorcycleDealers },
          {
            path: 'construction',
            element: Construction
          }
        ]
      },
      { path: '/tax', element: UlapTax },

      { path: '/reset-password', element: ResetPassword },

      { path: '/contact-us', element: ContactUs },
      { path: '/support', element: SupportPage },
      { path: 'terms', element: Terms },
      { path: '/pages/:cdPage', element: DynamicPage },
      { path: '404', element: NotFoundView },
      { path: '*', element: Navigate, elementProps: { to: '/404' } }
    ]
  },
  {
    path: 'ibfrs',
    element: IBFRS3Layout,
    elementProps: { fromUlap: true },
    children: [
      { path: '', element: IBFRSMainPage },
      { path: 'contact-us', element: Contact },
      {
        path: 'register',
        element: Navigate,
        elementProps: { to: '/app/register' }
      },
      { path: 'benefits', element: IBFRSMainPage },
      { path: 'anchors', element: IBFRSMainPage },
      { path: 'testimonials', element: IBFRSMainPage },
      { path: 'terms', element: IBFRSTerms },
      { path: '404', element: NotFoundView }
    ]
  },
  {
    path: '/',
    element: RadztechLayout,
    children: [{ path: '/radztech', element: Radztech }]
  },
  {
    path: '/radztech/bg',
    element: Header
  }
];

export default ulapBizGuestRoutes;
