import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Container,
  Button,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Event } from '@material-ui/icons';
import { Animated } from 'src/hooks/useReveal';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  description: {
    lineHeight: '1.8rem',
    fontSize: '1rem',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.5rem',
      textAlign: 'center'
    }
  },
  description2: {
    lineHeight: '1.8rem',
    fontSize: '1rem',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      textAlign: 'center'
    }
  },
  headerTitle: {
    display: 'block',
    fontWeight: 900,
    fontSize: '3em',
    [theme.breakpoints.down('md')]: {
      fontSize: '3em'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.3em'
    }
  },
  image: {
    clipPath: `polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)`,
    // filter: `grayScale(50%)`,
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)'
    }
  },
  mainTitle: {
    fontSize: '1.8rem',
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  flex: {
    display: 'flex',
    gridGap: '6em',
    [theme.breakpoints.down('md')]: {
      gridGap: '3em'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gridGap: '2.5em'
    }
  },
  logo: {
    height: '3.5em',
    width: 'auto',
    borderRadius: '1em',
    [theme.breakpoints.down('md')]: {
      height: '3.5em'
    },
    [theme.breakpoints.down('sm')]: {
      height: '3em'
    }
  },
  ulapColor: {
    color: '#ff7704'
  },
  prodContainer: {
    display: 'flex',
    gridGap: '.3em',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  bookDemo: {
    background: '#1976D2',
    color: '#fff',
    '&:hover': {
      background: '#1976D2',
      color: '#fff'
    }
  },
  back: {
    background: 'transparent',
    color: '#1976D2',
    border: '.1em solid #1976D2',
    '&:hover': {
      background: 'transparent',
      color: '#1976D2'
    }
  },
  buttonContainer: {
    display: 'flex',
    gap: '1em',
    marginTop: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}));

const Hero = ({ data }) => {
  const classes = useStyles(data);

  return (
    <div className={clsx(classes.root)}>
      <Container maxWidth="lg">
        <Box className={classes.flex} key={data?.title}>
          <Box flex={1}>
            <Animated
              from={{
                opacity: 0,
                y: -50
              }}
              to={{
                opacity: 1,
                y: 0
              }}
              config={{
                duration: 500
              }}
            >
              <Box className={classes.prodContainer}>
                <img
                  alt="Records"
                  src={data?.logo}
                  className={classes.logo}
                  loading="lazy"
                />

                <Box>
                  <Typography
                    color="textPrimary"
                    variant="h1"
                    className={classes.headerTitle}
                  >
                    <span className={classes.ulapColor}>{data.title1}</span>
                    {data.title2}
                  </Typography>
                </Box>
              </Box>
            </Animated>
            <Animated
              from={{
                opacity: 0,
                y: 50
              }}
              to={{
                opacity: 1,
                y: 0
              }}
              delay={300}
              config={{
                duration: 500
              }}
            >
              <Typography
                variant="body1"
                className={classes.description}
                color="textSecondary"
              >
                {data.s1}
              </Typography>
            </Animated>
            <Animated
              from={{
                opacity: 0,
                y: 50
              }}
              to={{
                opacity: 1,
                y: 0
              }}
              delay={600}
              config={{
                duration: 500
              }}
            >
              <Typography
                variant="body1"
                className={classes.description2}
                color="textSecondary"
              >
                {data.s2}
              </Typography>
            </Animated>

            <Animated
              from={{
                opacity: 0,
                y: 50
              }}
              to={{
                opacity: 1,
                y: 0
              }}
              delay={900}
              config={{
                duration: 500
              }}
            >
              <Box className={classes.buttonContainer}>
                <Button
                  component={NavLink}
                  to="/contact-us"
                  variant="contained"
                  size="large"
                  className={classes.bookDemo}
                  startIcon={<Event />}
                >
                  Book Demo
                </Button>
                <Button
                  component={NavLink}
                  to={'/accounting-and-beyond'}
                  variant="contained"
                  size="large"
                  className={classes.back}
                >
                  Back
                </Button>
              </Box>
            </Animated>
          </Box>
          <Box flex={1}>
            <Animated
              from={{
                opacity: 0,
                x: 50
              }}
              to={{
                opacity: 1,
                x: 0
              }}
              config={{
                duration: 500
              }}
            >
              <img
                alt="Records"
                src={data?.heroPhoto}
                className={classes.image}
                loading="lazy"
              />
            </Animated>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Hero;
