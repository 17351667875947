import React from 'react';
import { useDynamicPages } from './DynamicPageProvider';
import { TEMPLATES } from './DynamicPageProvider';

function GeneratePage() {
  const { pageDetails } = useDynamicPages();
  const template = pageDetails?.jsonSettings?.template ?? 'template1';
  const Element = TEMPLATES[template];
  return <Element />;
}

export default GeneratePage;
