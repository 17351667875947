import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useEnv } from 'src/hooks';

const PageRefsContext = React.createContext({
  plans: null,
  header: null,
  report: null,
  notable: null,
  anb: null,
  accounting: null,
  scroller: null,
  benefits: null,
  anchors: null,
  ibfrsHero: null,
  testimonials: null
});

const PageRefsProvider = ({ children }) => {
  const { pathname, key } = useLocation();
  const { EGOV } = useEnv();

  const scroller = useRef();

  // ulapBiz
  const plans = useRef();
  const header = useRef();
  const report = useRef();
  const notable = useRef();
  const anb = useRef();
  const accounting = useRef();

  // iBFRS
  const benefits = useRef();
  const anchors = useRef();
  const ibfrsHero = useRef();
  const testimonials = useRef();

  useEffect(() => {
    if (!EGOV && pathname === '/' && header.current)
      header.current.scrollIntoView({
        behavior: 'smooth'
      });
    else if (
      EGOV &&
      (pathname === '/' || pathname === 'ibfrs') &&
      ibfrsHero.current
    )
      ibfrsHero.current.scrollIntoView({
        behavior: 'smooth'
      });
    else if (pathname === '/plans' && plans.current)
      plans.current.scrollIntoView({
        behavior: 'smooth'
      });
    else if (pathname === '/reports-and-schedule' && report.current)
      report.current.scrollIntoView({
        behavior: 'smooth'
      });
    else if (pathname === '/accounting-and-beyond' && anb.current)
      anb.current.scrollIntoView({
        behavior: 'smooth'
      });
    else if (pathname === '/notable-features' && notable.current)
      notable.current.scrollIntoView({
        behavior: 'smooth'
      });
    else if (
      (pathname === '/ibfrs/benefits' || pathname === '/benefits') &&
      benefits.current
    )
      benefits.current.scrollIntoView({
        behavior: 'smooth'
      });
    else if (
      (pathname === '/ibfrs/anchors' || pathname === '/anchors') &&
      anchors.current
    )
      anchors.current.scrollIntoView({
        behavior: 'smooth'
      });
    else if (
      (pathname === '/ibfrs/testimonials' || pathname === '/testimonials') &&
      testimonials.current
    )
      testimonials.current.scrollIntoView({
        behavior: 'smooth'
      });
    else {
      if (scroller.current) scroller.current.scrollTo(0, 0);
      else window.scrollTo(0, 0);
    }
  }, [pathname, key]);

  return (
    <PageRefsContext.Provider
      value={{
        plans,
        header,
        report,
        notable,
        anb,
        accounting,
        scroller,
        benefits,
        anchors,
        ibfrsHero,
        testimonials
      }}
    >
      {children}
    </PageRefsContext.Provider>
  );
};

export { PageRefsProvider };
export default PageRefsContext;
