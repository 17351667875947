import React, { useState } from 'react';
import {
  Box,
  Typography,
  Link,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LongText from './LongText';
import { applyPagination } from 'src/helpers/pagination';
import { useToggle } from 'src/hooks';

const formatValue = (value, level = 0) => {
  if (value === null) {
    return '';
  }
  if (Array.isArray(value)) {
    return formatArray(value, level);
  }
  if (typeof value === 'object') {
    return formatObject(value, level);
  }
  return `  ${value}`;
};

const formatObject = (obj, level) => {
  if (Object.keys(obj).length === 0) {
    return '';
  }

  let result = '';
  for (const [key, value] of Object.entries(obj)) {
    result += `<div style="padding-left: ${level * 20}px;">${key}:${formatValue(
      value,
      level + 1
    )}</div>`;
  }
  return result;
};

const formatArray = (arr, level) => {
  if (arr.length === 0) {
    return '[]';
  }

  let result = '';
  arr.forEach((item, index) => {
    result += `<div style="padding-left: ${level * 20}px;">${index +
      1}. ${formatValue(item, level)}</div>`;
  });
  return result;
};

const formatJson = json => {
  if (Array.isArray(json)) {
    return formatArray(json, 0);
  }
  return formatValue(json);
};

export const ErrorsInItems = ({ errors }) => {
  const flds = Object.entries(errors?.flds ?? {})
    .map(([key, value]) => ({
      key,
      hidden: value?.hidden ?? false,
      label: value?.label ?? ''
    }))
    .filter(item => !item.hidden);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {flds.map(item => (
              <TableCell key={item.label}>{item.label}</TableCell>
            ))}
            {Boolean(errors?.flds?.['jid']) && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {errors.items.map(item => (
            <TableRow item={item.jid}>
              {flds.map(fld => (
                <TableCell key={fld}>{item?.[fld.key]}</TableCell>
              ))}
              {Boolean(item?.['jid']) && (
                <TableCell align="right">
                  <Tooltip title="Open in New Tab">
                    <IconButton
                      size="small"
                      onClick={() => {
                        window.open(`/app/trans/${item.jid}`, '_blank');
                      }}
                    >
                      <OpenInNewIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ErrorsInArray = ({ errors }) => {
  const view_type = typeof errors?.[0] ?? {};

  if (view_type === 'object') {
    const flds = Object.keys(errors?.[0] ?? {});
    return (
      <Box ml={2} mt={1}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {flds.map(fld => (
                  <TableCell key={fld}>{fld}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {errors.map((item, i) => (
                <TableRow item={i}>
                  {flds.map(fld => (
                    <TableCell key={fld}>{item?.[fld]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  } else {
    return (
      <Box ml={2} mt={1}>
        <ul>
          {errors.map((item, i) => (
            <li key={i}>
              <Typography variant="caption" display="block">
                {item}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    );
  }
};

export const ErrorDetails = ({ errors }) => {
  if (!errors) return null;

  if (errors?.items && Array.isArray(errors?.items))
    return <ErrorsInItems errors={errors} />;

  if (Array.isArray(errors)) return <ErrorsInArray errors={errors} />;

  return (
    <Box
      style={{
        whiteSpace: 'pre-wrap'
      }}
      dangerouslySetInnerHTML={{ __html: formatJson(errors) }}
    />
  );
};

export const ErrorItem = ({ data, useDialog = false }) => {
  const [viewDetails, setViewDetails] = useState(false);

  if (typeof data === 'string') {
    return <Typography variant="body2">{data}</Typography>;
  } else
    return (
      <Box>
        <Link
          component="button"
          onClick={() => setViewDetails(prev => !prev)}
          variant="body2"
          color="textPrimary"
          style={{
            textAlign: 'left',
            cursor: 'pointer',
            display: 'flex',
            gap: '.1em',
            fontWeight: 'bold',
            alignItems: 'center'
          }}
          underline="none"
        >
          {Boolean(data?.ob) ? (
            <span style={{ marginLeft: '-1em' }}>
              {viewDetails ? (
                <ExpandMoreIcon fontSize="inherit" />
              ) : (
                <ChevronRightIcon fontSize="inherit" />
              )}
            </span>
          ) : (
            <span />
          )}
          <LongText text={data?.msg} maxLength={100} disableTypography />
        </Link>

        {useDialog && (
          <Dialog
            open={viewDetails}
            onClose={() => setViewDetails(prev => !prev)}
          >
            <DialogTitle disableTypography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" color="textSecondary">
                  Details
                </Typography>

                <IconButton
                  size="small"
                  onClick={() => setViewDetails(prev => !prev)}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <ErrorDetails errors={data?.ob} />
          </Dialog>
        )}
        {!useDialog && viewDetails && <ErrorDetails errors={data?.ob} />}
      </Box>
    );
};

export const FormViewError = ({
  title = '',
  errorList = [],
  containerProps = {
    my: 1,
    width: '100%'
  },
  useDialog = false,
  ...rest
}) => {
  const [showAll, _, __, toggle] = useToggle(false);

  return (
    (errorList.length !== 0 || title !== '') && (
      <Box {...containerProps}>
        <Alert severity="error" {...rest}>
          <AlertTitle>{title === '' ? 'Failed' : title}</AlertTitle>
          {applyPagination(errorList, 0, showAll ? errorList.length : 5).map(
            (data, i) => (
              <ErrorItem data={data} useDialog={useDialog} key={i} />
            )
          )}

          {errorList.length > 5 && (
            <Button
              onClick={toggle}
              size="small"
              color="inherit"
              variant="contained"
            >
              {showAll ? 'Less' : 'Show'}
            </Button>
          )}
        </Alert>
      </Box>
    )
  );
};
