import React from 'react';
import {
  Button,
  FormControl,
  SvgIcon,
  Menu,
  MenuItem
} from '@material-ui/core';
import { BarChart2 } from 'react-feather';
import useMenu from 'src/hooks/useMenuV2';

const CHART_TYPES = [
  {
    value: 'bar',
    text: 'Bar'
  },
  {
    value: 'stackedBar',
    text: 'Stacked'
  },
  {
    value: 'line',
    text: 'Line'
  }
];

const ChartSelect = ({ isLoading, chartType, setChartType }) => {
  const [anchorEl, openMenu, closeMenu] = useMenu();

  return (
    <FormControl component="fieldset" disabled={isLoading}>
      <Button
        onClick={openMenu}
        startIcon={
          <SvgIcon fontSize="small">
            <BarChart2 />
          </SvgIcon>
        }
        color="primary"
      >
        {CHART_TYPES.find(cType => cType.value === chartType).text}
      </Button>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {CHART_TYPES.map(cType => (
          <MenuItem key={cType.value} onClick={() => setChartType(cType.value)}>
            {cType.text}
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );
};

export default ChartSelect;
