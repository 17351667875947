import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNotif, useRequest, useToggle } from 'src/hooks';
import { useParams } from 'react-router';

const UserContext = createContext({
  user: {
    ixUser: 0,
    username: '',
    LName: '',
    FName: '',
    MName: '',
    SName: '',
    email: '',
    phone: '',
    active: 0,
    phone_valid: 0,
    email_valid: 0,
    require_change_pw: 0
  },
  onChangeUserDetails: () => null,
  getName: () => '',
  getStatusDescription: () => '',
  isEditing: false,
  setIsEditing: () => null,
  isEditingContact: false,
  setIsEditingContact: () => null,
  saveDetails: params => null,
  saveContacts: params => '',
  isLoading: false,
  loadingOff: () => null,
  loadingOff: () => null,
  bizList: [],
  isSaving: false,
  openEmail: () => null,
  setOpenEmail: () => null,
  openSMS: () => null,
  setOpenSMS: () => null,
  activateShown: false,
  deactivateShown: false,
  showActivate: () => null,
  hideActivate: () => null,
  showDeactivate: () => null,
  hideDeactivate: () => null,
  errors: {},
  getErrors: () => null,
  isSaving: false,
  sendPhoneActivation: () => null,
  isSendingOTP: false,
  isSendingEmailActivationCode: false,
  sendEmailActivation: () => null,
  allowingMultipleLogin: false,
  allowMultipleLogin: () => null,
  allowMultipleLoginRemarks: '',
  setAllowMultipleLoginRemarks: () => null,
  setUserStatus: () => null,
  isPhoneActivating: false,
  activatePhone: () => null,
  phoneActivationShown: false,
  showPhoneActivation: () => null,
  hidePhoneActivation: () => null,
  otp: '',
  setOTP: () => null,
  changePasswordLoading: false,
  changePasswordLoadOn: () => null,
  changePasswordLoadOff: () => null,
  requireChangePassword: params => null,

  savedErrors: {},
  setSavedErrors: () => null
});

function UserProvider({ children }) {
  const [user, setUser] = useState({
    ixUser: 0,
    username: '',
    LName: '',
    FName: '',
    MName: '',
    SName: '',
    email: '',
    phone: '',
    active: 0,
    phone_valid: 0,
    email_valid: 0,
    require_change_pw: 0
  });
  const [openSMS, setOpenSMS] = useState({
    user: {
      fullName: '',
      phone_number: '',
      username: ''
    },
    open: false
  });
  const [openEmail, setOpenEmail] = useState({
    user: {
      fullName: '',
      email: '',
      username: ''
    },
    open: false
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingContact, setIsEditingContact] = useState(false);
  const [isSaving, saveOn, saveOff] = useToggle();

  const [isLoading, loadingOn, loadingOff] = useToggle();
  const [activateShown, showActivate, hideActivate] = useToggle();
  const [deactivateShown, showDeactivate, hideDeactivate] = useToggle();
  const [isSendingOTP, sendOTPloadOn, sendOTPloadOff] = useToggle();
  const [
    isSendingEmailActivationCode,
    sendEmailActivationCodeLoadOn,
    sendEmailActivationCodeLoadOff
  ] = useToggle();
  const [
    isPhoneActivating,
    phoneActivationLoadOn,
    phoneActivationLoadOff
  ] = useToggle();
  const [
    allowingMultipleLogin,
    allowingMultipleLoginLoadOn,
    allowingMultipleLoginLoadOff
  ] = useToggle();
  const [
    changePasswordLoading,
    changePasswordLoadOn,
    changePasswordLoadOff
  ] = useToggle();
  const [allowMultipleLoginRemarks, setAllowMultipleLoginRemarks] = useState(
    ''
  );
  const [
    phoneActivationShown,
    showPhoneActivation,
    hidePhoneActivation
  ] = useToggle();
  const [savedErrors, setSavedErrors] = useState({});
  const [otp, setOTP] = useState('');

  const [bizList, setBizList] = useState([]);
  const [errors, setErrors] = useState({});
  const params = useParams();
  const req = useRequest();
  const notif = useNotif();

  function onChangeUserDetails({ target: { name, value } }) {
    setUser(prev => ({ ...prev, [name]: value }));
  }

  function getName() {
    if (user.MName === '') return `${user.FName} ${user.LName} ${user.SName}`;

    return `${user.FName} ${user.MName} ${user.LName} ${user.SName}`;
  }

  function getStatusDescription() {
    return user.active === 1 ? 'Active' : 'Inactive';
  }

  async function saveDetails(values) {
    saveOn();

    const { active, phone_valid, email_valid, ...payload } = {
      ...user,
      ...values
    };

    const { success, error } = await req.put(
      '/registry/user/update-item',
      payload
    );
    saveOff();

    if (!success) {
      notif.error(error?.msg ?? 'Failed!');
      setErrors(error?.data?.validation_errors ?? {});
      return;
    }

    setIsEditing(false);
    setUser(prev => ({ ...prev, ...values }));
    setErrors({});
    notif.success('Saved!');
  }

  async function saveContacts(values) {
    saveOn();

    const { active, phone_valid, email_valid, ...payload } = {
      ...user,
      ...values
    };

    const { success, error } = await req.put(
      '/registry/user/update-item',
      payload
    );
    saveOff();

    if (!success) {
      notif.error(error?.msg ?? 'Failed!');
      setErrors(error?.data?.validation_errors ?? {});
      return;
    }

    setIsEditingContact(false);
    setUser(prev => {
      const copyValues = { ...values };

      if (prev.email !== values.email) copyValues.email_valid = 0;

      if (prev.phone !== values.phone) copyValues.phone_valid = 0;

      return { ...prev, ...copyValues };
    });
    setErrors({});
    notif.success('Saved!');
  }

  function getErrors(key) {
    return errors?.[key] || [];
  }

  async function loadDetails(cancelTokens) {
    const urls = [
      { url: `/registry/user/${params.user}` },
      { url: `/registry/user/${params.user}/biz` }
    ];
    loadingOn();
    const [userDetails, biz] = await req.multiFetch(urls, token => {
      cancelTokens.push(token);
    });
    loadingOff();

    if (userDetails.success) {
      setUser(prev => {
        const copy = { ...prev };

        copy.FName = userDetails.data.FName;
        copy.LName = userDetails.data.LName;
        copy.MName = userDetails.data.MName;
        copy.SName = userDetails.data.SName;
        copy.email = userDetails.data.email;
        copy.phone = userDetails.data.phone;
        copy.username = userDetails.data.username;
        copy.ixUser = params.user;
        copy.active = userDetails.data.active;
        copy.phone_valid = userDetails.data.phone_valid;
        copy.email_valid = userDetails.data.email_valid;
        copy.require_change_pw = userDetails.data.require_change_pw;

        return copy;
      });
    }

    if (biz.success) setBizList(biz.data);
  }

  async function sendPhoneActivation() {
    sendOTPloadOn();
    const { success, error } = await req.post(
      '/registry/user/send-phone-activation',
      {
        username: user.username
      }
    );
    sendOTPloadOff();

    if (!success) {
      setSavedErrors(prev => ({
        ...prev,
        sendOTP: {
          ...prev.sendOTP,
          msg: error?.data?.msg ?? 'Send OTP failed',
          errors: error?.data?.errors ?? []
        }
      }));

      notif.error('OTP send failed');
      return;
    }

    setSavedErrors(prev => ({
      ...prev,
      sendOTP: {}
    }));
    notif.success('OTP has been sent!');
  }

  async function sendEmailActivation() {
    sendEmailActivationCodeLoadOn();

    const { success, error } = await req.post(
      `/registry/user/${params.user}/send-verification-email`
    );
    sendEmailActivationCodeLoadOff();

    if (!success) {
      setSavedErrors(prev => ({
        ...prev,
        verifyEmail: {
          ...prev.verifyEmail,
          msg: error?.data?.msg ?? 'Send email verification failed',
          errors: error?.data?.errors ?? []
        }
      }));
      notif.error('Send email verification failed');
      return;
    }

    setSavedErrors(prev => ({
      ...prev,
      verifyEmail: {}
    }));
    notif.success('Email Verification Sent Successfully!');
  }

  async function allowMultipleLogin({ remarks }) {
    if (remarks === '' || typeof remarks === 'undefined' || remarks === null) {
      notif.error('Remarks is required.');
      return;
    }

    allowingMultipleLoginLoadOn();
    const { success, error } = await req.post(
      `/registry/user/allow-multiple-logins`,
      {
        ixUser: params.user,
        remarks
      }
    );
    allowingMultipleLoginLoadOff();

    if (!success) {
      setSavedErrors(prev => ({
        ...prev,
        allowMultipleLogin: {
          ...prev.allowMultipleLogin,
          msg: error?.data?.msg ?? 'Failed',
          errors: error?.data?.errors ?? []
        }
      }));

      notif.error(error?.data?.msg ?? 'Failed : Please try again.');
      return;
    }

    setAllowMultipleLoginRemarks('');
    setSavedErrors(prev => ({
      ...prev,
      allowMultipleLogin: {}
    }));
    notif.success('Multiple login enabled to this account.');
  }

  function setUserStatus({ status }) {
    setUser(prev => ({ ...prev, active: status }));
  }

  async function activatePhone() {
    phoneActivationLoadOn();
    const { success, error } = await req.post(`/registry/user/phone-activate`, {
      username: user.username,
      otp
    });
    phoneActivationLoadOff();

    if (!success) {
      setSavedErrors(prev => ({
        ...prev,
        phoneActivation: {
          ...prev.phoneActivation,
          msg: error?.data?.msg ?? 'Phone activation failed',
          errors: error?.data?.errors ?? []
        }
      }));
      return;
    }

    setUser(prev => ({
      ...prev,
      phone_valid: 1
    }));
    setOTP('');
    hidePhoneActivation();
    setSavedErrors(prev => ({
      ...prev,
      phoneActivation: {}
    }));
    notif.success('Phone successfully activated');
  }

  async function requireChangePassword({ require = 0 }) {
    changePasswordLoadOn();
    const { active, phone_valid, email_valid, ...payload } = {
      ...user,
      require_change_pw: require
    };

    const { success, error } = await req.put(
      '/registry/user/update-item',
      payload
    );
    changePasswordLoadOff();

    if (!success) {
      setSavedErrors(prev => ({
        ...prev,
        reqChangePass: {
          ...prev.reqChangePass,
          msg: error?.data?.msg ?? 'Failed',
          errors: error?.data?.errors ?? []
        }
      }));
      notif.error(error?.data?.msg ?? 'Failed : Please try again.');
      return;
    }

    setUser(prev => ({
      ...prev,
      require_change_pw: require
    }));
    setSavedErrors(prev => ({
      ...prev,
      reqChangePass: {}
    }));
    notif.error(error?.data?.msg ?? 'Failed : Please try again.');
    notif.success('Saved!');
  }

  useEffect(() => {
    let cancelTokens = [];

    loadDetails(cancelTokens);

    return () => {
      cancelTokens.forEach(token => {
        token();
      });
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        onChangeUserDetails,
        getName,
        getStatusDescription,
        isEditing,
        setIsEditing,
        isEditingContact,
        setIsEditingContact,
        saveDetails,
        saveContacts,
        isLoading,
        loadingOff,
        loadingOff,
        bizList,
        isSaving,
        openEmail,
        setOpenEmail,
        openSMS,
        setOpenSMS,
        activateShown,
        deactivateShown,
        showActivate,
        hideActivate,
        showDeactivate,
        hideDeactivate,
        errors,
        getErrors,
        isSaving,
        sendPhoneActivation,
        isSendingOTP,
        isSendingEmailActivationCode,
        sendEmailActivation,
        allowingMultipleLogin,
        allowMultipleLogin,
        allowMultipleLoginRemarks,
        setAllowMultipleLoginRemarks,
        setUserStatus,
        isPhoneActivating,
        activatePhone,
        phoneActivationShown,
        showPhoneActivation,
        hidePhoneActivation,
        otp,
        setOTP,
        changePasswordLoading,
        requireChangePassword,
        savedErrors,
        setSavedErrors
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => useContext(UserContext);

export default UserProvider;
