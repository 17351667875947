import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useBranchLimits = (withLimitsOnly = false) => {
  const { userBranchLimits = [] } = useSelector(state => state.bizMeta);

  const { branches = [] } = useSelector(state => state.storedValues);

  const branchUsers = useMemo(() => {
    const res = {};

    branches.forEach(({ ixBrch, sBrch }) => {
      const users = [];

      userBranchLimits.forEach(({ limits, UserID }) => {
        if (withLimitsOnly && !limits.length) return;

        if (!limits.length || limits.some(({ ixSub }) => ixSub === ixBrch))
          users.push(UserID);
      });

      res[ixBrch] = {
        ixBrch,
        sBrch,
        users
      };
    });

    return res;
  }, [userBranchLimits, branches, withLimitsOnly]);

  return { branchUsers };
};

export default useBranchLimits;
