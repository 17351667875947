import React from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  useTheme,
  Tooltip
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 50,
    paddingBottom: 50
  },
  image: {
    display: 'block',
    height: 'auto',
    width: 100,
    margin: '0 auto',
    transition: 'all 300ms ease-out',
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-.5em)'
    }
  },
  slider: {
    margin: 'auto',
    overflow: 'hidden',
    position: 'relative',
    '&:before': {
      background: `linear-gradient(to right,  ${theme.palette.background.dark} 0%,rgba(255,255,255,0) 100%)`,
      content: "''",
      height: '100%',
      position: 'absolute',
      width: 200,
      zIndex: 2,
      left: 0,
      top: 0
    },
    '&:after': {
      background: `linear-gradient(to right,  ${theme.palette.background.dark} 0%,rgba(255,255,255,0) 100%)`,
      content: "''",
      height: '100%',
      position: 'absolute',
      width: 200,
      zIndex: 2,
      right: 0,
      top: 0,
      transform: 'rotateZ(180deg)'
    },
    '& .slideTrack': {
      animation: '$scroll 60s linear infinite',
      display: 'flex',
      width: `calc(250px * ${IMAGES.length})`,
      height: 100,
      '&:hover': {
        animationPlayState: 'paused'
      }
    },
    '& .slide': {
      height: '100%',
      width: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  '@keyframes scroll': {
    '0%': { transform: `translateX(calc(-250px * ${IMAGES.length / 2}))` },
    '100%': { transform: 'translateX(0)' }
  }
}));

const PRODUCTS = [
  { key: 'cdo', name: 'CDO', img: '/static/images/clients_products/cdo.webp' },
  {
    key: 'dm',
    name: 'Del Monte',
    img: '/static/images/clients_products/del_monte.png'
  },
  {
    key: 'honda',
    name: 'Honda',
    img: '/static/images/clients_products/honda.png'
  },
  {
    key: 'kawasaki',
    name: 'Kawasaki',
    img: '/static/images/clients_products/kawasaki.png'
  },
  {
    key: 'kubota',
    name: 'Kubota',
    img: '/static/images/clients_products/kubota.png'
  },
  { key: 'lg', name: 'LG', img: '/static/images/clients_products/lg.png' },
  {
    key: 'ms',
    name: 'Mega Sardines',
    img: '/static/images/clients_products/mega_sardines.webp'
  },
  {
    key: 'palmolive',
    name: 'Palmolive',
    img: '/static/images/clients_products/palmolive.png'
  },
  {
    key: 'panasonic',
    name: 'Panasonic',
    img: '/static/images/clients_products/panasonic.png'
  },
  {
    key: 'sharp',
    name: 'Sharp',
    img: '/static/images/clients_products/sharp.png'
  },
  {
    key: 'sony',
    name: 'Sony',
    img: '/static/images/clients_products/sony.png'
  },
  {
    key: 'suzuki',
    name: 'Suzuki',
    img: '/static/images/clients_products/suzuki.png'
  },
  {
    key: 'valvoline',
    name: 'Valvoline',
    img: '/static/images/clients_products/valvoline.png'
  },
  { key: 'vs1', name: 'VS1', img: '/static/images/clients_products/vs1.png' },
  {
    key: 'yamaha',
    name: 'Yamaha',
    img: '/static/images/clients_products/yamaha.png'
  },
  {
    key: 'gulf',
    name: 'Gulf',
    img: '/static/images/clients_products/gulf.png'
  },
  { key: 'faw', name: 'FAW', img: '/static/images/clients_products/faw.png' }
];

const IMAGES = [
  ...PRODUCTS,
  ...PRODUCTS.map(product => ({ ...product, key: product.key + '-clone' }))
];

const Products = () => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ fontWeight: 700, marginBottom: theme.spacing(3) }}
        >
          Provided systems for the dealers of the following:
        </Typography>
        <Box className={classes.slider}>
          <Box className="slideTrack">
            {IMAGES.map(({ img, name, key }) => (
              <Box className="slide" key={key}>
                <img src={img} alt={name} className={classes.image} />
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Products;
