import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { orange, green, blue, red } from '@material-ui/core/colors';
import {
  Box,
  Grid,
  Container,
  Typography,
  makeStyles,
  Card,
  CardContent,
  Button,
  List,
  Hidden
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import SendOutlinedIcon from '@material-ui/icons/SendOutlined';

import { createBrowserHistory } from 'history';

import FeatureItem from './FeatureItem';

import data from '../data';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: orange[400],
    paddingTop: 70,
    paddingBottom: 70
  },

  mainTitle: {
    fontSize: '2.5rem',
    fontWeight: 800
  },
  smallTitle: {
    color: '#fff',
    textAlign: 'center',
    display: 'block',
    fontWeight: 900,
    fontSize: '1.5rem',
    padding: '.5em 0'
  },
  secondTitle: {
    color: '#fff',
    marginTop: '1em'
  },

  priceFont: {
    fontSize: '2.5rem',
    fontWeight: 900,
    marginRight: '.2em'
  },
  priceColor: {
    color: orange[500]
  },
  notes: {
    marginBottom: '1em'
  },
  cardActions: {
    flexDirection: 'column'
  },
  card: {
    height: '100%',
    transition: 'all 250ms ease-in-out',
    boxShadow: `rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px`
  },

  chip: {
    background: orange[500]
  },
  detailsButton: {
    border: '2px solid #3A3A3C',
    color: '#3A3A3C',
    transition: 'all 500ms ease-in-out',
    '&:hover': {
      background: '#3A3A3C',
      color: '#fff',
      fontWeight: 900
    }
  },

  productPrice: {
    textAlign: 'center',
    fontWeight: 900
  },
  check: {
    marginRight: '.5em',
    color: green[500]
  },
  headerTitleContainerUlapTax: {
    backgroundColor: blue[300]
  },
  headerTitleContainerUlapTaxVat: {
    backgroundColor: blue[700]
  },
  headerTitleContainerUlapBizPro: {
    backgroundColor: '#23272A'
  },
  headerTitleContainerUlapBizEnt: {
    backgroundColor: green[300]
  },
  caption: {
    marginTop: '1em',
    color: red[500]
  },
  startAt: {
    position: 'absolute',
    color: '#fff',
    background: 'red',
    top: 0
  }
}));

const UlapBizProducts = ({ className, ...rest }) => {
  const classes = useStyles();

  const history = createBrowserHistory();
  const [showFloatingHeader, setFloatingHeader] = useState(false);

  const handleGoto = link => {
    history.push(link);
    history.go();
  };

  useEffect(() => {
    window.addEventListener(
      'scroll',
      e => {
        if (window.scrollY >= 100) {
          if (showFloatingHeader) return;

          setFloatingHeader(true);
        } else {
          setFloatingHeader(false);
        }
      },
      true
    );
  }, []);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg" className={classes.wrapper}>
        <Hidden smDown mdDown>
          <Box
            display={showFloatingHeader ? 'block' : 'none'}
            position="sticky"
            top={50}
            zIndex={100}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Box className={classes.headerTitleContainerUlapTax}>
                  <Typography variant="body1" className={classes.smallTitle}>
                    UlapTax Non-VAT
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box className={classes.headerTitleContainerUlapTaxVat}>
                  <Typography variant="body1" className={classes.smallTitle}>
                    UlapTax VAT
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box className={classes.headerTitleContainerUlapBizPro}>
                  <Typography variant="body1" className={classes.smallTitle}>
                    UlapBiz Pro
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box className={classes.headerTitleContainerUlapBizEnt}>
                  <Typography variant="body1" className={classes.smallTitle}>
                    UlapBiz ERP
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Hidden>

        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Card className={classes.card}>
              <Box className={classes.headerTitleContainerUlapTax}>
                <Typography variant="body1" className={classes.smallTitle}>
                  UlapTax Non-VAT
                </Typography>
              </Box>
              <CardContent>
                {/* <Box mb={4} mt={2}>
                  <Typography
                    variant="h1"
                    className={classes.productPrice}
                    color="textPrimary"
                  >
                    Php {data.pricing.ulapTaxNonVat.grandTotal}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                    align="center"
                  >
                    Per Month
                  </Typography>
                </Box> */}
                <Button
                  fullWidth
                  component={RouterLink}
                  to="/contact-us"
                  className={classes.detailsButton}
                  endIcon={<SendOutlinedIcon />}
                >
                  Avail Now
                </Button>

                <Typography
                  variant="caption"
                  display="block"
                  align="center"
                  className={classes.caption}
                >
                  Free 30 Days trial for annual contract.
                </Typography>
                <Box mt={4}>
                  <List dense>
                    {data?.features?.map((item, i) => (
                      <FeatureItem
                        description={item.featureDescription}
                        value={item.ulapTaxNonVat}
                        indent={item?.indent ?? 0}
                        parent={item?.parent ?? false}
                        my={3}
                        key={i}
                      />
                    ))}
                  </List>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={3}>
            <Card className={classes.card}>
              <Box className={classes.headerTitleContainerUlapTaxVat}>
                <Typography variant="body1" className={classes.smallTitle}>
                  UlapTax VAT
                </Typography>
              </Box>
              <CardContent>
                {/* <Box mb={4} mt={2}>
                  <Typography
                    variant="h1"
                    className={classes.productPrice}
                    color="textPrimary"
                  >
                    Php {data.pricing.ulapTaxVat.grandTotal}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                    align="center"
                  >
                    Per Month
                  </Typography>
                </Box> */}
                <Button
                  fullWidth
                  color="primary"
                  className={classes.detailsButton}
                  component={RouterLink}
                  to="/contact-us"
                  endIcon={<SendOutlinedIcon />}
                >
                  Avail Now
                </Button>

                <Typography
                  variant="caption"
                  display="block"
                  align="center"
                  className={classes.caption}
                >
                  Free 30 Days trial for annual contract.
                </Typography>
                <Box my={4}>
                  <List dense>
                    {data?.features?.map((item, i) => (
                      <FeatureItem
                        description={item.featureDescription}
                        value={item.ulapTaxVat}
                        indent={item?.indent ?? 0}
                        parent={item?.parent ?? false}
                        my={3}
                        key={i}
                      />
                    ))}
                  </List>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={3}>
            <Card className={classes.card}>
              <Box className={classes.headerTitleContainerUlapBizPro}>
                <Typography variant="body1" className={classes.smallTitle}>
                  UlapBiz Pro
                </Typography>
              </Box>
              <CardContent>
                {/* <Box mb={4} mt={2}>
                  <Typography
                    variant="h1"
                    className={classes.productPrice}
                    color="textPrimary"
                  >
                    Php {data.pricing.ulapBizPro.grandTotal}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                    align="center"
                  >
                    Per Month
                  </Typography>
                </Box> */}
                <Button
                  fullWidth
                  component={RouterLink}
                  to="/contact-us"
                  className={classes.detailsButton}
                  endIcon={<SendOutlinedIcon />}
                >
                  Avail Now
                </Button>

                <Typography
                  variant="caption"
                  display="block"
                  align="center"
                  className={classes.caption}
                >
                  Free 30 Days trial for annual contract.
                </Typography>
                <Box mt={4}>
                  <List dense>
                    {data?.features?.map((item, i) => (
                      <FeatureItem
                        description={item.featureDescription}
                        value={item.ulapBizPro}
                        indent={item?.indent ?? 0}
                        parent={item?.parent ?? false}
                        my={3}
                        key={i}
                      />
                    ))}
                  </List>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className={classes.card}>
              <Box className={classes.headerTitleContainerUlapBizEnt}>
                <Typography variant="body1" className={classes.smallTitle}>
                  UlapBiz ERP
                </Typography>
              </Box>
              <CardContent>
                {/* <Box mb={4} mt={2}>
                  <Typography
                    variant="h1"
                    className={classes.productPrice}
                    color="textPrimary"
                  >
                    {data.pricing.ulapBizEnt.grandTotal}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                    align="center"
                  >
                    Call Us
                  </Typography>
                </Box> */}
                <Button
                  fullWidth
                  component={RouterLink}
                  to="/contact-us"
                  className={classes.detailsButton}
                  endIcon={<SendOutlinedIcon />}
                >
                  Avail Now
                </Button>

                <Typography
                  variant="caption"
                  display="block"
                  align="center"
                  className={classes.caption}
                >
                  Free 30 Days trial for annual contract.
                </Typography>
                <Box mt={4}>
                  <List dense>
                    {data?.features?.map((item, i) => (
                      <FeatureItem
                        description={item.featureDescription}
                        value={item.ulapBizEnt}
                        indent={item?.indent ?? 0}
                        parent={item?.parent ?? false}
                        my={3}
                        key={i}
                      />
                    ))}
                  </List>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

UlapBizProducts.propTypes = {
  className: PropTypes.string
};

export default UlapBizProducts;
