import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
const INITIAL_STATE = {
  dateRange: {
    dt1: '',
    dt2: '',
    textValue: ''
  },
  activeJCd: [],
  activeWH_From: {},
  activeWH_To: {},
  activeProdCat: {},
  activeProdSubCat: {},
  activeBrch: 0,
  showProdDetails: false,
  result: [],
  meta: {
    jcd: [],
    wh: []
  },
  showSN: false,
  showSN2: false,
  showBN: false,
  showExpDate: false,
  showBrchCol: false,
  showCS: false,
  showSC: false
};

const slice = createSlice({
  name: 'whTransfer',
  initialState: INITIAL_STATE,
  reducers: {
    storeDateRange(state, action) {
      state.dateRange = action.payload;
    },
    storeActiveJCd(state, action) {
      state.activeJCd = action.payload;
    },
    storeActiveWHFrom(state, action) {
      state.activeWH_From = action.payload;
    },
    storeActiveWHTo(state, action) {
      state.activeWH_To = action.payload;
    },
    storeActiveProdCat(state, action) {
      state.activeProdCat = action.payload;
    },
    storeActiveProdSubCat(state, action) {
      state.activeProdSubCat = action.payload;
    },
    storeActiveBranch(state, action) {
      state.activeBrch = action.payload;
    },
    storeShowProdDetails(state, action) {
      state.showProdDetails = action.payload;
    },
    storeResult(state, action) {
      const items = action.payload;
      state.result = items.map(item => ({ ...item, key_id: uuid() }));
      state.showCS = items.some(item => item.libQtyCS > 0);
      state.showSC = items.some(item => item.libQtySC > 0);
      state.showSN = items.some(item => item.SN !== '');
      state.showSN2 = items.some(item => item.SN2 !== '');
      state.showBrchCol = items.some(
        (item, _, arr) => item.ixBrch !== (arr?.[0]?.ixBrch ?? 0)
      );
      state.showBN = items.some(item => item.BN !== '');
      state.showExpDate = items.some(item => item.ExpDate !== '');
    },
    clearData(state, action) {
      state.result = [];
    },
    setMeta(state, action) {
      state.meta = action.payload;
    },
    resetState() {
      return INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const whTransferReducer = slice.reducer;

export const {
  storeDateRange,
  storeActiveJCd,
  storeActiveWHFrom,
  storeActiveWHTo,
  storeActiveVariation,
  storeActiveProdCat,
  storeActiveProdSubCat,
  storeActiveBranch,
  storeShowProdDetails,
  setMeta,
  clearData,
  storeResult,
  setPage,
  setSize,
  resetState,
  restore,
  clear
} = slice.actions;
export default whTransferReducer;
