import { useState } from 'react';

const useToggle = (initialValue = false) => {
  const [isOn, setIsOn] = useState(initialValue);

  const on = () => setIsOn(true);
  const off = () => setIsOn(false);
  const toggle = () => setIsOn(prevState => !prevState);

  return { isOn, toggle, on, off };
};

export default useToggle;
