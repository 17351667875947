import { THEMES } from 'src/constants';
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  direction: 'ltr',
  responsiveFontSizes: true,
  mode: 'light',
  theme: THEMES.DEFAULT
};

const slice = createSlice({
  name: 'preferences',
  initialState: INITIAL_STATE,
  reducers: {
    setTheme(state, action) {
      state.theme = action.payload;
    },
    setDefault(state, action) {
      return INITIAL_STATE;
    },
    setPreferences(state, action) {
      return { ...state, ...action.payload };
    },
    setMode(state, action) {
      state.mode = action.payload;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const preferencesReducer = slice.reducer;
export const {
  setTheme,
  setPreferences,
  restore,
  setMode,
  setDefault
} = slice.actions;
export default preferencesReducer;
