import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';

import Page from 'src/components/Page';
import useResetPassword from './useResetPassword';
import Request from './Request';
import OTP from './OTP';
import PasswordResetSuccess from './PasswordResetSuccess';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ResetPassword = () => {
  const classes = useStyles();
  const { form, ...rest } = useResetPassword();

  return (
    <Page className={classes.root} title="Reset Password">
      <Container maxWidth="xs">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          mt={12}
        >
          {form <= 1 && (
            <Box mb={2}>
              <Typography variant="h2" color="textPrimary">
                {form === 0 && 'Forgot password?'}
                {form === 1 && 'Create new password'}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {form === 0 &&
                  rest.valueType === '' &&
                  'Select which account details should we use to reset your password.'}
                {form === 1 && ''}
              </Typography>
            </Box>
          )}

          {form === 0 && <Request {...rest} />}
          {form === 1 && <OTP {...rest} />}
          {form > 1 && <PasswordResetSuccess {...rest} />}
        </Box>
      </Container>
    </Page>
  );
};

export default ResetPassword;
