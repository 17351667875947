import React from 'react';
import { Box } from '@material-ui/core';

const CenterBox = ({ children, ...props }) => (
  <Box display="flex" alignItems="center" justifyContent="center" {...props}>
    {children}
  </Box>
);

export default CenterBox;
