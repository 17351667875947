import { createSlice, current } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  ixAcc: 0,
  sAcc: '',
  subTypes: [],

  ixSubType1: 0,
  sSubType1: '',

  ixSubType2: 0,
  sSubType2: '',

  ixSubType3: 0,
  sSubType3: '',
  showSubType3: false,

  ixBrch: 0,
  dt1: null,
  dt2: null,
  textValue: '',
  page: 0,
  limit: 25,
  searchQuery: '',
  data: {
    tBeg: 0,
    tEnd: 0,
    tDr: 0,
    tCr: 0,
    rep: []
  }
};

function getSubType(subTypes, ixSubType, brch, caption = 'Sub Type') {
  const res = subTypes.find(({ id }) => id === ixSubType)?.description;

  if (Boolean(res)) {
    return res;
  } else {
    return ixSubType === brch?.ixBrchSubType ? brch?.label_singular : caption;
  }
}

const slice = createSlice({
  name: 'advSLSubBalances',
  initialState: INITIAL_STATE,
  reducers: {
    onChangeAccount(state, action) {
      state.ixSubType1 = action.payload.ixSubType1;
      state.ixSubType2 = action.payload.ixSubType2;
      state.ixSubType3 = action.payload.ixSubType3;

      let subTypes = [];
      [1, 2, 3].forEach(item => {
        if (action.payload[`ixSubType${item}`] !== 0) {
          subTypes.push({
            id: action.payload[`ixSubType${item}`],
            description: action.payload[`sSubType${item}`]
          });
        }
      });

      state.subTypes = subTypes;
      state.ixAcc = action.payload.ixAcc;
      state.sAcc = action.payload.sAcc;
    },
    onChangeDateRange(state, action) {
      state.dt1 = action.payload.dt1;
      state.dt2 = action.payload.dt2;
      state.textValue = action.payload.textValue;
    },
    onChangeBrch(state, action) {
      state.ixBrch = action.payload;
    },
    loadReportData(state, action) {
      const { data, brch } = action.payload;

      const currState = current(state);
      const ixSubType1 = currState.ixSubType1;
      const ixSubType2 = currState.ixSubType2;
      const ixSubType3 = currState.ixSubType3;
      const subTypes = currState.subTypes;

      state.data = data;
      state.sSubType1 = getSubType(subTypes, ixSubType1, brch, 'Sub Type 1');
      state.sSubType2 = getSubType(subTypes, ixSubType2, brch, 'Sub Type 2');
      state.sSubType3 = getSubType(subTypes, ixSubType3, brch, 'Sub Type 3');

      state.showSubType3 = (data?.rep ?? []).some(
        item =>
          typeof item['sSub3'] !== 'undefined' ||
          typeof item['ixSub3'] !== 'undefined'
      );
    },
    onChangeLimit(state, action) {
      state.limit = action.payload;
    },
    onChangePage(state, action) {
      state.page = action.payload;
    },
    onChangeSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    onChangeSubType(state, action) {
      state.ixSubType1 = action.payload;
    },
    onChangeSubType2(state, action) {
      state.ixSubType2 = action.payload;
    },
    onChangeSubType3(state, action) {
      state.ixSubType3 = action.payload;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    },
    clearReport(state, _) {
      return INITIAL_STATE;
    }
  }
});

const advSLSubBalances = slice.reducer;

export const {
  onChangeAccount,
  onChangeDateRange,
  onChangeBrch,
  onChangeSubType,
  onChangeSubType2,
  onChangeSubType3,
  loadReportData,
  onChangeLimit,
  onChangePage,
  onChangeSearchQuery,
  clearReport,
  restore,
  clear
} = slice.actions;
export default advSLSubBalances;
