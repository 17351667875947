import React from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Container,
  Grid
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import useReveal, { Animated } from 'src/hooks/useReveal';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 60,
    paddingBottom: 60,
    [theme.breakpoints.down('md')]: {
      paddingTop: 40,
      paddingBottom: 40
    }
  },
  smallCard: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5em',
    border: '2px solid #fff',
    padding: theme.spacing(2),
    borderRadius: '1em',
    height: '100%',
    background: '#fff',
    transition: 'all 100ms ease-in-out',
    boxShadow: theme.shadows[4],
    '&:hover': {
      borderColor: '#FF7704',
      boxShadow: theme.shadows[10]
    }
  },
  headerTitle: {
    fontSize: '2em',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3)
  }
}));

const Card = ({ title, icon, reveal, delay }) => {
  const classes = useStyles();

  return (
    <Animated
      to={{
        opacity: reveal ? 1 : 0
      }}
      config={{
        duration: 300
      }}
      delay={delay}
      className={classes.smallCard}
    >
      <Box p={2}>
        <FontAwesomeIcon icon={icons[icon]} size="xl" color="#FF7704" />
      </Box>
      <Box flex={2}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ fontWeight: 900 }}
        >
          {title}
        </Typography>
      </Box>
    </Animated>
  );
};

const SYSTEMS = [
  { key: 1, title: 'Accounting Information System', icon: 'faCalculator' },
  { key: 2, title: 'Inventory Management System', icon: 'faCartFlatbed' },
  { key: 3, title: 'Payroll System', icon: 'faPesoSign' },
  { key: 4, title: 'Enrollment System', icon: 'faSchool' },
  { key: 5, title: 'Human Resources Information System', icon: 'faUsersGear' },
  { key: 6, title: 'Property Management System', icon: 'faHouseLaptop' }
];

const Systems = ({ className, ...rest }) => {
  const classes = useStyles();
  const [ref, reveal] = useReveal({ threshold: 0.1 });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <div ref={ref}>
          <Typography variant="h1" className={classes.headerTitle}>
            Systems
          </Typography>
          <Grid container spacing={4}>
            {SYSTEMS.map(({ key, title, icon }, i) => (
              <Grid item md={3} sm={4} xs={12} key={key}>
                <Card
                  key={title}
                  title={title}
                  icon={icon}
                  reveal={reveal}
                  delay={200 * i}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Systems;
