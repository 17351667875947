import React from 'react';
import FeaturesTl1 from './FeaturesTl1';

/**
 *
 * @param {object} param
 * @param {string} param.ixPage
 * @param {pageFeatures} param.pageDetails
 * @returns
 */
const Features = ({ ixPage, pageDetails }) => {
  return <FeaturesTl1 ixPage={ixPage} pageDetails={pageDetails} />;
};

export default Features;
