import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useNavigate } from 'react-router';

const PasswordResetSuccess = ({ cdBiz, cdPortal }) => {
  const navigate = useNavigate();

  const handleOK = () => {
    navigate(`/portal/${cdBiz}/${cdPortal}/login`);
  };

  return (
    <Box my={2}>
      <Box mb={2}>
        <Typography align="center">
          <CheckCircleOutlineIcon
            style={{ fontSize: '5em', color: green[300] }}
          />
        </Typography>
      </Box>
      <Typography
        variant="h2"
        align="center"
        style={{ color: green[500], marginBottom: '1em' }}
      >
        Password reset successfully
      </Typography>
      <Typography variant="body2" align="center" color="textSecondary">
        You have successfully reset your password, Please use your new password
        when logging in.
      </Typography>

      <Box mt={4} display="flex" justifyContent="center">
        <Button color="primary" variant="contained" onClick={handleOK}>
          Sign In
        </Button>
      </Box>
    </Box>
  );
};

export default PasswordResetSuccess;
