const { makeStyles } = require('@material-ui/core');

const useGlobalStyles = makeStyles(theme => ({
  page: {
    background: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  oddEvenBg: {
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: theme.palette.background.dark
    }
  },
  dgDivider: {
    border: 'none',
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: theme.palette.background.dark
    },
    '& .MuiDataGrid-row:first-child': {
      borderTop: '2px solid rgba(224, 224, 224, 1)'
    },
    '& .MuiDataGrid-row:last-child': {
      borderTop: '2px solid rgba(224, 224, 224, 1)',
      fontWeight: 700
    }
  },
  roundedBtn: {
    borderRadius: 50
  },
  roundedTxtFld: {
    '& .MuiInputBase-root': {
      borderRadius: 50
    }
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(5),
    bottom: theme.spacing(5),
    zIndex: 1000
  },
  noHeaderBorderRight: {
    '& .MuiDataGrid-columnHeaderWrapper .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
      display: 'none'
    }
  }
}));

export default useGlobalStyles;
