import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Hidden,
  Link,
  Button
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import BizInfo from './BizInfo';
import AccountDetails from './AccountDetails';
import ActivateAccount from './ActivateAccount';
import ActivationSuccess from './ActivationSuccess';
import { FormViewError } from 'src/components/FormViewError';
import { useSelector, useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import { changeCurrentForm } from 'src/redux/actions/registration';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const STEPS_LABELS = [
  'Business Details',
  'Account Details',
  'Activate Account'
];

const UserRegistration = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentForm, errors, generalError } = useSelector(
    ({ registrationReducer }) => registrationReducer
  );

  useEffect(() => {
    if (currentForm >= 3) {
      dispatch(changeCurrentForm(0));
    }
  }, []);

  return (
    <Page className={classes.root} title="Register">
      <Container maxWidth="md">
        <Box my={3}>
          <Typography color="textPrimary" variant="h1">
            Register
          </Typography>

          <Box display="flex" alignItems="center">
            <Typography color="textSecondary" variant="body2">
              Already have an account?
            </Typography>

            <Button
              color="primary"
              variant="text"
              size="small"
              component={Link}
              href="/app/login"
              underline="none"
            >
              Sign In
            </Button>
          </Box>

          <Hidden mdDown>
            <Box mt={3} mb={3}>
              <Stepper activeStep={currentForm}>
                {STEPS_LABELS.map(step => (
                  <Step key={step}>
                    <StepLabel>{step}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Hidden>
          <Hidden lgUp>
            <Paper component={Box} mt={3} mb={3} p={3} elevation={0} square>
              <Typography color="textSecondary" variant="h4">
                {STEPS_LABELS[currentForm >= 3 ? 2 : currentForm]}
              </Typography>
            </Paper>
          </Hidden>
          {currentForm === 0 && (
            <FormViewError
              errorList={errors?.biz?.errors}
              containerProps={{ mb: 3 }}
            />
          )}

          {currentForm === 1 && (
            <FormViewError
              errorList={errors?.user?.errors}
              containerProps={{ mb: 3 }}
            />
          )}

          {generalError !== '' && (
            <Box mt={3}>
              <Alert severity="error">{generalError}</Alert>
            </Box>
          )}

          <Paper component={Box} mb={3} px={3} py={2} square elevation={0}>
            {currentForm === 0 && <BizInfo />}
            {currentForm === 1 && <AccountDetails />}
            {currentForm === 2 && <ActivateAccount />}
            {currentForm >= 3 && <ActivationSuccess />}
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

export default UserRegistration;
