import React, { useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton
} from '@material-ui/data-grid';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  TextField,
  IconButton,
  CardHeader,
  Typography,
  LinearProgress
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useEffect } from 'react';
import {
  Search,
  Clear,
  Fullscreen,
  InsertChartOutlinedTwoTone,
  Close
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  dataGrid: {
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: theme.palette.background.dark
    }
  }
}));

const escapeRegExp = value => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

// Toolbar for the Table
const TableToolBar = ({ value, onChange, clearSearch }) => {
  return (
    <Box padding=".4em">
      <GridToolbarContainer style={{ width: '100%' }}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <GridToolbarColumnsButton />
            <GridToolbarExport />
          </Box>
          <TextField
            variant="outlined"
            placeholder="Search"
            value={value}
            onChange={onChange}
            InputProps={{
              startAdornment: (
                <Search fontSize="small" style={{ color: '#4153AF' }} />
              ),
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{
                    visibility: value ? 'visible' : 'hidden',
                    color: '#E25141'
                  }}
                  onClick={clearSearch}
                >
                  <Clear fontSize="small" />
                </IconButton>
              )
            }}
          />
        </Box>
      </GridToolbarContainer>
    </Box>
  );
};

const PostingData = ({ data, title, isLoading, onClose, columns }) => {
  const classNames = useStyles();
  const [pageSize, setPageSize] = useState(5);
  const [rows, setRows] = useState([]);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    setRows(data);
  }, [data]);

  const handleSearchChange = e => {
    setSearchVal(e.target.value);
  };

  // filter data when searching
  useEffect(() => {
    const searchRegEx = new RegExp(escapeRegExp(searchVal), 'i');
    const filteredRows = data.filter(row => {
      return Object.keys(row).some(field => {
        return searchRegEx.test((row?.[field] || '').toString());
      });
    });
    setRows(filteredRows);
  }, [searchVal]);

  return (
    <Card>
      {isLoading && <LinearProgress style={{ height: 1 }} />}
      <CardHeader
        title={
          <Typography variant="h5" color="textSecondary">
            {title}
          </Typography>
        }
        disableTypography
        action={
          <IconButton style={{ color: '#E25141' }} onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        }
        style={{ padding: '16px 16px 6px 16px' }}
      />
      <CardContent style={{ padding: 0 }}>
        <DataGrid
          disableColumnMenu
          disableColumnFilter
          style={{ border: 'none' }}
          className={classNames.dataGrid}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={ps => setPageSize(ps)}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          getRowId={row => row['jid']}
          autoHeight
          rowHeight={80}
          headerHeight={40}
          components={{
            Toolbar: TableToolBar
          }}
          componentsProps={{
            toolbar: {
              value: searchVal,
              onChange: e => handleSearchChange(e),
              clearSearch: () => setSearchVal('')
            }
          }}
          disableSelectionOnClick
        />
      </CardContent>
    </Card>
  );
};

export default PostingData;
