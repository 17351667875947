import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

const INITIAL_STATE = {
  announcement: {
    message: '',
    severity: '',
    show: false,
    isClosed: false
  },
  nextLoad: null,
  isClosed: false
};

const slice = createSlice({
  name: 'announcement',
  initialState: INITIAL_STATE,
  reducers: {
    setAnnouncement(state, action) {
      if (!isEqual(state.announcement, action.payload)) {
        state.announcement = action.payload;
        state.isClosed = false;
      }
    },
    setNextLoad(state, action) {
      state.nextLoad = action.payload;
    },
    closeAnnouncement(state) {
      state.isClosed = true;
    }
  }
});

const announcementReducer = slice.reducer;

export const {
  setAnnouncement,
  setNextLoad,
  closeAnnouncement
} = slice.actions;
export default announcementReducer;
