import React from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import numeral from 'numeral';
import Zoom from '@material-ui/core/Zoom';
import { useCashierLog } from './context/CashierLogProvider';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const columns = [
  {
    field: 'denomination',
    headerName: 'Denomination',
    flex: 2,
    sortable: false,
    valueGetter: ({ value }) => numeral(value).format('0,0.00')
  },
  {
    field: 'qty',
    type: 'number',
    headerName: 'Qty',
    minWidth: 50,
    flex: 1,
    sortable: false,
    editable: true
  },
  {
    field: 'total',
    type: 'number',
    headerName: 'Total',
    minWidth: 80,
    flex: 1,
    sortable: false,
    valueGetter: ({ value }) => numeral(value).format('0,0.00')
  }
];

function Footer() {
  const { cashEnd } = useCashierLog();
  return (
    <Box padding={1} display="flex" justifyContent="space-between">
      <Typography variant="h4" align="right">
        Total :
      </Typography>
      <Typography variant="h4" align="right">
        {numeral(cashEnd).format('0,0.00')}
      </Typography>
    </Box>
  );
}

const LogOffCashierLogs = () => {
  const {
    isInitializing,
    logOffShown,
    hideLogOff,
    cashCount,
    onChangeCashCountQty,
    logOff,
    isUpdating
  } = useCashierLog();

  return (
    <Dialog
      open={logOffShown}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          hideLogOff();
        }
      }}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h4" color="textSecondary">
          Cash Count
        </Typography>
      </DialogTitle>
      <DialogContent>
        {isInitializing ? (
          <Box
            display="flex"
            padding={6}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={40} />
          </Box>
        ) : (
          <DataGrid
            rows={cashCount}
            columns={columns}
            onCellEditCommit={onChangeCashCountQty}
            autoHeight
            disableSelectionOnClick
            getRowId={row => row['id']}
            disableColumnFilter
            disableColumnMenu
            rowHeight={50}
            hideFooterPagination
            components={{
              Footer: Footer
            }}
          />
        )}
      </DialogContent>
      {!isInitializing && (
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            disabled={isUpdating}
            onClick={hideLogOff}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isUpdating}
            onClick={() => logOff({ isForcedClose: false })}
          >
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default LogOffCashierLogs;
