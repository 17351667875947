import React from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  mainTitle: {
    marginBottom: theme.spacing(4)
  },
  secondaryTitle: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5)
  },
  body1: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(3)
  }
}));

const TermsContent = () => {
  const classes = useStyles();

  return (
    <Box mb={3}>
      <Typography variant="h1" className={classes.mainTitle}>
        Terms of Service
      </Typography>
      <Typography variant="body1">
        All work is carried out under these terms except where changes are
        expressly agreed in writing.
      </Typography>
      <Typography variant="h2" className={classes.secondaryTitle}>
        The UlapBiz Terms of Service
      </Typography>
      <Typography variant="h4" className={classes.secondaryTitle}>
        Chapter 1 Terms of Service
      </Typography>
      <Typography variant="body1">
        These terms ("the Terms") will govern our agreement with you in relation
        to all UlapBiz Services that you subscribe to and that we agree to
        supply you from time to time ("the Services") subject to any additional
        terms that may be set out in the service descriptions of any UlapBiz
        Services and Add-Ons (as defined in these Terms) as they apply to you.
        These Terms may be updated from time to time and any changes will be
        binding to both parties. You agree to review these Terms regularly and
        we are entitled to treat your continued access to or use of the Services
        as confirmation that you agree to the changes.
      </Typography>
      <Typography variant="h4" className={classes.secondaryTitle}>
        1. Introduction
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        1.1 We are Radztech Business Solutions ("UlapBiz", "us", "we" and
        "our"). Radztech Business Solutions is a company registered in the
        Philippines under company number +63 956 896 3767 / +63 917 523 5387 and
        its business and registered address is #85 Doña Fronie Avenue, San
        Fermin, Cauayan City, Isabela Philippines 3305.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        1.2 These Terms of Service ("Terms") apply to you, our client ("Client",
        "you", "yourself" and "your"), when you use any of the Services (whether
        on the basis of a trial or by paid monthly, one off or annual
        subscription) featured on our website www.ulap.biz or ("the Site") or
        and, to the fullest extent applicable, to any services which you are
        introduced to via the site, which are provided by third parties.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        1.3 Definitions. In these Terms, the following definitions apply:
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          Add-Ons:
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Any and all of the other value, added services (as detailed in the
          individual Service Descriptions)
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Partners:
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Services offered by third parties, including but not limited to:
          payment services developed independently by the respective third
          parties. Such services will be purchased on the basis of separate
          contracts with the third parties who are introduced by us.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Business Day:
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          A day (other than a Saturday, Sunday or public holiday) when banks in
          the Philippines are open for business.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Charges:
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          The charges payable by clients for the supply of the Services and,
          where applicable, UlapBiz Services and Add-Ons (which are payable in
          addition) in accordance with clause 7.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Commencement Date:
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Has the meaning set out in clause 2.2.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Contract:
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          The contract between Us and the Client for the supply of the Services
          in accordance with these Terms.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          The UlapBiz Account:
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Means a current subscription to the Services providing access to the
          UlapBiz Software for delivery of the Services and enabling
          subscription to all Services.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          The UlapBiz Services:
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          The UlapTax, the UlapBiz Pro, and UlapBiz ERP being accessed via
          UlapBiz Software. Such UlapBiz Services and Add-ons may be purchased
          on the basis of an additional subscription or one off payment governed
          by these Terms and the Special Terms.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          The UlapBiz Software:
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Means the UlapBiz software developed and/or licensed by Radztech
          Corporation to provide the functionality for the Services for Clients
          with UlapBiz Account.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Intellectual Property Rights:
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Patents, rights to inventions, copyright and related rights,
          trademarks, business names and domain names, rights in get-up,
          goodwill and the right to sue for passing off, rights in designs,
          database rights, rights to use and protect the confidentiality of
          confidential information (including know-how) and all other
          intellectual property rights, in each case whether registered or
          unregistered and including all applications and rights to apply for
          and be granted, renewals or extensions of, and rights to claim
          priority from, such rights and all similar or equivalent rights or
          forms of protection which subsist or will subsist now or in the future
          in any part of the World.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Service Descriptions:
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          The individual service descriptions for UlapBiz Services and Add-Ons
          and the Partner Services as featured on the Site or emailed from time
          to time. Such Service Descriptions may contain additional contractual
          terms ("Special Terms") to govern use which shall be binding on you.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Services:
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Any and all of the UlapBiz Services.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Special Terms:
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          Means any specific terms applicable to the UlapBiz Services and
          Add-Ons, or the Partner Services, which are effectively incorporated
          into the Contract and shall be binding on the Client by express
          reference in the relevant Service Descriptions. In the event of a
          conflict between these Special Terms and the Terms, the Terms shall
          prevail.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Terms:
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          These Terms as amended from time to time.
        </Typography>

        <Typography variant="body1" className={classes.body1}>
          Written Notice:
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          Shall comprise all written communication between Us and its Clients,
          that is delivered between the parties by (i) personal delivery, (ii) a
          nationally-recognised, next-day courier service, (iii) first-class
          registered or recorded mail with postage prepaid, (iv) fax, or (v)
          electronic mail. The communication will be delivered to the Client's
          address as recorded by the UlapBiz Services.
        </Typography>
      </Box>
      <Typography variant="h4" className={classes.secondaryTitle}>
        2. Formation of Contract
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        2.1 Your application for UlapBiz Account constitutes an offer by you to
        purchase the Services in accordance with these terms.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        2.2 Your application shall only be deemed to be accepted when we issue
        written acceptance of the application by way of a Welcome email
        containing login details, at which point and on which date the Contract
        shall come into existence (Commencement Date). By applying for an
        UlapBiz Account to be created, you warrant that you are authorized to
        enter into this contract.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        2.3 We reserve the right, at our discretion, not to accept an
        application for a UlapBiz Account. The reasons may include but not be
        limited to: technical constraints; you or your business having been
        banned by us from using the Services; or our inability to adequately
        confirm your identity; or for any other reason. No charge will be made
        for declined applications.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        2.4 The acceptance of your application is based on the trading activity
        and organizational structure described by you when first signing up to
        the Services. If your trading activities or organizational structure
        change, the Services may not be right for you and we may not be able to
        continue to provide the Services to your business. In this instance we
        would reserve the right to issue a disengagement letter and cease to act
        for you.
      </Typography>

      <Typography variant="h4" className={classes.secondaryTitle}>
        3. The Services
      </Typography>
      <Typography variant="body1">
        We shall supply the Services to you in accordance with these Terms in
        all material respects.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        3.1 We shall have the right to make any changes to the Services which
        are necessary to comply with any applicable law, or which do not
        materially affect the nature or quality for the Services, and we shall
        notify you in any such event.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        3.2 We warrant to you that the Services will be provided using
        reasonable care and skill.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        3.3 In providing UlapBiz services, as part of the Services, we delegate
        certain tasks or subcontract part or all of the contractual performance
        to: Radztech Corporation; or, at our sole discretion, another Group
        Company; or to suitably qualified third parties. In all cases and at all
        times, we shall retain responsibility to you for the performance of the
        Services.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        3.4 UlapBiz provides three packaged services: Tax, Pro and ERP for the
        accounting years in which you are subscribed to the services.
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          3.4.1 Add on Services
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          Add-on Services supplied by UlapBiz are listed below. Where payment is
          required, these Services are paid for separately, if not included
          within package subscription.
        </Typography>
      </Box>
      <Typography variant="h4" className={classes.secondaryTitle}>
        4. Taxation & Accounting Information
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        4.1 Your UlapBiz Account allows for the provision of taxation and
        accounting information only and this is restricted to the private
        limited company which subscribes to UlapBiz Services. For specific
        matters relating to issues, such as but not limited to VAT compliance,
        broad information will be offered and additional services recommended.
        We recommend that you seek specialized services on specific matters
        either by expanding your UlapBiz Account to include additional services
        or by seeking advice from an alternative source.
      </Typography>
      <Typography variant="h4" className={classes.secondaryTitle}>
        5. Client's Obligations
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        5.1 You shall:
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          5.1.1 Cooperate with us in all matters relating to the Services
          including complying with all reasonable instructions provided by us in
          relation to the same;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.1.2 Provide your UlapBiz with such information and documentation as
          it may reasonably require in order to supply the Services, and
          ensuring that this is accurate in all material aspects;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.1.3 Ensure and keep that your UlapBiz has complete, accurate and
          up-to-date information at all times throughout the subscription
          period, including any changes to the company structure, trading
          activity, business address and personal contact details;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.1.4 Keep your UlapBiz updated with all trading transactions,
          including, but not limited to: entering all invoices and expenses;
          recording all withdrawals and payments; reconciling your cash balances
          on a regular basis; preparing your business tax returns and
          attachments using your UlapBiz as your bases;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.1.5 Ensure that the Services are not abused or used for any immoral
          and illegal purpose or in any way that would bring us in disrepute;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.1.6 Open at least one business account, with a Philippine-based
          bank, where your company can process business transactions;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.1.7 Ensure that your UlapBiz is fully updated and reconciled at
          least monthly before the relevant reporting/filing deadline;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.1.8 Remain solely and fully responsible for any breach of your
          obligations under these Terms and the consequences of any such breach,
          expressly acknowledging that we have no responsibility to you or to
          any third party for such breaches or the consequences of such breaches
          and that you shall indemnify us in relation to the same in accordance
          with clause 14.8; and
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.1.9 Notify us of an intention to cancel your UlapBiz Account at
          least 90 days before the next related direct payment is due to be
          processed.
        </Typography>
      </Box>
      <Typography variant="body1" className={classes.body1}>
        5.2 You shall not:
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          5.2.1 Give any illegal or improper bribe, kickback, payment, gift, or
          thing of value to any of our employees or agents in connection with
          the Services;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.2.2 Use any feature of the Services in any way that might infringe
          the rights or privacy of other users of the Services (either by
          hacking or other malicious means or otherwise);
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.2.3 Impersonate any person or entity, or falsely state or otherwise
          make available content that contains software viruses or any other
          computer code, files or programs that could interrupt/destroy/limit
          the functionality of UlapBiz Software, our hardware or
          telecommunications equipment, its users or Partners; or
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.2.4 Reproduce, copy, sell, trade or resell all or any part of the
          Services for any purpose.
        </Typography>
      </Box>
      <Typography variant="body1" className={classes.body1}>
        5.3 If our performance to any of our obligations under the Contract is
        prevented or delayed by any act or omission by the Client or failure by
        the Client to perform any relevant obligation ("Client Default"):
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          5.3.1 We shall, without limiting its other rights or remedies, have
          the right to suspend the performance of the Services until the Client
          remedies the Client Default, and to rely on the Client Default to
          relieve it from the performance of any of its obligations to the
          extent the Client Default prevents or delays our performance of any of
          its obligations;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.3.2 We shall not be liable for any costs or losses sustained or
          incurred by the Client arising directly or indirectly from the
          system’s failure or delay to perform any of its obligations as set out
          in this clause 5.3; and
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          5.3.3 The client shall reimburse us upon demand for any costs or
          losses sustained or incurred by us arising directly or indirectly from
          the Client Default.
        </Typography>
      </Box>
      <Typography variant="body1" className={classes.body1}>
        5.4 The accuracy and completeness of the data entered into the UlapBiz,
        is wholly your responsibility and we are wholly dependent upon your
        entering this in a timely way. We are under no obligation to identify,
        specifically, missing or incomplete information.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        5.5 If you have accounts from previous years that have not yet been
        finalized, we will not have opening balances from these periods.
        Consequently, your UlapBiz will not truly and accurately reflect your
        current financial status until information is supplied.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        5.6 If you start to use the Services midway through your current trading
        period you will be required to enter any trading activities already
        undertaken into your UlapBiz.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        5.7 You have a legal responsibility to retain your own documents and
        records relevant to your business activities.
      </Typography>
      <Typography variant="h4" className={classes.secondaryTitle}>
        6. Access, Security and Data Protection
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        6.1 You will not be able to use the UlapBiz Software and its Services
        without a username, password and memorable word.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        6.2 You are ultimately responsible for administering and safeguarding
        any passwords and memorable words created to control the access to your
        account and your use of your UlapBiz Software. Please keep any password
        issued to you with utmost security. If you choose to give another party
        access to your UlapBiz Software, you may do so at your own risk but you
        shall remain liable to us for complying with these Terms for the use of
        the UlapBiz Software and the Services.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        6.3 Our staff do not have access to any Client passwords or One-Time
        Passwords (OTP). Read-only access to your UlapBiz Software is available
        to specific staff members but is strictly controlled by passwords and IP
        address restrictions. The use of this access is for assisting you with
        your UlapBiz Software and for offering other support, as necessary.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        6.4 We do not have editing access to your UlapBiz accounting records
        without your authentication and permission, save that accountants will
        access your UlapBiz accounting records to make necessary adjustments.
        Additionally, our staff will have full access to your UlapBiz accounting
        records, with your authority, to undertake service. This access would be
        sought in exceptional circumstances and would not involve you having to
        reveal any of your login details.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        6.5 Each party acts as a Controller for the purposes of the Data Privacy
        Act and shall comply with all data protection legislation applicable to
        it when processing personal data in connection with the Services. Each
        party undertakes not to knowingly cause the other to breach the Data
        Privacy Act. In particular, you shall ensure that any disclosure of
        Personal Data to us complies with the Data Privacy Act.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        6.6 Our Privacy Policy explains how we process and treat personal
        information received by our company about you (if you are an individual)
        or your employees (if you are a company) in order to provide the
        Services and meet our own legal and regulatory obligations. In agreeing
        to these Terms, you acknowledge that you have read our Privacy Policy,
        as may be updated from time to time. You can always find the most
        up-to-date version of our Privacy Policy on our website.
      </Typography>
      <Typography variant="h4" className={classes.secondaryTitle}>
        7. Charges and Payment
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.1 Your subscription – Charges and Payment Terms: Subscription Charges
        for use of the Services are payable monthly, or annually in advance. The
        subscription rates are available on request and we may vary these from
        time to time. Prices are quoted in Php and exclude VAT, which is also
        payable. You will be notified of any changes to our current price plan.
        It is a requirement that all Clients agree to set up a direct debit
        (when available) in our favor for payment of monthly subscription fees.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.2 Additional Charges are payable and Add-Ons which fall outside of the
        scope of the Services and the amounts payable shall be posted on our
        website from time to time. The current rate shall be notified to the
        Client in advance of subscribing to the Add-Ons. If you require a
        specific additional service, please ask your Client Manager for details.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.3 The Client shall pay all amounts due under the Contract in full
        without any set-off, counterclaim, deduction or withholding (except for
        any deduction or withholding required by law). We may at any time,
        without limiting its other rights or remedies, set-off any amount owing
        to it by the Client against any amount payable by us to the Client.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.4 All amounts payable by the Client under the Contract are exclusive
        of amounts in respect of VAT chargeable for the time being. Where any
        taxable supply for VAT purposes is made under the Contract by us to the
        Client, the Client shall, on receipt of a valid VAT invoice from us, pay
        as such additional amounts in respect of VAT as are chargeable on the
        supply of the Services at the same time as payment is due for the supply
        of the Services.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.5 A set-up fee will not be charged when signing up for the UlapBiz
        Services.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.6 The Services rely upon the continuous payment of monthly
        subscriptions. We are unable to provide breaks in subscription fees due
        to: non-trading; you taking up permanent employment; or other
        disruptions to your business.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.7 If an annual payment is made for the Services please note that we
        cannot offer a pro-rated refund if you choose to cancel your UlapBiz
        Account before the end of the year for which you have paid.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.8 You are responsible for your preparation on all tax and financial
        reports to be filed to any regulatory bodies, we cannot be held
        responsible or accountable for any omissions in your accounts.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.9 We shall be under no obligation to provide the Services if any
        subscription fee is not paid to us on time. If subscription fees become
        overdue we reserve the right to suspend your access to the Services
        until the balance is paid and we may close your UlapBiz Account
        permanently.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.10 The Client must ensure that we have complete and accurate billing
        and contact information throughout the subscription period, including
        the full name of the Client, its business address and a billing contact
        email address.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.11 If the Client fails to make any payment due to us under the
        Contract by the due date for payment, then the Client shall pay the
        overdue amount plus 5% surcharge.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.12 Insofar as we are permitted to do so by law or professional
        guidelines, we reserve the right to exercise a lien over all funds,
        documents and records in our possession relating to all engagements for
        you until all outstanding fees are paid in full.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        7.13 We reserve the right to increase our Charges on an annual basis
        each year in line with the percentage in the Retail Price Index (RPI) in
        the preceding 12 months.
      </Typography>
      <Typography variant="h4" className={classes.secondaryTitle}>
        8. Returning Clients
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        8.1 If you are re-engaging us in the same accounting year in which you
        stop using the Service, we would normally re-enable your previous
        UlapBiz account and software and you would need to enter any missing
        data. Otherwise, you should contact us and request a new version of the
        UlapBiz Software. This new version of the UlapBiz Software would hold no
        historical data and you would be required to enter any previous trading
        activities back to the start of the accounting year in which you
        re-availed our services.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        8.2 If you have been through an accounting year-end since not utilizing
        UlapBiz, we shall be creating a new version of the UlapBiz Software for
        you.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        8.3 No set up fee will be charged when returning to the Services.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        8.4 If we are enabling the same UlapBiz Software, we will ask you to
        make up any missing monthly subscription fees for the period you have
        not used the UlapBiz Software.
      </Typography>
      <Typography variant="h4" className={classes.secondaryTitle}>
        9. Client Referral Scheme
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        9.1 (to be updated)
      </Typography>
      <Typography variant="h4" className={classes.secondaryTitle}>
        10. CPA Partnership
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        10.1 The Client has the option to grant viewing access to the outsourced
        Accounant/CPA.
      </Typography>
      <Typography variant="h4" className={classes.secondaryTitle}>
        11. Intellectual Property Rights and UlapBiz Software Licence
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        11.1 The Client acknowledges and agrees that all Intellectual Property
        Rights existing or arising in any materials, know-how, specifications,
        inventions, processes, software, applications, data or information
        supplied by us under or in connection with the Services shall at all
        times belong to and remain vested to UlapBiz or its licensors and, save
        as expressly provided hereunder, no proprietary rights or any other
        rights whatsoever are assigned, granted or shall otherwise pass to the
        Client. For the avoidance of doubt, The UlapBiz boxed graphics, logos,
        designs, page headers, button icons, scripts and service names are
        registered trademarks, trademarks or trade dress of UlapBiz in the
        Philippines and/or other countries. They may not be used in connection
        with any other product or service that is likely to cause confusion.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        11.2 In accessing your UlapBiz Software and the Services, you are
        entitled to use the UlapBiz Software for as long as you are subscribed
        to the Services. You recognize and agree that your right to access the
        Services is strictly on the basis of a restricted, non-exclusive,
        personal, non-transferable, revocable license for the duration of your
        paid up subscription only.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        11.3 The Client acknowledges that, in respect of any third party
        Intellectual Property Rights (which may be a feature of any UlapBiz
        Services and Add-Ons or otherwise), the Client's use of any such
        Intellectual Property Rights is conditional on UlapBiz obtaining a
        written licence from the relevant licensor on such terms as will entitle
        UlapBiz to license such rights to the Client. Further, you acknowledge
        and understand that all information (including data files, emails,
        computer software, advertisements, sponsored content and others), which
        you may have access to when using third party services, are the sole
        responsibility of the entity from which such content originated.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        11.4 You shall not:
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          11.4.1 Remove or alter the conditions of use, any copyright notices
          and other identification disclaimers as they may appear on the
          website, or in any print format; or
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          11.4.2 Provide any content by electronic means to any person other
          than an authorised user; or
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          11.4.3 Alter or change any part of the content.
        </Typography>
      </Box>
      <Typography variant="h4" className={classes.secondaryTitle}>
        12. Confidentiality
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        12.1 A party (receiving party) shall keep in strict confidence all
        technical or commercial know-how, specifications, investigations,
        processes or initiatives which are confidential in nature and have been
        disclosed to the receiving party by the other party (disclosing party),
        its employees, agents or subcontractors, and any other confidential
        information concerning the disclosing party's business, its products and
        services which the receiving party may obtain. The receiving party shall
        only disclose such confidential information to those of its employees,
        agents and subcontractors who need to know it for the purpose of
        discharging the receiving party's obligations under the Contract, and
        shall ensure that such employees, agents and subcontractors comply with
        the obligations set out in this clause as though they were a party to
        the Contract. The receiving party may also disclose such of the
        disclosing party's confidential information as is required to the
        disclosed by law, any governmental or regulatory authority or by a court
        of competent jurisdiction. This clause shall survive termination of the
        Contract.
      </Typography>

      <Typography variant="h4" className={classes.secondaryTitle}>
        14. Warranties and Limitation of Liability and Indemnity:
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        14.1 The Client warrants that it has all requisite power and authority
        to execute, deliver and perform its obligations in accordance with these
        Terms and that it shall comply with all applicable laws and regulations
        of any competent authority as they apply to the Services.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        14.2 We will use reasonable endeavors to ensure that the Services are
        suitable for your intended use but we do not warrant that the Services
        will meet your requirements or that they will be error-free, timely,
        reliable, entirely secure, virus-free or available at all times since we
        are dependent on the reliability of the internet and your use of your
        own computer to access the Services. We will try to keep any disruptions
        to a minimum but it may be necessary to temporarily suspend the Services
        from time to time to carry out maintenance and support work.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        14.3 The Services are provided on an "as is" basis. Save as expressly
        set out in these Terms, all warranties, representations, undertakings or
        terms whether express or implied, statutory or otherwise, including in
        particular any implied warranty of satisfactory quality or fitness for
        any particular purpose or use are excluded to the fullest extent
        permitted by law.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        14.4 The Client agrees that all the limitations and exclusions of
        liability in favor of UlapBiz have been drawn to its attention and are
        reasonable in the circumstances under which the Services are to be
        performed. In the event that a court of competent authority does not
        agree that the provisions of clause 17.4 shall apply and in any event,
        subject to clauses 14.5 and 14.6 and this clause, UlapBiz total
        liability shall not exceed the limits of the professional indemnity
        insurance that it maintains from time to time.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        14.5 INDEMNITY: The Client hereby undertakes and agrees to indemnify us
        and keep it fully indemnified for and against any and all costs, losses,
        damages, expense and/or liabilities (including, without limitation, any
        legal fees and expenses) which may be suffered or incurred by UlapBiz
        arising out of or in connection with
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          (i) any breach of the Client's undertakings or obligations set out in
          these Terms, and/or
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          (ii) the Client's negligence, fraud or misconduct.
        </Typography>
      </Box>

      <Typography variant="h4" className={classes.secondaryTitle}>
        15. Termination
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        15.1 The Client shall be deemed to have terminated the Contract if:
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          15.1.1 The Client does not elect to subscribe to the Services at the
          end of any trial period: and/or
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.1.2 The Client cancels their subscription to the Services or fails
          to pay UlapBiz for a subsequent renewal when due.
        </Typography>
      </Box>
      <Typography variant="body1" className={classes.body1}>
        15.2 We may terminate the Contract at any time by giving the Client not
        less than three (3) month's Written Notice or with immediate effect, at
        our sole discretion, should any of the events described in clause 15.6
        occur. The Client may terminate the Contract at any time by giving us
        not less than three (3) month's prior Written Notice.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        15.3 Without prejudice to its other rights and remedies, either party
        may, by Written Notice sent to the other, terminate the Contract with
        immediate effect if the other party:
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          15.3.1 Subject to clause 15.4, commits a material breach of the
          Contract and shall, in case of any remediable breach, fail to remedy
          the same within 14 business days of receipt of a written notice from
          the non-breaching party requiring such remedy; and/or
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.3.2 Is unable to pay its debts or otherwise becomes insolvent or an
          order is made or a resolution passed for the administration,
          winding-up or dissolution of the other party (other than for the
          purposes of a solvent amalgamation or reconstruction) or an
          administrative or other receiver, manager, liquidator, administrator,
          trustee or similar officer is appointed over all or any substantial
          part of the other party's assets or the other party enters into or
          proposes any composition or arrangement with its creditors generally
          or anything analogous to the foregoing occurs in any applicable
          jurisdiction or if it ceases to trade or threatens to cease trade.
        </Typography>
      </Box>
      <Typography variant="body1" className={classes.body1}>
        15.4 For the avoidance of doubt, failure by the Client to pay any
        Charges due to UlapBiz in full in cleared funds by the due date shall
        constitute a material breach of the Contract (see clause15.6). Failure
        by the Client to pay the Charges to UlapBiz in cleared funds by the due
        date on more than two occasions shall entitle us to terminate the
        Contract immediately on giving Written Notice to the Client.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        15.5 Without limiting its other rights or remedies, UlapBiz may suspend
        provision of the Services under the Contract or any other contract
        between the Client and UlapBiz if the Client becomes subject to any of
        the events listed in clause 15.3.2 or we reasonably believe that the
        Client is about to become subject to any of them.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        15.6 The events referred to in clause 15.2 above, which shall entitle us
        to terminate the Contract with immediate effect by written notice (which
        may be given by email) includes, without limitation:
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          15.6.1 Non-Payment – where your UlapBiz Account has been suspended and
          you have not paid us within one month or where your direct debit has
          failed more than once in a financial year;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.6.2 Abusive Behaviour and discrimination – use of racist, abusive,
          homophobic or sexist language or any other threatening or
          inappropriate conduct directed at our staff or third parties;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.6.3 Incompatibility – where it becomes apparent that the Client's
          business is incompatible with the UlapBiz Service due to, by way of
          non-exhaustive examples, the: i. abnormal size of the company.
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.6.4 Excessive Use – where, based on our judgment; the level of
          support required to maintain you is excessive by any objective measure
          of comparison with other users whether by reason of the volume of
          transactions or the number of service support requests; or where you
          are unwilling or unable to use the UlapBiz and the Services properly
          or efficiently despite previous training and support provided;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.6.5 Insufficient Use – where, based on our judgment; you fail to
          update your UlapBiz on a sufficiently frequent basis;
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.6.6 Non-Communication – where there has been no response on your
          side to phone calls or other communications for an extended period of
          time and no instructions have been given in relation to dormancy or
          closure;
        </Typography>
      </Box>
      <Typography variant="body1" className={classes.body1}>
        15.7 Consequences of Termination
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          15.7.1 On termination of the Contract for any reason:
        </Typography>
        <Box ml={2}>
          <Typography variant="body1" className={classes.body1}>
            i. The Client shall immediately pay to us all of UlapBiz outstanding
            unpaid invoices and surcharges and, in respect of Services supplied
            but for which no invoice has been submitted, we shall submit an
            invoice, which shall be payable by the Client immediately on
            receipt;
          </Typography>
          <Typography variant="body1" className={classes.body1}>
            ii. The accrued rights, remedies, obligations and liabilities of the
            parties as at expiry or termination shall be unaffected, including
            the right to claim damages in respect of any breach of the Contract
            which existed at or before the date of termination or expiry; and
          </Typography>
          <Typography variant="body1" className={classes.body1}>
            iii. Clauses which expressly or by implication survive termination
            shall continue in full force and effect.
          </Typography>
        </Box>

        <Typography variant="body1" className={classes.body1}>
          15.7.2 Termination will result in the closure of your UlapBiz Account
          and disengagement as your Agents. In such cases, we will issue a
          disengagement letter that will set out our respective responsibilities
          to ensure a clear understanding to both parties.
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.7.3 Following the cancellation of your account, we will have no
          further responsibility in relation to the preparation of your bases on
          tax filings or preparation of Financial Statements and schedules.
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.7.4 If you require the preparation of your Company's Year End
          accounts prior to leaving UlapBiz, we will ask you to make up the cost
          for producing these accounts to the value amounting to 12 times your
          monthly subscription + VAT provided that the number of monthly
          payments made has not covered the full accounting period for which we
          are producing year end accounts. If you require us to also assist with
          closure of your company, or preparation or dormancy, additional fees
          will apply.
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.7.5 Any data you have in your UlapBiz would need to be exported
          prior to closure.
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.7.6 Suspension of your UlapBiz Account could seriously jeopardize
          our and your ability to manage your company accounts and could lead to
          the missing of submission deadlines, including regular tax filing.
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.7.7 We cannot be held liable for any fines incurred resulting from
          this, and all our obligations under these Terms of Services are
          revoked.
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          15.7.8 If we withdraw the access to the Services, no refund will be
          paid or payable by our company. One month's notice will be given prior
          to the suspension of UlapBiz Account after which time if any issue has
          not been resolved within the following month, we will take action by
          disengaging ourselves and closure of your account. The right to close
          any UlapBiz Account for any reason shall be reserved to the Radztech
          Corporation by giving one month's notice with regards to the matter.
        </Typography>
      </Box>
      <Typography variant="h4" className={classes.secondaryTitle}>
        16. Force Majeure
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        16.1 For the purposes of this Contract, Force Majeure Event means an
        event beyond the reasonable control of UlapBiz including but not limited
        to strikes, lock-outs or other industrial disputes (whether involving
        the our workforce or any other party), failure of a utility service
        (including the Internet) or transport network, act of God, war, riot,
        civil commotion, malicious damage, compliance with any law or
        governmental order, rule, regulation or direction, accident, breakdown
        of plant or machinery, fire, flood, storm or default of suppliers or
        subcontractors.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        16.2 We shall not be liable to the Client as a result of any delay or
        failure to perform our obligations under this Contract as a result of a
        Force Majeure Event.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        16.3 If the Force Majeure Event prevents us from providing any of the
        Services for more than 10 weeks, we shall, without limiting its other
        rights or remedies, have the right to terminate this Contract
        immediately by giving written notice to the Client.
      </Typography>
      <Typography variant="h4" className={classes.secondaryTitle}>
        17. General
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        17.1 Assignment and other dealings: We may at any time assign, transfer,
        mortgage, charge, subcontract, delegate or deal in any other manner with
        all or any of its rights under the Contract to a Group Company or any
        other third party or agent.
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          17.1.1 The Client shall not, without the prior written consent of
          UlapBiz, assign, transfer, mortgage, charge, subcontract, declare a
          trust over or deal in any other manner with any or all of its rights
          or obligations under the Contract.
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          17.1.2 You hereby agree that Radztech Corporation, or a suitably
          qualified third party given an authority (a "provider") is hereby
          appointed to act as your provider in relation to your dealings with
          private companies or any other competent governmental or regulatory
          authority.
        </Typography>
      </Box>
      <Typography variant="body1" className={classes.body1}>
        17.2 Internal Disputes and Complaints. If the Client is a Private
        Company and we become aware of a dispute between parties who are
        shareholders or directors of the company, it should be noted that our
        client is the company itself. We would not provide information or
        services to one party without the express knowledge and permission of
        all parties. Unless otherwise agreed by all parties we will continue to
        supply information to the registered office/normal place of business for
        the attention of the directors.
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          17.2.1 We aim to provide the highest levels of client service and
          accounting support at all times but we recognize that, even with the
          best intentions and systems, things do not always live up to
          expectations. For additional queries and concerns, please put it into
          writing to clientsupport@radztech.com.
        </Typography>
      </Box>

      <Typography variant="body1" className={classes.body1}>
        17.3 Notices
      </Typography>
      <Box ml={2}>
        <Typography variant="body1" className={classes.body1}>
          17.3.1 Any notice or other communication given to a party under or in
          connection with the Contract shall be in the form of a Written Notice,
          addressed to that party at its registered office (if it is a company)
          or its principal place of business (in any other case) or such other
          address as that party may have specified to the other party in writing
          in accordance with this clause. We will communicate with you and with
          third parties via email or by other electronic means, unless another
          method is more appropriate. You will be responsible for virus-checking
          emails and any attachments.
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          17.3.2 A notice or other communication shall be deemed to have been
          received: if delivered personally, when left at the address recorded
          on your UlapBiz Account; if sent by pre-paid first class post or other
          next working day delivery service, at 9:00am on the second Business
          Day after posting; if delivered by commercial courier, on the date and
          at the time that the courier's delivery receipt is signed; or, if sent
          by fax or email, one Business Day after transmission.
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          17.3.3 You agree that we may send you notices via email, regular mail
          or alerts within the Services.
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          17.3.4 The provisions of this clause shall not apply to the service of
          any proceedings or other documents in any legal action.
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          17.3.4 The provisions of this clause shall not apply to the service of
          any proceedings or other documents in any legal action.
        </Typography>
      </Box>

      <Typography variant="body1" className={classes.body1}>
        17.4 Severance. If any provision or part-provision of the Contract is or
        becomes invalid, illegal or unenforceable, it shall be deemed modified
        to the minimum extent necessary to make it valid, legal and enforceable.
        If such modification is not possible, the relevant provision or
        part-provision shall be deemed deleted. Any modification to or deletion
        of a provision or part-provision under this clause shall not affect the
        validity and enforceability of the rest of the Contract.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        17.5 Our Staff. You will not undertake any of the following during the
        term of the Contract and for one and a half (1.5) years from the date of
        termination of the Contract: (i) The client shall not attempt to take
        any of UlapBiz employees. In the event of this occurring, the Client
        agrees to pay to the company a recruitment fee of 100% of the
        employee(s) salaries in question, in addition to any ongoing retainer
        fees within this contract.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        17.6 Waiver. A waiver of any right under the Contract or law is only
        effective if it is in writing and shall not be deemed to be a waiver of
        any subsequent breach or default. No failure or delay by a party in
        exercising any right or remedy provided under the Contract or by law
        shall constitute a waiver of that or any other right or remedy, nor
        shall it prevent or restrict its further exercise of that or any other
        right or remedy. No single or partial exercise of such right or remedy
        shall prevent or restrict the further exercise of that or any other
        right or remedy.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        17.7 Third parties. A person who is not a party to the Contract shall
        not have any rights to enforce its terms.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        17.8 Entire Agreement. The Terms and Service Descriptions constitute the
        entire agreement between the parties involved. The Client acknowledges
        that it has not relied on any statement, promise, representation,
        assurance or warranty made or given by a third party or on behalf of
        UlapBiz, which is not set out in the Contract.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        17.9 Variation. Except as set out in these Terms, no variation of the
        Contract, including the introduction of any additional terms and
        conditions, shall be made effective unless it is agreed in writing and
        signed by us.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        17.10 Governing law. This Contract, and any dispute or claim arising out
        of or in connection with it or its subject matter or formation
        (including non-contractual disputes or claims), shall be governed by,
        and construed in accordance with the law of the Philippines. Our company
        and you agree to attempt to resolve any dispute amicably by discussion
        and speaking among ourselves before resorting to any legal action.
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        17.11 Jurisdiction. Each party irrevocably agrees that the courts of the
        Philippines shall have exclusive jurisdiction to settle any dispute or
        claim arising out of or in connection with this Contract or its subject
        matter or formation (including non-contractual disputes or claims).
      </Typography>
    </Box>
  );
};

export default TermsContent;
