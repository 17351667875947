import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cellDense: {
    padding: 20,
    paddingTop: 2,
    paddingBottom: 2,
    minHeight: 52,
    maxHeight: 52,
    minWidth: 100,
    maxWidth: 100,
    lineHeight: '40px',
    boxSizing: 'border-box'
  },
  header: {
    padding: 20,
    paddingTop: 2,
    paddingBottom: 2,
    minHeight: 52,
    maxHeight: 52,
    minWidth: 100,
    maxWidth: 100,
    boxSizing: 'border-box'
  },
  evenRow: {
    backgroundColor: theme.palette.background.paper
  },
  oddRow: {
    backgroundColor: theme.palette.background.default
  },
  stickyCellEvenRow: {
    backgroundColor: theme.palette.background.paper
  },
  stickyCellOddRow: {
    backgroundColor: theme.palette.background.default
  }
}));

export default useStyles;
