import React from 'react';
import {
  Container,
  Typography,
  makeStyles,
  Box,
  Grid
} from '@material-ui/core';
import { Tilt } from 'react-tilt';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    height: '100%'
  },
  glass: {
    width: '100%',
    height: '100%',
    boxShadow: '0 0 1rem 0 rgba(0, 0, 0, .2)',
    backgroundColor: 'rgba(255, 255, 255, .10)',
    backdropFilter: 'blur(2px)',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 20
  },
  learnMore: {
    color: blue[400],
    transition: 'color 300ms ease-in',
    '&:hover': {
      color: blue[800],
      '& svg': {
        transform: 'translateX(6px)'
      }
    },
    '& svg': {
      marginLeft: 2,
      transition: 'transform 300ms ease-in'
    }
  },
  particle1: {
    position: 'relative',
    zIndex: 10,
    '&:before': {
      content: '""',
      zIndex: -10,
      position: 'absolute',
      backgroundImage: 'url(/static/ibfrs3/obj1.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      top: 0,
      right: 0,
      aspectRatio: 1,
      height: 400,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  },
  highlight: {
    color: theme.palette.primary.main,
    borderBottom: '4px solid ' + theme.palette.primary.main
  },
  sectionTitle: {
    fontSize: '3rem',
    fontWeight: 900,
    textAlign: 'center',
    '& .highlight': {
      color: theme.palette.primary.main,
      borderBottom: '4px solid ' + theme.palette.primary.main
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem'
    }
  }
}));

const Card = ({ title = '', icon = '', statement = '' }) => {
  const classNames = useStyles();

  return (
    <Tilt
      className={classNames.card}
      options={{
        max: 20,
        scale: 1.05,
        speed: 300
      }}
    >
      <Box className={classNames.glass}>
        <img src={icon} alt={title} width={50} height={50} />
        <Typography
          style={{
            fontWeight: 700,
            fontSize: '1.2rem',
            marginTop: '1em',
            marginBottom: '1em'
          }}
          align="center"
        >
          {title}
        </Typography>
        <Typography style={{ fontSize: '1rem', color: 'rgba(0,0,0,0.9)' }}>
          {statement}
        </Typography>
      </Box>
    </Tilt>
  );
};

const BenefitsSection = () => {
  const classNames = useStyles();

  return (
    <Box
      minHeight={600}
      py={10}
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classNames.particle1}
    >
      <Container maxWidth="lg">
        <Typography className={classNames.sectionTitle}>
          Amazing <span className="highlight">Benefits</span> For You
        </Typography>

        <Box mt={10}>
          <Grid container spacing={4} alignItems="stretch">
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Card
                icon="/static/ibfrs3/icons/monitoring.svg"
                title="Realtime Monitoring"
                statement="Provides realtime access to budget/financial data, allowing government officials to have up-to-date information on budgets, spending, revenue, and overall financial performance. Realtime monitoring enables proactive decision-making, timely corrections and resource allocation."
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Card
                icon="/static/ibfrs3/icons/process.svg"
                title="Streamline Processes"
                statement="Enables integration of various financial functions, such as budgeting, expense tracking, revenue management, and reporting. This streamlines processes by eliminating the need for data duplication, manual reconciliations, and transferring information between different systems or departments."
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Card
                icon="/static/ibfrs3/icons/analysis.svg"
                title="Improve Reporting and Analysis"
                statement="System generated reports, making it easier to analyze budget/financial data and extract meaningful insights. It allows for faster and more accurate analyses, enabling informed decision-making and strategic planning."
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Card
                icon="/static/ibfrs3/icons/save-time.svg"
                title="Save Time"
                statement="Eliminates manual and repetitive tasks, such as data entry, calculations, and report generation. It allows employees to focus on more value-added activities like analysis, decision-making, and strategic planning."
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default BenefitsSection;
