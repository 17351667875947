import React from 'react';
import {
  Box,
  Container,
  makeStyles,
  ButtonBase,
  Typography,
  Grid
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  cta: {
    backgroundImage: 'url(/static/ibfrs3/layered-waves1.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  button: {
    height: 52,
    borderRadius: 23,
    border: '2px solid #fff',
    backgroundColor: 'transparent',
    color: '#fff',
    width: '100%',
    transition: 'all 0.5s',
    fontSize: '1.2rem',
    fontWeight: 500,
    maxWidth: 600,
    '&:hover': {
      backgroundColor: '#fff',
      color: '#5B98F2',
      borderColor: '#5B98F2'
    }
  },
  footerListItem: {
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: 300
  },
  ctaDesc: {
    fontSize: '2.2rem',
    fontWeight: 900,
    marginBottom: '0.4em',
    textAlign: 'center',
    color: '#fff',
    marginTop: '2em',
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.8rem'
    }
  }
}));

const Footer = () => {
  const { cta, button, footerListItem, ctaDesc } = useStyles();

  return (
    <Box>
      <Box className={cta}>
        <Container maxWidth="lg">
          <Box
            minHeight={400}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Box
              maxWidth={800}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography className={ctaDesc}>
                Revolutionize The Way You Deliver Public Service, Book A Demo
                Now.
              </Typography>
              <ButtonBase className={button} component={Link} to="/contact-us">
                <Typography>Book a Demo</Typography>
              </ButtonBase>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box bgcolor="#4B8EF1" p={5}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Typography
                style={{ color: '#fff', fontWeight: 700, fontSize: '1.2rem' }}
              >
                Contact Us
              </Typography>

              <Box display="flex" flexDirection="column" gridGap={8} mt={2}>
                <Typography className={footerListItem}>
                  Radztech Business Solutions, Canciller Ave., Cauayan City,
                  3305 Isabela
                </Typography>
                <Typography className={footerListItem}>
                  +63 917 523 5387
                </Typography>
                <Typography className={footerListItem}>
                  +63 956 896 3767
                </Typography>
                <Typography className={footerListItem}>
                  sales@radztech.com
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Typography
                style={{ color: '#fff', fontWeight: 700, fontSize: '1.2rem' }}
              >
                Quick Links
              </Typography>

              <Box display="flex" flexDirection="column" gridGap={8} mt={2}>
                <Link to="/">
                  <Typography className={footerListItem}>Home</Typography>
                </Link>
                <Link to="/benefits">
                  <Typography className={footerListItem}>Benefits</Typography>
                </Link>
                <Link to="/anchors">
                  <Typography className={footerListItem}>Anchors</Typography>
                </Link>
                <Link to="/testimonials">
                  <Typography className={footerListItem}>
                    Testimonials
                  </Typography>
                </Link>
                <Link to="/contact-us">
                  <Typography className={footerListItem}>Contact Us</Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
