import React from 'react';
import { Typography } from '@material-ui/core';

const Text = ({ data }) => {
  const value = data?.value || '';
  const style = data?.row?.style?.[data?.field ?? data?.fld] ?? {};

  return (
    <Typography
      variant="body1"
      style={{
        padding: '0.5em',
        ...style
      }}
    >
      {value}
    </Typography>
  );
};

export default Text;
