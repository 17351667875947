import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Box,
  Typography,
  makeStyles,
  CircularProgress,
  TextField,
  InputAdornment,
  Divider,
  Popover,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListSubheader,
  ListItemSecondaryAction
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useSelectBiz from 'src/views/auth/SelectBiz/useSelectBiz';
import { MAX_RECENT_TO_DISPLAY } from 'src/constants';
import useBizState from 'src/hooks/useBizState';

const useStyles = makeStyles(theme => ({
  root: {
    width: 400,
    minWidth: 400,
    [theme.breakpoints.down('md')]: {
      width: 400,
      minWidth: 400
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: '100%'
    }
  },
  container: {
    cursor: 'pointer'
  },
  typography: {
    padding: theme.spacing(2)
  },
  companyTitle: {
    display: '-webkit-box',
    color: theme.palette.primary.contrastText,
    paddingLeft: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 2,
    '-webkit-box-orient': 'vertical',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2em'
    }
  }
}));

function BizItem({ data, onSelect, disableSelect = false, loading = false }) {
  const handleClick = () => {
    onSelect(data.ixBiz, data.biz_uuid);
  };

  return (
    <ListItem
      alignItems="flex-start"
      button
      onClick={handleClick}
      disabled={disableSelect}
      dense
    >
      <ListItemAvatar>
        <Avatar
          alt={data.sBiz}
          src={`/api/images/biz/${data.ixImage}/logo.jpg`}
        />
      </ListItemAvatar>
      <ListItemText
        primary={data.sBiz}
        secondary={`${data.Address2} ${data.Address2}`}
      />
      {loading ? (
        <ListItemSecondaryAction>
          <CircularProgress size={25} />
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
}

function SelectBizMenu() {
  const classes = useStyles();
  const { biz = {} } = useSelector(state => state.auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreLoading, setMoreLoading] = useState(false);
  const { save: saveBizState } = useBizState();
  const open = Boolean(anchorEl);
  const id = open ? 'select-biz-menu' : undefined;
  const {
    loading,
    error,
    disableSelect,
    setFilter,
    filter,
    bizList,
    bizMenuItems,
    filteredBizList,
    filteredRecentlyOpened,
    setCurrentPage,
    handleSelectBiz,
    handleSelectRecentBiz
  } = useSelectBiz({ loadBizWhenOpen: true, open });

  const recentlyOpened = filteredRecentlyOpened
    .filter(data => data.biz_uuid !== biz?.biz_uuid)
    .slice(0, MAX_RECENT_TO_DISPLAY);
  const bizItems = bizMenuItems.filter(data => data.biz_uuid !== biz?.biz_uuid);
  const hasRecentlyOpened = recentlyOpened.length > 0;
  const hasBizAvailable = bizItems.length > 0;

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = event => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const selectRecent = async (ixBiz, biz_uuid) => {
    saveBizState();
    await handleSelectRecentBiz(ixBiz, biz_uuid);
    setAnchorEl(null);
  };

  const selectBiz = async (ixBiz, biz_uuid) => {
    saveBizState();
    await handleSelectBiz(ixBiz, biz_uuid);
    setAnchorEl(null);
  };

  const incrementPage = () => {
    setCurrentPage(prev => prev + 1);
  };

  const wait = (cb, delay) =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(cb());
      }, [delay]);
    });

  const showMore = async () => {
    setMoreLoading(true);
    await wait(incrementPage, 1000);

    setMoreLoading(false);
  };

  const recentlySelectedBizLoading = ixBiz => {
    const biz = bizList.find(data => data.ixBiz === ixBiz);

    if (!biz) return false;

    return biz?.selected || false;
  };

  const showMoreButton = () => {
    const base = filter === '' ? bizList.length : filteredBizList.length;

    return base > bizItems.length;
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      gridGap=".3em"
      aria-describedby={id}
      onClick={handleClick}
      className={classes.container}
    >
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disablePortal
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Paper component={Box} className={classes.root}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={1}
            px={2}
          >
            <Typography variant="body1" display="block">
              Select Biz
            </Typography>
            <IconButton size="small" onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </Box>

          <Box py={1} px={2}>
            <TextField
              size="small"
              placeholder="Search"
              variant="outlined"
              fullWidth
              autoFocus
              value={filter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                )
              }}
              onChange={e => {
                setCurrentPage(1);
                setFilter(e.target.value);
              }}
            />
          </Box>
          {error.status ? (
            <Box mb={4}>
              <Alert severity="error">
                <AlertTitle>Failed</AlertTitle>
                {error.msg}
              </Alert>
            </Box>
          ) : null}
          {loading ? (
            <Box
              p={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={30} />
            </Box>
          ) : (
            <Box>
              <List dense>
                <ListSubheader disableSticky>Recently Selected</ListSubheader>
                <Divider />
                {hasRecentlyOpened ? (
                  recentlyOpened.map(data => (
                    <BizItem
                      data={data}
                      key={data.biz_uuid}
                      onSelect={selectRecent}
                      loading={recentlySelectedBizLoading(data.ixBiz)}
                      disableSelect={disableSelect}
                    />
                  ))
                ) : (
                  <ListItem>
                    <ListItemText
                      primary="No data to show"
                      primaryTypographyProps={{
                        align: 'center',
                        variant: 'body2',
                        color: 'primary'
                      }}
                    />
                  </ListItem>
                )}

                <ListSubheader disableSticky>Available Biz</ListSubheader>
                <Divider />
                {hasBizAvailable ? (
                  bizItems.map(data => (
                    <BizItem
                      data={data}
                      key={data.biz_uuid}
                      onSelect={selectBiz}
                      loading={data.selected}
                      disableSelect={disableSelect}
                    />
                  ))
                ) : (
                  <ListItem>
                    <ListItemText
                      primary="No data to show"
                      primaryTypographyProps={{
                        align: 'center',
                        variant: 'body2',
                        color: 'primary'
                      }}
                    />
                  </ListItem>
                )}
              </List>

              {showMoreButton() && (
                <>
                  <Divider />
                  <Button
                    color="primary"
                    fullWidth
                    onClick={showMore}
                    disabled={moreLoading}
                  >
                    Show More
                  </Button>
                </>
              )}
            </Box>
          )}
        </Paper>
      </Popover>
      <Typography className={classes.companyTitle} align="center" variant="h4">
        {biz.name}
      </Typography>
      <ArrowDropDownIcon />
    </Box>
  );
}

export default SelectBizMenu;
