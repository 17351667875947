import React, { useEffect, useRef, useState } from 'react';
import {
  TextField,
  MenuItem,
  makeStyles,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  OutlinedInput,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  IconButton,
  Box,
  Chip,
  Tooltip
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import { green, grey } from '@material-ui/core/colors';
import useBranch from 'src/hooks/useBranches';
import { useSelector } from 'react-redux';
import { useToggle } from 'src/hooks';
import { red } from '@material-ui/core/colors';
import { Clear } from '@material-ui/icons';
import searchByField from 'src/utils/searchByField';

const useStyles = makeStyles(theme => ({
  dotGreen: {
    width: '.4em',
    height: '.4em',
    borderRadius: '50%',
    background: green[500],
    marginRight: '.6em'
  },
  dotGrey: {
    width: '.4em',
    height: '.4em',
    borderRadius: '50%',
    background: grey[500],
    marginRight: '.6em'
  },
  dialogContent: {
    height: '500px',
    overflowY: 'scroll',
    padding: 0
  },
  adornEnd: {
    paddingRight: 40
  },

  adornStart: {
    paddingLeft: '.2em'
  }
}));

function Items({ value = [], type = 'normal' }) {
  if (type === 'chip') {
    return value.map(item => (
      <Box mr=".1em" display="inline-block">
        <Chip
          key={item.ixBrch}
          color="primary"
          size="small"
          label={item.sBrch}
        />
      </Box>
    ));
  }

  return value.map(item => item.sBrch).join(', ');
}

export const SelectBranch = ({
  value = 0,
  valueParent = 0,
  valueList = [],
  onChange = e => null,
  cbAllowed = e => null,
  cbLimits = e => null,
  showAllBranchOption = true,
  disregardLimits = false,
  txtFldSettings = {}, //TODO : remove this should pass through rest props
  disabled = false,
  defaultBranch = 0,
  excludedBrch = [],
  useForBudget = false,
  viewType = 'default',
  itemType = 'normal',
  hideClearBranch = false,
  ...rest
}) => {
  const classes = useStyles();
  const {
    data,
    branches,
    parents,
    loading,
    limits,
    hasOnlyOneOption,
    refresh
  } = useBranch(disregardLimits, excludedBrch, useForBudget);
  const [dialogShown, openDialog, closeDialog] = useToggle();
  const [query, setQuery] = useState('');
  const [selectedParent, setSelectedParent] = useState(-1);
  const { brch } = useSelector(({ bizMeta }) => bizMeta);
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel = useRef(null);

  const handleChange = e => {
    onChange(e.target.value);
  };

  const handleChangeGrp = e => {
    const value = e.target.value === -1 ? 0 : e.target.value;
    onChange({
      ixParent: value,
      lstBrch: branches
        .filter(item => item.ixParent === +value)
        .map(item => item.ixBrch)
    });
  };

  const hasPermission = ixBrch => {
    if (!limits.length) return classes.dotGreen;

    return limits.some(({ ixSub }) => ixSub === ixBrch)
      ? classes.dotGreen
      : classes.dotGrey;
  };

  const disabledField = () => (loading ? true : disabled);

  const isSelectedBrchExist = brch =>
    branches.findIndex(({ ixBrch }) => ixBrch === brch) !== -1;

  const setDefaultBranch = () => {
    const isBrchEmpty =
      value === '' ||
      value === null ||
      value === 0 ||
      typeof value === 'undefined';

    if (viewType !== 'default') return;

    if (!isBrchEmpty) return;

    // transaction default brch
    if (limits.length === 0 && isBrchEmpty) {
      onChange(defaultBranch);
      return;
    }

    if (showAllBranchOption && limits.length > 1) return;

    // limit default brch
    const defaultLimitBranch = limits.find(sub => Boolean(sub.bDefault));
    if (defaultLimitBranch) onChange(defaultLimitBranch.ixSub);
    else if (hasOnlyOneOption) onChange(limits[0].ixSub);
    else onChange(isSelectedBrchExist(defaultBranch) ? defaultBranch : 0);
  };

  //show the select branch that doesnt exist on the user's limit
  const branchThatDoestExistOnUserLimits = () => {
    const isBrchEmpty = value === '' || value === null || value === 0;
    const hasNoBranches = branches.length === 0;
    const isBranchSelectedExist = isSelectedBrchExist(value);
    const brch = data.items.find(sub => value === sub.ixBrch);

    if (
      isBrchEmpty ||
      hasNoBranches ||
      isBranchSelectedExist ||
      typeof brch === 'undefined'
    )
      return {};
    else return brch;
  };

  const handleOpenDialog = e => {
    const value = e.target.value;
    if (value !== '' || typeof value !== 'undefined' || value !== null) {
      const lastChar = value[value.length - 1];
      setQuery(lastChar);
    }

    openDialog();
  };

  useEffect(() => {
    if (data.allow) setDefaultBranch();
  }, [value]);

  // callbacks for branch settings and user branch limits
  useEffect(() => {
    if (data.allow) {
      cbAllowed({ required: data.allow, label: data.label, ...data });
      cbLimits(limits);
      setDefaultBranch();
    }
  }, [data.allow]);

  useEffect(() => {
    if (inputLabel.current) setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  if (!data.allow) return null;

  if (viewType === 'grp') {
    return (
      <TextField
        value={valueParent === 0 ? -1 : valueParent}
        select
        label={brch?.label_parent ?? data?.label ?? 'Branches'}
        onChange={handleChangeGrp}
        disabled={disabledField()}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Refresh">
                <IconButton
                  aria-label="search"
                  onClick={refresh}
                  size="small"
                  disabled={disabledField()}
                  color="primary"
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
          classes: {
            adornedEnd: classes.adornEnd
          }
        }}
        {...txtFldSettings}
        {...rest}
      >
        <MenuItem key={-1} value={-1} disabled={!showAllBranchOption}>
          {showAllBranchOption
            ? '-- All --'
            : `Select ${brch?.label_parent ?? data?.label ?? 'Branches'}`}
        </MenuItem>

        {parents.map(item => (
          <MenuItem key={item.ixParent} value={item.ixParent}>
            {item.sParent}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  if (viewType === 'multi-select') {
    return (
      <FormControl variant="outlined" {...rest}>
        <InputLabel id="mutiple-chip" htmlFor="mutiple-chip" ref={inputLabel}>
          {rest?.label ?? data?.label ?? 'Branches'}
        </InputLabel>
        <Select
          labelId="mutiple-chip"
          id="mutiple-chip"
          multiple
          value={valueList}
          onChange={handleChange}
          disabled={disabledField()}
          input={<OutlinedInput labelWidth={labelWidth} id="single-chip" />}
          renderValue={selected => (
            <Items
              value={selected.map(item => ({
                ixBrch: item.ixBrch,
                sBrch: branches.find(brch => brch.ixBrch === item)?.sBrch ?? ''
              }))}
              type={itemType}
            />
          )}
          {...rest}
        >
          {branches.map(item => (
            <MenuItem key={item.ixBrch} value={item.ixBrch}>
              <Checkbox checked={valueList.includes(item.ixBrch)} />
              <ListItemText primary={item.sBrch} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  if (branches.length > 10) {
    const branchDescription =
      data.items.find(brch => +brch.ixBrch === +value)?.sBrch ?? '';
    const result = searchByField({
      data: branches.filter(item => {
        if (selectedParent === -1) return true;
        else return item.ixParent === selectedParent;
      }),
      keys: ['sBrch', 'ixBrch', 'sParent', 'ixParent'],
      query
    });

    return (
      <>
        <TextField
          value={branchDescription}
          label={data?.label_singular ?? 'Branch'}
          disabled={disabledField()}
          onChange={handleOpenDialog}
          placeholder="Search..."
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autoFocus: true,
            form: {
              autocomplete: 'off'
            },
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="search"
                  onClick={openDialog}
                  size={txtFldSettings?.size ?? rest?.size ?? 'medium'}
                  disabled={disabledField()}
                >
                  <SvgIcon color="action">
                    <SearchIcon />
                  </SvgIcon>
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Refresh">
                  <IconButton
                    aria-label="search"
                    onClick={refresh}
                    size="small"
                    disabled={disabledField()}
                    color="primary"
                  >
                    <RefreshIcon fontSize={txtFldSettings?.size ?? 'medium'} />
                  </IconButton>
                </Tooltip>
                {!hideClearBranch && (
                  <Tooltip title="Clear">
                    <IconButton
                      aria-label="search"
                      onClick={e => {
                        e.stopPropagation();
                        handleChange({
                          target: {
                            value: 0
                          }
                        });
                      }}
                      size="small"
                      disabled={disabledField()}
                    >
                      <Clear
                        fontSize={
                          txtFldSettings?.size ?? rest?.size ?? 'medium'
                        }
                        style={{
                          color: disabledField()
                            ? 'rgba(0, 0, 0, 0.38)'
                            : red[500]
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </InputAdornment>
            ),
            classes: {
              adornedStart: classes.adornStart
            }
          }}
          {...txtFldSettings}
          {...rest}
        />
        <Dialog
          open={dialogShown}
          maxWidth="sm"
          fullWidth
          onClose={closeDialog}
        >
          <DialogTitle disableTypography>
            <Box display="flex" gridGap="1em" alignItems="center">
              <TextField
                fullWidth
                name="SrchAcc"
                value={query}
                onChange={e => {
                  setQuery(e.target.value);
                }}
                autoComplete="off"
                InputProps={{
                  disableUnderline: true,
                  autoFocus: true,
                  form: {
                    autocomplete: 'off'
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search"
                variant="standard"
                style={{
                  flex: 2
                }}
              />
              {parents.length > 0 && (
                <TextField
                  value={selectedParent}
                  InputProps={{
                    disableUnderline: true
                  }}
                  style={{
                    flex: 1
                  }}
                  onChange={e => setSelectedParent(+e.target.value)}
                  select
                >
                  <MenuItem key={-1} value={-1}>
                    Select Parent
                  </MenuItem>
                  {parents.map(parent => (
                    <MenuItem key={parent.ixParent} value={parent.ixParent}>
                      {parent.sParent}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogContent} dividers>
            <List>
              {result.length === 0 && (
                <ListItem>
                  <ListItemText
                    primary={'No Result Found'}
                    primaryTypographyProps={{
                      variant: 'h4',
                      align: 'center',
                      color: 'textSecondary'
                    }}
                  />
                </ListItem>
              )}
              {result.map(brch => (
                <ListItem
                  button
                  divider
                  key={brch.ixBrch}
                  onClick={() => {
                    handleChange({
                      target: {
                        value: brch.ixBrch
                      }
                    });
                    closeDialog();
                  }}
                >
                  <ListItemText
                    primary={brch.sBrch}
                    primaryTypographyProps={{
                      variant: 'body1'
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption'
                    }}
                    secondary={brch?.sParent}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return (
    <TextField
      value={value}
      select
      label={data?.label_singular ?? 'Branch'}
      onChange={handleChange}
      disabled={disabledField()}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="Refresh">
              <IconButton
                aria-label="search"
                onClick={refresh}
                size="small"
                disabled={disabledField()}
                color="primary"
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
        classes: {
          adornedEnd: classes.adornEnd
        }
      }}
      {...txtFldSettings}
      {...rest}
    >
      {!hasOnlyOneOption && (
        <MenuItem key={0} value={0}>
          {`${
            showAllBranchOption ? `All ` : `Select `
          } ${data?.label_singular ?? 'Branch'}`}
        </MenuItem>
      )}

      {branchThatDoestExistOnUserLimits()?.ixBrch && (
        <MenuItem
          key={branchThatDoestExistOnUserLimits()?.ixBrch}
          value={branchThatDoestExistOnUserLimits()?.ixBrch}
        >
          {branchThatDoestExistOnUserLimits()?.sBrch}
        </MenuItem>
      )}

      {branches.map(item => (
        <MenuItem key={item.ixBrch} value={item.ixBrch}>
          {disregardLimits && <span className={hasPermission(item.ixBrch)} />}
          {item.sBrch}
        </MenuItem>
      ))}
    </TextField>
  );
};
