import React from 'react';
import {
  makeStyles,
  Box,
  Container,
  Typography,
  ButtonBase
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDynamicPages } from '../../DynamicPageProvider';

const useStyles = makeStyles(theme => ({
  cta: {
    backgroundImage: 'url(/static/beyond-accounting/payroll/layered-waves.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  button: {
    height: 52,
    borderRadius: 23,
    border: '2px solid #fff',
    backgroundColor: 'transparent',
    color: '#fff',
    width: '100%',
    transition: 'all 0.5s',
    fontSize: '1.2rem',
    fontWeight: 500,
    maxWidth: 600,
    '&:hover': {
      backgroundColor: '#fff',
      color: '#ff7704'
    }
  }
}));

const CallToAction = () => {
  const { cta, button } = useStyles();
  const { pageDetails } = useDynamicPages();
  const ctaContent = pageDetails?.jsonSettings?.cta ?? {
    text: ''
  };

  return (
    <Box className={cta}>
      <Container maxWidth="lg">
        <Box
          minHeight={400}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            maxWidth={800}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography
              style={{
                fontSize: '2.2rem',
                fontWeight: 900,
                marginBottom: '0.4em',
                textAlign: 'center',
                color: '#fff'
              }}
            >
              {ctaContent?.text ?? ''}
            </Typography>
            <ButtonBase
              fullWidth
              className={button}
              component={Link}
              to="/contact-us"
            >
              <Typography>Book a Demo</Typography>
            </ButtonBase>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CallToAction;
