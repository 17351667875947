import React from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Checkbox,
  Link,
  Typography
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import TinTextField from 'src/components/TinTextField';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {
  changeBizInfo,
  changeCurrentForm
} from 'src/redux/actions/registration';

import useIndustries from './useIndustries';
import usePlans from './usePlans';
import { useEnv } from 'src/hooks';

const BizInfo = () => {
  const dispatch = useDispatch();
  const {
    biz: {
      Name,
      Address1,
      Address2,
      TIN,
      OwnersName,
      ixPlan,
      ixIndustry,
      accept
    },
    backendErrors
  } = useSelector(({ registrationReducer }) => registrationReducer);
  const { industries } = useIndustries();
  const { plans } = usePlans();
  const { EGOV } = useEnv();

  const hasBackendErrors = fld => {
    if (!backendErrors) return false;

    if (!backendErrors?.biz?.validation_errors) return false;

    return backendErrors?.biz?.validation_errors?.[fld];
  };

  const getBackendErrors = fld => {
    if (!backendErrors) return '';

    if (!backendErrors?.biz?.validation_errors) return '';

    return backendErrors?.biz?.validation_errors?.[fld]
      ?.map(data => data)
      .join('');
  };

  return (
    <>
      <Formik
        initialValues={{
          Name,
          Address1,
          Address2,
          TIN,
          OwnersName,
          ixPlan,
          ixIndustry,
          accept
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string()
            .max(255)
            .required('Username is required'),
          OwnersName: Yup.string()
            .max(255)
            .required("Owner's is required"),
          Address1: Yup.string()
            .max(255)
            .required('Street is required'),
          Address2: Yup.string()
            .max(255)
            .required('City/Municipality and Province is required'),
          TIN: Yup.string().max(17),
          ixPlan: Yup.number().required('Plan is required'),
          ixIndustry: EGOV ? Yup.number() : Yup.number().required(),
          accept: Yup.bool().oneOf(
            [true],
            'You must accept the terms of service'
          )
        })}
        onSubmit={(values, action) => {
          dispatch(changeBizInfo(values));
          dispatch(changeCurrentForm(1));
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              error={Boolean(
                (touched.Name && errors.Name) || hasBackendErrors('Name')
              )}
              fullWidth
              helperText={
                (touched.Name && errors.Name) || getBackendErrors('Name')
              }
              label="Business Name"
              margin="normal"
              name="Name"
              onChange={handleChange}
              onBlur={handleBlur}
              type="input"
              value={values.Name}
              variant="outlined"
              required
            />
            <TextField
              error={Boolean(
                (touched.OwnersName && errors.OwnersName) ||
                  hasBackendErrors('OwnersName')
              )}
              fullWidth
              helperText={
                (touched.OwnersName && errors.OwnersName) ||
                getBackendErrors('OwnersName')
              }
              label="Owner's Name"
              margin="normal"
              name="OwnersName"
              onChange={handleChange}
              onBlur={handleBlur}
              type="input"
              value={values.OwnersName}
              variant="outlined"
              required
            />
            {!EGOV && (
              <TextField
                error={Boolean(
                  (touched.ixIndustry && errors.ixIndustry) ||
                    hasBackendErrors('ixIndustry')
                )}
                fullWidth
                helperText={
                  (touched.ixIndustry && errors.ixIndustry) ||
                  getBackendErrors('ixIndustry')
                }
                label="Industry"
                margin="normal"
                name="ixIndustry"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ixIndustry}
                variant="outlined"
                select
                required
              >
                <MenuItem value="">Select Industry</MenuItem>
                {Object.entries(industries ?? {}).map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <TinTextField
              error={Boolean(
                (touched.TIN && errors.TIN) || hasBackendErrors('TIN')
              )}
              fullWidth
              helperText={
                (touched.TIN && errors.TIN) || getBackendErrors('TIN')
              }
              label="Tax Identification Number (Optional)"
              margin="normal"
              name="TIN"
              onChange={handleChange}
              onBlur={handleBlur}
              type="input"
              value={values.TIN}
              variant="outlined"
            />
            <TextField
              error={Boolean(
                (touched.Address1 && errors.Address1) ||
                  hasBackendErrors('Address1')
              )}
              fullWidth
              helperText={
                (touched.Address1 && errors.Address1) ||
                getBackendErrors('Address1')
              }
              label="Street Address"
              margin="normal"
              name="Address1"
              onChange={handleChange}
              onBlur={handleBlur}
              type="input"
              value={values.Address1}
              variant="outlined"
              required
            />

            <TextField
              error={Boolean(
                (touched.Address2 && errors.Address2) ||
                  hasBackendErrors('Address2')
              )}
              fullWidth
              helperText={
                (touched.Address2 && errors.Address2) ||
                getBackendErrors('Address2')
              }
              label="City/Municipality, Province"
              margin="normal"
              name="Address2"
              onChange={handleChange}
              onBlur={handleBlur}
              type="input"
              value={values.Address2}
              variant="outlined"
              required
            />

            <TextField
              error={Boolean(
                (touched.ixPlan && errors.ixPlan) || hasBackendErrors('ixPlan')
              )}
              fullWidth
              helperText={
                (touched.ixPlan && errors.ixPlan) || getBackendErrors('ixPlan')
              }
              label="Choosen Plan"
              margin="normal"
              name="ixPlan"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.ixPlan}
              variant="outlined"
              select
              required
            >
              <MenuItem value="">Select Plan</MenuItem>
              {Object.entries(plans ?? {}).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>

            <Box my={1}>
              <Checkbox
                checked={values.accept}
                name="accept"
                type="checkbox"
                onChange={handleChange}
              />
              <Typography variant="subtitle2" display="inline">
                I agree with{' '}
                <Link href="/terms" target="_blank">
                  Terms of Service <OpenInNewIcon fontSize="inherit" />
                </Link>
              </Typography>
              {touched.accept && errors.accept ? (
                <Typography variant="caption" color="error" display="block">
                  {touched.accept && errors.accept}
                </Typography>
              ) : null}
            </Box>

            <Box display="flex" justifyContent="end" mt={2}>
              <Button type="submit" color="primary" variant="contained">
                Next
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default BizInfo;
