import { useState, useEffect } from 'react';
import axios from 'axios';
import searchByField from 'src/utils/searchByField';
import { useSelector } from 'react-redux';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { decodeString } from 'src/helpers/CodeString';
import APIRequest from 'src/helpers/APIRequest';

const cardDefaultState = {
  comments: { data: [], rows: 15, endReached: false },
  logs: { data: [], rows: 15, endReached: false }
};

const useRecentActivities = ({
  other_users_only = false,
  activityType = 'logs',
  mentions = false,
  query = '',
  onCardView = false
}) => {
  const [recentActivities, setRecentActivities] = useState({
    comments: { data: [], page: 1, rows: 5, error: '' },
    logs: { data: [], page: 1, rows: 5, error: '' }
  });

  const [cardActivities, setCardActivities] = useState(cardDefaultState);

  const [loading, setLoading] = useState(false);

  const { base_url, userToken, current_user } = useSelector(({ auth }) => auth);

  const resetCardActivities = () => {
    setCardActivities(cardDefaultState);
  };

  const fetchCardActivities = async () => {
    if (cardActivities[activityType].endReached) return;

    const url = `${base_url}/trans/wf/assigned-activities/${
      activityType === 'logs' ? 'logs' : 'comments'
    }`;

    const rows = cardActivities[activityType].rows;
    const activitiesLength = cardActivities[activityType].data.length;

    setLoading(true);

    const res = await APIRequest({
      url,
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': userToken
      },
      params: {
        page:
          activitiesLength < rows ? 1 : Math.ceil(activitiesLength / rows) + 1,
        limit: rows
      }
    });

    if (res.success) {
      if (activityType === 'comments' && res.data?.length)
        res.data.forEach(
          item => (item.comment = decodeString(item?.comment || ''))
        );

      setCardActivities(prevState => ({
        ...prevState,
        [activityType]: {
          ...prevState[activityType],
          data: [...prevState[activityType].data, ...res.data],
          endReached: res.data.length < rows
        }
      }));
    }

    setLoading(false);
  };

  const fetchRecentActivities = () => {
    const url = `${base_url}/trans/wf/assigned-activities/${
      activityType === 'logs' ? 'logs' : 'comments'
    }`;

    setLoading(true);

    axios({
      method: 'GET',
      url,
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': userToken
      },
      params: {
        page: recentActivities?.[activityType]?.page ?? 1,
        limit: recentActivities?.[activityType]?.rows ?? 5
      }
    })
      .then(({ data }) => {
        if (activityType === 'comments' && data?.length)
          data.forEach(
            item => (item.comment = decodeString(item?.comment || ''))
          );

        setRecentActivities(prev => ({
          ...prev,
          [activityType]: {
            ...prev[activityType],
            data,
            error: ''
          }
        }));

        setLoading(false);
      })
      .catch(error => {
        setRecentActivities(prev => ({
          ...prev,
          [activityType]: {
            ...prev[activityType],
            error: 'Failed to load ' + activityType
          }
        }));

        setLoading(false);
      });
  };

  const next = () => {
    setRecentActivities(prev => ({
      ...prev,
      [activityType]: {
        ...prev[activityType],
        page: prev[activityType].page + 1
      }
    }));
  };

  const previous = () => {
    setRecentActivities(prev => ({
      ...prev,
      [activityType]: {
        ...prev[activityType],
        page:
          prev[activityType].page - 1 === 0 ? 1 : prev[activityType].page - 1
      }
    }));
  };

  const onChangeRows = rows => {
    setRecentActivities(prev => ({
      ...prev,
      [activityType]: {
        ...prev[activityType],
        rows,
        page: 1
      }
    }));
  };

  useEffect(() => {
    if (!onCardView) fetchRecentActivities();
  }, [
    recentActivities[activityType].page,
    recentActivities[activityType].rows,
    activityType,
    onCardView
  ]);

  const getComments = comments => {
    let data = comments;

    // show other users only
    if (other_users_only) {
      data = data.filter(({ username }) => {
        if (!other_users_only) return true;
        return username !== current_user.username;
      });
    }

    // mentions

    if (mentions) {
      data = searchByField({
        data,
        keys: ['comment'],
        query: `@${current_user.username}`
      });
    }

    if (query !== '') {
      data = searchByField({
        data,
        keys: ['comment', 'Name', 'Particulars', 'JCd'],
        query
      });
    }

    return data.map((item, index) => {
      if (!item.lastview) {
        return {
          ...item,
          isNew: true
        };
      }

      const ts = moment(item.ts);
      const lastview = moment(item.lastview);

      return {
        ...item,

        isNew: lastview.isBefore(ts)
      };
    });
  };

  const getLogs = logs => {
    let data = logs;

    // show other users only
    if (other_users_only) {
      data = data.filter(({ username }) => {
        if (!other_users_only) return true;
        return username !== current_user.username;
      });
    }

    if (query !== '') {
      data = searchByField({
        data,
        keys: ['logDescription', 'Name', 'Particulars', 'JCd'],
        query
      });
    }

    return data.map((item, index) => {
      if (!item.lastview) {
        return {
          ...item,
          isNew: true
        };
      }

      const ts = moment(item.ts);
      const lastview = moment(item.lastview);

      return {
        ...item,

        isNew: lastview.isBefore(ts)
      };
    });
  };

  const filteredRecentActivities = () => {
    return {
      comments: getComments(recentActivities.comments.data),
      logs: getLogs(recentActivities.logs.data)
    };
  };

  const refresh = () => {
    setRecentActivities(prev => ({
      ...prev,
      [activityType]: {
        ...prev[activityType],
        page: 1
      }
    }));

    fetchRecentActivities();
  };

  return {
    recentActivities: filteredRecentActivities(),
    loading,
    page: recentActivities?.[activityType]?.page ?? 1,
    rows: recentActivities?.[activityType]?.rows ?? 5,
    error: recentActivities?.[activityType]?.error ?? 5,
    next,
    previous,
    onChangeRows,
    fetchRecentActivities: refresh,
    cardActivities,
    fetchCardActivities,
    resetCardActivities,
    getComments,
    getLogs
  };
};

export default useRecentActivities;
