import React, { useState } from 'react';
import {
  Chip,
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Tooltip
} from '@material-ui/core';
import { Add, Language, OpenInNew } from '@material-ui/icons';
import { useToggle } from 'src/hooks';
import DebounceTextField from '../DebounceTextField';
import { isValidURL } from 'src/helpers';

const Link = ({ data, onChangeField, editMode = true }) => {
  const links = data?.value || [];

  const [URLError, setURLError] = useState('');
  const [linkDetails, setLinkDetails] = useState({
    url: '',
    label: ''
  });
  const [addOpen, openAdd, closeAdd] = useToggle();

  function validate() {
    if (links.some(item => item.url === linkDetails.url)) {
      setURLError('Link already exists');
      return true;
    }

    if (!isValidURL(linkDetails.url)) {
      setURLError('Not a valid URL');
      return true;
    }

    return false;
  }

  function handleAdd() {
    if (validate()) return;

    onChangeField({
      target: {
        name: data.fld,
        value: [...links, { url: linkDetails.url, label: linkDetails.label }]
      }
    });
    closeAdd();
    setLinkDetails({ url: '', label: '' });
  }

  function handleRemove(url) {
    onChangeField({
      target: {
        name: data.fld,
        value: links.filter(item => item.url !== url)
      }
    });
  }

  /**
   *
   * @param {string} url
   */
  function openURL(url) {
    if (!url.includes('http')) url = 'https://' + url;

    window.open(url, '_blank');
  }

  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={addOpen} onClose={closeAdd}>
        <DialogTitle disableTypography>
          <Typography variant="h4">Add Link</Typography>
        </DialogTitle>
        <DialogContent
          style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
        >
          <DebounceTextField
            fullWidth
            label="Label"
            variant="outlined"
            value={linkDetails.label}
            onChange={e => {
              setLinkDetails(state => ({ ...state, label: e.target.value }));
            }}
          />
          <DebounceTextField
            fullWidth
            label="URL"
            variant="outlined"
            value={linkDetails.url}
            onChange={e => {
              setLinkDetails(state => ({ ...state, url: e.target.value }));
            }}
            error={Boolean(URLError)}
            helperText={URLError}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeAdd}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleAdd}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        <Typography variant="caption" color="textSecondary">
          {data?.caption}
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          mt={1}
          gridGap={8}
          alignItems="center"
        >
          {links?.length ? (
            <>
              {links.map(item => (
                <Tooltip title={item.url}>
                  <Chip
                    key={item.url}
                    clickable
                    variant="outlined"
                    color="primary"
                    label={item.label}
                    icon={<Language fontSize="small" />}
                    onClick={() => openURL(item.url)}
                    onDelete={editMode ? () => handleRemove(item.url) : null}
                  />
                </Tooltip>
              ))}
            </>
          ) : (
            <Typography>No links added</Typography>
          )}
          {editMode && (
            <Chip
              clickable
              color="primary"
              label="Add"
              icon={<Add />}
              onClick={openAdd}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Link;
