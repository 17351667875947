import React, { useEffect, useRef } from 'react';
import Page from 'src/components/Page';
import Footer from 'src/views/landingPage/Footer';
import { usePages } from 'src/hooks';
import { Hero, FeatureList } from 'src/pageComponents';

const features = [
  {
    title: 'Credit And Collection',
    hasSubtitle: true,
    grid: {
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12
    },
    children: [
      {
        grid: {
          lg: 4,
          md: 4,
          sm: 6,
          xs: 12
        },
        subtitle: 'Credit Investigation',
        children: [
          {
            title: 'Documentary Requirements with DaloyWF',
            description:
              "Efficient flow of necessary documents on assessing your business entity's creditworthiness."
          },
          {
            title: 'Promissory Note Auto-fill up and Printing',
            description:
              'Effortlessly fill and print the agreement for better tracking and maintaining organized record.'
          },
          {
            title: 'Customer Profile',
            description:
              'Arranging relevant customer information for better assessment, enhancing record-keeping and facilitating informed decisions.'
          }
        ]
      },
      {
        grid: {
          lg: 4,
          md: 4,
          sm: 6,
          xs: 12
        },
        subtitle: 'Customer Aging Summary',
        children: [
          {
            title: 'Statement of Account',
            description:
              "Monitors the progress of customers' financial transactions and outstanding balances effortlessly."
          },
          {
            title: 'Payment Schedule',
            description: ''
          },
          {
            title: 'Collection Efficiency Calculator',
            description:
              'Assessing the collection transactions and timeliness of customer’s payment.'
          },
          {
            title: 'SMS/Text Blast',
            description:
              'Automatically notifies clients and customers about their pending balances and informs them weeks ahead about their payment through SMS.'
          }
        ]
      },
      {
        grid: {
          lg: 4,
          md: 4,
          sm: 6,
          xs: 12
        },
        subtitle: 'Repo',
        children: [
          {
            title: 'Report with DaloyWF',
            description:
              'Boost the process and documentation of repossessed vehicles by updating records and transactions.'
          },
          {
            title: 'Independent Repo Inventory',
            description:
              'Provides separate Inventory for the repossessed items for more refined directory of sales.'
          },
          {
            title: 'Repo Reappraisal',
            description: ''
          }
        ]
      }
    ]
  },
  {
    title: 'Accounting',
    grid: {
      lg: 4,
      md: 4,
      sm: 6,
      xs: 12
    },
    children: [
      {
        title: 'Disbursements',
        description:
          'Enhances operational efficiency as it streamlines disbursement processes, ensuring accurate and timely payments.'
      },
      {
        title: 'Customer Deposits',
        description:
          'Effortless management of customer deposits from securely accepting deposits to tracking them with ease.'
      },
      {
        title: 'Payables',
        description:
          'Manages and monitors your payment transactions ensuring accurate and timeliness in all financial transactions.'
      },
      {
        title: 'Receivables',
        description:
          'Helps monitor outstanding balances from customers and ensure timely collection of payments.'
      }
    ]
  },
  {
    title: 'Branch Accounting',
    grid: {
      lg: 4,
      md: 4,
      sm: 6,
      xs: 12
    },
    children: [
      {
        title: 'Petty Cash',
        description:
          'Efficiently manage and track your money kept on hand for small purchases.'
      },
      {
        title: 'Cash Advances',
        description:
          'Streamlines effortless tracking, transparent processing, and efficient record-keeping for managing finances.'
      },
      {
        title: 'Liquidation with DaloyWF',
        description:
          'Facilitate and track liquidation processes for transparent and refined workflow.'
      },
      {
        title: 'Deposit',
        description:
          'Elevating deposit transactions by providing a smooth process in tracking and maintaining detailed record of deposits.'
      }
    ]
  },
  {
    title: 'Reports',
    grid: {
      lg: 4,
      md: 4,
      sm: 6,
      xs: 12
    },
    children: [
      {
        title: 'Ending Inventory with Age',
        description:
          'Categorizes inventory by age and value, identifying slow moving stock and providing reports for efficient inventory management.'
      },
      {
        title: 'Available Units per Branch',
        description:
          'Provides real-time insights into the inventory levels at each branch.'
      },
      {
        title: 'Stock Card',
        description:
          'Tracks inventory item movements, aiding inventory control.'
      },
      {
        title: 'Sales Order Summary',
        description:
          'Providing a concise overview of all sales orders which streamlines order processing, facilitates inventory management, and aids in sales analysis.'
      },
      {
        title: 'Sales Summary',
        description:
          'Offers comprehensive insights into sales performance and easy generation of detailed sales summaries for better decision-making.'
      },
      {
        title: 'Purchase Order Summary',
        description:
          'Provides an overview of your procurement activities for easier tracking of purchase orders and monitoring of order status.'
      },
      {
        title: 'Purchase Summary',
        description:
          'Track and analyze your purchasing activities and generate reports to understand expenditure patterns and better inventory management.'
      },
      {
        title: 'Ending Inventory',
        description:
          'Offers reporting features to accurately track remaining stock levels for an efficient inventory control.'
      }
    ]
  },

  {
    title: 'Inventory',
    grid: {
      lg: 4,
      md: 4,
      sm: 6,
      xs: 12
    },
    children: [
      {
        title: 'Engine and Chassis No.',
        description: ''
      },
      {
        title: 'Parts Inventory',
        description: ''
      },
      {
        title: 'Stock Transfer with DaloyWF',
        description: ''
      },
      {
        title: 'Product Categories & Subcategories',
        description: ''
      },
      {
        title: 'Summary of Units for Registration',
        description: ''
      },
      {
        title: 'Multi-Warehouse',
        description: ''
      },
      {
        title: 'Product Variance',
        description: ''
      }
    ]
  },
  {
    title: 'Branch Inventory',
    grid: {
      lg: 4,
      md: 4,
      sm: 6,
      xs: 12
    },
    children: [
      { title: 'Sales Target', description: '' },
      { title: 'Independent Inventory', description: '' }
    ]
  },
  {
    title: 'Sales',
    grid: {
      lg: 4,
      md: 4,
      sm: 6,
      xs: 12
    },
    children: [
      { title: 'By Branch', description: '' },
      { title: 'By Salesman', description: '' },
      { title: 'By Product Category', description: '' }
    ]
  },
  {
    title: 'Dashboards',
    hasSubtitle: true,
    grid: {
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12
    },
    children: [
      {
        subtitle: 'CEO Dashboard',
        grid: {
          lg: 4,
          md: 4,
          sm: 6,
          xs: 12
        },
        children: [
          {
            title: 'Top Sold Units',
            description: ''
          },
          {
            title: 'Collection Performance',
            description: ''
          },
          {
            title: 'Sales per Branch',
            description: ''
          },
          {
            title: 'Expense Summary',
            description: ''
          },
          {
            title: 'Sales Target',
            description: ''
          }
        ]
      },
      {
        subtitle: 'Branch Dashboard',
        grid: {
          lg: 4,
          md: 4,
          sm: 6,
          xs: 12
        },
        children: [
          {
            title: 'Top Sold Units',
            description: ''
          },
          {
            title: 'Collection Performance',
            description: ''
          },
          {
            title: 'Sales per Branch',
            description: ''
          },
          {
            title: 'Expense Summary',
            description: ''
          },
          {
            title: 'Sales Target',
            description: ''
          }
        ]
      },
      {
        subtitle: 'Customer Dashboard',
        grid: {
          lg: 4,
          md: 4,
          sm: 6,
          xs: 12
        },
        children: [
          {
            title: 'Purchase History',
            description: ''
          },
          {
            title: 'SOA',
            description: ''
          },
          {
            title: 'Outstanding Balance',
            description: ''
          },
          {
            title: 'Customer Profile',
            description: ''
          }
        ]
      }
    ]
  }
];

const MotorcycleDealers = () => {
  const ref = useRef();
  const { ixPage, pageDetails } = usePages('motorcycle', {
    jsonSettings: {
      hero: {
        template: 'template2',
        primaryText: 'ULAPBIZ ERP FOR MOTORCYCLE DEALERS',
        secondaryText:
          'Streamline your motorcycle dealership operations with our tailored ERP solution, designed to optimize inventory management, sales tracking, and customer relations, ensuring smoother rides towards profitability.',
        img: '/static/images/landing-page/ent_motorcycle.png',
        staticImg: true,
        showBg: true,
        show: true
      },
      featureList: {
        template: 'template1',
        show: true,
        features
      }
    }
  });

  useEffect(() => {
    ref.current.scrollIntoView();
  }, []);

  return (
    <Page
      title={`ERP - Motorcycle Dealers`}
      style={{ backgroundColor: '#FFF' }}
    >
      <div ref={ref}>
        {Boolean(pageDetails?.hero?.show) && (
          <Hero ixPage={ixPage} pageDetails={pageDetails.hero} />
        )}
        {Boolean(pageDetails?.featureList?.show) && (
          <FeatureList ixPage={ixPage} pageDetails={pageDetails.featureList} />
        )}
      </div>
      <Footer />
    </Page>
  );
};

export default MotorcycleDealers;
