import { makeStyles } from '@material-ui/core';

export const useStylesTextSeach = makeStyles(theme => ({
  root: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    width: 500,
    minWith: 500
  },
  input: {
    padding: '1px',
    paddingLeft: '-2px',
    flex: 1
  },
  iconButton: {
    padding: 10,
    margin: '1px'
  },
  adornStart: {
    paddingLeft: 1
  },
  adornEnd: {
    paddingRight: 4
  },
  divider: {
    height: 28,
    margin: 2,
    marginRight: 0
  }
}));
