import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  rightTopCorner: {
    position: 'absolute',
    top: -300,
    right: -300,
    transform: 'scale(0.5)',
    transformOrigin: 'top right'
  },
  bottomLeftCorner: {
    position: 'absolute',
    bottom: 0,
    left: -150,
    transform: 'scale(0.5)',
    transformOrigin: 'bottom left'
  },
  particle1: {
    position: 'absolute',
    top: 140,
    left: 360
  },
  container: {
    position: 'relative',
    overflow: 'hidden'
  },
  header: {
    position: 'relative',
    overflow: 'hidden',
    height: 'calc(100vh - 64px)'
  },
  headerBg1: {
    backgroundImage: 'url(/static/beyond-accounting/payroll/lines-bg3.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  headerBg2: {
    backgroundImage: 'url(/static/ibfrs3/background-header.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  wave1Top: {
    position: 'absolute',
    bottom: '98%',
    left: 0
  },
  wave1Bottom: {
    position: 'absolute',
    top: '98%',
    left: 0
  },
  scrollDownWrapper: {
    position: 'absolute',
    bottom: 80,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  indicator: {
    width: '20px',
    height: '35px',
    border: '2px solid #000',
    borderRadius: '50px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '8px',
      height: '8px',
      background: '#000',
      margin: 'auto',
      left: '0',
      right: '0',
      top: 0,
      borderRadius: '50%',
      animation: '$animate 1s linear infinite'
    }
  },
  '@keyframes animate': {
    '0%': {
      top: 0
    },
    '100%': {
      top: '20px',
      transform: 'scale(0.8)'
    }
  },
  scrollDown: {
    color: '#000',
    marginTop: '.1em'
  },
  scrollDownContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  primaryTypography: {
    fontSize: '3.8rem',
    fontWeight: 900
  },
  secondaryTypography: {
    fontSize: '2rem',
    fontWeight: 500,
    marginTop: '1em'
  }
}));

/**
 *
 * @param {object} param
 * @param {string} param.ixPage
 * @param {pageHero} param.pageDetails
 * @returns
 */
const HeroTl1 = ({ pageDetails, ixPage }) => {
  const classNames = useStyles();

  const showBg = pageDetails?.showBg || false;
  const bg = pageDetails?.bg || 'bg1';

  const bgClass = { bg1: classNames.headerBg1, bg2: classNames.headerBg2 }[bg];

  return (
    <Box className={`${classNames.header} ${showBg ? bgClass : ''}`}>
      <Box display="flex" justifyContent="center" height="100%">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
          maxWidth={600}
        >
          {Boolean(pageDetails?.img) && (
            <img
              src={
                pageDetails?.staticImg
                  ? pageDetails?.img
                  : `/api/pages/${ixPage}/images/${pageDetails.img}`
              }
              width={260}
            />
          )}
          <Typography className={classNames.primaryTypography}>
            {pageDetails?.primaryText || ''}
          </Typography>
          <Typography className={classNames.secondaryTypography} align="center">
            {pageDetails?.secondaryText || ''}
          </Typography>
          <Box className={classNames.scrollDownContainer} mt={5}>
            <div className={classNames.indicator} />
            <Typography
              variant="caption"
              display="block"
              className={classNames.scrollDown}
            >
              Scroll Down
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroTl1;
