import React, { useEffect, useState } from 'react';
import { AvatarGroup } from '@material-ui/lab';
import {
  Avatar,
  makeStyles,
  Tooltip,
  Menu,
  MenuItem,
  Box,
  IconButton,
  Chip,
  Popover,
  Dialog,
  DialogActions,
  useTheme,
  Button,
  Typography,
  TextField,
  Grid
} from '@material-ui/core';
import { Add, Edit, Settings } from '@material-ui/icons';
import { useMenu, useToggle } from 'src/hooks';
import CustomNumberFormat from '../CustomNumberFormat';
import { DataGrid } from '@material-ui/data-grid';
import numeral from 'numeral';

const useStyles = makeStyles({
  smallAvatar: {
    width: 32,
    height: 32
  }
});

const Users = ({ data, onChangeField }) => {
  const theme = useTheme();
  const classNames = useStyles();

  const [userPoints, setUserPoints] = useState({});

  const [anchor, open, close] = useMenu();
  const [usersAnchor, openUsers, closeUsers] = useMenu();

  const [userPointsShown, showUserPoints, closeUserPoints] = useToggle();

  /**
   * @type {user[]}
   * @returns {string}
   */
  const users = data?.users || [];

  /**
   * @type {string[]}
   */
  const selectedUsers = data?.value || [];
  const showTotal = data?.showTotal || {};

  const unselectedUsers = users.filter(
    u => !selectedUsers.includes(u.username)
  );

  const editable = 'editable' in data ? data.editable : true;

  /**
   *
   * @param {string} username
   */
  function getFullName(username) {
    const user = users.find(u => u.username === username);
    return user ? user.FName + ' ' + user.LName : 'N/A';
  }

  /**
   *
   * @param {string} username
   */
  function handleAddUser(username) {
    onChangeField({
      target: {
        name: data.fld,
        value: [...selectedUsers, username]
      }
    });
  }

  /**
   *
   * @param {string} username
   */
  function removeUser(username) {
    onChangeField({
      target: {
        name: data.fld,
        value: selectedUsers.filter(u => u !== username)
      }
    });
  }

  function saveUserPoints() {
    if (data.onChangeUserPoints) {
      data.onChangeUserPoints(userPoints);
    }
    closeUserPoints();
  }

  function onCellEditCommit({ id, value }) {
    setUserPoints(state => ({
      ...state,
      [id]: parseFloat(value)
    }));
  }

  useEffect(() => {
    setUserPoints(data?.userPoints || {});
  }, [data?.userPoints]);

  return (
    <>
      <Dialog
        open={userPointsShown}
        fullWidth
        maxWidth="md"
        onClose={closeUserPoints}
      >
        <DataGrid
          rows={selectedUsers.map(u => ({
            user: u,
            points: userPoints?.[u] || 0
          }))}
          getRowId={row => row.user}
          columns={[
            {
              field: 'user',
              headerName: 'User',
              sortable: false,
              flex: 1,
              valueFormatter: ({ value }) => getFullName(value)
            },
            {
              field: 'timeliness',
              headerName: 'Timeliness (%)',
              sortable: false,
              align: 'right',
              editable: true,
              width: 130,
              valueFormatter: ({ value }) => numeral(value).format('0,0.00')
            },
            {
              field: 'quality',
              headerName: 'Quality (%)',
              sortable: false,
              align: 'right',
              editable: true,
              width: 130,
              valueFormatter: ({ value }) => numeral(value).format('0,0.00')
            },
            {
              field: 'collabRate',
              headerName: 'Collab Rate (%)',
              sortable: false,
              align: 'right',
              editable: true,
              width: 130,
              valueFormatter: ({ value }) => numeral(value).format('0,0.00')
            },
            {
              field: 'points',
              headerName: 'Points',
              sortable: false,
              align: 'right',
              editable: true,
              width: 130,
              valueFormatter: ({ value }) => numeral(value).format('0,0.00')
            }
          ]}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          hideFooter
          onCellEditCommit={onCellEditCommit}
        />
        <DialogActions>
          <Button color="primary" size="small" onClick={closeUserPoints}>
            Close
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={saveUserPoints}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        style={{ maxHeight: 260 }}
      >
        {unselectedUsers.length ? (
          unselectedUsers.map(user => (
            <MenuItem
              key={user.ixUser}
              button
              onClick={() => handleAddUser(user.username)}
            >
              {user.FName + ' ' + user.LName}
            </MenuItem>
          ))
        ) : (
          <MenuItem>All users added</MenuItem>
        )}
      </Menu>
      <Popover
        anchorEl={usersAnchor}
        open={Boolean(usersAnchor)}
        onClose={closeUsers}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        style={{ maxHeight: 400 }}
      >
        <Box display="flex" p={1} gridGap={4} flexWrap="wrap" maxWidth={200}>
          {selectedUsers.map(username => {
            const fullName = getFullName(username);

            return (
              <Chip
                key={username}
                avatar={
                  <Avatar
                    alt={fullName}
                    src={`/api/images/user/${username}/avatar.jpg`}
                  />
                }
                label={fullName}
                onDelete={editable ? () => removeUser(username) : null}
              />
            );
          })}
        </Box>
      </Popover>
      <Box display="flex" height="100%" width="100%">
        <AvatarGroup onClick={openUsers} style={{ cursor: 'pointer' }}>
          {selectedUsers.map(user => {
            const fullName = getFullName(user);

            return (
              <Tooltip title={fullName} arrow key={user}>
                <Avatar
                  className={classNames.smallAvatar}
                  alt={fullName.toUpperCase()}
                  src={`/api/images/user/${user}/avatar.jpg`}
                />
              </Tooltip>
            );
          })}
        </AvatarGroup>
        {Boolean(editable) && (
          <IconButton color="primary" onClick={open} size="small">
            <Add fontSize="small" />
          </IconButton>
        )}
        {Boolean(editable && showTotal) && (
          <IconButton color="primary" size="small" onClick={showUserPoints}>
            <Edit fontSize="small" />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default Users;
