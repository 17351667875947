import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const INITIAL_STATE = {
  username: 'none',
  jv_wf_assigned: {},
  loading: false,
  error: null,
  activeCard: '',
  query: '',
  limit: 5,
  page: 0,
  activeStatus: '',
  dateSort: 'desc',
  defaultTransactionChart: 'bar',
  defaultWFChart: 'pie'
};

const slice = createSlice({
  name: 'wfAdmin',
  initialState: INITIAL_STATE,
  reducers: {
    selectUser(state, action) {
      state.username = action.payload;
    },
    setActiveCard(state, action) {
      state.activeCard = action.payload;
    },
    setAssignedTransactionsQuery(state, action) {
      state.query = action.payload;
    },
    setAssignedTransactionsLimit(state, action) {
      state.limit = action.payload;
    },
    setAssignedTransactionsPage(state, action) {
      state.page = action.payload;
    },
    setActiveStatus(state, action) {
      state.activeStatus = action.payload;
    },
    setDateSort(state, action) {
      state.dateSort = action.payload;
    },
    fetchUserAssignedTransactions(state) {
      state.query = '';
      state.limit = 5;
      state.page = 0;
      state.dateSort = 'desc';
      state.loading = true;
      state.error = null;
    },
    fetchUserAssignedTransactionsSuccess(state, action) {
      state.loading = false;
      state.jv_wf_assigned = action.payload;
    },
    fetchUserAssignedTransactionsFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    changeTransactionChart(state, action) {
      state.defaultTransactionChart = action.payload;
    },
    changeWFChart(state, action) {
      state.defaultWFChart = action.payload;
    },
    clearUserAssignedTransactions(state) {
      return {
        ...INITIAL_STATE,
        defaultTransactionChart: state.defaultTransactionChart,
        defaultWFChart: state.defaultWFChart
      };
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const wfAdminReducer = slice.reducer;

export const {
  selectUser,
  setActiveCard,
  setAssignedTransactionsQuery,
  setAssignedTransactionsLimit,
  setAssignedTransactionsPage,
  setActiveStatus,
  setDateSort,
  fetchUserAssignedTransactionsSuccess,
  fetchUserAssignedTransactionsFailed,
  changeTransactionChart,
  changeWFChart,
  clearUserAssignedTransactions,
  clear,
  restore
} = slice.actions;

export let cancelRequest = null;

export const fetchUserAssignedTransactions = username => async (
  dispatch,
  getState
) => {
  const { base_url, userToken } = getState().auth;

  dispatch(slice.actions.fetchUserAssignedTransactions());

  axios({
    method: 'GET',
    url: `${base_url}/trans/wf/assigned/${username}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    },

    cancelToken: new axios.CancelToken(function executor(c) {
      cancelRequest = c;
    })
  })
    .then(({ data }) => {
      dispatch(fetchUserAssignedTransactionsSuccess(data));
    })
    .catch(error => {
      if (axios.isCancel(error)) {
        return;
      }

      dispatch(
        fetchUserAssignedTransactionsFailed(
          "Failed to fetch user's assigned transactions. Please try again"
        )
      );
    });
};

export default wfAdminReducer;
