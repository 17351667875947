import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  bizStatusFilter: 'all',
  bizSearchFilter: '',
  bizList: [],
  adminBizList: []
};

const slice = createSlice({
  name: 'registry',
  initialState: INITIAL_STATE,
  reducers: {
    updateBizStatusFilter(state, action) {
      state.bizStatusFilter = action.payload;
    },
    setBizList(state, action) {
      state.bizList = action.payload;
    },
    setAdminBizList(state, action) {
      state.adminBizList = action.payload;
    },
    setBizSearchFilter(state, action) {
      state.bizSearchFilter = action.payload;
    }
  }
});

const registryReducer = slice.reducer;

export const {
  updateBizStatusFilter,
  setBizList,
  setBizSearchFilter,
  setAdminBizList
} = slice.actions;
export default registryReducer;
