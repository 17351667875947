import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import useStyles from './useStyles';

const features = [
  {
    title: 'Dashboards',
    description:
      'Track the progress of ongoing projects with the Main Dashboard. See each project progress in terms of collection and expenses in graphically with the Project Dashboard.',
    url: '/static/images/solutions/construction/dashboard.png'
  },
  {
    title: 'Financial Statements',
    description:
      'Perform prudent decision-makings with the full picture of your company’s financial position, performance and cash flows generated from the system.',
    url: '/static/images/solutions/construction/fs.png'
  },
  {
    title: 'General and Subsidiary Ledger',
    description:
      'Efficiently review historical data affecting your financial reports with the system’s general and detailed summary of transactions',
    url: '/static/images/solutions/construction/gl_and_sl.png'
  },
  {
    title: 'Property, Plant and Equipment Schedule',
    description:
      'Keep track of your assets’ status and maintain accurate records throughout their lifecycle.',
    url: '/static/images/solutions/construction/ppe.png'
  },
  {
    title: 'Receivables and Payables Management',
    description:
      'Effortlessly monitor outstanding account balances, and ensure timely collections and payments. ',
    url: '/static/images/solutions/construction/sl.png'
  },
  {
    title: 'Disbursement Management',
    description:
      'Record expenses as they happen with the integrated Disbursement System.',
    url: '/static/images/solutions/construction/dv.png'
  }
];

const Feature = ({ title = '', description = '', url = '' }) => {
  const classes = useStyles();
  return (
    <Box className={classes.featureContainer}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flex={1}
        className={classes.featureDetailsContainer}
      >
        <Typography className={classes.featureTitle}>{title}</Typography>
        <Typography
          className={classes.featureDesc}
          variant="body1"
          color="textSecondary"
        >
          {description}
        </Typography>
      </Box>

      <img className={classes.featureImage} src={url} />
    </Box>
  );
};

const Features = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Box className={classes.sectionTitleContainer}>
          <Box flex={1}>
            <Typography variant="body1" className={classes.smallTitle}>
              <span> SYSTEM FEATURES </span>
            </Typography>
            <Typography className={classes.sectionTitle}>
              Empowering Your{' '}
              <span className={classes.secondaryTitleColor}>
                Project's Financial Path
              </span>
            </Typography>
          </Box>
          <Box flex={1} />
        </Box>

        <Box mt={10}>
          {features.map(feature => (
            <Feature key={feature.title} {...feature} />
          ))}
        </Box>
      </Container>
    </div>
  );
};

export default Features;
