import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
  switch: {
    '& .checkbox': {
      opacity: 0,
      position: 'absolute'
    },
    '& .checkbox-label': {
      backgroundColor: '#111',
      width: 50,
      height: 26,
      borderRadius: 50,
      position: 'relative',
      padding: 5,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& .checkbox-label .ball': {
      backgroundColor: '#fff',
      width: 20,
      height: 20,
      position: 'absolute',
      left: 3,
      top: 3,
      borderRadius: '50%',
      transition: 'transform 0.2s linear'
    },
    '& .checkbox:checked + .checkbox-label .ball': {
      transform: 'translateX(24px)'
    }
  }
});

/**
 *
 * @param {object} param
 * @param {string} param.className
 * @param {boolean} param.checked
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} param.onChange
 * @returns
 */
const CustomSwitch = ({
  className = '',
  checked = false,
  onChange = e => {}
}) => {
  const classNames = useStyles();

  return (
    <div className={clsx(classNames.switch, className)}>
      <input
        type="checkbox"
        className="checkbox"
        id="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor="checkbox" className="checkbox-label">
        <FontAwesomeIcon icon={faMoon} color="#ff7704" />
        <FontAwesomeIcon icon={faSun} color="#ff7704" />
        <span className="ball" />
      </label>
    </div>
  );
};

export default CustomSwitch;
