import React from 'react';
import { Box, Typography, makeStyles, Container } from '@material-ui/core';
import useReveal, { Animated } from 'src/hooks/useReveal';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 90,
    paddingBottom: 90,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  description: {
    lineHeight: '1.8rem',
    fontSize: '1rem',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.5rem'
    }
  },
  headerTitle: {
    fontWeight: 900,
    fontSize: '2.3em',
    color: '#FF7704',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em'
    }
  },
  image: {
    objectFit: 'contain',
    borderRadius: '1em',
    width: '100%'
  },
  imgContainer: {
    flex: 1,
    padding: '1em',
    minHeight: '300px',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  mainTitle: {
    fontSize: '1.8rem',
    fontWeight: 500,
    textTransform: 'uppercase'
  },

  flex: {
    display: 'flex',
    display: 'flex',
    marginTop: '4em',
    marginBottom: '4em',
    gap: '4em',
    background: '#F8F8F8',
    padding: '3em',
    borderRadius: '3em',
    '&:nth-child(even)': {
      flexDirection: 'row-reverse'
    },
    '&:first-child': {
      marginTop: 0
    },
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      gap: '1em',
      padding: '1em',
      borderRadius: '1em',
      flexDirection: 'column',
      '&:nth-child(even)': {
        flexDirection: 'column'
      }
    }
  },
  container: {
    flex: 1,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '1em'
    }
  }
}));

const Feature = ({ title, photo, actions, type, description }) => {
  const classes = useStyles();
  const [ref, reveal] = useReveal({ threshold: 0.5 });

  return (
    <div ref={ref} className={classes.flex}>
      <Animated
        to={{
          opacity: reveal ? 1 : 0
        }}
        config={{
          duration: 1000
        }}
        className={classes.imgContainer}
      >
        <img alt={title} className={classes.image} src={photo} loading="lazy" />
      </Animated>

      <Box className={classes.container}>
        <Animated
          to={{
            opacity: reveal ? 1 : 0,
            y: reveal ? 0 : -20
          }}
          config={{
            duration: 1000
          }}
        >
          <Typography
            color="textPrimary"
            variant="h1"
            className={classes.headerTitle}
          >
            {title}
          </Typography>
        </Animated>

        <Animated
          to={{
            opacity: reveal ? 1 : 0,
            y: reveal ? 0 : 20
          }}
          config={{
            duration: 1000
          }}
        >
          {type === 'component' ? (
            description
          ) : (
            <Typography
              variant="body1"
              className={classes.description}
              color="textSecondary"
            >
              {description}
            </Typography>
          )}
        </Animated>
        {actions}
      </Box>
    </div>
  );
};

const NotableFeatures = ({ data }) => {
  const classes = useStyles();
  const items = data?.items || [];

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        {items.map(item => (
          <Feature key={item.title} {...item} />
        ))}
      </Container>
    </div>
  );
};

export default NotableFeatures;
