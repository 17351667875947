import React, { useRef } from 'react';
import NavBar from './NavBar';
import { Outlet } from 'react-router';
import { ThemeProvider, createTheme } from '@material-ui/core';
import IBFRS_THEME from 'src/theme/themes/ibfrs2';
import { useToggle } from 'src/hooks';
import ScrollTrigger from 'react-scroll-trigger';

const iBFRSContext = React.createContext({
  isScrolled: false
});

const IBFRS3Layout = ({ fromUlap = false }) => {
  const theme = createTheme(IBFRS_THEME);
  const header = useRef();

  const [isScrolled, scrolled, atTop] = useToggle();
  const [isMenuOpen, openMenu, closeMenu, toggleMenu] = useToggle();

  return (
    <ThemeProvider theme={theme}>
      <iBFRSContext.Provider
        value={{
          header,
          isScrolled,
          isMenuOpen,
          openMenu,
          closeMenu,
          toggleMenu,
          fromUlap
        }}
      >
        <NavBar />
        <div style={{ paddingTop: 80 }}>
          <ScrollTrigger onExit={scrolled} onEnter={atTop} />
          <Outlet />
        </div>
      </iBFRSContext.Provider>
    </ThemeProvider>
  );
};

export const useIBFRSLayout = () => React.useContext(iBFRSContext);
export default IBFRS3Layout;
