import React from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Tilt } from 'react-tilt';
import themes from 'src/theme/themes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { blue } from '@material-ui/core/colors';
import { useDynamicPages } from '../../DynamicPageProvider';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    height: 280
  },
  glass: {
    width: '100%',
    height: '100%',
    boxShadow: '0 0 1rem 0 rgba(0, 0, 0, .2)',
    backgroundColor: 'rgba(255, 255, 255, .10)',
    backdropFilter: 'blur(2px)',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '40px 20px'
  },
  particle1: {
    position: 'absolute',
    top: '35%',
    right: '4%'
  },
  particle2: {
    position: 'absolute',
    bottom: '20%',
    left: '4%'
  },
  learnMore: {
    color: blue[400],
    transition: 'color 300ms ease-in',
    '&:hover': {
      color: blue[800],
      '& svg': {
        transform: 'translateX(6px)'
      }
    },
    '& svg': {
      marginLeft: 2,
      transition: 'transform 300ms ease-in'
    }
  }
}));

const Card = ({ title = '', icon = '' }) => {
  const classNames = useStyles();

  return (
    <Tilt
      className={classNames.card}
      options={{
        max: 20,
        scale: 1.05,
        speed: 300
      }}
    >
      <Box className={classNames.glass}>
        {icon !== '' && <img src={icon} alt={title} width={50} height={50} />}
        <Typography variant="h3" style={{ fontWeight: 700 }} align="center">
          {title}
        </Typography>
      </Box>
    </Tilt>
  );
};

const Overview = () => {
  const classNames = useStyles();
  const { pageDetails } = useDynamicPages();
  const overview = pageDetails?.jsonSettings?.overview ?? {
    primaryText: '',
    secondaryText: '',
    items: []
  };

  return (
    <Box position="relative">
      <img
        src="/static/beyond-accounting/payroll/dots1.svg"
        className={classNames.particle2}
      />
      <Box minHeight={600} py={10}>
        <Container maxWidth="lg">
          <Typography
            style={{
              fontSize: '3.8rem',
              fontWeight: 900,
              marginBottom: '0.4em'
            }}
          >
            {overview.primaryText}
          </Typography>
          <Box width={{ xs: '100%', sm: '100%', md: '80%', lg: '60%' }}>
            <Typography style={{ fontSize: '1.4rem' }}>
              {overview.secondaryText}
            </Typography>
          </Box>

          {Boolean(overview?.items) && (
            <Box mt={8}>
              <Grid container spacing={5}>
                {overview.items.map(item => (
                  <Grid key={item.id} item lg={3} md={4} sm={6} xs={12}>
                    <Card
                      title={item.text}
                      icon={`/api/pages/${
                        pageDetails?.ixPage
                      }/images/${item.img ?? ''}`}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Overview;
