import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  data: {
    rep: [],
    tDr: 0,
    tCr: 0
  },
  dt1: null,
  dt2: null,
  sDate: '',
  ixBrch: 0,
  loading: false
};

const slice = createSlice({
  name: 'gj',
  initialState: INITIAL_STATE,
  reducers: {
    setData(state, action) {
      state.data.rep = action.payload.rep;
      state.data.tCr = action.payload.tCr;
      state.data.tDr = action.payload.tDr;
    },
    setDateRange(state, action) {
      state.dt1 = action.payload.dt1;
      state.dt2 = action.payload.dt2;
      state.sDate = action.payload.textValue;
    },
    setBranch(state, action) {
      state.ixBrch = action.payload;
    },

    restore(_, action) {
      return action.payload || INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    }
  }
});

const gjReducer = slice.reducer;

export const {
  setDateRange,
  setData,
  setBranch,
  setPage,
  setLimit,
  restore,
  clear
} = slice.actions;
export default gjReducer;
