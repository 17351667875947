import { useEffect, useState } from 'react';
import useRequest from './useRequest';
import useToggle from './useToggleV2';

/**
 *
 * @typedef {object} page
 * @property {pageDetails} jsonSettings
 * @property {string} cdPage
 * @property {number} ixPage
 * @property {string} sPage
 */

/**
 *
 * @param {string} code
 * @param {page} defaultSettings
 * @returns
 */
const usePages = (code = '', defaultSettings = {}) => {
  /**
   * @type {state<page>}
   */
  const [page, setPageDetails] = useState(defaultSettings);
  const [error, setError] = useState(false);

  const [isLoading, loadingOn, loadingOff] = useToggle();
  const request = useRequest(loadingOn, loadingOff);

  const { ixPage, cdPage, sPage, jsonSettings: pageDetails } = page;

  async function getPageDetails(cancelCb) {
    setError(false);
    const res = await request.get(`/pages/by-code/${code}`, {}, cancelCb);

    if (res.success) setPageDetails(res.data);
    else if (!res.isCancelled) setError(true);
  }

  useEffect(() => {
    let cancel = () => {};
    getPageDetails(c => (cancel = c));
    return cancel;
  }, [code]);

  return { isLoading, ixPage, cdPage, sPage, pageDetails, error };
};

export default usePages;
