import { useSnackbar } from 'notistack';

/**
 *
 * @returns {notify}
 */
const useNotif = () => {
  const { enqueueSnackbar } = useSnackbar();

  const success = text => {
    enqueueSnackbar(text, { variant: 'success' });
  };

  const info = text => {
    enqueueSnackbar(text, { variant: 'info' });
  };

  const warning = text => {
    enqueueSnackbar(text, { variant: 'warning' });
  };

  const error = text => {
    enqueueSnackbar(text, { variant: 'error' });
  };

  return { success, info, warning, error };
};

export default useNotif;
