import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { LinearProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    padding: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '.5em',
      '&>*': {
        width: '100%'
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '.5em',
      '&>*': {
        width: '100%'
      }
    }
  }
}));

const ToolbarContainer = ({
  actions,
  children,
  title,
  secondaryTitle,
  alert = null,
  hasActions = true,
  isLoading = false
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  return (
    <Box mt={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        mb={1}
      >
        <Box width="100%">
          <Typography variant="h3" color="primary">
            {title}
          </Typography>

          {!open ? <Box mb={1}>{secondaryTitle}</Box> : null}
          {!open ? <Divider /> : null}
        </Box>
        <Box>
          <IconButton onClick={() => setOpen(prev => !prev)} color="primary">
            {open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={open}>
        <Card>
          {isLoading && <LinearProgress style={{ height: 2 }} />}
          <CardContent>{children}</CardContent>
          <Divider />
          {hasActions ? (
            <CardActions classes={{ root: classes.contentContainer }}>
              {actions}
            </CardActions>
          ) : null}

          {alert !== null ? (
            <>
              <Divider />
              <CardActions classes={{ root: classes.contentContainer }}>
                {alert}
              </CardActions>
            </>
          ) : null}
        </Card>
      </Collapse>
    </Box>
  );
};

export default ToolbarContainer;
