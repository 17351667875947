function getSNCaption({ settings = {}, products = [], type = 'SN' }) {
  const caption = settings?.caption ?? settings?.label ?? type;
  const captionByProdCat = settings?.captionByProdCat ?? {};
  const prodWithProdCat = products.filter(({ product, ...rest }) =>
    Boolean(product?.ixCat || rest?.ixProdCat || rest?.ixCat)
  );

  const prodCategories = prodWithProdCat
    .filter(({ product, ...rest }) => {
      const SNtype = rest?.[type];
      const ixCat = product?.ixCat ?? rest?.ixProdCat ?? rest?.ixCat;
      return (
        Boolean(SNtype) && Boolean(ixCat) && Boolean(captionByProdCat?.[ixCat])
      );
    })
    .map(
      ({ product, ...rest }) => product?.ixCat ?? rest?.ixProdCat ?? rest?.ixCat
    );

  const distinct = prodCategories.reduce((acc, prev) => {
    if (!acc.includes(prev)) acc.push(prev);
    return acc;
  }, []);

  if (prodCategories.length === 0) {
    return caption;
  } else if (prodWithProdCat.length === prodCategories.length) {
    return distinct.map(item => captionByProdCat?.[item] ?? '').join('/');
  } else {
    return [
      caption,
      ...distinct.map(item => captionByProdCat?.[item] ?? '')
    ].join('/');
  }
}

export default getSNCaption;
