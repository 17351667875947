import React from 'react';
import {
  Button,
  Menu,
  MenuItem,
  FormControl,
  SvgIcon
} from '@material-ui/core';
import { Category } from '@material-ui/icons';

const SelectSubCategory = ({
  openSubCategory,
  closeSubCategory,
  productSubCategories,
  productSubCategory,
  subCatAnchorEl,
  productCategory,
  setProductSubCategory
}) => {
  return (
    <FormControl component="fieldset">
      <Button
        onClick={openSubCategory}
        startIcon={
          <SvgIcon fontSize="small">
            <Category />
          </SvgIcon>
        }
        color="primary"
      >
        {productSubCategories.find(
          pSubCat => pSubCat.ixProdCatSub === productSubCategory
        )?.sProdCatSub || 'All Sub-Categories'}
      </Button>
      <Menu
        anchorEl={subCatAnchorEl}
        onClose={closeSubCategory}
        open={Boolean(subCatAnchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem
          button
          onClick={() => {
            setProductSubCategory(0);
            closeSubCategory();
          }}
        >
          All Sub-Categories
        </MenuItem>
        {productCategory !== 0 &&
          productSubCategories.map(({ ixProdCatSub, sProdCatSub }) => (
            <MenuItem
              button
              key={ixProdCatSub}
              onClick={() => {
                setProductSubCategory(ixProdCatSub);
                closeSubCategory();
              }}
            >
              {sProdCatSub}
            </MenuItem>
          ))}
      </Menu>
    </FormControl>
  );
};

export default SelectSubCategory;
