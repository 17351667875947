import {
  createTheme as createMuiTheme,
  responsiveFontSizes
} from '@material-ui/core';
import typography from './typography';
import themes from './themes';
import IBFRS_THEME from './themes/ibfrs';

export const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themesOptions = [...themes];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find(theme => theme.name === config.theme);
  const isEGOV = config.EGOV;

  themeOptions =
    isEGOV && themeOptions?.name === 'DEFAULT' ? IBFRS_THEME : themeOptions;

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  const options = themeOptions[config.mode];

  let theme = createMuiTheme({
    ...baseOptions,
    ...options,
    direction: config.direction
  });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
