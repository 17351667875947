function setObjectValue({ keys = '', value = '', obj = {} }) {
  let copy = { ...obj };
  let ref = copy;

  const splittedKeys = keys.split('.');

  for (let i = 0; i < splittedKeys.length; i++) {
    const key = splittedKeys[i];
    if (!Boolean(ref?.[key]) && i + 1 !== splittedKeys.length) {
      ref[key] = {};
      ref = ref[key];
    } else {
      if (i + 1 === splittedKeys.length) ref[key] = value;
      else ref = ref[key];
    }
  }

  return copy;
}

export default setObjectValue;
