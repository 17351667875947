import React from 'react';
import { IconButton, Link, Tooltip, Typography, Box } from '@material-ui/core';
import moment from 'moment-timezone';
import { ArrowForward, OpenInNew } from '@material-ui/icons';

const tableHeaderMeta = [
  {
    field: 'RefNo',
    headerName: 'Reference No',
    flex: 1,
    renderCell: ({ row }) => (
      <Typography variant="body2">
        <strong>{row.RefNo}</strong>
      </Typography>
    )
  },
  {
    field: 'jDate',
    headerName: 'Transaction Date',
    flex: 1,
    renderCell: ({ row }) => (
      <Box display="flex" flexDirection="column">
        <Typography variant="body2">
          <strong>{moment(row.jDate).format('MMMM D, YYYY')}</strong>
        </Typography>
        <Typography variant="body2">
          {moment().diff(moment(row.jDate), 'days') + ' days ago'}
        </Typography>
      </Box>
    )
  },
  {
    field: 'postDate',
    headerName: 'Date Posted',
    flex: 1,
    renderCell: ({ row }) => (
      <Box display="flex" flexDirection="column">
        <Typography variant="body2">
          <strong>{moment(row.postDate).format('MMMM D, YYYY')}</strong>
        </Typography>
        <Typography variant="body2">
          {moment().diff(moment(row.postDate), 'days') + ' days ago'}
        </Typography>
      </Box>
    )
  },
  {
    field: 'Name',
    headerName: 'Name',
    flex: 2,
    renderCell: ({ row }) => (
      <Box display="flex" flexDirection="column">
        <Typography variant="body2">
          <strong>{row.Name}</strong>
        </Typography>
        <Typography variant="body2">{row.Particulars}</Typography>
      </Box>
    )
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    flex: 1,
    renderCell: ({ id }) => (
      <Box display="flex">
        <Tooltip title="Open">
          <IconButton component={Link} href={'/app/trans/' + id}>
            <ArrowForward fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Open in New Tab">
          <IconButton
            component={Link}
            href={'/app/trans/' + id}
            target="_blank"
          >
            <OpenInNew fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }
];

export default tableHeaderMeta;
