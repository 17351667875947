import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useReveal, { Animated } from 'src/hooks/useReveal';
import { Box, Typography, makeStyles, Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: '#fff',
    height: 'auto',
    width: '100%',
    paddingTop: 100,
    paddingBottom: 100,
    overflow: 'visible'
  },

  description: {
    fontSize: '1rem',
    lineHeight: '2rem',
    marginTop: '2em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  headerTitle: {
    color: '#ff7704',
    fontSize: '2rem',
    fontWeight: 900,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      textAlign: 'center'
    }
  },

  img: {
    '& > img': {
      maxWidth: '100%',
      height: 'auto'
    }
  },
  container: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    gap: '4em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  title2: {
    color: '#ff7704',
    fontWeight: 900,
    fontSize: '1.5em',
    lineHeight: '1.3rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }
}));

const AccountingSoftware = ({ className, onGotoProductLine, ...rest }) => {
  const classes = useStyles();
  const [ref, reveal] = useReveal({ threshold: 0.3 });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <div className={classes.container} ref={ref}>
          <Box flex={1}>
            <Animated
              config={{
                config: { duration: 500 }
              }}
              to={{ opacity: reveal ? 1 : 0, y: reveal ? 0 : 100 }}
            >
              <Typography className={classes.headerTitle}>
                CLOUD-BASED ACCOUNTING SYSTEM (PHILIPPINES)
              </Typography>
            </Animated>

            <Animated
              config={{
                config: { duration: 500 }
              }}
              delay={200}
              to={{ opacity: reveal ? 1 : 0, y: reveal ? 0 : 100 }}
            >
              <Typography variant="body2" className={classes.description}>
                <strong>UlapBiz</strong> is leading a movement to a future where
                all business accounting system are automated. We are powered by
                people with great minds who aim to bring automation in the hands
                of every businessman.
              </Typography>
            </Animated>
            <Animated
              config={{
                config: { duration: 500 }
              }}
              delay={400}
              to={{ opacity: reveal ? 1 : 0, y: reveal ? 0 : 100 }}
            >
              <Typography variant="body2" className={classes.description}>
                <strong> Analyze-Design-Deploy </strong> is our approach for
                crafting a system that truly performs while holding ourselves to
                unparalleled quality standards through making automated system a
                norm in the world of business.
              </Typography>
            </Animated>
          </Box>

          <Box position="relative" flex={1}>
            <Animated
              config={{
                config: { duration: 900 }
              }}
              to={{ opacity: reveal ? 1 : 0, x: reveal ? 0 : 100 }}
              className={classes.img}
            >
              <img src="/static/images/landing-page/acc_system.png" />
            </Animated>
          </Box>
        </div>
      </Container>
    </div>
  );
};

AccountingSoftware.propTypes = {
  className: PropTypes.string
};

export default AccountingSoftware;
