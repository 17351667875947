import { Button } from '@material-ui/core';
import React from 'react';

const ArrayField = ({ data, onChangeField }) => {
  const value = data?.value || [];
  const arrayFill = data?.prop?.arrayFill || {};

  function renderField() {
    if (arrayFill?.type === 'json') {
    }
  }

  return (
    <>
      {value.map(item => renderField())}
      <Button variant="contained" color="primary">
        Add Item
      </Button>
    </>
  );
};

export default ArrayField;
