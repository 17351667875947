import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const AntTabs = withStyles({
  indicator: {
    backgroundColor: '#1890ff'
  }
})(Tabs);

export const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: 0,
    '& .MuiTab-wrapper': {
      display: 'flex',
      flexDirection: 'row',
      '& > *:first-child': {
        marginBottom: 0,
        marginRight: '6px'
      }
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);

const CustomTab = ({ tabs, currentTab, updateCurrentTab }) => {
  return (
    <>
      <AntTabs value={currentTab} onChange={updateCurrentTab}>
        {tabs.map(tab => (
          <AntTab
            key={tab.id}
            label={tab.title}
            icon={tab?.icon ? <tab.icon /> : null}
          />
        ))}
      </AntTabs>
    </>
  );
};

export default CustomTab;
