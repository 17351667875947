import React, { createContext, useContext, useState } from 'react';
import Viewer from 'react-viewer';
import useToggle from 'src/hooks/useToggleV2';

export const ImageViewerContext = createContext({
  open: false,
  close: () => {},
  show: () => {},
  setUrl: () => {},
  url: ''
});

const ImageViewerProvider = ({ children }) => {
  const [open, show, close] = useToggle();
  const [url, setUrl] = useState(null);

  const onClose = () => {
    close();
  };

  return (
    <ImageViewerContext.Provider value={{ open, close, url, setUrl, show }}>
      {children}
      <Viewer
        visible={open}
        onClose={onClose}
        images={[{ src: url, alt: 'image' }]}
        zIndex={1600}
        noImgDetails
        zoomSpeed={0.5}
      />
    </ImageViewerContext.Provider>
  );
};

export const useViewImage = () => useContext(ImageViewerContext);
export default ImageViewerProvider;
