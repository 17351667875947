import React from 'react';
import {
  Box,
  Card,
  makeStyles,
  Typography,
  CardContent,
  useTheme
} from '@material-ui/core';

const useStyles = makeStyles({
  transCard: {
    transition: 'all 200ms ease-in-out',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
    '&:hover': {
      transform: 'translateY(-.5em)',
      boxShadow: '0 6px 10px 0 rgba(0,0,0,.2)'
    }
  }
});

const TransCard = ({
  showCaption = false,
  caption = 'New',
  color = '#FFF',
  children
}) => {
  const theme = useTheme();
  const classNames = useStyles();

  return (
    <Card
      style={{
        flexShrink: 0,
        position: 'relative'
      }}
      className={classNames.transCard}
    >
      {showCaption && (
        <Box
          position="absolute"
          width={40}
          height={24}
          bgcolor={color}
          display="flex"
          borderRadius="0 0 0 8px"
          justifyContent="center"
          alignItems="center"
          right={0}
          top={0}
        >
          <Typography
            variant="caption"
            style={{
              color: theme.palette.getContrastText(color),
              fontWeight: 700
            }}
          >
            {caption}
          </Typography>
        </Box>
      )}
      <Box height={4} width="100%" bgcolor={color} />
      <CardContent
        style={{ padding: '1em', marginTop: '.4em', height: '100%' }}
      >
        <Box
          width={160}
          minHeight={180}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {children}
        </Box>
      </CardContent>
    </Card>
  );
};

const TransContent = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column">
      {children}
    </Box>
  );
};

const TransActions = ({ children }) => {
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-end"
      style={{ columnGap: '.4em' }}
    >
      {children}
    </Box>
  );
};

const TransCardContainer = ({ children, ...props }) => {
  return (
    <Box display="flex" {...props}>
      {children}
    </Box>
  );
};

export { TransCardContainer, TransCard, TransContent, TransActions };
