import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '6px 16px'
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  }
}));

/**
 * @typedef {Object} statement
 * @property {Number} key
 * @property {String} title
 * @property {String[]} statements
 */

/**
 *
 * @param {Object} props
 * @param {statement[]} props.timeline
 * @returns {React.JSX.Element}
 */
const VerticalTimeline = ({ timeline }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Timeline align="alternate">
      {timeline.map(item => (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h4" color="primary">
                {item.title}
              </Typography>
              <Box
                mt={1}
                display="flex"
                flexDirection="column"
                gridGap={theme.spacing(1)}
              >
                {item.statements.map(statement => (
                  <Typography variant="body2">{statement}</Typography>
                ))}
              </Box>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default VerticalTimeline;
