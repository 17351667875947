import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import data from '../data';
import { ArrowForward } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import useReveal, { Animated } from 'src/hooks/useReveal';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'auto',
    width: '100%',
    paddingTop: 100,
    paddingBottom: 100,
    background: theme.palette.background.default
  },
  mainTitle: {
    fontSize: '2.5rem',
    fontWeight: 700,
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center'
    }
  },
  feature: {
    height: '100%',
    maxHeight: 70,
    borderRadius: '10px',
    transition: 'max-height 600ms ease-in',
    boxShadow: `rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px`,
    overflow: 'hidden',
    '& .iconContainer': {
      width: 80,
      backgroundColor: '#FF7704',
      transition: 'width 200ms ease-in',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .icon': {
        color: '#FFF'
      }
    },
    '& .infoContainer': {
      width: '100%',
      backgroundColor: '#FFF',
      display: 'flex',
      flexDirection: 'column',
      padding: '1.2em',
      transition: 'all 300ms ease-out',
      '& .title': {
        transition: 'all 300ms ease-in'
      },
      '& .description': {
        marginTop: '1.2em',
        textAlign: 'justify',
        opacity: 0,
        transition: 'opacity 300ms ease-in'
      }
    },
    '&.active': {
      maxHeight: 500,
      '& .iconContainer': {
        width: 0
      },
      '& .infoContainer': {
        '& .description': {
          opacity: 1
        },
        '& .title': {
          fontSize: '1.4rem',
          fontWeight: 900,
          color: '#FF7704'
        }
      }
    }
  }
}));

const Feature = ({
  title,
  description,
  iconSource = 'mui',
  Icon,
  reveal,
  delay
}) => {
  const classes = useStyles();
  const featureRef = useRef();

  let timeoutID;

  function onMouseEnter() {
    timeoutID = setTimeout(function() {
      featureRef.current.classList.add('active');
    }, 500);
  }

  function onMouseLeave() {
    clearTimeout(timeoutID);
    featureRef.current.classList.remove('active');
  }

  const getIcon = () => {
    if (!Icon) return <ArrowForward className="icon" fontSize="large" />;
    if (iconSource === 'fa')
      return (
        <FontAwesomeIcon icon={faIcons[Icon]} size="2x" className="icon" />
      );

    return <Icon className="icon" fontSize="large" />;
  };

  return (
    <Animated
      to={{ opacity: reveal ? 1 : 0, y: reveal ? 0 : 50 }}
      config={{ duration: 500 }}
      delay={delay}
    >
      <Box
        display="flex"
        className={classes.feature}
        ref={featureRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Box className="iconContainer">{getIcon()}</Box>
        <Box className="infoContainer">
          <Typography variant="h4" color="textSecondary" className="title">
            {title}
          </Typography>
          {description && (
            <Typography
              variant="body1"
              color="textSecondary"
              className="description"
            >
              {description}
            </Typography>
          )}
        </Box>
      </Box>
    </Animated>
  );
};

const Features = ({ className, ...rest }) => {
  const classes = useStyles();
  const [refNotableFeature, revealNotableFeature] = useReveal({
    threshold: 0.2
  });
  const [refInteractiveFeature, revelInretactive] = useReveal({
    threshold: 0.2
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={10}>
          <Grid item lg={6} md={6}>
            <div ref={refNotableFeature}>
              <Animated
                to={{
                  opacity: revealNotableFeature ? 1 : 0,
                  y: revealNotableFeature ? 0 : -50
                }}
                congfig={{
                  duration: 500
                }}
              >
                <Typography className={classes.mainTitle} align="center">
                  Notable Features
                </Typography>
              </Animated>
              <Box
                display="flex"
                flexDirection="column"
                style={{ rowGap: '2em' }}
              >
                {data.notable_features.map(
                  ({ title, description, iconSoure, Icon, key }, i) => (
                    <Feature
                      key={key}
                      title={title}
                      description={description}
                      iconSource={iconSoure}
                      Icon={Icon}
                      reveal={revealNotableFeature}
                      delay={i * 200}
                    />
                  )
                )}
              </Box>
            </div>
          </Grid>
          <Grid item lg={6} md={6}>
            <div ref={refInteractiveFeature}>
              <Animated
                to={{
                  opacity: revelInretactive ? 1 : 0,
                  y: revelInretactive ? 0 : -50
                }}
                congfig={{
                  duration: 500
                }}
              >
                <Typography className={classes.mainTitle} align="center">
                  Interactive Features
                </Typography>
              </Animated>
              <Box
                display="flex"
                flexDirection="column"
                style={{ rowGap: '2em' }}
              >
                {data.interactive_features.map(
                  ({ title, description, iconSoure, Icon, key }, i) => (
                    <Feature
                      key={key}
                      title={title}
                      description={description}
                      iconSource={iconSoure}
                      Icon={Icon}
                      reveal={revelInretactive}
                      delay={i * 200}
                    />
                  )
                )}
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
