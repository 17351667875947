import React from 'react';
import { Box, Button, Typography, Link } from '@material-ui/core';
import { useCashierLog } from 'src/components/CashierLog/context/CashierLogProvider';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import numeral from 'numeral';
import moment from 'moment';

function CashierLog() {
  const {
    open,
    cashierLog,
    initLogOff,
    showForceClose,
    logOff,
    isUpdating
  } = useCashierLog();

  if (cashierLog.jid === 0) {
    return (
      <Button
        color="primary"
        variant="contained"
        fullWidth
        onClick={open}
        size="small"
      >
        Log as Cashier
      </Button>
    );
  }

  return (
    <Box>
      <Typography variant="body2" style={{ fontWeight: 900 }}>
        Cashier Log
      </Typography>

      <Box display="flex" flexDirection="column" gridGap=".5em" mt={1}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="caption">Trans. ID :</Typography>
          <Link
            href={'/app/trans/' + cashierLog.jid}
            variant="body2"
            align="right"
            target="_blank"
          >
            <strong> {cashierLog.jid}</strong>{' '}
            <OpenInNewIcon fontSize="inherit" />
          </Link>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography variant="caption">Reference No :</Typography>
          <Link
            href={'/app/trans/' + cashierLog.jid}
            variant="body2"
            align="right"
            target="_blank"
          >
            <strong> {cashierLog.RefNo}</strong>{' '}
            <OpenInNewIcon fontSize="inherit" />
          </Link>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="caption">Time IN:</Typography>
          <Typography variant="body2" align="right" color="textSecondary">
            <strong>
              {moment(cashierLog.date1).format('YYYY-MM-DD hh:mm a')}
            </strong>
          </Typography>
        </Box>
        {!showForceClose && (
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={initLogOff}
            size="small"
          >
            Log off
          </Button>
        )}
        {showForceClose && (
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => logOff({ isForceClosed: true })}
            size="small"
            disabled={isUpdating}
          >
            {isUpdating ? 'Logging off...' : `Force Log off`}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default CashierLog;
