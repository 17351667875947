import { createSlice } from '@reduxjs/toolkit';

const pageHelper = (listReport, index, value) => {
  const copyListReport = [...listReport];
  const list = copyListReport[index];
  list.page = value;
  copyListReport[index] = list;

  return copyListReport;
};

const limitHelper = (listReport, index, value) => {
  const copyListReport = [...listReport];
  const list = copyListReport[index];
  list.limit = value;
  copyListReport[index] = list;

  return copyListReport;
};

const toggleHelper = (listReport, dataIndex, value) => {
  const copyListReport = [...listReport];
  const index = copyListReport.findIndex(data => data.dataIndex === dataIndex);
  const list = copyListReport[index];
  list.isOpen = !value;
  copyListReport[index] = list;
  return copyListReport;
};

const INITIAL_STATE = {
  loading: false,
  ptsReportList: [],
  jePtsServerList: null,
  jePtsCustomerList: null,
  jePtsCustomerListPage: 0,
  jePtsCustomerListLimit: 25,
  searchCustomerText: '',
  sJEPts: null,
  filterFormData: {
    ixAcc: null,
    ixJEPts: null,
    dt1: null,
    dt2: null,
    textValue: ''
  },
  errorStatus: false,
  errorMsg: ''
};

const slice = createSlice({
  name: 'jeptssummary',
  initialState: INITIAL_STATE,
  reducers: {
    onFetchJePtsSummaryList(state, action) {
      state.loading = true;
    },
    loadReportList(state, action) {
      state.loading = false;
      state.ptsReportList = action.payload;
    },
    loadError(state, action) {
      state.loading = false;
      state.errorStatus = true;
      state.errorMsg = action.payload.message;
    },
    selectJePtsReport(state, action) {
      state.sJEPts = action.payload;
    },
    setJePtsID(state, action) {
      state.filterFormData.ixJEPts = action.payload;
    },
    setJePtsAccount(state, action) {
      state.filterFormData.ixAcc = action.payload;
    },
    setDateRange(state, action) {
      state.filterFormData.dt1 = action.payload.dt1;
      state.filterFormData.dt2 = action.payload.dt2;
      state.filterFormData.textValue = action.payload.textValue;
    },
    clearJePtsReport(state, action) {
      return {
        ...INITIAL_STATE,
        ptsReportList: state.ptsReportList,
        sJEPts: state.ptsReportList > 1 ? null : state.sJEPts,
        filterFormData: {
          ixAcc: state.ptsReportList > 1 ? null : state.filterFormData.ixAcc,
          ixJEPts:
            state.ptsReportList > 1 ? null : state.filterFormData.ixJEPts,
          dt1: null,
          dt2: null,
          textValue: ''
        }
      };
    },
    clearReport(state, action) {
      state.jePtsServerList = null;
    },
    clearError(state, action) {
      state.errorStatus = false;
      state.errorMsg = '';
    },
    setJePtsServerReport(state, action) {
      state.loading = false;
      state.jePtsServerList = action.payload;
    },
    setServerReportPage(state, action) {
      state.jePtsServerList = pageHelper(
        state.jePtsServerList,
        action.payload.serveIndex,
        action.payload.page
      );
    },
    setServerReportLimit(state, action) {
      state.jePtsServerList = limitHelper(
        state.jePtsServerList,
        action.payload.serveIndex,
        action.payload.limit
      );
    },
    setJePtsCustomerList(state, action) {
      state.jePtsCustomerList = action.payload;
      state.loading = false;
    },
    setJePtsCustomerListPage(state, action) {
      state.jePtsCustomerListPage = action.payload;
    },
    setJePtsCustomerListLimit(state, action) {
      state.jePtsCustomerListLimit = action.payload;
    },
    setLoading(state, action) {
      state.loading = false;
    },
    setCustomerSearchValue(state, action) {
      state.searchCustomerText = action.payload;
      state.jePtsCustomerList = 0;
    },
    toggleJePtsReportCustomerServeList(state, action) {
      state.jePtsCustomerList = toggleHelper(
        state.jePtsCustomerList,
        action.payload.dataIndex,
        action.payload.status
      );
    },
    cancelRequest(state, action) {
      state.loading = false;
    },
    clear(state, action) {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

export const fetchReportSummaryList = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const {
    loadReportList,
    selectJePtsReport,
    setJePtsID,
    setJePtsAccount,
    loadError
  } = slice.actions;

  try {
    const response = await fetch(`${base_url}/reports/je/pts/list`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-tokens': userToken
      }
    });

    const responseJson = await response.json();

    dispatch(loadReportList(responseJson));
    if (responseJson.length === 1) {
      dispatch(selectJePtsReport(responseJson[0]));
      dispatch(setJePtsID(responseJson[0].ixJEPts));
      dispatch(setJePtsAccount(responseJson[0].ixAcc));
    }
  } catch (err) {
    dispatch(
      loadError({
        message: 'Error - Something went wrong.'
      })
    );
  }
};

const jePtsSummaryReducer = slice.reducer;
export const {
  onFetchJePtsSummaryList,
  loadReportList,
  loadError,
  selectJePtsReport,
  setJePtsAccount,
  setDateRange,
  clearJePtsReport,
  clearReport,
  clearError,
  setJePtsServerReport,
  setServerReportPage,
  setServerReportLimit,
  setJePtsCustomerList,
  setJePtsCustomerListPage,
  setJePtsCustomerListLimit,
  setLoading,
  setCustomerSearchValue,
  toggleJePtsReportCustomerServeList,
  cancelRequest,
  restore,
  clear,
  setJePtsID
} = slice.actions;
export default jePtsSummaryReducer;
