import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  ixBrch: 0,
  dt1: null,
  dt2: null,
  textValue: '',
  tab: 0,
  data: [],
  dataByFC: []
};

const slice = createSlice({
  name: 'saao',
  initialState: INITIAL_STATE,
  reducers: {
    setBranch(state, action) {
      state.ixBrch = action.payload;
    },
    setDateRange(state, action) {
      state.dt1 = action.payload.dt1;
      state.dt2 = action.payload.dt2;
      state.textValue = action.payload.textValue;
    },
    setData(state, action) {
      state.data = action.payload.byRC;
      state.dataByFC = action.payload.byFC;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const saaoReducer = slice.reducer;

export const {
  setBranch,
  setData,
  setDateRange,
  clear,
  restore,
  setTab
} = slice.actions;
export default saaoReducer;
