import React from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { useCashierLog } from './context/CashierLogProvider';
import { NumberInput } from 'src/views/trans/JCdTrans/components/NumberInput';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3)
  }
}));

const CashierLogModal = () => {
  const classes = useStyles();
  const {
    log,
    setCashierLogDetails,
    isInitializing,
    cashierLogDetails,
    loading,
    cashierLogShown: open,
    hideCashierLog: onClose
  } = useCashierLog();

  const onChangeCashBeg = ({ value }) => {
    setCashierLogDetails(prev => {
      return {
        ...prev,
        cashBeg: +value
      };
    });
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose(event, reason);
        }
      }}
      maxWidth="xs"
      TransitionComponent={Transition}
      fullWidth
    >
      <DialogTitle>Cashier Log</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {isInitializing && (
          <Box
            display="flex"
            padding={6}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={40} />
          </Box>
        )}

        {!isInitializing && (
          <NumberInput
            value={cashierLogDetails.cashBeg}
            onChange={onChangeCashBeg}
            textboxProps={{
              label: 'Cash Beginning'
            }}
          />
        )}
      </DialogContent>
      {!isInitializing && (
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            disabled={loading}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={log}
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CashierLogModal;
