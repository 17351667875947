import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useNotif from 'src/hooks/useNotif';
import { setLimits } from 'src/redux/slices/storedValues';
import { useRequest, useToggle } from 'src/hooks';
import { setup, trans } from 'src/lib/endpoints';

export default function useSubSearch({
  ixSubType,
  sSubType,
  disregardLimits,
  autoSelect,
  selectSub,
  isOpen,
  showEdit,
  showAdd,
  ...rest
}) {
  const dispatch = useDispatch();
  const notify = useNotif();
  const { current_user } = useSelector(state => state.auth);
  const { limits: subLimits } = useSelector(state => state.storedValues);
  const [loading, loadingOn, loadingOff] = useToggle();
  const [error, hasError, clearError] = useToggle();
  const [isFormOpen, openForm, closeForm] = useToggle(false);
  const [showInactive, , , toggleInactive] = useToggle();

  const [subList, setSubList] = useState([]);
  const [meta, setMeta] = useState({});
  const [subAccDetails, setSubAccDetails] = useState({});
  const [query, setQuery] = useState('');

  const limits = subLimits?.[current_user?.username]?.[ixSubType]?.limits || [];
  const hasOnlyOneOption = limits.length === 1;
  const hasStoredLimits = Boolean(
    subLimits?.[current_user.username]?.[ixSubType]
  );

  const request = useRequest(loadingOn, loadingOff);

  const filterSearch = (result = []) => {
    if (limits.length === 0 || disregardLimits) return result;

    return result.filter(subAcc =>
      limits.some(limit => limit.ixSub === subAcc.ixSub)
    );
  };

  const loadLimits = async () => {
    if (!hasStoredLimits && ixSubType) {
      const limit = await request.get(trans.limits(ixSubType));

      if (limit.success) {
        dispatch(
          setLimits({
            ixSubType,
            limits: limit.data,
            sSubType
          })
        );

        if (autoSelect) {
          const defaultSub = limit.data.find(sub => Boolean(sub.bDefault));
          if (defaultSub)
            selectSub({
              ixSub: defaultSub.ixSub,
              sub_id: defaultSub.ixSub,
              sSub: defaultSub.sub_title,
              sub_title: defaultSub.sub_title,
              ...rest
            });
          else if (limit.data.length === 1) {
            const sub = limit.data[0];
            selectSub({
              ixSub: sub.ixSub,
              sub_id: sub.ixSub,
              sSub: sub.sub_title,
              sub_title: sub.sub_title,
              ...rest
            });
          }
        }
      }
    }
  };

  function createSubAcc() {
    setSubAccDetails({});
    openForm();
  }

  async function editSubAcc(ixSub) {
    const res = await request.get(setup.sub.subDetails(ixSub));

    if (res.success) {
      openForm();
      setSubAccDetails(res.data);
    } else notify.error(res.errorMessage);
  }

  useEffect(() => {
    if (!isOpen || !hasStoredLimits) {
      return;
    }

    let cancel = () => {};
    clearError();

    (async function() {
      const res = await request.get(
        setup.sub.list,
        {
          params: {
            ixSubType,
            q: (query || '').trim(),
            showInactive
          }
        },
        function(c) {
          cancel = c;
        }
      );

      if (res.success) setSubList(res.data?.sub_list || []);
      else if (!res.isCancelled) hasError();
    })();

    return () => cancel();
  }, [query, isOpen, hasStoredLimits, showInactive]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    loadLimits();
  }, [hasStoredLimits, ixSubType, isOpen]);

  useEffect(() => {
    if ((!showAdd && showEdit) || !ixSubType || !isFormOpen) {
      return;
    }

    let cancel = () => {};

    (async function() {
      const meta = await request.get(setup.subType.meta(ixSubType));

      if (meta.success) setMeta(meta.data);
      else notify.error(meta.error);
    })();

    return () => {
      cancel();
    };
  }, [showAdd, showEdit, ixSubType, isFormOpen]);

  return {
    loading,
    error,
    subList: filterSearch(subList),
    meta,
    isFormOpen,
    openForm,
    closeForm,
    subAccDetails,
    limits,
    hasOnlyOneOption,
    query,
    setQuery,
    editSubAcc,
    createSubAcc,
    showInactive,
    toggleInactive
  };
}
