import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    margin: theme.spacing(1)
  }
}));

export const fetchChanges = async ({ userToken, url }) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url,
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': userToken
      }
    });

    return {
      success: true,
      data
    };
  } catch (error) {
    return {
      success: false,
      error:
        error?.response?.data?.message ??
        error?.response?.data?.msg ??
        error?.response?.data.description ??
        error?.response?.data ??
        'Something went wrong'
    };
  }
};

export const CardChanges = ({ type = '', content = '' }) => {
  const classes = useStyles();

  if (type === '') return null;

  return (
    <Card className={classes.card}>
      <CardHeader
        title={type}
        titleTypographyProps={{
          color: type === 'New' ? 'primary' : 'error'
        }}
      />
      <CardContent>
        <Typography variant="caption"> {content} </Typography>
      </CardContent>
    </Card>
  );
};

export const SectionChanges = ({
  label = '',
  new_value = '',
  old_value = ''
}) => {
  return (
    <>
      <Typography variant="h5">{label}</Typography>
      <Box mt={1} display="flex" justifyContent="space-between">
        <CardChanges type="Old" content={old_value} />
        <CardChanges type="New" content={new_value} />
      </Box>
    </>
  );
};

export const ChangesContent = ({ changes, loading, error }) => {
  if (loading)
    return (
      <Box p={4} display="flex" justifyContent="center">
        <CircularProgress size={30} />
      </Box>
    );

  if (error.status)
    return (
      <Box p={4}>
        <Typography color="error" variant="h4">
          {error.msg}
        </Typography>
      </Box>
    );

  return Object.entries(changes).map(([key, value], i, arr) => (
    <>
      <Box mb={2} mt={2}>
        <SectionChanges {...value} key={i} />
      </Box>
      {i + 1 !== arr.length ? <Divider /> : null}
    </>
  ));
};

export const ViewChanges = ({
  open = false,
  handleClose = () => null,
  url = ''
}) => {
  const [changes, setChanges] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    status: false,
    msg: ''
  });
  const { userToken } = useSelector(state => state.auth);

  useEffect(() => {
    let isCancelled = false;

    const getChanges = async () => {
      setLoading(true);
      setError({
        error: false,
        msg: ''
      });

      if (!isCancelled) {
        const result = await fetchChanges({ userToken, url });
        setLoading(false);
        const actions = [loadError, loadChanges];
        actions[Number(result.success)]({ ...result });
      }
    };

    if (open) getChanges();

    return () => {
      isCancelled = true;
    };
  }, [open]);

  const loadChanges = ({ ...res }) => {
    setChanges({ ...res.data });
  };

  const loadError = ({ ...res }) => {
    setError({
      status: true,
      msg: res.error
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          Changes
          <Button
            onClick={handleClose}
            color="primary"
            variant="text"
            size="small"
          >
            Close
          </Button>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <ChangesContent changes={changes} loading={loading} error={error} />
      </DialogContent>
    </Dialog>
  );
};
