import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  userToken: null,
  loginToken: null,
  current_user: {},
  require_change_pw: false,
  biz: {},
  base_url: '/api',
  users: [],
  ixBiz: 0,
  ixImage: 0,
  ltid: 0,
  reg: false,
  ru_note: '',
  mustSelectBiz: false,
  isBizSelected: false,
  recentlyOpenedBiz: [],
  sandbox: null,
  backend_version: '',
  portal: {
    token: null,
    ixBiz: 0,
    cdPortal: '',
    cdBiz: '',
    ixPortal: '',
    current_user: {},
    linkedSub: {
      ixSub: 0,
      ixSubUser: 0,
      linkStatus: 0,
      sLinkStatus: '',
      sSub: '',
      subStatus: 0
    }
  }
};

const slice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    loadCredentials(state, action) {
      state.userToken = action.payload.token;
      state.loginToken = action.payload.token;
      state.current_user = action.payload.current_user;
      state.biz = action.payload.biz;
      state.mustSelectBiz = action.payload.must_select_biz;
      state.ixImage = action.payload?.biz?.image || 0;
      state.ltid = action.payload?.biz?.ltid || 0;
      state.isBizSelected = false;
      state.reg = action.payload.reg;
      state.ru_note = action.payload.ru_note;
      state.require_change_pw = action.payload?.require_change_pw || false;
      state.backend_version = action?.payload?.version ?? '';
    },
    loadPortalCredentials(state, action) {
      state.portal = {
        ...(state?.portal || {}),
        token: action.payload.portal_token,
        ixBiz: action.payload.biz,
        cdPortal: action.payload.cdPortal,
        ixPortal: action.payload.ixPortal,
        cdBiz: action.payload.cdBiz,
        current_user: action.payload.portal_user,
        linkedSub: action.payload.linked_sub
      };
    },
    reSelectBIZ(state) {
      state.isBizSelected = false;
      state.biz = {};
    },

    selectBIZ(state, action) {
      state.ixBiz = action.payload.ixBiz;
      state.sandbox = action.payload.sandbox;
      state.userToken = action.payload.token;
      state.isBizSelected = true;
      state.ixImage = action?.payload?.bizInfo?.ixImage || 0;
      state.ltid = action?.payload?.bizInfo?.ltid || 0;
      state.biz = {
        ad1: action.payload.bizInfo.Address1,
        ad2: action.payload.bizInfo.Address2,
        name: action.payload.bizInfo.sBiz
      };
      state.ru_note = action.payload.ru_note;
    },
    setRecentlyOpenedBiz(state, action) {
      state.recentlyOpenedBiz = action.payload;
    },
    logout(state, action) {
      return {
        ...state,
        isLoading: false,
        loginToken: null,
        userToken: null,
        isLoggedIn: false,
        current_user: {},
        biz: {},
        mustSelectBiz: false,
        isBizSelected: false,
        reg: false
      };
    },
    logoutPortal(state) {
      state.portal.token = null;
    },
    clearRequireChangePw(state) {
      state.require_change_pw = false;
    }
  }
});

export const logout = () => dispatch => {
  const action = slice.actions;
  dispatch(action.logout());
};

const auth = slice.reducer;
export const {
  loadCredentials,
  loadPortalCredentials,
  reSelectBIZ,
  selectBIZ,
  setRecentlyOpenedBiz,
  logoutPortal,
  clearRequireChangePw
} = slice.actions;
export default auth;
