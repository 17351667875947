import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  teal,
  yellow,
  red
} from '@material-ui/core/colors';

const muiColors = (shade = 500) => ({
  indigo: indigo[shade],
  blue: blue[shade],
  green: green[shade],
  red: red[shade],
  orange: orange[shade],
  amber: amber[shade],
  deepOrange: deepOrange[shade],
  purple: purple[shade],
  yellow: yellow[shade],
  pink: pink[shade],
  cyan: cyan[shade],
  grey: grey[shade],
  lime: lime[shade],
  blueGrey: blueGrey[shade],
  brown: brown[shade],
  deepPurple: deepPurple[shade],
  lightBlue: lightBlue[shade],
  lightGreen: lightGreen[shade],
  teal: teal[shade],
  default: indigo[shade]
});

export const defaultColors = [
  { color: 'blue', shade: 500 },
  { color: 'green', shade: 500 },
  { color: 'orange', shade: 500 },
  { color: 'purple', shade: 500 },
  { color: 'yellow', shade: 500 },
  { color: 'pink', shade: 500 },
  { color: 'deepOrange', shade: 500 },
  { color: 'indigo', shade: 500 },
  { color: 'lime', shade: 500 },
  { color: 'cyan', shade: 500 },
  { color: 'grey', shade: 500 },
  { color: 'amber', shade: 500 },
  { color: 'blueGrey', shade: 500 },
  { color: 'brown', shade: 500 },
  { color: 'deepPurple', shade: 500 },
  { color: 'lightBlue', shade: 500 },
  { color: 'lightGreen', shade: 500 },
  { color: 'teal', shade: 500 }
];

export const colors = [
  blue[500],
  green[500],
  orange[500],
  purple[500],
  yellow[500],
  pink[500],
  deepOrange[500],
  indigo[500],
  lime[500],
  cyan[500],
  grey[500],
  amber[500],
  blueGrey[500],
  brown[500],
  deepPurple[500],
  lightBlue[500],
  lightGreen[500],
  teal[500]
];

export const plainColors = [
  'blue',
  'green',
  'orange',
  'purple',
  'yellow',
  'pink',
  'deepOrange',
  'indigo',
  'lime',
  'cyan',
  'grey',
  'amber',
  'blueGrey',
  'brown',
  'deepPurple',
  'lightBlue',
  'lightGreen',
  'teal'
];

const getColorFromMUI = ({ color = 'default', shade = 500 }) => {
  return muiColors(shade)?.[color] ?? indigo[shade];
};

const objMUIColors = muiColors(500);

export default getColorFromMUI;
export { objMUIColors };
