import React from 'react';
import CustomChip from 'src/components/CustomChip';
import { getWFStatus, getWFColor } from 'src/helper';
import { useSelector } from 'react-redux';
import getixJCd from 'src/helpers/getixJCd';

const WFChip = ({ row }) => {
  const { wf } = useSelector(state => state.bizMeta);

  const ixJCd = getixJCd(row?.JCd, wf);
  const jsonWF = wf?.[ixJCd]?.wf ?? {};

  return (
    <CustomChip
      color={getWFColor(jsonWF, row.wfStatus)}
      label={getWFStatus(jsonWF, row.wfStatus)}
    />
  );
};

export const WFChipPortal = ({ wfStatus, jsonWF }) => {
  return (
    <CustomChip
      color={getWFColor(jsonWF, wfStatus)}
      label={getWFStatus(jsonWF, wfStatus)}
    />
  );
};

export default WFChip;
