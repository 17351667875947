import { escapeRegExp } from 'lodash';

const splitQuery = query =>
  query
    .toLowerCase()
    .split(' ')
    .filter(part => part.trim() !== '');

const getValue = (key = '', items = {}) => {
  const keys = key.split('.');

  if (keys.length === 1) return items?.[key] ?? '';

  let value;

  for (let i = 0; i < keys.length; i++) {
    if (i === 0) value = items?.[keys[i]] ?? '';
    else value = value?.[keys[i]] ?? '';
  }

  return value;
};

const globalSearch = (query, item, keys) => {
  const searchRegEx = new RegExp(escapeRegExp(query), 'i');

  return keys.some(key => searchRegEx.test(getValue(key, item).toString()));
};

const matchByParts = (query, item, keys) => {
  const splittedSearchValue = splitQuery(query);
  return keys.some(key => {
    const splittedKeyValue = splitQuery(getValue(key, item).toString());
    return splittedSearchValue.every((searchValue, i) => {
      const searchRegEx = new RegExp(escapeRegExp(searchValue, 'i'));
      return searchRegEx.test(splittedKeyValue?.[i] ?? '');
    });
  });
};

export default ({ data = [], keys = [], query = '' }) => {
  const filter = query.trim();

  if (query === '') return data;

  return data.filter(
    item => globalSearch(filter, item, keys) || matchByParts(filter, item, keys)
  );
};
