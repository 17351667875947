function convertFileToBase64(file) {
  if (!file) return null;

  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
}

export default convertFileToBase64;
