import { ceil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

/**
 * @template T
 * @param {object} param
 * @param {T} param.items
 * @param {number} param.itemsPerPage
 * @param {number} param.initialPage
 * @param {boolean} param.resetPageOnItemsChange
 * @returns {{
 * pageItems: T,
 * page: number,
 * prevPage: VoidFunction,
 * nextPage: VoidFunction,
 * prevPageAvailable: boolean,
 * nextPageAvailable: boolean,
 * totalPage: number,
 * goToLastPage: VoidFunction,
 * goToFirstPage: VoidFunction,
 * goToPage: (page: number) => void,
 * showPagination: boolean
 * }}
 */
const usePaginate = ({
  items = [],
  itemsPerPage = 10,
  initialPage = 1,
  resetPageOnItemsChange = false
}) => {
  const [page, setPage] = useState(initialPage);

  const totalPage = ceil(items.length / itemsPerPage);
  const prevPageAvailable = page > 1;
  const nextPageAvailable = page < totalPage;

  const showPagination = items.length > itemsPerPage;

  function nextPage() {
    if (!nextPageAvailable) return;

    setPage(currentPage => currentPage + 1);
  }

  function prevPage() {
    if (page === 1) return;

    setPage(currentPage => currentPage - 1);
  }

  function goToLastPage() {
    setPage(totalPage);
  }

  function goToFirstPage() {
    setPage(1);
  }

  function goToPage(desiredPage) {
    setPage(desiredPage);
  }

  const pageItems = useMemo(
    () => items.slice(page * itemsPerPage - itemsPerPage, page * itemsPerPage),
    [items, page, itemsPerPage]
  );

  useEffect(() => {
    setPage(initialPage);
  }, [itemsPerPage]);

  useEffect(() => {
    if (!resetPageOnItemsChange) return;

    setPage(1);
  }, [items]);

  return {
    pageItems,
    page,
    prevPage,
    nextPage,
    prevPageAvailable,
    nextPageAvailable,
    totalPage,
    goToLastPage,
    goToFirstPage,
    goToPage,
    showPagination
  };
};

export default usePaginate;
