function generateTotals(columns = [], data = []) {
  const totals = {};

  for (const col of columns) {
    totals[col] = 0;
  }

  for (const row of data) {
    for (const col of columns) {
      totals[col] += row?.[col] || 0;
    }
  }

  return totals;
}

export default generateTotals;
