import React, { createContext } from 'react';
import {
  Box,
  Collapse,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useDashboardLayout } from 'src/layouts/DashboardLayout';
export const StickyBannerContext = createContext({});

function StickyBanner() {
  const {
    stickyBannerDetails: details,
    isStickyBannerOpen
  } = useDashboardLayout();

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const mediaQ = lg || xl || md;

  const title = details?.title ?? '';
  const detail1 = details?.detail1 ?? '';
  const detail2 = details?.detail2 ?? '';
  const detail3 = details?.detail3 ?? '';
  const detail4 = details?.detail4 ?? '';
  const detail5 = details?.detail5 ?? '';

  return (
    <Box position="absolute" top="0" left="0" right="0" zIndex={999}>
      <Collapse in={isStickyBannerOpen}>
        <Box
          component={Paper}
          p={2}
          square
          borderLeft={3}
          borderColor="primary.main"
          elevation={5}
        >
          <Typography variant={mediaQ ? 'h4' : 'h5'}>{title}</Typography>
          <Box
            display="flex"
            gridGap={mediaQ ? '1em' : '0.5em'}
            flexWrap="wrap"
          >
            {detail1 !== '' && (
              <Typography
                variant={mediaQ ? 'body2' : 'caption'}
                color="textSecondary"
              >
                {detail1}
              </Typography>
            )}
            {detail2 !== '' && (
              <Typography
                variant={mediaQ ? 'body2' : 'caption'}
                color="textSecondary"
              >
                {detail2}
              </Typography>
            )}
            {detail3 !== '' && (
              <Typography
                variant={mediaQ ? 'body2' : 'caption'}
                color="textSecondary"
              >
                {detail3}
              </Typography>
            )}
            {detail4 !== '' && (
              <Typography
                variant={mediaQ ? 'body2' : 'caption'}
                color="textSecondary"
              >
                {detail4}
              </Typography>
            )}
            {detail5 !== '' && (
              <Typography
                variant={mediaQ ? 'body2' : 'caption'}
                color="textSecondary"
              >
                {detail5}
              </Typography>
            )}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}

export default StickyBanner;
