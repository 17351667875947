import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isSidebarOpen: false
};

const slice = createSlice({
  name: 'daloy',
  initialState: INITIAL_STATE,
  reducers: {
    setIsSideBarOpen(state, action) {
      state.isSidebarOpen = action.payload;
    }
  }
});

const globalStateReducer = slice.reducer;

export const { setIsSideBarOpen } = slice.actions;
export default globalStateReducer;
