import { ChangePassword } from 'src/components';
import DashboardLayout from 'src/layouts/DashboardLayout';
import RegistryLayout from 'src/layouts/RegistryLayout';
import ResetPortalPassword from 'src/views/auth/ResetPortalPassword';

const changePwRoutes = {
  app: [
    {
      path: 'portal/:cdBiz/:cdPortal/reset-password',
      element: ResetPortalPassword
    },
    {
      path: 'portal/:cdBiz/:cdPortal/reset-password/:username',
      element: ResetPortalPassword
    },
    {
      path: 'app',
      element: DashboardLayout,
      children: [
        {
          path: '*',
          element: ChangePassword
        }
      ]
    }
  ],
  registry: [
    {
      path: 'portal/:cdBiz/:cdPortal/reset-password',
      element: ResetPortalPassword
    },
    {
      path: 'portal/:cdBiz/:cdPortal/reset-password/:username',
      element: ResetPortalPassword
    },
    {
      path: '*',
      element: RegistryLayout,
      children: [{ path: '*', element: ChangePassword }]
    }
  ]
};

export default changePwRoutes;
