import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import NotFoundView from '../errors/NotFoundView';

const PortalNavigator = () => {
  const navigate = useNavigate();
  const { portal = {} } = useSelector(state => state.auth);
  const { cdBiz, cdPortal } = portal;

  useEffect(() => {
    if (cdBiz && cdPortal) {
      navigate(`/portal/${cdBiz}/${cdPortal}/login`);
    }
  }, [cdBiz, cdPortal]);

  return <NotFoundView />;
};

export default PortalNavigator;
