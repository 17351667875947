import React from 'react';
import AnchorsTl1 from './AnchorsTl1';

/**
 *
 * @param {object} param
 * @param {pageAnchors} param.pageDetails
 * @param {number} param.ixPage
 * @returns
 */
const Anchors = ({ pageDetails, ixPage }) => {
  return <AnchorsTl1 pageDetails={pageDetails} ixPage={ixPage} />;
};

export default Anchors;
