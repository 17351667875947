import React, { Fragment } from 'react';
import { Outlet, Route, Routes } from 'react-router';
// import ErrorBoundary from 'src/components/ErrorBoundary';

const renderRoutes = (routes = [], { portal, app, registry }) =>
  routes.map(route => {
    const props = route.elementProps || {};

    const Guard = route.guard || Fragment;
    const Element = route.element || Outlet;

    return 'children' in route ? (
      <Route
        path={route.path}
        key={route.path}
        element={
          <Guard>
            <Element {...props} />
          </Guard>
        }
      >
        {renderRoutes(route.children, { portal, app, registry })}
      </Route>
    ) : (
      <Route
        path={route.path}
        key={route.path}
        element={
          <Guard>
            {/* <ErrorBoundary portal={portal} app={app} registry={registry}> */}
            <Element {...props} />
            {/* </ErrorBoundary> */}
          </Guard>
        }
      />
    );
  });

const createRouter = (routes = [], { portal, app, registry }) => (
  <Routes>{renderRoutes(routes, { portal, app, registry })}</Routes>
);

export default createRouter;
