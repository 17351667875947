import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  makeStyles
} from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: { fontWeight: 900 }
}));

const MobileMenu = ({ open = false, setOpen, logout }) => {
  const classes = useStyles();
  const location = useLocation();

  const { current_user } = useSelector(({ auth }) => auth);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <Collapse in={open}>
      <List>
        {current_user?.ru === 1 && (
          <ListItem
            button
            component={Link}
            className={classes.root}
            to="/app/registry/admin/biz"
          >
            <ListItemText
              primary="Registry Admin"
              primaryTypographyProps={{
                align: 'center',
                className: classes.root
              }}
            />
          </ListItem>
        )}
        {/* 
        {reg && (
          <ListItem
            button
            component={Link}
            className={classes.root}
            to="/app/registry"
          >
            <ListItemText
              primary="Registry"
              primaryTypographyProps={{
                align: 'center',
                className: classes.root
              }}
            />
          </ListItem>
        )} */}

        <ListItem button component={Link} className={classes.root} to="/">
          <ListItemText
            primary="Select Biz"
            primaryTypographyProps={{
              align: 'center',
              className: classes.root
            }}
          />
        </ListItem>

        <ListItem button className={classes.root} onClick={logout}>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{
              align: 'center',
              className: classes.root
            }}
          />
        </ListItem>
      </List>
    </Collapse>
  );
};

export default MobileMenu;
