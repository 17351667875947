import { baseOptions } from '../';
import { softShadows } from '../shadows';
import { IBFRS_COLORS, THEMES } from 'src/constants';

const { BLUE, LIGHT_GREEN } = IBFRS_COLORS;

const IBFRS_THEME = {
  ...baseOptions,
  name: THEMES.IBFRS_THEME,
  light: {
    palette: {
      type: 'light',
      background: {
        default: '#FAFAFA',
        paper: '#FFFFFF',
        navbar: BLUE,
        dark: '#f4f6f8'
      },
      primary: {
        main: BLUE,
        contrastText: 'rgba(255,255,255,0.87)'
      },
      secondary: {
        main: BLUE,
        contrastText: 'rgba(255,255,255,0.87)'
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(0, 0, 0, 0.38)'
      }
    },
    overrides: {
      MuiAppBar: {
        root: {
          color: '#fff',
          background: `linear-gradient(105deg, ${BLUE} 0%, ${LIGHT_GREEN} 100%)`
        }
      },
      MuiButton: {
        contained: {
          color: '#fff',
          background: `linear-gradient(105deg, ${BLUE} 0%, ${LIGHT_GREEN} 100%)`
        }
      },
      MuiListItem: {
        contained: {
          '&:hover': {
            color: '#fff',
            background: `linear-gradient(105deg, ${BLUE} 0%, ${LIGHT_GREEN} 100%)`
          }
        },
        root: {
          '&:hover': {
            color: '#fff',
            background: `linear-gradient(105deg, ${BLUE} 0%, ${LIGHT_GREEN} 100%)`
          },
          '&:hover .MuiListItemIcon-root': {
            color: '#fff'
          },
          '&:hover .MuiSvgIcon-root': {
            color: '#fff'
          },
          '&:hover .MuiButton-label ': {
            color: '#fff'
          }
        }
      },
      MuiLinearProgress: {
        root: {
          '& .MuiLinearProgress-barColorPrimary': {
            background: `linear-gradient(105deg, ${BLUE} 0%, ${LIGHT_GREEN} 100%)`
          }
        }
      }
    },
    shadows: softShadows
  },
  dark: {
    palette: {
      type: 'dark',
      background: {
        default: '#282C34',
        navbar: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: LIGHT_GREEN,
        contrastText: 'rgba(255,255,255,0.87)'
      },
      secondary: {
        main: LIGHT_GREEN,
        contrastText: 'rgba(255,255,255,0.87)'
      },
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(255, 255, 255, 0.5)'
      }
    },
    overrides: {
      MuiAppBar: {
        root: {
          color: '#fff',
          background: `linear-gradient(105deg, ${BLUE} 0%, ${LIGHT_GREEN} 100%)`
        }
      },
      MuiButton: {
        contained: {
          color: '#fff',
          background: `linear-gradient(105deg, ${BLUE} 0%, ${LIGHT_GREEN} 100%)`
        }
      },
      MuiListItem: {
        contained: {
          '&:hover': {
            color: '#fff',
            background: `linear-gradient(105deg, ${BLUE} 0%, ${LIGHT_GREEN} 100%)`
          }
        },
        root: {
          '&:hover': {
            color: '#fff',

            background: `linear-gradient(105deg, ${BLUE} 0%, ${LIGHT_GREEN} 100%)`
          },
          '&:hover .MuiListItemIcon-root': {
            color: '#fff'
          },
          '&:hover .MuiSvgIcon-root': {
            color: '#fff'
          },
          '&:hover .MuiButton-label ': {
            color: '#fff'
          }
        }
      },
      MuiLinearProgress: {
        root: {
          '& .MuiLinearProgress-barColorPrimary': {
            background: `linear-gradient(105deg, ${BLUE} 0%, ${LIGHT_GREEN} 100%)`
          }
        }
      }
    },
    shadows: softShadows
  }
};

export default IBFRS_THEME;
