import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Box,
  IconButton,
  Button,
  CircularProgress,
  InputAdornment,
  SvgIcon,
  Typography,
  useTheme,
  Tooltip,
  Checkbox,
  Chip
} from '@material-ui/core';
import useATCList from './useATCList';
import { Search, Cached, Close, Add, Check } from '@material-ui/icons';
import ScrollBar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    height: 500,
    padding: 0
  },
  list: {
    padding: 0
  },
  selected: {
    backgroundColor: theme.palette.primary.main + '19',
    '&:hover': {
      backgroundColor: theme.palette.primary.main + '19'
    }
  }
}));

export const AtcModal = ({
  handleSelect,
  open,
  onClose,
  closeWhenATCSelected = false,
  defaultATCList = [],
  loading = false,
  selectedATC = []
}) => {
  const theme = useTheme();
  const { hasError, atc, loading: isGettingAtcList, refresh } = useATCList();
  const [query, setQuery] = useState('');
  const [showAll, setShowAll] = useState(true);
  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    if (defaultATCList.length > 0) {
      setShowToggle(true);
      setShowAll(false);
    }
  }, [defaultATCList]);

  const classes = useStyles();

  const handleChangeFilter = e => {
    const { value } = e.target;

    setQuery(value);
  };

  const filteredList = () => {
    return Object.entries(atc)
      .map(([key, value]) => ({
        key,
        ...value
      }))
      .filter(data => {
        if (showAll) return true;

        if (!showAll && defaultATCList.includes(data.key)) return true;

        return false;
      })
      .filter(
        data =>
          data.key.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          (data.rate * 100)
            .toFixed(0)
            .toString()
            .concat('%')
            .indexOf(query) !== -1 ||
          data.description
            .toString()
            .toLowerCase()
            .indexOf(query.toLowerCase()) !== -1
      );
  };

  const handleSelectATC = ({ key, rate }) => {
    handleSelect({ key, rate });
    if (closeWhenATCSelected) {
      onClose();
    }
  };

  const handleToggle = e => {
    const isChecked = e.target.checked;
    setShowAll(isChecked);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <TextField
          fullWidth
          value={query}
          onChange={handleChangeFilter}
          autoComplete="off"
          autoFocus
          InputProps={{
            disableUnderline: true,
            autoFocus: true,
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon color="action">
                  <Search />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {showToggle && !isGettingAtcList && !loading && (
                  <InputAdornment position="end">
                    <Tooltip title="Show all ATC" arrow>
                      <span>
                        <Checkbox
                          color="default"
                          checked={showAll}
                          onChange={handleToggle}
                        />
                      </span>
                    </Tooltip>
                  </InputAdornment>
                )}
                <InputAdornment position="end">
                  <Tooltip title="Refresh" arrow>
                    <span>
                      <IconButton onClick={refresh} size="small">
                        <Cached />
                      </IconButton>
                    </span>
                  </Tooltip>
                </InputAdornment>
                <InputAdornment position="end">
                  <Tooltip title="Refresh" arrow>
                    <span>
                      <IconButton onClick={onClose} size="small">
                        <Close />
                      </IconButton>
                    </span>
                  </Tooltip>
                </InputAdornment>
              </>
            )
          }}
          placeholder="Search..."
          variant="standard"
        />
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <ScrollBar>
          {(loading || isGettingAtcList) && (
            <Box
              height="100%"
              p={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}

          {hasError && !(loading || isGettingAtcList) && (
            <Box
              height="100%"
              p={3}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gridRowGap={theme.spacing(1)}
            >
              <Typography variant="h3" color="primary">
                Failed to load.
              </Typography>
              <Button color="primary" variant="contained">
                Refresh
              </Button>
            </Box>
          )}

          {!isGettingAtcList && !loading && (
            <List className={classes.list}>
              {filteredList().map(({ key, description, rate }) => {
                const isSelected = selectedATC.includes(key);

                return (
                  <ListItem
                    key={key}
                    role={undefined}
                    divider
                    button
                    onClick={() => {
                      handleSelectATC({ key, rate });
                    }}
                    className={isSelected ? classes.selected : ''}
                  >
                    {isSelected && (
                      <ListItemIcon style={{ marginRight: theme.spacing(1) }}>
                        <Check />
                      </ListItemIcon>
                    )}
                    <ListItemText
                      primary={`${key} - ${(rate * 100).toFixed(0)}%`}
                      primaryTypographyProps={{
                        variant: 'h5'
                      }}
                      secondary={description}
                      text
                      secondaryTypographyProps={{
                        variant: 'caption',
                        align: 'justify'
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          )}
        </ScrollBar>
      </DialogContent>
    </Dialog>
  );
};

const AtcList = ({ selectedList = [], onAddAtc, onRemoveAtc }) => {
  const theme = useTheme();
  const [openList, setOpenList] = useState(false);

  const handleClose = () => {
    setOpenList(false);
  };

  const handleOpen = () => {
    setOpenList(true);
  };

  const handleAddAtc = ({ key }) => {
    if (!selectedList.includes(key)) onAddAtc(key);
  };

  const handleRemoveAtc = atc => {
    onRemoveAtc(atc);
  };

  return (
    <Box>
      <Box display="flex" flexWrap="wrap" gridGap={theme.spacing(1)}>
        {Boolean(selectedList?.length) &&
          selectedList.map(atc => (
            <Chip
              label={atc}
              color="primary"
              variant="outlined"
              onDelete={() => handleRemoveAtc(atc)}
            />
          ))}
        <Chip
          icon={<Add fontSize="small" />}
          label="Add ATC"
          clickable
          color="primary"
          onClick={handleOpen}
        />
      </Box>
      <AtcModal
        open={openList}
        onClose={handleClose}
        handleSelect={handleAddAtc}
        selectedATC={selectedList}
      />
    </Box>
  );
};

export default AtcList;
