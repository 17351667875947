import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Zoom from '@material-ui/core/Zoom';
import ClearIcon from '@material-ui/icons/ClearOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import SearchIcon from '@material-ui/icons/Search';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import DebounceTextField from '../../DebounceTextField';
import Accounts from './Accounts';
import Filters from './Filters';
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  adornStart: {
    paddingLeft: '1px'
  },

  dialogContent: {
    height: '500px',
    overflow: 'hidden',
    padding: 0
  }
}));

function SearchDialog({
  open,
  onClose,
  accounts,
  loading,
  query,
  onChangeQuery,
  onSelectAccount,
  onChangeAccountTypes,
  refresh,
  showExport,
  exportAccounts,
  accountTypes
}) {
  const classes = useStyles();
  const { lastAccountSearchQuery } = useSelector(data => data.storedValues);
  const [anchorElement, setAnchorElement] = useState(null);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleShowFilter = e => {
    setAnchorElement(e.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorElement(null);
  };

  const handleKeyPress = e => {
    if (e.shiftKey && e.key === ' ') {
      onChangeQuery({
        target: {
          value: lastAccountSearchQuery.trim()
        }
      });
    }
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClose}
      open={open}
      fullScreen={smScreen}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <DebounceTextField
          fullWidth
          name="SrchAcc"
          value={query}
          onChange={onChangeQuery}
          onKeyPress={handleKeyPress}
          autoComplete="off"
          InputProps={{
            disableUnderline: true,
            autoFocus: true,
            form: {
              autocomplete: 'off'
            },
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <Tooltip title="Filter">
                    <IconButton
                      aria-describedby={
                        Boolean(anchorElement) ? 'filter-account' : undefined
                      }
                      onClick={handleShowFilter}
                      size="small"
                      tabIndex="-1"
                    >
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
                {showExport && (
                  <InputAdornment position="end">
                    <Tooltip title="Export">
                      <IconButton
                        onClick={exportAccounts}
                        size="small"
                        tabIndex="-1"
                      >
                        <CloudDownloadOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )}
                <InputAdornment position="end">
                  <Tooltip title="Refresh">
                    <IconButton onClick={refresh} size="small" tabIndex="-1">
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
                <InputAdornment position="end">
                  <Tooltip title="Close">
                    <IconButton onClick={onClose} size="small" tabIndex="-1">
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              </>
            )
          }}
          placeholder="Search"
          variant="standard"
          className="controls srchTxtFld"
        />

        <Filters
          anchorElement={anchorElement}
          onCloseFilter={handleCloseFilter}
          accountTypes={accountTypes}
          onChangeAccountTypes={onChangeAccountTypes}
        />
      </DialogTitle>

      <DialogContent className={classes.dialogContent} dividers>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress size={40} />
          </Box>
        ) : null}

        {!loading && accounts.length === 0 && query === '' ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <SearchIcon color="primary" fontSize="large" />
              <Typography variant="h5" color="primary">
                Search Account
              </Typography>
            </Box>
          </Box>
        ) : null}

        {!loading && accounts.length === 0 && query.trim() !== '' ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <SearchIcon color="primary" fontSize="large" />
              <Typography variant="h5" color="primary">
                No Result Found
              </Typography>
            </Box>
          </Box>
        ) : null}

        {!loading && accounts.length > 0 ? (
          <Accounts accounts={accounts} onSelectAccount={onSelectAccount} />
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

export default SearchDialog;
