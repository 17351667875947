import {
  Box,
  InputAdornment,
  TextField,
  IconButton,
  Button,
  Collapse,
  Checkbox,
  Typography,
  makeStyles,
  Container,
  Link,
  Divider
} from '@material-ui/core';
import {
  VisibilityOffOutlined,
  VisibilityOutlined,
  OpenInNew
} from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useEnv, useRequest, useToggle } from 'src/hooks';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import base64 from 'Base64';
import { Alert } from '@material-ui/lab';
import { loadPortalCredentials } from 'src/redux/slices/auth';
import { setPortalMeta } from 'src/redux/slices/portal';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Page } from 'src/components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { portal } from 'src/lib/endpoints';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  container: {
    height: props => (props.EGOV ? 'calc(100vh - 64px)' : '100vh'),
    display: 'flex',
    flexDirection: 'column'
  },
  version: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '4em',
    addingTop: '1em',
    paddingBottom: '1em'
  }
}));

const PortalLogin = () => {
  const { EGOV } = useEnv();
  const classes = useStyles({ EGOV });
  const dispatch = useDispatch();

  const { cdBiz, cdPortal } = useParams();

  const [portalError, setPortalError] = useState('');
  const [authError, setAuthError] = useState('');
  const [portalDetails, setPortalDetails] = useState({
    biz: '',
    sPortal: ''
  });

  const [passwordShown, , , togglePasswordVisibility] = useToggle();
  const request = useRequest();

  const logoFallback = EGOV
    ? '/static/images/iBFRS.png'
    : '/static/images/landingpage/sign-in-logo.png';

  function clearError() {
    setAuthError('');
  }

  async function loadPortalMeta(portalToken) {
    const res = await request
      .overrideWholeConfig({
        headers: {
          'x-access-tokens': portalToken,
          'Content-Type': 'application/json'
        }
      })
      .get(portal.meta);

    if (res.success) dispatch(setPortalMeta(res.data));
  }

  async function handleLogin(username, password) {
    const res = await request.get(`/portal/${cdBiz}/${cdPortal}/login`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + base64.btoa(username + ':' + password)
      },
      data: {}
    });

    if (res.success) {
      dispatch(loadPortalCredentials({ ...res.data, cdBiz, cdPortal }));
      loadPortalMeta(res.data?.portal_token || '');
    } else {
      setAuthError(res.error.data);
    }
  }

  useEffect(() => {
    let cancel = () => {};

    (async function() {
      const res = await request.get(`/portal/${cdBiz}/${cdPortal}/welcome`);

      if (res.success) setPortalDetails(res.data);
      else if (!res.isCancelled) setPortalError(res.errorMessage);
    })();

    return cancel;
  }, []);

  return (
    <Page className={classes.root} title="Login">
      <Container className={classes.container} maxWidth="xs">
        <Box className={classes.cardContainer}>
          <Box>
            <Box display="flex" justifyContent="center">
              <RouterLink to={`/portal/${cdBiz}/${cdPortal}`}>
                <img
                  src={`/api/portal/${cdBiz}/${cdPortal}/images/active_portal_logo.png`}
                  style={{ height: 180, width: 180 }}
                  alt="LOGO"
                  onError={e => {
                    e.target.onerror = null;
                    e.target.src = logoFallback;
                  }}
                />
              </RouterLink>
            </Box>
            <Box flexGrow={1} mt={2}>
              <Formik
                initialValues={{
                  username: '',
                  password: '',
                  accept: false
                }}
                validationSchema={Yup.object().shape({
                  username: Yup.string()
                    .max(255)
                    .required('Username is required'),
                  password: Yup.string()
                    .max(255)
                    .required('Password is required'),
                  accept: Yup.bool().oneOf(
                    [true],
                    'You must accept the terms of service'
                  )
                })}
                onSubmit={values =>
                  handleLogin(values.username, values.password)
                }
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Collapse in={portalError !== '' || authError !== ''}>
                      <Box mb={2}>
                        <Alert
                          severity="error"
                          variant="filled"
                          onClose={clearError}
                        >
                          {portalError || authError}
                        </Alert>
                      </Box>
                    </Collapse>

                    <TextField
                      error={Boolean(touched.username && errors.username)}
                      fullWidth
                      autoFocus
                      helperText={touched.username && errors.username}
                      label="Username"
                      margin="normal"
                      name="username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.username}
                      variant="outlined"
                      disabled={isSubmitting}
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      variant="outlined"
                      type={passwordShown ? 'text' : 'password'}
                      disabled={isSubmitting}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={togglePasswordVisibility}
                              size="small"
                              tabIndex={1}
                              disabled={isSubmitting}
                            >
                              {passwordShown ? (
                                <VisibilityOutlined />
                              ) : (
                                <VisibilityOffOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />

                    <Box display="flex" alignItems="center">
                      <Checkbox
                        checked={values.accept}
                        name="accept"
                        type="checkbox"
                        onChange={handleChange}
                        size="small"
                        disabled={isSubmitting}
                      />
                      <Typography
                        variant="subtitle2"
                        display="inline"
                        color="textSecondary"
                      >
                        I agree with{' '}
                        <Link href="/terms" target="_blank" tabIndex={1}>
                          Terms of Service <OpenInNew fontSize="inherit" />
                        </Link>
                      </Typography>
                    </Box>
                    {Boolean(touched.accept && errors.accept) ? (
                      <Typography
                        variant="caption"
                        color="error"
                        display="block"
                        style={{ marginLeft: '1em' }}
                      >
                        {touched.accept && errors.accept}
                      </Typography>
                    ) : null}

                    <Box mt={2}>
                      <Button
                        color="secondary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {isSubmitting ? 'Logging in...' : 'Log In'}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
            <Box my={3}>
              <Divider />
            </Box>
            <Box>
              <Typography
                component={Link}
                variant="subtitle2"
                href={`/portal/${cdBiz}/${cdPortal}/reset-password`}
                underline="none"
                tabIndex={2}
              >
                Forgot password?
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.version}>
          <Typography variant="body2" color="textSecondary">
            version {process?.env?.REACT_APP_VERSION ?? ''}
          </Typography>
        </Box>
      </Container>
    </Page>
  );
};

export default PortalLogin;
