const TERM_OPEN = '!@~[';
const TERM_CLOSE = ']';

const encodeString = (str = '') => {
  const emojiRegex = /\p{Extended_Pictographic}/u;
  let res = '';
  const strArray = Array.from(str);

  for (let i = 0; i < strArray.length; i++) {
    if (emojiRegex.test(strArray[i])) res += encodeEmoji(strArray[i]);
    else res += strArray[i];
  }

  return res;
};

const encodeEmoji = emoji => {
  return `${TERM_OPEN}0x${emoji.codePointAt(0).toString(16)}${TERM_CLOSE}`;
};

const decodeString = (str = '') => {
  let res = '';
  let index = str.indexOf(TERM_OPEN);
  while (index !== -1) {
    if (index !== 0) {
      res += str.substring(0, index);
      str = str.substring(index, str.length);
    }

    // const closeIndex = str.indexOf(TERM_CLOSE);
    let closeIndex,
      openCount = 0;
    for (let i = index + TERM_OPEN.length; i < str.length; i++) {
      if (str[i] === '[') openCount++;
      else if (str[i] === ']') {
        if (openCount === 0) {
          closeIndex = i;
          break;
        } else openCount--;
      }
    }
    const emojiHex = str.substring(TERM_OPEN.length, closeIndex);

    try {
      res += String.fromCodePoint(emojiHex);
    } catch (e) {}

    if (closeIndex !== str.length) {
      str = str.substring(closeIndex + 1, str.length);
    }

    index = str.indexOf(TERM_OPEN);
  }
  return res + str;
};

export { encodeString, decodeString };
