import React from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  makeStyles,
  useTheme
} from '@material-ui/core';
import DateFilter from './DateFilter';
import Chart from './Chart';
import usePostingSummary from '../hooks/usePostingSummary';
import PostingData from './PostingData';
import tableHeaderMeta from '../data/tableHeaderMeta';
import ChartSelect from './ChartSelect';

const useStyles = makeStyles({
  card: {
    transition: 'all 200ms ease-in-out',
    '&:hover': {
      transform: 'translateY(-.5em)',
      boxShadow: '0 4px 8px rgba(0,0,0,0.3)'
    }
  }
});

const PostingSummary = () => {
  const theme = useTheme();
  const classNames = useStyles();

  const {
    dateRange,
    updateDateRange,
    isLoading,
    postChartData,
    getPostingData,
    postingData,
    postingDataIsLoading,
    postingDataShown,
    summaryRef,
    summaryTitle,
    postingDataShownToggleOff,
    chartType,
    setChartType,
    minMaxAvg,
    minMaxBG,
    selectedTrans
  } = usePostingSummary();

  return (
    <>
      <Box mt={3}>
        <Card>
          {isLoading && <LinearProgress style={{ height: '2px' }} />}
          <CardContent>
            <Box display="flex" gridGap={theme.spacing(1)}>
              <DateFilter
                dateRange={dateRange}
                updateDateRange={updateDateRange}
                isLoading={isLoading}
              />
              <ChartSelect
                isLoading={isLoading}
                chartType={chartType}
                setChartType={setChartType}
              />
            </Box>
            <Box marginTop={2}>
              <Chart
                title="Total Posts"
                chartData={postChartData}
                getPostingData={getPostingData}
                chartType={chartType}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box my={3} ref={summaryRef}>
        {postingDataShown && (
          <>
            <Typography
              variant="h3"
              color="textSecondary"
              style={{ marginBottom: '.8em' }}
            >
              {selectedTrans}
            </Typography>
            <Grid container spacing={3} style={{ marginBottom: '1em' }}>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Card
                  style={{ backgroundColor: minMaxBG, color: '#FFF' }}
                  className={classNames.card}
                >
                  <Box p={2}>
                    <Typography variant="h5">Minimum Days to Post</Typography>
                    <Typography variant="h1">{minMaxAvg.post.min}</Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Card
                  style={{ backgroundColor: minMaxBG, color: '#FFF' }}
                  className={classNames.card}
                >
                  <Box p={2}>
                    <Typography variant="h5">Maximum Days to Post</Typography>
                    <Typography variant="h1">{minMaxAvg.post.max}</Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Card
                  style={{ backgroundColor: minMaxBG, color: '#FFF' }}
                  className={classNames.card}
                >
                  <Box p={2}>
                    <Typography variant="h5">Average Days to Post</Typography>
                    <Typography variant="h1">{minMaxAvg.post.avg}</Typography>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <PostingData
              data={postingData}
              isLoading={postingDataIsLoading}
              title={summaryTitle}
              columns={tableHeaderMeta}
              onClose={postingDataShownToggleOff}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default PostingSummary;
