import React from 'react';
import { useAuth, useEnv, useMenu } from 'src/hooks';
import { Link, useLocation } from 'react-router-dom';
import { useIBFRSLayout } from '.';
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  Zoom
} from '@material-ui/core';
import clsx from 'clsx';

const NavBar = () => {
  const { CONFIG } = useEnv();
  const { header, isScrolled, fromUlap } = useIBFRSLayout();
  const [anchorEl, open, close] = useMenu();

  const { pathname } = useLocation();

  const { portal, app } = useAuth();

  return (
    <header
      ref={header}
      className={clsx(
        'header-area',
        'header-sticky',
        'slideInDown',
        isScrolled ? 'background-header' : ''
      )}
      style={{ position: 'fixed', top: 0 }}
    >
      <Container maxWidth="lg">
        <div className="row">
          <Grid item md={12} sm={12} xs={12}>
            <nav className="main-nav">
              <Link to={fromUlap ? '/ibfrs' : '/'} className="logo">
                <Box display={'flex'} alignItems="center" gridGap={8}>
                  <img
                    src={
                      fromUlap
                        ? '/static/images/iBFRS_transparent.png'
                        : CONFIG.logo_url.small
                    }
                    width={60}
                    height={60}
                    alt="iBFRS"
                  />
                  <Typography variant="h2" color="primary">
                    <strong>iBFRS</strong>
                  </Typography>
                </Box>
              </Link>
              <Box flex={1} />
              <ul className="nav">
                <li className="scroll-to-section">
                  <Typography>
                    <Link
                      to={fromUlap ? '/ibfrs' : '/'}
                      className={pathname === '/' ? 'active' : ''}
                    >
                      Home
                    </Link>
                  </Typography>
                </li>
                <li className="scroll-to-section">
                  <Typography>
                    <Link
                      to={fromUlap ? '/ibfrs/benefits' : '/benefits'}
                      className={pathname === '/benefits' ? 'active' : ''}
                    >
                      Benefits
                    </Link>
                  </Typography>
                </li>
                <li className="scroll-to-section">
                  <Typography>
                    <Link
                      to={fromUlap ? '/ibfrs/anchors' : '/anchors'}
                      className={pathname === '/anchors' ? 'active' : ''}
                    >
                      Anchors
                    </Link>
                  </Typography>
                </li>
                <li className="scroll-to-section">
                  <Typography>
                    <Link
                      to={fromUlap ? '/ibfrs/testimonials' : '/testimonials'}
                      className={pathname === '/testimonials' ? 'active' : ''}
                    >
                      Testimonials
                    </Link>
                  </Typography>
                </li>
                <li className="scroll-to-section">
                  <Typography>
                    <Link
                      to={fromUlap ? '/ibfrs/contact-us' : '/contact-us'}
                      className={pathname === '/contact-us' ? 'active' : ''}
                    >
                      Contact Us
                    </Link>
                  </Typography>
                </li>
                <li>
                  <div className="gradient-button">
                    <Typography>
                      <Link
                        id="modal_trigger"
                        to={
                          portal
                            ? '/app/portal/welcome'
                            : app
                            ? '/app/dashboard'
                            : '/app/login'
                        }
                      >
                        {portal ? 'Portal' : app ? 'Dashboard' : 'Login'}
                      </Link>
                    </Typography>
                  </div>
                </li>
              </ul>
              <a
                className={clsx(
                  'menu-trigger',
                  Boolean(anchorEl) ? 'active' : ''
                )}
                onClick={open}
              >
                <span></span>
              </a>
            </nav>
          </Grid>
        </div>
      </Container>
      <Popover
        TransitionComponent={Zoom}
        PaperProps={{
          style: {
            boxShadow: '0px 5px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: 10
          }
        }}
        style={{
          marginTop: 26,
          marginLeft: 30
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={close}
      >
        <Box width={220}>
          <List component="nav" disablePadding>
            <ListItem button component={Link} to="/" onClick={close}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5',
                  align: 'center'
                }}
              >
                Home
              </ListItemText>
            </ListItem>
            <ListItem button component={Link} to="/benefits" onClick={close}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5',
                  align: 'center'
                }}
              >
                Benefits
              </ListItemText>
            </ListItem>
            <ListItem button component={Link} to="/anchors" onClick={close}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5',
                  align: 'center'
                }}
              >
                Anchors
              </ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/testimonials"
              onClick={close}
            >
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5',
                  align: 'center'
                }}
              >
                Testimonials
              </ListItemText>
            </ListItem>
            <ListItem button component={Link} to="/contact-us" onClick={close}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5',
                  align: 'center'
                }}
              >
                Contact Us
              </ListItemText>
            </ListItem>
            <ListItem button component={Link} to="/login" onClick={close}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5',
                  align: 'center'
                }}
              >
                Login
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Popover>
    </header>
  );
};

export default NavBar;
