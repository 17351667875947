import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dateRange: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  subType: {
    id: '',
    description: ''
  },
  subAccount: {
    ixSub: '',
    sSub: ''
  },
  brch: {
    ixBrch: 0,
    sBrch: ''
  },
  error: '',
  compact: false,
  results: [],
  page: 1,
  rowsPerPage: 25
};

const slice = createSlice({
  name: 'sl2',
  initialState: INITIAL_STATE,
  reducers: {
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setSubType(state, action) {
      state.subType = action.payload;
    },
    setSubAccount(state, action) {
      state.subAccount = action.payload;
    },
    setResults(state, action) {
      state.results = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    setBranch(state, action) {
      state.brch = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setCompact(state, action) {
      state.compact = action.payload;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const sl2Reducer = slice.reducer;

export const {
  setDateRange,
  setSubType,
  setSubAccount,
  setCompact,
  setResults,
  setRowsPerPage,
  setPage,
  clear,
  restore,
  setBranch
} = slice.actions;

export default sl2Reducer;
